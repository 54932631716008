import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  Toolbar,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import rootStore from "../../stores/RootStore";
import Select from "react-select";
import { contactOperatorMapList } from "../../Db";
import { Add, Cancel, ChevronLeft, Menu } from "@material-ui/icons";
import { Circle } from "@mui/icons-material";
import moment from "moment";
import { getListApi } from "../../Api";
import {
  getAccountLocalizedText,
  getLocalizedText,
  getSlug,
} from "../../Functions";
import ProductFilterValueComponent from "./ProductFilterValueComponent";
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for options
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for the entire control container, including the search input
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "no options" message
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "loading" message
  }),
};

const Header = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #979797;
`;
const VerticalSpaceBetween = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ChipLable = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
  margin-right: 4px;
`;
const ChipValue = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  word-wrap: break-word;
  width: 100%;
`;
const CustomChip = styled(Box)`
  display: flex;
  position: relative;
  padding: 20px;
  background-color: #e0e0e0;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  border-radius: 12px;
`;
const FilterListContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${`max-height: calc(100vh - 576px);`};
  overflow-y: auto;
  column-gap: 10px;
  row-gap: 10px;
  padding: 0px 20px;
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #efeff4;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #999;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #666;
  }
`;

const ProductsFilterSection = ({
  postSaveReportFilter,
  handleReset,
  appliedFilterList,
  containerWidth,
}) => {
  const columnsList = [...rootStore.productStore.columnsList];

  const [multiSelectFilterColumnValue, setMultiSelectFilterColumnValue] =
    useState(null);
  const [selectedFilterColumn, setSelectedFilterColumn] = useState(null);
  const [operatorList, setOperatorList] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [filterTypeListMeta, setFilterTypeListMeta] = useState({});
  const [filter, setFilter] = useState({});
  const [selectedFilterList, setSelectedFilterList] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleSubmit = async () => {
    postSaveReportFilter(selectedFilterList);
  };

  const init = async () => {
    setSelectedFilterList(appliedFilterList);
  };

  useEffect(() => {
    init();
  }, [appliedFilterList]);

  const getSelectFilterColumnOptions = () => {
    let filteredList = columnsList.filter(
      (obj) =>
        obj["filterable"] &&
        obj["is_visible"] &&
        canShowOptionInSelectColumnFilter(obj["id"])
    );
    return filteredList.map((entry) => ({
      label: entry["display_name"],
      value: entry["id"],
    }));
  };

  const getOperatorList = (item) => {
    if (item["data_type"] === "date" || item["data_type"] === "datetime") {
      if (item["filter_type"] === "range") {
        return contactOperatorMapList["date_range"];
      } else {
        return contactOperatorMapList["date"];
      }
    } else if (
      item["data_type"] === "string" ||
      item["data_type"] === "json_array_string"
    ) {
      if (item["filter_type"] === "list") {
        return contactOperatorMapList["list"];
      } else {
        return contactOperatorMapList["string"];
      }
    } else if (item["data_type"] === "integer") {
      if (item["filter_type"] === "range") {
        return contactOperatorMapList["integer_range"];
      } else if (item["filter_type"] === "list") {
        return contactOperatorMapList["list"];
      } else {
        return contactOperatorMapList["integer"];
      }
    }

    return [];
  };

  const getListDetailsToRender = async (columnMeta) => {
    let input = columnMeta["input"];
    let tempType = "list";
    let tempList = [];
    if (input.charAt(0) === "/" || input.startsWith("ep:")) {
      let slug = getSlug(input);
      let response = await getListApi({ slug: slug });
      if (response !== null) {
        if (Array.isArray(response)) {
          tempType = "list";
          tempList = response;
        } else if (typeof response === "object") {
          if (Object.keys(response).length > 0) {
            tempType = response["type"];
            tempList = response["values"];
          }
        }
      }
    } else if (input.charAt(0) !== "/" && input !== "" && input !== null) {
      try {
        let parseJSON = JSON.parse(input);
        if (Array.isArray(parseJSON)) {
          tempType = "list";
          tempList = parseJSON;
        } else if (typeof parseJSON === "object") {
          if (Object.keys(parseJSON).length > 0) {
            tempType = parseJSON["type"];
            tempList = parseJSON["values"];
          }
        }
      } catch (error) {
        if (input.length > 0) {
          let list = input.split(",");
          tempType = "list";
          tempList = list;
        }
      }
    }
    return { list: tempList, type: tempType };
  };

  const isFilterValuePicked = () => {
    if (Object.keys(filter).length > 0) {
      let data = filter[selectedFilterColumn["column_name"]];
      if (data["filter_type"] === "list") {
        return data["value"].length > 0;
      } else if (data["filter_type"] === "range") {
        if (data["data_type"] === "date" || data["data_type"] === "datetime") {
          return data["value"] !== null;
        } else {
          return (
            data["value"].hasOwnProperty("start") &&
            data["value"].hasOwnProperty("end")
          );
        }
      } else {
        return data["value"] !== null && data["value"] !== "";
      }
    }
    return false;
  };

  const isAddButtonEnabled = () => {
    if (isFilterValuePicked()) {
      return selectedOperator !== null;
    }
    return false;
  };

  const handleAdd = () => {
    setAlertMessage("");
    setShowAlert(false);
    let tempList = [...selectedFilterList];
    let tempData = {
      column_id: selectedFilterColumn["id"],
      filter: filter,
      operator: selectedOperator,
    };
    let index = tempList.findIndex(
      (filterData) => filterData["column_id"] === tempData["column_id"]
    );
    if (index !== -1) {
      tempList[index] = tempData;
    } else {
      tempList.push(tempData);
    }
    setSelectedFilterList(tempList);
    setFilterTypeListMeta({});
    setOperatorList([]);
    setSelectedOperator(null);
    setSelectedFilterColumn(null);
    setMultiSelectFilterColumnValue(null);
    setFilter({});
  };

  const handleRemove = (columnID) => {
    let tempList = [...selectedFilterList];
    tempList = tempList.filter(
      (filterData) => filterData["column_id"] !== columnID
    );
    setSelectedFilterList(tempList);
  };

  const handleEdit = async (filterData) => {
    let index = columnsList.findIndex(
      (column) => column["id"] === filterData["column_id"]
    );
    if (index !== -1) {
      let columnMeta = columnsList[index];
      if (columnMeta["filter_type"] === "list") {
        let filterTypeListMeta = await getListDetailsToRender(columnMeta);

        setFilterTypeListMeta(filterTypeListMeta);
      }
      setFilter(filterData["filter"]);
      setSelectedOperator(filterData["operator"]);
      setSelectedFilterColumn(columnMeta);
      setMultiSelectFilterColumnValue({
        label: columnMeta["display_name"],
        value: columnMeta["id"],
      });
      let tempList = getOperatorList(columnMeta);
      setOperatorList(tempList);
    }
  };

  const canShowOptionInSelectColumnFilter = (columnID) => {
    let index = selectedFilterList.findIndex(
      (obj) => obj["column_id"] === columnID
    );
    return index === -1;
  };

  const renderValueFieldComponent = () => {
    if (selectedFilterColumn === null || selectedOperator === null) {
      return (
        <OutlinedInput
          disabled
          placeholder="Enter Value"
          style={{
            width: "100%",
            height: "30px",
            fontSize: "12px",
          }}
        />
      );
    } else {
      return (
        <ProductFilterValueComponent
          filter={filter}
          setFilter={setFilter}
          filterTypeListMeta={filterTypeListMeta}
          selectedColumn={selectedFilterColumn}
          selectedFilterList={selectedFilterList}
        />
      );
    }
  };

  return (
    <Box role="presentation" sx={{ height: "100%" }}>
      <VerticalSpaceBetween
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (isAddButtonEnabled()) {
            setAlertMessage(
              "Kindly apply the selected filter before proceeding with your search."
            );
            setShowAlert(true);
          } else {
            if (selectedFilterList.length === 0) {
              setAlertMessage(
                "Kindly apply at least one filter before proceeding with your search."
              );
              setShowAlert(true);
            } else {
              handleSubmit();
            }
          }
        }}
      >
        <List>
          <ListItem
            style={{
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <ListItemText>
              <Header>{getAccountLocalizedText("filter_products")}</Header>
            </ListItemText>
          </ListItem>

          <ListItem>
            <InputWrapper>
              <Label>{getLocalizedText("select_filter")}</Label>
              <Select
                isDisabled={selectedFilterList.length > 5}
                options={getSelectFilterColumnOptions()}
                value={multiSelectFilterColumnValue}
                onChange={async (selectedOption) => {
                  console.log("selected option:::", selectedOption);
                  if (selectedOption !== null) {
                    let index = columnsList.findIndex(
                      (column) => column["id"] === selectedOption.value
                    );
                    if (index !== -1) {
                      let columnMeta = columnsList[index];
                      if (columnMeta["filter_type"] === "list") {
                        let filterTypeListMeta = await getListDetailsToRender(
                          columnMeta
                        );

                        setFilterTypeListMeta(filterTypeListMeta);
                      }
                      let tempList = getOperatorList(columnMeta);
                      setFilter({});
                      if (tempList.length > 0) {
                        setSelectedOperator(tempList[0]);
                      } else {
                        setSelectedOperator(null);
                      }
                      setOperatorList(tempList);
                      setMultiSelectFilterColumnValue(selectedOption);
                      setSelectedFilterColumn(columnMeta);
                    }
                  } else {
                    setFilterTypeListMeta({});
                    setOperatorList([]);
                    setSelectedOperator(null);
                    setSelectedFilterColumn(null);
                    setMultiSelectFilterColumnValue(null);
                    setFilter({});
                  }
                }}
                closeMenuOnSelect={true}
                styles={customStyles}
                isClearable
              />
            </InputWrapper>
          </ListItem>
          <ListItem>
            <InputWrapper>
              <Label>{getLocalizedText("select_operator")}</Label>
              <Select
                isDisabled={
                  operatorList.length === 0 ||
                  multiSelectFilterColumnValue === null ||
                  selectedFilterList.length > 5
                }
                options={operatorList}
                value={selectedOperator}
                onChange={(selectedOption) => {
                  setSelectedOperator(selectedOption);
                }}
                closeMenuOnSelect={true}
                styles={customStyles}
                isClearable
              />
            </InputWrapper>
          </ListItem>
          <ListItem>
            <InputWrapper>
              <Label>{getLocalizedText("filter_value")}</Label>

              {renderValueFieldComponent()}
            </InputWrapper>
          </ListItem>

          <ListItem>
            <ButtonWrapper
              style={{ justifyContent: "flex-end", marginBottom: "20px" }}
            >
              <Button
                disabled={!isAddButtonEnabled()}
                onClick={handleAdd}
                variant="contained"
                color="primary"
                style={{ textTransform: "capitalize", width: "100%" }}
              >
                <Add /> {getLocalizedText("add_filter")}
              </Button>
            </ButtonWrapper>
          </ListItem>
          <Divider style={{ marginBottom: "20px" }} />
          <ListItem>
            {selectedFilterList.length > 0 && (
              <FilterListContainer>
                {selectedFilterList.map((item, i) => {
                  let index = columnsList.findIndex(
                    (column) => column["id"] === item["column_id"]
                  );
                  if (index !== -1) {
                    let columnMeta = columnsList[index];

                    if (columnMeta["filter_type"] === "list") {
                      let value =
                        item["filter"][columnMeta["column_name"]]["value"];
                      return (
                        <CustomChip
                          onClick={() => {
                            handleEdit(item);
                          }}
                          key={i}
                          style={{ flexDirection: "column" }}
                        >
                          <Cancel
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemove(item["column_id"]);
                            }}
                            style={{
                              position: "absolute",
                              top: "2px",
                              right: "2px",
                              cursor: "pointer",
                            }}
                          />

                          <ChipLable>{`${columnMeta["display_name"]} ${item["operator"]["label"]}`}</ChipLable>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {value.map((list_item, i) => {
                              return (
                                <Box
                                  sx={{ display: "flex", gap: "4px" }}
                                  key={i}
                                >
                                  <Circle
                                    style={{
                                      width: "8px",
                                      height: "8px",
                                      color: "gray",
                                      marginTop: "2px",
                                    }}
                                  />
                                  <ChipValue>{list_item["label"]}</ChipValue>
                                </Box>
                              );
                            })}
                          </Box>
                        </CustomChip>
                      );
                    } else if (columnMeta["filter_type"] === "range") {
                      let chipValueString = "";
                      if (
                        columnMeta["data_type"] === "date" ||
                        columnMeta["data_type"] === "datetime"
                      ) {
                        let start = moment(
                          item["filter"][columnMeta["column_name"]]["value"][0]
                        ).format("YYYY-MM-DD");
                        let end = moment(
                          item["filter"][columnMeta["column_name"]]["value"][1]
                        ).format("YYYY-MM-DD");
                        chipValueString = `${start} to ${end}`;
                      } else {
                        chipValueString = `${
                          item["filter"][columnMeta["column_name"]]["value"][
                            "start"
                          ]
                        } to ${
                          item["filter"][columnMeta["column_name"]]["value"][
                            "end"
                          ]
                        }`;
                      }
                      return (
                        <>
                          <CustomChip
                            onClick={() => {
                              handleEdit(item);
                            }}
                          >
                            {columnMeta["column_name"] !== "created_at" && (
                              <Cancel
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemove(item["column_id"]);
                                }}
                                style={{
                                  position: "absolute",
                                  top: "2px",
                                  right: "2px",
                                  cursor: "pointer",
                                }}
                              />
                            )}

                            <ChipLable>{`${columnMeta["display_name"]} ${item["operator"]["label"]}`}</ChipLable>
                            <ChipValue>{chipValueString}</ChipValue>
                          </CustomChip>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <CustomChip
                            onClick={() => {
                              handleEdit(item);
                            }}
                          >
                            <Cancel
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemove(item["column_id"]);
                              }}
                              style={{
                                position: "absolute",
                                top: "2px",
                                right: "2px",
                                cursor: "pointer",
                              }}
                            />

                            <ChipLable>{`${columnMeta["display_name"]} ${item["operator"]["label"]}`}</ChipLable>
                            <ChipValue>
                              {
                                item["filter"][columnMeta["column_name"]][
                                  "value"
                                ]
                              }
                            </ChipValue>
                          </CustomChip>
                        </>
                      );
                    }
                  }
                })}
              </FilterListContainer>
            )}
          </ListItem>
        </List>
        <AppBar
          position="sticky"
          color="inherit"
          elevation={0}
          style={{
            padding: "0px",
            border: "1px solid #E0E0E0",
          }}
          sx={{ top: "auto", left: 0, bottom: 0, width: containerWidth }}
        >
          <Toolbar
            style={{
              width: containerWidth,
              position: "relative",
              padding: "16px",
            }}
          >
            <Grid container spacing={2}>
              {showAlert && (
                <Grid item xs={12}>
                  <Alert
                    style={{
                      width: "100%",
                      padding: "0px 20px",
                      fontSize: "12px",
                    }}
                    severity="warning"
                  >
                    {alertMessage}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      setAlertMessage("");
                      setShowAlert(false);
                      handleReset();
                    }}
                    disabled={appliedFilterList.length === 0}
                    variant="contained"
                    color="error"
                    style={{ textTransform: "capitalize", width: "100%" }}
                  >
                    {getLocalizedText("reset")}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "capitalize", width: "100%" }}
                  >
                    {getLocalizedText("filter")}
                  </Button>
                </ButtonWrapper>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </VerticalSpaceBetween>
    </Box>
  );
};

export default observer(ProductsFilterSection);
