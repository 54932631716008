import {
  Box,
  Typography,
  OutlinedInput,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  Alert,
} from "@mui/material";
import { Grid, makeStyles, Button } from "@material-ui/core";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { metaWabaHeaderOptions, metaWabaButtonTypes } from "../../../Db";
import CustomFieldHeader from "../../custom_tooltip_headers/CustomFieldHeader";
import AddFileInputComponent from "../../add_product_sidepanel/components/AddFileInputComponent";
import InputFileViewComponent from "../../add_product_sidepanel/components/InputFileViewComponent";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
import EditIcon from "@mui/icons-material/Edit";
import CustomAlertMessageDialog from "../../alert_dialogue/CustomAlertMessageDialog";
import WhatsappMessageTextEditor from "../WhatsappMessageTextEditor";

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const GroupLabel = styled("legend")`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  width: auto;
`;

const MetaWABAScreen = ({
  isEdit,
  selectedImages,
  handleImageUpload,
  handleRemoveImage,
  handleImagePreview,
  details,
  setDetails,
  buttonRows, // buttons render field rows
  setButtonRows,
  isMarketingTemplate,
  setFooterEditable,
  footerEditable,
  getOptOutKeywordsForProvider,
  bodyTextError,
  setBodyTextError,
}) => {
  const classes = useStyles();
  const [showFooterWarning, setShowFooterWarning] = useState(false);
  const [alertShown, setAlertShown] = useState(false);

  const handleChange = (e) => {
    const updatedDetails = { ...details, [e.target.name]: e.target.value };
    setDetails(updatedDetails);
  };

  const handleBodyTextChange = (value) => {
    const updatedDetails = { ...details, body_text: value };
    setDetails(updatedDetails);
  };

  const handleAddButtonRow = (option) => {
    const initialFields =
      option === "Visit"
        ? { url: "", button_text: "" }
        : { phone_number: "", button_text: "" };

    const newButtonRows = [
      ...buttonRows,
      { id: uuidv4(), option, ...initialFields },
    ];

    setButtonRows(newButtonRows);
  };

  const handleInputChange = (id, field, value) => {
    setButtonRows((prev) =>
      prev.map((button) =>
        button.id === id ? { ...button, [field]: value } : button
      )
    );
  };

  const handleDeleteButtonRow = (id) => {
    setButtonRows((prev) => prev.filter((button) => button.id !== id));
  };

  const checkForAddRowDisable = (rowType) => {
    //if the rowType is already present, disable add button for it
    return buttonRows.some((button) => button.option === rowType);
  };

  const handleEditFooterClick = () => {
    setFooterEditable(!footerEditable);
  };

  return (
    <>
      <Grid item xs={12}>
        <Box
          component={"fieldset"}
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
            border: "1px solid #cccccc",
            padding: "12px",
            width: "100%",
          }}
        >
          <GroupLabel style={{ fontSize: "12px" }}>
            <CustomFieldHeader
              header={"Header"}
              tooltip={"Add header text or image (Optional)"}
              required={false}
              style={{
                marginBottom: 0,
              }}
            />
          </GroupLabel>
          <Select
            classes={{
              icon: classes.icon,
            }}
            displayEmpty
            required
            onChange={handleChange}
            name="header_format"
            disableUnderline
            variant="outlined"
            value={details["header_format"]}
            style={{
              width: "100%",
              height: "30px",
              fontSize: "12px",
              borderRadius: "4px",
            }}
          >
            {metaWabaHeaderOptions.map((item, i) => {
              return (
                <MenuItem value={item} key={i} style={{ fontSize: "12px" }}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>

          {details.header_format === "Text" && (
            <OutlinedInput
              required
              style={{ height: "30px" }}
              inputProps={{
                maxLength: 60,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              onChange={(e) => {
                if (e.target.value.length <= 60) {
                  handleChange(e); //length constrain
                }
              }}
              name="header_text"
              value={details["header_text"]}
            />
          )}
          {details.header_format === "Image" && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
                flexWrap: "wrap",
              }}
            >
              {selectedImages.length < 1 && (
                <AddFileInputComponent
                  acceptedFiles={"image/jpeg,image/png"}
                  handleChange={(event) => handleImageUpload(event)}
                  scope={"image"}
                />
              )}
              {selectedImages.map((image, idx) => {
                return (
                  <InputFileViewComponent
                    fileURL={isEdit ? image : URL.createObjectURL(image)}
                    handleRemove={() => handleRemoveImage(idx)}
                    handleView={() => handleImagePreview(image)}
                    scope={"image"}
                  />
                );
              })}
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <InputWrapper>
          <CustomFieldHeader
            header={"Body"}
            tooltip={"Enter body text. Can include variables. Max length 1024"}
            required={true}
          />
          <WhatsappMessageTextEditor
            value={details["body_text"]}
            handleChange={(value) => {
              if (value.length <= 1024) {
                handleBodyTextChange(value); //length constrain
              }
            }}
            placeholder={
              "Dear {{1}}! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus interdum dui vitae nisi {{2}}, ac convallis sem scelerisque."
            }
          />
          {bodyTextError && (
            <Alert
              style={{
                width: "100%",
                padding: "0px 20px",
                marginTop: "8px",
              }}
              severity="warning"
              onClose={() => {
                setBodyTextError(false);
              }}
            >
              This field is required.
            </Alert>
          )}
        </InputWrapper>
      </Grid>
      <Grid item xs={12}>
        <InputWrapper>
          <CustomFieldHeader
            header={"Footer"}
            tooltip={"Enter footer text (Optional)"}
            required={false}
          />
          <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <OutlinedInput
              disabled={isMarketingTemplate ? !footerEditable : false}
              style={{ height: "30px", width: "100%" }}
              inputProps={{
                maxLength: 60,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              onChange={(e) => {
                if (e.target.value.length <= 60) {
                  handleChange(e); //length constrain
                }
              }}
              onBlur={(e) => {
                if (isMarketingTemplate) {
                  //show alert once if they are removing optout keywords
                  const optOutKeywords = getOptOutKeywordsForProvider();
                  const containsOptOutKeyword = optOutKeywords.some((keyword) =>
                    e.target.value.includes(keyword)
                  );
                  if (!containsOptOutKeyword) {
                    if (!alertShown) {
                      setShowFooterWarning(true);
                      setAlertShown(true);
                    }
                  }
                }
              }}
              name="footer_text"
              value={details["footer_text"]}
            />
            {isMarketingTemplate && (
              <Tooltip title={"Edit Footer"}>
                <IconButton onClick={handleEditFooterClick}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </InputWrapper>
      </Grid>
      <Grid item xs={12}>
        <Box
          component={"fieldset"}
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
            border: "1px solid #cccccc",
            padding: "12px",
            width: "100%",
          }}
        >
          <GroupLabel
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              lineHeight: "1.5",
              marginBottom: "0px",
            }}
          >
            <CustomFieldHeader
              header={"Buttons"}
              tooltip={
                "Add buttons that lets customers respond or take action (Optional)"
              }
              required={false}
              style={{
                marginBottom: 0,
              }}
            />
          </GroupLabel>
          <FormControl fullWidth>
            <Select
              style={{ height: "40px" }}
              labelId="select-label"
              displayEmpty
              value=""
              onChange={() => {}} // Disable default selection behavior
              renderValue={() => (
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <AddIcon />
                  <Typography sx={{ fontSize: "14px" }}>Add item</Typography>
                </Box>
              )}
            >
              {metaWabaButtonTypes.map((option) => (
                <MenuItem
                  disabled={checkForAddRowDisable(option)}
                  key={option}
                  onClick={() => handleAddButtonRow(option)}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "14px",
                  }}
                >
                  {option}
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ fontSize: "14px" }}
                  >
                    Add
                  </Button>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {buttonRows?.map((row) => (
              <Box
                key={row.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {row.option === "Visit" ? (
                  <>
                    <OutlinedInput
                      required
                      style={{ height: "30px" }}
                      inputProps={{
                        maxLength: 2000,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      placeholder="URL"
                      value={row.url}
                      onChange={(e) => {
                        if (e.target.value.length <= 2000) {
                          handleInputChange(row.id, "url", e.target.value); //length constrain
                        }
                      }}
                      size="small"
                      sx={{ flex: 1 }}
                    />
                    <OutlinedInput
                      required
                      style={{ height: "30px" }}
                      inputProps={{
                        maxLength: 25,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      placeholder="Button Text"
                      value={row.text}
                      onChange={(e) => {
                        if (e.target.value.length <= 25) {
                          handleInputChange(row.id, "text", e.target.value);
                        }
                      }}
                      size="small"
                      sx={{ flex: 1 }}
                    />
                  </>
                ) : (
                  <>
                    <OutlinedInput
                      required
                      style={{ height: "30px" }}
                      inputProps={{
                        pattern: "[0-9]*", // only allow numbers
                        maxLength: 20,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      placeholder="Phone Number"
                      value={row.phone_number}
                      onChange={(e) => {
                        if (e.target.value.length <= 20) {
                          handleInputChange(
                            row.id,
                            "phone_number",
                            e.target.value
                          ); //length constrain
                        }
                      }}
                      size="small"
                      sx={{ flex: 1 }}
                    />
                    <OutlinedInput
                      required
                      style={{ height: "30px" }}
                      inputProps={{
                        maxLength: 25,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      placeholder="Button Text"
                      value={row.text}
                      onChange={(e) => {
                        if (e.target.value.length <= 25) {
                          handleInputChange(row.id, "text", e.target.value);
                        }
                      }}
                      size="small"
                      sx={{ flex: 1 }}
                    />
                  </>
                )}

                <IconButton
                  onClick={() => handleDeleteButtonRow(row.id)}
                  color="error"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>
      </Grid>
      {showFooterWarning && (
        <CustomAlertMessageDialog
          title={"Alert"}
          open={showFooterWarning}
          handleClose={() => setShowFooterWarning(false)}
          handleCloseOutside={() => setShowFooterWarning(false)}
          message={`You've edited the footer text. For MARKETING templates, including an opt-out keyword in the footer is recommended.`}
        />
      )}
    </>
  );
};

export default MetaWABAScreen;
