import { action, makeObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import rootStore from "../stores/RootStore";
import { getUserStatus } from "../Api";
import OzonetelCallService from "../services/ozonetelCallService";
import MCubeCallService from "../services/mcubeCallService";
import externalDialerNotificationService from "../services/externalDialerNotificationService";
import TeckinfoCallService from "../services/teckinfoCallService";
import { consoleLogger } from "../Functions";
import BonvoiceCallService from "../services/bonvoiceCallService";

class PhoneCallState {
  inboundCallService = null;
  outboundCallService = null;
  state = {};
  userStatus = {};
  callStatus = {};
  interval = null;
  showProgressiveCallDialog = false;
  dispositionFailed = false;
  constructor() {
    makeObservable(this, {
      inboundCallService: observable,
      outboundCallService: observable,
      state: observable,
      userStatus: observable,
      callStatus: observable,
      interval: observable,
      showProgressiveCallDialog: observable,
      dispositionFailed: observable,
      registerCallService: action,
      init: action,
      fetchUserStatus: action,
      startFetchCallStatusJob: action,
      stopFetchCallStatusJob: action,
      initiateCall: action,
      postSubmitCallLog: action,
      reset: action,
      getDialerLogIdForLead: action,
      getExternalDialerAudioUrl: action,
      hasPendingCallLog: action,
      onProgressiveCallStart: action,
      onProgressiveCallEnd: action,
      enableProgressiveCallDialog: action,
      resetProgressiveCallDialog: action,
      setCallDisposition: action,
      isUserLeadOwner: action,
      isCallCompleted: action,
      getCallTypeForLead: action,
    });
    makePersistable(this, {
      name: PhoneCallState,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: ["state"],
    });
  }

  isCallCompleted = () => {
    let outboundDialer = rootStore.userStore.getOutboundDialer();
    let inboundDialer = rootStore.userStore.getInboundDialer();
    if (outboundDialer === "teckinfo" || inboundDialer === "teckinfo") {
      if (this.callStatus.hasOwnProperty("status")) {
        if (this.callStatus["status"] === "wrapup") {
          return true;
        }
      }
    } else {
      if (this.callStatus.hasOwnProperty("status")) {
        if (this.callStatus["status"] === "completed") {
          return true;
        }
      }
    }
    return false;
  };

  init = async () => {
    await this.fetchUserStatus();
    consoleLogger(
      `Phone state ::: fetch user status ::: ${JSON.stringify(this.userStatus)}`
    );
    consoleLogger(`Phone state ::: state ::: ${JSON.stringify(this.state)}`);
    this.registerCallService();

    if (this.hasPendingCallLog()) {
      let projectID = rootStore.authStore.projectId;
      let formData = {
        dialer_log_id: this.state["dialer_log_id"],
        project_id: this.state["project_id"],
      };
      if (this.state["project_id"] === projectID) {
        await this.fetchCallStatus(formData);
        let outboundDialer = rootStore.userStore.getOutboundDialer();
        if (
          !this.isCallCompleted() &&
          this.state["call_type"] === "c2c" &&
          outboundDialer !== "default" &&
          outboundDialer !== "none"
        ) {
          consoleLogger(`Phone state ::: startFetchCallStatusJob`);
          this.startFetchCallStatusJob(
            this.state["lead_id"],
            this.state["dialer_log_id"]
          );
        }
      }

      consoleLogger(
        `Phone state ::: fetch call status ::: ${JSON.stringify(
          this.callStatus
        )}`
      );
    }
  };

  hasPendingCallLog = () => {
    if (
      this.state.hasOwnProperty("dialer_log_id") &&
      this.state.hasOwnProperty("call_log_submitted")
    ) {
      return this.state["call_log_submitted"] === false;
    }
    return false;
  };

  fetchUserStatus = async () => {
    let formdata = {
      project_id: rootStore.authStore.projectId,
    };
    try {
      let response = await getUserStatus(formdata);
      this.userStatus = response.data;
    } catch (error) {
      this.userStatus = {};
      console.log(error);
    }
  };
  registerCallService = async () => {
    if (this.callServiceInstance !== null) {
      this.callServiceInstance = null;
    }
    //get the service name from project
    let outboundDialer = rootStore.userStore.getOutboundDialer();
    let inboundDialer = rootStore.userStore.getInboundDialer();

    switch (inboundDialer) {
      case "ozonetel":
        this.inboundCallService = new OzonetelCallService();
        break;
      case "mcube":
        this.inboundCallService = new MCubeCallService();
        break;
      case "bonvoice":
        this.inboundCallService = new BonvoiceCallService();
        break;
      case "teckinfo":
        this.inboundCallService = new TeckinfoCallService();
        this.inboundCallService.setupDialerConfig();
        break;
      default:
        this.inboundCallService = null;
        break;
    }
    switch (outboundDialer) {
      case "ozonetel":
        this.outboundCallService = new OzonetelCallService();
        break;
      case "mcube":
        this.outboundCallService = new MCubeCallService();
        break;
      case "bonvoice":
        this.outboundCallService = new BonvoiceCallService();
        break;
      case "teckinfo":
        this.outboundCallService = new TeckinfoCallService();
        this.outboundCallService.setupDialerConfig();
        break;
      default:
        this.outboundCallService = null;
        break;
    }
  };
  getExternalDialerAudioUrl = (summary) => {
    if (
      summary.hasOwnProperty("ResourceURL") &&
      summary["ResourceURL"] !== null
    ) {
      return summary["ResourceURL"];
    }

    if (summary.hasOwnProperty("filename") && summary["filename"] !== null) {
      return summary["filename"];
    }

    if (summary.hasOwnProperty("Filename") && summary["Filename"] !== null) {
      return summary["Filename"];
    }

    if (summary.hasOwnProperty("AudioFile") && summary["AudioFile"] !== null) {
      return summary["AudioFile"];
    }
    return "";
  };

  startFetchCallStatusJob = (leadID, dialerLogID) => {
    this.interval = setInterval(async () => {
      let projectID = rootStore.authStore.projectId;

      let formData = {
        dialer_log_id: dialerLogID,
        project_id: projectID,
        lead_id: leadID,
      };
      await this.fetchCallStatus(formData);
    }, 30000);
  };

  fetchCallStatus = async (formData) => {
    let response = {};
    if (this.state["call_type"] === "progressive") {
      if (this.inboundCallService !== null) {
        response = await this.inboundCallService.fetchCallStatus(formData);
      }
    } else {
      if (this.outboundCallService != null) {
        response = await this.outboundCallService.fetchCallStatus(formData);
      }
    }

    if (Object.keys(response).length > 0) {
      this.callStatus = response;
      if (this.isCallCompleted()) {
        this.stopFetchCallStatusJob();
      }
    }
  };

  stopFetchCallStatusJob = () => {
    if (this.interval != null) {
      clearInterval(this.interval);
    }
  };

  initiateCall = async (leadID) => {
    if (this.outboundCallService === null) {
      return false;
    }
    let response = await this.outboundCallService.makeCall(leadID);

    if (response.hasOwnProperty("dialer_log_id")) {
      let projectID = rootStore.authStore.projectId;
      let userID = localStorage.getItem("uid");
      let dialerLogID = response["dialer_log_id"];
      this.setState({
        dialer_log_id: dialerLogID,
        project_id: projectID,
        lead_id: leadID,
        call_log_submitted: false,
        lead_owner_id: userID,
        call_type: "c2c",
      });

      let formData = {
        dialer_log_id: dialerLogID,
        project_id: projectID,
        lead_id: leadID,
      };
      this.dispositionFailed = false;
      await this.fetchCallStatus(formData);
      this.startFetchCallStatusJob(leadID, dialerLogID);
      return { status: "success" };
    } else {
      return { status: "failure", message: response.message };
    }
  };

  setCallDisposition = async (leadId, formData) => {
    let response = null;
    if (this.state["call_type"] === "progressive") {
      response = await this.inboundCallService.setCallDisposition(
        leadId,
        formData
      );
    } else {
      response = await this.outboundCallService.setCallDisposition(
        leadId,
        formData
      );
    }

    if (response.hasError()) {
      this.dispositionFailed = true;
    } else {
      this.dispositionFailed = false;
    }
    return response;
  };

  isUserLeadOwner = () => {
    let userID = localStorage.getItem("uid");

    if (this.state.hasOwnProperty("lead_owner_id")) {
      consoleLogger(
        `Phone state ::: lead owner id = ${this.state["lead_owner_id"]} ::: user id = ${userID}`
      );
      return this.state["lead_owner_id"] === userID;
    }
    return false;
  };

  getDialerLogIdForLead = (leadID) => {
    if (this.state.hasOwnProperty("lead_id")) {
      if (this.state["lead_id"] === leadID) {
        return this.state["dialer_log_id"];
      }
    }
    return "";
  };

  getCallTypeForLead = (leadID) => {
    if (this.state.hasOwnProperty("lead_id")) {
      if (this.state["lead_id"] === leadID) {
        return this.state["call_type"];
      }
    }
    return "";
  };

  setState = (value) => {
    this.state = value;
  };

  resetCallStatus = () => {
    this.callStatus = {};
  };

  postSubmitCallLog = () => {
    consoleLogger("Phone state ::: post submit calllog");
    this.setState({});
    this.callStatus = {};
    this.showProgressiveCallDialog = false;
    this.dispositionFailed = false;
    this.stopFetchCallStatusJob();
  };

  onProgressiveCallStart = async (data) => {
    let userID = localStorage.getItem("uid");
    let index = rootStore.authStore.projectList.findIndex(
      (element) => element["id"] === data["project_id"]
    );
    if (userID === data["user_id"] && index !== -1) {
      this.setState({
        dialer_log_id: data["dialer_log_id"],
        project_id: data["project_id"],
        lead_id: data["lead_id"],
        lead_owner_id: data["lead_owner_id"],
        call_log_submitted: false,
        call_type: "progressive",
      });
      this.dispositionFailed = false;
      let formData = {
        dialer_log_id: data["dialer_log_id"],
        project_id: data["project_id"],
        lead_id: data["lead_id"],
      };
      if (this.inboundCallService != null) {
        await this.fetchCallStatus(formData);
        this.enableProgressiveCallDialog();
      }
    }
  };
  onProgressiveCallEnd = async (data) => {
    let userID = localStorage.getItem("uid");
    let index = rootStore.authStore.projectList.findIndex(
      (element) => element["id"] === data["project_id"]
    );
    if (
      userID === data["user_id"] &&
      index !== -1 &&
      this.state.hasOwnProperty("dialer_log_id")
    ) {
      this.setState({
        dialer_log_id: data["dialer_log_id"],
        project_id: data["project_id"],
        lead_id: data["lead_id"],
        lead_owner_id: data["lead_owner_id"],
        call_log_submitted: false,
        call_type: "progressive",
      });

      let formData = {
        dialer_log_id: data["dialer_log_id"],
        project_id: data["project_id"],
        lead_id: data["lead_id"],
      };
      if (this.inboundCallService != null) {
        await this.fetchCallStatus(formData);
        this.enableProgressiveCallDialog();
      }
    }
  };

  enableProgressiveCallDialog = () => {
    this.showProgressiveCallDialog = true;
  };
  resetProgressiveCallDialog = () => {
    this.showProgressiveCallDialog = false;
  };

  reset = async () => {
    externalDialerNotificationService.close();
    this.inboundCallService.reset();
    this.outboundCallService.reset();
    this.state = {};
    this.userStatus = {};
    this.callStatus = {};
    this.inboundCallService = null;
    this.outboundCallService = null;
    this.interval = null;
    this.showProgressiveCallDialog = false;
    this.dispositionFailed = false;
  };
}

export default PhoneCallState;
