import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, styled, Typography } from "@mui/material";
import { getLocalizedText } from "../../Functions";
const MenuText = styled(Typography)`
  font: normal normal 500 12px Open Sans;
`;
const AddFacebookMetaMenuComponent = ({
  handleFbAdMetaImport,
}) => {
  const [anchorE1, setAnchorE1] = React.useState(null);
  const Open = Boolean(anchorE1);
  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE1(null);
  };

  return (
    <>
      <IconButton
        style={{ padding: "0px" }}
        aria-label="more"
        id="long-button"
        aria-controls={Open ? "long-menu" : undefined}
        aria-expanded={Open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        style={{ display: "flex", flexDirection: "column" }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorE1}
        open={Open}
        onClose={() => {
          handleClose();
        }}
        PaperProps={{
          style: {
            width: "fit-content",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleFbAdMetaImport();
            handleClose();
          }}
        >
          <MenuText>{getLocalizedText("import_fb_ad_meta")}</MenuText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AddFacebookMetaMenuComponent;
