import { Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { fbAppID, fbLoginConfigID } from "../../../Config";
import { updateFbLoginToken } from "../../../Api";
import ReplayIcon from "@mui/icons-material/Replay";

const FacebookBusinessReconnectButton = ({
  fbCreateConfigID,
  postReconnect,
}) => {
  useEffect(() => {
    const loadFbSdk = () => {
      window.fbAsyncInit = async function () {
        await window.FB.init({
          appId: fbAppID,
          cookie: true,
          xfbml: true,
          version: "v19.0",
        });
      };
      (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        const js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    };

    loadFbSdk();
  }, []);

  const statusChangeCallback = async (response) => {
    //console.log("statusChangeCallback", response);
    if (response.status === "connected") {
      console.log("Reauthenticating.");

      window.FB.login(
        (loginResponse) => {
          handleSendToken(loginResponse);
        },
        { config_id: fbLoginConfigID, auth_type: "reauthenticate" }
      );
    } else {
      console.log("Please log into this webpage.");

      window.FB.login(
        (loginResponse) => {
          handleSendToken(loginResponse);
        },
        { config_id: fbLoginConfigID }
      );
    }
  };

  const handleSendToken = async (loginResponse) => {
    const accessToken = loginResponse.authResponse?.accessToken;
    if (accessToken && fbCreateConfigID !== "") {
      const payload = {
        token: accessToken,
      };
      const serverResponse = await updateFbLoginToken({
        fbcc_id: fbCreateConfigID,
        payload: payload,
      });
      postReconnect();
    } else {
      console.log("Access token or fb conf id is missing.");
    }
  };

  const checkLoginState = () => {
    window.FB.getLoginStatus(async (response) => {
      await statusChangeCallback(response);
    });
  };

  return (
    <div>
      <Tooltip title="Reconnect">
        <ReplayIcon
          color="primary"
          sx={{
            fontSize: "18px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            checkLoginState();
          }}
        />
      </Tooltip>
    </div>
  );
};

export default FacebookBusinessReconnectButton;
