import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Modal } from "@material-ui/core";
import {
  Box,
  Button,
  Typography,
  Divider,
  AppBar,
  Toolbar,
} from "@mui/material";
import { consoleLogger, getLocalizedText } from "../../../Functions";
import ImportFileScreen from "./screens/ImportFileScreen";
import { LoadingButton } from "@mui/lab";
import Papa from "papaparse";
import ColumnMappingScreen from "./screens/ColumnMappingScreen";
import { addFacebookMetaApi } from "../../../Api";
import ResultScreen from "./screens/ResultScreen";
import MuiAlert from "@mui/material/Alert";
import { facebookAddMetaColumns } from "../../../Db";
import AddFacebookAdMetaProgressScreen from "./screens/AddFacebookAdMetaProgressScreen";
import { read, utils } from "xlsx";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 600px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin: 0px;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin: 0px;
  margin-top: 2px;
`;
const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const ImportFbAdMetaDialog = ({ open, handleClose, handleCloseResult }) => {
  const [showFileErrorMessage, setShowFileErrorMessage] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [formStep, setFormStep] = useState(0);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [uploadedFileColumns, setUploadedFileColumns] = useState([]);
  const [file, setFile] = useState(null);
  const [fileAndFacebookColumnMapping, setFileAndFacebookColumnMapping] =
    useState({});

  const [isImporting, setIsImporting] = useState(false);
  const [isImportDone, setIsImportDone] = useState(false);
  const [createProgressMessage, setCreateProgressMessage] = useState("");
  const [results, setResults] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handlePrevButton = () => {
    setFormStep(formStep - 1);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setShowFileErrorMessage(false);
      setFileErrorMessage("");
      setIsLoadingFile(true);
      const fileExtension = file.name.split(".").pop().toLowerCase(); // get file extension of uploaded file to parse based on file extension
      if (fileExtension === "csv") {
        let startTime = performance.now();
        Papa.parse(file, {
          header: false,
          skipEmptyLines: true,
          worker: true,
          complete: function (results) {
            let elapsedTime = (performance.now() - startTime) / 1000;
            consoleLogger(
              "Performance time for parsing csv:",
              elapsedTime,
              "s"
            );
            let rowCount = Array.isArray(results.data)
              ? results.data.length
              : 0;
            consoleLogger("ROW Count:", rowCount);
            if (rowCount === 0) {
              setFileErrorMessage(
                "Error: No data is present in uploaded file."
              );
              setShowFileErrorMessage(true);
            } else {
              if (rowCount - 1 > 1000) {
                // we can import upto 1000 entries
                setFileErrorMessage(
                  "Error: The number of rows has exceeded the allowed limit."
                );
                setShowFileErrorMessage(true);
              } else if (rowCount === 1) {
                // there is only one entry which can be used for column headers, and no entries present to import
                setFileErrorMessage(
                  "Import failed: Please ensure your import file contains facebook ad data."
                );
                setShowFileErrorMessage(true);
              } else {
                let headers = results.data[0];
                consoleLogger("CSV Rows:", headers);
              }
            }
            setUploadedFileColumns(results.data[0]);
            setFileData(results.data);
            setFile(file);
            setIsLoadingFile(false);
          },
        });
      } else if (fileExtension === "xls" || fileExtension === "xlsx") {
        // Handle Excel Parsing with XLSX
        const reader = new FileReader();
        reader.onload = (e) => {
          let startTime = performance.now(); // Start time tracking
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: "array" });

          const sheetName = workbook.SheetNames[0]; // Read first sheet
          const worksheet = workbook.Sheets[sheetName];

          const jsonData = utils.sheet_to_json(worksheet, { header: 1 }); //header:1 - to get array of arrays instead of array of objects (default)

          let elapsedTime = (performance.now() - startTime) / 1000; // Calculate elapsed time
          consoleLogger(
            "Performance time for parsing Excel:",
            elapsedTime,
            "s"
          );

          let rowCount = jsonData.length;
          consoleLogger("ROW Count:", rowCount);

          if (rowCount === 0) {
            setFileErrorMessage("Error: No data is present in uploaded file.");
            setShowFileErrorMessage(true);
          } else {
            if (rowCount - 1 > 1000) {
              setFileErrorMessage(
                "Error: The number of rows has exceeded the allowed limit."
              );
              setShowFileErrorMessage(true);
            } else if (rowCount === 1) {
              // there is only one entry which can be used for column headers, and no entries present to import
              setFileErrorMessage(
                "Import failed: Please ensure your import file contains facebook ad data."
              );
              setShowFileErrorMessage(true);
            } else {
              setUploadedFileColumns(jsonData[0]); // Headers
              setFileData(jsonData);
            }
          }
          setFile(file);
          setIsLoadingFile(false);
        };
        reader.readAsArrayBuffer(file);
      } else {
        setFileErrorMessage(
          "Unsupported file format. Please upload a CSV or Excel file."
        );
        setShowFileErrorMessage(true);
        setIsLoadingFile(false);
      }
    }
  };
  const getFileColumnHeaders = () => {
    let tempFileColumnHeaders = [];
    uploadedFileColumns.forEach((columnHeader) => {
      tempFileColumnHeaders.push({ label: columnHeader, key: columnHeader });
    });
    return tempFileColumnHeaders;
  };

  const generatePayloadData = ({ facebookData }) => {
    let tempPayload = {};

    for (const key in fileAndFacebookColumnMapping) {
      let mappedData = fileAndFacebookColumnMapping[key];
      if (mappedData["checked"] === true) {
        let index = facebookAddMetaColumns.findIndex(
          (column) =>
            column["column_name"] ===
            mappedData["facebook_column"]["column_name"]
        );

        if (index !== -1) {
          let columnMeta = facebookAddMetaColumns[index];
          if (facebookData.hasOwnProperty(key)) {
            let value = facebookData[key];
            if (value !== null && value !== "") {
              value = String(value); // Ensure value is always a string
              
              // Check for colon delimiter and extract second part if present
              if (value.includes(":")) {
                let parts = value.split(":");
                value = parts.length > 1 ? parts[1].trim() : value; // Take second part
              }
            }
            tempPayload[columnMeta["column_name"]] = value;
          }
        }
      }
    }
    return tempPayload;
  };

  //below function is to check if all facebook columns are mapped or not
  const isAllFacebookColumnsMapped = () => {
    let allColumnList = facebookAddMetaColumns.map(
      (column) => column.column_name
    );

    // Get the mapped columns from fileAndFacebookColumnMapping where "checked" is true
    const checkedFileToFacebookMapping = Object.keys(
      fileAndFacebookColumnMapping
    )
      .filter((key) => fileAndFacebookColumnMapping[key].checked === true)
      .map(
        (key) => fileAndFacebookColumnMapping[key].facebook_column.column_name
      );

    // Check if every column in allColumnList exists in the checked mapping
    let flag = allColumnList.every((col) =>
      checkedFileToFacebookMapping.includes(col)
    );

    return flag;
  };
  const handleImport = async () => {
    let payloadDataList = [];
    let facebookEntriesFromFile = fileData.slice(1);
    let totalRows = facebookEntriesFromFile.length;
    setIsImporting(true);
    setCreateProgressMessage(`Creating (1/${totalRows}).`); // setting the initial create message
    facebookEntriesFromFile.forEach((entry) => {
      let facebookData = {};
      // here each entry is a list of value strings (from imported file)
      entry.forEach((value, index) => {
        facebookData[uploadedFileColumns[index]] = value;
      });

      let payloadData = generatePayloadData({ facebookData: facebookData });
      payloadDataList.push(payloadData);
    });
    const responseList = [];
    for (let i = 0; i < payloadDataList.length; i++) {
      let payloadData = payloadDataList[i];
      let response = await addFacebookMetaApi({
        payload: payloadData,
      });
      let ad_name = payloadData.hasOwnProperty("ad_name")
        ? payloadData["ad_name"]
        : "";
      if (response.hasError()) {
        let result = {
          row_number: i + 1,
          name: ad_name,
          status: "failure",
          message: "Error adding facebook ad meta!",
        };
        responseList.push(result);
      } else {
        let result = {
          row_number: i + 1,
          name: ad_name,
          status: "success",
          message: "Facebook ad meta created successfully!",
        };
        responseList.push(result);
      }

      setCreateProgressMessage(
        `Creating (${i + 1}/${payloadDataList.length}).`
      );
    }
    setResults(responseList);
    setIsImportDone(true);
  };

  // functions to download sample csv
  const convertToASCII = (str) => {
    return str.replace(/[^\x00-\x7F]/g, "");
  };

  const convertArrayToCSV = (array) => {
    // Convert the array to a single row CSV
    return array.map((item) => convertToASCII(item)).join(",");
  };

  const handleDownloadCSV = () => {
    const columnHeaderList = facebookAddMetaColumns.map(
      (entry) => entry["column_name"]
    );
    const csv = convertArrayToCSV(columnHeaderList);
    const blob = new Blob([csv], { type: "text/plain;charset=us-ascii;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", "sample.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderFormSection = () => {
    if (formStep === 0) {
      return (
        <ImportFileScreen
          handleFileUpload={(event) => {
            handleFileUpload(event);
          }}
          file={file}
          fileErrorMessage={fileErrorMessage}
          showFileErrorMessage={showFileErrorMessage}
        />
      );
    } else if (formStep === 1) {
      if (!isImporting) {
        return (
          <ColumnMappingScreen
            fileColumnHeaders={getFileColumnHeaders()}
            fileAndFacebookColumnMapping={fileAndFacebookColumnMapping}
            setFileAndFacebookColumnMapping={setFileAndFacebookColumnMapping}
          />
        );
      } else {
        if (!isImportDone) {
          return (
            <AddFacebookAdMetaProgressScreen
              loadingMessage={createProgressMessage}
              noteMessage={
                "Please do not refresh or leave this tab while your data is being imported."
              }
            />
          );
        } else {
          return <ResultScreen data={results} />;
        }
      }
    }
  };

  const RenderButtons = () => {
    if (isImportDone) {
      return (
        <Button
          onClick={handleCloseResult}
          type="button"
          variant="contained"
          color="inherit"
          style={{
            textTransform: "none",

            fontSize: "12px",
            fontWeight: "bold",
            width: "100px",
          }}
        >
          {getLocalizedText("close")}
        </Button>
      );
    } else {
      if (!isImporting) {
        if (formStep === 0) {
          return (
            <>
              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="inherit"
                style={{
                  textTransform: "none",

                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("cancel")}
              </Button>
              {isLoadingFile ? (
                <LoadingButton
                  loading
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",
                    width: "100px",
                  }}
                >
                  <span> {getLocalizedText("next")}</span>
                </LoadingButton>
              ) : (
                <Button
                  disabled={showFileErrorMessage === true || file === null}
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",

                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100px",
                  }}
                >
                  {getLocalizedText("next")}
                </Button>
              )}
            </>
          );
        } else if (formStep === 1) {
          return (
            <>
              <Button
                onClick={handlePrevButton}
                type="button"
                variant="contained"
                color="inherit"
                style={{
                  textTransform: "none",

                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("prev")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",

                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("import")}
              </Button>
            </>
          );
        }
      }
    }
  };

  return (
    <>
      <Modal
        open={open}
        disableEscapeKeyDown={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Container
            component={"form"}
            onSubmit={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (formStep < 1) {
                setFormStep(formStep + 1);
              } else {
                if (isAllFacebookColumnsMapped()) {
                  handleImport();
                } else {
                  setErrorMessage(
                    getLocalizedText(
                      "all_fields_for_facebook_ad_meta_creation_are_not_mapped"
                    )
                  );
                  setShowErrorMessage(true);
                }
              }
            }}
          >
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>{getLocalizedText("import_fb_ad_meta")}</Header>
                  <SubHeader>
                    {getLocalizedText("fill_in_the_below_details")}
                  </SubHeader>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>

            <Divider style={{ margin: "16px", marginTop: "0px" }} />
            <Box
              style={{
                height: "100%",
                height: "100%",
                padding: "0px 16px",
              }}
            >
              {renderFormSection()}
            </Box>

            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{
                top: "auto",
                bottom: 0,
                width: "100%",
                borderRadius: "0px 0px 8px 8px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: "100%",
                  position: "relative",
                }}
              >
                {showErrorMessage && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setShowErrorMessage(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <Grid container style={{ marginTop: "20px" }}>
                  <Grid item xs={6}>
                    {formStep === 0 && (
                      <Button
                        onClick={() => {
                          handleDownloadCSV();
                        }}
                        variant="text"
                        color="primary"
                        style={{
                          font: "normal normal 600 12px Open Sans",
                          textTransform: "none",
                        }}
                      >
                        {getLocalizedText("download_sample_csv")}
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "flex-end",
                        gap: "16px",
                      }}
                    >
                      <RenderButtons />
                    </Box>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Container>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default ImportFbAdMetaDialog;
