import React, { useState } from "react";
import { styled } from "@mui/system";
import {
  FormControl,
  makeStyles,
  Select,
  Box,
  IconButton,
  Tooltip,
  Typography,
  TextField,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { observer } from "mobx-react-lite";
import PreviewIcon from "@mui/icons-material/Preview";
import { getLocalizedText } from "../../Functions";
import WhatsappTemplatePreview from "./WhatsappTemplatePreview";
const InputContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

const InputWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "50%",
});

const Label = styled(Typography)({
  fontSize: 12,
  fontWeight: 500,
  fontFamily: "Open Sans, sans-serif",
  color: "#4d4e4f",
  marginBottom: 6,
});

const Input = styled(TextField)(({ theme }) => ({
  width: 428,
  marginBottom: 20,
  "& .MuiInputBase-root": {
    height: 30,
    padding: "4px 12px",
    color: "#4d4e4f",
    border: "1px solid #bfbfbf",
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Open Sans, sans-serif",
  },
  '& input[type="number"]': {
    MozAppearance: "textfield",
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
  "& input::placeholder": {
    fontSize: 12,
    fontWeight: 500,
    color: "#e1e1e1",
  },
  [theme.breakpoints.down("sm")]: {
    width: 280,
  },
}));

const SelectColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: 200,
  [theme.breakpoints.down("sm")]: {
    width: 280,
  },
}));

const Row = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: 20,
  columnGap: 10,
});

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const WhatsappCampaign = (props) => {
  const classes = useStyles();
  const [templateList, setTemplateList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);

  React.useEffect(() => {
    if (
      props.providerData.hasOwnProperty("id") &&
      props.providerData.id !== ""
    ) {
      setup();
    }
  }, [props.providerData, props.isCsvSegment]);

  const setup = () => {
    let filteredTemplates = [];
    if (props.isCsvSegment) {
      filteredTemplates = props.metaTemplates.filter(
        (item) =>
          item["status"] === "APPROVED" && item["category"] === "MARKETING" //only showing marketing templates
      );
    } else {
      filteredTemplates = props.metaTemplates.filter(
        (item) =>
          item.project !== null &&
          item.project !== "" &&
          item.project.id === props.providerData.project.id && // filter by provider project when segment is there
          item["status"] === "APPROVED" &&
          item["category"] === "MARKETING" //only showing marketing templates
      );
    }

    setTemplateList(filteredTemplates);
  };

  const handleVariable = (e) => {
    props.setVariables({ ...props.variables, [e.target.name]: e.target.value });
  };

  const handleTemplateVariablesSubmit = (payload) => {
    props.setWhatsappVariablePayload(payload);
    props.setWhatsappVariablesFilled(true);
  };

  const handleChange = (e) => {
    props.setSelectedWhatsappTemplate(e.target.value);

    //reset selected variable data and payload data
    props.setVariableDetails([]);
    props.setHeaderVariableField({});
    props.setSelectedFile(null);
    props.setWhatsappVariablesFilled(false);
    props.setWhatsappVariablePayload({});
  };

  const shouldDisableWhatsappPreview = () => {
    return (
      props.selectedWhatsappTemplate === null ||
      !props.selectedWhatsappTemplate.hasOwnProperty("components")
    );
  };

  return (
    <>
      <InputContainer>
        <SelectColumn style={{ width: "100%" }}>
          <Label>Select Whatsapp Template*</Label>
          <Row>
            <FormControl
              style={{ width: "100%" }}
              classes={{
                root: classes.quantityRoot,
              }}
            >
              <Select
                classes={{ icon: classes.icon }}
                name="whatsapp_template"
                displayEmpty
                disableUnderline
                variant="outlined"
                required
                onChange={handleChange}
                value={props.selectedWhatsappTemplate || ""}
                style={{
                  width: "100%",
                  height: "30px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "0px",
                  paddingRight: "40px",
                }}
              >
                {templateList.length === 0 ? (
                  <MenuItem disabled>
                    {getLocalizedText("no_templates_found")}
                  </MenuItem>
                ) : (
                  templateList.map((e) => (
                    <MenuItem key={e.id} value={e}>
                      {e.template_title}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
            <Tooltip title="Preview">
              <PreviewIcon
                onClick={() => {
                  if (shouldDisableWhatsappPreview()) {
                    return;
                  }
                  setPreviewOpen(true);
                }}
                color={"primary"}
                style={{
                  cursor: shouldDisableWhatsappPreview()
                    ? "not-allowed"
                    : "pointer",
                  pointerEvents: shouldDisableWhatsappPreview()
                    ? "none"
                    : "auto",
                  opacity: shouldDisableWhatsappPreview() ? 0.5 : 1,
                }}
              />
            </Tooltip>
          </Row>
        </SelectColumn>
        {previewOpen && (
          <WhatsappTemplatePreview
            open={previewOpen}
            setOpen={setPreviewOpen}
            providerData={props.providerData}
            selectedWhatsappTemplate={props.selectedWhatsappTemplate}
            onSubmitCallback={handleTemplateVariablesSubmit}
            setVariableDetails={props.setVariableDetails}
            variableDetails={props.variableDetails}
            setHeaderVariableField={props.setHeaderVariableField}
            headerVariableField={props.headerVariableField}
            setSelectedFile={props.setSelectedFile}
            selectedFile={props.selectedFile}
          />
        )}
      </InputContainer>
    </>
  );
};

export default observer(WhatsappCampaign);
