import { AppBar, Box, Button, Grid, Modal, Toolbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { consoleLogger, getLocalizedText } from "../../../Functions";
import MuiAlert from "@mui/material/Alert";
import DelhiveryFirstScreen from "../delhivery_mapping_screens/DelhiveryFirstScreen";
import ColumnMappingScreen from "./indiamart_mapping_screens/ColumnMappingScreen";
import CustomConfirmationDialog from "../../alert_dialogue/CustomConfirmationDialog";
import {
  createDelhiveryConfigApi,
  updateDelhiveryConfigApi,
} from "../../../Api";
import { LoadingButton } from "@mui/lab";

const dialogWidth = 600;
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  height: "fit-content",
  width: "fit-content",
  border: "none",
  outline: "none",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px #0000000d, 0px 3px 20px #185dd21f",
  border: "1px solid #c5d7f1",
  borderRadius: "8px",
  overflow: "hidden",
}));

const ViewDelhiveryIntegrationConfigDialog = ({
  open,
  setOpen,
  formData,
  integrationData,
  postSubmitCallback,
}) => {
  const [projectColumnList, setProjectColumnList] = useState([]);
  const [formQuestionList, setFormQuestionsList] = useState([]);
  const [orderCreationColumnMapping, setOrderCreationColumnMapping] = useState(
    {}
  );
  const [orderResponseColumnMapping, setOrderResponseColumnMapping] = useState(
    {}
  );
  const [orderStatusColumnMapping, setOrderStatusColumnMapping] = useState({});
  const [selectedProjectID, setSelectedProjectID] = useState("");
  const [details, setDetails] = useState({});
  const [formStep, setFormStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [loadingOrderCreation, setLoadingOrderCreation] = useState(false);
  const [loadingOrderResponse, setLoadingOrderResponse] = useState(false);
  const [loadingOrderStatus, setLoadingOrderStatus] = useState(false);

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const isAtleastOneColumnMapped = (mapping) => {
    let flag = false;
    Object.keys(mapping).forEach((item) => {
      if (mapping[item]["checked"] === true) {
        flag = true;
      }
    });
    return flag;
  };

  const areAllColumnsMapped = (mapping) => {
    return Object.keys(mapping).every(
      (item) =>
        mapping[item]?.checked === true &&
        mapping[item]?.project_lead &&
        Object.keys(mapping[item].project_lead).length > 0
    );
  };

  const handleConfirmation = async () => {
    setIsFormSubmitting(true);
    setShowConfirmationDialog(false);
    let payload = {
      name: details.name,
      account_name: details.account_name,
      project_id: selectedProjectID,
      token: details.token,
      ep: details.endpoint,
    };
    let tempCreationList = {};
    Object.keys(orderCreationColumnMapping).forEach((key) => {
      if (orderCreationColumnMapping[key]["checked"]) {
        tempCreationList[key] =
          orderCreationColumnMapping[key]["project_lead"]["id"];
      }
    });
    payload["order_request_mapping"] = tempCreationList;

    let tempResponseList = {};
    Object.keys(orderResponseColumnMapping).forEach((key) => {
      if (orderResponseColumnMapping[key]["checked"]) {
        tempResponseList[key] =
          orderResponseColumnMapping[key]["project_lead"]["id"];
      }
    });
    payload["order_response_mapping"] = tempResponseList;

    let tempStatusList = {};
    Object.keys(orderStatusColumnMapping).forEach((key) => {
      if (orderStatusColumnMapping[key]["checked"]) {
        tempStatusList[key] =
          orderStatusColumnMapping[key]["project_lead"]["id"];
      }
    });
    payload["order_status_mapping"] = tempStatusList;

    let response = {};

    if (Object.keys(formData).length === 0) {
      response = await createDelhiveryConfigApi({ payload: payload });
    } else {
      response = await updateDelhiveryConfigApi({
        payload: payload,
        configID: formData["id"],
      });
    }

    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setIsSubmitFail(false);
      postSubmitCallback();
    }
    setIsFormSubmitting(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //order status column mapping
    if (formStep === 3) {
      if (isAtleastOneColumnMapped(orderStatusColumnMapping)) {
        setIsSubmitFail(false);
        setShowConfirmationDialog(true);
      } else {
        setErrorMessage(
          getLocalizedText("please_map_atleast_one_column_to_proceed")
        );
        setIsSubmitFail(true);
      }
      //order response column mapping
    } else if (formStep === 2) {
      if (isAtleastOneColumnMapped(orderResponseColumnMapping)) {
        setFormStep(formStep + 1);
        setIsSubmitFail(false);
      } else {
        setErrorMessage(
          getLocalizedText("please_map_atleast_one_column_to_proceed")
        );
        setIsSubmitFail(true);
      }
    } else if (formStep === 1) {
      if (areAllColumnsMapped(orderCreationColumnMapping)) {
        setFormStep(formStep + 1);
        setIsSubmitFail(false);
      } else {
        setErrorMessage(
          getLocalizedText("please_map_all_the_required_columns_to_proceed")
        );
        setIsSubmitFail(true);
      }
    } else {
      setFormStep(formStep + 1);
      setIsSubmitFail(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const RenderButton = () => {
    if (formStep === 0) {
      return (
        <>
          <Grid item>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              variant="contained"
              color="default"
              style={{
                fontSize: "12px",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              {getLocalizedText("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                width: "fit-content",
                textTransform: "none",
              }}
            >
              Next
            </Button>
          </Grid>
        </>
      );
    } else if (formStep < 3) {
      return (
        <>
          <Grid item>
            <Button
              onClick={() => {
                setFormStep(formStep - 1);
              }}
              variant="contained"
              color="default"
              style={{
                fontSize: "12px",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              Prev
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={
                loadingOrderCreation ||
                loadingOrderStatus ||
                loadingOrderResponse
              }
              type="submit"
              variant="outlined"
              color="primary"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                width: "fit-content",
                textTransform: "none",
              }}
            >
              Next
            </Button>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid item>
            <Button
              onClick={() => {
                setFormStep(formStep - 1);
              }}
              disabled={isFormSubmitting}
              variant="contained"
              color="default"
              style={{
                fontSize: "12px",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              Prev
            </Button>
          </Grid>
          <Grid item>
            {isFormSubmitting ? (
              <LoadingButton
                loading
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                  width: "fit-content",
                }}
              >
                <span> {getLocalizedText("save")}</span>
              </LoadingButton>
            ) : (
              <Button
                disabled={
                  loadingOrderCreation ||
                  loadingOrderStatus ||
                  loadingOrderResponse
                }
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "fit-content",
                  textTransform: "none",
                }}
              >
                Save
              </Button>
            )}
          </Grid>
        </>
      );
    }
  };

  const init = async () => {
    if (Object.keys(formData).length > 0) {
      if (formData.hasOwnProperty("project_id")) {
        setSelectedProjectID(formData.project_id);
      }
      const updatedDetails = {};

      if (formData.hasOwnProperty("ep") && formData["ep"] !== null) {
        updatedDetails.endpoint = formData.ep;
      }

      if (
        formData.hasOwnProperty("account_name") &&
        formData["account_name"] !== null
      ) {
        updatedDetails.account_name = formData.account_name;
      }

      if (
        formData.hasOwnProperty("name") &&
        formData["name"] !== null
      ) {
        updatedDetails.name = formData.name;
      }


      if (Object.keys(updatedDetails).length > 0) {
        setDetails((prevDetails) => ({
          ...prevDetails,
          ...updatedDetails,
        }));
      }
    }
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box component={"form"} onSubmit={handleSubmit}>
            {(() => {
              if (formStep === 0) {
                return (
                  <DelhiveryFirstScreen
                    details={details}
                    selectedProjectID={selectedProjectID}
                    setSelectedProjectID={setSelectedProjectID}
                    handleChange={(e) => {
                      handleChange(e);
                    }}
                    handleProject={(value) => {
                      setSelectedProjectID(value);
                    }}
                  />
                );
              } else if (formStep === 1) {
                return (
                  <ColumnMappingScreen
                    key={`order-creation-${selectedProjectID}`}
                    projectID={selectedProjectID}
                    formData={formData}
                    projectColumnList={projectColumnList}
                    setProjectColumnList={setProjectColumnList}
                    formQuestionList={formQuestionList}
                    setFormQuestionsList={setFormQuestionsList}
                    formAndProjectColumnMapping={orderCreationColumnMapping}
                    setFormAndProjectColumnMapping={
                      setOrderCreationColumnMapping
                    }
                    type={integrationData.type}
                    loading={loadingOrderCreation}
                    setLoading={setLoadingOrderCreation}
                    title={"Map Columns for Order Creation"}
                    mappingKey="order_request_mapping"
                  />
                );
              } else if (formStep === 2) {
                return (
                  <ColumnMappingScreen
                    key={`order-response-${selectedProjectID}`}
                    projectID={selectedProjectID}
                    formData={formData}
                    projectColumnList={projectColumnList}
                    setProjectColumnList={setProjectColumnList}
                    formQuestionList={formQuestionList}
                    setFormQuestionsList={setFormQuestionsList}
                    formAndProjectColumnMapping={orderResponseColumnMapping}
                    setFormAndProjectColumnMapping={
                      setOrderResponseColumnMapping
                    }
                    type={integrationData.type}
                    loading={loadingOrderResponse}
                    setLoading={setLoadingOrderResponse}
                    title={"Map Columns for Order Response"}
                    mappingKey="order_response_mapping"
                  />
                );
              } else {
                return (
                  <ColumnMappingScreen
                    key={`order-status-${selectedProjectID}`}
                    projectID={selectedProjectID}
                    formData={formData}
                    projectColumnList={projectColumnList}
                    setProjectColumnList={setProjectColumnList}
                    formQuestionList={formQuestionList}
                    setFormQuestionsList={setFormQuestionsList}
                    formAndProjectColumnMapping={orderStatusColumnMapping}
                    setFormAndProjectColumnMapping={setOrderStatusColumnMapping}
                    type={integrationData.type}
                    loading={loadingOrderStatus}
                    setLoading={setLoadingOrderStatus}
                    title={"Map columns for Order Status Response"}
                    mappingKey="order_status_mapping"
                  />
                );
              }
            })()}

            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: "100%" }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <Grid container spacing={2} justifyContent="flex-end">
                  <RenderButton />
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        </ModelContainer>
      </Modal>
      {showConfirmationDialog && (
        <CustomConfirmationDialog
          handleConfirm={handleConfirmation}
          open={showConfirmationDialog}
          setOpen={setShowConfirmationDialog}
          message={"Are you sure you want to save the form changes?"}
        />
      )}
    </>
  );
};

export default ViewDelhiveryIntegrationConfigDialog;
