import {
  Box,
  Button,
  InputLabel,
  Modal,
  Select,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  leadExternalPartnerAssignApi,
  getFilteredPartnersApi,
} from "../../Api";
import {
  MenuItem,
  FormControl,
  Radio,
  Divider,
  Avatar,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { consoleLogger, getLocalizedText } from "../../Functions";
import { Typography } from "antd";
import profile_image from "../../assets/profile_placeholder.png";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ExternalOwnerFilterModal from "../lead_external_owner_assign/ExternalOwnerFilterModal";

const ModelContainer = styled(Box)({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  height: "fit-content",
  width: "fit-content",
  border: "none",
  outline: "none",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px #0000000d",
  borderRadius: "10px",
  padding: "30px",
});

const ModelWrapper = styled(Box)({
  width: "420px",
  margin: "auto",
  display: "flex",
  position: "relative",
  flexDirection: "column",
});

const Header = styled(Typography)({
  margin: 0,
  fontWeight: 600,
  fontSize: "18px",
  fontFamily: "Open Sans",
});

const SubHeader = styled(Typography)({
  margin: 0,
  fontWeight: 500,
  fontSize: "14px",
  fontFamily: "Open Sans",
  color: "#5a5a5a",
});

const ButtonWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  width: "100%",
  gap: "10px",
});

const ProfileImage = styled("img")({
  aspectRatio: "1 / 1",
  width: "100%",
  height: "auto",
  borderRadius: "50%",
  objectFit: "cover",
});

const ImageWrapper = styled(Avatar)({
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid #a7a6a8",
  padding: 0,
  marginRight: "8px",
  position: "relative",
  backgroundColor: "white",
});

const InputWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const LeadExternalOwnerAssignModal = (props) => {
  const [externalUsers, setExternalUsers] = useState([]);

  const [externalUserID, setExternalUserID] = useState("");
  const [comments, setComments] = useState("");
  const [filterPopupOpen, setFilterPopupOpen] = useState(false);
  const [partnerTypeFilter, setPartnerTypeFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");
  const [stateFilter, setStateFilter] = useState("");
  const [specilitiesFilterList, setSpecialitiesFilterList] = useState([]);
  const [filterQuery, setFilterQuery] = useState({});

  const handleChange = (event) => {
    setExternalUserID(event.target.value);
  };

  const handleAssign = async () => {
    document.getElementById("assign-btn").innerText = "Loading...";
    let payload = {
      external_owner_id: externalUserID,
      comments: comments,
    };

    let response = await leadExternalPartnerAssignApi({
      payload: payload,
      leadID: props.leadID,
    });
    if (response.hasError()) {
      console.log(response.error);
    } else {
      document.getElementById("assign-btn").style.backgroundColor = "green";
      document.getElementById("assign-btn").innerText = "Assigned";
      setTimeout(() => {
        props.setRefresh(!props.refresh);
        props.setOpen(false);
      }, [800]);
    }
  };

  const handleFilterSubmit = async (
    partnerFilterString,
    nameFilterString,
    cityFilterString,
    stateFilterString,
    specialtiesList
  ) => {
    setExternalUserID("");
    setPartnerTypeFilter(partnerFilterString);
    setNameFilter(nameFilterString);
    setCityFilter(cityFilterString);
    setStateFilter(stateFilterString);
    setSpecialitiesFilterList(specialtiesList);
    let query = {};
    if (partnerFilterString.length > 0) {
      query["partner_type"] = {
        value: partnerFilterString,
        op: "equals",
      };
    }
    if (nameFilterString !== "") {
      query["name"] = {
        value: nameFilterString,
        op: "equals",
      };
    }
    if (cityFilterString !== "") {
      query["city"] = {
        value: cityFilterString,
        op: "equals",
      };
    }
    if (stateFilterString !== "") {
      query["state"] = {
        value: stateFilterString,
        op: "equals",
      };
    }
    if (specialtiesList.length > 0) {
      query["specialities"] = {
        value: specialtiesList,
        op: "in",
      };
    }

    setFilterQuery(query);
  };

  const getPartnersList = async (query) => {
    let response = await getFilteredPartnersApi({
      payload: { query: query },
    });
    setExternalUsers(response);
  };
  const init = async () => {
    getPartnersList(filterQuery);
  };

  useEffect(() => {
    init();
  }, [filterQuery]);

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <ModelWrapper>
              <Header>{getLocalizedText("assign_external_owner")}</Header>
              <SubHeader>
                {getLocalizedText("select_from_below_dropdown")}
              </SubHeader>
              <Divider style={{ margin: "10px 0px" }} />
              <InputLabel
                id="demo-simple-select-label"
                style={{
                  font: "normal normal 600 10px Open Sans",
                  color: "#4d4e4f",
                  marginBottom: "4px",
                }}
              >
                {getLocalizedText("select_external_user")}
              </InputLabel>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <FormControl
                  sx={{
                    width: "100%",
                  }}
                >
                  <Select
                    variant="outlined"
                    value={externalUserID}
                    renderValue={(value) =>
                      externalUsers.find((x) => x.id === value).name
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      handleChange(e);
                    }}
                    style={{
                      width: "100%",
                      font: "normal normal 500 12px Open Sans",
                      height: "30px",
                    }}
                  >
                    {externalUsers.map((e) => {
                      return (
                        <MenuItem
                          component={Paper}
                          id={e.id}
                          value={e.id}
                          sx={{
                            margin: "1px",
                            marginBottom: "4px",
                            padding: 1,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Radio
                            checked={e.id === externalUserID}
                            onChange={(e) => {
                              e.stopPropagation();
                            }}
                            value={externalUserID}
                            name="radio-buttons"
                          />
                          <ImageWrapper>
                            <ProfileImage
                              src={
                                e.profile_pic != null
                                  ? e.profile_pic
                                  : profile_image
                              }
                              alt="loading..."
                            />
                          </ImageWrapper>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              style={{
                                width: "fit-content",
                                fontSize: "14px",
                                fontWeight: "500",
                                textAlign: "left",
                              }}
                            >
                              {e.name}
                            </Typography>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                style={{
                                  width: "fit-content",
                                  fontSize: "12px",
                                  textAlign: "left",
                                  color: "#b7b7b7",
                                }}
                              >
                                Partner Type: {e.partner_type}
                              </Typography>
                            </Box>
                            <Box></Box>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <IconButton
                  size="medium"
                  onClick={() => {
                    setFilterPopupOpen(true);
                  }}
                  style={{
                    padding: "0px",
                    marginLeft: "4px",
                  }}
                >
                  <FilterAltIcon color="primary" />
                </IconButton>
              </Box>
              <Box
                sx={{
                  marginBottom: "20px",
                }}
              >
                <InputWrapper>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{
                      font: "normal normal 600 10px Open Sans",
                      color: "#4d4e4f",
                    }}
                  >
                    Comments*
                  </InputLabel>
                  <OutlinedInput
                    multiline={true}
                    required
                    rows={3}
                    inputProps={{
                      min: 0,
                      "aria-label": "weight",
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    value={comments}
                    name={"comments"}
                    type={"text"}
                    placeholder={"Enter Value"}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </InputWrapper>
              </Box>
              <ButtonWrapper>
                <Button
                  onClick={() => {
                    props.setOpen(false);
                  }}
                  variant="contained"
                  color="default"
                  style={{
                    width: "100%",
                    font: "normal normal 600 10px Open Sans",
                    textTransform: "none",
                    minHeight: "26px",
                  }}
                >
                  {getLocalizedText("cancel")}
                </Button>
                <Button
                  id="assign-btn"
                  onClick={handleAssign}
                  variant="contained"
                  color="primary"
                  disabled={externalUserID === "" || comments === ""}
                  style={{
                    font: "normal normal 600 10px Open Sans",
                    width: "100%",
                    textTransform: "none",
                    minHeight: "26px",
                  }}
                >
                  {getLocalizedText("assign")}
                </Button>
              </ButtonWrapper>
            </ModelWrapper>
          </Box>
        </ModelContainer>
      </Modal>
      {filterPopupOpen && (
        <ExternalOwnerFilterModal
          open={filterPopupOpen}
          setOpen={setFilterPopupOpen}
          handleSubmit={handleFilterSubmit}
          partnerTypeFilter={partnerTypeFilter}
          nameFilter={nameFilter}
          cityFilter={cityFilter}
          stateFilter={stateFilter}
          specilitiesFilterList={specilitiesFilterList}
        />
      )}
    </>
  );
};

export default LeadExternalOwnerAssignModal;
