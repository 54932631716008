import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Box, Chip, Grid, Toolbar, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import rootStore from "../../stores/RootStore";
import { dataRenderer } from "../../Functions";
import ColumnInfoCard from "./cards/ColumnInfoCard";
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 16px;
  gap: 16px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ViewCreateLeadSectionComponent = ({ details, columnsList }) => {
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const init = async () => {
    let existingSelectedColumns =
      details.hasOwnProperty("columns") && details["columns"] !== null
        ? details["columns"]
        : [];

    let tempSelectedColumns = [];
    existingSelectedColumns.forEach((entry) => {
      let index = columnsList.findIndex(
        (column) => column["id"] === entry["column_meta_id"]
      );
      if (index !== -1) {
        tempSelectedColumns.push({
          label: columnsList[index]["headerName"],
          value: columnsList[index]["id"],
        });
      }
    });
    setSelectedColumns(tempSelectedColumns);
  };

  useEffect(() => {
    init();
  }, []);

  // check if the column is a required field
  const isColumnRequiredField = (columnID) => {
    let index = columnsList.findIndex((column) => column["id"] === columnID);
    if (index !== -1) {
      return columnsList[index]["required"];
    }
    return false;
  };

  return (
    <Box role="presentation">
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar style={{ padding: "16px" }}>
          <ModelHeader>Section Details</ModelHeader>
        </Toolbar>
      </AppBar>
      <ModelWrapper component={"form"} onSubmit={handleSubmit}>
        <InputContainer>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputWrapper>
                <Label>Name*</Label>
                <Detail>{dataRenderer(details["name"])}</Detail>
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper>
                <Label>Columns</Label>
                <Box sx={{ width: "100%" }}>
                  {selectedColumns.length > 0 ? (
                    selectedColumns.length > 0 && (
                      <Grid
                        container
                        spacing={1}
                        style={{
                          marginBottom: "8px",
                        }}
                      >
                        {selectedColumns.map((entry, index) => {
                          return (
                            <Grid item key={index} xs={12}>
                              <ColumnInfoCard
                                columnData={entry}
                                isRequiredField={isColumnRequiredField(
                                  entry["value"]
                                )}
                                onRemove={() => {}}
                                canRemove={false}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    )
                  ) : (
                    <Detail>-</Detail>
                  )}
                </Box>
              </InputWrapper>
            </Grid>
          </Grid>
        </InputContainer>
      </ModelWrapper>
    </Box>
  );
};

export default observer(ViewCreateLeadSectionComponent);
