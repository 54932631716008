import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import { getLocalTimeFromIsoString } from "../../Functions";

const ModelWrapper = styled(Box)(({ theme }) => ({
  width: "500px",
  margin: "auto",
  display: "flex",
  position: "relative",
  /* padding: 30px, */
  flexDirection: "column",
  height: "95vh",
  paddingTop: "0px",
}));
const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "30px 0px 30px 16px",
  marginBottom: "20px",
  backgroundColor: "#f9f9fc",
}));

const ModelHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  variant: "span",
  color: "#4d4e4f",
}));

const Title = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 14px Open Sans",
  color: "#5A606680",
}));
const Detail = styled(Typography)(({ theme }) => ({
  font: "normal normal 500 14px Open Sans",
  color: "#5A6066",
  wordWrap: "break-word",
}));

const DetailLink = styled("a")(({ theme }) => ({
  font: "normal normal 500 14px Open Sans",
  color: "#5A6066",
  wordWrap: "break-word",
  margin: "0px",
  color: "#3f51b5",
  cursor: "pointer",
  textDecoration: "none",
}));

const Column = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
  padding: "0px 16px 0px 16px",
}));

const CallSummaryPanel = (props) => {
  const RenderValue = ({ value }) => {
    if (value !== undefined && value !== null && value !== "") {
      let parsedValue = value.toString();
      if (parsedValue.startsWith("https:") || parsedValue.startsWith("http:")) {
        return (
          <DetailLink href={parsedValue} target="_blank">
            {value}
          </DetailLink>
        );
      } else {
        return <Detail>{parsedValue}</Detail>;
      }
    }
    return <Detail>-</Detail>;
  };

  const BuildInfo = () => {
    let summary = props.data["summary_response"] ?? {};
    if (props.data["dialer"] === "leadpixie_dialer") {
      return (
        <Column>
          <Box key={0}>
            <Box
              style={{
                marginTop: "8px",
                marginBottom: "8px",
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={4}>
                  <Title>{"call_start_time"}</Title>
                </Grid>
                <Grid item xs={1}>
                  <Divider
                    orientation="vertical"
                    sx={{ height: "20px", color: "#3535354D" }}
                  />
                </Grid>
                <Grid item xs={7} paddingLeft={"8px"}>
                  <RenderValue
                    value={getLocalTimeFromIsoString(
                      summary["call_start_time"]
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider orientation="horizontal" />
          </Box>
          <Box key={1}>
            <Box
              style={{
                marginTop: "8px",
                marginBottom: "8px",
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={4}>
                  <Title>{"call_end_time"}</Title>
                </Grid>
                <Grid item xs={1}>
                  <Divider
                    orientation="vertical"
                    sx={{ height: "20px", color: "#3535354D" }}
                  />
                </Grid>
                <Grid item xs={7} paddingLeft={"8px"}>
                  <RenderValue
                    value={getLocalTimeFromIsoString(summary["call_end_time"])}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider orientation="horizontal" />
          </Box>
          <Box key={2}>
            <Box
              style={{
                marginTop: "8px",
                marginBottom: "8px",
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={4}>
                  <Title>{"call_duration"}</Title>
                </Grid>
                <Grid item xs={1}>
                  <Divider
                    orientation="vertical"
                    sx={{ height: "20px", color: "#3535354D" }}
                  />
                </Grid>
                <Grid item xs={7} paddingLeft={"8px"}>
                  <RenderValue value={summary["call_duration"]} />
                </Grid>
              </Grid>
            </Box>
            <Divider orientation="horizontal" />
          </Box>
          <Box key={3}>
            <Box
              style={{
                marginTop: "8px",
                marginBottom: "8px",
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={4}>
                  <Title>{"audio"}</Title>
                </Grid>
                <Grid item xs={1}>
                  <Divider
                    orientation="vertical"
                    sx={{ height: "20px", color: "#3535354D" }}
                  />
                </Grid>
                <Grid item xs={7} paddingLeft={"8px"}>
                  <RenderValue value={summary["audio"]} />
                </Grid>
              </Grid>
            </Box>
            <Divider orientation="horizontal" />
          </Box>
        </Column>
      );
    } else {
      return (
        <Column>
          {Object.keys(summary).map((key, i) => {
            return (
              <Box key={i}>
                <Box
                  style={{
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={4}>
                      <Title>{key}</Title>
                    </Grid>
                    <Grid item xs={1}>
                      <Divider
                        orientation="vertical"
                        sx={{ height: "20px", color: "#3535354D" }}
                      />
                    </Grid>
                    <Grid item xs={7} paddingLeft={"8px"}>
                      <RenderValue value={summary[key]} />
                    </Grid>
                  </Grid>
                </Box>
                <Divider orientation="horizontal" />
                {/* <Row>
              <TermHeading>{key}</TermHeading>
              <TermHeading>{props.data[key]}</TermHeading>
            </Row> */}
              </Box>
            );
          })}
        </Column>
      );
    }
  };
  return (
    <>
      <Box role="presentation">
        <ModelWrapper>
          <HeaderWrapper>
            <Info color="primary" style={{ marginRight: "8px" }} />
            <ModelHeader>Call Summary</ModelHeader>
          </HeaderWrapper>
          <BuildInfo />
        </ModelWrapper>
      </Box>
    </>
  );
};

export default CallSummaryPanel;
