class CallService {
  constructor() {
    if (new.target === CallService) {
      throw new TypeError("Cannot construct abstract instances directly");
    }
  }

  makeCall = async (leadID) => {
    throw new Error("You have to implement the method makeCall!");
  };
  fetchCallStatus = async (formData) => {
    throw new Error("You have to implement the method fetchCallStatus!");
  };
  setCallDisposition = async (formData) => {
    throw new Error("You have to implement the method setCallDisposition!");
  };
  reset = () => {};
}
export default CallService;
