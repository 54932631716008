import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@material-ui/core";
import {
  AppBar,
  Box,
  CircularProgress,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { editLeadInvoiceApi } from "../../Api";
import BillingInfo from "../add_quote_sidepanel/screens/BillingInfo";
import { v4 as uuidv4 } from "uuid";
import { consoleLogger, getLocalizedText } from "../../Functions";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  ${`height: calc(100vh - 128px);`};
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 24px;
  gap: 20px;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const EditInvoiceNonDraft = ({
  setOpen,
  activeDealDetails,
  leadID,
  setRefresh,
  existingInvoiceData,
  setInvoiceScope,
}) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [details, setDetails] = useState({
    title: "",
    invoice_date: null,
    expiry_date: null,
  });
  const [productAddedList, setProductAddedList] = useState([]);
  const [selectedQuoteID, setSelectedQuoteID] = useState("");
  const [loading, setLoading] = useState(true);
  const [amountDetails, setAmountDetails] = useState({
    sub_total: "",
    additional_discount: "",
    additional_tax: "",
    adjustments: "",
    grand_total: "",
  });
  const [billingDetails, setBillingDetails] = useState({
    billing_name: "",
    billing_address: "",
    billing_city: "",
    billing_state: "",
    billing_country: "",
    billing_zipcode: "",
    billing_gst: "",
    shipping_name: "",
    shipping_address: "",
    shipping_city: "",
    shipping_state: "",
    shipping_country: "",
    shipping_zipcode: "",
  });
  const [additionalDetails, setAdditionalDetails] = useState({
    terms_and_conditions: "",
    additional_information: "",
  });
  const [placeOfSupply, setPlaceOfSupply] = useState("");

  const handleClose = () => {
    setOpen(false);
    setInvoiceScope("");
  };
  const getValidIntegerValue = (value) => {
    if (value !== "" && value !== null) {
      return parseInt(value);
    }
    return "";
  };
  const getValidFloatValue = (value) => {
    if (value !== "" && value !== null) {
      const floatValue = parseFloat(value);
      return floatValue.toFixed(2);
    }
    return "";
  };

  const editInvoice = async (payload) => {
    let response = await editLeadInvoiceApi({
      payload: payload,
      leadID: leadID,
      invoiceID: existingInvoiceData["id"],
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const handleSubmit = async () => {
    let formData = {
      invoice_date: details.invoice_date,
      expiry_date: details.expiry_date,
      quotation_template_id: selectedQuoteID,
    };
    if (activeDealDetails.hasOwnProperty("id")) {
      formData["deal_id"] = activeDealDetails["id"];
    }
    let tempQuotationSchema = {
      invoice_title: details.title,
    };
    let itemList = [];

    productAddedList.forEach((productItem) => {
      let tempObj = {
        rate: getValidIntegerValue(productItem["rate"]),
        amount: getValidFloatValue(productItem["amount"]),
        quantity: getValidIntegerValue(productItem["quantity"]),
        discount: getValidIntegerValue(productItem["discount"]),
        tax: getValidIntegerValue(productItem["tax"]),
        desc: productItem["desc"],
      };
      tempObj["name"] = productItem["item"]["name"];
      itemList.push(tempObj);
    });
    tempQuotationSchema["items"] = itemList;
    let tempAmountDetails = {};
    Object.keys(amountDetails).forEach((key) => {
      tempAmountDetails[key] = getValidFloatValue(amountDetails[key]);
    });
    tempQuotationSchema["amount_details"] = tempAmountDetails;
    tempQuotationSchema["billing_details"] = billingDetails;
    tempQuotationSchema["additional_details"] = additionalDetails;
    tempQuotationSchema["place_of_supply"] = placeOfSupply;
    //copy schema from existing invoice data

    tempQuotationSchema["version"] = 2;
    tempQuotationSchema["company_details"] = {};
    tempQuotationSchema["company_details"] =
      existingInvoiceData["schema"]["company_details"];
    tempQuotationSchema["show_gst"] = existingInvoiceData["schema"]["show_gst"]
      ? existingInvoiceData["schema"]["show_gst"]
      : false;
    if (hasQuotationAndInvoiceTitles(existingInvoiceData["schema"])) {
      tempQuotationSchema["title"] =
        existingInvoiceData["schema"]["invoice_title"];
    } else {
      if (
        existingInvoiceData["schema"].hasOwnProperty("title") &&
        existingInvoiceData["schema"]["title"] !== null &&
        existingInvoiceData["schema"]["title"] !== ""
      ) {
        tempQuotationSchema["title"] = existingInvoiceData["schema"]["title"];
      }
    }
    tempQuotationSchema["additional_details"]["show_additional_information"] =
      existingInvoiceData["schema"]["additional_details"][
        "show_additional_information"
      ];
    tempQuotationSchema["additional_details"]["show_terms_and_conditions"] =
      existingInvoiceData["schema"]["additional_details"][
        "show_terms_and_conditions"
      ];
    tempQuotationSchema["additional_details"]["show_quotation_number"] =
      existingInvoiceData["schema"]["additional_details"][
        "show_quotation_number"
      ];
    tempQuotationSchema["billing_details"]["show_shipping_address"] =
      existingInvoiceData["schema"]["billing_details"]["show_shipping_address"];

    formData["schema"] = tempQuotationSchema;
    formData["amount"] = parseFloat(tempAmountDetails["grand_total"]);
    //sending new balance if old balance is there
    if (existingInvoiceData["balance"] !== null) {
      const amount =
        existingInvoiceData["amount"] !== null
          ? existingInvoiceData["amount"]
          : 0;
      const paid = amount - existingInvoiceData["balance"];
      formData["balance"] = formData["amount"] - paid;
    }
    await editInvoice(formData);
  };

  const initEditableData = async () => {
    setSelectedQuoteID(existingInvoiceData["quotation_template_id"]);
    setBillingDetails(existingInvoiceData["schema"]["billing_details"]);
    let updatedAmountDetails = updateTotalAmount({
      type: "init",
      amountDetails: existingInvoiceData["schema"]["amount_details"],
    });
    setAmountDetails(updatedAmountDetails);
    setAdditionalDetails(existingInvoiceData["schema"]["additional_details"]);

    let tempObj = {
      title: existingInvoiceData["schema"]["invoice_title"],
      invoice_date:
        existingInvoiceData["quote_date"] !== null &&
        existingInvoiceData["quote_date"] !== ""
          ? existingInvoiceData["quote_date"]
          : null,
      expiry_date:
        existingInvoiceData["expiry_date"] !== null &&
        existingInvoiceData["expiry_date"] !== ""
          ? existingInvoiceData["expiry_date"]
          : null,
    };
    setDetails(tempObj);
    setPlaceOfSupply(
      existingInvoiceData["schema"]["place_of_supply"] !== null &&
        existingInvoiceData["schema"]["place_of_supply"] !== ""
        ? existingInvoiceData["schema"]["place_of_supply"]
        : null
    );
    let tempList = [...existingInvoiceData["schema"]["items"]];
    tempList.forEach((item) => {
      item["item"] = { name: item["name"], id: uuidv4() };
      item["desc"] =
        item.hasOwnProperty("desc") && item["desc"] !== null
          ? item["desc"]
          : "";
    });

    setProductAddedList(tempList);
  };

  const setup = async () => {
    await initEditableData();
    setLoading(false);
  };

  useEffect(() => {
    setup();
  }, []);

  const hasQuotationAndInvoiceTitles = (schema) => {
    if (
      schema.hasOwnProperty("quotation_title") &&
      schema.hasOwnProperty("invoice_title")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const updateTotalAmount = ({ type, amountDetails }) => {
    let totalAmount = parseFloat(amountDetails["sub_total"]);
    let taxedAmount =
      amountDetails["additional_tax"] !== null &&
      amountDetails["additional_tax"] !== ""
        ? parseFloat(amountDetails["additional_tax"])
        : 0;
    let discountedAmount =
      amountDetails["additional_discount"] !== null &&
      amountDetails["additional_discount"] !== ""
        ? parseFloat(amountDetails["additional_discount"])
        : 0;
    let adjustmentAmount =
      amountDetails["adjustments"] !== null &&
      amountDetails["adjustments"] !== ""
        ? parseFloat(amountDetails["adjustments"])
        : 0;

    totalAmount = totalAmount - discountedAmount;
    totalAmount = totalAmount + taxedAmount;

    let roundedDifference = 0;
    if (type === "adjustments" || type === "init") {
      //using entered adjustment amount if it was init or from text field
      totalAmount = totalAmount + adjustmentAmount;
    } else {
      // done only if not changing adjustments directly or not init
      //calc difference to auto adjust
      roundedDifference = parseFloat(
        (Math.round(totalAmount) - totalAmount).toFixed(2)
      );
      amountDetails["adjustments"] = roundedDifference;
    }

    amountDetails["grand_total"] = parseFloat(
      totalAmount + roundedDifference
    ).toFixed(2);
    return amountDetails;
  };

  const RenderButton = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: "20px",
          width: "fit-content",
        }}
      >
        <Button
          onClick={handleClose}
          type="button"
          variant="outlined"
          color="primary"
          style={{
            textTransform: "none",
            width: "120px",
            fontWeight: "bold",
          }}
        >
          {getLocalizedText("cancel")}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            width: "120px",
            fontWeight: "bold",
          }}
        >
          {getLocalizedText("submit")}
        </Button>
      </Box>
    );
  };

  const getHeaderText = () => {
    return getLocalizedText("edit_invoice");
  };
  const getSuccessMessageText = () => {
    return getLocalizedText("invoice_edited_successfully");
  };

  const removeTemplateItemsFromList = ({ templateData }) => {
    let newList = [];
    if (
      templateData.schema.hasOwnProperty("items") &&
      templateData.schema["items"] !== null &&
      templateData.schema["items"].length > 0
    ) {
      productAddedList.forEach((item) => {
        let index = templateData.schema["items"].findIndex(
          (obj) => obj["name"] === item["item"]["name"]
        );
        if (index === -1) {
          newList.push(item);
        }
      });
    }
    return newList;
  };

  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            <ModelHeader>{getHeaderText()}</ModelHeader>
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModelWrapper
            component={"form"}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <InputContainer>
              <BillingInfo
                billingDetails={billingDetails}
                handleChange={(name, value) => {
                  setBillingDetails({ ...billingDetails, [name]: value });
                }}
                showShippingAddress={false}
              />
            </InputContainer>

            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500, bgcolor: "#f9f9fc" }}
            >
              <Toolbar style={{ width: 500, position: "relative" }}>
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <ButtonWrapper>
                  <RenderButton />
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </ModelWrapper>
        ) : (
          <MessageWrapper>
            <Message>{getSuccessMessageText()}</Message>
            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              {getLocalizedText("close")}
            </Button>
          </MessageWrapper>
        )}
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default EditInvoiceNonDraft;
