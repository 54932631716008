import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Select,
  makeStyles,
} from "@material-ui/core";
import {
  AppBar,
  Box,
  MenuItem,
  Toolbar,
  Typography,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { observer } from "mobx-react-lite";
import MuiAlert from "@mui/material/Alert";
import analytics from "../../Analytics";
import { myTheme } from "../../themeUtils";
import { addUsersApi, editUsersApi } from "../../Api";
import { getLocalizedText, getIsoString } from "../../Functions";
import PreviewImageDialog from "../files_page_components/PreviewImageDialog";
import UserProfileImageWithUploadComponent from "../file_upload_component/user_profile_image/UserProfileImageWithUploadComponent";
import { countryCodeList, loginRestrictionOptions } from "../../Db";
import moment from "moment";
import rootStore from "../../stores/RootStore";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 18px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;
const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;
const CustomizedDatePicker = styled(DatePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
    padding: 7.5px 14px;
  }
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const roleList = ["admin", "member"];
const AddUserSidepanel = (props) => {
  const classes = useStyles();
  const accountRole =
    rootStore.userStore.UserData !== null &&
    rootStore.userStore.UserData.hasOwnProperty("role") &&
    rootStore.userStore.UserData.role !== null
      ? rootStore.userStore.UserData.role
      : "";
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    role: "member",
    unmask_columns: false,
    employee_id: "",
    designation: "",
    group: "",
    bank_name: "",
    bank_account_number: "",
    bank_account_name: "",
    ifsc_code: "",
    pan_number: "",
    reporting_to_id: "",
    login_restriction: "",
    dob: null,
  });
  const [file, setFile] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [loginRestrictionList, setLoginRestrictionList] = useState([]);
  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleClose = () => {
    props.setOpen(false);
    props.setIsEdit(false);
  };

  const editUser = async () => {
    let payload = new FormData();

    Object.keys(user).forEach((key) => {
      if (user[key] !== "" && user[key] !== null) {
        if (key === "phone") {
          let value = `${countryCode}-${user[key]}`;
          payload.append(key, value);
        } else if (key === "dob") {
          const date = user[key].toISOString();
          payload.append(key, date);
        } else {
          payload.append(key, user[key]);
        }
      }
    });

    if (file !== null) {
      payload.append("profile_pic", file);
    }

    try {
      let response = await editUsersApi({
        id: props.editableData.id,
        data: payload,
      });

      props.setRefresh(true);
      setIsSubmitSuccess(true);
    } catch (error) {
      setErrorMessage(getLocalizedText("something_went_wrong"));
      setIsSubmitFail(true);
      console.log(error);
    }
  };

  const getPhoneWithoutCountryCode = (phoneString) => {
    for (const code of countryCodeList) {
      if (phoneString.startsWith(`${code}-`)) {
        return phoneString.replace(`${code}-`, "");
      }
    }
    return phoneString;
  };

  const getCountryCodeWithoutPhone = (phoneString) => {
    for (const code of countryCodeList) {
      if (phoneString.startsWith(`${code}-`)) {
        return code;
      }
    }
    return "+91";
  };

  const addUser = async () => {
    let payload = new FormData();
    Object.keys(user).forEach((key) => {
      if (user[key] !== "" && user[key] !== null) {
        if (key === "phone") {
          let value = `${countryCode}-${user[key]}`;
          payload.append(key, value);
        } else if (key === "dob") {
          const date = user[key].toISOString();
          payload.append(key, date);
        } else {
          payload.append(key, user[key]);
        }
      }
    });
    if (file !== null) {
      payload.append("profile_pic", file);
    }
    try {
      let response = await addUsersApi({
        data: payload,
      });

      props.setRefresh(true);
      setIsSubmitSuccess(true);
    } catch (error) {
      setErrorMessage(getLocalizedText("something_went_wrong"));
      setIsSubmitFail(true);
      console.log(error);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.isEdit === true) {
      editUser();
    } else {
      addUser();
    }
  };

  const init = async () => {
    analytics.triggerEvent(
      4625000119,
      "add_user_form_load",
      "admin_users_page",
      "",
      {}
    );
    let newLoginRestrictionList = [...loginRestrictionOptions];
    //set simultaneous login option if allow_simultaneous_login flag is there in account details
    if (
      rootStore.userStore.UserData.account.hasOwnProperty(
        "allow_simultaneous_login"
      ) &&
      rootStore.userStore.UserData.account["allow_simultaneous_login"] !== null
    ) {
      if (rootStore.userStore.UserData.account["allow_simultaneous_login"]) {
        newLoginRestrictionList.push({
          label: "Allow One Web and One Mobile Login Simultaneously",
          value: "Allow One Web and One Mobile Login Simultaneously",
        });
      }
    }
    setLoginRestrictionList(newLoginRestrictionList);

    if (props.isEdit === true) {
      let tempPhone =
        props.editableData.phone !== null ? props.editableData.phone : "";
      let tempCountryCode = getCountryCodeWithoutPhone(tempPhone);
      tempPhone = getPhoneWithoutCountryCode(tempPhone);
      let tempObj = {
        name: props.editableData.name !== null ? props.editableData.name : "",
        email:
          props.editableData.email !== null ? props.editableData.email : "",
        phone: tempPhone,
        role: props.editableData.role !== null ? props.editableData.role : "",
        unmask_columns:
          props.editableData.unmask_columns !== null
            ? props.editableData.unmask_columns
            : "",
        employee_id:
          props.editableData.employee_id !== null
            ? props.editableData.employee_id
            : "",
        designation:
          props.editableData.designation !== null
            ? props.editableData.designation
            : "",
        group:
          props.editableData.group !== null ? props.editableData.group : "",
        bank_name:
          props.editableData.bank_name !== null
            ? props.editableData.bank_name
            : "",
        bank_account_number:
          props.editableData.bank_account_number !== null
            ? props.editableData.bank_account_number
            : "",
        bank_account_name:
          props.editableData.bank_account_name !== null
            ? props.editableData.bank_account_name
            : "",
        ifsc_code:
          props.editableData.ifsc_code !== null
            ? props.editableData.ifsc_code
            : "",
        pan_number:
          props.editableData.pan_number !== null
            ? props.editableData.pan_number
            : "",
        reporting_to_id:
          props.editableData.reporting_to !== null
            ? props.editableData.reporting_to.id
            : "",
        login_restriction:
          props.editableData.login_restriction !== null
            ? props.editableData.login_restriction
            : "",
        dob:
          props.editableData.hasOwnProperty("dob") &&
          props.editableData.dob !== null &&
          props.editableData.dob !== ""
            ? moment(props.editableData.dob)
            : null,
      };
      setCountryCode(tempCountryCode);
      setUser(tempObj);
    } else {
      setUser({
        ...user,
        login_restriction: "Allow Only Web - Single Login",
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };

  const getProfileImageUrl = () => {
    if (file !== null) {
      return URL.createObjectURL(file);
    } else {
      if (props.isEdit === true) {
        if (
          props.editableData.hasOwnProperty("profile_pic") &&
          props.editableData["profile_pic"] !== null
        ) {
          return props.editableData["profile_pic"];
        }
      }
      return null;
    }
  };

  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            {props.isEdit ? (
              <ModelHeader>{getLocalizedText("edit_details")}</ModelHeader>
            ) : (
              <ModelHeader>{getLocalizedText("add_a_new_user")}</ModelHeader>
            )}
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModelWrapper
            component={"form"}
            onSubmit={handleSubmit}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <InputContainer>
              <Grid container spacing={2}>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <UserProfileImageWithUploadComponent
                      handleUpload={(event) => handleFile(event)}
                      url={getProfileImageUrl()}
                      handleImageClick={() => {
                        setOpenPreview(true);
                      }}
                      canShowUpload={true}
                      scope={"admin_users"}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("name")}*</Label>
                    <Input
                      value={user.name}
                      required
                      type="text"
                      placeholder="Enter name here..."
                      name="name"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("email")}*</Label>
                    <Input
                      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                      value={user.email}
                      required
                      type="email"
                      placeholder="Enter email here..."
                      name="email"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("phone")}*</Label>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        "& .MuiFormControl-root": {
                          minWidth: "unset",
                        },
                      }}
                    >
                      <FormControl
                        sx={{
                          width: "100%",
                        }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          value={countryCode}
                          name="role"
                          displayEmpty
                          disableUnderline
                          variant="outlined"
                          onChange={(event) => {
                            setCountryCode(event.target.value);
                          }}
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",
                            borderRadius: "0px",
                          }}
                        >
                          {countryCodeList.map((item, idx) => {
                            return (
                              <MenuItem
                                style={{
                                  fontSize: "12px",
                                }}
                                key={idx}
                                value={item}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <Input
                        style={{ borderLeft: "none" }}
                        pattern={countryCode === "+91" ? "^[0-9]{10}$" : null}
                        value={user.phone}
                        required
                        type="text"
                        placeholder="1231231231"
                        name="phone"
                        onChange={handleChange}
                      />
                    </Box>
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("employee_id")}</Label>
                    <Input
                      value={user.employee_id}
                      type="text"
                      placeholder="Enter employee id"
                      name="employee_id"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("group")}</Label>
                    <Input
                      value={user.group}
                      type="text"
                      placeholder="Enter group"
                      name="group"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("designation")}</Label>
                    <Input
                      value={user.designation}
                      type="text"
                      placeholder="Enter designation"
                      name="designation"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("select_role")}</Label>
                    <FormControl
                      sx={{ width: "100%" }}
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        value={user.role}
                        name="role"
                        displayEmpty
                        disableUnderline
                        variant="outlined"
                        disabled={props.editableData.role === "owner"}
                        required={props.editableData.role !== "owner"}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "0px",
                        }}
                      >
                        {props.editableData.role !== "owner" &&
                          accountRole === "owner" && (
                            <MenuItem value="owner">owner</MenuItem>
                          )}
                        {props.editableData.role !== "owner" ? (
                          roleList.map((e) => (
                            <MenuItem key={e} id={e} value={e}>
                              {e}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="owner">Owner</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label
                      style={{
                        marginBottom: "0px",
                      }}
                    >
                      {getLocalizedText("unmask_columns")}
                    </Label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio size="small" />}
                        label={<Label style={{ fontSize: "12px" }}>Yes</Label>}
                        checked={user.unmask_columns}
                        onChange={() => {
                          setUser({ ...user, unmask_columns: true });
                        }}
                      />
                      <FormControlLabel
                        value={false}
                        checked={!user.unmask_columns}
                        onChange={() => {
                          setUser({ ...user, unmask_columns: false });
                        }}
                        control={<Radio size="small" />}
                        label={<Label style={{ fontSize: "12px" }}>No</Label>}
                      />
                    </RadioGroup>
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>Bank Name</Label>
                    <Input
                      value={user.bank_name}
                      type="text"
                      placeholder="Enter value"
                      name="bank_name"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>Bank Account Number</Label>
                    <Input
                      value={user.bank_account_number}
                      type="text"
                      placeholder="Enter value"
                      name="bank_account_number"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>Bank Account Name</Label>
                    <Input
                      value={user.bank_account_name}
                      type="text"
                      placeholder="Enter value"
                      name="bank_account_name"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>IFSC Code</Label>
                    <Input
                      value={user.ifsc_code}
                      type="text"
                      placeholder="Enter value"
                      name="ifsc_code"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>PAN Number</Label>
                    <Input
                      value={user.pan_number}
                      type="text"
                      placeholder="Enter value"
                      name="pan_number"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>Reporting To</Label>
                    <FormControl
                      sx={{ width: "100%" }}
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        value={user.reporting_to_id}
                        name="reporting_to_id"
                        displayEmpty
                        disableUnderline
                        variant="outlined"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "0px",
                        }}
                      >
                        {props.userList.map((item) => {
                          if (item.active) {
                            return (
                              <MenuItem id={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>DOB</Label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomizedDatePicker
                        onChange={(value) => {
                          setUser({ ...user, ["dob"]: value });
                        }}
                        value={user.dob}
                        inputFormat="YYYY-MM-DD"
                        renderInput={(params) => (
                          <TextField
                            sx={{ width: "100%" }}
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Login Restriction Options</Label>
                    <FormControl
                      sx={{ width: "100%" }}
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        value={user.login_restriction}
                        name="login_restriction"
                        displayEmpty
                        disableUnderline
                        variant="outlined"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "0px",
                        }}
                      >
                        {loginRestrictionList.map((e) => {
                          return (
                            <MenuItem id={e.value} value={e.value}>
                              {e.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </InputWrapper>
                </Grid>
              </Grid>
            </InputContainer>
            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500 }}
            >
              <Toolbar style={{ width: 500, position: "relative" }}>
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000120,
                        "cancel_button_tap",
                        "add_user_form",
                        "cancel_button",
                        {}
                      );
                      handleClose();
                    }}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      backgroundColor: "#EFEFF4",
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000121,
                        "submit_button_tap",
                        "add_user_form",
                        "submit_button",
                        {}
                      );
                    }}
                    type="submit"
                    variant="contained"
                    id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    {getLocalizedText("submit")}
                  </Button>
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </ModelWrapper>
        ) : (
          <MessageWrapper>
            {props.isEdit ? (
              <Message>User info updated successfully!</Message>
            ) : (
              <Message>User created successfully!</Message>
            )}

            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        )}
      </Box>
      {openPreview && (
        <PreviewImageDialog
          open={openPreview}
          setOpen={setOpenPreview}
          file={getProfileImageUrl()}
          isCreate={false}
        />
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(AddUserSidepanel);
