import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Drawer, Grid, makeStyles } from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { currencylist, dealPriorityList } from "../../Db";
import { createAdminPredefinedDealApi, getProductsListApi } from "../../Api";
import { Percent } from "@mui/icons-material";
import { ExpandMore } from "@material-ui/icons";
import ViewProductSidepanel from "../view_product_sidepanel/ViewProductSidepanel";
import AddCustomDealTableComponent from "./table_component/AddCustomDealTableComponent";
import AddProductModal from "./custom_deal_add_product_modals/AddProductModal";
import AddCustomItemModal from "./custom_deal_add_product_modals/AddCustomItemModal";
import ViewCustomItemInfopanel from "./view_custom_item_infopanel/ViewCustomItemInfopanel";
import { getLocalizedText } from "../../Functions";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const CreateAdminPredefinedDealSidepanel = (props) => {
  const classes = useStyles();
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [details, setDetails] = useState({
    name: "",
    deal_value: "",
    cost_of_service: "",
    deal_value_currency: "INR",
    priority: "Medium",
    original_price: "",
    discount: "",
    discount_in_price: "",
  });
  const [openAddProductModal, setOpenAddProductModal] = useState(false);
  const [openViewProductModal, setOpenViewProductModal] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState({});
  const [productAddedList, setProductAddedList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [defaultProductList, setDefaultProductList] = useState([]);
  const [fullProductList, setFullProductList] = useState([]);
  const [selectedItemType, setSelectedItemType] = useState("");
  const [discountType, setDiscountType] = useState("price");
  const [isEditItem, setIsEditItem] = useState(false);
  const [loading, setLoading] = useState(true);

  const getProductsList = async () => {
    const queryPayload = {
      name: "",
      active: true,
    };

    let response = await getProductsListApi({
      page: 0,
      page_size: 50,
      payload: { query: queryPayload },
    });
    if (Object.keys(response).length > 0) {
      setFullProductList(response.items);
    } else {
      setFullProductList([]);
    }
  };

  const init = async () => {
    //initially getting full product list and storing to filter added product and use later
    await getProductsList();
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleChange = (e) => {
    let tempDetails = { ...details };
    tempDetails[e.target.name] = e.target.value;
    setDetails(tempDetails);
  };

  const createPredefinedDeal = async () => {
    let rawData = { ...details };
    let payload = {
      name: rawData.name,
      deal_value_currency: rawData.deal_value_currency,
      priority: rawData.priority,
      margin: getMarginValue(),
      discount_type: discountType,
    };

    payload["deal_value"] =
      rawData["deal_value"] !== null && rawData["deal_value"] !== ""
        ? parseInt(rawData["deal_value"])
        : 0;
    payload["original_price"] =
      rawData["original_price"] !== null && rawData["original_price"] !== ""
        ? parseInt(rawData["original_price"])
        : 0;
    payload["cost_of_service"] =
      rawData["cost_of_service"] !== null && rawData["cost_of_service"] !== ""
        ? parseInt(rawData["cost_of_service"])
        : 0;

    payload["discount"] =
      rawData["discount"] !== null && rawData["discount"] !== ""
        ? parseFloat(rawData["discount"])
        : 0;
    payload["discount_in_price"] =
      rawData["discount_in_price"] !== null &&
      rawData["discount_in_price"] !== ""
        ? parseFloat(rawData["discount_in_price"])
        : 0;

    let dealItems = [];
    productAddedList.forEach((productItem) => {
      let tempObj = {
        rate: productItem["rate"],
        amount: productItem["amount"],
        quantity: productItem["quantity"],
      };

      // discount and discount in price are float values - when we added line items
      tempObj["discount"] = productItem["discount"];
      tempObj["discount_in_price"] = productItem["discount_in_price"];
      tempObj["discount_type"] = productItem["discount_type"];
      if (productItem["product"]["type"] === "product") {
        tempObj["product_id"] = productItem["product"]["id"];
      } else {
        tempObj["item"] = productItem["product"]["name"];
        tempObj["type"] = productItem["product"]["type"];

        tempObj["desc"] = productItem["product"]["desc"];
      }
      dealItems.push(tempObj);
    });
    payload["deal_items"] = dealItems;
    let response = await createAdminPredefinedDealApi({
      projectID: props.projectID,
      teamID: props.teamID,
      payload: payload,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (hasValidDiscount()) {
      createPredefinedDeal();
    } else {
      setErrorMessage(
        getLocalizedText("discount_amount_should_be_less_than_actual_amount")
      );
      setIsSubmitFail(true);
    }
  };

  const getDefaultProductsList = () => {
    //setting default list to show show 10 items by default before search
    if (fullProductList.length > 0) {
      let productItems = fullProductList
        .filter((item) => !isProductAlreadyAdded(item.id))
        .slice(0, 10);

      setDefaultProductList(productItems);
      setProductList(productItems);
    } else {
      setDefaultProductList([]);
      setProductList([]);
    }
  };

  const onSearchResetCallback = (searchResults) => {
    const newProductsList = defaultProductList.concat(searchResults);
    setProductList(newProductsList);
  };

  const canPrefillCostOfService = (list) => {
    return list.every(
      (item) =>
        item["product"]["type"] === "product" &&
        item["product"].hasOwnProperty("unit_cost") &&
        item["product"]["unit_cost"] !== null &&
        item["product"]["unit_cost"] !== ""
    );
  };

  const autoUpdateValues = (list) => {
    let totalOriginalPrice = 0;
    let totalDiscountedAmount = 0;
    let totalCostOfService = 0;
    let totalDealValue = 0;

    list.forEach((item) => {
      const actualAmount = parseFloat(item.rate) * parseInt(item.quantity);
      totalOriginalPrice += actualAmount;
      if (item.product !== null && item.product.hasOwnProperty("unit_cost")) {
        totalCostOfService +=
          item.product.unit_cost !== null
            ? parseFloat(item.product.unit_cost) * parseInt(item.quantity)
            : 0;
      }

      totalDiscountedAmount += Math.round(
        (actualAmount * item["discount"]) / 100
      );
      totalDealValue += item.amount;
    });
    let totalDiscountedAmountPercent =
      totalOriginalPrice > 0
        ? (totalDiscountedAmount / totalOriginalPrice) * 100
        : 0; // do the dicount percent calculation only if the original price is not zero
    let tempDetails = {
      ...details,
      cost_of_service: canPrefillCostOfService(list) ? totalCostOfService : "", //prefill cost_of_service only if all line items are products and have unit_cost value
      original_price: totalOriginalPrice,
      deal_value: totalDealValue,
      discount_in_price: totalDiscountedAmount,
      discount: parseFloat(totalDiscountedAmountPercent.toFixed(2)),
    };
    setDetails(tempDetails);
  };

  const getMarginValue = () => {
    if (details["cost_of_service"] === "" || details["deal_value"] === "") {
      return 0;
    }
    let costOfService = parseFloat(details["cost_of_service"]);
    let finalDealValue = parseFloat(details["deal_value"]);

    if (finalDealValue === 0) {
      return 0; // Avoid division by zero
    }

    let margin = finalDealValue - costOfService;
    let marginPercentage = (margin / finalDealValue) * 100;

    return parseFloat(marginPercentage.toFixed(2)); // Returns percentage with 2 decimal points
  };

  const handleOffFocusOriginalAmount = (value) => {
    if (value !== "") {
      if (details["discount"] !== "") {
        let amount = parseFloat(value);
        let discountableAmount =
          (amount * parseFloat(details["discount"])) / 100;

        setDetails({
          ...details,
          discount_in_price: discountableAmount,
        });
      }
    } else {
      if (details["discount"] !== "" && details["discount_in_price"] !== "") {
        setDetails({
          ...details,
          discount: "",
          discount_in_price: "",
        });
      }
    }
  };

  const hasValidDiscount = () => {
    if (details["discount_in_price"] !== "") {
      let amount = parseFloat(details["original_price"]);
      return parseFloat(details["discount_in_price"]) < amount;
    }
    return true;
  };

  const handleOffFocusDiscountPrice = (value) => {
    if (value !== "") {
      let discountPrice = parseFloat(value);
      let amount = parseFloat(details["original_price"]);
      if (discountPrice > amount) {
        setErrorMessage(
          getLocalizedText("discount_amount_should_be_less_than_actual_amount")
        );
        setIsSubmitFail(true);
      } else {
        let discountPricePercent = (discountPrice / amount) * 100;
        setDetails({
          ...details,
          discount: parseFloat(discountPricePercent.toFixed(2)),
        });
      }
    } else {
      if (details["discount"] !== "") {
        setDetails({ ...details, discount: "" });
      }
    }
  };

  const handleOffFocusDiscountPercent = (value) => {
    if (value !== "") {
      let discountPercent = parseFloat(value);
      let amount = parseFloat(details["original_price"]);
      let discountedPrice = (amount * discountPercent) / 100;
      setDetails({
        ...details,
        discount_in_price: parseFloat(discountedPrice.toFixed(2)),
      });
    } else {
      if (details["discount_in_price"] !== "") {
        setDetails({
          ...details,
          discount_in_price: "",
        });
      }
    }
  };
  const isDiscountFieldDisabled = () => {
    return details["original_price"] === "" || productAddedList.length > 0;
  };

  const isProductAlreadyAdded = (id) => {
    let index = productAddedList.findIndex(
      (product) => product["product"]["id"] === id
    );
    if (index !== -1) {
      return true;
    }
    return false;
  };
  return (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            <ModelHeader>
              {getLocalizedText("create_predefined_deal")}
            </ModelHeader>
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModelWrapper component={"form"} onSubmit={handleSubmit}>
            <InputContainer>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{`${getLocalizedText("name")}*`}</Label>
                    <OutlinedInput
                      style={{ height: "30px", overflow: "hidden" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      onChange={handleChange}
                      name="name"
                      required
                      value={details["name"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <AddCustomDealTableComponent
                      scope={"predefined_deals"}
                      tableData={productAddedList}
                      handleDelete={(selectedItem) => {
                        let tempProductList = [...productAddedList];
                        tempProductList = tempProductList.filter(
                          (entry) =>
                            entry["product"]["id"] !==
                            selectedItem["product"]["id"]
                        );
                        setProductAddedList(tempProductList);
                        autoUpdateValues(tempProductList);
                      }}
                      handleEdit={(selectedItem) => {
                        setSelectedProductData(selectedItem);

                        setSelectedItemType(selectedItem.product.type);

                        setIsEditItem(true);
                        setOpenAddProductModal(true);
                      }}
                      handleView={(selectedItem) => {
                        setSelectedProductData(selectedItem);

                        setSelectedItemType(selectedItem.product.type);

                        setOpenViewProductModal(true);
                      }}
                      handleAddCustomItem={() => {
                        setSelectedItemType("custom_item");
                        setOpenAddProductModal(true);
                      }}
                      handleAddProduct={() => {
                        getDefaultProductsList();
                        setSelectedItemType("product");
                        setOpenAddProductModal(true);
                      }}
                    />
                  </InputWrapper>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <InputWrapper>
                      <Label>{getLocalizedText("original_price")}</Label>
                      <OutlinedInput
                        style={{ height: "30px", overflow: "hidden" }}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        onBlur={(e) =>
                          handleOffFocusOriginalAmount(e.target.value)
                        }
                        disabled={productAddedList.length > 0}
                        onChange={handleChange}
                        name="original_price"
                        value={details["original_price"]}
                        placeholder="Enter value"
                      />
                    </InputWrapper>
                    <InputWrapper style={{ width: "100px" }}>
                      <Label>{getLocalizedText("currency")}</Label>
                      <FormControl
                        sx={{ width: "100%" }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          displayEmpty
                          required
                          onChange={handleChange}
                          name="deal_value_currency"
                          disableUnderline
                          variant="outlined"
                          value={details["deal_value_currency"]}
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",

                            borderRadius: "0px",
                          }}
                        >
                          {currencylist.map((item, i) => {
                            return (
                              <MenuItem
                                value={item.code}
                                key={i}
                                style={{ fontSize: "12px" }}
                              >
                                {item.code}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </InputWrapper>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Accordion defaultExpanded={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Label style={{ fontSize: "14px" }}>
                        {getLocalizedText("discount")}
                      </Label>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: "0px" }}>
                      <InputWrapper>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            gap: "16px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              border: "1px solid #cccccc",
                              padding: "12px",
                              paddingTop: "0px",
                            }}
                          >
                            <FormControlLabel
                              disabled={isDiscountFieldDisabled()}
                              value="price"
                              checked={discountType === "price"}
                              onChange={() => {
                                setDiscountType("price");
                              }}
                              control={<Radio size="small" />}
                              label={
                                <Label style={{ fontSize: "12px" }}>
                                  {getLocalizedText("price")}
                                </Label>
                              }
                            />
                            <OutlinedInput
                              style={{
                                height: "30px",
                                width: "100%",
                                overflow: "hidden",
                              }}
                              inputProps={{
                                min: 0,
                                "aria-label": "weight",
                                style: {
                                  fontSize: "12px",
                                },
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Label
                                    style={{ fontSize: "12px", color: "gray" }}
                                  >
                                    {details["deal_value_currency"]}
                                  </Label>
                                </InputAdornment>
                              }
                              disabled={
                                discountType === "percentage" ||
                                isDiscountFieldDisabled()
                              }
                              onBlur={(e) =>
                                handleOffFocusDiscountPrice(e.target.value)
                              }
                              onChange={handleChange}
                              name="discount_in_price"
                              value={details["discount_in_price"]}
                              placeholder="Enter value"
                            />
                          </Box>
                          <Box
                            sx={{
                              border: "1px solid #cccccc",
                              padding: "12px",
                              paddingTop: "0px",
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <FormControlLabel
                              disabled={isDiscountFieldDisabled()}
                              onChange={() => {
                                setDiscountType("percentage");
                              }}
                              checked={discountType === "percentage"}
                              value="percentage"
                              control={<Radio size="small" />}
                              label={
                                <Label style={{ fontSize: "12px" }}>
                                  {getLocalizedText("percentage")}
                                </Label>
                              }
                            />
                            <OutlinedInput
                              disabled={
                                discountType === "price" ||
                                isDiscountFieldDisabled()
                              }
                              style={{
                                height: "30px",
                                width: "100%",
                                overflow: "hidden",
                              }}
                              inputProps={{
                                min: 0,
                                max: 100,
                                "aria-label": "weight",
                                style: {
                                  fontSize: "12px",
                                },
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Percent
                                    style={{
                                      width: "16px",
                                      height: "16px",
                                      color: "gray",
                                    }}
                                  />
                                </InputAdornment>
                              }
                              onBlur={(e) =>
                                handleOffFocusDiscountPercent(e.target.value)
                              }
                              onChange={handleChange}
                              name="discount"
                              value={details["discount"]}
                              placeholder="Enter value"
                            />
                          </Box>
                        </Box>
                      </InputWrapper>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{`${getLocalizedText("final_deal_value")}*`}</Label>
                    <OutlinedInput
                      style={{ height: "30px", overflow: "hidden" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      disabled={productAddedList.length > 0}
                      onChange={handleChange}
                      name="deal_value"
                      required
                      value={details["deal_value"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>

                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>
                      {getLocalizedText("cost_of_service_or_product")}
                    </Label>
                    <OutlinedInput
                      style={{ height: "30px", overflow: "hidden" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      onChange={handleChange}
                      name="cost_of_service"
                      value={details["cost_of_service"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("margin")}</Label>
                    <Box
                      sx={{
                        border: "1px solid #BDBDBD",
                        padding: "6px 12px",
                        height: "30px",
                        borderRadius: "4px",
                      }}
                    >
                      <Label style={{ fontSize: "12px" }}>
                        {getMarginValue()}
                      </Label>
                    </Box>
                  </InputWrapper>
                </Grid>

                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{`${getLocalizedText("priority")}*`}</Label>
                    <FormControl
                      sx={{ width: "100%" }}
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        displayEmpty
                        required
                        onChange={handleChange}
                        name="priority"
                        disableUnderline
                        variant="outlined"
                        value={details["priority"]}
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          borderRadius: "0px",
                        }}
                      >
                        {dealPriorityList.map((item, i) => {
                          return (
                            <MenuItem
                              value={item}
                              key={i}
                              style={{ fontSize: "12px" }}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </InputWrapper>
                </Grid>
              </Grid>
            </InputContainer>

            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500 }}
            >
              <Toolbar style={{ width: 500, position: "relative" }}>
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <ButtonWrapper>
                  <Button
                    onClick={handleClose}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#185DD2",
                      color: "white",
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {getLocalizedText("submit")}
                  </Button>
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </ModelWrapper>
        ) : (
          <MessageWrapper>
            <Message>
              {getLocalizedText("predefined_deal_created_successfully")}
            </Message>
            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              {getLocalizedText("close")}
            </Button>
          </MessageWrapper>
        )}
      </Box>
      {openAddProductModal &&
        (selectedItemType === "product" ? (
          <AddProductModal
            scope={"predefined_deals"}
            open={openAddProductModal}
            setOpen={setOpenAddProductModal}
            productList={productList}
            onSearchResetCallback={onSearchResetCallback}
            defaultProducts={defaultProductList}
            dealValueCurrency={details["deal_value_currency"]}
            editableProduct={selectedProductData}
            isEdit={isEditItem}
            postAddCallback={(details) => {
              let tempProductAddedList = [...productAddedList];
              tempProductAddedList.push(details);
              setProductAddedList(tempProductAddedList);
              autoUpdateValues(tempProductAddedList);
              setIsEditItem(false);
              setOpenAddProductModal(false);
            }}
            postEditCallback={(details) => {
              let tempProductAddedList = [...productAddedList];
              let index = productAddedList.findIndex(
                (productItem) =>
                  productItem["product"]["id"] === details["product"]["id"]
              );
              if (index !== -1) {
                tempProductAddedList[index] = details;
              }
              setProductAddedList(tempProductAddedList);
              autoUpdateValues(tempProductAddedList);
              setIsEditItem(false);
              setOpenAddProductModal(false);
            }}
            handleClose={() => {
              setIsEditItem(false);
              setOpenAddProductModal(false);
            }}
            isProductAlreadyAdded={(id) => isProductAlreadyAdded(id)}
          />
        ) : (
          <AddCustomItemModal
            open={openAddProductModal}
            dealValueCurrency={details["deal_value_currency"]}
            editableProduct={selectedProductData}
            isEdit={isEditItem}
            postAddCallback={(details) => {
              let tempProductAddedList = [...productAddedList];
              tempProductAddedList.push(details);
              setProductAddedList(tempProductAddedList);
              autoUpdateValues(tempProductAddedList);
              setIsEditItem(false);
              setOpenAddProductModal(false);
            }}
            postEditCallback={(details) => {
              let tempProductAddedList = [...productAddedList];
              let index = productAddedList.findIndex(
                (productItem) =>
                  productItem["product"]["id"] === details["product"]["id"]
              );
              if (index !== -1) {
                tempProductAddedList[index] = details;
              }
              setProductAddedList(tempProductAddedList);
              autoUpdateValues(tempProductAddedList);
              setIsEditItem(false);
              setOpenAddProductModal(false);
            }}
            handleClose={() => {
              setIsEditItem(false);
              setOpenAddProductModal(false);
            }}
          />
        ))}
      {openViewProductModal &&
        (selectedItemType === "product" ? (
          <Drawer
            disableEnforceFocus
            anchor={"right"}
            open={openViewProductModal}
            onClose={() => {
              setSelectedProductData({});
              setOpenViewProductModal(false);
            }}
          >
            <ViewProductSidepanel
              productID={selectedProductData["product"]["id"]}
              setOpen={setOpenViewProductModal}
              open={openViewProductModal}
            />
          </Drawer>
        ) : (
          <Drawer
            disableEnforceFocus
            anchor={"right"}
            open={openViewProductModal}
            onClose={() => {
              setSelectedProductData({});
              setOpenViewProductModal(false);
            }}
          >
            <ViewCustomItemInfopanel details={selectedProductData} />
          </Drawer>
        ))}
    </>
  );
};

export default CreateAdminPredefinedDealSidepanel;
