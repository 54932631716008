import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  Modal,
  Select,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Close } from "@material-ui/icons";
import "./rule.css";
import {
  Alert,
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  Link,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { observer } from "mobx-react-lite";
import analytics from "../../../Analytics";
import { getLocalizedText } from "../../../Functions";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MemberFilterModal from "../../lead_assign_modal/MemberFilterModal";
import profile_image_placeholder from "../../../assets/profile_placeholder.png";

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 30px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 350px;
`;
const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: fit-content;
  width: 398px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;

const SubHeader = styled(Typography)`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
`;
const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  margin-bottom: 6px;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  column-gap: 10px;
`;

const ProfileImage = styled("img")`
  aspect-ratio: 1/1;
  width: 100%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
`;
const ImageWrapper = styled(Avatar)`
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid #a7a6a8;
  padding: 0px;
  position: relative;
  background-color: white;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const AssignmentRulesModal = (props) => {
  const classes = useStyles();
  const [assignmentRule, setAssignmentRule] = useState({});
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [detailedMembersList, setDetailedMembersList] = useState([]);
  const [openFilterMemberDialog, setOpenFilterMemberDialog] = useState(false);
  //member filters
  const [selectedRolesFilter, setSelectedRolesFilter] = useState([]);
  const [selectedTeamsFilter, setSelectedTeamsFilter] = useState([]);

  useEffect(() => {
    analytics.triggerEvent(
      4625000102,
      "assignment_rules_form_load",
      "imports_create_leads_form",
      "",
      {}
    );
    setAssignmentRule(props.selectedAssignmentRule);
    setupDetailedMemberList();
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    if (assignmentRule.slug === "project_member_equally") {
      if (selectedMembers.length === 0) {
        setShowAlert(true);
        setAlertMessage(getLocalizedText("please_select_atleast_one_member"));
      } else {
        props.setSelectedMembers(selectedMembers);
        props.setSelectedAssignmentRule(assignmentRule);
        props.setOpen(false);
      }
    } else {
      props.setSelectedAssignmentRule(assignmentRule);
      props.setOpen(false);
      props.setSelectedMembers([]);
    }
  };

  const setupDetailedMemberList = async () => {
    setSelectedMembers(props.selectedMembers);

    let detailedList = [];
    props.memberList.forEach((e) => {
      let labelStr = `${e.name} || ${e.role} || ${e.team.name}`;
      let tempData = {
        label: labelStr,
        value: e.id,
        name: e.name,
        team: e.team,
        role: e.role,
        profile_pic: e.profile_pic,
      };
      detailedList.push(tempData);
    });
    setDetailedMembersList(detailedList);
  };

  const detailedOptionRenderer = ({ checked, option, onClick, disabled }) => {
    return option.value === "" && !option.hasOwnProperty("name") ? (
      <div
        className={`item-renderer ${disabled && "disabled"}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "14px",
          }}
        >
          <Link
            onClick={(e) => {
              onClick();
              e.preventDefault();
              e.stopPropagation();
            }}
            sx={{
              textDecoration: "none",
              color: checked ? "#3f51b5" : "inherit",
            }}
          >
            {" "}
            <Typography
              style={{
                width: "fit-content",
                fontSize: "14px",
                fontWeight: "600",
                textAlign: "left",
                textTransform: "uppercase",
              }}
            >
              {option.label}
            </Typography>
          </Link>
          <Link
            disabled={selectedMembers.length === 0}
            sx={{
              textDecoration: "none",
              color: "#000",
            }}
            onClick={(e) => {
              if (selectedMembers.length > 0) {
                setSelectedMembers([]);
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          >
            {" "}
            <Typography
              style={{
                width: "fit-content",
                fontSize: "14px",
                fontWeight: "600",
                textAlign: "left",
                textTransform: "uppercase",
              }}
            >
              Clear
            </Typography>
          </Link>
        </Box>
      </div>
    ) : (
      <div
        className={`item-renderer ${disabled && "disabled"}`}
        onClick={(e) => {
          onClick();
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Card
          square={true}
          elevation={2}
          id={option.id}
          value={option.id}
          style={{
            marginBottom: 4,
            marginLeft: 1,
            marginRight: 1,
            width: "98%",
            maxWidth: "98%",
            height: "70px",
            backgroundColor: checked ? "#E8F1FA" : null,
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <CardActionArea>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Checkbox
                  color="primary"
                  style={{
                    margin: 0,
                    padding: 0,
                    marginLeft: 0,
                    marginRight: 8,
                  }}
                  checked={checked}
                  onChange={(e) => {
                    //onClick();
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
                <ImageWrapper>
                  <ProfileImage
                    src={
                      option.profile_pic != null
                        ? option.profile_pic
                        : profile_image_placeholder
                    }
                    alt="loading..."
                  />
                </ImageWrapper>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    padding: 2,
                    marginLeft: 8,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        width: "fit-content",
                        fontSize: "14px",
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      {option.name}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{
                        width: "fit-content",
                        fontSize: "12px",
                        textAlign: "left",
                        color: "#b7b7b7",
                      }}
                    >
                      Role: {option.role}
                    </Typography>
                    <Typography
                      style={{
                        width: "fit-content",
                        fontSize: "12px",
                        textAlign: "right",
                        color: "#b7b7b7",
                      }}
                    >
                      Team: {option.team.name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    );
  };

  const valueRenderer = (selected, _options) => {
    return selected.length
      ? selected.map((x, index) => {
          let text = x.name;
          if (index < selected.length - 1) {
            text += ", ";
          }
          return text;
        })
      : "No Items Selected";
  };

  const handleFilterSubmit = (teamsFilterValue, rolesFilterValue) => {
    //resetting selections and applying filters for members
    setSelectedMembers([]);
    setSelectedRolesFilter(rolesFilterValue);
    setSelectedTeamsFilter(teamsFilterValue);

    let memberList = props.memberList;

    //apply team filter
    if (teamsFilterValue.length !== 0) {
      if (!teamsFilterValue.find((x) => x.value === "All")) {
        // if "All teams" filter is selected, don't filter
        const selectedTeamIDs = teamsFilterValue.map((x) => x.value);
        memberList = memberList.filter((x) =>
          selectedTeamIDs.includes(x.team.id)
        );
      }
    }

    //apply role filter
    if (rolesFilterValue.length !== 0) {
      const selectedRoleValues = rolesFilterValue.map((x) => x.value);
      memberList = memberList.filter((x) =>
        selectedRoleValues.includes(x.role)
      );
    }

    let detailedList = [];
    memberList.forEach((e) => {
      let labelStr = `${e.name} || ${e.role} || ${e.team.name}`;
      let tempData = {
        label: labelStr,
        value: e.id,
        name: e.name,
        team: e.team,
        role: e.role,
        profile_pic: e.profile_pic,
      };
      detailedList.push(tempData);
    });
    setDetailedMembersList(detailedList);
  };

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <IconButton
              onClick={() => {
                analytics.triggerEvent(
                  4625000105,
                  "close_icon_tap",
                  "assignment_rules_form",
                  "close_icon",
                  {}
                );
                props.setOpen(false);
              }}
              style={{
                zIndex: "9999",
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
            >
              <Close />
            </IconButton>

            <ModelWrapper>
              <Header>
                {getLocalizedText("lead_assignment_rule", "project")}
              </Header>

              <SubHeader>
                {getLocalizedText(
                  "rules_for_assigning_leads_on_import",
                  "project"
                )}
              </SubHeader>
              <Divider style={{ margin: "10px 0px" }} />
              <Wrapper onSubmit={handleSave} component={"form"}>
                <Column>
                  <InputWrapper>
                    <Label>{getLocalizedText("assignment_rule")}</Label>
                    <FormControl
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        name="assignment_rule"
                        onChange={(e) => {
                          setShowAlert(false);
                          setAlertMessage("");
                          setAssignmentRule(e.target.value);
                        }}
                        required
                        value={assignmentRule}
                        displayEmpty
                        disableUnderline
                        variant="outlined"
                        style={{
                          marginBottom: "20px",
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "0px",
                        }}
                      >
                        {props.assignmentRuleList.map((e, index) => {
                          return (
                            <MenuItem
                              style={{ fontSize: "14px" }}
                              id={index}
                              value={e}
                            >
                              {e.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </InputWrapper>

                  {assignmentRule.slug === "project_member_equally" && (
                    <InputWrapper>
                      <Label>{getLocalizedText("select_member")}</Label>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            "&": {
                              zIndex: "2",
                              maxWidth: "100%",
                              width: "100%",
                            },
                            "& .select-item": {
                              padding: 0,
                            },
                            "& .select-item.selected": {
                              background: "none",
                            },
                            "& .select-item:hover": {
                              background: "none",
                            },
                          }}
                        >
                          <MultiSelect
                            hasSelectAll={true}
                            valueRenderer={valueRenderer}
                            ItemRenderer={detailedOptionRenderer}
                            options={detailedMembersList}
                            value={selectedMembers}
                            className={"global_filter_multi_select"}
                            onChange={(e) => {
                              setShowAlert(false);
                              setAlertMessage("");
                              setSelectedMembers(e);
                            }}
                            labelledBy="Select Members"
                          />
                        </Box>

                        <IconButton
                          size="medium"
                          onClick={() => {
                            setOpenFilterMemberDialog(true);
                          }}
                          style={{
                            padding: "0px",
                            marginLeft: "4px",
                            marginRight: "-4px",
                          }}
                        >
                          <FilterAltIcon color="primary" />
                        </IconButton>
                      </Box>
                    </InputWrapper>
                  )}
                </Column>
                {assignmentRule.desc !== "" && (
                  <InputWrapper
                    style={{
                      height: "100%",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <Alert
                      style={{
                        padding: "0px 10px",
                        fontSize: "12px",
                        borderRadius: "0px 12px 12px 12px",
                      }}
                      severity="info"
                    >
                      {assignmentRule.desc}
                    </Alert>
                  </InputWrapper>
                )}
                {showAlert && (
                  <Alert
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      padding: "0px 20px",
                    }}
                    severity="warning"
                  >
                    {alertMessage}
                  </Alert>
                )}
                <ButtonWrapper>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000103,
                        "cancel_button_tap",
                        "assignment_rules_form",
                        "cancel_button",
                        {}
                      );
                      props.setOpen(false);
                    }}
                    style={{
                      font: "normal normal 600 12px Open Sans",
                      width: "130px",
                      textTransform: "none",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000104,
                        "save_button_tap",
                        "assignment_rules_form",
                        "save_button",
                        {}
                      );
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      font: "normal normal 600 12px Open Sans",
                      width: "130px",
                      textTransform: "none",
                    }}
                  >
                    {getLocalizedText("save")}
                  </Button>
                </ButtonWrapper>
              </Wrapper>
            </ModelWrapper>
          </Box>
        </ModelContainer>
      </Modal>

      {/* using same member filter modal which is used for lead assing - because we are using current project teams and roles are fixed stored locally */}
      {openFilterMemberDialog && (
        <MemberFilterModal
          open={openFilterMemberDialog}
          setOpen={setOpenFilterMemberDialog}
          handleSubmit={handleFilterSubmit}
          selectedTeamsFilter={selectedTeamsFilter}
          selectedRolesFilter={selectedRolesFilter}
          setSelectedTeamsFilter={setSelectedTeamsFilter}
          setSelectedRolesFilter={setSelectedRolesFilter}
        />
      )}
    </>
  );
};

export default observer(AssignmentRulesModal);
