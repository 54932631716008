import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { CircularProgress, Grid } from "@material-ui/core";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  OutlinedInput,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import MuiAlert from "@mui/material/Alert";
import {
  createProductManufacturerApi,
  updateProductManufacturerApi,
} from "../../Api";
import { getAccountLocalizedText, getLocalizedText } from "../../Functions";
import CustomFieldHeader from "../custom_tooltip_headers/CustomFieldHeader";
import { AddBoxOutlined, ExpandMore } from "@material-ui/icons";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 18px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;
const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled("textarea")`
  width: 100%;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;
  height: 80px;
  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const CreateBuilderComponent = ({
  setOpen,
  isEdit,
  setIsEdit,
  editableData,
  setRefresh,
}) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({
    name: "",
    desc: "",
    address: "",
    city: "",
    state: "",
    country: "",
  });
  const [tagsList, setTagsList] = useState([]);
  const [additionalDetailsList, setAdditionalDetailsList] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const checkIfAddTagsDisabled = () => {
    return !inputValue.trim();
  };

  const handleAddTags = (e) => {
    const value = inputValue;
    if (!value.trim()) return;
    if (!tagsList.includes(value)) {
      setTagsList([...tagsList, value]);
    }
    setInputValue("");
  };
  const handleDelete = (input) => {
    let tempList = tagsList.filter((item) => item !== input);
    setTagsList(tempList);
  };

  const handleAddAdditionalDetail = () => {
    const initialFields = { field: "", value: "" };

    const newList = [
      ...additionalDetailsList,
      { id: uuidv4(), ...initialFields },
    ];

    setAdditionalDetailsList(newList);
  };
  const handleDeleteAdditionalDetail = (id) => {
    setAdditionalDetailsList((prev) =>
      prev.filter((detail) => detail.id !== id)
    );
  };
  const handleAdditionalDetailChange = (id, field, value) => {
    setAdditionalDetailsList((prev) =>
      prev.map((detail) =>
        detail.id === id ? { ...detail, [field]: value } : detail
      )
    );
  };

  const updateBuilder = async (payload) => {
    let response = await updateProductManufacturerApi({
      manufacturerID: editableData["id"],
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const createBuilder = async (payload) => {
    let response = await createProductManufacturerApi({
      payload: payload,
    });

    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const additionalDetails = {};
    additionalDetailsList.forEach((entry) => {
      additionalDetails[entry.field] = entry.value;
    });

    const payload = {
      ...details,
      tags: tagsList,
      additional_details: additionalDetails,
    };

    if (isEdit === true) {
      await updateBuilder(payload);
    } else {
      await createBuilder(payload);
    }
  };

  const init = async () => {
    if (isEdit === true) {
      let tempObj = {
        name:
          editableData.hasOwnProperty("name") && editableData.name !== null
            ? editableData.name
            : "",
        desc:
          editableData.hasOwnProperty("desc") && editableData.desc !== null
            ? editableData.desc
            : "",
        address:
          editableData.hasOwnProperty("address") &&
          editableData.address !== null
            ? editableData.address
            : "",

        city:
          editableData.hasOwnProperty("city") && editableData.city !== null
            ? editableData.city
            : "",
        state:
          editableData.hasOwnProperty("state") && editableData.state !== null
            ? editableData.state
            : "",
        country:
          editableData.hasOwnProperty("country") &&
          editableData.country !== null
            ? editableData.country
            : "",
      };
      setDetails(tempObj);
      let tempTagsList =
        editableData.hasOwnProperty("tags") &&
        editableData.tags !== null &&
        Array.isArray(editableData.tags)
          ? editableData.tags
          : [];
      setTagsList(tempTagsList);
      let existingAdditionalDetails =
        editableData.hasOwnProperty("additional_details") &&
        editableData.additional_details !== null &&
        typeof editableData.additional_details === "object"
          ? editableData.additional_details
          : {};
      let tempAdditionalDetails = [];
      for (const key in existingAdditionalDetails) {
        tempAdditionalDetails.push({
          id: uuidv4(),
          field: key,
          value: existingAdditionalDetails[key],
        });
      }

      setAdditionalDetailsList(tempAdditionalDetails);
    }
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };

  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            {isEdit ? (
              <ModelHeader>{getLocalizedText("update_details")}</ModelHeader>
            ) : (
              <ModelHeader>
                {getAccountLocalizedText("create_manufacturer")}
              </ModelHeader>
            )}
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModelWrapper
            component={"form"}
            onSubmit={handleSubmit}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <InputContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{getLocalizedText("name")}*</Label>
                    <Input
                      value={details.name}
                      required
                      type="text"
                      placeholder="Enter Name"
                      name="name"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{getLocalizedText("description")}</Label>
                    <TextArea
                      value={details.desc}
                      type="text"
                      placeholder="Enter Description"
                      name="desc"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <CustomFieldHeader
                      header={getLocalizedText("tags")}
                      tooltip={"Add tags"}
                    />
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <OutlinedInput
                        style={{
                          height: "30px",
                          width: "100%",
                          borderRadius: "0px",
                        }}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        type="text"
                        placeholder="Type something"
                        name="tags"
                        value={inputValue}
                        onChange={(e) => {
                          setInputValue(e.target.value);
                        }}
                      />
                      <AddBoxOutlined
                        color={
                          checkIfAddTagsDisabled() ? "disabled" : "primary"
                        }
                        onClick={
                          checkIfAddTagsDisabled() ? null : handleAddTags
                        }
                        style={{
                          marginLeft: "5px",
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                          transform: "scale(1.3)",
                        }}
                      />
                    </Box>
                    {tagsList.length > 0 && (
                      <Box
                        style={{
                          padding: "12px 0px",
                          display: "flex",
                          alignItems: "flex-start",
                          gap: "8px",
                          flexWrap: "wrap",
                        }}
                      >
                        {tagsList.map((chip, index) => {
                          return (
                            <Box key={index}>
                              <Chip
                                index={index}
                                label={chip}
                                onDelete={() => handleDelete(chip)}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                  </InputWrapper>
                </Grid>

                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="company-details-content"
                      id="company-details-header"
                    >
                      {getLocalizedText("address_details")}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <InputWrapper>
                            <Label>{getLocalizedText("address")}</Label>

                            <TextArea
                              value={details.address}
                              type="text"
                              placeholder="Enter Address"
                              name="address"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={6}>
                          <InputWrapper>
                            <Label>{getLocalizedText("city")}</Label>
                            <Input
                              value={details.city}
                              type="text"
                              placeholder="Enter City"
                              name="city"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={6}>
                          <InputWrapper>
                            <Label>{getLocalizedText("state")}</Label>
                            <Input
                              value={details.state}
                              type="text"
                              placeholder="Enter State"
                              name="state"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={12}>
                          <InputWrapper>
                            <Label>{getLocalizedText("country")}</Label>
                            <Input
                              value={details.country}
                              type="text"
                              placeholder="Enter Country"
                              name="country"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="additional-details-content"
                      id="additional-details-header"
                    >
                      {getLocalizedText("additional_details")}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              overflowY: "auto",
                            }}
                          >
                            {additionalDetailsList.map((row) => (
                              <Box
                                key={row.id}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                  marginBottom: "6px",
                                }}
                              >
                                <OutlinedInput
                                  required
                                  style={{ height: "30px" }}
                                  inputProps={{
                                    maxLength: 2000,
                                    "aria-label": "weight",
                                    style: {
                                      padding: "0px 12px",
                                      fontSize: "12px",
                                    },
                                  }}
                                  placeholder="Name"
                                  value={row.field}
                                  onChange={(e) => {
                                    handleAdditionalDetailChange(
                                      row.id,
                                      "field",
                                      e.target.value
                                    );
                                  }}
                                  size="small"
                                  sx={{ flex: 1 }}
                                />
                                <OutlinedInput
                                  required
                                  style={{ height: "30px" }}
                                  inputProps={{
                                    maxLength: 25,
                                    "aria-label": "weight",
                                    style: {
                                      padding: "0px 12px",
                                      fontSize: "12px",
                                    },
                                  }}
                                  placeholder="Value"
                                  value={row.value}
                                  onChange={(e) => {
                                    handleAdditionalDetailChange(
                                      row.id,
                                      "value",
                                      e.target.value
                                    );
                                  }}
                                  size="small"
                                  sx={{ flex: 1 }}
                                />

                                <IconButton
                                  onClick={() =>
                                    handleDeleteAdditionalDetail(row.id)
                                  }
                                  color="error"
                                  size="small"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            ))}

                            <Button
                              style={{
                                textTransform: "none",
                                fontWeight: "bold",
                                width: "100%",
                              }}
                              color="primary"
                              variant="text"
                              onClick={() => {
                                handleAddAdditionalDetail();
                              }}
                            >
                              <AddIcon /> Add
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </InputContainer>
            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500 }}
            >
              <Toolbar style={{ width: 500, position: "relative" }}>
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    type="button"
                    variant="contained"
                    color="inherit"
                    style={{
                      backgroundColor: "#EFEFF4",
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {getLocalizedText("submit")}
                  </Button>
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </ModelWrapper>
        ) : (
          <MessageWrapper>
            {isEdit ? (
              <Message>Builder info updated successfully!</Message>
            ) : (
              <Message>Builder created successfully!</Message>
            )}

            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="inherit"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        )}
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(CreateBuilderComponent);
