import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Tab,
  Tabs,
  tabsClasses,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  createAdminQuotationApi,
  saveAdminQuotationTemplateApi,
} from "../../Api.js";
import CustomErrorMessageDialog from "../alert_dialogue/CustomErrorMessageDialog.js";
import RenderContentSection from "./create_quote_template_components/RenderContentSection.js";
import rootStore from "../../stores/RootStore.js";
import AddQuoteTemplateWidgetDialog from "./create_quote_template_components/dialogs/AddQuoteTemplateWidgetDialog.js";
import { v4 as uuidv4 } from "uuid";
import BasicDetailsSection from "./create_quote_template_components/BasicDetailsSection.js";
import FooterDetailsCard from "./create_quote_template_components/card_widgets/FooterDetailsCard.js";
import { getLocalizedText } from "../../Functions.js";

const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  width: 100%;
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
  padding: 16px;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CloneAdminCustomQuoteTemplateDialog({
  open,
  handleClose,
  setRefresh,
  editableData,
  scope,
}) {
  const [tabIdx, setTabIdx] = useState(0);
  const [widgetList, setWidgetList] = useState([]);
  const [footerWidgetList, setFooterWidgetList] = useState([]);
  const [pdfStyles, setPdfStyles] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [headerWidgetList, setHeaderWidgetList] = useState([]);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const [loading, setLoading] = useState(true);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({
    company_logo: "",
    company_name: "",
    company_email: "",
    company_address: "",
    company_website: "",
    show_in_header: true,
  });
  const [openAddWidgetDialog, setOpenAddWidgetDialog] = useState(false);
  const [addType, setAddType] = useState("");
  const [selectedWidgetData, setSelectedWidgetData] = useState({});
  const [basicDetails, setBasicDetails] = useState({
    name: "",
    quotation_template: true,
    invoice_template: false,
  });

  const init = async () => {
    let schemaData = {};

    schemaData = editableData["schema"];

    let tempPdfStyles = { ...schemaData["styles"] };
    let tempCompanyDetails =
      schemaData.hasOwnProperty("company_details") &&
      schemaData["company_details"] !== null
        ? schemaData["company_details"]
        : {
            company_logo: "",
            company_name: "",
            company_email: "",
            company_address: "",
            company_website: "",
            show_in_header: true,
          };

    if (tempCompanyDetails.hasOwnProperty("show_in_header") === false) {
      tempCompanyDetails["show_in_header"] = true;
    }
    setCompanyDetails(tempCompanyDetails);
    setPdfStyles(tempPdfStyles);
    setHeaderWidgetList([...schemaData["header"]["components"]]);
    setFooterWidgetList([...schemaData["footer"]["components"]]);
    setWidgetList([...schemaData["components"]]);
    let templateBasicDetails = {
      name: "",
      quotation_template:
        editableData.hasOwnProperty("quotation_template") &&
        editableData["quotation_template"] !== null
          ? editableData["quotation_template"]
          : true,
      invoice_template:
        editableData.hasOwnProperty("invoice_template") &&
        editableData["invoice_template"] !== null
          ? editableData["invoice_template"]
          : false,
    };

    // prefill the template name only if its edit form
    if (scope === "edit") {
      templateBasicDetails["name"] =
        editableData.hasOwnProperty("name") && editableData["name"] !== null
          ? editableData["name"]
          : "";
    }
    setBasicDetails(templateBasicDetails);
    setLoading(false);
  };
  React.useEffect(() => {
    init();
  }, []);

  const createTemplate = async () => {
    let payload = {
      name: basicDetails["name"],
      quotation_template: basicDetails["quotation_template"],
      invoice_template: basicDetails["invoice_template"],
    };

    payload["schema"] = {
      components: widgetList,
      footer: {
        ...editableData["schema"]["footer"],
        components: footerWidgetList,
      },
      header: {
        ...editableData["schema"]["header"],
        components: headerWidgetList,
      },
      styles: pdfStyles,
      template_type: editableData["schema"]["template_type"],
      template_version: 2,
      company_details: companyDetails,
    };

    let response = await createAdminQuotationApi({
      payload: payload,
      projectID: rootStore.authStore.projectId,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setShowError(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const updateTemplate = async () => {
    let payload = {
      schema: {
        components: widgetList,
        footer: {
          ...editableData["schema"]["footer"],
          components: footerWidgetList,
        },
        header: {
          ...editableData["schema"]["header"],
          components: headerWidgetList,
        },
        styles: pdfStyles,
        template_type: editableData["schema"]["template_type"],
        template_version: 2,
        company_details: companyDetails,
      },
    };
    let response = await saveAdminQuotationTemplateApi({
      payload: payload,
      projectID: rootStore.authStore.projectId,
      templateID: editableData["id"],
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setShowError(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const handleSave = async () => {
    setIsFormSubmitting(true);

    if (hasValidTemplateData()) {
      if (scope === "edit") {
        await updateTemplate();
      } else {
        await createTemplate();
      }
    } else {
      setShowError(true);
    }
    setIsFormSubmitting(false);
  };

  const hasValidTemplateData = () => {
    if (basicDetails["name"] === null || basicDetails["name"] === "") {
      setErrorMessage(
        getLocalizedText("please_enter_template_name_in_basic_details_tab")
      );
      return false;
    }
    return true;
  };

  const generateTableData = (rowCount, columnCount) => {
    let tempTableData = [];
    const initialWidth = parseFloat(100 / columnCount);
    for (let i = 0; i < rowCount; i++) {
      let tempRowData = [];
      for (let j = 0; j < columnCount; j++) {
        let tempCellData = {
          id: `cell-${i}-${j}`,
          style: {
            width: initialWidth,
            border: "1px solid black",
            cursor: "pointer",
            padding: "2px",
            backgroundColor: "#ffffff",
          },
          content: "",
        };

        tempRowData.push(tempCellData);
      }
      tempTableData.push(tempRowData);
    }
    return tempTableData;
  };

  const getTitleText = () => {
    if (scope === "edit") {
      return getLocalizedText("update_template");
    }
    return getLocalizedText("clone_template");
  };

  const getSuccessMessage = () => {
    if (scope === "edit") {
      return getLocalizedText("template_updated_successfully");
    }
    return getLocalizedText("template_created_successfully");
  };

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      disableEscapeKeyDown={true}
    >
      {!loading ? (
        !isSubmitSuccess ? (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <AppBar
              elevation={0}
              component={"nav"}
              position="sticky"
              color="inherit"
              sx={{ top: 0, bottom: "auto", width: "100%", right: 0 }}
            >
              <Toolbar style={{ padding: "16px", width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Header>{getTitleText()}</Header>
                </Box>
              </Toolbar>
            </AppBar>
            <Box
              component="main"
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "calc(100vh - 64px)",
                  width: "300px",
                  position: "sticky",
                  top: "64px",
                  left: "0px",
                }}
              >
                <Box>
                  <Tabs
                    orientation="vertical"
                    sx={{
                      [`& .${tabsClasses.scrollButtons}`]: {
                        "&.Mui-disabled": { opacity: 0.3 },
                      },

                      width: "100%",
                    }}
                    variant="scrollable"
                    value={tabIdx}
                    textColor="primary"
                    indicatorColor="primary"
                    scrollButtons={"auto"}
                    onChange={(event, newValue) => {
                      setTabIdx(newValue);
                    }}
                  >
                    <Tab
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#E7EEFA",
                          color: "#4079DA",
                          font: "normal normal 600 14px Open Sans",
                          textTransform: "capitalize",
                        },

                        backgroundColor: "white",
                        color: "#4D4E4F",
                        font: "normal normal 600 14px Open Sans",
                        textTransform: "capitalize",
                      }}
                      label={"Basic Details"}
                    />

                    <Tab
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#E7EEFA",
                          color: "#4079DA",
                          font: "normal normal 600 14px Open Sans",
                          textTransform: "capitalize",
                        },

                        backgroundColor: "white",
                        color: "#4D4E4F",
                        font: "normal normal 600 14px Open Sans",
                        textTransform: "capitalize",
                      }}
                      label={"Content"}
                    />
                    <Tab
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#E7EEFA",
                          color: "#4079DA",
                          font: "normal normal 600 14px Open Sans",
                          textTransform: "capitalize",
                        },

                        backgroundColor: "white",
                        color: "#4D4E4F",
                        font: "normal normal 600 14px Open Sans",
                        textTransform: "capitalize",
                      }}
                      label={"Footer"}
                    />
                  </Tabs>
                  <Divider />
                </Box>
                <Box sx={{ height: "100%" }} />
                <Box>
                  <Divider />
                  <ButtonWrapper>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="inherit"
                      style={{
                        textTransform: "capitalize",
                        width: "100%",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>

                    <Button
                      onClick={() => {
                        handleSave();
                      }}
                      type="button"
                      variant="contained"
                      color="primary"
                      style={{
                        textTransform: "capitalize",
                        width: "100%",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("save")}
                    </Button>
                  </ButtonWrapper>
                </Box>
              </Box>

              {(() => {
                if (tabIdx === 0) {
                  return (
                    <BasicDetailsSection
                      basicDetails={basicDetails}
                      setBasicDetails={setBasicDetails}
                      companyDetails={companyDetails}
                      handleCompanyDetails={(key, value) => {
                        let tempCompanyDetails = { ...companyDetails };
                        tempCompanyDetails[key] = value;
                        setCompanyDetails(tempCompanyDetails);
                      }}
                      headerWidgetList={headerWidgetList}
                      setHeaderWidgetList={setHeaderWidgetList}
                      scope={scope}
                    />
                  );
                } else if (tabIdx === 1) {
                  return (
                    <RenderContentSection
                      widgetList={widgetList}
                      setWidgetList={setWidgetList}
                      handleAddAbove={(widget) => {
                        setAddType("above");
                        setSelectedWidgetData(widget);
                        setOpenAddWidgetDialog(true);
                      }}
                      handleAddBelow={(widget) => {
                        setAddType("below");
                        setSelectedWidgetData(widget);
                        setOpenAddWidgetDialog(true);
                      }}
                      handleAdd={() => {
                        setAddType("direct");
                        setOpenAddWidgetDialog(true);
                      }}
                      handleAddPageBreak={(widget) => {
                        let widgetData = {
                          id: uuidv4(),
                          type: "page_break",
                          properties: {},
                          content: "",
                        };

                        let tempList = [...widgetList];
                        let relativeWidgetIndex = tempList.findIndex(
                          (entry) => entry["id"] === widget["id"]
                        );
                        if (relativeWidgetIndex !== -1) {
                          if (relativeWidgetIndex === 0) {
                            tempList.unshift(widgetData);
                          } else {
                            tempList.splice(relativeWidgetIndex, 0, widgetData); // insert relative to existing widget index
                          }
                        }
                        setWidgetList(tempList);
                      }}
                    />
                  );
                } else if (tabIdx === 2) {
                  return (
                    <FooterDetailsCard
                      setWidgetList={setFooterWidgetList}
                      widgetList={footerWidgetList}
                    />
                  );
                }
              })()}
            </Box>

            {showError && (
              <CustomErrorMessageDialog
                setOpen={setShowError}
                message={errorMessage}
                open={showError}
              />
            )}

            {openAddWidgetDialog && (
              <AddQuoteTemplateWidgetDialog
                handleAddTemplateWidget={(widgetType, details) => {
                  let widgetData = {
                    id: uuidv4(),
                    type: widgetType,
                    properties: {},
                  };
                  if (widgetType === "table") {
                    let tableData = generateTableData(
                      parseInt(details["number_of_rows"]),
                      parseInt(details["number_of_columns"])
                    );
                    widgetData["content"] = tableData;
                  } else if (widgetType === "image") {
                    widgetData["content"] = { image_url: "" };

                    widgetData["properties"]["size"] = 100;
                    widgetData["properties"]["alignment"] = "center";
                  } else if (widgetType === "text") {
                    widgetData["content"] = "";
                  }

                  let tempList = [...widgetList];
                  if (addType === "direct") {
                    tempList.push(widgetData);
                  } else {
                    let relativeWidgetIndex = tempList.findIndex(
                      (entry) => entry["id"] === selectedWidgetData["id"]
                    );
                    if (relativeWidgetIndex !== -1) {
                      if (addType === "above") {
                        if (relativeWidgetIndex === 0) {
                          tempList.unshift(widgetData);
                        } else {
                          tempList.splice(relativeWidgetIndex, 0, widgetData); // insert relative to existing widget index
                        }
                      } else if (addType === "below") {
                        if (relativeWidgetIndex === tempList.length - 1) {
                          tempList.push(widgetData);
                        } else {
                          tempList.splice(
                            relativeWidgetIndex + 1,
                            0,
                            widgetData
                          ); // insert relative to existing widget index
                        }
                      }
                    }
                  }
                  setWidgetList(tempList);
                  setOpenAddWidgetDialog(false);
                }}
                open={openAddWidgetDialog}
                setOpen={setOpenAddWidgetDialog}
              />
            )}

            <Backdrop
              open={isFormSubmitting}
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
              <CircularProgress style={{ color: "white" }} />
            </Backdrop>
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100vh",
              display: "Flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper>
              <Message>{getSuccessMessage()}</Message>

              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="inherit"
                style={{
                  marginTop: "20px",
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </Box>
        )
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "Flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
}
