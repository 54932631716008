import {
  AccessAlarmsTwoTone,
  CloseOutlined,
  EventNote,
  Visibility,
} from "@material-ui/icons";
import { Box, Button, Divider, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { getFilterColsApi } from "../../Api";
import rootStore from "../../stores/RootStore";
import { IconButton } from "@material-ui/core";
import WabaNotificationComponent from "./WabaChatNotification/WabaNotificationComponent";
import { checkIfProjectHasWabaProvider, validateDisabledFeature } from "../../Functions";
const Container = styled(Box)`
  width: 286px;
  height: 40vh;
  overflow-y: auto;
  display: flex;
  background-color: white;
  flex-direction: column;
  margin-top: -8px;
  ::-webkit-scrollbar {
    width: 4px;
  }
  position: relative;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  /* padding: 20px; */
  padding-top: 0px;
  /* margin-top: 50px; */
`;
const ItemWrapper = styled(Box)`
  display: flex;
  padding: 20px;
`;
const ItemLeft = styled(Box)`
  margin-right: 20px;
`;
const ItemRight = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Text2 = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Poppins;
  color: #2b2b2b;
  margin-bottom: 2px;
`;
const Text3 = styled(Typography)`
  margin: 0px;
  font: normal normal 500 10px Poppins;
  color: #a3a3a3;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #185dd2;
  cursor: pointer;
  text-transform: none;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  /* justify-content: flex-end; */
`;
const NotificationComponent = (props) => {
  let navigate = useNavigate();
  const handleView = (e) => {
    rootStore.notificationState.updateNotificationViewedList(
      e.notification_type
    );
    navigate(`/notification/${e.notification_type}/${e.title}`);
    props.setAnchorEl(null);
  };
  const handleDismiss = (e) => {
    rootStore.notificationState.dismissNotification(e.notification_type);
  };

  const getIconColor = (item) => {
    if (
      rootStore.notificationState.notificationState.notitications_viewed.includes(
        item.notification_type
      )
    ) {
      return "default";
    } else {
      return "primary";
    }
  };

  const RegularNotificationComponent = ({ e, i }) => {
    return (
      <>
        <ItemWrapper
          style={{
            backgroundColor:
              rootStore.notificationState.notificationState.notitications_viewed.includes(
                e.notification_type
              )
                ? "#F6F6F6"
                : (i + 1) % 2 !== 0
                ? "#f9f9fc"
                : "white",
          }}
        >
          <ItemLeft>
            <EventNote
              style={{
                color: "#757575",
              }}
            />
          </ItemLeft>
          <ItemRight>
            <Text2
              style={{
                color:
                  rootStore.notificationState.notificationState.notitications_viewed.includes(
                    e.notification_type
                  )
                    ? "#a3a3a3"
                    : "#2b2b2b",
              }}
            >
              {e.title}
            </Text2>
            <Text3>{e.subtitle}</Text3>
            <Row style={{ marginTop: "8px", columnGap: "10px" }}>
              <IconButton
                style={{
                  border: "1px solid #89b7e7",
                  borderRadius: "8px",
                  padding: "8px",
                }}
                color={getIconColor(e)}
                variant="outlined"
                onClick={() => handleView(e)}
              >
                <Visibility style={{ width: "16px", height: "16px" }} />
              </IconButton>

              <IconButton
                style={{
                  border: "1px solid #89b7e7",
                  borderRadius: "8px",
                  padding: "8px",
                }}
                color={getIconColor(e)}
                onClick={() => {
                  rootStore.notificationState.setSnoozeForNotification(
                    e.notification_type
                  );
                  props.setAnchorEl(null);
                }}
              >
                <AccessAlarmsTwoTone
                  style={{ width: "16px", height: "16px" }}
                />
                <Text
                  style={{
                    color:
                      rootStore.notificationState.notificationState.notitications_viewed.includes(
                        e.notification_type
                      )
                        ? "#a3a3a3"
                        : "#3f51b5",
                  }}
                >
                  1 Hour
                </Text>
              </IconButton>

              <IconButton
                style={{
                  border: "1px solid #89b7e7",
                  borderRadius: "8px",
                  padding: "8px",
                }}
                color={
                  rootStore.notificationState.notificationState.notitications_viewed.includes(
                    e.notification_type
                  )
                    ? "disabled"
                    : "secondary"
                }
                onClick={() => handleDismiss(e)}
              >
                <CloseOutlined style={{ width: "16px", height: "16px" }} />
              </IconButton>
            </Row>
          </ItemRight>
        </ItemWrapper>
        <Divider />
      </>
    );
  };

  //rendering whatsapp notification component if waba access is there else, rendering only regular notification component
  return validateDisabledFeature("waba") && checkIfProjectHasWabaProvider() ? (
    <Container>
      {(rootStore.notificationState.notificationList.hasOwnProperty("items") &&
        rootStore.notificationState.notificationList["items"].length > 0) ||
      (rootStore.wabaChatNotificationState.notificationState.canShowBadge &&
        rootStore.wabaChatNotificationState.getNotificationsForProject(
          rootStore.authStore.currentProject.id
        ).length > 0) ? (
        <>
          {/* WhatsApp Messages Section */}
          {rootStore.wabaChatNotificationState.notificationState
            .canShowBadge && (
            <WabaNotificationComponent
              setShowWabaSidepanel={props.setShowWabaSidepanel}
              showWabaSidePanel={props.showWabaSidePanel}
              setAnchorEl={props.setAnchorEl}
            />
          )}

          {/* Notifications Section */}
          {rootStore.notificationState.notificationList.hasOwnProperty(
            "items"
          ) &&
            rootStore.notificationState.notificationList["items"].length >
              0 && (
              <Wrapper>
                {rootStore.notificationState.notificationList["items"].map(
                  (e, i) => {
                    return <RegularNotificationComponent e={e} i={i} />;
                  }
                )}
              </Wrapper>
            )}
        </>
      ) : (
        <Wrapper
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ItemWrapper>
            <Text3>There's nothing here, yet</Text3>
          </ItemWrapper>
        </Wrapper>
      )}
    </Container>
  ) : (
    <Container>
      {rootStore.notificationState.notificationList.hasOwnProperty("items") &&
      rootStore.notificationState.notificationList["items"].length > 0 ? (
        <Wrapper>
          {rootStore.notificationState.notificationList["items"].map((e, i) => {
            return <RegularNotificationComponent e={e} i={i} />;
          })}
        </Wrapper>
      ) : (
        <Wrapper
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ItemWrapper>
            <Text3>There's nothing here, yet</Text3>
          </ItemWrapper>
        </Wrapper>
      )}
    </Container>
  );
};

export default observer(NotificationComponent);
