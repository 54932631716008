import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Grid, makeStyles, Button } from "@material-ui/core";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  OutlinedInput,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import PreviewIcon from "@mui/icons-material/Preview";
import CustomFieldHeader from "../custom_tooltip_headers/CustomFieldHeader";
import PreviewImageDialog from "../files_page_components/PreviewImageDialog";
import {
  businessWhatsappLanguages,
  businessWhatsappTemplateCategories,
} from "../../Db";
import MetaWABAScreen from "./provider_screens/MetaWABAScreen";
import VariableSampleTextScreen from "./VariableSampleTextScreen";
import {
  consoleLogger,
  dataRenderer,
  formatPhoneNumberForWhatsApp,
} from "../../Functions";

const WarningAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="warning"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  ${`height: calc(100vh - 128px);`};
  padding-top: 0px;
  overflow-y: auto;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 24px;
  gap: 20px;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const AddWhatsappBusinessTemplateSidepanel = ({
  setOpen,
  isEdit,
  editableData,
  setPreviewOpen,
  postSubmitCallback,
  isSubmitSuccess,
  isSubmitFail,
  setIsSubmitFail,
  errorMessage,
  submitLoading,
  selectedImages,
  setSelectedImages,
  setSelectedFileUrl,
  selectedFileUrl,
  setPreviewComponents,
  providersList,
}) => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [details, setDetails] = useState({
    language: "",
    template_title: "",
    template_message: "",
    category: "",
    whatsapp_provider: "",
    enable_for_stages: [],
  });
  const [metaWabaDetails, setMetaWabaDetails] = useState({
    header_format: "None",
    header_text: "",
    body_text: "",
    footer_text: "",
  });
  const [metaWabaButtons, setMetaWabaButtons] = useState([]);
  const [showTextWarning, setShowTextWarning] = useState(false);
  const [viewImageDialog, setViewImageDialog] = useState(false);
  const [bodyTextError, setBodyTextError] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [variableMapping, setVariableMapping] = useState({});

  //variable states
  const [variableDetails, setVariableDetails] = useState({
    header: [],
    body: [],
    button: {
      url: [],
      phone: [],
    },
  });

  //MetaWABAScreen state
  const [footerEditable, setFooterEditable] = useState(false);

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const RenderButton = () => {
    if (areMetaWabaVariablesPresent()) {
      if (activeStep === 0) {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "20px",
              width: "fit-content",
            }}
          >
            <Button
              type="button"
              onClick={handleClose}
              variant="outlined"
              color="primary"
              style={{
                textTransform: "none",
                width: "120px",
                fontWeight: "bold",
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{
                textTransform: "none",
                width: "120px",
                fontWeight: "bold",
              }}
            >
              Next
            </Button>
          </Box>
        );
      } else if (activeStep === 1) {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "20px",
              width: "fit-content",
            }}
          >
            <Button
              onClick={handlePrevious}
              type="button"
              variant="outlined"
              color="primary"
              style={{
                textTransform: "none",
                width: "120px",
                fontWeight: "bold",
              }}
            >
              Prev
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={
                submitLoading ||
                details["whatsapp_provider"]?.provider_name !== "Meta WABA"
              }
              color="primary"
              style={{
                textTransform: "none",
                width: "120px",
                fontWeight: "bold",
              }}
            >
              Submit
            </Button>
          </Box>
        );
      }
    } else {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "fit-content",
          }}
        >
          <Button
            type="button"
            onClick={handleClose}
            variant="outlined"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={
              submitLoading ||
              details["whatsapp_provider"]?.provider_name !== "Meta WABA"
            }
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            Submit
          </Button>
        </Box>
      );
    }
  };

  const getDefaultMarketingFooter = (provider = null) => {
    if (provider === null) {
      provider = details["whatsapp_provider"];
    }
    const keyword =
      provider.hasOwnProperty("optout_words") &&
      provider["optout_words"].length !== 0
        ? provider["optout_words"][0]
        : "Stop"; //check provider data for keyword. using "Stop" if not found

    return `Type '${keyword}' to opt out`;
  };

  const getOptOutKeywordsForProvider = (provider = null) => {
    if (provider === null) {
      provider = details["whatsapp_provider"];
    }
    const keywords =
      provider.hasOwnProperty("optout_words") &&
      provider["optout_words"].length !== 0
        ? provider["optout_words"]
        : [];

    return keywords;
  };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      setSelectedImages((prevImages) => [...prevImages, ...files]);
    }
  };
  const handleImagePreview = (image) => {
    const objectURL = isEdit ? image : URL.createObjectURL(image);
    setSelectedFileUrl(objectURL);
    setViewImageDialog(true);
  };
  const handleRemoveImage = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  useEffect(() => {
    setup();
    setLoading(false);
  }, []);

  const setup = () => {
    if (isEdit) {
      let newDetails = {
        template_title:
          editableData.template_title !== null
            ? editableData.template_title
            : "",
        category: editableData.category !== null ? editableData.category : "",
        language: editableData.language !== null ? editableData.language : "",
        whatsapp_provider:
          editableData.whatsapp_provider !== null
            ? editableData.whatsapp_provider.name
            : "",
        enable_for_stages: [],
      };
      let newMetaWabaDetails = {
        header_format: "None",
        header_text: "",
        body_text: "",
        footer_text: "",
      };
      let newButtons = [];
      const headerComponent = getComponentByType(
        "header",
        editableData["components"]
      )
        ? getComponentByType("header", editableData["components"])
        : {};
      const bodyComponent = getComponentByType(
        "body",
        editableData["components"]
      );
      const footerComponent = getComponentByType(
        "footer",
        editableData["components"]
      )
        ? getComponentByType("footer", editableData["components"])
        : {};
      const buttonComponent = getComponentByType(
        "buttons",
        editableData["components"]
      )
        ? getComponentByType("buttons", editableData["components"])
        : [];

      if (editableData.hasOwnProperty("components")) {
        if (Object.keys(headerComponent).length !== 0) {
          newMetaWabaDetails["header_format"] =
            headerComponent?.["format"] !== null
              ? toTitleCase(headerComponent["format"])
              : "";

          if (headerComponent?.["format"].toLowerCase() === "image") {
            setSelectedImages([headerComponent.example.header_handle]);
          } else {
            newMetaWabaDetails["header_text"] =
              headerComponent?.["text"] !== null ? headerComponent["text"] : "";
          }
        }

        newMetaWabaDetails["body_text"] =
          bodyComponent?.["text"] !== null ? bodyComponent["text"] : "";
        if (Object.keys(footerComponent).length !== 0) {
          newMetaWabaDetails["footer_text"] =
            footerComponent?.["text"] !== null ? footerComponent["text"] : "";
        }
        if (buttonComponent.length !== 0) {
          for (let i = 0; i < buttonComponent["buttons"].length; i++) {
            let buttonItem = {};

            const item = buttonComponent["buttons"][i];
            if (item["type"].toLowerCase() === "phone_number") {
              buttonItem["option"] = "Call";
            } else if (item["type"].toLowerCase() === "url") {
              buttonItem["option"] = "Visit";
            }

            if (buttonItem["option"] === "Call") {
              buttonItem["phone_number"] = item["phone_number"];
            }

            if (buttonItem["option"] === "Visit") {
              buttonItem["url"] = item["url"];
            }
            buttonItem["text"] = item["text"];

            newButtons.push(buttonItem);
          }
        }
      }

      setDetails(newDetails);
      setMetaWabaDetails(newMetaWabaDetails);
      setMetaWabaButtons(newButtons);
    } else {
      //pre-selecting the first whatsapp provider from the provider list on create
      if (providersList.length !== 0 && providersList[0].hasOwnProperty("id")) {
        let newDetails = {
          ...details,
          whatsapp_provider: providersList[0],
        };
        setDetails(newDetails);
      }
    }
  };

  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  };

  const getComponentByType = (type, components) => {
    return components.find((x) => x.type.toLowerCase() === type.toLowerCase());
  };

  const shouldDisablePreview = () => {
    if (areMetaWabaVariablesPresent()) {
      // only checking body variables
      const allVarPlaceholders = getAllPlaceholders(
        metaWabaDetails["body_text"]
      );
      const nonEmptyBodyDetails = Object.values(variableDetails["body"]).filter(
        (value) => value !== ""
      );

      return nonEmptyBodyDetails.length !== allVarPlaceholders.length;
    } else {
      return false;
    }
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const handleClose = () => {
    setSelectedImages([]);
    setOpen(false);
  };

  const generateComponents = (payloadType) => {
    //generate meta waba payload structure
    let components = [];

    let header = {
      type: "HEADER",
      format: metaWabaDetails["header_format"].toUpperCase(),
    };
    let body = {
      type: "BODY",
      text: metaWabaDetails["body_text"],
    };
    let footer = {
      type: "FOOTER",
      text: metaWabaDetails["footer_text"],
    };
    let buttons = {
      type: "BUTTONS",
      buttons: [],
    };

    if (
      metaWabaDetails["header_format"] !== "" &&
      metaWabaDetails["header_format"].toLowerCase() !== "none"
    ) {
      if (metaWabaDetails["header_format"].toLowerCase() === "text") {
        header["text"] = metaWabaDetails["header_text"];
      } else if (metaWabaDetails["header_format"].toLowerCase() === "image") {
        header.example = {};

        if (payloadType === "preview") {
          const objectURL = URL.createObjectURL(selectedImages[0]);
          header["example"]["header_handle"] = [objectURL];
        } else {
          header["example"]["header_handle"] = selectedImages[0];
        }
      }
      components.push(header);
    }

    components.push(body); //always push body

    if (metaWabaDetails["footer_text"] !== "") {
      components.push(footer);
    }

    if (metaWabaButtons.length !== 0) {
      const buttonData = metaWabaButtons.map((item) => {
        let button = {
          text: item["text"],
          type: item["option"] === "Call" ? "PHONE_NUMBER" : "URL",
        };
        if (item["option"] === "Call") {
          button["phone_number"] = formatPhoneNumberForWhatsApp(
            item["phone_number"]
          );
        }
        if (item["option"] === "Visit") {
          button["url"] = item["url"];
        }
        return button;
      });
      buttons["buttons"] = buttonData;
      components.push(buttons);
    }

    return components;
  };

  const getAllPlaceholders = (str) => {
    const regex = /\{\{\d+\}\}/g;
    return str.match(regex) || [];
  };

  const hasVariablePlaceholders = (str) => {
    const regex = /\{\{\d+\}\}/g;
    return regex.test(str);
  };

  const areMetaWabaVariablesPresent = () => {
    let bool = false;

    if (metaWabaDetails["body_text"] !== "") {
      bool = hasVariablePlaceholders(metaWabaDetails["body_text"]);
    }

    //variables currently disabled for header and buttons

    // if (metaWabaDetails["header_text"] !== "") {
    //   bool = hasVariablePlaceholders(metaWabaDetails["header_text"]);
    // }

    // if (metaWabaButtons.length !== 0) {
    //   for (let i = 0; i < metaWabaButtons.length; i++) {
    //     const item = metaWabaButtons[i];

    //     if (item["option"] === "Call") {
    //       bool = hasVariablePlaceholders(item["phone_number"]);
    //     }

    //     if (item["option"] === "Visit") {
    //       bool = hasVariablePlaceholders(item["url"]);
    //     }

    //     if (bool) {
    //       break;
    //     }
    //   }
    // }
    return bool;
  };

  const submitPayload = async (components) => {
    let payload = {
      template_title: details["template_title"],
      category: details["category"],
      language: details["language"],
    };
    payload["whatsapp_provider_id"] = details[
      "whatsapp_provider"
    ].hasOwnProperty("id")
      ? details.whatsapp_provider.id
      : "";
    payload["components"] = components;
    payload["component_variable_mapping"] = variableMapping["body"];    //only body variables supported

    postSubmitCallback(payload);
  };

  const getPayloadWithVariables = (oldPayload) => {
    let newComponents = [...oldPayload];

    // only showing body variables for now

    // body
    if (
      variableDetails["body"] &&
      Object.keys(variableDetails["body"]).length > 0
    ) {
      const bodyIndex = newComponents.findIndex(
        (x) => x.type.toLowerCase() === "body"
      );

      if (bodyIndex !== -1) {
        newComponents[bodyIndex]["example"] =
          newComponents[bodyIndex]["example"] || {};
        newComponents[bodyIndex]["example"]["body_text"] = [
          Object.values(variableDetails.body),
        ];
      }
    }

    // header
    // if (variableDetails["header"].length > 0) {
    //   const headerIndex = newComponents.findIndex(
    //     (x) => x.type.toLowerCase() === "header"
    //   );

    //   if (headerIndex !== -1) {
    //     newComponents[headerIndex]["example"] = {};
    //     newComponents[headerIndex]["example"]["body_text"] =
    //       variableDetails.header || [];
    //   }
    // }

    // buttons
    // const buttonIndex = newComponents.findIndex(
    //   (x) => x.type.toLowerCase() === "buttons"
    // );
    // let urlIndex = 0;
    // let phoneIndex = 0;

    // if (buttonIndex !== -1) {
    //   const urlExamples = [];
    //   const phoneExamples = [];

    //   newComponents[buttonIndex]["buttons"].forEach((button, index) => {
    //     if (button.type.toLowerCase() === "url") {
    //       const exampleUrl = variableDetails.buttons.url[`button_${urlIndex}`] || "";
    //       urlExamples.push(exampleUrl);
    //       urlIndex++;
    //     }

    //     if (button.type.toLowerCase() === "phone_number") {
    //       const examplePhone = variableDetails.buttons.phone[`button_${phoneIndex}`] || "";
    //       phoneExamples.push(examplePhone);
    //       phoneIndex++;
    //     }
    //   });

    //   if (!newComponents[buttonIndex].hasOwnProperty("example")) {
    //     newComponents[buttonIndex]["example"] = {};
    //   }
    //   newComponents[buttonIndex]["example"]["body_text"] = [
    //     ...urlExamples,
    //     ...phoneExamples,
    //   ];
    // }

    return newComponents;
  };

  const handleSubmit = async () => {
    if (details["whatsapp_provider"]?.provider_name === "Meta WABA") {
      //manual body field validation (due to using MDEditor)
      if (!metaWabaDetails["body_text"].trim()) {
        setBodyTextError(true);
        return;
      }
    }

    if (areMetaWabaVariablesPresent()) {
      submitPayload(getPayloadWithVariables(generateComponents("api")));
    } else {
      submitPayload(generateComponents("api"));
    }
  };

  const hasDuplicateButtonTexts = () => {
    const textValues = metaWabaButtons
      .map((row) => row.text)
      .filter((text) => text);
    const hasDuplicates = new Set(textValues).size !== textValues.length;

    if (hasDuplicates) {
      return true;
    } else {
      return false;
    }
  };

  return !loading ? (
    <Box role="presentation">
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <ModelHeader>
              {isEdit ? "Edit template" : "Create Template"}
            </ModelHeader>
            {!isSubmitSuccess && (
              <Button
                disabled={
                  activeStep === 1
                    ? details["whatsapp_provider"]?.provider_name !==
                        "Meta WABA" || shouldDisablePreview()
                    : details["whatsapp_provider"]?.provider_name !==
                        "Meta WABA" || areMetaWabaVariablesPresent()
                }
                onClick={() => {
                  setPreviewComponents(
                    getPayloadWithVariables(generateComponents("preview"))
                  );
                  setPreviewOpen(true);
                }}
                variant="outlined"
                color="primary"
                size="small"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PreviewIcon />
                Preview
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {!isSubmitSuccess ? (
        <ModelWrapper
          component={"form"}
          onSubmit={(e) => {
            e.preventDefault();
            if (areMetaWabaVariablesPresent()) {
              if (activeStep < 1) {
                if (hasDuplicateButtonTexts()) {
                  setShowTextWarning(true);
                } else {
                  handleNext();
                }
              } else {
                if (hasDuplicateButtonTexts()) {
                  setShowTextWarning(true);
                } else {
                  handleSubmit();
                }
              }
            } else {
              if (hasDuplicateButtonTexts()) {
                setShowTextWarning(true);
              } else {
                handleSubmit();
              }
            }
          }}
        >
          <InputContainer>
            {(() => {
              if (activeStep === 0) {
                return (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <CustomFieldHeader
                          header={"Template Title"}
                          tooltip={
                            "Enter the name of the new WhatsApp template"
                          }
                          required={true}
                        />
                        <OutlinedInput
                          style={{ height: "30px" }}
                          inputProps={{
                            pattern: "^[a-z0-9_]+$",
                            title:
                              "Only lowercase letters and underscores are allowed",
                            "aria-label": "weight",
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          onChange={handleChange}
                          name="template_title"
                          required
                          value={details["template_title"]}
                          placeholder="Enter value with lowercase letters and underscores only"
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Category*</Label>
                        <FormControl
                          sx={{ width: "100%" }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            displayEmpty
                            required
                            onChange={(e) => {
                              handleChange(e);
                              if (
                                e.target.value.toLowerCase() === "marketing"
                              ) {
                                setFooterEditable(false); //initially disable footer field
                                //prefill default footer text
                                const updatedDetails = {
                                  ...metaWabaDetails,
                                  footer_text: getDefaultMarketingFooter(
                                    details["whatsapp_provider"]
                                  ),
                                };

                                setMetaWabaDetails(updatedDetails);
                              } else {
                                setMetaWabaDetails({
                                  header_format: "None",
                                  header_text: "",
                                  body_text: "",
                                  footer_text: "",
                                });
                              }
                            }}
                            name="category"
                            disableUnderline
                            variant="outlined"
                            value={details["category"]}
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              borderRadius: "4px",
                            }}
                          >
                            {businessWhatsappTemplateCategories.map(
                              (item, i) => {
                                return (
                                  <MenuItem
                                    value={item}
                                    key={i}
                                    style={{ fontSize: "12px" }}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                        </FormControl>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Language*</Label>
                        <FormControl
                          sx={{ width: "100%" }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            displayEmpty
                            required
                            onChange={handleChange}
                            name="language"
                            disableUnderline
                            variant="outlined"
                            value={details["language"]}
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              borderRadius: "4px",
                            }}
                          >
                            {businessWhatsappLanguages.map((item, i) => {
                              return (
                                <MenuItem
                                  value={item.value}
                                  key={i}
                                  style={{ fontSize: "12px" }}
                                >
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>WhatsApp Provider*</Label>
                        <FormControl
                          sx={{ width: "100%" }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            displayEmpty
                            required
                            onChange={(e) => {
                              handleChange(e);
                              if (
                                details["category"].toLowerCase() ===
                                "marketing"
                              ) {
                                setFooterEditable(false); //initially disable footer field
                                //prefill default footer text
                                const updatedDetails = {
                                  ...metaWabaDetails,
                                  footer_text: getDefaultMarketingFooter(
                                    e.target.value
                                  ),
                                };

                                setMetaWabaDetails(updatedDetails);
                              }
                            }}
                            name="whatsapp_provider"
                            disableUnderline
                            variant="outlined"
                            value={details["whatsapp_provider"]}
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              borderRadius: "4px",
                            }}
                          >
                            {providersList.map((item, i) => {
                              return (
                                <MenuItem
                                  value={item}
                                  key={i}
                                  style={{ fontSize: "12px" }}
                                >
                                  {dataRenderer(item.name)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </InputWrapper>
                    </Grid>
                    {details["whatsapp_provider"]?.provider_name ===
                    "Meta WABA" ? (
                      <MetaWABAScreen
                        isEdit={isEdit}
                        selectedImages={selectedImages}
                        handleImageUpload={handleImageUpload}
                        handleRemoveImage={handleRemoveImage}
                        handleImagePreview={handleImagePreview}
                        details={metaWabaDetails}
                        setDetails={setMetaWabaDetails}
                        buttonRows={metaWabaButtons}
                        setButtonRows={setMetaWabaButtons}
                        isMarketingTemplate={
                          details["category"].toLowerCase() === "marketing"
                        }
                        setFooterEditable={setFooterEditable}
                        footerEditable={footerEditable}
                        getOptOutKeywordsForProvider={
                          getOptOutKeywordsForProvider
                        }
                        bodyTextError={bodyTextError}
                        setBodyTextError={setBodyTextError}
                      />
                    ) : (
                      <Grid item xs={12}>
                        <InputWrapper>
                          <CustomFieldHeader
                            header={"Message"}
                            tooltip={`To personalize your WhatsApp message, you can include lead-specific fields by using double curly braces {{FieldName}}, such as {{Name}}. For system fields like dates, use double square brackets [[FieldName]], such as [[Date]]. These placeholders will be automatically replaced with the respective values when the message is sent.`}
                            required={true}
                          />
                          <OutlinedInput
                            multiline
                            rows={20}
                            inputProps={{
                              min: 0,
                              "aria-label": "weight",
                              style: {
                                fontSize: "12px",
                              },
                            }}
                            onChange={handleChange}
                            name="template_message"
                            required
                            value={details["template_message"]}
                            placeholder="Dear {{Name}}! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus interdum dui vitae nisi [[Date]], ac convallis sem scelerisque."
                          />
                        </InputWrapper>
                      </Grid>
                    )}
                  </Grid>
                );
              } else {
                return (
                  <VariableSampleTextScreen
                    details={variableDetails}
                    setDetails={setVariableDetails}
                    components={generateComponents("api")}
                    setVariableMapping={setVariableMapping}
                    variableMapping={variableMapping}
                  />
                );
              }
            })()}
          </InputContainer>

          <AppBar
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: 500, bgcolor: "#f9f9fc" }}
          >
            <Toolbar style={{ width: 500, position: "relative" }}>
              {isSubmitFail && (
                <ErrorAlert
                  onClose={(e) => {
                    e.stopPropagation();
                    setIsSubmitFail(false);
                  }}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {errorMessage}
                </ErrorAlert>
              )}
              {showTextWarning && (
                <WarningAlert
                  onClose={(e) => {
                    e.stopPropagation();
                    setShowTextWarning(false);
                  }}
                  severity="warning"
                  sx={{ width: "100%" }}
                >
                  Please enter unique texts for each button.
                </WarningAlert>
              )}
              <ButtonWrapper>
                <RenderButton />
              </ButtonWrapper>
            </Toolbar>
          </AppBar>
        </ModelWrapper>
      ) : (
        <MessageWrapper>
          <Message>
            {isEdit
              ? "Template edited successfully!"
              : "Template created successfully!"}
          </Message>
          <Button
            onClick={handleClose}
            type="button"
            variant="contained"
            color="default"
            style={{
              marginTop: "20px",
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "bold",
              width: "100px",
            }}
          >
            Close
          </Button>
        </MessageWrapper>
      )}
      {viewImageDialog && (
        <PreviewImageDialog
          open={viewImageDialog}
          setOpen={setViewImageDialog}
          file={selectedFileUrl}
          isCreate={false}
        />
      )}
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AddWhatsappBusinessTemplateSidepanel;
