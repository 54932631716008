import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Grid, Modal } from "@material-ui/core";
import {
  Select,
  MenuItem,
  Chip,
  Typography,
  Divider,
  OutlinedInput,
} from "@mui/material";
import { MultiSelect } from "react-multi-select-component";
import { partnerTypeOptionsList } from "../../Db";
import { AddBoxOutlined } from "@material-ui/icons";

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  textAlign: "left",
  margin: "auto",
  backgroundColor: "white",
  height: "fit-content",
  "&::-webkit-scrollbar": {
    width: "0.2em",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px #ffffff",
  },
});

const InputContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "fit-content",
  width: "320px",
  rowGap: "20px",
});

const Label = styled(Typography)({
  font: "normal normal normal 10px Open Sans",
  color: "#4d4e4f",
});

const InputWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const ModelContainer = styled(Box)({
  position: "absolute",
  top: "0px",
  bottom: "0px",
  left: "0px",
  right: "0px",
  margin: "auto",
  height: "fit-content",
  width: "fit-content",
  border: "none",
  outline: "none",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px #0000000d",
  borderRadius: "8px",
  padding: "30px",
  border: "1px solid #c5d7f1",
  boxShadow: "0px 3px 20px #185dd21f",
});

const ButtonWrapper = styled(Box)({
  marginTop: "20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  columnGap: "10px",
});

const Header = styled(Typography)({
  font: "normal normal 600 18px Open Sans",
  color: "#4d4e4f",
  margin: "0px",
});

const SubHeader = styled(Typography)({
  font: "normal normal normal 14px Open Sans",
  color: "#6f6f6f",
  margin: "0px",
  marginTop: "2px",
});

const ExternalOwnerFilterModal = (props) => {
  const [partnerTypeFilter, setPartnerTypeFilter] = useState(
    props.partnerTypeFilter
  );
  const [nameFilter, setNameFilter] = useState(props.nameFilter);
  const [cityFilter, setCityFilter] = useState(props.cityFilter);
  const [stateFilter, setStateFilter] = useState(props.stateFilter);
  const [specialtiesList, setSpecialtiesList] = useState(
    props.specilitiesFilterList
  );
  const [partnerTypeOptions, setPartnerTypeOptions] = useState([]);
  const [specialtiesInputValue, setSpecialtiesInputValue] = useState("");

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSubmit = () => {
    props.handleSubmit(
      partnerTypeFilter,
      nameFilter,
      cityFilter,
      stateFilter,
      specialtiesList
    );
    props.setOpen(false);
  };

  const checkIfSpecialtiesDisabled = () => {
    return !specialtiesInputValue.trim();
  };

  const handleAddSpecialty = (e) => {
    const value = specialtiesInputValue;
    if (!value.trim()) return;
    if (!specialtiesList.includes(value)) {
      setSpecialtiesList([...specialtiesList, value]);
    }
    setSpecialtiesInputValue("");
  };
  const handleDelete = (input) => {
    let tempList = specialtiesList.filter((item) => item !== input);
    setSpecialtiesList(tempList);
  };

  useEffect(() => {
    setup();
  }, []);

  const setup = () => {
    const partnerTypes = partnerTypeOptionsList.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    setPartnerTypeOptions(partnerTypes);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Header>Filter External User</Header>
          <SubHeader>Select filters from below dropdowns</SubHeader>
          <Divider style={{ margin: "10px 0px" }} />
          <Box>
            <Container
              component={"form"}
              onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleSubmit();
              }}
            >
              <InputContainer>
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Name</Label>
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        onChange={(e) => setNameFilter(e.target.value)}
                        name="name"
                        value={nameFilter}
                        placeholder="Enter value"
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Partner Type</Label>
                      <Select
                        name="external_user"
                        value={partnerTypeFilter || ""}
                        displayEmpty
                        onChange={(e) => {
                          setPartnerTypeFilter(e.target.value);
                        }}
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          borderRadius: "4px",
                        }}
                      >
                        <MenuItem value={""}>
                          <em>none</em>
                        </MenuItem>
                        {partnerTypeOptions.map((partner) => (
                          <MenuItem key={partner.value} value={partner.value}>
                            {partner.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>City</Label>
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        onChange={(e) => setCityFilter(e.target.value)}
                        name="city"
                        value={cityFilter}
                        placeholder="Enter value"
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>State</Label>
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        onChange={(e) => setStateFilter(e.target.value)}
                        name="city"
                        value={stateFilter}
                        placeholder="Enter value"
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Specialities</Label>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <OutlinedInput
                          style={{
                            height: "30px",
                            width: "100%",
                            borderRadius: "0px",
                          }}
                          inputProps={{
                            min: 0,
                            "aria-label": "weight",
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          type="text"
                          placeholder="Type something"
                          name="specialities"
                          value={specialtiesInputValue}
                          onChange={(e) => {
                            setSpecialtiesInputValue(e.target.value);
                          }}
                        />
                        <AddBoxOutlined
                          color={
                            checkIfSpecialtiesDisabled()
                              ? "disabled"
                              : "primary"
                          }
                          onClick={
                            checkIfSpecialtiesDisabled()
                              ? null
                              : handleAddSpecialty
                          }
                          style={{
                            marginLeft: "5px",
                            width: "30px",
                            height: "30px",
                            cursor: "pointer",
                            transform: "scale(1.3)",
                          }}
                        />
                      </Box>
                      <Box
                        style={{
                          padding: "12px 0px",
                          display: "flex",
                          alignItems: "flex-start",
                          gap: "8px",
                          flexWrap: "wrap",
                        }}
                      >
                        {Array.isArray(specialtiesList) &&
                          specialtiesList.map((chip, index) => {
                            return (
                              <Box key={index}>
                                <Chip
                                  index={index}
                                  label={chip}
                                  onDelete={() => handleDelete(chip)}
                                />
                              </Box>
                            );
                          })}
                      </Box>
                    </InputWrapper>
                  </Grid>
                </Grid>
              </InputContainer>
              <ButtonWrapper>
                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="inherit"
                  style={{
                    textTransform: "none",

                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",

                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Submit
                </Button>
              </ButtonWrapper>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default ExternalOwnerFilterModal;
