import {
  Box,
  OutlinedInput,
  Typography,
  styled,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  InputAdornment,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormGroup,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Add, ExpandMore } from "@material-ui/icons";
import { CurrencyRupee } from "@mui/icons-material";
import TableComponent from "../table_component/TableComponent";
import {
  indianStatesList,
  quotationCountriesList,
  quoteCreateTypeSlugs,
} from "../../../Db";
import TableComponentAreaBased from "../table_component/TableComponentAreaBased";
import { canShowAreaBasedQuoteType, getLocalizedText } from "../../../Functions";

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;
const DetailWrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;
const DetailLabel = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #a9a9a9;
  white-space: nowrap;
`;
const TextLgHighlight = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  margin: 0px;
`;
const Header = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #a9a9a9;
`;

const TemplateInfoScreen = ({
  companyDetails,
  handleChange,
  handleLogo,
  handleTemplateName,
  templateName,
  handleTemplateDetail,
  handleQuotationInvoiceTemplate,
  templateDetails,
  productAddedList,
  setProductAddedList,
  setOpenAddProductModal,
  setOpenEditProductModal,
  setOpenViewProductModal,
  setSelectedProductData,
  handleCloneItem,
  scope,
}) => {
  const classes = useStyles();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputWrapper>
            <Label>Template Name*</Label>
            <OutlinedInput
              disabled={scope === "edit"}
              style={{ height: "30px" }}
              inputProps={{
                min: 0,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              onChange={(e) => handleTemplateName(e.target.value)}
              name="template_name"
              required
              value={templateName}
              placeholder="Enter value"
            />
          </InputWrapper>
        </Grid>
        <Grid item xs={12}>
          <InputWrapper>
            <Label>Type*</Label>
            <FormControl sx={{ width: "100%" }}>
              <Select
                displayEmpty
                required
                onChange={(e) => {
                  setProductAddedList([]); //reset products list on type change
                  handleTemplateDetail(e.target.name, e.target.value);
                }}
                name="template_type"
                disableUnderline
                variant="outlined"
                value={templateDetails["template_type"]}
                style={{
                  width: "100%",
                  height: "30px",
                  fontSize: "12px",
                  borderRadius: "0px",
                }}
              >
                {quoteCreateTypeSlugs.map((item, i) => {
                  if (item.value === "template_area_based") {
                    if (canShowAreaBasedQuoteType()) {
                      return (
                        <MenuItem
                          value={item.value}
                          key={i}
                          style={{ fontSize: "12px" }}
                        >
                          {item.label}
                        </MenuItem>
                      );
                    }
                  } else {
                    return (
                      <MenuItem
                        value={item.value}
                        key={i}
                        style={{ fontSize: "12px" }}
                      >
                        {item.label}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          </InputWrapper>
        </Grid>
        <Grid item xs={12}>
          <InputWrapper>
            <FormGroup row>
              <RadioGroup
                row
                value={
                  templateDetails["quotation_template"]
                    ? "quotation_template"
                    : "invoice_template"
                }
                onChange={(e) => {
                  const selectedTemplate = e.target.value;
                  let newFlagValues = {
                    quotation_template:
                      selectedTemplate === "quotation_template",
                    invoice_template: selectedTemplate === "invoice_template",
                  };
                  handleQuotationInvoiceTemplate(newFlagValues);
                }}
              >
                <FormControlLabel
                  disabled={scope === "edit"}
                  value="quotation_template"
                  control={<Radio />}
                  label={<Label>Quotation Template</Label>}
                />
                <FormControlLabel
                  disabled={scope === "edit"}
                  value="invoice_template"
                  control={<Radio />}
                  label={<Label>Invoice Template</Label>}
                />
              </RadioGroup>
            </FormGroup>
          </InputWrapper>
        </Grid>
        {templateDetails["quotation_template"] && (
          <Grid item xs={12}>
            <InputWrapper>
              <Label>Quotation Title*</Label>
              <OutlinedInput
                style={{ height: "30px" }}
                inputProps={{
                  min: 0,
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                onChange={(e) =>
                  handleTemplateDetail(e.target.name, e.target.value)
                }
                name="quotation_title"
                required
                value={templateDetails["quotation_title"]}
                placeholder="Enter value"
              />
            </InputWrapper>
          </Grid>
        )}
        {templateDetails["invoice_template"] && (
          <Grid item xs={12}>
            <InputWrapper>
              <Label>Invoice Title*</Label>
              <OutlinedInput
                style={{ height: "30px" }}
                inputProps={{
                  min: 0,
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                onChange={(e) =>
                  handleTemplateDetail(e.target.name, e.target.value)
                }
                name="invoice_title"
                required
                value={templateDetails["invoice_title"]}
                placeholder="Enter value"
              />
            </InputWrapper>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputWrapper>
            <Label>Subject</Label>
            <OutlinedInput
              style={{ height: "30px" }}
              inputProps={{
                min: 0,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              onChange={(e) =>
                handleTemplateDetail(e.target.name, e.target.value)
              }
              name="template_subject"
              value={templateDetails["template_subject"]}
              placeholder="Enter value"
            />
          </InputWrapper>
        </Grid>
        <Grid item xs={6}>
          <InputWrapper>
            <Label>Company Logo</Label>
            <input
              type="file"
              onChange={(event) => handleLogo(event)}
              style={{
                width: "100%",
                fontSize: "12px",
                padding: "2px",
                backgroundColor: "#EFEFF4",
                border: "none",
                borderRadius: "6px",
              }}
              accept="image/*"
            />
          </InputWrapper>
        </Grid>
        <Grid item xs={6}>
          <InputWrapper>
            <Label>Company Name*</Label>
            <OutlinedInput
              style={{ height: "30px" }}
              inputProps={{
                min: 0,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name="company_name"
              required
              value={companyDetails["company_name"]}
              placeholder="Enter value"
            />
          </InputWrapper>
        </Grid>

        <Grid item xs={6}>
          <InputWrapper>
            <Label>Company Email*</Label>
            <OutlinedInput
              style={{ height: "30px" }}
              inputProps={{
                min: 0,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name="company_email"
              required
              value={companyDetails["company_email"]}
              placeholder="Enter value"
            />
          </InputWrapper>
        </Grid>
        <Grid item xs={6}>
          <InputWrapper>
            <Label>Company Website*</Label>
            <OutlinedInput
              style={{ height: "30px" }}
              inputProps={{
                min: 0,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name="company_website"
              required
              value={companyDetails["company_website"]}
              placeholder="Enter value"
            />
          </InputWrapper>
        </Grid>
        <Grid item xs={12}>
          <InputWrapper>
            <Label>Company Address*</Label>
            <OutlinedInput
              multiline
              rows={3}
              inputProps={{
                min: 0,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name="company_address"
              required
              value={companyDetails["company_address"]}
              placeholder="Enter value"
            />
          </InputWrapper>
        </Grid>
        <Grid item xs={6}>
          <InputWrapper>
            <Label>Company Country*</Label>

            <FormControl
              sx={{ width: "100%" }}
              classes={{
                root: classes.quantityRoot,
              }}
            >
              <Select
                classes={{
                  icon: classes.icon,
                }}
                displayEmpty
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                name="company_country"
                required
                value={companyDetails["company_country"]}
                disableUnderline
                variant="outlined"
                style={{
                  width: "100%",
                  height: "30px",
                  fontSize: "12px",
                  borderRadius: "4px",
                }}
              >
                {quotationCountriesList.map((item, i) => {
                  return (
                    <MenuItem value={item} key={i} style={{ fontSize: "12px" }}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </InputWrapper>
        </Grid>
        <Grid item xs={6}>
          <InputWrapper>
            <Label>Company State*</Label>
            <FormControl
              sx={{ width: "100%" }}
              classes={{
                root: classes.quantityRoot,
              }}
            >
              <Select
                classes={{
                  icon: classes.icon,
                }}
                displayEmpty
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                name="company_state"
                required
                value={companyDetails["company_state"]}
                disableUnderline
                variant="outlined"
                style={{
                  width: "100%",
                  height: "30px",
                  fontSize: "12px",
                  borderRadius: "4px",
                }}
              >
                {companyDetails["company_country"] === "India" &&
                  indianStatesList.map((item, i) => {
                    return (
                      <MenuItem
                        value={item}
                        key={i}
                        style={{ fontSize: "12px" }}
                      >
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </InputWrapper>
        </Grid>

        <Grid item xs={12}>
          <InputWrapper>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Header>{getLocalizedText("product_or_services")}</Header>

              <Button
                variant="text"
                style={{ textTransform: "none", fontSize: "12px" }}
                color="primary"
                onClick={() => {
                  setOpenAddProductModal(true);
                }}
              >
                <Add
                  style={{ marginRight: "6px", width: "18px", height: "18px" }}
                />
                <TextLgHighlight>Add new</TextLgHighlight>
              </Button>
            </Box>
            {templateDetails["template_type"] === "template_area_based" ? (
              <TableComponentAreaBased
                tableData={productAddedList}
                setOpen={setOpenAddProductModal}
                setSelectedProductData={setSelectedProductData}
                setOpenEditProductModal={setOpenEditProductModal}
                setOpenViewProductModal={setOpenViewProductModal}
                handleCloneItem={handleCloneItem}
              />
            ) : (
              <TableComponent
                tableData={productAddedList}
                setOpen={setOpenAddProductModal}
                setSelectedProductData={setSelectedProductData}
                setOpenEditProductModal={setOpenEditProductModal}
                setOpenViewProductModal={setOpenViewProductModal}
                handleCloneItem={handleCloneItem}
              />
            )}
          </InputWrapper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TemplateInfoScreen;
