import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { getLeadProductListApi } from "../../Api";
import { AddBoxOutlined, Edit, History } from "@material-ui/icons";
import { Switch, Drawer } from "@material-ui/core";
import { leadProductsTabColumnHeaders } from "../../Db";
import AddLeadProductsComponent from "../lead_products_tab_components/AddLeadProductsComponent";
import ViewProductSidepanel from "../view_product_sidepanel/ViewProductSidepanel";
import { dataRenderer } from "../../Functions";
import UpdateProductStatusDialog from "../lead_products_tab_components/dialogs/UpdateProductStatusDialog";
import ViewProductHistoryDialog from "../lead_products_tab_components/dialogs/ViewProductHistoryDialog";
import ProductActivateDialog from "../lead_products_tab_components/dialogs/ProductActivateDialog";

const Container = styled(Box)`
  width: 100%;
  height: 100%;
`;

const TableWrapper = styled(Box)`
  position: relative;
  width: 100%;
  ${`min-height: calc(100vh - 332px);`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 5px;
  top: 5px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
`;
const TextHighlight = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;

const ProductsTab = ({
  leadData,
  canEditLead,
  leadRefresh,
  setLeadRefresh,
}) => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAddLeadProductsPanel, setOpenAddLeadProductsPanel] =
    useState(false);
  const [openViewProductPanel, setOpenViewProductPanel] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [openUpdateStatusDialog, setOpenUpdateStatusDialog] = useState(false);
  const [openActivateDialog, setOpenActivateDialog] = useState(false);
  const [openViewProductHistory, setOpenViewProductHistory] = useState(false);

  const getLeadProducts = async () => {
    const queryParams = {
      active: false,
    };
    let response = await getLeadProductListApi({
      leadID: leadData["id"],
      queryParams: queryParams,
    });
    if (response.length > 0) {
      setRow(response);
    } else {
      setRow([]);
    }
  };
  const init = async () => {
    await getLeadProducts();
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const refetch = async () => {
    setLoading(true);
    await getLeadProducts({ page: 0 });
    setLoading(false);
    setRefresh(false);
  };

  const refreshLead = () => {
    setLeadRefresh(!leadRefresh);
  };

  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const IDColumn = [
    {
      field: "id",
      headerName: "ID",

      width: 70,
      cellRenderer: (params) => {
        let value = dataRenderer(params.data.product.flid);

        return (
          <Box
            sx={{ display: "flex", alignItems: "center", height: "100%" }}
            onClick={(e) => {
              e.preventDefault();
              setSelectedRowData(params.data);
              setOpenViewProductPanel(true);
            }}
          >
            <Tooltip title={value}>
              <TextHighlight>{value}</TextHighlight>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const actionColumn = [
    {
      field: "active",
      headerName: "ACTIVE",
      sortable: false,
      width: 100,
      cellRenderer: (params) => {
        return (
          <>
            <Switch
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedRowData(params.data);
                setOpenActivateDialog(true);
              }}
              checked={isProductActive(params.data)}
            />
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      minWidth: 200,
      resizable: false,
      cellRenderer: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              gap: "10px",
            }}
          >
            <Tooltip title={"Edit"}>
              <IconButton
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedRowData(params.data);
                  setOpenUpdateStatusDialog(true);
                }}
              >
                <Edit
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Product History"}>
              <IconButton
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedRowData(params.data);
                  setOpenViewProductHistory(true);
                }}
              >
                <History
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const isProductActive = (productData) => {
    if (
      productData.hasOwnProperty("active") &&
      productData["active"] !== null
    ) {
      return productData["active"];
    }
    return false;
  };

  const getRowStyle = (params) => {
    return {
      cursor: "pointer",
      opacity: isProductActive(params.data) ? "1" : "0.4",
    };
  };

  return (
    <Container>
      {!loading ? (
        <TableWrapper>
          <AddBtnWrapper>
            <IconButton
              style={{ padding: "0px" }}
              disabled={!canEditLead()}
              onClick={() => {
                setOpenAddLeadProductsPanel(true);
              }}
              color="primary"
            >
              <AddBoxOutlined
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </IconButton>
          </AddBtnWrapper>
          <div className="ag-theme-alpine" style={{ width: "100%" }}>
            <AgGridReact
              getRowStyle={getRowStyle}
              domLayout="autoHeight"
              gridOptions={gridOptions}
              rowData={row}
              defaultColDef={defaultColDef}
              columnDefs={IDColumn.concat(leadProductsTabColumnHeaders).concat(
                actionColumn
              )}
              animateRows={true}
              suppressCellFocus
              pagination={false}
              onRowClicked={(row) => {
                if (row.event.defaultPrevented) {
                  return null;
                }
                setSelectedRowData(row.data);
                setOpenViewProductPanel(true);
              }}
            />
          </div>
        </TableWrapper>
      ) : (
        <TableWrapper
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </TableWrapper>
      )}
      {openAddLeadProductsPanel && (
        <Drawer
          anchor={"right"}
          open={openAddLeadProductsPanel}
          disableEscapeKeyDown
          onClose={() => {
            setOpenAddLeadProductsPanel(false);
          }}
        >
          <AddLeadProductsComponent
            setOpen={setOpenAddLeadProductsPanel}
            existingProducts={row}
            leadID={leadData["id"]}
            setRefresh={setRefresh}
          />
        </Drawer>
      )}
      {openViewProductPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openViewProductPanel}
          onClose={() => {
            setSelectedRowData({});
            setOpenViewProductPanel(false);
          }}
        >
          <ViewProductSidepanel
            productID={
              selectedRowData.hasOwnProperty("product") &&
              selectedRowData.product.hasOwnProperty("id")
                ? selectedRowData.product.id
                : ""
            }
            setOpen={setOpenViewProductPanel}
            open={openViewProductPanel}
          />
        </Drawer>
      )}
      {openUpdateStatusDialog && (
        <UpdateProductStatusDialog
          open={openUpdateStatusDialog}
          setOpen={setOpenUpdateStatusDialog}
          leadData={leadData}
          leadProductID={
            selectedRowData.hasOwnProperty("id") ? selectedRowData["id"] : ""
          }
          refreshLead={refreshLead}
          editableData={selectedRowData}
        />
      )}
      {openActivateDialog && (
        <ProductActivateDialog
          open={openActivateDialog}
          setOpen={setOpenActivateDialog}
          leadData={leadData}
          leadProductID={
            selectedRowData.hasOwnProperty("id") ? selectedRowData["id"] : ""
          }
          refreshLead={refreshLead}
          editableData={selectedRowData}
        />
      )}
      {openViewProductHistory && (
        <ViewProductHistoryDialog
          open={openViewProductHistory}
          setOpen={setOpenViewProductHistory}
          leadID={leadData["id"]}
          productHistory={
            selectedRowData.hasOwnProperty("history")
              ? selectedRowData["history"]
              : []
          }
        />
      )}
    </Container>
  );
};

export default observer(ProductsTab);
