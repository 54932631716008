import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, CircularProgress, Drawer } from "@material-ui/core";
import { Add, ChevronRight } from "@material-ui/icons";
import { leadCreateLayoutConfigColumnHeaders } from "../../Db";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import {
  getAdminColumnsApi,
  getProjectAdminLeadCreateLayoutApi,
  updateLeadCreateLayoutApi,
} from "../../Api";
import ViewCreateLeadSectionComponent from "../../components/lead_create_layout_page_components/ViewCreateLeadSectionComponent";
import CreateAddLeadLayoutComponent from "../../components/lead_create_layout_page_components/CreateAddLeadLayoutComponent";
import { getLocalizedText } from "../../Functions";
import CustomErrorMessageDialog from "../../components/alert_dialogue/CustomErrorMessageDialog";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 16px 0px;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 214px);`};
  background-color: white;
  padding: 20px;
  position: relative;
`;
const ButtonText = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  margin: 0px;
  white-space: nowrap;
`;
const AdminProjectLeadCreateLayout = () => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openCreateConfigPanel, setOpenCreateConfigPanel] = useState(false);
  const [openViewDealPanel, setOpenViewDealPanel] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [layoutData, setLayoutData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [columnsList, setColumnsList] = useState([]);
  const [errorMessge, setErrorMessage] = useState("");
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const getConfigData = async () => {
    let response = await getProjectAdminLeadCreateLayoutApi();

    // getting list of layouts, in which picking the first layout and then getting the config from that entry
    if (response.length > 0) {
      let tempLayoutData = response[0];
      let tempConfigData =
        tempLayoutData.hasOwnProperty("config") &&
        tempLayoutData["config"] !== null
          ? tempLayoutData["config"]
          : [];
      setLayoutData(tempLayoutData);
      setRow(tempConfigData);
    } else {
      setLayoutData({});
      setRow([]);
    }
  };
  const fetchProjectAdminColumns = async () => {
    //fetching bulk project admin columns to show and add it in sections
    try {
      let response = await getAdminColumnsApi({
        pid: rootStore.authStore.projectId,
        page: 0,
        page_size: 200,
      });
      setColumnsList(response.data.items);
    } catch (error) {
      console.log(error);
      setColumnsList([]);
    }
  };

  const init = async () => {
    setLoading(true);
    await getConfigData();
    await fetchProjectAdminColumns();
    setLoading(false);
  };

  const refetch = async () => {
    setLoading(true);
    await getConfigData();
    setRefresh(false);
    setLoading(false);
  };

  useEffect(() => {
    if (refresh === true) {
      refetch();
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  // after reordering fetching update layout call with new ordering of sections
  const updateLayout = async ({ sectionList }) => {
    let payload = { is_default: true, config: sectionList };
    let response = await updateLeadCreateLayoutApi({
      layoutID: layoutData["id"],
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setShowErrorDialog(true);
    } else {
      setRefresh(true);
    }
  };

  const onRowDragEnd = async (event) => {
    const gridApi = event.api;
    let draggedItem = event.node.data;
    let newOrderList = [];
    gridApi.forEachNode((node) => {
      let tempObj = { ...node.data };
      newOrderList.push(tempObj);
    });

    let prevIndex = row.findIndex(
      (data) => data["sequence"] === draggedItem["sequence"]
    );
    let newIndex = newOrderList.findIndex(
      (data) => data["sequence"] === draggedItem["sequence"]
    );
    newOrderList.forEach((entry, index) => {
      entry["sequence"] = index;
    });
    if (prevIndex !== newIndex) {
      await updateLayout({ sectionList: newOrderList });
    }
  };

  return (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Header>{getLocalizedText("admin")}</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Header>{getLocalizedText("lead_create_layout")}</Header>
            </TopWrapper>
            {Object.keys(layoutData).length > 0 && (
              <Button
                onClick={() => {
                  setIsEdit(true);
                  setOpenCreateConfigPanel(true);
                }}
                variant="contained"
                style={{
                  textTransform: "none",
                }}
                color="primary"
              >
                <Add />
                <ButtonText>{getLocalizedText("update_layout")}</ButtonText>
              </Button>
            )}
          </Row>
          {!loading ? (
            Object.keys(layoutData).length > 0 ? (
              <TableWrapper>
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    onRowDragEnd={onRowDragEnd}
                    rowDragManaged={true}
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={leadCreateLayoutConfigColumnHeaders}
                    animateRows={true}
                    suppressCellFocus
                    pagination={false}
                    paginationPageSize={pageSize}
                    className="paginated-ag-grid"
                    onRowClicked={(row) => {
                      if (row.event.defaultPrevented) {
                        return null;
                      }
                      setSelectedRowData(row.data);
                      setOpenViewDealPanel(true);
                    }}
                  />
                </div>
              </TableWrapper>
            ) : (
              <TableWrapper
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => {
                    setOpenCreateConfigPanel(true);
                  }}
                  variant="text"
                  style={{
                    textTransform: "none",
                  }}
                  color="primary"
                >
                  <Add />
                  <ButtonText>{getLocalizedText("create_layout")}</ButtonText>
                </Button>
              </TableWrapper>
            )
          ) : (
            <TableWrapper
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </TableWrapper>
          )}
        </Container>
      </ParentContainer>
      <Footer />
      {openCreateConfigPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCreateConfigPanel}
          onClose={() => {
            setIsEdit(false);
            setSelectedRowData({});
            setOpenCreateConfigPanel(false);
          }}
        >
          <CreateAddLeadLayoutComponent
            columnsList={columnsList}
            layoutData={layoutData}
            handleClose={() => {
              setIsEdit(false);
              setOpenCreateConfigPanel(false);
            }}
            isEdit={isEdit}
            setRefresh={setRefresh}
          />
        </Drawer>
      )}

      {openViewDealPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openViewDealPanel}
          onClose={() => {
            setOpenViewDealPanel(false);
          }}
        >
          <ViewCreateLeadSectionComponent
            details={selectedRowData}
            columnsList={columnsList}
          />
        </Drawer>
      )}
      {showErrorDialog && (
        <CustomErrorMessageDialog
          open={showErrorDialog}
          setOpen={setShowErrorDialog}
          message={errorMessge}
        />
      )}
    </>
  );
};

export default observer(AdminProjectLeadCreateLayout);
