import { AppBar, Box, Divider, Toolbar } from "@material-ui/core";
import React from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../../Functions";
import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  OutlinedInput,
} from "@mui/material";
import rootStore from "../../../stores/RootStore";
const dialogWidth = 600;

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: `${dialogWidth}px`,
  textAlign: "left",
  margin: "auto",
  backgroundColor: "white",
  height: "fit-content",
  padding: "16px",
  overflowY: "auto",
  rowGap: "10px",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "18px",
  fontFamily: "Open Sans",
  color: "#4d4e4f",
}));

const SubHeader = styled(Typography)(({ theme }) => ({
  fontWeight: "normal",
  fontSize: "14px",
  fontFamily: "Open Sans",
  color: "#6f6f6f",
  marginTop: "2px",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "12px",
  fontFamily: "Open Sans",
  color: "#4d4e4f",
}));

const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const TradeIndiaFirstScreen = ({
  selectedProjectID,
  details,
  handleChange,
  handleProject,
  apiKey,
  setApiKey,
}) => {
  const projectList = [...rootStore.authStore.projectList];

  return (
    <>
      <Box>
        <AppBar
          component={"nav"}
          elevation={0}
          position="sticky"
          color="inherit"
          sx={{
            top: 0,
            bottom: "auto",
            width: "100%",
            right: 0,
          }}
        >
          <Toolbar
            style={{
              padding: "16px",
            }}
          >
            <HeaderWrapper>
              <Header>Configure API Response</Header>
              <SubHeader>
                {" "}
                {getLocalizedText("fill_in_the_below_details")}
              </SubHeader>
            </HeaderWrapper>
          </Toolbar>
        </AppBar>
        <Divider style={{ margin: "0px 16px" }} />
        <Container>
          <InputWrapper>
            <Label>User ID*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="user_id"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={details.user_id}
              />
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <Label>Profile ID*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="profile_id"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={details.profile_id}
              />
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <Label>API Key*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={(e) => {
                  setApiKey(e.target.value);
                }}
                name="api_key"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={apiKey}
              />
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <Label>Select Destination Project*</Label>
            <FormControl sx={{ width: "100%" }}>
              <Select
                displayEmpty
                required
                onChange={(event) => {
                  handleProject(event.target.value);
                }}
                name="selected_predefined_deal"
                disableUnderline
                variant="outlined"
                value={selectedProjectID}
                style={{
                  width: "100%",
                  height: "30px",
                  fontSize: "12px",
                  borderRadius: "0px",
                }}
              >
                {projectList.map((item, i) => {
                  return (
                    <MenuItem
                      value={item.id}
                      key={i}
                      style={{ fontSize: "12px" }}
                    >
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </InputWrapper>
        </Container>
      </Box>
    </>
  );
};

export default TradeIndiaFirstScreen;
