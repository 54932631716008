import { Grid } from "@material-ui/core";
import { Box, OutlinedInput, styled, Typography } from "@mui/material";
import React from "react";
import { getLocalizedText } from "../../../../Functions";
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Input = styled("input")`
  width: 100%;
  height: 30px;
  outline: none;
  color: #4d4e4f;
  border: none;
  border-radius: 6px;
  padding: 7px;

  background-color: #efeff4;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const ImportFileScreen = ({
  handleFileUpload,
  file,
  showFileErrorMessage,
  fileErrorMessage,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputWrapper>
          <Label style={{ fontSize: "12px" }}>
            {getLocalizedText("pick_a_file")}
          </Label>
          <Input
            type="file"
            onClick={(e) => (e.target.value = null)}
            accept=".csv, .xls, .xlsx"
            onChange={(event) => handleFileUpload(event)}
          />
          {showFileErrorMessage && (
            <Label style={{ color: "red" }}>{fileErrorMessage}</Label>
          )}
          <Label style={{ marginTop: "4px" }}>
            {getLocalizedText("selected_file")}:{" "}
            <strong>{file ? file.name : "-"}</strong>
          </Label>
        </InputWrapper>
      </Grid>
    </Grid>
  );
};

export default ImportFileScreen;
