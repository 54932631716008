import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@material-ui/core";
import { AppBar, Box, Toolbar, Typography, Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import MuiAlert from "@mui/material/Alert";
import { getLocalizedText } from "../../Functions";
import AddColumnsToSectionComponent from "./AddColumnsToSectionComponent";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import SectionCard from "./cards/SectionCard";
import { Add } from "@material-ui/icons";
import AddSectionDialog from "./dialogs/AddSectionDialog";
import { v4 as uuidv4 } from "uuid";
import { LoadingButton } from "@mui/lab";
import {
  createLeadCreateLayoutApi,
  updateLeadCreateLayoutApi,
} from "../../Api";

const drawerWidth = "50vw";
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const Wrapper = styled(Box)`
  width: 100%;
  ${`height: calc(100vh - 132px);`};
  padding: 16px;
  overflow-y: auto;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 18px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;
const ButtonText = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  margin: 0px;
`;

const CreateAddLeadLayoutComponent = ({
  setRefresh,
  layoutData,
  handleClose,
  isEdit,
  columnsList,
}) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [sectionList, setSectionList] = useState([]);
  const [sectionColumnMapping, setSectionColumnMapping] = useState();
  const [activeSectionID, setActiveSectionID] = useState("");
  const [selectedSectionData, setSelectedSectionData] = useState({});
  const [openAddSectionDialog, setOpenAddSectionDialog] = useState(false);
  const [isSectionEdit, setIsSectionEdit] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);

  const onChange = async (sourceId, sourceIndex, targetIndex, targetId) => {
    if (sourceIndex === targetIndex) {
      return;
    }
    const newState = swap(sectionList, sourceIndex, targetIndex);
    newState.forEach((section, index) => {
      section["sequence"] = index;
    });
    setSectionList(newState);
  };

  const createLayout = async (payload) => {
    let response = await createLeadCreateLayoutApi({
      payload: payload,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };
  const updateLayout = async (payload) => {
    let response = await updateLeadCreateLayoutApi({
      layoutID: layoutData["id"],
      payload: payload,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const isColumnMappedInSection = (columnID) => {
    for (const key in sectionColumnMapping) {
      let index = sectionColumnMapping[key].findIndex(
        (entry) => entry["value"] === columnID
      );
      if (index !== -1) {
        return true;
      }
    }
    return false;
  };
  const isAllRequiredColumnsMapped = () => {
    const requiredColumnIDs = columnsList
      .filter((column) => column["required"])
      .map((column) => column["id"]);
    for (let i = 0; i < requiredColumnIDs.length; i++) {
      let columnID = requiredColumnIDs[i];
      if (isColumnMappedInSection(columnID) === false) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    setSubmittingForm(true);
    let payload = { is_default: true };
    let sections = [];
    sectionList.forEach((section) => {
      let tempSectionData = {
        name: section["name"],
        sequence: section["sequence"],
      };
      let tempSectionColumns = [];
      if (sectionColumnMapping.hasOwnProperty(section["id"])) {
        sectionColumnMapping[section["id"]].forEach((entry, index) => {
          tempSectionColumns.push({
            column_meta_id: entry["value"],
            sequence: index,
          });
        });
      }
      tempSectionData["columns"] = tempSectionColumns;
      sections.push(tempSectionData);
    });
    payload["config"] = sections;
    if (isEdit) {
      await updateLayout(payload);
    } else {
      await createLayout(payload);
    }
    setSubmittingForm(false);
  };

  const getWidgetLayoutHeight = () => {
    const heightPerRow = 48;
    const numberOfRows = sectionList.length;
    return heightPerRow * numberOfRows;
  };
  const init = () => {
    if (isEdit) {
      let sectionListFromConfig = layoutData.hasOwnProperty("config")
        ? layoutData["config"]
        : [];
      let tempSectionList = [];
      let tempSectionColumnMapping = {};
      sectionListFromConfig.forEach((section) => {
        let sectionID = uuidv4();
        tempSectionList.push({
          name: section["name"],
          sequence: section["sequence"],
          id: sectionID,
        });
        let tempColumns = [];
        section["columns"].forEach((entry) => {
          let index = columnsList.findIndex(
            (column) => column["id"] === entry["column_meta_id"]
          );
          if (index !== -1) {
            let columnMeta = columnsList[index];
            tempColumns.push({
              label: columnMeta["headerName"],
              value: columnMeta["id"],
            });
          }
        });

        tempSectionColumnMapping[sectionID] = tempColumns;
      });
      if (tempSectionList.length > 0) {
        setActiveSectionID(tempSectionList[0]["id"]);
      }
      setSectionList(tempSectionList);
      setSectionColumnMapping(tempSectionColumnMapping);
    }
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    // Only update if the active section is missing (i.e., when a section is deleted)
    if (!sectionList.some((section) => section.id === activeSectionID)) {
      setActiveSectionID(sectionList.length > 0 ? sectionList[0].id : "");
    }
  }, [sectionList]);

  return !loading ? (
    <>
      <Box role="presentation" sx={{ width: drawerWidth }}>
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: drawerWidth,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar style={{ padding: "16px" }}>
            <ModelHeader>
              {isEdit === true
                ? getLocalizedText("update_add_lead_layout")
                : getLocalizedText("create_add_lead_layout")}
            </ModelHeader>
          </Toolbar>
        </AppBar>

        {!isSubmitSuccess ? (
          <Box
            component="main"
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            {sectionList.length > 0 && (
              <Box
                sx={{
                  backgroundColor: "#f9f9fc",
                  position: "sticky",
                  width: "300px",
                  top: "64px",
                  left: "0px",
                  height: "calc(100vh - 64px)",
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "auto",
                }}
              >
                <GridContextProvider onChange={onChange}>
                  <GridDropZone
                    id="items"
                    boxesPerRow={1}
                    rowHeight={48}
                    style={{
                      height: getWidgetLayoutHeight(),
                      overflow: "hidden",
                    }}
                  >
                    {sectionList.map((entry, index) => (
                      <GridItem key={entry["id"]} id={entry["id"]}>
                        <SectionCard
                          key={entry["id"]}
                          sectionData={entry}
                          isSelected={entry["id"] === activeSectionID}
                          handleSectionClick={() => {
                            setActiveSectionID(entry["id"]);
                          }}
                          handleDeleteSection={() => {
                            let tempSectionList = [...sectionList];
                            let tempSectionColumnMapping = {
                              ...sectionColumnMapping,
                            };
                            tempSectionList = tempSectionList.filter(
                              (obj) => obj["id"] !== entry["id"]
                            );
                            delete tempSectionColumnMapping[entry["id"]];
                            tempSectionList.forEach((section, index) => {
                              section["sequence"] = index;
                            });
                            setSectionList(tempSectionList);
                            setSectionColumnMapping(tempSectionColumnMapping);
                          }}
                          handleEditSection={() => {
                            setSelectedSectionData(entry);
                            setIsSectionEdit(true);
                            setOpenAddSectionDialog(true);
                          }}
                        />
                      </GridItem>
                    ))}
                  </GridDropZone>
                </GridContextProvider>
                <Button
                  onClick={() => {
                    setOpenAddSectionDialog(true);
                  }}
                  variant="text"
                  style={{
                    textTransform: "none",
                  }}
                  color="primary"
                >
                  <Add />
                  <ButtonText>{getLocalizedText("add_section")}</ButtonText>
                </Button>
              </Box>
            )}

            <Wrapper
              component={"form"}
              onSubmit={(e) => {
                e.preventDefault();
                if (isAllRequiredColumnsMapped()) {
                  handleSubmit();
                } else {
                  setErrorMessage(
                    getLocalizedText(
                      "please_map_all_required_columns_to_sections_before_proceeding"
                    )
                  );
                  setIsSubmitFail(true);
                }
              }}
            >
              {sectionList.length > 0 ? (
                sectionList.map((section, idx) => {
                  if (activeSectionID === section["id"]) {
                    return (
                      <AddColumnsToSectionComponent
                        key={section["id"]}
                        selectedColumns={
                          sectionColumnMapping.hasOwnProperty(section["id"])
                            ? sectionColumnMapping[section["id"]]
                            : []
                        }
                        columnsList={columnsList}
                        sectionColumnMapping={sectionColumnMapping}
                        handleSelectedColumns={(columns) => {
                          let tempSectionColumnMapping = {
                            ...sectionColumnMapping,
                          };
                          tempSectionColumnMapping[section["id"]] = columns;
                          setSectionColumnMapping(tempSectionColumnMapping);
                        }}
                      />
                    );
                  }
                })
              ) : (
                <Box
                  style={{
                    height: "calc(100vh - 164px)",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <Button
                    onClick={() => {
                      setOpenAddSectionDialog(true);
                    }}
                    variant="text"
                    style={{
                      textTransform: "none",
                    }}
                    color="primary"
                  >
                    <Add />
                    <ButtonText>{getLocalizedText("add_section")}</ButtonText>
                  </Button>
                </Box>
              )}
              <AppBar
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                  top: "auto",
                  bottom: 0,
                  width: drawerWidth,
                  bgcolor: "#f9f9fc",
                }}
              >
                <Toolbar
                  style={{
                    width: drawerWidth,
                    position: "relative",
                    padding: "16px",
                  }}
                >
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}

                  <ButtonWrapper>
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      color="primary"
                      style={{ width: "100px", textTransform: "none" }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                    {submittingForm ? (
                      <LoadingButton
                        loading
                        variant="contained"
                        color="primary"
                        style={{
                          textTransform: "none",
                          width: "100px",
                        }}
                      >
                        <span> {getLocalizedText("submit")}</span>
                      </LoadingButton>
                    ) : (
                      <Button
                        disabled={sectionList.length === 0}
                        color="primary"
                        type="submit"
                        variant="contained"
                        style={{
                          width: "100px",
                          textTransform: "none",
                        }}
                      >
                        {getLocalizedText("submit")}
                      </Button>
                    )}
                  </ButtonWrapper>
                </Toolbar>
              </AppBar>
            </Wrapper>
          </Box>
        ) : (
          <MessageWrapper>
            {isEdit ? (
              <Message>
                {getLocalizedText("add_lead_layout_updated_successfully")}
              </Message>
            ) : (
              <Message>
                {getLocalizedText("add_lead_layout_created_successfully")}
              </Message>
            )}

            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="inherit"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              {getLocalizedText("close")}
            </Button>
          </MessageWrapper>
        )}
        {openAddSectionDialog && (
          <AddSectionDialog
            handleSectionAdd={(sectionName) => {
              let tempSectionList = [...sectionList];
              let tempSectionColumnMapping = {
                ...sectionColumnMapping,
              };
              let sectionID = uuidv4();
              let newSectionData = {
                name: sectionName,
                id: sectionID,
                sequence: tempSectionList.length,
              };
              tempSectionList.push(newSectionData);
              tempSectionColumnMapping[sectionID] = [];
              setSectionList(tempSectionList);
              setSectionColumnMapping(tempSectionColumnMapping);
              setActiveSectionID(sectionID);
              setOpenAddSectionDialog(false);
            }}
            handleSectionEdit={(sectionName) => {
              let tempSectionList = [...sectionList];
              let index = tempSectionList.findIndex(
                (entry) => entry["id"] === selectedSectionData["id"]
              );
              if (index !== -1) {
                let sectionData = {
                  ...tempSectionList[index],
                  name: sectionName,
                };
                tempSectionList[index] = sectionData;
              }
              setSectionList(tempSectionList);
              setIsSectionEdit(false);
              setSelectedSectionData({});
              setOpenAddSectionDialog(false);
            }}
            isEdit={isSectionEdit}
            open={openAddSectionDialog}
            sectionDetails={selectedSectionData}
            setOpen={setOpenAddSectionDialog}
          />
        )}
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: drawerWidth,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(CreateAddLeadLayoutComponent);
