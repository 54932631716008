import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { CircularProgress, Grid } from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getProductLineDetailsApi } from "../../Api";
import { consoleLogger, dataRenderer, getAccountLocalizedText, getLocalizedText } from "../../Functions";
import PreviewPdfDialog from "../files_page_components/PreviewPdfDialog";
import PreviewImageDialog from "../files_page_components/PreviewImageDialog";
import InputFileViewComponent from "../add_product_sidepanel/components/InputFileViewComponent";
import MediaThumbnailCard from "../add_product_sidepanel/components/MediaThumbnailCard";
import PreviewVideoDialog from "../files_page_components/PreviewVideoDialog";
import { Circle } from "@mui/icons-material";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #979797;
  margin-bottom: 16px;
`;
const fixedFields = ["name", "product_type", "sku", "desc"];

const ViewProductLineSidepanel = (props) => {
  const [loading, setLoading] = useState(true);
  const columnsList = [
    {
      column_name: "name",
      display_name: "Name",
    },
    {
      column_name: "tags",
      display_name: "Tags",
    },
    {
      column_name: "desc",
      display_name: "Description",
    },
  ];
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [viewImageDialog, setViewImageDialog] = useState(false);
  const [viewPdfDialog, setViewPdfDialog] = useState(false);
  const [viewVideoDialog, setViewVideoDialog] = useState(false);

  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [productMediaData, setProductMediaData] = useState({});
  const [productDetails, setProductDetails] = useState({});

  const getProductDetails = async () => {
    let response = await getProductLineDetailsApi({
      productLineID: props.productLineID,
    });
    return response;
  };
  const init = async () => {
    let productData = await getProductDetails();
    setProductDetails(productData);
    let tempImageFiles = Object.keys(productData)
      .filter((key) => key.startsWith("image_"))
      .map((key) => productData[key])
      .filter((value) => value !== null);

    let tempDocFiles = Object.keys(productData)
      .filter((key) => key.startsWith("file_"))
      .map((key) => productData[key])
      .filter((value) => value !== null);

    for (const key in productData) {
      if (
        key === "product_image" ||
        key === "product_brochure" ||
        key === "product_video"
      ) {
        if (
          productData[key] !== null &&
          productData[key] !== "" &&
          productData[key].hasOwnProperty("url")
        ) {
          let tempObj = { type: key, data: productData[key] };
          setProductMediaData(tempObj);
        }
      }
    }
    setSelectedImages(tempImageFiles);
    setSelectedDocuments(tempDocFiles);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const handleImagePreview = (image) => {
    setSelectedFileUrl(image["url"]);
    setViewImageDialog(true);
  };
  const handleDocumentPreview = (doc) => {
    setSelectedFileUrl(doc["url"]);
    setViewPdfDialog(true);
  };

  const handleVideoPreview = (video) => {
    setSelectedFileUrl(video["url"]);
    setViewVideoDialog(true);
  };
  const RenderListOfStrings = ({ value }) => {
    let listOfObjects;

    if (value !== null && value !== "") {
      if (typeof value === "string") {
        try {
          listOfObjects = JSON.parse(value); // Parse if it's a JSON string
        } catch (error) {
          consoleLogger("Error parsing JSON string:", error);
          listOfObjects = {}; // Default to an empty object on error
        }
      } else if (typeof value === "object") {
        listOfObjects = value; // Assign directly if it's an object
      } else {
        consoleLogger("Invalid value type:", typeof value);
        listOfObjects = {};
      }
    } else {
      listOfObjects = {}; // Default to an empty object if `value` is null or empty
    }

    if (Object.keys(listOfObjects).length > 0) {
      return (
        <TableContainer sx={{ width: "100%" }}>
          <Table>
            <TableBody>
              {Object.keys(listOfObjects).map((key, idx) => (
                <TableRow key={idx}>
                  <TableCell sx={{ width: "50%" }}>{key}</TableCell>
                  <TableCell>{listOfObjects[key]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }

    return <Detail>-</Detail>;
  };

  const RenderBulletPoints = ({ value }) => {
    let list =
      value !== null && value !== "" && Array.isArray(value) ? value : [];
    if (list.length > 0) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {list.map((item) => {
            return (
              <Box sx={{ display: "flex", columnGap: "8px" }}>
                <Circle
                  style={{
                    width: "8px",
                    height: "8px",
                    marginTop: "6px",
                    color: "gray",
                  }}
                />
                <Detail>{item}</Detail>
              </Box>
            );
          })}
        </Box>
      );
    }
    return <Detail>-</Detail>;
  };
  return !loading ? (
    <Box sx={{ width: 500 }}>
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>{getAccountLocalizedText("product_line_details")}</ModelHeader>
        </Toolbar>
      </AppBar>
      <ModelWrapper>
        <Grid container spacing={3}>
          {Object.keys(productMediaData).length > 0 && (
            <Grid item xs={12}>
              <MediaThumbnailCard
                mediaData={productMediaData}
                mediaUrl={productMediaData["data"]["url"]}
                handleRemove={() => {}}
                handleView={() => {
                  if (productMediaData["type"] === "product_image") {
                    handleImagePreview(productMediaData["data"]);
                  } else if (productMediaData["type"] === "product_brochure") {
                    handleDocumentPreview(productMediaData["data"]);
                  } else if (productMediaData["type"] === "product_video") {
                    handleVideoPreview(productMediaData["data"]);
                  }
                }}
                canEdit={false}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Name</Label>
              <Detail>{dataRenderer(productDetails.name)} </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Description</Label>
              <Detail>{dataRenderer(productDetails.desc)} </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper>
              <Label>Tags</Label>
              <RenderBulletPoints value={productDetails.tags} />
            </InputWrapper>
          </Grid>

          <Grid item xs={12}>
            <InputWrapper>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <legend
                    style={{
                      width: "auto",
                      marginBottom: "0px",
                      fontSize: "12px",
                    }}
                  >
                    <SectionHeader sx={{ margin: 0 }}>
                      Specifications
                    </SectionHeader>
                  </legend>
                </AccordionSummary>
                <AccordionDetails>
                  <RenderListOfStrings
                    value={
                      productDetails.hasOwnProperty("specifications")
                        ? productDetails["specifications"]
                        : null
                    }
                  />
                </AccordionDetails>
              </Accordion>
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <legend
                    style={{
                      width: "auto",
                      marginBottom: "0px",
                      fontSize: "12px",
                    }}
                  >
                    <SectionHeader sx={{ margin: 0 }}>
                      Additional Details
                    </SectionHeader>
                  </legend>
                </AccordionSummary>
                <AccordionDetails>
                  <RenderListOfStrings
                    value={
                      productDetails.hasOwnProperty("additional_details")
                        ? productDetails["additional_details"]
                        : null
                    }
                  />
                </AccordionDetails>
              </Accordion>
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <legend
                  style={{
                    width: "auto",
                    marginBottom: "0px",
                    fontSize: "12px",
                  }}
                >
                  <SectionHeader sx={{ margin: 0 }}>{getAccountLocalizedText("manufacturers")}</SectionHeader>
                </legend>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>{getAccountLocalizedText("manufacturer")}</Label>
                      <Detail>
                        {dataRenderer(
                          productDetails["product_manufacturer"] !== null &&
                            productDetails[
                              "product_manufacturer"
                            ].hasOwnProperty("name")
                            ? productDetails["product_manufacturer"]["name"]
                            : ""
                        )}
                      </Detail>
                    </InputWrapper>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <legend
                    style={{
                      width: "auto",
                      marginBottom: "0px",
                      fontSize: "12px",
                    }}
                  >
                    <SectionHeader sx={{ margin: 0 }}>Images</SectionHeader>
                  </legend>
                </AccordionSummary>
                <AccordionDetails>
                  {selectedImages.length > 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        flexWrap: "wrap",
                      }}
                    >
                      {selectedImages.map((image, idx) => {
                        return (
                          <InputFileViewComponent
                            canRemove={false}
                            fileURL={image["url"]}
                            handleRemove={() => {}}
                            handleView={() => handleImagePreview(image)}
                            scope={"image"}
                          />
                        );
                      })}
                    </Box>
                  ) : (
                    <Detail>-</Detail>
                  )}
                </AccordionDetails>
              </Accordion>
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <legend
                    style={{
                      width: "auto",
                      marginBottom: "0px",
                      fontSize: "12px",
                    }}
                  >
                    <SectionHeader sx={{ margin: 0 }}>Documents</SectionHeader>
                  </legend>
                </AccordionSummary>
                <AccordionDetails>
                  {selectedDocuments.length > 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        flexWrap: "wrap",
                      }}
                    >
                      {selectedDocuments.map((doc, idx) => {
                        return (
                          <InputFileViewComponent
                            canRemove={false}
                            fileURL={doc["url"]}
                            handleRemove={() => {}}
                            handleView={() => handleDocumentPreview(doc)}
                            scope={"pdf"}
                          />
                        );
                      })}
                    </Box>
                  ) : (
                    <Detail>-</Detail>
                  )}
                </AccordionDetails>
              </Accordion>
            </InputWrapper>
          </Grid>
        </Grid>
      </ModelWrapper>
      {viewPdfDialog && (
        <PreviewPdfDialog
          open={viewPdfDialog}
          setOpen={setViewPdfDialog}
          file={selectedFileUrl}
        />
      )}
      {viewImageDialog && (
        <PreviewImageDialog
          open={viewImageDialog}
          setOpen={setViewImageDialog}
          file={selectedFileUrl}
          isCreate={false}
        />
      )}
      {viewVideoDialog && (
        <PreviewVideoDialog
          fileUrl={selectedFileUrl}
          open={viewVideoDialog}
          setOpen={setViewVideoDialog}
        />
      )}
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 500,
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default ViewProductLineSidepanel;
