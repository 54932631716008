import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TableComponent from "../table_component/TableComponent";
import { Grid, makeStyles } from "@material-ui/core";
import { CurrencyRupee } from "@mui/icons-material";
import { ExpandMore } from "@material-ui/icons";
import { dataRenderer, getLocalizedText } from "../../../Functions";
import { indianStatesList } from "../../../Db";
import TableComponentAreaBased from "../table_component/TableComponentAreaBased";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AdditionalColumnsDialog from "../dialogs/AdditionalColumnsDialog";
import DateField from "../../custom_field_components/DateField";

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;
const DetailWrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
const DetailLabel = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #a9a9a9;
  white-space: nowrap;
`;
const Header = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #a9a9a9;
`;
const MenuText = styled(Typography)`
  font: normal normal 500 12px Open Sans;
`;

const QuoteAndProuductInfo = ({
  details,
  isFormDisabled,
  handleChange,
  productAddedList,
  setOpenAddProductModal,
  setSelectedProductData,
  setOpenViewProductModal,
  handleDeleteProduct,
  amountDetails,
  handleAmountDetails,
  handlePlaceOfSupply,
  placeOfSupply,
  quotationNumber,
  slug,
  handleCloneItem,
  handleDelete,
  handleEdit,
  handleView,
  handleAddCustomItem,
  handleAddProduct,
  setTableAdditionalColumns,
  tableAdditionalColumns,
  handleClone,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const accordionDetailsRef = useRef(null);
  const [anchor, setAnchor] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const Open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccordion = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  useEffect(() => {
    setTimeout(() => {
      if (expanded && accordionDetailsRef.current) {
        accordionDetailsRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, 300);
  }, [expanded]);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputWrapper
            style={{ flexDirection: "row", gap: "8px", alignItems: "center" }}
          >
            <Label>Quotation Number :</Label>
            <Label style={{ fontSize: "12px", fontWeight: "bold" }}>
              {dataRenderer(quotationNumber)}
            </Label>
          </InputWrapper>
        </Grid>
   
        <Grid item xs={12}>
          <InputWrapper>
            <Label>Subject*</Label>
            <OutlinedInput
              disabled={isFormDisabled}
              style={{ height: "30px" }}
              inputProps={{
                min: 0,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name="title"
              required
              value={details["title"]}
              placeholder="Enter value"
            />
          </InputWrapper>
        </Grid>

        <Grid item xs={6}>
          {" "}
          <InputWrapper>
            <Label>Quote Date</Label>
            <DateField
              disabled={isFormDisabled}
              value={details["quote_date"]}
              onChange={(event) => {
                handleChange("quote_date", event);
              }}
              required={false}
              padding={"7.5px 14px"}
            />
          </InputWrapper>
        </Grid>
        <Grid item xs={6}>
          {" "}
          <InputWrapper>
            <Label>Expiry Date</Label>
            <DateField
              disabled={isFormDisabled || details["quote_date"] === null}
              value={details["expiry_date"]}
              onChange={(event) => {
                handleChange("expiry_date", event);
              }}
              required={false}
              minDate={details["quote_date"]}
              padding={"7.5px 14px"}
            />
          </InputWrapper>
        </Grid>
        <Grid item xs={12}>
          <InputWrapper>
            <Label>Place of Supply*</Label>
            <FormControl
              sx={{ width: "100%" }}
              classes={{
                root: classes.quantityRoot,
              }}
            >
              <Select
                classes={{
                  icon: classes.icon,
                }}
                disabled={isFormDisabled}
                displayEmpty
                onChange={(event) => {
                  handlePlaceOfSupply(event.target.value);
                }}
                name="place_of_supply"
                required
                value={placeOfSupply}
                disableUnderline
                variant="outlined"
                style={{
                  width: "100%",
                  height: "30px",
                  fontSize: "12px",
                  borderRadius: "4px",
                }}
              >
                {indianStatesList.map((item, i) => {
                  return (
                    <MenuItem value={item} key={i} style={{ fontSize: "12px" }}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </InputWrapper>
        </Grid>

        <Grid item xs={12}>
          <InputWrapper>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Header>{getLocalizedText("product_or_services")}</Header>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={Open ? "long-menu" : undefined}
                aria-expanded={Open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                disabled={isFormDisabled}
              >
                <MoreVertIcon />
              </IconButton>

              <Menu
                style={{ display: "flex", flexDirection: "column" }}
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={Open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: "fit-content",
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleAddProduct();
                    setAnchorEl(null);
                  }}
                >
                  <MenuText>{getLocalizedText("add_product", "project")}</MenuText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleAddCustomItem();
                    setAnchorEl(null);
                  }}
                >
                  <MenuText>{getLocalizedText("add_custom_item")}</MenuText>
                </MenuItem>
                <MenuItem
                  color="primary"
                  onClick={(e) => {
                    setAnchor(e.currentTarget);
                  }}
                  size="small"
                >
                  <MenuText>{getLocalizedText("additional_columns")}</MenuText>{" "}
                </MenuItem>
              </Menu>
            </Box>
            {slug === "template_area_based" ? (
              <TableComponentAreaBased
                tableData={productAddedList}
                setOpen={setOpenAddProductModal}
                setSelectedProductData={setSelectedProductData}
                handleEdit={(item) => handleEdit(item)}
                setOpenViewProductModal={setOpenViewProductModal}
                handleDeleteProduct={handleDeleteProduct}
                isFormDisabled={isFormDisabled}
                handleCloneItem={handleCloneItem}
                tableAdditionalColumns={tableAdditionalColumns}
              />
            ) : (
              <TableComponent
                tableData={productAddedList}
                isFormDisabled={isFormDisabled}
                handleDelete={(item) => handleDelete(item)}
                handleEdit={(item) => handleEdit(item)}
                handleView={(item) => handleView(item)}
                handleClone={(item) => handleClone(item)}
                tableAdditionalColumns={tableAdditionalColumns}
              />
            )}
          </InputWrapper>
        </Grid>
        <Grid item xs={12}>
          <Accordion expanded={expanded} onChange={handleAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <DetailWrapper style={{ justifyContent: "flex-start" }}>
                <Header>Grand Total :</Header>
                <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                  <CurrencyRupee
                    style={{ width: "16px", height: "16px", color: "#4d4e4f" }}
                  />
                  <Header style={{ color: "#4d4e4f" }}>
                    {amountDetails["grand_total"] !== ""
                      ? amountDetails["grand_total"]
                      : "-"}
                  </Header>
                </Box>
              </DetailWrapper>
            </AccordionSummary>
            <AccordionDetails ref={accordionDetailsRef}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item xs={11}>
                  <DetailWrapper>
                    <DetailLabel>Sub Total :</DetailLabel>
                    <OutlinedInput
                      disabled={isFormDisabled}
                      style={{ height: "30px", width: "218px" }}
                      readOnly
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          padding: "0px 12px",
                          fontSize: "12px",
                        },
                      }}
                      startAdornment={
                        <InputAdornment
                          position="end"
                          style={{ marginLeft: "0px" }}
                        >
                          <Label style={{ fontSize: "12px", color: "#A9A9A9" }}>
                            INR
                          </Label>
                        </InputAdornment>
                      }
                      type="text"
                      name="sub_total"
                      onChange={(event) => handleAmountDetails(event)}
                      value={
                        amountDetails["sub_total"] !== ""
                          ? amountDetails["sub_total"]
                          : "-"
                      }
                      placeholder="Enter value"
                    />
                  </DetailWrapper>
                </Grid>
                <Grid item xs={11}>
                  <DetailWrapper>
                    <DetailLabel>Additional Discount :</DetailLabel>
                    <OutlinedInput
                      disabled={isFormDisabled}
                      style={{ height: "30px", width: "218px" }}
                      inputProps={{
                        min: 0,
                        step: "any",
                        "aria-label": "weight",
                        style: {
                          padding: "0px 12px",
                          fontSize: "12px",
                        },
                      }}
                      startAdornment={
                        <InputAdornment
                          position="end"
                          style={{ marginLeft: "0px" }}
                        >
                          <Label style={{ fontSize: "12px", color: "#A9A9A9" }}>
                            INR
                          </Label>
                        </InputAdornment>
                      }
                      type="number"
                      name="additional_discount"
                      onChange={(event) => handleAmountDetails(event)}
                      value={amountDetails["additional_discount"]}
                      placeholder="Enter value"
                    />
                  </DetailWrapper>
                </Grid>
                <Grid item xs={11}>
                  <DetailWrapper>
                    <DetailLabel>Additional Tax :</DetailLabel>
                    <OutlinedInput
                      disabled={isFormDisabled}
                      style={{ height: "30px", width: "218px" }}
                      inputProps={{
                        min: 0,
                        step: "any",
                        "aria-label": "weight",
                        style: {
                          padding: "0px 12px",
                          fontSize: "12px",
                        },
                      }}
                      startAdornment={
                        <InputAdornment
                          position="end"
                          style={{ marginLeft: "0px" }}
                        >
                          <Label style={{ fontSize: "12px", color: "#A9A9A9" }}>
                            INR
                          </Label>
                        </InputAdornment>
                      }
                      type="number"
                      name="additional_tax"
                      onChange={(event) => handleAmountDetails(event)}
                      value={amountDetails["additional_tax"]}
                      placeholder="Enter value"
                    />
                  </DetailWrapper>
                </Grid>
                <Grid item xs={11}>
                  <DetailWrapper>
                    <DetailLabel>Adjustments :</DetailLabel>
                    <OutlinedInput
                      disabled={isFormDisabled}
                      style={{ height: "30px", width: "218px" }}
                      inputProps={{
                        step: "any",
                        "aria-label": "weight",
                        style: {
                          padding: "0px 12px",
                          fontSize: "12px",
                        },
                      }}
                      startAdornment={
                        <InputAdornment
                          position="end"
                          style={{ marginLeft: "0px" }}
                        >
                          <Label style={{ fontSize: "12px", color: "#A9A9A9" }}>
                            INR
                          </Label>
                        </InputAdornment>
                      }
                      type="number"
                      name="adjustments"
                      onChange={(event) => handleAmountDetails(event)}
                      value={amountDetails["adjustments"]}
                      placeholder="Enter value"
                    />
                  </DetailWrapper>
                </Grid>
                <Grid item xs={11}>
                  <DetailWrapper>
                    <DetailLabel>Grand Total :</DetailLabel>
                    <OutlinedInput
                      disabled={isFormDisabled}
                      style={{ height: "30px", width: "218px" }}
                      readOnly
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          padding: "0px 12px",
                          fontSize: "12px",
                        },
                      }}
                      startAdornment={
                        <InputAdornment
                          position="end"
                          style={{ marginLeft: "0px" }}
                        >
                          <Label style={{ fontSize: "12px", color: "#A9A9A9" }}>
                            INR
                          </Label>
                        </InputAdornment>
                      }
                      type="text"
                      name="grand_total"
                      onChange={(event) => handleAmountDetails(event)}
                      value={
                        amountDetails["grand_total"] !== ""
                          ? amountDetails["grand_total"]
                          : "-"
                      }
                      placeholder="Enter value"
                    />
                  </DetailWrapper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      {anchor !== null && (
        <AdditionalColumnsDialog
          anchor={anchor}
          handleClose={() => setAnchor(null)}
          setTableAdditionalColumns={setTableAdditionalColumns}
          tableAdditionalColumns={tableAdditionalColumns}
        />
      )}
    </Box>
  );
};

export default QuoteAndProuductInfo;
