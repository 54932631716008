import React from "react";
import { Tooltip } from "@mui/material";
import MDEditor, { commands } from "@uiw/react-md-editor";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

const WhatsappMessageTextEditor = ({ value, handleChange, placeholder }) => {
  const italicCommand = {
    name: "italic_whatsapp",
    keyCommand: "italic",
    buttonProps: { "aria-label": "Italic" },
    icon: (
      <Tooltip title="Italic" arrow>
        <FormatItalicIcon sx={{ fontSize: "small" }} />
      </Tooltip>
    ),
    execute: (state, api) => {
      const { selectedText } = state;

      const newText = `_${selectedText}_`;

      api.replaceSelection(newText);
    },
  };

  const boldCommand = {
    name: "bold_whatsapp",
    keyCommand: "bold",
    buttonProps: { "aria-label": "Bold" },
    icon: (
      <Tooltip title="Bold" arrow>
        <FormatBoldIcon sx={{ fontSize: "small" }} />
      </Tooltip>
    ),
    execute: (state, api) => {
      const { selectedText } = state;

      const newText = `*${selectedText}*`;

      api.replaceSelection(newText);
    },
  };

  const strikethroughCommand = {
    name: "strikethrough_whatsapp",
    keyCommand: "strikethrough",
    buttonProps: { "aria-label": "Strikethrough" },
    icon: (
      <Tooltip title="Strikethrough" arrow>
        <StrikethroughSIcon sx={{ fontSize: "small" }} />
      </Tooltip>
    ),
    execute: (state, api) => {
      const { selectedText } = state;
      const newText = `~${selectedText}~`;

      api.replaceSelection(newText);
    },
  };

  const bulletedListCommand = {
    name: "bulleted_list_whatsapp",
    keyCommand: "bulleted_list",
    buttonProps: { "aria-label": "Bulleted List" },
    icon: (
      <Tooltip title="Bulleted List" arrow>
        <FormatListBulletedIcon sx={{ fontSize: "small" }} />
      </Tooltip>
    ),
    execute: (state, api) => {
      const { selectedText } = state;

      const newText = selectedText
        .split("\n")
        .map((line) => `* ${line}`)
        .join("\n");

      api.replaceSelection(newText);
    },
  };

  const numberedListCommand = {
    name: "numbered_list_whatsapp",
    keyCommand: "numbered_list",
    buttonProps: { "aria-label": "Numbered List" },
    icon: (
      <Tooltip title="Numbered List" arrow>
        <FormatListNumberedIcon sx={{ fontSize: "small" }} />
      </Tooltip>
    ),
    execute: (state, api) => {
      const { selectedText } = state;

      const newText = selectedText
        .split("\n")
        .map((line, index) => `${index + 1}. ${line}`)
        .join("\n");

      api.replaceSelection(newText);
    },
  };

  const quoteCommand = {
    name: "quote_whatsapp",
    keyCommand: "quote",
    buttonProps: { "aria-label": "Quote" },
    icon: (
      <Tooltip title="Quote" arrow>
        <FormatQuoteIcon sx={{ fontSize: "small" }} />
      </Tooltip>
    ),
    execute: (state, api) => {
      const { selectedText } = state;

      const newText = selectedText
        .split("\n")
        .map((line) => `> ${line}`)
        .join("\n");

      api.replaceSelection(newText);
    },
  };

  return (
    <div className="container" data-color-mode="light">
      <MDEditor
        value={value}
        onChange={(value) => {
          handleChange(value);
        }}
        textareaProps={{
          placeholder: placeholder,
        }}
        preview="edit"
        commands={[
          boldCommand,
          italicCommand,
          strikethroughCommand,
          bulletedListCommand,
          numberedListCommand,
          quoteCommand,
        ]}
        commandsFilter={(cmd) => {
          //only showing custom whatsapp commands
          const allowedCommands = [
            "bold_whatsapp",
            "italic_whatsapp",
            "strikethrough_whatsapp",
            "bulleted_list_whatsapp",
            "numbered_list_whatsapp",
            "quote_whatsapp",
          ];
          return allowedCommands.includes(cmd.name) ? cmd : false;
        }}
        className="custom-editor"
      />
      {/* overriding markdown styling in text editor field */}
      <style>
        {`
            .custom-editor * {
              font-style: normal !important;
              font-weight: normal !important;
              text-decoration: none !important;
              color: black !important;
            }
          `}
      </style>
    </div>
  );
};

export default WhatsappMessageTextEditor;
