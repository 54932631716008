import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@material-ui/core";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  Grid,
  OutlinedInput,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import MuiAlert from "@mui/material/Alert";
import {
  createProductLineApi,
  editProductLineApi,
  getProductLineDetailsApi,
  getProductManufacturerListApi,
} from "../../Api";
import { getAccountLocalizedText, getLocalizedText } from "../../Functions";
import PreviewImageDialog from "../files_page_components/PreviewImageDialog";
import PreviewPdfDialog from "../files_page_components/PreviewPdfDialog";
import { LoadingButton } from "@mui/lab";
import BasicDetailsScreen from "./screens/BasicDetailsScreen";
import PreviewVideoDialog from "../files_page_components/PreviewVideoDialog";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BuilderScreen from "./screens/BuilderScreen";

const drawerWidth = "500px";
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const Wrapper = styled(Box)`
  width: 100%;
  ${`height: calc(100vh - 132px);`};
  padding: 16px;
  overflow-y: auto;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 18px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #979797;
`;
const Detail = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const AddProductLineSidePanel = ({
  setRefresh,
  productLineID,
  handleClose,
  isEdit,
}) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const columnsList = [
    {
      column_name: "name",
      display_name: "Name",
    },
    {
      column_name: "tags",
      display_name: "Tags",
    },
    {
      column_name: "desc",
      display_name: "Description",
    },
  ];

  const [selectedImages, setSelectedImages] = useState({
    image_1: null,
    image_2: null,
    image_3: null,
    image_4: null,
  });
  const [selectedDocuments, setSelectedDocuments] = useState({
    file_1: null,
    file_2: null,
    file_3: null,
    file_4: null,
  });
  const [viewImageDialog, setViewImageDialog] = useState(false);
  const [viewPdfDialog, setViewPdfDialog] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [submittingForm, setSubmittingForm] = useState(false);
  const [fieldValues, setFieldValues] = useState({});
  const [payloadData, setPayloadData] = useState({});
  const [specificationList, setSpecificationList] = useState([]);
  const [additionalDetailsList, setAdditionalDetailsList] = useState([]);
  const [productManufacturerList, setProductManufacturerList] = useState([]);
  const [builderDetails, setBuilderDetails] = useState(null);
  //children components have product_ prefix so keeping it as "product_image". in payload it is sent as "image"
  const [mediaDetails, setMediaDetails] = useState({
    product_image: null,
    product_video: null,
    product_brochure: null,
  });
  const [openVideoPreviewDialog, setOpenVideoPreviewDialog] = useState(false);
  const objectUrlCache = new Map();
  const [specificationsDetails, setSpecificationsDetails] = useState({
    label: "",
    value: "",
  });
  const handleSpecificationsChange = (e) => {
    setSpecificationsDetails({
      ...specificationsDetails,
      [e.target.name]: e.target.value,
    });
  };

  const [additionalDetails, setAdditionalDetails] = useState({
    label: "",
    value: "",
  });
  const handleAdditionalDetailsChange = (e) => {
    setAdditionalDetails({
      ...additionalDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleAdditionalDetails = (details) => {
    let tempAdditionalDetailsList = [...additionalDetailsList];
    let index = tempAdditionalDetailsList.findIndex(
      (entry) => entry["label"].toLowerCase() === details["label"].toLowerCase()
    );
    if (index === -1) {
      tempAdditionalDetailsList.push(details);
    }
    setAdditionalDetailsList(tempAdditionalDetailsList);
  };

  const handleAdditionalDetailsDelete = (label) => {
    let tempAdditionalDetailsList = [...additionalDetailsList];
    tempAdditionalDetailsList = tempAdditionalDetailsList.filter(
      (entry) => entry["label"].toLowerCase() !== label.toLowerCase()
    );
    setAdditionalDetailsList(tempAdditionalDetailsList);
  };
  const handleSpecificationDetails = (details) => {
    let tempSpecificationList = [...specificationList];
    let index = tempSpecificationList.findIndex(
      (entry) => entry["label"].toLowerCase() === details["label"].toLowerCase()
    );
    if (index === -1) {
      tempSpecificationList.push(details);
    }
    setSpecificationList(tempSpecificationList);
  };
  const handleSpecificationsDelete = (label) => {
    let tempSpecificationList = [...specificationList];
    tempSpecificationList = tempSpecificationList.filter(
      (entry) => entry["label"].toLowerCase() !== label.toLowerCase()
    );
    setSpecificationList(tempSpecificationList);
  };

  const updatePayloadValue = (key, value) => {
    const columnIndex = columnsList.findIndex(
      (entry) => entry["column_name"] === key
    );

    if (columnIndex !== -1) {
      let fieldValue = value;
      const columnData = columnsList[columnIndex];

      //array type
      if (key === "tags") {
        if (value !== null && value !== "") {
          fieldValue = JSON.stringify(value);
        }
      }

      let tempPayloadData = { ...payloadData };

      tempPayloadData[key] = fieldValue;

      setPayloadData(tempPayloadData);
    }
  };

  const handleChange = ({ key, value }) => {
    setIsSubmitFail(false);

    setFieldValues({ ...fieldValues, [key]: value });
    updatePayloadValue(key, value);
  };

  const createProductLine = async (formData) => {
    let response = await createProductLineApi({
      payload: formData,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };
  const updateProductLine = async (formData) => {
    let response = await editProductLineApi({
      productLineID: productLineID,
      payload: formData,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };
  const handleSubmit = async () => {
    setSubmittingForm(true);
    let formData = new FormData();

    Object.keys(payloadData).forEach((key) => {
      if (payloadData[key] !== "") {
        formData.append(key, payloadData[key]);
      }
    });
    const specifications = {};
    specificationList.forEach((entry) => {
      specifications[entry["label"]] = entry["value"];
    });
    formData.append("specifications", JSON.stringify(specifications));

    const additionalDetails = {};
    additionalDetailsList.forEach((entry) => {
      additionalDetails[entry["label"]] = entry["value"];
    });
    formData.append("additional_details", JSON.stringify(additionalDetails));
    Object.keys(selectedImages).forEach((key) => {
      let value = selectedImages[key];
      if (value !== null) {
        if (value instanceof File) {
          formData.append(key, selectedImages[key]);
        }
      } else {
        formData.append(key, null);
      }
    });

    Object.keys(selectedDocuments).forEach((key) => {
      let value = selectedDocuments[key];
      if (value !== null) {
        if (value instanceof File) {
          formData.append(key, selectedDocuments[key]);
        }
      } else {
        formData.append(key, null);
      }
    });

    formData.append("image", mediaDetails["product_image"]);
    formData.append("video", mediaDetails["product_video"]);
    formData.append("brochure", mediaDetails["product_brochure"]);

    formData.append(
      "product_manufacturer_id",
      builderDetails != null ? builderDetails["value"]["id"] : null
    );

    if (isEdit === true) {
      await updateProductLine(formData);
    } else {
      await createProductLine(formData);
    }
    setSubmittingForm(false);
  };

  const getProductManufacturerList = async () => {
    const queryPayload = {
      name: "",
    };

    let response = await getProductManufacturerListApi({
      page: 0,
      page_size: 10,
      payload: { query: queryPayload },
    });
    if (Object.keys(response).length > 0) {
      setProductManufacturerList(response.items);
    } else {
      setProductManufacturerList([]);
    }
  };

  const init = async () => {
    if (isEdit === true) {
      await initEditableData({ columnList: columnsList });
    }
    await getProductManufacturerList();
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const imageKeys = Object.keys(selectedImages);

    files.forEach((file) => {
      const availableKey = imageKeys.find(
        (key) => selectedImages[key] === null
      );
      if (availableKey) {
        setSelectedImages((prevImages) => ({
          ...prevImages,
          [availableKey]: file,
        }));
      }
    });
  };

  const handleDocumentUpload = (event) => {
    const files = Array.from(event.target.files);
    const docKeys = Object.keys(selectedDocuments);

    files.forEach((file) => {
      const availableKey = docKeys.find(
        (key) => selectedDocuments[key] === null
      );
      if (availableKey) {
        setSelectedDocuments((prevDocs) => ({
          ...prevDocs,
          [availableKey]: file,
        }));
      }
    });
  };

  const handleImagePreview = (image) => {
    if (image instanceof File) {
      const objectURL = URL.createObjectURL(image);
      setSelectedFileUrl(objectURL);
    } else {
      setSelectedFileUrl(image["url"]);
    }
    setViewImageDialog(true);
  };
  const handleDocumentPreview = (doc) => {
    if (doc instanceof File) {
      const objectURL = URL.createObjectURL(doc);
      setSelectedFileUrl(objectURL);
    } else {
      setSelectedFileUrl(doc["url"]);
    }
    setViewPdfDialog(true);
  };

  const handleVideoPreview = (video) => {
    if (video instanceof File) {
      const objectURL = URL.createObjectURL(video);
      setSelectedFileUrl(objectURL);
    } else {
      setSelectedFileUrl(video["url"]);
    }
    setOpenVideoPreviewDialog(true);
  };

  const handleRemoveImage = (key) => {
    setSelectedImages((prevImages) => ({
      ...prevImages,
      [key]: null,
    }));
  };

  const handleRemoveDoc = (key) => {
    setSelectedDocuments((prevDocs) => ({
      ...prevDocs,
      [key]: null,
    }));
  };

  const getFileUrl = (file) => {
    if (file instanceof File) {
      // Check if the URL is already cached
      if (!objectUrlCache.has(file)) {
        const objectURL = URL.createObjectURL(file);
        objectUrlCache.set(file, objectURL);
      }
      return objectUrlCache.get(file);
    } else {
      // For non-File objects, return the existing URL
      return file["url"];
    }
  };

  const RenderNextOrSubmitButton = () => {
    if (submittingForm) {
      return (
        <LoadingButton
          loading
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            width: "100px",
          }}
        >
          <span> {getLocalizedText("submit")}</span>
        </LoadingButton>
      );
    } else {
      return (
        <Button
          color="primary"
          type="submit"
          variant="contained"
          style={{
            width: "100px",
            textTransform: "none",
          }}
        >
          {getLocalizedText("submit")}
        </Button>
      );
    }
  };

  const handleProductMediaUpload = (event, fileType) => {
    const file = event.target.files[0];
    if (file) {
      setMediaDetails({ ...mediaDetails, [fileType]: file });
    }
  };

  const getFieldValue = ({ columnMeta, productDetails }) => {
    let productData = productDetails;
    let key = columnMeta["column_name"];
    if (columnMeta["is_custom_param"] === true) {
      productData = productDetails.hasOwnProperty("custom_params")
        ? JSON.parse(productDetails["custom_params"])
        : {};
      key = columnMeta["column_name"].replace("custom_params_", "");
    }

    if (productData.hasOwnProperty(key)) {
      let value = productData[key];
      if (value !== null && value !== "") {
        if (
          columnMeta["data_type"] === "date" ||
          columnMeta["data_type"] === "datetime"
        ) {
          value = moment(productData[key]);
        } else if (columnMeta["data_type"] === "integer") {
          value = parseInt(productData[key]);
        } else if (columnMeta["data_type"] === "decimal") {
          value = parseFloat(productData[key]);
        } else {
          if (
            columnMeta["filter_type"] === "list" &&
            columnMeta["is_array"] === false
          ) {
            value = { label: productData[key], value: productData[key] };
          }
          if (columnMeta["is_array"] === true) {
            if (typeof productData[key] === "string") {
              value = productData[key].split(",");
            }
          }
        }
        return value;
      }
      return "";
    }
  };

  const getPrefilledInputData = ({ columnList, productDetails }) => {
    let tempInputData = {};

    for (let i = 0; i < columnList.length; i++) {
      let item = columnList[i];

      let value = getFieldValue({
        columnMeta: item,
        productDetails: productDetails,
      });
      tempInputData[item["column_name"]] = value;
    }

    return tempInputData;
  };

  const initEditableData = async ({ columnList }) => {
    let productDetails = await getProductLineDetails();
    let preFilledData = getPrefilledInputData({
      columnList: columnList,
      productDetails: productDetails,
    });
    setFieldValues(preFilledData);
    const tempImageFiles = Object.keys(productDetails)
      .filter((key) => key.startsWith("image_"))
      .reduce((acc, key) => {
        acc[key] = productDetails[key];
        return acc;
      }, {});
    const tempDocFiles = Object.keys(productDetails)
      .filter((key) => key.startsWith("file_"))
      .reduce((acc, key) => {
        acc[key] = productDetails[key];
        return acc;
      }, {});
    setSelectedImages(tempImageFiles);
    setSelectedDocuments(tempDocFiles);

    let productImage =
      productDetails.hasOwnProperty("image") &&
      productDetails["image"] !== null &&
      productDetails["image"] !== ""
        ? productDetails["image"]
        : null;
    let productVideo =
      productDetails.hasOwnProperty("video") &&
      productDetails["video"] !== null &&
      productDetails["video"] !== ""
        ? productDetails["video"]
        : null;
    let productBrochure =
      productDetails.hasOwnProperty("brochure") &&
      productDetails["brochure"] !== null &&
      productDetails["brochure"] !== ""
        ? productDetails["brochure"]
        : null;
    setMediaDetails({
      product_image: productImage,
      product_brochure: productBrochure,
      product_video: productVideo,
    });
    let existingAdditionalDetails =
      productDetails.hasOwnProperty("additional_details") &&
      productDetails["additional_details"] !== null &&
      productDetails["additional_details"] !== ""
        ? productDetails["additional_details"]
        : {};
    if (typeof existingAdditionalDetails === "string") {
      existingAdditionalDetails = JSON.parse(existingAdditionalDetails);
    }

    let exstingSpecifications =
      productDetails.hasOwnProperty("specifications") &&
      productDetails["specifications"] !== null &&
      productDetails["specifications"] !== ""
        ? productDetails["specifications"]
        : {};
    if (typeof exstingSpecifications === "string") {
      exstingSpecifications = JSON.parse(exstingSpecifications);
    }
    let tempSpecificationList = [];
    for (const key in exstingSpecifications) {
      tempSpecificationList.push({
        label: key,
        value: exstingSpecifications[key],
      });
    }
    let tempAdditionalDetailsList = [];
    for (const key in existingAdditionalDetails) {
      tempAdditionalDetailsList.push({
        label: key,
        value: existingAdditionalDetails[key],
      });
    }

    setAdditionalDetailsList(tempAdditionalDetailsList);

    setSpecificationList(tempSpecificationList);

    setBuilderDetails(
      productDetails.hasOwnProperty("product_manufacturer") &&
        productDetails.product_manufacturer !== null &&
        productDetails.product_manufacturer !== ""
        ? {
            label: productDetails.product_manufacturer.name,
            value: productDetails.product_manufacturer,
          }
        : null
    );
  };

  const getProductLineDetails = async () => {
    let response = await getProductLineDetailsApi({ productLineID: productLineID });
    return response;
  };

  return !loading ? (
    <>
      <Box role="presentation" sx={{ width: drawerWidth }}>
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: drawerWidth,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar style={{ padding: "16px" }}>
            <ModelHeader>
              {isEdit === true
                ? getAccountLocalizedText("edit_product_line")
                : getAccountLocalizedText("add_product_line")}
            </ModelHeader>
          </Toolbar>
        </AppBar>

        {!isSubmitSuccess ? (
          <Box
            component="main"
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Wrapper
              component={"form"}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              {" "}
              <BasicDetailsScreen
                getFileUrl={(file) => getFileUrl(file)}
                fieldValues={fieldValues}
                handleChange={({ key, value }) => {
                  handleChange({
                    key: key,
                    value: value,
                  });
                }}
                mediaDetails={mediaDetails}
                handleRemoveProductMedia={(type) => {
                  let tempMediaDetails = { ...mediaDetails };
                  tempMediaDetails[type] = null;
                  setMediaDetails(tempMediaDetails);
                }}
                handleViewProductMedia={(type) => {
                  if (type === "product_image") {
                    handleImagePreview(mediaDetails["product_image"]);
                  } else if (type === "product_brochure") {
                    handleDocumentPreview(mediaDetails["product_brochure"]);
                  } else if (type === "product_video") {
                    handleVideoPreview(mediaDetails["product_video"]);
                  }
                }}
                handleProductMediaUpload={(file, type) =>
                  handleProductMediaUpload(file, type)
                }
                handleRemoveDoc={(idx) => handleRemoveDoc(idx)}
                handleDocumentPreview={(doc) => handleDocumentPreview(doc)}
                handleImageUpload={(imageFile) => handleImageUpload(imageFile)}
                handleRemoveImage={(imageIndex) =>
                  handleRemoveImage(imageIndex)
                }
                handleImagePreview={(docFile) => handleImagePreview(docFile)}
                selectedDocuments={selectedDocuments}
                selectedImages={selectedImages}
                handleDocumentUpload={(docFile) =>
                  handleDocumentUpload(docFile)
                }
              />
              <Box sx={{ marginTop: "8px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <legend
                          style={{
                            width: "auto",
                            marginBottom: "0px",
                            fontSize: "12px",
                          }}
                        >
                          <SectionHeader>Specifications</SectionHeader>
                        </legend>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="flex-end">
                              <Grid item xs={5}>
                                <InputWrapper>
                                  <Label>Specification Name</Label>
                                  <OutlinedInput
                                    style={{ height: "30px" }}
                                    inputProps={{
                                      "aria-label": "weight",
                                      style: {
                                        fontSize: "12px",
                                      },
                                    }}
                                    value={specificationsDetails.label}
                                    type="text"
                                    placeholder=""
                                    name="label"
                                    onChange={handleSpecificationsChange}
                                  />
                                </InputWrapper>
                              </Grid>
                              <Grid item xs={5}>
                                <InputWrapper>
                                  <Label>Specification Value</Label>

                                  <OutlinedInput
                                    style={{ height: "30px" }}
                                    inputProps={{
                                      "aria-label": "weight",
                                      style: {
                                        fontSize: "12px",
                                      },
                                    }}
                                    value={specificationsDetails.value}
                                    type="text"
                                    placeholder=""
                                    name="value"
                                    onChange={handleSpecificationsChange}
                                  />
                                </InputWrapper>
                              </Grid>
                              <Grid item xs={2}>
                                <Button
                                  style={{
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    width: "100%",
                                    fontSize: "12px",
                                  }}
                                  disabled={
                                    specificationsDetails["label"] === "" ||
                                    specificationsDetails["value"] === ""
                                  }
                                  color="primary"
                                  variant="contained"
                                  onClick={() => {
                                    handleSpecificationDetails(
                                      specificationsDetails
                                    );
                                    setSpecificationsDetails({
                                      label: "",
                                      value: "",
                                    });
                                  }}
                                >
                                  {" "}
                                  ADD
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Box sx={{ width: "100%" }}>
                              {specificationList.length > 0 &&
                                specificationList.map((entry, idx) => {
                                  return (
                                    <Grid
                                      container
                                      spacing={2}
                                      alignItems="center"
                                    >
                                      <Grid item xs={5}>
                                        <Detail>{entry["label"]}</Detail>
                                      </Grid>
                                      <Grid item xs={5}>
                                        <Detail>{entry["value"]}</Detail>
                                      </Grid>
                                      <Grid xs={2} item>
                                        <Button
                                          style={{
                                            textTransform: "none",
                                            fontWeight: "bold",
                                            width: "100%",
                                            fontSize: "12px",
                                          }}
                                          color="error"
                                          variant="outlined"
                                          onClick={() => {
                                            handleSpecificationsDelete(
                                              entry["label"]
                                            );
                                          }}
                                        >
                                          DELETE
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <legend
                          style={{
                            width: "auto",
                            marginBottom: "0px",
                            fontSize: "12px",
                          }}
                        >
                          <SectionHeader>Additional Details</SectionHeader>
                        </legend>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            {/* <Box
          sx={{
            border: "1px solid #cccccc",
            padding: "20px",
          }}
        > */}
                            <Grid container spacing={2} alignItems="flex-end">
                              <Grid item xs={5}>
                                <InputWrapper>
                                  <Label>Name</Label>
                                  <OutlinedInput
                                    style={{ height: "30px" }}
                                    inputProps={{
                                      "aria-label": "weight",
                                      style: {
                                        fontSize: "12px",
                                      },
                                    }}
                                    value={additionalDetails.label}
                                    type="text"
                                    placeholder=""
                                    name="label"
                                    onChange={handleAdditionalDetailsChange}
                                  />
                                </InputWrapper>
                              </Grid>
                              <Grid item xs={5}>
                                <InputWrapper>
                                  <Label>Value</Label>
                                  {/* <Box
                  sx={{ display: "flex", alignItems: "flex-end", gap: "16px" }}
                > */}
                                  <OutlinedInput
                                    style={{ height: "30px" }}
                                    inputProps={{
                                      "aria-label": "weight",
                                      style: {
                                        fontSize: "12px",
                                      },
                                    }}
                                    value={additionalDetails.value}
                                    type="text"
                                    placeholder=""
                                    name="value"
                                    onChange={handleAdditionalDetailsChange}
                                  />
                                  {/* <Button
                    style={{
                      textTransform: "none",
                      fontWeight: "bold",
                      width: "100%",
                      fontSize: "12px",
                    }}
                    disabled={
                      specificationsDetails["label"] === "" || specificationsDetails["value"] === ""
                    }
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      handleAdditionalDetails(specificationsDetails);
                      setSpecificationsDetails({ label: "", value: "" });
                    }}
                  >
                    {" "}
                    ADD
                  </Button>
                </Box> */}
                                </InputWrapper>
                              </Grid>
                              <Grid item xs={2}>
                                {/* <Button
                style={{
                  textTransform: "none",
                  fontWeight: "bold",
                  width: "100%",
                }}
                disabled={specificationsDetails["label"] === "" || specificationsDetails["value"] === ""}
                color="primary"
                variant="text"
                onClick={() => {
                  handleAdditionalDetails(specificationsDetails);
                  setSpecificationsDetails({ label: "", value: "" });
                }}
              >
                <Add /> Add Specification
              </Button> */}
                                <Button
                                  style={{
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    width: "100%",
                                    fontSize: "12px",
                                  }}
                                  disabled={
                                    additionalDetails["label"] === "" ||
                                    additionalDetails["value"] === ""
                                  }
                                  color="primary"
                                  variant="contained"
                                  onClick={() => {
                                    handleAdditionalDetails(additionalDetails);
                                    setAdditionalDetails({
                                      label: "",
                                      value: "",
                                    });
                                  }}
                                >
                                  {" "}
                                  ADD
                                </Button>
                              </Grid>
                            </Grid>
                            {/* </Box> */}
                          </Grid>
                          <Grid item xs={12}>
                            <Box sx={{ width: "100%" }}>
                              {additionalDetailsList.length > 0 &&
                                additionalDetailsList.map((entry, idx) => {
                                  return (
                                    <Grid
                                      container
                                      spacing={2}
                                      // key={idx}
                                      style={
                                        {
                                          // backgroundColor: idx % 2 !== 0 ? "#F2F2F2" : "white",
                                          // padding: "4px 0px",
                                          // width: "100%",
                                        }
                                      }
                                      alignItems="center"
                                    >
                                      <Grid item xs={5}>
                                        <Detail>{entry["label"]}</Detail>
                                      </Grid>
                                      <Grid item xs={5}>
                                        <Detail>{entry["value"]}</Detail>
                                      </Grid>
                                      <Grid xs={2} item>
                                        <Button
                                          style={{
                                            textTransform: "none",
                                            fontWeight: "bold",
                                            width: "100%",
                                            fontSize: "12px",
                                          }}
                                          color="error"
                                          variant="outlined"
                                          onClick={() => {
                                            handleAdditionalDetailsDelete(
                                              entry["label"]
                                            );
                                          }}
                                        >
                                          DELETE
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <legend
                          style={{
                            width: "auto",
                            marginBottom: "0px",
                            fontSize: "12px",
                          }}
                        >
                          <SectionHeader>
                            {getAccountLocalizedText("manufacturer")}
                          </SectionHeader>
                        </legend>
                      </AccordionSummary>
                      <AccordionDetails>
                        <BuilderScreen
                          details={builderDetails}
                          handleChange={(value) => {
                            setBuilderDetails(value);
                          }}
                          productManufacturerList={productManufacturerList}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </Box>
              <AppBar
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                  top: "auto",
                  bottom: 0,
                  width: drawerWidth,
                  bgcolor: "#f9f9fc",
                }}
              >
                <Toolbar
                  style={{
                    width: drawerWidth,
                    position: "relative",
                    padding: "16px",
                  }}
                >
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <ButtonWrapper>
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      color="primary"
                      style={{ width: "100px", textTransform: "none" }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                    <RenderNextOrSubmitButton />
                  </ButtonWrapper>
                </Toolbar>
              </AppBar>
            </Wrapper>
          </Box>
        ) : (
          <MessageWrapper>
            {isEdit ? (
              <Message>
                {getAccountLocalizedText("product_line_updated_successfully")}
              </Message>
            ) : (
              <Message>
                {getAccountLocalizedText("product_line_added_successfully")}
              </Message>
            )}

            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="inherit"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        )}
      </Box>

      {viewPdfDialog && (
        <PreviewPdfDialog
          open={viewPdfDialog}
          setOpen={setViewPdfDialog}
          file={selectedFileUrl}
        />
      )}
      {viewImageDialog && (
        <PreviewImageDialog
          open={viewImageDialog}
          setOpen={setViewImageDialog}
          file={selectedFileUrl}
          isCreate={false}
        />
      )}
      {openVideoPreviewDialog && (
        <PreviewVideoDialog
          open={openVideoPreviewDialog}
          setOpen={setOpenVideoPreviewDialog}
          fileUrl={
            mediaDetails["product_video"] !== null &&
            mediaDetails["product_video"] !== ""
              ? URL.createObjectURL(mediaDetails["product_video"])
              : ""
          }
        />
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: drawerWidth,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(AddProductLineSidePanel);
