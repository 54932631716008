import React, { useEffect, useState, useRef } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  FormControl,
  OutlinedInput,
  Button,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import { Grid, Modal, makeStyles } from "@material-ui/core";
import WhatsappSendTemplateMessagePreview from "./WhatsappSendTemplateMessagePreview";
import {
  consoleLogger,
  getLeadVariableValue,
  getLocalizedText,
  getSystemVariableValue,
} from "../../Functions";
import rootStore from "../../stores/RootStore";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
  border: "1px solid #c5d7f1",
  borderRadius: "8px",
  padding: "16px",
  width: "100%",
  maxWidth: "560px",
  maxHeight: "95vh",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
  height: "100%",
  overflow: "hidden",
}));
const PreviewWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  width: "100%",
  padding: "15px",
  position: "relative",
  backgroundColor: "#e5ddd5",
  boxSizing: "border-box",
  overflow: "auto",
  maxHeight: "60vh",
  flexShrink: 0,
}));
const FieldsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "100%",
  gap: "8px",
  overflowY: "auto",
  maxHeight: "calc(95vh - 200px)",
  boxSizing: "border-box",
  width: "100%",
  padding: "8px",
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  gap: "8px",
}));

const Label = styled(Typography)(({ theme }) => ({
  width: "25%",
  font: "normal normal 500 12px Open Sans",
  color: "#4d4e4f",
}));

const PreviewHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
}));

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    minHeight: "20px",
  },
}));

function TemplateMessageSendPopupComponent(props) {
  //consoleLogger("data::", props.data);
  const toolbarStyle = useStyles();
  const [headerVariableField, setHeaderVariableField] = useState({});
  const [variableDetails, setVariableDetails] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const contactColumns = [...rootStore.contactStore.columnsList];
  const [mediaMissingWarning, setMediaMissingWarning] = useState(false);

  const handleSubmit = () => {
    props.onSendCallback(variableDetails, selectedFile);
    handleClose();
  };

  const handleClose = () => {
    props.onCloseCallback();
    props.setOpen(false);
  };

  const getComponentByType = (type) => {
    const component = props.data["components"].find(
      (x) => x.type.toLowerCase() === type
    );
    return component || null;
  };

  function extractPlaceholders(inputString) {
    const regex = /\{\{\d+\}\}/g;
    const matches = inputString.match(regex);
    return matches || [];
  }

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    //header var handling
    const headerData = getComponentByType("header");
    if (
      headerData !== null &&
      headerData.hasOwnProperty("format") &&
      headerData.format !== null
    ) {
      if (
        headerData.format.toLowerCase() === "image" ||
        headerData.format.toLowerCase() === "video"
      ) {
        setHeaderVariableField({
          name: headerData.format.toLowerCase(),
          id: -1,
        });
      }
    }
    let bodyData = [];

    if (props.data.components.length > 0) {
      bodyData = props.data["components"].find(
        (x) => x.type.toLowerCase() === "body"
      );
    }

    let componentVariableMapping = {};
    let variablesWithType = [];
    let allPlaceholders = extractPlaceholders(bodyData["text"]);
    let variableDetailsList = allPlaceholders.map((placeholder, i) => {
      return {
        name: placeholder,
        id: i + 1,
        is_visible: true,
        contact_column: false,
        value: "",
      };
    });
    if (props.data.hasOwnProperty("variables")) {
      componentVariableMapping = props.data["variables"];
      for (const key in componentVariableMapping) {
        let value = "";

        if (
          typeof componentVariableMapping[key] === "object" &&
          componentVariableMapping[key] !== null
        ) {
          const name = componentVariableMapping[key]["name"];
          const mappingKey = key.replace(/[{}]/g, "");
          variableDetailsList[mappingKey - 1]["value"] =
            componentVariableMapping[key]["name"];

          const index = contactColumns.findIndex(
            (column) => column.display_name === name
          );

          if (index !== -1) {
            value = contactColumns[index]["id"]; //getting column id from Name in mapping if there is a match
          }
        } else {
          // for older templates, using the value directly from componentVariableMapping key
          value = componentVariableMapping[key]; //value is contact column id here
        }

        const index = contactColumns.findIndex((column) => column.id === value);
        if (index !== -1) {
          const mappingKey = key.replace(/[{}]/g, "");
          variablesWithType.push({
            variable_id: mappingKey,
            variable_name: contactColumns[index]["display_name"],
            variable_type: "lead_variable",
          });
        }
      }

      //prefill lead variable in details
      const leadVariableValues = getLeadVariableValuesForMessage({
        variablesWithType: variablesWithType,
        leadData: props.leadData,
      });

      Object.keys(leadVariableValues).forEach((key) => {
        const index = variableDetailsList.findIndex(
          (item) => item.id === parseInt(key)
        );
        if (index !== -1) {
          //filling lead value in field if a value is there
          if (
            leadVariableValues[key] !== "" &&
            leadVariableValues[key] !== null
          ) {
            variableDetailsList[index]["value"] = leadVariableValues[key];
          }
        }
      });
    }
    setVariableDetails(variableDetailsList);
  };

  //used on init to prefill contact column mapping from template
  const getLeadVariableValuesForMessage = ({ variablesWithType, leadData }) => {
    const columnsList = [...rootStore.userStore.AllColumnsList];

    let tempVariableValueMapping = {};
    variablesWithType.forEach((variableData, i) => {
      if (variableData["variable_type"] === "lead_variable") {
        let index = columnsList.findIndex(
          (column) => column["headerName"] === variableData["variable_name"]
        );
        if (index !== -1) {
          let variableValue = getLeadVariableValue({
            columnMeta: columnsList[index],
            leadData: leadData,
          });
          tempVariableValueMapping[variableData["variable_id"]] = variableValue;
        } else {
          tempVariableValueMapping[variableData["variable_id"]] = null;
        }
      } else {
        let variableValue = getSystemVariableValue({
          variable: variableData["variable_name"],
        });
        tempVariableValueMapping[variableData["variable_id"]] = variableValue;
      }
    });
    return tempVariableValueMapping;
  };

  //used on input change to map with entered lead variables
  const getLeadVariableForPlaceholder = ({ leadVariableName, leadData }) => {
    const columnsList = [...rootStore.userStore.AllColumnsList];
    let index = columnsList.findIndex(
      (column) => column["headerName"] === leadVariableName
    );
    if (index !== -1) {
      let variableValue = getLeadVariableValue({
        columnMeta: columnsList[index],
        leadData: leadData,
      });
      return variableValue;
    } else {
      return null;
    }
  };

  const handlePhotoAdd = (event) => {
    setSelectedFile(event.target.files);
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModelContainer>
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          style={{
            top: "-16px",
            bottom: "auto",
            width: "100%",
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              width: "100%",
              position: "relative",
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              style={{ height: "50px" }}
            >
              <PreviewHeader>Send template</PreviewHeader>
            </Grid>
          </Toolbar>
        </AppBar>
        <Wrapper
          component={"form"}
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            //stopping if not media file is added if it is required
            if (Object.keys(headerVariableField).length !== 0) {
              if (selectedFile !== null) {
                handleSubmit();
                setMediaMissingWarning(false);
              } else {
                setMediaMissingWarning(true);
              }
            } else {
              handleSubmit();
              setMediaMissingWarning(false);
            }
          }}
        >
          <PreviewWrapper>
            <WhatsappSendTemplateMessagePreview
              data={props.data.components}
              variableDetails={variableDetails}
              headerVariableField={headerVariableField}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              handlePhotoAdd={handlePhotoAdd}
            />
          </PreviewWrapper>
          <FieldsWrapper>
            {variableDetails.map(
              (field) =>
                field.is_visible && (
                  <InputWrapper key={field.id}>
                    <Label>{field.name}</Label>
                    <FormControl sx={{ width: "100%" }}>
                      <OutlinedInput
                        name={`body_text_${field.id}`}
                        onChange={(e) => {
                          const { value } = e.target;
                          let newDetails = [...variableDetails];

                          const leadPattern = /\{\{([^{}]+)\}\}/g;
                          const foundMatches = [
                            ...value.matchAll(leadPattern),
                          ].map((match) => match[1]);

                          let newValue;
                          if (foundMatches.length > 0) {
                            newValue = getLeadVariableForPlaceholder({
                              leadVariableName: foundMatches[0],
                              leadData: props.leadData,
                            });
                          } else {
                            newValue = value;
                          }

                          if (newDetails[field.id - 1]["value"] !== newValue) {
                            newDetails[field.id - 1]["value"] = newValue;
                            setVariableDetails([...newDetails]);
                          } else {
                            setVariableDetails((prev) => [...prev]);
                          }
                        }}
                        value={variableDetails[field.id - 1]["value"] || ""}
                        style={{
                          height: "40px",
                          width: "100%",
                        }}
                        inputProps={{
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                      />
                    </FormControl>
                  </InputWrapper>
                )
            )}
          </FieldsWrapper>
          <AppBar
            position="static"
            color="inherit"
            elevation={0}
            sx={{ bottom: 0, width: "100%", height: 60 }}
          >
            <Toolbar
              style={{
                padding: "0px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {mediaMissingWarning && (
                <ErrorAlert
                  onClose={(e) => {
                    e.stopPropagation();
                    setMediaMissingWarning(false);
                  }}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {getLocalizedText("please_add_media_before_sending")}
                </ErrorAlert>
              )}
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    type="button"
                    variant="contained"
                    color="inherit"
                    style={{
                      textTransform: "none",

                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",

                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Send
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Wrapper>
      </ModelContainer>
    </Modal>
  );
}

export default TemplateMessageSendPopupComponent;
