import {
  getOzonetelCallStatus,
  ozonetelCall,
  ozonetelSetCallDisposition,
} from "../Api";
import CallService from "./callService";

class OzonetelCallService extends CallService {
  makeCall = async (leadID) => {
    try {
      let response = await ozonetelCall(leadID);
      return response.data;
    } catch (error) {
      let responseData = error.response.data;
      switch (responseData["dailer_resp"]["message"]) {
        case "DND":
          return {
            message:
              "The customer's number has been designated for DND status.",
          };
        default:
          return {
            message: "Something went wrong! Please contact your administrator.",
          };
      }
    }
  };
  fetchCallStatus = async (formData) => {
    try {
      let response = await getOzonetelCallStatus(formData);
      return response.data;
    } catch (error) {
      return {};
    }
  };
  setCallDisposition = async (leadID, formData) => {
    let response = await ozonetelSetCallDisposition(leadID, formData);
    return response;
  };
}

export default OzonetelCallService;
