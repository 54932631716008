import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { LoadingButton } from "@mui/lab";
import { pageSize } from "../../Config";
import rootStore from "../../stores/RootStore";
import { addLeadProductsApi, getFilteredProductsApi } from "../../Api";
import {
  getAccountLocalizedText,
  getIsoString,
  getLocalizedText,
} from "../../Functions";
import ProductsListingSection from "./ProductsListingSection";
import PaginationWithoutPageSize from "../pagination/PaginationWithoutPageSize";
import ProductsFilterSection from "./ProductsFilterSection";
import ViewProductSidepanel from "../view_product_sidepanel/ViewProductSidepanel";
import { Drawer } from "@material-ui/core";
const drawerWidth = "50vw";
const filterSectionWidth = "250px";
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  position: relative;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  width: 100%;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding: 16px;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #979797;
  margin-bottom: 16px;
`;
const AddLeadProductsComponent = ({
  setOpen,
  existingProducts,
  leadID,
  setRefresh,
}) => {
  const [loading, setLoading] = useState(true);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const productColumnsList = [...rootStore.productStore.columnsList];

  const [productsList, setProductsList] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [appliedFilterList, setAppliedFilterList] = useState([]);
  const [selectedProductData, setSelectedProductData] = useState({});
  const [openViewProductPanel, setOpenViewProductPanel] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);
    let payload = {
      product_ids: selectedProducts.map((entry) => entry["id"]),
    };
    let response = await addLeadProductsApi({
      leadID: leadID,
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
    setIsSubmitting(false);
  };

  const init = async () => {
    await getProductsList({ page: 0, filterList: appliedFilterList });
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const generateFilterPayload = (filterList) => {
    let normalFields = {};
    filterList.forEach((item) => {
      let filterData = item["filter"];
      let value = null;
      Object.keys(filterData).map((key) => {
        if (
          filterData[key]["filter_type"] === "normal" ||
          filterData[key]["filter_type"] === ""
        ) {
          if (
            filterData[key]["data_type"] === "date" ||
            filterData[key]["data_type"] === "datetime"
          ) {
            value = getIsoString(filterData[key]["value"]);
          } else {
            value = filterData[key]["value"];
          }
        } else if (filterData[key]["filter_type"] === "range") {
          if (
            filterData[key]["data_type"] === "date" ||
            filterData[key]["data_type"] === "datetime"
          ) {
            let d1 = filterData[key]["value"][0].toISOString();
            let d2 = filterData[key]["value"][1].toISOString();
            value = {
              start: d1,
              end: d2,
            };
          } else if (filterData[key]["data_type"] === "integer") {
            let num1 = filterData[key]["value"]["start"];
            let num2 = filterData[key]["value"]["end"];
            value = {
              start: num1,
              end: num2,
            };
          }
        } else if (filterData[key]["filter_type"] === "list") {
          let tempList = [];
          filterData[key]["value"].forEach((obj) => {
            tempList.push(obj["value"]);
          });
          value = tempList;
        }
      });
      const fieldName = Object.keys(filterData)[0];

      let index = productColumnsList.findIndex(
        (column) => column["column_name"] === fieldName
      );
      if (index !== -1) {
        normalFields[fieldName] = {
          value: value,
          op: item["operator"]["value"],
        };
      }
    });
    return normalFields;
  };

  const getProductsList = async ({ page, filterList }) => {
    setLoadingProducts(true);
    const filterPayload = generateFilterPayload(filterList);
    let response = await getFilteredProductsApi({
      page: page,
      page_size: pageSize,
      payload: { query: filterPayload },
    });
    if (Object.keys(response).length > 0) {
      setProductsList(response.items);
      setCount(response.item_count);
    } else {
      setProductsList([]);
      setCount(0);
    }
    setLoadingProducts(false);
  };
  const handlePagination = async (e, page) => {
    setPage(page - 1);

    await getProductsList({ page: page - 1, filterList: appliedFilterList });
  };

  const getPaginationCounterText = () => {
    let total = count !== 0 ? count : 0;
    let itemsPerPage = 25;
    let min = total !== 0 ? page * itemsPerPage + 1 : 0;
    let max =
      (page + 1) * itemsPerPage > total ? total : (page + 1) * itemsPerPage;
    return `${min}-${max} of ${total}`;
  };
  const handleSearch = async (filterList) => {
    setAppliedFilterList(filterList);
    setSelectedProducts([]);
    setPage(0);
    await getProductsList({ page: 0, filterList: filterList });
  };

  const handleReset = async () => {
    setAppliedFilterList([]);
    setSelectedProducts([]);
    setPage(0);
    await getProductsList({ page: 0, filterList: [] });
  };

  const handleProductSelection = (product) => {
    let tempSelectedProducts = [...selectedProducts];
    let index = tempSelectedProducts.findIndex(
      (entry) => entry["id"] === product["id"]
    );
    if (index !== -1) {
      tempSelectedProducts = tempSelectedProducts.filter(
        (entry) => entry["id"] !== product["id"]
      );
    } else {
      tempSelectedProducts.push(product);
    }
    setSelectedProducts(tempSelectedProducts);
  };

  const isProductSelected = (product) => {
    let tempSelectedProducts = [...selectedProducts];
    let index = tempSelectedProducts.findIndex(
      (entry) => entry["id"] === product["id"]
    );
    return index !== -1;
  };

  const isProductDisabled = (product) => {
    let tempExistingProducts = [...existingProducts];
    let index = tempExistingProducts.findIndex(
      (entry) => entry["id"] === product["id"]
    );
    return index !== -1;
  };

  return !loading ? (
    <Box role="presentation">
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: drawerWidth,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar style={{ padding: "16px" }}>
          <ModelHeader>{getAccountLocalizedText("add_products")}</ModelHeader>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        {!isSubmitSuccess ? (
          <>
            <Box
              sx={{
                position: "sticky",
                width: filterSectionWidth,
                top: "64px",
                left: "0px",
                height: "calc(100vh - 64px)",
                borderRight: "1px solid #e0e0e0",
              }}
            >
              <ProductsFilterSection
                containerWidth={filterSectionWidth}
                appliedFilterList={appliedFilterList}
                handleReset={handleReset}
                postSaveReportFilter={(filterList) => handleSearch(filterList)}
              />
            </Box>
            <ModelWrapper
              component={"form"}
              onSubmit={handleSubmit}
              sx={{
                width: `calc(${drawerWidth} - ${filterSectionWidth})`,
              }}
            >
              <Wrapper>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <SectionHeader>
                    {getAccountLocalizedText("products")}
                    {selectedProducts.length > 0 && (
                      <span style={{ marginLeft: "8px" }}>{`(${
                        selectedProducts.length
                      } ${getLocalizedText("selected")})`}</span>
                    )}
                  </SectionHeader>
                  <Box sx={{ width: "100%" }}>
                    <ProductsListingSection
                      count={count}
                      getPaginationCounterText={getPaginationCounterText}
                      handlePagination={handlePagination}
                      isProductSelected={(product) =>
                        isProductSelected(product)
                      }
                      page={page}
                      productList={productsList}
                      isLoading={loadingProducts}
                      handleProductSelection={(product) =>
                        handleProductSelection(product)
                      }
                      isProductDisabled={(product) =>
                        isProductDisabled(product)
                      }
                      handleProductView={(product) => {
                        setSelectedProductData(product);
                        setOpenViewProductPanel(true);
                      }}
                    />
                    <PaginationWithoutPageSize
                      page_no={page}
                      row={productsList}
                      count={count}
                      handlePage={handlePagination}
                      counterText={getPaginationCounterText()}
                      page_size={25}
                    />
                  </Box>
                </Box>
              </Wrapper>

              <AppBar
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                  top: "auto",
                  bottom: 0,
                  width: `calc(${drawerWidth} - ${filterSectionWidth})`,
                }}
              >
                <Toolbar
                  style={{
                    width: `calc(${drawerWidth} - ${filterSectionWidth})`,
                    position: "relative",
                    padding: "16px",
                  }}
                >
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <ButtonWrapper>
                    <Button
                      onClick={handleClose}
                      type="button"
                      variant="contained"
                      color="inherit"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                    {isSubmitting ? (
                      <LoadingButton
                        loading
                        variant="contained"
                        color="primary"
                        style={{
                          textTransform: "none",
                          width: "100%",
                        }}
                      >
                        <span>{getLocalizedText("submit")}</span>
                      </LoadingButton>
                    ) : (
                      <Button
                        disabled={selectedProducts.length === 0}
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{
                          textTransform: "none",
                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "100%",
                        }}
                      >
                        {getLocalizedText("submit")}
                      </Button>
                    )}
                  </ButtonWrapper>
                </Toolbar>
              </AppBar>
              {openViewProductPanel && (
                <Drawer
                  disableEnforceFocus
                  anchor={"right"}
                  open={openViewProductPanel}
                  onClose={() => {
                    setSelectedProductData({});
                    setOpenViewProductPanel(false);
                  }}
                >
                  <ViewProductSidepanel
                    productID={
                      selectedProductData.hasOwnProperty("id")
                        ? selectedProductData["id"]
                        : ""
                    }
                    setOpen={setOpenViewProductPanel}
                    open={openViewProductPanel}
                  />
                </Drawer>
              )}
            </ModelWrapper>
          </>
        ) : (
          <MessageWrapper>
            <Message>
              {getAccountLocalizedText("products_added_successfully")}
            </Message>

            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="inherit"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              {getLocalizedText("close")}
            </Button>
          </MessageWrapper>
        )}
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: drawerWidth,
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AddLeadProductsComponent;
