import { AgGridReact } from "ag-grid-react";
import React, { useState, useEffect, useMemo } from "react";
import { styled } from "@mui/material/styles";
import { Drawer, CircularProgress } from "@material-ui/core";
import {
  leadQuoteListColumnHeaders,
  leadInvoiceListColumnHeaders,
} from "../../../Db";
import { Box, IconButton, Tooltip, Chip } from "@mui/material";
import { Preview, SaveAs } from "@mui/icons-material";
import { Description, Edit, Send } from "@material-ui/icons";
import ViewQuoteSidepanel from "../../add_quote_sidepanel/view_quote_sidepanel/ViewQuoteSidepanel";
import ViewInvoiceSidepanel from "../../invoice_sidepanel/ViewInvoiceSidepanel";
import MessageWithLoadingStateCustomDialog from "../../alert_dialogue/MessageWithLoadingStateCustomDialog";
import {
  getLeadQuoteListApi,
  getLeadInvoiceListApi,
  markLeadQuotationAsInvoicedApi,
  markLeadQuotationAsSentApi,
  reviseLeadQuotationApi,
  markLeadInvoiceAsSentApi,
} from "../../../Api";
import AddRevisionNoteDialog from "../../add_quote_sidepanel/dialogs/AddRevisionNoteDialog";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import RecordPaymentDialog from "../../invoice_sidepanel/record_payment_dialog/RecordPaymentDialog";
import rootStore from "../../../stores/RootStore";
import { isCustomQuotationTemplate } from "../../../Functions";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  ${`min-height: calc(100vh - 258px);`};
  margin-bottom: 20px;
`;
const Wrapper = styled(Box)`
  display: flex;
`;
const DetailsWrapper = styled(Box)`
  ${`min-height: calc(100vh - 282px);`};
  width: 100%;
  background: white;
  border-radius: 10px;
  display: flex;
`;
const ChipsContainer = styled(Box)`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  column-gap: 8px;
`;

const QuotesTab = ({
  leadID,
  canEditLead,
  setOpenAddQuotePanel,
  setOpenAddInvoicePanel,
  setOpenCreateQuoteWithContentDialog,
  setOpenCreateInvoiceWithContentDialog,
  setQuoteScope,
  setInvoiceScope,
  setRefreshQuoteList,
  refreshQuoteList,
  setRefreshInvoiceList,
  refreshInvoiceList,
  setSelectedQuoteData,
  selectedQuoteData,
}) => {
  const [loading, setLoading] = useState(true);
  const [openViewQuotePanel, setOpenViewQuotePanel] = useState(false);
  const [quoteList, setQuoteList] = useState([]);
  const [invoicesList, setInvoicesList] = useState([]);
  const [chipValue, setChipValue] = useState("quotes");

  const [fetchingApi, setFetchingApi] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [openRevisionNoteDialog, setOpenRevisionNoteDialog] = useState(false);
  const [openRecordPayment, setOpenRecordPayment] = useState(false);

  const init = async () => {
    setLoading(true);
    if (
      rootStore.subscriptionState.isFeatureAvailableWithAddOns("Quotations")
    ) {
      await fetchQuoteList();
      await fetchInvoiceList();
    }
    setLoading(false);
  };

  const fetchQuoteList = async () => {
    let response = await getLeadQuoteListApi({ leadID: leadID });
    setQuoteList(response);
  };

  const fetchInvoiceList = async () => {
    let response = await getLeadInvoiceListApi({ leadID: leadID });
    setInvoicesList(response);
  };

  const handleMarkAsSent = async (quoteID) => {
    setFetchingApi(true);
    setOpenSubmitResponseDialog(true);
    let response = await markLeadQuotationAsSentApi({
      leadID: leadID,
      quoteID: quoteID,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setIsSubmitFail(false);
      setRefreshQuoteList(true);
      setSuccessMessage("Quote sent successfully!");
    }
    setFetchingApi(false);
  };
  const handleMarkAsInvoiced = async (quoteID) => {
    setFetchingApi(true);
    setOpenSubmitResponseDialog(true);
    let response = await markLeadQuotationAsInvoicedApi({
      leadID: leadID,
      quoteID: quoteID,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setIsSubmitFail(false);
      setRefreshQuoteList(true);
      setSuccessMessage("Quote invoiced successfully!");
    }
    setFetchingApi(false);
  };

  const handleContinueRevision = async (notes) => {
    setFetchingApi(true);
    setOpenSubmitResponseDialog(true);
    let payload = {
      notes: notes,
    };
    let response = await reviseLeadQuotationApi({
      payload: payload,
      leadID: leadID,
      quoteID: selectedQuoteData["id"],
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
      setFetchingApi(false);
    } else {
      setIsSubmitFail(false);
      setFetchingApi(false);
      setOpenSubmitResponseDialog(false);
      setRefreshQuoteList(true);
      setSelectedQuoteData(JSON.parse(JSON.stringify(response.data))); //deep copy
      setQuoteScope("revise");
      if (isCustomQuotationTemplate(response.data["quote_schema"])) {
        setOpenCreateQuoteWithContentDialog(true);
      } else {
        setOpenAddQuotePanel(true);
      }
    }
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const refetchQuoteList = async () => {
    await fetchQuoteList();
    setRefreshQuoteList(false);
  };

  const refetchInvoiceList = async () => {
    await fetchInvoiceList();
    setRefreshInvoiceList(false);
  };

  useEffect(() => {
    if (refreshQuoteList) {
      refetchQuoteList();
    }
  }, [refreshQuoteList]);

  useEffect(() => {
    if (refreshInvoiceList) {
      refetchInvoiceList();
    }
  }, [refreshInvoiceList]);

  useEffect(() => {
    init();
    return () => {
      resetStateVariables();
    };
  }, []);

  const resetStateVariables = () => {
    setOpenAddQuotePanel(false);
    setOpenCreateQuoteWithContentDialog(false);
  };

  const isInvoiceEditDisabled = (invoiceID) => {
    const index = invoicesList.findIndex((item) => item["id"] === invoiceID);
    if (index !== -1) {
      return invoicesList[index].status !== "Draft";
    }
    return false;
  };

  const isRecordPaymentDisabled = (invoiceID) => {
    const index = invoicesList.findIndex((item) => item["id"] === invoiceID);
    if (index !== -1) {
      return invoicesList[index].status === "Paid";
    }
    return false;
  };

  const setOpenRecordPaymentCallback = (invoiceID) => {
    const index = invoicesList.findIndex((item) => item["id"] === invoiceID);
    if (index !== -1) {
      setSelectedQuoteData(invoicesList[index]);
    }
    setOpenRecordPayment(true);
  };

  const handleInvoiceMarkAsSent = async (invoiceID) => {
    setFetchingApi(true);
    setOpenSubmitResponseDialog(true);
    let response = await markLeadInvoiceAsSentApi({
      invoiceID: invoiceID,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setIsSubmitFail(false);
      setRefreshInvoiceList(true);
      setSuccessMessage("Invoice sent successfully!");
    }
    setFetchingApi(false);
  };

  const isDisabled = (actionType, status) => {
    if (canEditLead()) {
      switch (status) {
        case "Sent":
          return actionType === "markSent" || actionType === "edit";
        case "Invoiced":
          return (
            actionType === "markSent" ||
            actionType === "markInvoiced" ||
            actionType === "edit" ||
            actionType === "revise" ||
            actionType === "toInvoice"
          );
        case "Draft":
          return actionType === "revise";
        case "Void":
          return true;
        default:
          return false;
      }
    } else {
      return true;
    }
  };
  const quotesActionColumn = [
    {
      field: "actions",
      headerName: "ACTIONS",
      resizable: false,
      minWidth: 130,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              gap: "10px",
            }}
          >
            <Tooltip title={"Preview"}>
              <IconButton
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  window.open(
                    `/quote/preview?lead_id=${leadID}&quote_id=${params.data["id"]}&is_invoice=no`,
                    "_blank"
                  );
                }}
              >
                <Preview
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title={"Edit"}>
              <IconButton
                disabled={isDisabled("edit", params.data.status)}
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  setSelectedQuoteData(JSON.parse(JSON.stringify(params.data)));
                  setQuoteScope("edit");
                  if (isCustomQuotationTemplate(params.data["quote_schema"])) {
                    setOpenCreateQuoteWithContentDialog(true);
                  } else {
                    setOpenAddQuotePanel(true);
                  }
                }}
              >
                <Edit
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Mark as Sent"}>
              <IconButton
                disabled={isDisabled("markSent", params.data.status)}
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  handleMarkAsSent(params.data.id);
                }}
              >
                <Send
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title={"Convert to Invoice"}>
              <IconButton
                disabled={isDisabled("toInvoice", params.data.status)}
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  setSelectedQuoteData(JSON.parse(JSON.stringify(params.data))); //deep copy
                  setInvoiceScope("to_invoice");

                  if (isCustomQuotationTemplate(params.data["quote_schema"])) {
                    setOpenCreateInvoiceWithContentDialog(true);
                  } else {
                    setOpenAddInvoicePanel(true);
                  }
                }}
              >
                <Description
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Revise"}>
              <IconButton
                disabled={isDisabled("revise", params.data.status)}
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSelectedQuoteData(JSON.parse(JSON.stringify(params.data))); //deep copy
                  setOpenRevisionNoteDialog(true);
                }}
              >
                <SaveAs
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const invoicesActionColumn = [
    {
      field: "actions",
      headerName: "ACTIONS",
      resizable: false,
      minWidth: 130,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              gap: "10px",
            }}
          >
            <Tooltip title={"Preview"}>
              <IconButton
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  window.open(
                    `/quote/preview?lead_id=${leadID}&invoice_id=${params.data["id"]}&is_invoice=yes`,
                    "_blank"
                  );
                }}
              >
                <Preview
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Edit"}>
              <IconButton
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  setSelectedQuoteData(JSON.parse(JSON.stringify(params.data))); //deep copy
                  if (params.data.status.toLowerCase() === "draft") {
                    setInvoiceScope("edit");
                  } else {
                    setInvoiceScope("edit_non_draft");
                  }

                  if (isCustomQuotationTemplate(params.data["schema"])) {
                    setOpenCreateInvoiceWithContentDialog(true);
                  } else {
                    setOpenAddInvoicePanel(true);
                  }
                }}
              >
                <Edit
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Mark as Sent"}>
              <IconButton
                disabled={params.data.status !== "Draft"}
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  handleInvoiceMarkAsSent(params.data.id);
                }}
              >
                <Send
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Record Payment"}>
              <IconButton
                disabled={isRecordPaymentDisabled(params.data.id)}
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  setSelectedQuoteData(params.data);
                  setOpenRecordPayment(true);
                }}
              >
                <DownloadForOfflineOutlinedIcon
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const chipData = [
    { label: "Quotes", value: "quotes" },
    { label: "Invoices", value: "invoices" },
  ];

  return (
    <Container>
      <Wrapper>
        {!loading ? (
          <>
            <Box
              sx={{ display: "flex", width: "100%", flexDirection: "column" }}
            >
              <ChipsContainer>
                {chipData.map((data) => {
                  return (
                    <Chip
                      clickable
                      color={data.value === chipValue ? "primary" : "default"}
                      label={data.label}
                      onClick={() => {
                        setChipValue(data.value);
                      }}
                    />
                  );
                })}
              </ChipsContainer>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                {chipValue === "quotes" ? (
                  // Quotes listing
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    rowData={quoteList}
                    defaultColDef={defaultColDef}
                    columnDefs={leadQuoteListColumnHeaders.concat(
                      quotesActionColumn
                    )}
                    animateRows={true}
                    suppressCellFocus
                    onRowClicked={(row) => {
                      if (row.event.defaultPrevented) {
                        return null;
                      }
                      if (
                        !isCustomQuotationTemplate(row.data["quote_schema"])
                      ) {
                        setSelectedQuoteData(row.data);
                        setOpenViewQuotePanel(true);
                      }
                    }}
                  />
                ) : (
                  // Invoices listing
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    rowData={invoicesList}
                    defaultColDef={defaultColDef}
                    columnDefs={leadInvoiceListColumnHeaders.concat(
                      invoicesActionColumn
                    )}
                    animateRows={true}
                    suppressCellFocus
                    onRowClicked={(row) => {
                      if (row.event.defaultPrevented) {
                        return null;
                      }
                      if (!isCustomQuotationTemplate(row.data["schema"])) {
                        setSelectedQuoteData(row.data);
                        setOpenViewQuotePanel(true);
                      }
                    }}
                  />
                )}
              </div>
            </Box>
            {openViewQuotePanel && (
              <Drawer
                disableEnforceFocus
                anchor={"right"}
                open={openViewQuotePanel}
                onClose={() => {
                  setSelectedQuoteData({});
                  setOpenViewQuotePanel(false);
                }}
              >
                {chipValue === "quotes" ? (
                  <ViewQuoteSidepanel
                    scope={"lead"}
                    details={selectedQuoteData}
                    checkIfDisabled={isDisabled}
                    onClickCallback={(data) => {
                      setOpenViewQuotePanel(false);
                      setSelectedQuoteData(data);
                      setQuoteScope("edit");
                      setOpenAddQuotePanel(true);
                    }}
                  />
                ) : (
                  <ViewInvoiceSidepanel
                    scope={"lead"}
                    details={selectedQuoteData}
                    onClickCallback={(data) => {
                      setOpenViewQuotePanel(false);
                      setSelectedQuoteData(data);
                      if (data.status.toLowerCase() === "draft") {
                        setInvoiceScope("edit");
                      } else {
                        setInvoiceScope("edit_non_draft");
                      }
                      setOpenAddInvoicePanel(true);
                    }}
                    openRecordPayment={openRecordPayment}
                    setOpenRecordPayment={setOpenRecordPaymentCallback}
                    isRecordPaymentDisabled={isRecordPaymentDisabled}
                  />
                )}
              </Drawer>
            )}
            {openSubmitResponseDialog && (
              <MessageWithLoadingStateCustomDialog
                open={openSubmitResponseDialog}
                setOpen={setOpenSubmitResponseDialog}
                successMessage={successMessage}
                errorMessage={errorMessage}
                loading={fetchingApi}
                isError={isSubmitFail}
              />
            )}
            {openRevisionNoteDialog && (
              <AddRevisionNoteDialog
                open={openRevisionNoteDialog}
                setOpen={setOpenRevisionNoteDialog}
                handleContinueButton={(notes) => {
                  handleContinueRevision(notes);
                  setOpenRevisionNoteDialog(false);
                }}
              />
            )}
            {openRecordPayment && (
              <RecordPaymentDialog
                details={selectedQuoteData}
                open={openRecordPayment}
                setOpen={setOpenRecordPayment}
                invoiceID={selectedQuoteData["id"]}
                setRefresh={setRefreshInvoiceList}
              />
            )}
          </>
        ) : (
          <DetailsWrapper
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <CircularProgress />
          </DetailsWrapper>
        )}
      </Wrapper>
    </Container>
  );
};

export default QuotesTab;
