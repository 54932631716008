import {
  AppBar,
  Box,
  Divider,
  Grid,
  Modal,
  Toolbar,
  OutlinedInput,
  Typography,
  FormControl,
  Select as MuiSelect,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { Button, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Select from "react-select";
import { leadProductStatusList } from "../../../Db";
import MuiAlert from "@mui/material/Alert";
import { updateLeadProductStatusApi } from "../../../Api";
import {
  getAccountLocalizedText,
  getAvailableStatusListByStage,
  getLocalizedText,
  isAfterCurrentTime,
} from "../../../Functions";
import rootStore from "../../../stores/RootStore";
import UpdateFieldsWidget from "../../status_param_column_rendere/UpdateFieldsWidget";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const dialogWidth = 420;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const CheckLabel = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #979797;
`;
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for options
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for the entire control container, including the search input
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "no options" message
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "loading" message
  }),
};
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const UpdateProductStatusDialog = ({
  open,
  setOpen,
  leadData,
  leadProductID,
  refreshLead,
  editableData,
}) => {
  const classes = useStyles();
  const [status, setStatus] = useState(null);
  const [selectedStage, setSelectedStage] = useState(null);
  const [comments, setComments] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [activeFlag, setActiveFlag] = useState(false);

  const columns = [...rootStore.userStore.AllColumnsList];
  const stageStatusList = [...rootStore.authStore.stageStatusList];
  const stagesList = [...rootStore.authStore.stageList];
  const statusList = [...rootStore.authStore.statusList];
  const [availableStatuses, setAvailableStatuses] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);
  const [submitLogErrorMessage, setSubmitLogErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const [statusData, setStatusData] = useState({
    status: "",
    sub_status: "",
  });
  const [updateFieldList, setUpdateFieldList] = useState([]);
  const [updateFieldInputData, setUpdateFieldInputData] = useState({});
  const [nextFollowupRelativeDate, setNextFollowupRelativeDate] = useState("");
  const [prevStage, setPrevStage] = useState("");

  const isAllMandatoryFieldsFilled = () => {
    let filled_fields = { ...updateFieldInputData };
    for (let i = 0; i < updateFieldList.length; i++) {
      let item = updateFieldList[i];
      if (item["required"] === true) {
        if (filled_fields.hasOwnProperty(item["field"])) {
          if (
            filled_fields[item["field"]]["value"] === "" ||
            filled_fields[item["field"]]["value"] === null
          ) {
            setSubmitLogErrorMessage(
              `Please fill "${item["headerName"]}" field`
            );
            return false;
          }
        } else {
          setSubmitLogErrorMessage(`Please fill "${item["headerName"]}" field`);
          return false;
        }
      }
    }

    return true;
  };

  const isValidNextFollowupDatetime = () => {
    let tempDatetime = updateFieldInputData.hasOwnProperty("next_follow_up_on")
      ? updateFieldInputData["next_follow_up_on"]["value"]
      : null;
    if (tempDatetime !== null) {
      return isAfterCurrentTime(tempDatetime.toISOString());
    }
    return true;
  };

  const generateUpdateFieldsPayloadData = (data) => {
    let normalList = {};
    let customList = {};
    for (const key in data) {
      let value = data[key]["value"];
      let index = columns.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        if (value !== "" && value !== null) {
          if (
            data[key]["type"] === "datetime" ||
            data[key]["type"] === "date"
          ) {
            value = value.toISOString();
          } else if (data[key]["type"] === "integer") {
            value = parseInt(value);
          }
          if (columns[index]["is_custom_param"]) {
            let newKey = key.replace("custom_params_", "");
            customList[newKey] = value;
          } else {
            normalList[key] = value;
          }
        }
      }
    }
    if (Object.keys(customList).length > 0) {
      normalList["custom_params"] = customList;
    }

    return normalList;
  };

  const hasValidDateAndDateTime = (updateFieldInputData) => {
    for (const key in updateFieldInputData) {
      if (
        updateFieldInputData[key]["type"] === "datetime" ||
        updateFieldInputData[key]["type"] === "date"
      ) {
        let value = updateFieldInputData[key]["value"];
        if (value !== null) {
          try {
            if (!value.isValid()) {
              const index = columns.findIndex(
                (column) => column["field"] === key
              );

              if (index !== -1) {
                setSubmitLogErrorMessage(
                  `Invalid ${columns[index]["headerName"]}`
                );
                return false;
              }
            }
          } catch (error) {
            setSubmitLogErrorMessage("Some error occurred!\nError code:1020");
            return false;
          }
        }
      }
    }
    return true;
  };

  const init = async () => {
    setActiveFlag(
      editableData.hasOwnProperty("active") ? !editableData.active : false
    );
    //prefill product status
    const presentValue =
      editableData.hasOwnProperty("product_status") &&
      editableData.product_status !== null
        ? editableData.product_status
        : "";
    let index = leadProductStatusList.findIndex(
      (item) => item.value === presentValue
    );
    if (index !== -1) {
      setStatus(leadProductStatusList[index]);
    }

    setComments(
      editableData.hasOwnProperty("comments") && editableData.comments !== null
        ? editableData.comments
        : ""
    );
    const leadStage =
      leadData.hasOwnProperty("lead_stage") && leadData["lead_stage"] !== null
        ? leadData["lead_stage"]
        : "";
    setSelectedStage(leadStage);
    setPrevStage(leadStage);
    initUpdateFieldList(stagesList, leadStage);

    //old code - with lead status and substatus fields for update fields
    // let tempStatusList = getAvailableStatusListByStage({
    //   currentStage: leadStage,
    //   stageList: stagesList,
    //   statusList: statusList,
    // });
    // setAvailableStatuses(tempStatusList);
  };
  useEffect(() => {
    init();
  }, []);

  const getUpdateFieldsInStage = (stageList, stageName) => {
    let tempList = [];

    let index = stageList.findIndex((item) => item["stage"] === stageName);
    if (index !== -1) {
      if (
        stageList[index].hasOwnProperty("update_fields") &&
        stageList[index]["update_fields"] !== null
      ) {
        let updateFieldsData = stageList[index]["update_fields"];
        updateFieldsData.forEach((data) => {
          let index = columns.findIndex(
            (column) => column["id"] === data["column_meta"]["id"]
          );
          if (index !== -1) {
            let columnData = { ...columns[index] };
            columnData["required"] = data["required"];
            columnData["_overwrite"] = data["overwrite"];
            columnData["_append"] = data.hasOwnProperty("append")
              ? data["append"]
              : false;
            tempList.push(columnData);
          }
        });
      }
    }

    return tempList;
  };

  const getUpdateFieldsInStatus = (statusName) => {
    let tempList = [];
    let index = statusList.findIndex(
      (status) => status["status"] === statusName
    );
    if (index !== -1) {
      if (
        statusList[index].hasOwnProperty("update_fields") &&
        statusList[index]["update_fields"] !== null
      ) {
        let updateFieldsData = statusList[index]["update_fields"];
        updateFieldsData.forEach((data) => {
          let index = columns.findIndex(
            (column) => column["id"] === data["column_meta"]["id"]
          );
          if (index !== -1) {
            let columnData = { ...columns[index] };
            columnData["required"] = data["required"];
            columnData["_overwrite"] = data["overwrite"];
            columnData["_append"] = data.hasOwnProperty("append")
              ? data["append"]
              : false;
            tempList.push(columnData);
          }
        });
      }
    }
    return tempList;
  };

  const getUpdateFieldsInStageStatus = (statusName) => {
    let tempList = [];
    let index = stageStatusList.findIndex(
      (item) =>
        item["lead_status"]["status"] === statusName &&
        item["lead_stage"]["stage"] === selectedStage
    );
    if (index !== -1) {
      if (
        stageStatusList[index].hasOwnProperty("update_fields") &&
        stageStatusList[index]["update_fields"] !== null
      ) {
        let updateFieldsData = stageStatusList[index]["update_fields"];
        updateFieldsData.forEach((data) => {
          let index = columns.findIndex(
            (column) => column["id"] === data["column_meta"]["id"]
          );
          if (index !== -1) {
            let columnData = { ...columns[index] };
            columnData["required"] = data["required"];
            columnData["_overwrite"] = data["overwrite"];
            columnData["_append"] = data.hasOwnProperty("append")
              ? data["append"]
              : false;
            tempList.push(columnData);
          }
        });
      }
    }
    return tempList;
  };

  const initUpdateFieldList = (stageList, stageName) => {
    let updateFields = getUpdateFieldsInStage(stageList, stageName);
    if (updateFields.length > 0) {
      setUpdateFieldList(updateFields);
    }
  };

  //old code - with lead status and substatus fields for update fields

  // const initUpdateFieldListForStageStatus = (statusName) => {
  //   let updateFieldsInStageSTatus = getUpdateFieldsInStageStatus(statusName);
  //   if (updateFieldsInStageSTatus.length > 0) {
  //     setUpdateFieldList(updateFieldsInStageSTatus);
  //   } else {
  //     let updateFieldsInStatus = getUpdateFieldsInStatus(statusName);
  //     setUpdateFieldList(updateFieldsInStatus);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let payload = {
      product_status: status["value"],
      comments: comments,
      active: activeFlag,
    };
    if (selectedStage !== prevStage) {
      if (selectedStage !== null && selectedStage !== "") {
        let stageIndex = stagesList.findIndex(
          (item) => item.stage === selectedStage
        );
        if (stageIndex !== -1) {
          payload["lead_stage_id"] = stagesList[stageIndex]["id"];
        }
      }
    }
    // Error handling for invalid datetime field inputs
    if (hasValidDateAndDateTime(updateFieldInputData)) {
      let updateFieldsPayload =
        generateUpdateFieldsPayloadData(updateFieldInputData);
      if (selectedStage !== prevStage) {
        payload["update_fields"] = updateFieldsPayload;
        payload["updated_fields"] = updateFieldsPayload;

        if (updateFieldsPayload.hasOwnProperty("next_follow_up_on")) {
          payload["next_followup_on"] =
            updateFieldsPayload["next_follow_up_on"];
        }
      }
    }

    if (isAllMandatoryFieldsFilled()) {
      if (isValidNextFollowupDatetime()) {
        let response = await updateLeadProductStatusApi({
          leadID: leadData["id"],
          leadProductID: leadProductID,
          payload: payload, // Using "payload" instead of FormData
        });

        if (response.hasError()) {
          setSubmitLogErrorMessage(response.errorMessage);
          setIsError(true);
        } else {
          setIsError(false);
          setIsSubmitSuccess(true);
          refreshLead();
        }
      } else {
        setSubmitLogErrorMessage("Invalid Next Followup On Time");
        setIsError(true);
      }
    } else {
      setIsError(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      {!isSubmitSuccess ? (
        <ModelContainer>
          <Box component={"form"} onSubmit={handleSubmit}>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>
                    {activeFlag
                      ? getAccountLocalizedText("activate_product")
                      : getAccountLocalizedText("deactivate_product")}
                  </Header>
                  <SubHeader>Fill in the below details</SubHeader>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              <Box
                style={{
                  height: "285px",
                  overflowY: "auto",
                  overflowX: "hidden",
                  padding: "0px 16px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>
                        {getAccountLocalizedText("select_product_status")}*
                      </Label>
                      <Select
                        menuPosition="fixed"
                        options={leadProductStatusList}
                        value={status}
                        onChange={(selectedOption) => {
                          setStatus(selectedOption);
                        }}
                        closeMenuOnSelect={true}
                        styles={customStyles}
                        isClearable
                      />
                    </InputWrapper>
                  </Grid>

                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Comments*</Label>
                      <OutlinedInput
                        multiline
                        required
                        rows={3}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        name="number_of_rows"
                        onChange={(e) => {
                          setComments(e.target.value);
                        }}
                        value={comments}
                        type={"text"}
                        placeholder={"Enter Value"}
                      />
                    </InputWrapper>
                  </Grid>

                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <legend
                          style={{
                            width: "auto",
                            marginBottom: "0px",
                            fontSize: "12px",
                          }}
                        >
                          <SectionHeader>Stage Details</SectionHeader>
                        </legend>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <InputWrapper>
                              <Label>{getLocalizedText("lead_stage")}</Label>
                              <FormControl
                                sx={{ width: "100%" }}
                                classes={{
                                  root: classes.quantityRoot,
                                }}
                              >
                                <MuiSelect
                                  classes={{
                                    icon: classes.icon,
                                  }}
                                  name="stage"
                                  displayEmpty
                                  disableUnderline
                                  variant="outlined"
                                  value={selectedStage}
                                  onChange={(e) => {
                                    setNextFollowupRelativeDate("");
                                    setUpdateFieldList([]);
                                    setUpdateFieldInputData({});
                                    setSelectedStage(e.target.value);

                                    initUpdateFieldList(
                                      stagesList,
                                      e.target.value
                                    );

                                    //old code - with lead status and substatus fields for update fields
                                    // setNextFollowupRelativeDate("");
                                    // setUpdateFieldList([]);
                                    // setUpdateFieldInputData({});
                                    // setStatusData({
                                    //   ...statusData,
                                    //   status: "",
                                    //   sub_status: "",
                                    // });
                                    // let index = stagesList.findIndex(
                                    //   (entry) =>
                                    //     entry["stage"] === e.target.value
                                    // );
                                    // if (index !== -1) {
                                    //   let stageData = stagesList[index];
                                    //   let tempStatusList =
                                    //     getAvailableStatusListByStage({
                                    //       currentStage: stageData["stage"],
                                    //       stageList: stagesList,
                                    //       statusList: statusList,
                                    //     });
                                    //   setAvailableStatuses(tempStatusList);
                                    //   setSubStatusList([]);
                                    //   setSelectedStage(stageData["stage"]);
                                    // } else {
                                    //   setAvailableStatuses([]);
                                    //   setSubStatusList([]);
                                    //   setSelectedStage("");
                                    // }
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "30px",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    borderRadius: "4px",
                                  }}
                                >
                                  {stagesList.map((entry) => {
                                    return (
                                      <MenuItem
                                        id={entry.id}
                                        value={entry.stage}
                                      >
                                        {entry.stage}
                                      </MenuItem>
                                    );
                                  })}
                                </MuiSelect>
                              </FormControl>
                            </InputWrapper>
                          </Grid>

                          {/* <Grid item xs={6}>
                            <InputWrapper>
                              <Label>{getLocalizedText("lead_status")}</Label>
                              <FormControl
                                sx={{ width: "100%" }}
                                classes={{
                                  root: classes.quantityRoot,
                                }}
                              >
                                <MuiSelect
                                  classes={{
                                    icon: classes.icon,
                                  }}
                                  name="status"
                                  displayEmpty
                                  disableUnderline
                                  variant="outlined"
                                  value={statusData.status}
                                  onChange={(e) => {
                                    setNextFollowupRelativeDate("");
                                    setUpdateFieldList([]);
                                    setUpdateFieldInputData({});
                                    availableStatuses.map((item) => {
                                      if (e.target.value === item.status) {
                                        let newList = item.sub_status;
                                        setSubStatusList(newList);
                                        initUpdateFieldList(
                                          stagesList,
                                          e.target.value
                                        );

                                        setSubmitLogErrorMessage("");
                                        if (newList.length > 0) {
                                          if (newList.includes("None")) {
                                            setStatusData({
                                              ...statusData,
                                              status: e.target.value,
                                              sub_status: "None",
                                            });
                                          } else {
                                            setStatusData({
                                              ...statusData,
                                              status: e.target.value,
                                              sub_status: newList[0],
                                            });
                                          }
                                        }
                                      }
                                    });
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "30px",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    borderRadius: "4px",
                                  }}
                                >
                                  {availableStatuses.map((e) => {
                                    if (e["is_not_set"] === false) {
                                      return (
                                        <MenuItem id={e.id} value={e.status}>
                                          {e.status}
                                        </MenuItem>
                                      );
                                    }
                                  })}
                                </MuiSelect>
                              </FormControl>
                            </InputWrapper>
                          </Grid>

                          <Grid item xs={6}>
                            <InputWrapper>
                              <Label>
                                {getLocalizedText("lead_sub_status")}
                              </Label>
                              <FormControl
                                sx={{ width: "100%" }}
                                classes={{
                                  root: classes.quantityRoot,
                                }}
                              >
                                <MuiSelect
                                  classes={{
                                    icon: classes.icon,
                                  }}
                                  name="sub_status"
                                  displayEmpty
                                  variant="outlined"
                                  value={statusData.sub_status}
                                  onChange={(e) => {
                                    setStatusData({
                                      ...statusData,
                                      sub_status: e.target.value,
                                    });
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "30px",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    borderRadius: "4px",
                                  }}
                                >
                                  {subStatusList.length > 0 &&
                                    subStatusList.map((e) => {
                                      return (
                                        <MenuItem id={e} value={e}>
                                          {e}
                                        </MenuItem>
                                      );
                                    })}
                                </MuiSelect>
                              </FormControl>
                            </InputWrapper>
                          </Grid> */}
                          {updateFieldList.length > 0 && (
                            <UpdateFieldsWidget
                              value={updateFieldInputData}
                              setValue={setUpdateFieldInputData}
                              fieldList={updateFieldList}
                              nextFollowupRelativeDate={
                                nextFollowupRelativeDate
                              }
                              setNextFollowupRelativeDate={
                                setNextFollowupRelativeDate
                              }
                              leadData={leadData}
                            />
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </Box>
            </Container>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: "100%" }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                {isError && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsError(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {submitLogErrorMessage}
                  </ErrorAlert>
                )}
                {/* {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )} */}
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="inherit"
                      style={{
                        fontSize: "12px",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={status === null}
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "fit-content",
                        textTransform: "none",
                      }}
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        </ModelContainer>
      ) : (
        <ModelContainer
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MessageWrapper style={{ marginBottom: "0px", padding: "16px" }}>
            <Message>
              {activeFlag
                ? getAccountLocalizedText("product_activated_successfully")
                : getAccountLocalizedText("product_deactivated_successfully")}
            </Message>

            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              {getLocalizedText("close")}
            </Button>
          </MessageWrapper>
        </ModelContainer>
      )}
    </Modal>
  );
};

export default UpdateProductStatusDialog;
