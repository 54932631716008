import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Drawer, Tooltip } from "@material-ui/core";
import { ChevronRight, Edit } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import { getProductLineListApi } from "../../Api";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { dataRenderer, getAccountLocalizedText, getLocalizedText } from "../../Functions";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";
import AgGridColumnWithFilterIcon from "../../components/column_header/AgGridColumnWithFilterIcon";
import { useNavigate } from "react-router-dom";
import AddProductLineSidePanel from "../../components/add_product_line_sidepanel/AddProductLineSidePanel";
import ViewProductLineSidepanel from "../../components/view_product_sidepanel/ViewProductLineSidepanel";
import ViewListModal from "../../components/add_product_line_sidepanel/components/ViewListModal";
import { MoreHoriz } from "@material-ui/icons";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 16px 0px;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 236px);`};
  background-color: white;
  padding: 20px;
  padding-bottom: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;
const TextHighlight = styled(Box)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;

const ProductLinePage = () => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openAddProductPanel, setOpenAddProductPanel] = useState(false);
  const [openViewProductPanel, setOpenViewProductPanel] = useState(false);
  const [selectedRowID, setSelectedRowID] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [openSelectColumnDialog, setOpenSelectColumnDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterQuery, setFilterQuery] = useState({});
  const [viewTags, setViewTags] = useState(false);
  const [editableData, setEditableData] = useState(null);

  const Open = Boolean(anchorEl);
  let navigate = useNavigate();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const generateQueryPayload = (queryList) => {
    let tempQueryPayload = {};
    for (const key in queryList) {
      let queryData = queryList[key];
      if (queryData["value"] !== null && queryData["value"] !== "") {
        tempQueryPayload[key] = queryData["value"];
      }
    }
    return tempQueryPayload;
  };

  const getProductsList = async ({ query }) => {
    setLoadingData(true);
    const queryPayload = generateQueryPayload(query);
    let response = await getProductLineListApi({
      page: rootStore.productStore.productLineListingInfo.page_number,
      page_size: rootStore.productStore.productLineListingInfo.page_size,
      payload: { query: queryPayload },
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      rootStore.productStore.updateProductLineCount(response.item_count);
    } else {
      setRow([]);
      rootStore.productStore.updateProductLineCount(0);
    }
    setLoadingData(false);
  };

  const init = async () => {
    setLoading(true);
    await getProductsList({ query: filterQuery });
    setLoading(false);
  };

  useEffect(() => {
    if (refresh === true) {
      getProductsList({ query: filterQuery });
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handlePageSize = async (e) => {
    rootStore.productStore.udpateProductLinePageNumber(0);
    rootStore.productStore.updateProductLinePageSize(e.target.value);
    await getProductsList({ query: filterQuery });
  };
  const handlePagination = async (e, page) => {
    rootStore.productStore.udpateProductLinePageNumber(page - 1);

    await getProductsList({ query: filterQuery });
  };

  const productLineColumnHeaders = [
    {
      field: "flid",
      headerName: "ID",

      width: 100,

      cellRenderer: (params) => {
        let value = dataRenderer(params.data.flid);
        return (
          <>
            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <Tooltip title={value}>
                <TextHighlight>{value}</TextHighlight>
              </Tooltip>
            </Box>
          </>
        );
      },
    },
    {
      width: 200,
      headerComponent: AgGridColumnWithFilterIcon,
      headerComponentParams: {
        headerText: "NAME",
        dataType: "string",
        handleSearch: (searchValue) => {
          handleSearch(searchValue, "name", "string");
        },
        queryData: filterQuery?.["name"]?.value || null,
      },
      cellRenderer: (params) => {
        let value = dataRenderer(params.data.name);
        return (
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "desc",
      headerName: "DESCRIPTION",

      width: 300,
      cellRenderer: (params) => {
        let value = dataRenderer(params.data.desc);
        return (
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        );
      },
    },
  ];

  const actionColumn = [
    {
      field: "tags",
      headerName: "TAGS",
      sortable: false,
      minWidth: 100,
      cellRenderer: (params) => {
        if (params.data.tags.length > 0) {
          return (
            <MoreHoriz
              style={{
                marginRight: "20px",
                width: "18px",
                height: "18px",
                cursor: "pointer",
              }}
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEditableData(params.data);
                setViewTags(true);
              }}
            />
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      flex: 1,
      minWidth: 100,
      resizable: false,
      cellRenderer: (params) => {
        return (
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsEdit(true);
              setSelectedRowID(params.data.id);
              setOpenAddProductPanel(true);
            }}
          >
            <Tooltip title={"Edit"}>
              <Edit
                style={{ width: "18px", height: "18px", cursor: "pointer" }}
                color="primary"
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleSearch = async (searchValue, fieldName, dataType) => {
    let tempFilterQuery = { ...filterQuery };
    tempFilterQuery[fieldName] = { value: searchValue, data_type: dataType };
    setFilterQuery(tempFilterQuery);
    rootStore.productStore.udpateProductLinePageNumber(0);
    await getProductsList({
      query: tempFilterQuery,
    });
  };

  return !loading ? (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Header
                style={{
                  color: "#185DD2",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/products");
                }}
              >
                {getAccountLocalizedText("products")}
              </Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Header>{getAccountLocalizedText("product_lines")}</Header>
            </TopWrapper>
            <ButtonWrapper>
              <Button
                onClick={() => {
                  setOpenAddProductPanel(true);
                }}
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "fit-content",
                  whiteSpace: "nowrap",
                }}
              >
                {getAccountLocalizedText("add_product_line")}
              </Button>
              {/* <IconButton
                style={{ padding: "0px" }}
                aria-label="more"
                id="long-button"
                aria-controls={Open ? "long-menu" : undefined}
                aria-expanded={Open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                <MoreVertIcon />
              </IconButton> */}
              {/* <Menu
                style={{ display: "flex", flexDirection: "column" }}
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={Open}
                onClose={handleMenuClose}
                PaperProps={{
                  style: {
                    width: "fit-content",
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    setOpenSelectColumnDialog(true);
                    handleMenuClose();
                  }}
                >
                  {getLocalizedText("select_columns")}
                </MenuItem>
              </Menu> */}
            </ButtonWrapper>
          </Row>
          {!loadingData ? (
            <TableWrapper>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={productLineColumnHeaders.concat(actionColumn)}
                  animateRows={true}
                  suppressCellFocus
                  pagination={false}
                  paginationPageSize={
                    rootStore.productStore.productLineListingInfo.page_size
                  }
                  className="paginated-ag-grid"
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    setSelectedRowID(row.data.id);
                    setOpenViewProductPanel(true);
                  }}
                />
              </div>
            </TableWrapper>
          ) : (
            <TableWrapper
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingAnimationComponent size={"small"} />
            </TableWrapper>
          )}
          <PaginationWrapper>
            <PaginationComponent
              page_no={
                rootStore.productStore.productLineListingInfo.page_number
              }
              row={row}
              page_size={
                rootStore.productStore.productLineListingInfo.page_size
              }
              size={rootStore.productStore.productLineListingInfo.page_size}
              count={rootStore.productStore.productLineListingInfo.count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </PaginationWrapper>
        </Container>
      </ParentContainer>
      <Footer />
      {openAddProductPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openAddProductPanel}
          onClose={() => {
            setIsEdit(false);
            setSelectedRowID("");
            setOpenAddProductPanel(false);
          }}
        >
          <AddProductLineSidePanel
            setRefresh={setRefresh}
            handleClose={() => {
              setIsEdit(false);
              setOpenAddProductPanel(false);
            }}
            isEdit={isEdit}
            productLineID={selectedRowID}
          />
        </Drawer>
      )}
      {viewTags && (
        <ViewListModal
          setOpen={setViewTags}
          open={viewTags}
          title={"Tags"}
          list={editableData["tags"]}
        />
      )}
      {openViewProductPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openViewProductPanel}
          onClose={() => {
            setSelectedRowID("");
            setOpenViewProductPanel(false);
          }}
        >
          <ViewProductLineSidepanel
            productLineID={selectedRowID}
            setOpen={setOpenViewProductPanel}
            open={openViewProductPanel}
          />
        </Drawer>
      )}
      {/* {openSelectColumnDialog && (
        <SelectProductColumnsDialog
          handleClose={() => {
            setOpenSelectColumnDialog(false);
          }}
          open={openSelectColumnDialog}
          handlePostApply={(columnList) => {
            updateColumnsPostSelectColumn(columnList);
            setOpenSelectColumnDialog(false);
          }}
        />
      )} */}
    </>
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingAnimationComponent size={"small"} />
    </Box>
  );
};

export default observer(ProductLinePage);
