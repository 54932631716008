import React, { useEffect, useState } from "react";
import { styled as MuiStyled } from "@mui/material/styles";
import { AppBar, Toolbar, Box, Divider, Grid, Typography } from "@mui/material";
import { FilterList, Info } from "@mui/icons-material";
import {
  IsoToLocalDate,
  dataRenderer,
  getLocalDateTime,
  getLocalizedText,
} from "../../Functions";
import { getSegmentDataApi } from "../../Api";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import rootStore from "../../stores/RootStore";
import moment from "moment";
import analytics from "../../Analytics";

const ModelWrapper = MuiStyled(Box)(({ theme }) => ({
  width: "500px",
  margin: "auto",
  display: "flex",
  position: "relative",
  /* padding: 30px, */
  flexDirection: "column",
  height: "95vh",
  paddingTop: "0px",
}));
const ModelHeader = MuiStyled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  variant: "span",
  color: "#4d4e4f",
}));

const Title = MuiStyled(Typography)(({ theme }) => ({
  font: "normal normal 400 14px Open Sans",
  color: "#5A606680",
}));
const Desc = MuiStyled(Typography)(({ theme }) => ({
  font: "normal normal 500 14px Open Sans",
  color: "#5A6066",
  wordWrap: "break-word",
}));

const Column = MuiStyled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
  padding: "0px 30px 0px 30px",
}));

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 20px;
  column-gap: 20px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;
const InputWrapperLg = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled.span`
  font: normal normal 500 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const SectionHeader = styled.span`
  font: normal normal 600 16px Open Sans;
  color: #4d4e4f;
`;

const SectionHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
const Detail = styled.span`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const SegmentInfoPanel = (props) => {
  const { userStore } = rootStore;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [filtersData, setFiltersData] = useState({});
  const columnsList = userStore.AllColumnsList;

  useEffect(() => {
    analytics.triggerEvent(
      4625000242,
      "segment_details_form_load",
      "segments_page",
      "",
      {}
    );
    const getData = async () => {
      try {
        let response = await getSegmentDataApi(props.id);
        setData(response.data);
        let new_list = {};
        if (response.data.filters !== null) {
          Object.keys(response.data.filters).forEach((key) => {
            if (key !== "custom_params") {
              new_list[key] = response.data.filters[key];
            } else {
              let custom_params_filters =
                response.data.filters["custom_params"];
              Object.keys(custom_params_filters).forEach((custom_key) => {
                let new_key = "custom_params_" + custom_key;
                new_list[new_key] = custom_params_filters[custom_key];
              });
            }
          });
        }
        setFiltersData(new_list);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    getData();
  }, []);

  const getFilterFieldData = (filter_field) => {
    let index = columnsList.findIndex(
      (column) => column["field"] === filter_field
    );
    if (index !== -1) {
      return columnsList[index];
    }
    return null;
  };
  const getFilterValue = (key) => {
    const filter = filtersData[key];

    if (
      filter &&
      typeof filter === "object" &&
      filter.hasOwnProperty("value")
    ) {
      return filter.value;
    }
    return filter ?? "-";
  };
  const convertUtcToLocal = (utcDate) =>
    moment.utc(utcDate).local().format("YYYY-MM-DD");
  return (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
            marginBottom: "20px",
          }}
        >
          <Toolbar>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Info color="primary" style={{ marginRight: "8px" }} />
              <ModelHeader>{getLocalizedText("segment_details")}</ModelHeader>
            </Box>
          </Toolbar>
        </AppBar>
        <ModelWrapper>
          <Column>
            {!loading ? (
              <>
                <InputContainer>
                  <InputWrapper>
                    <Label>{getLocalizedText("project")}</Label>
                    <Detail>
                      {data.hasOwnProperty("project")
                        ? dataRenderer(data.project.name)
                        : "-"}
                    </Detail>
                  </InputWrapper>
                  <InputWrapper>
                    <Label>{getLocalizedText("title")}</Label>
                    <Detail>{dataRenderer(data.title)}</Detail>
                  </InputWrapper>
                  <InputWrapperLg>
                    <Label>{getLocalizedText("description")}</Label>
                    <Detail>{dataRenderer(data.desc)}</Detail>
                  </InputWrapperLg>

                  <InputWrapper>
                    <Label>{getLocalizedText("created_at")}</Label>
                    <Detail>
                      {data.hasOwnProperty("created_at") &&
                      data["created_at"] !== null
                        ? IsoToLocalDate(data.created_at)
                        : "-"}
                    </Detail>
                  </InputWrapper>
                  <InputWrapper>
                    <Label>{getLocalizedText("created_by")}</Label>
                    <Detail>
                      {data.hasOwnProperty("creator")
                        ? dataRenderer(data.creator.name)
                        : "-"}
                    </Detail>
                  </InputWrapper>
                  <InputWrapper>
                    <Label>{getLocalizedText("status")}</Label>
                    <Detail>
                      {data.hasOwnProperty("active")
                        ? data.active === true
                          ? "Active"
                          : "Inactive"
                        : "-"}
                    </Detail>
                  </InputWrapper>
                </InputContainer>
                {Object.keys(filtersData).length > 0 && (
                  <>
                    <Divider style={{ margin: "20px 0px" }} />
                    <SectionHeaderWrapper>
                      <FilterList />
                      <SectionHeader>
                        {getLocalizedText("filters")}
                      </SectionHeader>
                    </SectionHeaderWrapper>
                    {Object.keys(filtersData).map((key, i) => {
                      let field_data = getFilterFieldData(key);
                      if (field_data !== null) {
                        if (field_data["filter_type"] === "list") {
                          return (
                            <Box key={i}>
                              <Box
                                style={{
                                  marginTop: "8px",
                                  marginBottom: "8px",
                                }}
                              >
                                <Grid container alignItems="center">
                                  <Grid item xs={4}>
                                    <Title>{field_data["headerName"]}</Title>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Divider
                                      orientation="vertical"
                                      sx={{
                                        height: "20px",
                                        color: "#3535354D",
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={7} paddingLeft={"8px"}>
                                    {getFilterValue(key).map((item) => {
                                      return <Desc>{item}</Desc>;
                                    })}
                                  </Grid>
                                </Grid>
                              </Box>
                              <Divider orientation="horizontal" />
                            </Box>
                          );
                        } else if (
                          field_data["filter_type"] === "range" &&
                          (field_data["data_type"] === "date" ||
                            field_data["data_type"] === "datetime")
                        ) {
                          return (
                            <Box key={i}>
                              <Box
                                style={{
                                  marginTop: "8px",
                                  marginBottom: "8px",
                                }}
                              >
                                <Grid container alignItems="center">
                                  <Grid item xs={4}>
                                    <Title>{field_data["headerName"]}</Title>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Divider
                                      orientation="vertical"
                                      sx={{
                                        height: "20px",
                                        color: "#3535354D",
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={7} paddingLeft={"8px"}>
                                    <Desc>
                                      {`${convertUtcToLocal(
                                        getFilterValue(key)["start"]
                                      )} - ${convertUtcToLocal(
                                        getFilterValue(key)["end"]
                                      )}`}
                                    </Desc>
                                  </Grid>
                                </Grid>
                              </Box>
                              <Divider orientation="horizontal" />
                            </Box>
                          );
                        } else {
                          return (
                            <Box key={i}>
                              <Box
                                style={{
                                  marginTop: "8px",
                                  marginBottom: "8px",
                                }}
                              >
                                <Grid container alignItems="center">
                                  <Grid item xs={4}>
                                    <Title>{field_data["headerName"]}</Title>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Divider
                                      orientation="vertical"
                                      sx={{
                                        height: "20px",
                                        color: "#3535354D",
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={7} paddingLeft={"8px"}>
                                    {field_data.data_type === "date" ? (
                                      <Desc>
                                        {convertUtcToLocal(getFilterValue(key))}
                                      </Desc>
                                    ) : field_data.data_type === "datetime" ? (
                                      <Desc>
                                        {getLocalDateTime(getFilterValue(key))}
                                      </Desc>
                                    ) : (
                                      <Desc>{getFilterValue(key)}</Desc>
                                    )}
                                  </Grid>
                                </Grid>
                              </Box>
                              <Divider orientation="horizontal" />
                            </Box>
                          );
                        }
                      }
                    })}
                  </>
                )}
              </>
            ) : (
              <InputContainer
                style={{
                  justifyContent: "center",
                  height: "100%",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </InputContainer>
            )}
          </Column>
        </ModelWrapper>
      </Box>
    </>
  );
};

export default SegmentInfoPanel;
