import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Divider,
  Modal,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button, makeStyles } from "@material-ui/core";
import rootStore from "../../stores/RootStore";
import {
  dataRenderer,
  getLocalizedText,
  IsoToLocalTime,
} from "../../Functions";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  margin: "auto",
  backgroundColor: "white",
  height: "fit-content",
  maxHeight: "95vh",
  width: "500px",
}));

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  height: "fit-content",
  width: "500px",
  border: "none",
  outline: "none",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px #0000000d, 0px 3px 20px #185dd21f",
  border: "1px solid #c5d7f1",
  borderRadius: "8px",
  overflow: "hidden",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "18px",
  fontFamily: "Open Sans",
  color: "#4d4e4f",
}));

const StatusText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "18px",
  fontFamily: "Open Sans",
  color: "#21589f",
  marginTop: "4px",
  marginBottom: "10px",
  marginLeft: "10px",
}));

const SubHeader = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Open Sans",
  color: "#6f6f6f",
}));

const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const EmptyText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "14px",
  fontFamily: "Open Sans",
  textAlign: "center",
  color: "#4d4e4f",
}));

const DelhiveryOrderScansDialog = (props) => {
  const [details, setDetails] = useState({});
  const [error, setError] = useState("");
  const [transitSteps, setTransitSteps] = useState([]);

  const init = () => {
    let customData = props.data.hasOwnProperty("custom_params")
      ? JSON.parse(props.data["custom_params"])
      : {};
    let statusData = customData.hasOwnProperty("delhivery_status_response")
      ? JSON.parse(customData["delhivery_status_response"])
      : {};

    if (Object.keys(statusData).length > 0) {
      if (statusData.hasOwnProperty("ShipmentData")) {
        setDetails(statusData);
        // building transit steps from response
        const steps = statusData.ShipmentData[0].Shipment.Scans.map((scan) => ({
          time: moment
            .tz(scan["ScanDetail"]["ScanDateTime"], "Asia/Kolkata")
            .format("D MMM YYYY h:mm A"), //time from response is in IST
          label: scan["ScanDetail"]["Scan"],
          description: `${scan.ScanDetail.Instructions} at ${scan.ScanDetail.ScannedLocation}`,
        }));
        setTransitSteps(steps);
      } else if (statusData.hasOwnProperty("Error")) {
        //error case
        setError(statusData.Error);
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  const handleClose = () => {
    props.setOpen(false);
  };

  const TransitTimeline = ({ steps }) => {
    return (
      <Timeline sx={{ padding: 0 }} position="right">
        {steps.map((step, index) => (
          <TimelineItem
            key={index}
            sx={{
              "&::before": { flex: 0, padding: 0 }, // Removes left-side space in timeline
            }}
          >
            <TimelineSeparator>
              {index > 0 && (
                <TimelineConnector
                  sx={{
                    bgcolor: "#ccc",
                    width: 2,
                    height: "100%",
                    marginLeft: index === steps.length - 1 ? "5px" : 0,
                  }}
                />
              )}
              <TimelineDot
                variant={"filled"}
                color={"grey"}
                sx={{
                  width: index === steps.length - 1 ? 28 : 12,
                  height: index === steps.length - 1 ? 28 : 12,
                  borderColor: "#ccc",
                  backgroundColor: "#fff",
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position:
                    index === steps.length - 1 ? "absolute" : "relative",
                  left: index === steps.length - 1 ? "-8px" : "0",
                  bottom: 0,
                }}
              >
                {index === steps.length - 1 && (
                  <LocalShippingIcon fontSize="small" sx={{ color: "#ccc" }} />
                )}
              </TimelineDot>
              {index < steps.length - 1 && (
                <TimelineConnector
                  sx={{ bgcolor: "#ccc", width: 2, height: "100%" }}
                />
              )}
            </TimelineSeparator>

            <TimelineContent
              sx={{
                marginLeft: index === steps.length - 1 ? "5px" : 0,
                px: "28px",
                display: "flex",
                flexDirection: "column",
                justifyContent:
                  index === 0
                    ? "flex-start"
                    : index === steps.length - 1
                    ? "flex-end"
                    : "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: "#333" }}
              >
                {step.time}
              </Typography>

              <Typography variant="caption" sx={{ color: "#999" }}>
                {step.description}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    );
  };

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Container>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  position: "relative",
                }}
              >
                <HeaderWrapper>
                  <Header>{getLocalizedText("transit_history")}</Header>
                  <SubHeader>
                    {getLocalizedText("all_tracking_history_details")}
                  </SubHeader>
                </HeaderWrapper>

                <IconButton
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    color: "#555",
                    "&:hover": {
                      color: "#000",
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider
              style={{
                margin: "16px",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            />
            {Object.keys(details).length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: "14px 24px",
                }}
              >
                <StatusText>
                  {dataRenderer(
                    Object.keys(details).length > 0
                      ? details?.ShipmentData[0]?.Shipment?.["Status"]["Status"]
                      : ""
                  )}
                </StatusText>
                <Box
                  style={{
                    maxHeight: "calc(100vh - 200px)",
                    height: "330px",
                    overflowY: "auto",
                    overflowX: "hidden",
                    padding: "14px 10px",
                  }}
                >
                  {transitSteps.length > 0 ? (
                    <TransitTimeline steps={transitSteps} />
                  ) : (
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* No scan data in response */}
                      <EmptyText>No data found!</EmptyText>
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              // no response or error getting waybill number
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: "14px 24px",
                  height: "330px",
                }}
              >
                <EmptyText>{error !== "" ? error : "No data found!"}</EmptyText>
              </Box>
            )}
          </Container>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(DelhiveryOrderScansDialog);
