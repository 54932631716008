import React from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { Grid } from "@material-ui/core";
import { IsoToLocalDate, IsoToLocalTime, dataRenderer } from "../../Functions";
import ReactAudioPlayer from "react-audio-player";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ViewAttendanceCallInfoComponent = ({ details }) => {
  const noteTextGetter = (raw_string) => {
    return raw_string.replace(/\r\n|\n/g, "\n");
  };
  return (
    <Box sx={{ width: 500 }}>
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>Call Details</ModelHeader>
        </Toolbar>
      </AppBar>
      <ModelWrapper>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Created At</Label>
              <Detail>
                {details.hasOwnProperty("created_at") &&
                details["created_at"] !== null &&
                details["created_at"] !== ""
                  ? IsoToLocalDate(details["created_at"])
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Caller</Label>
              <Detail>
                {details.hasOwnProperty("caller") && details["caller"] !== null
                  ? dataRenderer(details["caller"]["name"])
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Call Start Time</Label>
              <Detail>
                {details.hasOwnProperty("call_start_time") &&
                details["call_start_time"] !== null &&
                details["call_start_time"] !== ""
                  ? IsoToLocalTime(details["call_start_time"])
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Call End Time</Label>
              <Detail>
                {details.hasOwnProperty("call_end_time") &&
                details["call_end_time"] !== null &&
                details["call_end_time"] !== ""
                  ? IsoToLocalTime(details["call_end_time"])
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Call Duration</Label>
              <Detail>{dataRenderer(details["call_duration"])}</Detail>
            </InputWrapper>
          </Grid>

          <Grid item xs={6}>
            <InputWrapper>
              <Label>Lead Status</Label>
              <Detail>{dataRenderer(details["lead_status"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper>
              <Label>Notes</Label>
              {details.hasOwnProperty("notes") &&
              details.notes !== null &&
              details.notes !== "" ? (
                <Typography
                  component="div"
                  style={{
                    whiteSpace: "pre-line",
                  }}
                >
                  {noteTextGetter(details.notes)}
                </Typography>
              ) : (
                <Detail>-</Detail>
              )}
            </InputWrapper>
          </Grid>
          {details.hasOwnProperty("audio") &&
            details["audio"] !== null &&
            details["audio"] !== "" && (
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>Audio</Label>
                  <ReactAudioPlayer
                    src={details["audio"]}
                    autoPlay={false}
                    controls
                  />
                </InputWrapper>
              </Grid>
            )}
        </Grid>
      </ModelWrapper>
    </Box>
  );
};

export default ViewAttendanceCallInfoComponent;
