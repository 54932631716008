import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Divider } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import "./createjob.css";
import { DataGrid } from "@mui/x-data-grid";
import {
  AutoDialerCampaignColumnHeaders,
  OzonetelDialerAgentListColumnHeaders,
} from "../../Db";
import { ArrowForward } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import { getLocalizedText } from "../../Functions";
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 340px;
  width: 400px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Info = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled.span`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const TableWrapper = styled.div`
  width: 100%;
  /* max-height: 144px; */
  /* overflow-y: auto; */
  height: 144px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;

const DialerExportJobSummary = (props) => {
  const { authStore } = rootStore;
  const [jobType, setJobType] = useState(null);

  const getJobType = () => {
    props.typeList.map((item) => {
      if (item.value === props.item.type) {
        setJobType(item["label"]);
      }
    });
  };
  useEffect(() => {
    getJobType();
  }, []);

  return (
    <>
      <SubHeader>{getLocalizedText("job_summary")}</SubHeader>
      <Divider style={{ margin: "10px 0px" }} />
      <InputContainer>
        <InputWrapper style={{ marginBottom: "20px" }}>
          <Info>{getLocalizedText("job_type")} </Info>
          <Detail>{jobType !== null ? jobType : "-"}</Detail>
        </InputWrapper>
        <InputWrapper style={{ marginBottom: "20px" }}>
          <Info>{getLocalizedText("job_title")} </Info>
          <Detail>{props.item.title ? props.item.title : "-"}</Detail>
        </InputWrapper>
        <InputWrapper style={{ marginBottom: "20px" }}>
          <Info>{getLocalizedText("max_leads_per_agent","project")} </Info>
          <Detail>
            {props.maxLeadCount !== null ? props.maxLeadCount : "-"}
          </Detail>
        </InputWrapper>
        <InputWrapper style={{ marginBottom: "20px" }}>
          <Info>{getLocalizedText("date_range")} </Info>
          {props.dateRange !== null ? (
            <Row>
              <Detail>{props.dateRange["start_date"]}</Detail>
              <ArrowForward style={{ width: "18px", height: "18px" }} />
              <Detail>{props.dateRange["end_date"]}</Detail>
            </Row>
          ) : (
            <Detail>-</Detail>
          )}
        </InputWrapper>
        <InputWrapper
          style={{
            width: "100%",
          }}
        >
          <Label>{getLocalizedText("agents")}</Label>
          <TableWrapper>
            <DataGrid
              //   autoHeight={true}
              rows={props.agentList}
              columns={
                rootStore.userStore.getOutboundDialer() === "ozonetel"
                  ? OzonetelDialerAgentListColumnHeaders
                  : AutoDialerCampaignColumnHeaders
              }
              checkboxSelection={false}
              style={{ cursor: "pointer" }}
              hideFooter={true}
              disableColumnMenu={true}
            />
          </TableWrapper>
        </InputWrapper>
      </InputContainer>
    </>
  );
};

export default observer(DialerExportJobSummary);
