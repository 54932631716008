import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { capitalizeWord, getAccountLocalizedText, getLocalizedText } from "../../../Functions";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import Select from "react-select";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: "100%";
  text-align: left;
  margin: auto;
  background-color: white;
  height: 400px;
  padding: 16px;
  overflow-y: auto;
  row-gap: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const RowWrapper = styled(Box)`
  display: flex;
  column-gap: 10px;
  width: 100%;
`;
const CheckBoxTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const ColumnWrapper = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0px 16px;
  margin-top: 16px;
  width: 100%;
`;
const ColumnHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  width: 100%;
`;
const ColumnMappingScreen = ({
  productColumnsList,
  fileColumnHeaders,
  flleAndProductColumnMapping,
  setFileAndProductColumnMapping,
}) => {
  const [loading, setLoading] = useState(true);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      fontWieght: "bold",
      // Font size for options
    }),
    menuList: (base) => ({
      ...base,
      minHeight: "200px", // your desired height
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      width: "284px",
      fontWieght: "bold",
      // Font size for the entire control container, including the search input
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      // Font size for "no options" message
    }),
    loadingMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      // Font size for "loading" message
    }),
  };

  const canUseColumnForMapping = ({
    mappedDataList,
    destProjectColumnField,
  }) => {
    for (const value of Object.values(mappedDataList)) {
      if (
        value["product_column"].hasOwnProperty("display_name") &&
        value["product_column"]["display_name"] === destProjectColumnField &&
        value["checked"] === true
      ) {
        return false;
      }
    }
    return true;
  };

  const initFormToProjectColumns = () => {
    let initList = {};

    fileColumnHeaders.forEach((fileColumn) => {
      let checked = false;
      let productColumn = {};

      let filteredColumnsList = productColumnsList.filter((col) =>
        canUseColumnForMapping({
          mappedDataList: initList,
          destProjectColumnField: col["display_name"],
        })
      );

      let index = findClosestMatchColumn(
        fileColumn["key"],
        filteredColumnsList
      );
      if (index !== -1) {
        checked = true;
        productColumn = {
          id: filteredColumnsList[index]["id"],
          column_name: filteredColumnsList[index]["column_name"],
          display_name: filteredColumnsList[index]["display_name"],
          label: filteredColumnsList[index]["display_name"],
          value: filteredColumnsList[index]["column_name"],
          isDisabled: isAlreadySelectedProjectField(
            filteredColumnsList[index].field
          ),
          required: filteredColumnsList[index].required,
        };
      }

      let tempObj = {
        form_lead: {
          column_name: fileColumn["key"],
          display_name: fileColumn["label"],
        },
        product_column: productColumn,
        checked: checked,
      };

      initList[fileColumn["key"]] = tempObj;
    });

    return initList;
  };

  const findClosestMatchColumn = (field, projectColumns) => {
    const searchStr = field;
    const titleStr = capitalizeWord(field);
    const words = field.split("_");

    // 1. Exact match for field
    let index = projectColumns.findIndex(
      (obj) => obj.column_name === searchStr
    );
    if (index !== -1) return index;

    // 2. Exact match for headerName
    index = projectColumns.findIndex((obj) => obj.display_name === titleStr);
    if (index !== -1) return index;

    // 3. Case-insensitive match for field
    index = projectColumns.findIndex(
      (obj) => obj.column_name.toLowerCase() === searchStr.toLowerCase()
    );
    if (index !== -1) return index;

    // 4. Case-insensitive match for headerName
    index = projectColumns.findIndex(
      (obj) => obj.display_name.toLowerCase() === titleStr.toLowerCase()
    );
    if (index !== -1) return index;

    // 5. Match for any word from the snake_case string in field or headerName
    index = projectColumns.findIndex((obj) => {
      const fieldWords = obj.column_name.toLowerCase().split("_");
      const headerNameWords = obj.display_name.toLowerCase().split(" ");
      return words.some(
        (word) =>
          fieldWords.includes(word.toLowerCase()) ||
          headerNameWords.includes(word.toLowerCase())
      );
    });
    if (index !== -1) return index;

    return -1;
  };

  const setup = async () => {
    let tempFileAndProductColumnMapping = initFormToProjectColumns();
    setFileAndProductColumnMapping(tempFileAndProductColumnMapping);
    setLoading(false);
  };

  const getProductColumnsForDropdown = () => {
    let tempList = [];
    productColumnsList.forEach((column) => {
      let tempObj = {
        label: column.display_name,
        value: column.column_name,
        isDisabled: isAlreadySelectedProjectField(column["column_name"]),
        required: column.required,
      };
      tempList.push(tempObj);
    });
    return tempList;
  };

  const getFormFieldHeaderName = (field) => {
    let index = fileColumnHeaders.findIndex(
      (question) => question["key"] === field
    );
    if (index !== -1) {
      return fileColumnHeaders[index]["label"];
    } else {
      return "";
    }
  };

  useEffect(() => {
    setup();
  }, []);

  const isAlreadySelectedProjectField = (field) => {
    let flag = false;
    Object.keys(flleAndProductColumnMapping).forEach((item) => {
      if (
        flleAndProductColumnMapping[item]["product_column"].hasOwnProperty(
          "column_name"
        ) &&
        flleAndProductColumnMapping[item]["product_column"]["column_name"] ===
          field
      ) {
        flag = true;
      }
    });
    return flag;
  };

  return (
    <>
      {!loading ? (
        <Box>
          <ColumnWrapper>
            <ColumnHeader> {getLocalizedText("file_field")}</ColumnHeader>
            <ColumnHeader>
              {getAccountLocalizedText("product_field")}
            </ColumnHeader>
          </ColumnWrapper>
          <Container>
            {Object.keys(flleAndProductColumnMapping).map((item, index) => {
              return (
                <RowWrapper>
                  <CheckBoxTextWrapper>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setFileAndProductColumnMapping({
                              ...flleAndProductColumnMapping,
                              [item]: {
                                ...flleAndProductColumnMapping[item],
                                checked: e.target.checked,
                              },
                            });
                          }}
                          size="small"
                          checked={flleAndProductColumnMapping[item]["checked"]}
                        />
                      }
                      label={
                        <Label>
                          {getFormFieldHeaderName(
                            flleAndProductColumnMapping[item]["form_lead"][
                              "column_name"
                            ]
                          )}
                        </Label>
                      }
                    />
                  </CheckBoxTextWrapper>
                  <Select
                    menuPosition="fixed"
                    key={index}
                    maxMenuHeight={150}
                    value={flleAndProductColumnMapping[item]["product_column"]}
                    required={flleAndProductColumnMapping[item]["checked"]}
                    onChange={(e) => {
                      if (e === null) {
                        setFileAndProductColumnMapping({
                          ...flleAndProductColumnMapping,
                          [item]: {
                            ...flleAndProductColumnMapping[item],
                            product_column: {},
                          },
                        });
                      } else {
                        let index = productColumnsList.findIndex(
                          (column) => column["column_name"] === e.value
                        );

                        setFileAndProductColumnMapping({
                          ...flleAndProductColumnMapping,
                          [item]: {
                            ...flleAndProductColumnMapping[item],
                            product_column: {
                              ...e,
                              id: productColumnsList[index]["id"],
                              column_name:
                                productColumnsList[index]["column_name"],
                              display_name:
                                productColumnsList[index]["display_name"],
                            },
                          },
                        });
                      }
                    }}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    options={getProductColumnsForDropdown()}
                    closeMenuOnSelect={true}
                    isClearable
                    styles={customStyles}
                    menuShouldScrollIntoView={false}
                    formatOptionLabel={(option) => {
                      if (option.required) {
                        return (
                          <Typography sx={{ fontSize: "12px" }}>
                            <span style={{ color: "red" }}>*</span>
                            {option.label}
                          </Typography>
                        );
                      } else {
                        return (
                          <Typography sx={{ fontSize: "12px" }}>
                            {option.label}
                          </Typography>
                        );
                      }
                    }}
                  ></Select>
                </RowWrapper>
              );
            })}
          </Container>
        </Box>
      ) : (
        <Container sx={{ justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </Container>
      )}
    </>
  );
};

export default ColumnMappingScreen;
