import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  OutlinedInput,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { Grid, Modal, makeStyles } from "@material-ui/core";
import { consoleLogger, getLocalizedText } from "../../Functions";
import WhatsappSendTemplateMessagePreview from "../whatsapp_business_chat_components/WhatsappSendTemplateMessagePreview";
import MuiAlert from "@mui/material/Alert";
import { uploadWabaMediaApi } from "../../Api";
import rootStore from "../../stores/RootStore";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "none",
  outline: "none",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
  border: "1px solid #c5d7f1",
  borderRadius: "8px",
  padding: "16px",
  width: "100%",
  maxWidth: "560px",
  height: "max-content",
  maxHeight: "95vh",
  overflow: "auto",
  boxSizing: "border-box",
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "100vw",
  minWidth: "500px",
}));

const FieldsWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "100%",
  gap: "8px",
  overflow: "auto",
  boxSizing: "border-box",
  padding: "8px",
}));
const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  gap: "8px",
}));
const Label = styled(Typography)(({ theme }) => ({
  width: "25%",
  font: "normal normal 500 12px Open Sans",
  color: "#4d4e4f",
}));
const PreviewHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "18px",
  fontFamily: "'Open Sans', sans-serif",
}));
const PreviewWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  width: "100%",
  padding: "15px",
  position: "relative",
  backgroundColor: "#e5ddd5",
  boxSizing: "border-box",
  overflow: "auto",
  height: "30vh",
  flexShrink: 0,
}));
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    minHeight: "20px",
  },
}));

function WhatsappTemplatePreview({
  open,
  setOpen,
  providerData,
  selectedWhatsappTemplate,
  onSubmitCallback,
  setVariableDetails,
  variableDetails,
  setHeaderVariableField,
  headerVariableField,
  setSelectedFile,
  selectedFile,
}) {
  const toolbarStyle = useStyles();
  const [mediaMissingWarning, setMediaMissingWarning] = useState(false);
  const contactColumns = [...rootStore.contactStore.columnsList];

  useEffect(() => {
    init();
  }, []);
  const handlePhotoAdd = (event) => {
    setSelectedFile(event.target.files);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getComponentByType = (type) => {
    const component = selectedWhatsappTemplate["components"].find(
      (x) => x.type.toLowerCase() === type
    );
    return component || null;
  };

  const init = () => {
    //skip setup if data was already submitted before (prefilling)
    if (
      variableDetails.length !== 0 ||
      selectedFile !== null ||
      Object.keys(headerVariableField).length !== 0
    ) {
      return;
    }

    //header var handling
    const headerData = getComponentByType("header");
    if (
      headerData !== null &&
      headerData.hasOwnProperty("format") &&
      headerData.format !== null
    ) {
      if (
        headerData.format.toLowerCase() === "image" ||
        headerData.format.toLowerCase() === "video"
      ) {
        setHeaderVariableField({
          name: headerData.format.toLowerCase(),
          id: -1,
        });
      }
    }
    let bodyData = [];
    if (selectedWhatsappTemplate.components.length > 0) {
      bodyData = selectedWhatsappTemplate["components"].find(
        (x) => x.type.toLowerCase() === "body"
      );
    }

    let componentVariableMapping = {};
    let allPlaceholders = extractPlaceholders(bodyData["text"]);
    let variableDetailsList = allPlaceholders.map((placeholder, i) => {
      return {
        name: placeholder,
        id: i + 1,
        is_visible: true,
        value: "",
      };
    });
    if (selectedWhatsappTemplate.hasOwnProperty("variables")) {
      componentVariableMapping = selectedWhatsappTemplate["variables"];
      for (const key in componentVariableMapping) {
        let value = "";

        if (
          typeof componentVariableMapping[key] === "object" &&
          componentVariableMapping[key] !== null
        ) {
          const name = componentVariableMapping[key]["name"];
          const mappingKey = key.replace(/[{}]/g, "");
          variableDetailsList[mappingKey - 1]["value"] =
            componentVariableMapping[key]["name"]; //fill name value directly first in variable field

          const index = contactColumns.findIndex(
            (column) => column.display_name === name
          );

          if (index !== -1) {
            value = contactColumns[index]["id"]; //getting column id from Name in mapping if there is a match
          }
        } else {
          // for older templates, using the value directly from componentVariableMapping key
          value = componentVariableMapping[key]; //value is contact column id here
        }
      }
    }
    setVariableDetails(variableDetailsList);
  };

  function extractPlaceholders(inputString) {
    const regex = /\{\{\d+\}\}/g;
    const matches = inputString.match(regex);
    return matches || [];
  }

  const uploadWABAMedia = async (file) => {
    const formData = new FormData();
    formData.append("wp_id", providerData.id);
    formData.append("file", file);

    const response = await uploadWabaMediaApi({
      payload: formData,
    });

    if (!response.hasError()) {
      return response.data.media_id;
    } else {
      console.log(response.errorMessage);
      return "";
    }
  };

  const handleSubmit = async () => {
    let incoming_components = [];

    let mediaID = "";
    let fileType = "";
    if (selectedFile !== null) {
      mediaID = await uploadWABAMedia(selectedFile[0]);
      fileType = selectedFile[0].type.split("/")[0]; // image or video
      incoming_components.push({
        type: "header",
        parameters: [{ type: fileType, [fileType]: { id: mediaID } }],
      });
    }
    let bodyPayload = Object.values(variableDetails).map((detail) => ({
      type: "text",
      text: detail["value"],
    }));

    const payload = {
      component_parameters: [
        ...incoming_components,
        {
          type: "body",
          parameters: bodyPayload,
        },
      ],
    };
    consoleLogger("payload:: ", payload);

    onSubmitCallback(payload);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModelContainer
        component={"form"}
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (Object.keys(headerVariableField).length !== 0) {
            if (selectedFile !== null) {
              handleSubmit();
              setMediaMissingWarning(false);
            } else {
              setMediaMissingWarning(true);
            }
          } else {
            handleSubmit();
            setMediaMissingWarning(false);
          }
        }}
      >
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          style={{
            top: "-16px",
            bottom: "auto",
            width: "100%",
          }}
        >
          <Toolbar
            classes={{ root: toolbarStyle.root }}
            style={{
              padding: "0px",
              width: "100%",
              position: "relative",
              minHeight: 40,
              height: 40,
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              style={{ height: "50px" }}
            >
              <PreviewHeader>Preview and Variables</PreviewHeader>
            </Grid>
          </Toolbar>
        </AppBar>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ContentWrapper>
              <PreviewWrapper>
                <WhatsappSendTemplateMessagePreview
                  data={selectedWhatsappTemplate.components}
                  variableDetails={variableDetails}
                  headerVariableField={headerVariableField}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  handlePhotoAdd={handlePhotoAdd}
                />
              </PreviewWrapper>
            </ContentWrapper>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ marginBottom: "8px" }}>
              {" "}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <legend
                    style={{
                      width: "auto",
                      marginBottom: "0px",
                      fontSize: "12px",
                    }}
                  >
                    Variables
                  </legend>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    overflow: "auto",
                    height: "fit-content",
                    maxHeight: "30vh",
                  }}
                >
                  {variableDetails.length > 0 ? (
                    variableDetails.map(
                      (field) =>
                        field.is_visible && (
                          <FieldsWrapper>
                            <InputWrapper key={field.id}>
                              <Label>{field.name}</Label>
                              <FormControl sx={{ width: "100%" }}>
                                <OutlinedInput
                                  name={`body_text_${field.id}`}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    let newDetails = [...variableDetails];

                                    newDetails[field.id - 1] = {
                                      ...newDetails[field.id - 1],
                                      value,
                                    };

                                    setVariableDetails([...newDetails]);
                                  }}
                                  value={
                                    variableDetails[field.id - 1]["value"] || ""
                                  }
                                  style={{
                                    height: "40px",
                                    width: "100%",
                                  }}
                                  inputProps={{
                                    "aria-label": "weight",
                                    style: {
                                      fontSize: "12px",
                                    },
                                  }}
                                />
                              </FormControl>
                            </InputWrapper>
                          </FieldsWrapper>
                        )
                    )
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {getLocalizedText("no_variables_found")}
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
        </Grid>
        <AppBar
          position="static"
          color="inherit"
          elevation={0}
          sx={{ bottom: 0, width: "100%" }}
        >
          <Toolbar
            style={{
              padding: "2px",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              minHeight: "0px",
            }}
          >
            {mediaMissingWarning && (
              <ErrorAlert
                onClose={(e) => {
                  e.stopPropagation();
                  setMediaMissingWarning(false);
                }}
                severity="error"
                sx={{ width: "100%" }}
              >
                {getLocalizedText("please_add_media_before_sending")}
              </ErrorAlert>
            )}

            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                  type="button"
                  variant="contained"
                  color="inherit"
                  style={{
                    textTransform: "none",

                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  {getLocalizedText("cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",

                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </ModelContainer>
    </Modal>
  );
}

export default WhatsappTemplatePreview;
