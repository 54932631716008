import { Grid } from "@material-ui/core";
import { Box, CircularProgress, styled, Typography } from "@mui/material";
import React from "react";
import ProductCard from "./ProductCard";
const Message = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;
const ProductsListingSection = ({
  productList,
  isProductSelected,
  isLoading,
  handleProductSelection,
  isProductDisabled,
  handleProductView,
}) => {
  return !isLoading ? (
    productList.length > 0 ? (
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 220px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Grid container spacing={2}>
          {productList.map((product, index) => {
            return (
              <Grid item xs={6} key={index}>
                <ProductCard
                  isProductDisabled={() => isProductDisabled(product)}
                  details={product}
                  handleClick={() => handleProductSelection(product)}
                  isProductSelected={() => isProductSelected(product)}
                  handleView={() => handleProductView(product)}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    ) : (
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 220px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Message>No products found.</Message>
      </Box>
    )
  ) : (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 220px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default ProductsListingSection;
