import React, { useState } from "react";
import styled from "styled-components";
import {
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  Box,
  Grid,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment-timezone";
import { consoleLogger } from "../../Functions";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 300px;
  width: 428px;
`;
const FormSubHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #a9a9a9;
  margin-bottom: 10px;
`;
const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const CustomizedDatePicker = styled(DatePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
    padding: 10px 14px;
    height: 40px;
  }
`;

const timezones = moment.tz.names();

const WhatsappScheduleComponent = ({
  scheduleOption,
  setScheduleOption,
  setScheduleDateTime,
  setSelectedDate,
  selectedDate,
  setSelectedTime,
  selectedTime,
  timeSlot,
  setTimeSlot,
  timezone,
  setTimezone,
}) => {
  const [timeSlots, setTimeSlots] = useState([]);

  const generateTimeSlots = (date) => {
    const selectedDateValue = date
      ? moment.tz(date.format("YYYY-MM-DD"), timezone)
      : null;
    const today = moment().tz(timezone).startOf("day").format("YYYY-MM-DD");

    const isCurrentDate = selectedDateValue
      ? moment(selectedDateValue).isSame(today, "day")
      : false;

    let now = isCurrentDate ? moment() : moment().startOf("day"); // Start at current time or midnight
    let endTime = moment().endOf("day");

    // Round up to the nearest 15-minute mark
    let remainder = now.minute() % 15;
    if (remainder > 0) {
      now.add(15 - remainder, "minutes");
    }

    let slots = [];

    while (now.isBefore(endTime) || now.isSame(endTime, "minute")) {
      let formattedTime = now.format("h:mm A"); // 12-hour format with AM/PM
      slots.push({ label: formattedTime, value: formattedTime });

      now.add(15, "minutes"); // Increment by 15 minutes
    }

    setTimeSlots(slots);
  };

  const init = () => {
    generateTimeSlots(selectedDate);
  };

  React.useEffect(() => {
    init();
  }, []);

  const handleDateTimeChange = (date, time) => {
    if (!date || !time) {
      consoleLogger("Invalid date or time input", date, time);
      return;
    }

    const momentDate = moment.tz(date.format("YYYY-MM-DD"), timezone);
    const momentTime = moment.tz(time, timezone).format("HH:mm");

    // Combine the date and time
    const combinedDateTime = moment.tz(
      `${momentDate.format("YYYY-MM-DD")} ${momentTime}`,
      "YYYY-MM-DD HH:mm",
      timezone
    );

    consoleLogger("Combined DateTime :: ", combinedDateTime.format());
    setScheduleDateTime(combinedDateTime.toISOString());
  };

  const getTimeValueForTimeSlot = (selectedTime) => {
    if (!selectedTime || !selectedDate) return null;

    const combinedDateTime = moment.tz(
      `${selectedDate.format("YYYY-MM-DD")} ${selectedTime}`,
      "YYYY-MM-DD h:mm A",
      timezone
    );

    return moment(combinedDateTime);
  };

  return (
    <InputContainer>
      <FormSubHeader>Schedule</FormSubHeader>
      <FormControl component="fieldset">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RadioGroup
              value={scheduleOption}
              onChange={(e) => {
                setScheduleOption(e.target.value);

                //resetting selected values
                setSelectedDate(null);
                setSelectedTime("");
                setTimeSlot("");
                setTimezone(moment.tz.guess());
                setScheduleDateTime("");
              }}
            >
              <FormControlLabel
                value="now"
                control={<Radio />}
                label="Send it now"
              />
              <FormControlLabel
                value="later"
                control={<Radio />}
                label="Schedule for a specific time"
              />
            </RadioGroup>
          </Grid>

          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomizedDatePicker
                disabled={scheduleOption !== "later"}
                disablePast
                onChange={(newDate) => {
                  setSelectedDate(newDate);
                  setSelectedTime("");
                  setTimeSlot("");

                  handleDateTimeChange(newDate, null);
                  generateTimeSlots(newDate); // generate time slots again on date change
                }}
                slotProps={{ textField: { size: "small" } }}
                value={selectedDate || null}
                inputFormat="YYYY-MM-DD"
                renderInput={(params) => (
                  <TextField
                    required={scheduleOption === "later"}
                    disabled={scheduleOption !== "later"}
                    {...params}
                    fullWidth
                    label="Select date"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  flex: 0.2, // 20% width
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  color: scheduleOption === "later" ? "#000" : "gray",
                }}
              >
                Deliver at
              </Typography>

              <FormControl sx={{ flex: 0.8 }}>
                {" "}
                {/* 80% width */}
                <InputLabel>Select time*</InputLabel>
                <Select
                  label="Select time"
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-input": {
                      fontSize: "14px",
                    },
                  }}
                  required={scheduleOption === "later"}
                  disabled={scheduleOption !== "later"}
                  value={timeSlot}
                  onChange={(e) => {
                    setTimeSlot(e.target.value);
                    const time = getTimeValueForTimeSlot(e.target.value);

                    setSelectedTime(time);
                    handleDateTimeChange(selectedDate, time);
                  }}
                >
                  {timeSlots.map((timeSlot) => (
                    <MenuItem
                      key={timeSlot.value}
                      value={timeSlot.value}
                      sx={{ fontSize: "14px" }}
                    >
                      {timeSlot.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Select timezone*</InputLabel>{" "}
              <Select
                label="Select timezone"
                id="timezone-select"
                required={scheduleOption === "later"}
                disabled={scheduleOption !== "later"}
                labelId="timezone-label"
                value={timezone}
                sx={{ fontSize: "14px" }}
                onChange={(e) => {
                  setTimezone(e.target.value);
                  handleDateTimeChange(selectedDate, selectedTime);
                }}
                displayEmpty
              >
                {timezones.map((tz) => (
                  <MenuItem key={tz} value={tz} sx={{ fontSize: "14px" }}>
                    {tz}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </FormControl>
    </InputContainer>
  );
};

export default observer(WhatsappScheduleComponent);
