import {
  AddBoxOutlined,
  Delete,
  Edit,
  History,
  SettingsPhone,
} from "@material-ui/icons";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  getAdminMembersApi,
  removeAdminMemberApi,
  removeAdminProjectMemberDialerConfigApi,
} from "../../Api";
import spinner from "../../assets/images/gifs/spinner.gif";
import { MemberSettingColumns } from "../../Db";
import { validateDisabledFeature } from "../../Functions";
import AddMemberDirectly from "../admin_setting_modals/AddMemberDirectly";
import AddMemberModal from "../admin_setting_modals/AddMemberModal";
import MemberDetailModal from "../admin_setting_modals/add_member_modals/MemberDetailModal";
import NewMemberAddModal from "../admin_setting_modals/add_member_modals/NewMemberAddModal";
import GetMemberByPhoneModal from "../admin_setting_modals/GetMemberByPhoneModal";
import rootStore from "../../stores/RootStore";
import EditProjectMember from "../admin_setting_modals/EditProjectMember";
import analytics from "../../Analytics";
import CustomErrorMessageDialog from "../alert_dialogue/CustomErrorMessageDialog";
import SuccessMessageDialog from "../alert_dialogue/SuccessMessageDialog";
import CustomErrorAlertWithContinue from "../alert_dialogue/CustomErrorAlertWithContinue";
import DialerConfigSidepanel from "../dialer_config_sidepanel/DialerConfigSidepanel";
import { Drawer } from "@material-ui/core";
import CustomConfirmationDialog from "../alert_dialogue/CustomConfirmationDialog";
import MessageWithLoadingStateCustomDialog from "../alert_dialogue/MessageWithLoadingStateCustomDialog";
const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const Container = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 234px);`};

  position: relative;
  /* margin-top: 70px; */
`;
const Wrapper = styled.div`
  display: flex;
`;

const DetailsWrapper = styled.div`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const AddBtnWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 9px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 700px) {
    position: unset;
    margin-left: 12px;
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const ProjectMemberTab = (props) => {
  const [row, setRow] = useState([]);
  const { userStore } = rootStore;
  const [openMemberInfoModal, setOpenMemberInfoModal] = useState(false);
  const [openAddMemberDirectly, setOpenAddMemberDirectly] = useState(false);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    phone: "",
    role: "member",
    unmask_columns: false,
  });
  const [openNewUserModal, setOpenNewUserModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState("");
  const [memberData, setMemberData] = useState(null);
  const [newUserId, setNewUserId] = useState(null);
  const [openFinalScreen, setOpenFinalScreen] = useState(false);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedRowData, setRowData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedUserID, setSelectedUserID] = useState("");
  const [showConfirmAlertDialog, setShowConfirmAlertDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  //*************dialer config related state variables****** */
  const [openDialerConfigPanel, setOpenDialerConfigPanel] = useState(false);
  const [
    showRemoveDialerCredeConfirmationDialog,
    setShowRemoveDialerCredConfirmationDialog,
  ] = useState(false);
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isRemoveApiFailed, setIsRemoveApiFailed] = useState(false);
  const [fetchingApi, setFetchingApi] = useState(false);
  //************************end********************************** */

  const getMembersList = async () => {
    try {
      let response = await getAdminMembersApi(props.id);
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };
  useEffect(() => {
    fetchMembers();
  }, []);
  const fetchMembers = async () => {
    setLoading(true);
    let membersList = await getMembersList();
    setRow(membersList);
    setLoading(false);
  };

  const refetchMembers = async () => {
    setLoading(true);
    let membersList = await getMembersList();
    if (rootStore.userStore.projectMemberMapping !== null) {
      rootStore.userStore.updateProjectMemberMapping({
        projectID: props.id,
        list: membersList,
      });
    }
    setRow(membersList);
    setLoading(false);
  };
  useEffect(() => {
    if (refresh === true) {
      refetchMembers();
      setRefresh(false);
    }
  }, [refresh]);
  const handleDelete = async (id) => {
    let data = { user_id: id, force: false };
    let response = await removeAdminMemberApi({ id: props.id, data: data });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setShowConfirmAlertDialog(true);
    } else {
      setSuccessMessage("User removed successfully!");
      setShowSuccessDialog(true);
      setRefresh(true);
    }
  };

  const handleRemoveDialerCredentials = async () => {
    setShowRemoveDialerCredConfirmationDialog(false);
    setFetchingApi(true);
    setOpenSubmitResponseDialog(true);
    let payload = { user_id: selectedUserID, dialer: "teckinfo" };
    let response = await removeAdminProjectMemberDialerConfigApi({
      projectID: rootStore.authStore.projectId,
      payload: payload,
    });
    if (!response.hasError()) {
      setIsRemoveApiFailed(false);
      setSuccessMessage("Dialer credentials deleted successfully!");
      setFetchingApi(false);
      setRefresh(true);
    } else {
      setErrorMessage(response.errorMessage);
      setIsRemoveApiFailed(true);
      setFetchingApi(false);
    }
    setSelectedUserID("");
  };

  const handleConfirm = async () => {
    let data = { user_id: selectedUserID, force: true };
    let response = await removeAdminMemberApi({ id: props.id, data: data });
    setShowConfirmAlertDialog(false);
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setShowErrorDialog(true);
    } else {
      setSuccessMessage("User removed successfully!");
      setShowSuccessDialog(true);
      setRefresh(true);
    }
  };

  const canDirectAddMember = () => {
    let flag = false;
    if (userStore.UserData !== null) {
      if (
        userStore.UserData.role === "admin" ||
        userStore.UserData.role === "owner"
      ) {
        return true;
      }
    }
    return flag;
  };

  const canShowDialerConfigAction = () => {
    let outboundDialer = rootStore.userStore.getOutboundDialer();
    let inboundDialer = rootStore.userStore.getInboundDialer();

    return outboundDialer === "teckinfo" || inboundDialer === "teckinfo";
  };
  const actionColumn = [
    {
      field: "action",
      headerName: <Text>Actions</Text>,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <Row>
            <Edit
              onClick={(e) => {
                e.stopPropagation();
                analytics.triggerEvent(
                  4625000181,
                  "edit_button_tap",
                  "project_members_page",
                  "edit_button",
                  {}
                );
                setOpenEdit(true);
                setRowData(params.row);
              }}
              style={{ marginRight: "10px", width: "18px", height: "18px" }}
              color="primary"
            />
            <Delete
              color="error"
              onClick={(e) => {
                analytics.triggerEvent(
                  4625000182,
                  "delete_button_tap",
                  "project_members_page",
                  "delete_button",
                  {}
                );
                handleDelete(params.row.user_id);
                setSelectedUserID(params.row.user_id);
              }}
              style={{ marginRight: "10px", width: "18px", height: "18px" }}
            />
            {validateDisabledFeature("checkins") && (
              <History
                onClick={(e) => {
                  analytics.triggerEvent(
                    4625000183,
                    "checkins_button_tap",
                    "project_members_page",
                    "checkins_button",
                    {}
                  );
                  navigate(`/checkin/${props.id}/${params.row.id}`);
                }}
                style={{ marginRight: "10px", width: "18px", height: "18px" }}
              />
            )}
            {canShowDialerConfigAction() && (
              <Tooltip title={"Setup Dialer Config"}>
                <SettingsPhone
                  color="primary"
                  onClick={(e) => {
                    setSelectedUserID(params.row.user_id);
                    setOpenDialerConfigPanel(true);
                  }}
                  style={{ width: "18px", height: "18px" }}
                />
              </Tooltip>
            )}
          </Row>
        );
      },
    },
  ];
  return (
    <>
      <Container>
        <AddBtnWrapper>
          <AddBoxOutlined
            onClick={() => {
              if (canDirectAddMember()) {
                analytics.triggerEvent(
                  4625000177,
                  "add_button_tap",
                  "project_members_page",
                  "add_button",
                  {}
                );
                setOpenAddMemberDirectly(true);
              } else {
                setOpen(true);
              }
            }}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </AddBtnWrapper>

        <Wrapper>
          <DetailsWrapper>
            <TableWrapper>
              <DataGrid
                autoHeight={true}
                rows={row}
                columns={MemberSettingColumns.concat(actionColumn)}
                checkboxSelection={false}
                style={{ cursor: "pointer" }}
                hideFooter={true}
                disableColumnMenu={true}
                loading={loading}
              />
            </TableWrapper>
          </DetailsWrapper>
        </Wrapper>
      </Container>
      {openEdit && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openEdit}
          onClose={() => {
            setOpenEdit(false);
          }}
        >
          <EditProjectMember
            setOpen={setOpenEdit}
            pid={props.id}
            open={openEdit}
            data={selectedRowData}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </Drawer>
      )}
      {open && (
        <GetMemberByPhoneModal
          id={props.id}
          open={open}
          setOpen={setOpen}
          setOpenMemberInfoModal={setOpenMemberInfoModal}
          phone={phone}
          setPhone={setPhone}
          setMemberData={setMemberData}
          setOpenNewUserModal={setOpenNewUserModal}
          // refresh={refresh}
          // setRefresh={setRefresh}
          // existUsers={row}
        />
      )}
      {openMemberInfoModal && memberData && (
        <MemberDetailModal
          setOpen={setOpenMemberInfoModal}
          open={openMemberInfoModal}
          data={memberData}
          setOpenFinal={setOpenFinalScreen}
        />
      )}
      {openFinalScreen && (
        <AddMemberModal
          setOpen={setOpenFinalScreen}
          open={openFinalScreen}
          existData={memberData}
          data={newUser}
          id={newUserId}
          pid={props.id}
          refresh={refresh}
          setUser={setNewUser}
          setRefresh={setRefresh}
          setId={setNewUserId}
          setOpenMemberInfoModal={setOpenMemberInfoModal}
          setOpenMobileDialogue={setOpen}
        />
      )}
      {openAddMemberDirectly && row && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openAddMemberDirectly}
          onClose={() => {
            setOpenAddMemberDirectly(false);
          }}
        >
          <AddMemberDirectly
            id={props.id}
            open={openAddMemberDirectly}
            setOpen={setOpenAddMemberDirectly}
            refresh={refresh}
            setRefresh={setRefresh}
            existUsers={row}
          />
        </Drawer>
      )}
      {openNewUserModal && (
        <NewMemberAddModal
          open={openNewUserModal}
          setOpen={setOpenNewUserModal}
          user={newUser}
          setUser={setNewUser}
          setId={setNewUserId}
          setOpenFinal={setOpenFinalScreen}
          phone={phone}
          pid={props.id}
        />
      )}

      {showErrorDialog && (
        <CustomErrorMessageDialog
          open={showErrorDialog}
          setOpen={setShowErrorDialog}
          message={errorMessage}
        />
      )}
      {showSuccessDialog && (
        <SuccessMessageDialog
          open={showSuccessDialog}
          setOpen={setShowSuccessDialog}
          message={successMessage}
        />
      )}
      {showConfirmAlertDialog && (
        <CustomErrorAlertWithContinue
          open={showConfirmAlertDialog}
          setOpen={setShowConfirmAlertDialog}
          errorMessage={errorMessage}
          confirmMessage={""}
          handleConfirm={() => {
            handleConfirm();
          }}
        />
      )}
      {openDialerConfigPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openDialerConfigPanel}
          onClose={() => {
            setOpenDialerConfigPanel(false);
          }}
        >
          <DialerConfigSidepanel
            setOpen={setOpenDialerConfigPanel}
            setRefresh={setRefresh}
            userID={selectedUserID}
            setShowConfirmationDialog={
              setShowRemoveDialerCredConfirmationDialog
            }
          />
        </Drawer>
      )}

      {openSubmitResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={openSubmitResponseDialog}
          setOpen={setOpenSubmitResponseDialog}
          successMessage={successMessage}
          errorMessage={errorMessage}
          loading={fetchingApi}
          isError={isRemoveApiFailed}
        />
      )}
      {showRemoveDialerCredeConfirmationDialog && (
        <CustomConfirmationDialog
          message={"Are you sure you want to delete the dialer credentials?"}
          open={showRemoveDialerCredeConfirmationDialog}
          setOpen={setShowRemoveDialerCredConfirmationDialog}
          handleConfirm={() => {
            handleRemoveDialerCredentials();
          }}
        />
      )}
    </>
  );
};

export default ProjectMemberTab;
