import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Link,
  List,
  ListItem,
  Button,
  IconButton,
} from "@mui/material";
import Markdown from "markdown-to-jsx";
import { EmojiConvertor } from "emoji-js";
import PhoneIcon from "@mui/icons-material/Phone";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { consoleLogger } from "../../Functions";
import image_placeholder from "../../assets/logo_placeholder.png";
import { Add } from "@material-ui/icons";
import { Cancel } from "@material-ui/icons";
import ReplyIcon from "@mui/icons-material/Reply";
import moment from "moment";

const emoji = new EmojiConvertor();
emoji.replace_mode = "unified";
emoji.allow_native = true;

const MessageBox = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
  overflowWrap: "anywhere",
  width: "320px",
  fontSize: "16px",
  backgroundColor: "#d9fdd3",
  padding: "5px 3px 5px 3px",
  marginBottom: "5px",
  marginRight: "10px",
  borderRadius: "10px",
  position: "relative",
  "&:after": {
    content: '""',
    width: 0,
    height: 0,
    position: "absolute",
    right: "-14px",
    top: "0px",
    border: "14px solid",
    borderTopRightRadius: "3px",
    borderColor: "#d9fdd3 transparent transparent transparent",
  },
}));

const ImageBoxWrapper = styled("div")`
  width: 100%;
  position: relative;
  // aspect-ratio: 1 / 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 5px;
`;

const ImageBox = styled("img")`
  width: 100%;
  height: 160px;
  object-fit: cover;
`;

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#a6a6a6",
  marginBottom: theme.spacing(1),
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  marginBottom: theme.spacing(1),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
  display: "inline",
}));

const Bold = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  display: "inline",
}));

const Italic = styled(Typography)(({ theme }) => ({
  fontStyle: "italic",
  display: "inline",
}));

const Strikethrough = styled(Typography)(({ theme }) => ({
  textDecoration: "line-through",
  display: "inline",
}));

const Monospace = styled(Typography)(({ theme }) => ({
  fontFamily: "monospace",
  display: "inline",
}));

const Quote = styled(Box)(({ theme }) => ({
  borderLeft: `4px solid ${theme.palette.grey[500]}`,
  padding: theme.spacing(1, 2),
  margin: theme.spacing(2, 0),
  color: theme.palette.text.secondary,
}));

const BulletedList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const NumberedList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

const InlineCode = styled(Typography)(({ theme }) => ({
  fontFamily: "monospace",
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(0.5),
  borderRadius: "4px",
  display: "inline",
}));
const MessageVideo = styled("video")(({ theme }) => ({
  width: "100%",
  height: "auto",
  borderRadius: "10px",
  backgroundColor: "#000",
}));

const Hour = styled(Box)(({ theme }) => ({
  fontSize: "11px",
  lineHeight: "14px",
  whiteSpace: "nowrap",
  opacity: 0.6,
}));

const ListItemStyled = styled(ListItem)(({ theme, type }) => ({
  padding: 0,
  display: "list-item",
  listStyleType: type === "ol" ? "decimal" : "disc",
}));

const ListContext = React.createContext("ul");

const ListItemComponent = ({ children }) => {
  const parentType = React.useContext(ListContext);
  return <ListItemStyled type={parentType}>{children}</ListItemStyled>;
};

const components = {
  p: ({ children }) => {
    return <Paragraph>{children}</Paragraph>;
  },
  a: ({ children, href }) => {
    return <StyledLink href={href}>{children}</StyledLink>;
  },
  em: ({ children }) => {
    return <Italic>{children}</Italic>;
  },
  strong: ({ children }) => {
    return <Bold>{children}</Bold>;
  },
  del: ({ children }) => {
    return <Strikethrough>{children}</Strikethrough>;
  },
  ul: ({ children }) => {
    return (
      <ListContext.Provider value="ul">
        <BulletedList>{children}</BulletedList>
      </ListContext.Provider>
    );
  },
  ol: ({ children }) => {
    return (
      <ListContext.Provider value="ol">
        <NumberedList>{children}</NumberedList>
      </ListContext.Provider>
    );
  },
  li: ListItemComponent,
  blockquote: ({ children }) => {
    return <Quote>{children}</Quote>;
  },
  code: ({ children }) => {
    return <InlineCode>{children}</InlineCode>;
  },
};

const WhatsappSendTemplateMessagePreview = ({
  data,
  variableDetails,
  headerVariableField,
  selectedFile,
  setSelectedFile,
  handlePhotoAdd,
}) => {
  const inputRef = useRef(null);

  const getMessageTime = (messageData) => {
    if (messageData.status === null) {
      return messageData.created_at;
    }
    if (messageData.status === "read" && messageData.read_at !== null) {
      return messageData.read_at;
    } else if (
      messageData.status === "delivered" &&
      messageData.delivered_at !== null
    ) {
      return messageData.delivered_at;
    } else if (messageData.sent_at !== null) {
      return messageData.sent_at;
    }
    return messageData.created_at;
  };

  const DoubleCheck = ({ fillColor }) => {
    return (
      <svg viewBox="0 0 16 15" width="16" height="15" className="double-check">
        <path
          fill={fillColor}
          d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
        ></path>
      </svg>
    );
  };

  const shouldHandleItalic = (str) => /^:.+:$/.test(str.trim()); //only if the input is not emoji, do italic -> :alarm_clock: (starts and ends with :)

  const formatWhatsappMarkdown = (markdown = "") => {
    markdown = markdown.replace(/(?<!\*)\*(\S.*?\S|\S)\*(?!\*)/g, "**$1**"); //bold

    if (shouldHandleItalic(markdown)) {
      markdown = markdown.replace(/(?<!\n)_(.*?)_(?!\s*\n)/g, "*$1*"); // italic
    }

    markdown = markdown.replace(/~(.*?)~/g, "~~$1~~"); // Strikethrough

    markdown = markdown.replace(/```(.*?)```/g, "`$1`"); // Monospace

    markdown = markdown.replace(/\n/g, "  \n"); // markdown requires two spaces at the end for line breaks
    markdown = markdown.replace(/\n\s*\n/g, "\n\n"); // to handle paragraphs after list items
    return emoji.replace_colons(markdown);
  };

  const getComponentByType = (type) => {
    return data.filter((x) => x.type.toLowerCase() === type.toLowerCase());
  };

  const replacePlaceholders = (string, values) => {
    if (Array.isArray(values) && typeof values[0] === "object") {
      return string.replace(/\{\{(\d+)\}\}/g, (_, index) => {
        const valueIndex = values.findIndex((item) => item.id === parseInt(index));
        if (valueIndex !== -1) {
          if (values[valueIndex]["value"] !== undefined && values[valueIndex]["value"] !== "") {
            return values[valueIndex]["value"];
          } else {
            if (values[valueIndex]["contact_column"]) {
              return values[valueIndex]["name"];
            }
            return `{{${index}}}`;
          }
        }
        return `{{${index}}}`;
      });
    } else {
      return string.replace(/\{\{(\d+)\}\}/g, (_, index) => {
        const value = values[parseInt(index)];
        return value !== undefined && value !== "" ? value : `{{${index}}}`;
      });
    }
  };

  const RenderHeader = () => {
    const headerData = getComponentByType("header");

    return (
      <>
        {headerData.map((header, index) => {
          if (header.format.toLowerCase() === "text") {
            return (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flexStart",
                  padding: "4px",
                }}
              >
                <Bold
                  sx={{
                    color: "#474747",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                  }}
                  key={index}
                >
                  {header.text}
                </Bold>
              </Box>
            );
          } else if (header.format.toLowerCase() === "video") {
            return (
              <ImageBoxWrapper>
                <ImageBox
                  key={index}
                  src={
                    selectedFile !== null
                      ? URL.createObjectURL(selectedFile[0])
                      : header.example.header_handle[0]
                  }
                  alt="loading..."
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = image_placeholder;
                  }}
                >
                  {header.text}
                </ImageBox>
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {selectedFile === null ? (
                    <Button
                      style={{
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                      color="primary"
                      variant="text"
                      onClick={() => {
                        inputRef.current.click();
                      }}
                    >
                      <Add /> Add {headerVariableField.name}
                    </Button>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setSelectedFile(null);
                      }}
                      color="error"
                      sx={{
                        position: "absolute",
                        top: "8px", // Adjust for top-right corner spacing
                        right: "8px",
                        padding: "4px",
                        zIndex: 2, // Ensure the icon is above other elements
                      }}
                    >
                      <Cancel />
                    </IconButton>
                  )}
                  <input
                    style={{ display: "none" }}
                    type="file"
                    ref={inputRef}
                    accept=".avif,.m4v,.mp4,.3gp,.mov"
                    onClick={() => {
                      inputRef.current.value = "";
                    }}
                    onChange={(event) => handlePhotoAdd(event)}
                  />
                </Box>
              </ImageBoxWrapper>
            );
          } else if (header.format.toLowerCase() === "image") {
            return (
              <ImageBoxWrapper>
                <ImageBox
                  key={index}
                  src={
                    selectedFile !== null
                      ? URL.createObjectURL(selectedFile[0])
                      : image_placeholder
                  }
                  alt="loading..."
                >
                  {header.text}
                </ImageBox>
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {selectedFile === null ? (
                    <Button
                      style={{
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                      color="primary"
                      variant="text"
                      onClick={() => {
                        inputRef.current.click();
                      }}
                    >
                      <Add /> Add {headerVariableField.name}
                    </Button>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setSelectedFile(null);
                      }}
                      color="error"
                      sx={{
                        position: "absolute",
                        top: "8px", // Adjust for top-right corner spacing
                        right: "8px",
                        padding: "4px",
                        zIndex: 2, // Ensure the icon is above other elements
                      }}
                    >
                      <Cancel />
                    </IconButton>
                  )}
                  <input
                    style={{ display: "none" }}
                    type="file"
                    ref={inputRef}
                    accept=".xbm,.tiff,.tif,.ico,.gif,.svg,.jpeg,.jpg,.svgz,.webp,.png,.bmp,.pjp,.apng,.avif"
                    onClick={() => {
                      inputRef.current.value = "";
                    }}
                    onChange={(event) => handlePhotoAdd(event)}
                  />
                </Box>
              </ImageBoxWrapper>
            );
          }
          return null;
        })}
      </>
    );
  };

  const RenderBody = () => {
    let text = "";
    let bodyData = [];
    let footerData = [];

    if (data.length > 0) {
      bodyData = getComponentByType("body")[0];
      footerData = getComponentByType("footer")[0]
        ? getComponentByType("footer")[0]
        : [];
      text = bodyData.text ? bodyData.text : "";

      //replace placeholders with variables from payload if present
      text = replacePlaceholders(text, variableDetails);
    }
    return (
      <>
        <Box sx={{ paddingX: "4px" }}>
          <Markdown
            options={{
              overrides: components,
              forceBlock: true,
            }}
          >
            {formatWhatsappMarkdown(text)}
          </Markdown>
          {footerData.length !== 0 && (
            <FooterText>{footerData.text}</FooterText>
          )}
        </Box>
      </>
    );
  };

  const RenderButtons = () => {
    const buttonData = getComponentByType("buttons")[0]
      ? getComponentByType("buttons")[0]
      : [];

    return buttonData.length !== 0 ? (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {buttonData.buttons?.map((button, index) => {
          return (
            <Box
              key={index}
              sx={{
                width: index !== 0 ? "95%" : "100%",
                height: "45px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "4px",
                borderTop: "1px solid rgba(166, 166, 166, .5)",
              }}
            >
              {button.type.toLowerCase() === "url" && (
                <>
                  <OpenInNewIcon sx={{ color: "#3db7f6", fontSize: "16px" }} />
                  <a
                    key={index}
                    style={{
                      textDecoration: "none",
                      color: "#3db7f6",
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {button.text}
                  </a>
                </>
              )}
              {button.type.toLowerCase() === "phone_number" && (
                <>
                  <PhoneIcon sx={{ color: "#3db7f6", fontSize: "16px" }} />
                  <a
                    key={index}
                    style={{
                      textDecoration: "none",
                      color: "#3db7f6",
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {button.text}
                  </a>
                </>
              )}
              {button.type.toLowerCase() === "quick_reply" && (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ReplyIcon sx={{ color: "#3db7f6", fontSize: "16px" }} />
                  <Typography
                    key={index}
                    style={{
                      height: "100%",
                      textDecoration: "none",
                      color: "#3db7f6",
                      fontSize: "14px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {" "}
                    {button.text}
                  </Typography>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    ) : null;
  };

  const getStatusTickComponent = () => {
    return <DoubleCheck fillColor={"#89a187"} />;
  };

  return (
    <MessageBox>
      <Box sx={{ paddingX: "5px", position: "relative" }}>
        <RenderHeader />
        <RenderBody />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {moment(getMessageTime(data)).isValid() && (
            <Hour>{moment(getMessageTime(data)).format("hh:mm A")}</Hour>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginLeft: "2px",
            }}
          >
            {getStatusTickComponent()}
          </Box>
        </Box>
      </Box>

      <RenderButtons />
    </MessageBox>
  );
};

export default WhatsappSendTemplateMessagePreview;
