import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Link,
  List,
  ListItem,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Markdown from "markdown-to-jsx";
import moment from "moment";
import WhatsappTemplateMessageComponent from "./WhatsappTemplateMessageComponent";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { consoleLogger, dataRenderer } from "../../Functions";
import image_placeholder from "../../assets/logo_placeholder.png";
import { CardActionArea } from "@material-ui/core";
import { getWhatsappLeadChatMediaApi } from "../../Api";
import DownloadIcon from "@mui/icons-material/Download";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";

const MessageImage = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
}));

const MessageBoxRight = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
  overflowWrap: "anywhere",
  width: "320px",
  fontSize: "16px",
  backgroundColor: "#d9fdd3",
  padding: "5px 3px 5px 3px",
  marginBottom: "5px",
  marginRight: "10px",
  borderRadius: "10px",
  position: "relative",
  "&:after": {
    content: '""',
    width: 0,
    height: 0,
    position: "absolute",
    right: "-14px",
    top: "0px",
    border: "15px solid",
    borderTopRightRadius: "3px",
    borderColor: "#d9fdd3 transparent transparent transparent",
  },
}));
const MessageBoxLeft = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
  overflowWrap: "anywhere",
  width: "320px",
  fontSize: "16px",
  backgroundColor: "#ffffff",
  padding: "5px 3px 5px 3px",
  marginBottom: "5px",
  marginLeft: "10px",
  borderRadius: "10px",
  position: "relative",
  "&:after": {
    content: '""',
    width: 0,
    height: 0,
    position: "absolute",
    left: "-14px",
    top: "0px",
    border: "15px solid",
    borderTopLeftRadius: "3px",
    borderColor: "#ffffff transparent transparent transparent",
  },
}));
const Paragraph = styled(Typography)(({ theme }) => ({
  fontSize: "15px",
  marginBottom: theme.spacing(1),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
  display: "inline",
}));

const Bold = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  display: "inline",
}));

const Italic = styled(Typography)(({ theme }) => ({
  fontStyle: "italic",
  display: "inline",
}));

const Strikethrough = styled(Typography)(({ theme }) => ({
  textDecoration: "line-through",
  display: "inline",
}));

const Monospace = styled(Typography)(({ theme }) => ({
  fontFamily: "monospace",
  display: "inline",
}));

const Quote = styled(Box)(({ theme }) => ({
  borderLeft: `4px solid ${theme.palette.grey[500]}`,
  padding: theme.spacing(1, 2),
  margin: theme.spacing(2, 0),
  color: theme.palette.text.secondary,
}));

const BulletedList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const NumberedList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  padding: 0,
  display: "list-item",
  listStyleType: "disc",
}));

const InlineCode = styled(Typography)(({ theme }) => ({
  fontFamily: "monospace",
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(0.5),
  borderRadius: "4px",
  display: "inline",
}));

const Hour = styled(Box)(({ theme }) => ({
  fontSize: "10px",
  lineHeight: "15px",
  whiteSpace: "nowrap",
  opacity: 0.6,
}));
const MediaWrapper = styled(CardActionArea)(({ theme }) => ({
  width: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  borderRadius: "10px",
  backgroundColor: "white",
  marginBottom: "5px",
  position: "relative",
}));

const ImageBox = styled("img")(({ theme }) => ({
  width: "100%",
  height: "160px",
  objectFit: "cover",
}));
const MessageVideo = styled("video")(({ theme }) => ({
  width: "100%",
  height: "auto",
  borderRadius: "10px",
  backgroundColor: "#000",
}));

const components = {
  p: ({ children }) => <Paragraph>{children}</Paragraph>,
  a: ({ children, href }) => <StyledLink href={href}>{children}</StyledLink>,
  em: ({ children }) => <Italic>{children}</Italic>,
  strong: ({ children }) => <Bold>{children}</Bold>,
  del: ({ children }) => <Strikethrough>{children}</Strikethrough>,
  ul: ({ children }) => <BulletedList>{children}</BulletedList>,
  ol: ({ children }) => <NumberedList>{children}</NumberedList>,
  li: ({ children }) => <ListItemStyled>{children}</ListItemStyled>,
  blockquote: ({ children }) => <Quote>{children}</Quote>,
  code: ({ children }) => <InlineCode>{children}</InlineCode>,
};

const mediaCache = new Map(); //caching media with media_id

const WhatsappChatMessageComponent = ({ data, templateList, id }) => {
  const [mediaLoaded, setMediaLoaded] = useState(false);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [mediaURL, setMediaURL] = useState("");

  useEffect(() => {
    //preloading cache media
    if (data && data.message) {
      let mediaID = "";
      let messageType = data.message_type; //sometimes message_type is null
      if (
        messageType &&
        (messageType === "image" ||
          messageType === "video" ||
          messageType === "document")
      ) {
        if (
          data.whatsapp_template_id !== null &&
          data.whatsapp_template_id !== ""
        ) {
          const headerData = getComponentByType("header");
          if (headerData) {
            const index = headerData["parameters"].findIndex(
              (item) => item.type === "image" || item.type === "video"
            );

            if (index !== -1) {
              messageType = headerData["parameters"][index]["type"];

              const messageData = headerData["parameters"][index][messageType];
              mediaID = messageData?.hasOwnProperty("id") ? messageData.id : "";
            }
          } else {
            setMediaLoaded(true);
            return;
          }
        } else {
          mediaID =
            data.direction === "outbound"
              ? data.message[messageType]?.id
              : data.message.id;
        }

        if (mediaCache.has(mediaID)) {
          setMediaURL(mediaCache.get(mediaID));
          setMediaLoaded(true);
          consoleLogger("loaded cache");
        }
      }
    }
  }, []);

  const getMessageTime = (messageData) => {
    if (messageData.status === null) {
      return messageData.created_at;
    }
    if (messageData.status === "read" && messageData.read_at !== null) {
      return messageData.read_at;
    } else if (
      messageData.status === "delivered" &&
      messageData.delivered_at !== null
    ) {
      return messageData.delivered_at;
    } else if (messageData.sent_at !== null) {
      return messageData.sent_at;
    }
    return messageData.created_at;
  };

  const getMessageTypeRenderText = (messageType) => {
    if (!messageType) {
      return "Unknown"; // for null or undefined message types
    }
    return (
      messageType.charAt(0).toUpperCase() + messageType.slice(1).toLowerCase()
    ); //sentence case conversion
  };

  const getMessageText = (messageType) => {
    if (messageType === null) {
      return data.message.text;
    } else if (messageType === "text") {
      if (data.message.hasOwnProperty("body")) {
        return data.message.body;
      }
      return data.message.text;
    } else if (messageType === "button") {
      if (data.message.hasOwnProperty("body")) {
        return data.message.body;
      }
      return data.message.text;
    }
    return data.message?.text;
  };

  const getComponentByType = (type) => {
    if (Array.isArray(data["message_parameters"])) {
      return data["message_parameters"].find(
        (x) => x.type.toLowerCase() === type
      );
    }
    return null;
  };

  const loadMessageMedia = async (type = "regular") => {
    setMediaLoading(true);
    let mediaID = "";
    let messageType = data.message_type; //sometimes message_type is null

    if (type === "header") {
      const headerData = getComponentByType("header");
      const index = headerData["parameters"].findIndex(
        (item) => item.type === "image" || item.type === "video"
      );

      if (index !== -1) {
        messageType = headerData["parameters"][index]["type"];

        const messageData = headerData["parameters"][index][messageType];
        mediaID = messageData?.hasOwnProperty("id") ? messageData.id : "";
      }
    } else {
      mediaID =
        data.direction === "outbound"
          ? data.message[messageType]?.id
          : data.message.id;
    }

    // using mediaCache if it is available for media id
    if (mediaCache.has(mediaID)) {
      const cachedMediaURL = mediaCache.get(mediaID);
      if (messageType === "document") {
        await downloadDocument(cachedMediaURL);
        setMediaLoaded(true);
        setMediaLoading(false);
        return;
      }
      setMediaURL(cachedMediaURL);
      setMediaLoaded(true);
      setMediaLoading(false);
      consoleLogger("used cache for mediaID:: ", mediaID);

      return;
    }

    let queryParams = {
      media_id: mediaID,
      media_type: messageType,
      wm_id: data.id,
    };
    const response = await getWhatsappLeadChatMediaApi({
      leadID: id,
      queryParams: queryParams,
    });

    let mimeType;
    switch (messageType) {
      case "image":
        mimeType = "image/jpeg";
        break;
      case "video":
        mimeType = "video/mp4";
        break;
      case "document":
        mimeType = "application/pdf"; //only pdf document allowed for now
        break;
      default:
        mimeType = "image/jpeg";
        break;
    }

    const mediaBlob = new Blob([response], { type: mimeType });
    const mediaObjectURL = URL.createObjectURL(mediaBlob);

    // storing the local media url to mediaCache
    mediaCache.set(mediaID, mediaObjectURL);

    // Download if document and return
    if (messageType === "document") {
      await downloadDocument(mediaObjectURL);
      setMediaLoaded(true);
      setMediaLoading(false);
      return;
    }
    setMediaURL(mediaObjectURL);
    setMediaLoaded(true);
    setMediaLoading(false);
  };

  const downloadDocument = async (mediaURL) => {
    const link = document.createElement("a");
    link.href = mediaURL;

    const fileName = `document_${Date.now()}.pdf`;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderMessageContent = () => {
    return (
      <Box sx={{ paddingX: "4px", width: "100%" }}>
        {data?.user?.name && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent:
                data.direction === "outbound" ? "flex-end" : "flex-start",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                color: data.direction === "outbound" ? "#075E54" : "#f37442",
              }}
            >
              {dataRenderer(data.user.name)}
            </Typography>
          </Box>
        )}

        {/* Message Content */}
        {(() => {
          if (data.message === null) {
            return (
              <MessageUnavailableComponent
                errorText={
                  "This message couldn't be displayed.\n(Error Code: 1022)"
                }
              />
            );
          }

          switch (data.message_type) {
            case "image":
              return (
                <MediaWrapper onClick={loadMessageMedia} disabled={mediaLoaded}>
                  <MessageImage
                    src={mediaLoaded ? mediaURL : image_placeholder}
                    alt="image"
                  />
                  {(mediaLoading || !mediaLoaded) && (
                    <Box
                      sx={{
                        position: "absolute",
                        width: 54,
                        height: 54,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {mediaLoading ? (
                        <CircularProgress style={{ color: "white" }} />
                      ) : (
                        <DownloadIcon style={{ color: "#fff" }} />
                      )}
                    </Box>
                  )}
                </MediaWrapper>
              );

            case "video":
              return (
                <>
                  <MediaWrapper
                    onClick={loadMessageMedia}
                    disabled={mediaLoaded}
                    sx={{
                      height: mediaLoaded ? "auto" : "200px",
                      backgroundColor: "#c9c9c9",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        width: 54,
                        height: 54,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {mediaLoading ? (
                        <CircularProgress style={{ color: "white" }} />
                      ) : (
                        <PlayCircleOutlineIcon style={{ color: "#fff" }} />
                      )}
                    </Box>
                  </MediaWrapper>
                  {mediaLoaded && (
                    <MessageVideo controls>
                      <source src={mediaURL} type="video/mp4" />
                      Your browser does not support the video tag.
                    </MessageVideo>
                  )}
                </>
              );

            case "document":
              return (
                <Box
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    padding: "12px",
                    borderRadius: "6px",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <InsertDriveFileIcon fontSize="large" color="primary" />
                      <Box>
                        <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                          Document
                        </Typography>
                        <Typography sx={{ fontSize: "12px", color: "gray" }}>
                          PDF
                        </Typography>
                      </Box>
                    </Box>
                    <IconButton size="small" onClick={loadMessageMedia}>
                      {mediaLoading ? (
                        <CircularProgress size="22px" color="primary" />
                      ) : (
                        <DownloadForOfflineOutlinedIcon />
                      )}
                    </IconButton>
                  </Box>
                </Box>
              );

            case "text":
              return (
                <Markdown
                  options={{
                    overrides: components,
                    forceBlock: true,
                  }}
                >
                  {getMessageText(data.message_type)}
                </Markdown>
              );

            // unsupported message case
            default:
              return (
                <MessageUnavailableComponent
                  errorText={`${getMessageTypeRenderText(
                    data.message_type
                  )} message type isn't currently supported.`}
                />
              );
          }
        })()}

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {moment(getMessageTime(data)).isValid() && (
            <Hour>{moment(getMessageTime(data)).format("hh:mm A")}</Hour>
          )}
          {data.direction === "outbound" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginLeft: "2px",
              }}
            >
              {getStatusTickComponent()}
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const MessageUnavailableComponent = ({ errorText }) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          width: "100%",
          padding: "10px",
          color: "#333",
          fontFamily: "Arial, sans-serif",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            width: "45px",
            height: "45px",
            minWidth: "45px",
            minHeight: "45px",
            borderRadius: "50%",
            backgroundColor: "#77939f",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <SmsFailedIcon sx={{ color: "#fff", fontSize: "22px" }} />
        </Box>

        <Box>
          <Typography
            sx={{ fontWeight: "600", color: "#5d6164", fontSize: "14px" }}
          >
            Message Unavailable
          </Typography>
          <Typography sx={{ color: "#5d6164", fontSize: "12px" }}>
            {errorText}
          </Typography>
        </Box>
      </Box>
    );
  };

  const getStatusTickComponent = () => {
    if (data.id === "") {
      return <ErrorIcon style={{ fontSize: 13, opacity: 0.6, color: "red" }} />; //some error if id is empty
    }
    if (data.status === null) {
      return <DoneIcon style={{ fontSize: 16, opacity: 0.6 }} />; //not delivered
    } else {
      if (data.status === "delivered") {
        return <DoneAllIcon style={{ fontSize: 16, opacity: 0.6 }} />;
      } else if (data.status === "read") {
        return <DoneAllIcon style={{ fontSize: 16, color: "#53bdeb" }} />;
      } else if (data.status === "sent") {
        return <DoneIcon style={{ fontSize: 16, opacity: 0.6 }} />;
      } else if (data.status === "waiting") {
        return <AccessTimeIcon style={{ fontSize: 12, opacity: 0.6 }} />; //before refetching
      }
    }
  };

  const getWhatsappTemplate = (templateID) => {
    const index = templateList.findIndex(
      (template) => template.id === templateID
    );
    if (index !== -1) {
      return templateList[index];
    }
    return {};
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent:
          data.direction === "outbound" ? "flex-end" : "flex-start",
      }}
    >
      {data.whatsapp_template_id !== null &&
      data.whatsapp_template_id !== "" ? (
        <WhatsappTemplateMessageComponent
          components={getWhatsappTemplate(data.whatsapp_template_id).components}
          data={data}
          image={mediaLoaded ? mediaURL : null}
          handleLoadImage={() => {
            loadMessageMedia("header");
          }}
          mediaLoaded={mediaLoaded}
          mediaLoading={mediaLoading}
        />
      ) : data.direction === "outbound" ? (
        <MessageBoxRight>{renderMessageContent()}</MessageBoxRight>
      ) : (
        <MessageBoxLeft>{renderMessageContent()}</MessageBoxLeft>
      )}
    </Box>
  );
};

export default WhatsappChatMessageComponent;
