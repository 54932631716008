import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  AppBar,
  Toolbar,
} from "@mui/material";
import { dataRenderer, getLocalizedText } from "../../Functions";
import { styled } from "@mui/system";
import { Divider } from "@material-ui/core";

const Header = styled(Typography)({
  margin: 0,
  fontWeight: 600,
  fontSize: "20px",
  fontFamily: "Open Sans",
});
const Message = styled(Typography)({
  margin: 0,
  fontSize: "12px",
  fontFamily: "Open Sans",
});

const CampaignStartConfirmationModal = ({
  type,
  itemCount,
  open,
  onClose,
  onConfirm,
  hasSegment,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (open) {
      setIsChecked(false);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          bottom: "0px",
          left: "0px",
          right: "0px",
          margin: "auto",
          height: "fit-content",
          width: "680px",
          border: "none",
          outline: "none",
          backgroundColor: "white",
          boxShadow: "0px 3px 6px #0000000d",
          borderRadius: "8px",
          padding: "30px",
          border: "1px solid #c5d7f1",
          boxShadow: "0px 3px 20px #185dd21f",
        }}
      >
        <Header>
          {" "}
          {type === "email"
            ? `Confirmation: Sending emails${
                hasSegment ? ` to ${dataRenderer(itemCount)}` : ""
              } ${
                hasSegment
                  ? itemCount === 1
                    ? getLocalizedText("lead", "project").toLowerCase()
                    : getLocalizedText("leads", "project").toLowerCase()
                  : ""
              }`
            : `Confirmation: Running campaign${
                hasSegment ? ` for ${dataRenderer(itemCount)}` : ""
              } ${
                hasSegment
                  ? itemCount === 1
                    ? getLocalizedText("lead", "project").toLowerCase()
                    : getLocalizedText("leads", "project").toLowerCase()
                  : ""
              }`}
        </Header>
        <Divider style={{ margin: "10px 0px" }} />

        <Box
          sx={{
            border: "4px solid #ffeebf",
            borderRadius: "4px",
            backgroundColor: "#ffeebf",
            padding: "8px",
            mt: 2,
          }}
        >
          <Header sx={{ fontSize: "14px", mb: "4px" }}>
            Compliance Confirmation
          </Header>

          <Message>
            I confirm that this campaign complies with all applicable laws and
            regulations. I will not send spam, messages to opted-out contacts,
            or engage in illegal broadcasts. I understand that I am solely
            responsible for this campaign, and any violations may result in
            penalties or account suspension.
          </Message>
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              size="small"
            />
          }
          label={
            <Typography sx={{ fontSize: "12px" }}>
              I agree to the compliance terms mentioned above
            </Typography>
          }
          sx={{ mt: 1 }}
        />
        <AppBar
          position="static"
          color="inherit"
          elevation={0}
          sx={{
            top: "auto",
            bottom: 0,
            width: "100%",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <Toolbar
            variant="dense"
            disableGutters
            sx={{
              display: "flex",
              alignItems: "flex-end",
              width: "100%",
              justifyContent: "flex-start",

              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                width: "100%",
                justifyContent: "flex-end",
                gap: "16px",
              }}
            >
              {" "}
              <Button variant="text" onClick={onClose} color="inherit">
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={onConfirm}
                color="primary"
                disabled={!isChecked}
              >
                Confirm
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </Modal>
  );
};

export default CampaignStartConfirmationModal;
