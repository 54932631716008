import { getMcubeCallStatus, mcubeCall } from "../Api";
import ApiResult from "./apiResult";
import CallService from "./callService";

class MCubeCallService extends CallService {
  makeCall = async (leadID) => {
    try {
      let response = await mcubeCall(leadID);
      return response.data;
    } catch (error) {
      // let responseData = error.response.data;
      // switch (responseData["dailer_resp"]["message"]) {
      //   case "DND":
      //     return {
      //       message:
      //         "The customer's number has been designated for DND status.",
      //     };
      //   default:
      //     return {
      //       message: "Something went wrong! Please contact your administrator.",
      //     };
      // }
      return {
        message: "Something went wrong! Please contact your administrator.",
      };
    }
  };
  fetchCallStatus = async (formData) => {
    try {
      let response = await getMcubeCallStatus(formData);
      return response.data;
    } catch (error) {
      return {};
    }
  };
  setCallDisposition = async (leadID, formData) => {
    return new ApiResult("success", {}, 200);
  };
}

export default MCubeCallService;
