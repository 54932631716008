import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Modal,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button, makeStyles } from "@material-ui/core";
import rootStore from "../../stores/RootStore";
import { getLocalizedText } from "../../Functions";
import {
  editContactApi,
  editLeadApi,
  getDelhiveryTrackOrderApi,
} from "../../Api";
import { DataGrid } from "@mui/x-data-grid";
import { v4 as uuid } from "uuid";
import { myTheme } from "../../themeUtils";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  textAlign: "left",
  margin: "auto",
  backgroundColor: "white",
  height: "100%",
  width: "100%",
}));

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
  border: "1px solid #c5d7f1",
  borderRadius: "8px",
  padding: "20px",
  width: "550px",
  height: "fit-content",
  maxHeight: "95vh",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  overflow: "auto",
}));

const Message = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "12px",
  fontFamily: "Open Sans",
  color: "red",
  marginRight: "10px",
}));

const Text = styled(Typography)(({ theme }) => ({
  margin: "0px",
  font: "normal normal 600 12px Open Sans",
  color: "#4d4e4f",
}));
const Column = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));
const Header = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  color: "#4d4e4f",
}));
const MessageWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "12px",
  flexDirection: "column",
}));

const MultiDelhiveryTrackOrderDialog = (props) => {
  const { userStore, authStore } = rootStore;
  const configData = rootStore.userStore.getDelhiveryIntegrationsConfig();
  const columnsList = [...userStore.AllColumnsList];
  const [responseData, setResponseData] = useState([]);
  const [isSent, setIsSent] = useState(false);

  const responseColumnHeader = [
    {
      field: "target",
      headerName: <Text>{"Lead ID"}</Text>,
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title={params.row.target}>
              <span className="csutable-cell-trucate">
                {params.row.target !== null ? params.row.target : "-"}
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: <Text>Status</Text>,
      sortable: false,
      flex: 1,

      renderCell: (params) => {
        return (
          <>
            <Tooltip title={params.row.status}>
              <span className="csutable-cell-trucate">
                {params.row.status !== null ? params.row.status : "-"}
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const getWaybillNumber = (leadData) => {
    if (configData["order_response_mapping"].hasOwnProperty("waybill")) {
      let waybillColumnID = configData["order_response_mapping"].waybill;
      const index = columnsList.findIndex(
        (column) => column["id"] === waybillColumnID
      );

      if (index !== -1) {
        if (columnsList[index]["is_custom_param"]) {
          let custom_data = leadData.hasOwnProperty("custom_params")
            ? JSON.parse(leadData["custom_params"])
            : {};

          let newKey = columnsList[index]["field"].replace(
            "custom_params_",
            ""
          );
          return custom_data.hasOwnProperty(newKey) ? custom_data[newKey] : "";
        }
        return leadData.hasOwnProperty(columnsList[index]["field"])
          ? leadData.columnsList[index]["field"]
          : "";
      }
      return "";
    }
    return "";
  };

  const init = async () => {
    let statusResultData = [];
    for (const leadData of props.selectedLeadsData) {
      //check existing status from leadData before update
      let customData = leadData.hasOwnProperty("custom_params")
        ? JSON.parse(leadData["custom_params"])
        : {};

      let statusData = customData.hasOwnProperty("delhivery_status_response")
        ? JSON.parse(customData["delhivery_status_response"])
        : {};

      let deliveryStatus =
        Object.keys(statusData).length > 0 &&
        !statusData.hasOwnProperty("Error")
          ? statusData?.ShipmentData[0]?.Shipment?.["Status"]["Status"]
          : "";

      const waybillNumber = getWaybillNumber(leadData);
      //not calling the api if status is delivered or there is no waybill number
      if (
        deliveryStatus.toLowerCase() !== "delivered" &&
        waybillNumber !== ""
      ) {
        const queryParams = {
          waybill: waybillNumber,
        };
        const response = await getDelhiveryTrackOrderApi({
          leadID: leadData.id,
          queryParams: queryParams,
        });

        let normalFieldsPayload = {};
        let customFieldsPayload = {};
        let contactFieldsPayload = {};

        function checkAndAssign(key, value) {
          if (typeof value !== "object") {
            if (configData["order_status_mapping"].hasOwnProperty(key)) {
              let index = columnsList.findIndex(
                (column) =>
                  column["id"] === configData["order_status_mapping"][key]
              );
              if (index !== -1) {
                if (
                  columnsList[index]["contact_column"] !== null &&
                  columnsList[index]["contact_column"] !== ""
                ) {
                  contactFieldsPayload[columnsList[index]["field"]] = value;
                }
                if (columnsList[index]["is_custom_param"]) {
                  let new_key_name = columnsList[index]["field"].replace(
                    "custom_params_",
                    ""
                  );
                  customFieldsPayload[new_key_name] = value;
                } else {
                  normalFieldsPayload[columnsList[index]["field"]] = value;
                }
              }
            }
          }
        }

        //storing the whole response in delhivery_status_response column
        customFieldsPayload["delhivery_status_response"] =
          JSON.stringify(response);

        //update lead fields with mapping from integrations
        if (
          response.hasOwnProperty("ShipmentData") &&
          Array.isArray(response.ShipmentData) &&
          response.ShipmentData.length > 0
        ) {
          if (Object.keys(configData["order_status_mapping"]).length !== 0) {
            const shipment = response.ShipmentData[0]?.Shipment;

            // looping through top-level keys
            if (shipment && typeof shipment === "object") {
              Object.keys(shipment).forEach((shipmentKey) => {
                checkAndAssign(shipmentKey, shipment[shipmentKey]);
              });
            }

            // looping through packages array and objects nested inside
            const status = shipment?.Status;
            if (status && typeof status === "object") {
              Object.keys(status).forEach((statusKey) => {
                checkAndAssign(statusKey, status[statusKey]);
              });
            }
          }
        }

        //update contact if needed
        if (Object.keys(contactFieldsPayload).length > 0) {
          if (
            props.data["contact_id"] !== null &&
            props.data["contact_id"] !== ""
          ) {
            let response = await editContactApi({
              contactID: props.data["contact_id"],
              payload: contactFieldsPayload,
            });
          }
        }

        const updateLeadDetails = async () => {
          let payload = {
            ...normalFieldsPayload,
            custom_params: { ...customFieldsPayload },
          };
          try {
            let response = await editLeadApi({
              id: leadData.id,
              data: payload,
            });
            //status logic
            let leadSendStatus = {
              target: leadData.id,
              status: "success",
            };
            statusResultData.push(leadSendStatus);
          } catch (error) {
            let leadSendStatus = {
              target: leadData.id,
              status: "failure",
            };
            statusResultData.push(leadSendStatus);
          }
        };

        await updateLeadDetails();
      } else {
        if (waybillNumber === "") {
          let leadSendStatus = {
            target: leadData.id,
            status: "failure: no waybill number",
          };
          statusResultData.push(leadSendStatus);
        }
      }
    }
    setResponseData(statusResultData);
    setIsSent(true);
  };

  useEffect(() => {
    init();
  }, []);

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Container>
            <Box>
              {!isSent ? (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <CircularProgress style={{ width: "22px", height: "22px" }} />
                  <Message style={{ color: "#4D4E4F", fontSize: "16px" }}>
                    Getting order status...
                  </Message>
                </Box>
              ) : responseData.length > 0 ? (
                <Box style={{ width: "100%" }}>
                  <Column style={{ rowGap: "20px" }}>
                    <Header>{getLocalizedText("result")}</Header>
                    <div
                      style={{
                        width: "100%",
                        maxHeight: "40vh",
                        overflowY: "auto",
                      }}
                    >
                      <DataGrid
                        getRowId={() => uuid() + Math.random()}
                        autoHeight={true}
                        rows={responseData}
                        columns={responseColumnHeader}
                        checkboxSelection={false}
                        style={{ cursor: "pointer" }}
                        hideFooter={true}
                        disableColumnMenu={true}
                      />
                    </div>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleClose();
                      }}
                      style={{
                        width: "fit-content",
                        ...myTheme.Button.btnLight,

                        fontSize: "12px",
                        fontWeight: "bold",
                        margin: "auto",
                        textTransform: "none",
                      }}
                    >
                      {getLocalizedText("close")}
                    </Button>
                  </Column>
                </Box>
              ) : (
                <Box>
                  <MessageWrapper>
                    <Message
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    >
                      {getLocalizedText("no_response_data_to_show")}
                    </Message>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        props.setOpen(false);
                      }}
                      color="inherit"
                      style={{
                        width: "fit-content",

                        fontSize: "12px",
                        fontWeight: "bold",
                        margin: "auto",
                        textTransform: "none",
                      }}
                    >
                      {getLocalizedText("close")}
                    </Button>
                  </MessageWrapper>
                </Box>
              )}
            </Box>
          </Container>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(MultiDelhiveryTrackOrderDialog);
