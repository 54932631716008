import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";

const MultiCreatableSelectField = ({
  value = [],
  handleChange,
  isDisabled,
  isRequired,
  optionsList,
}) => {
  const [options, setOptions] = useState(optionsList);

  React.useEffect(() => {
    if (value) {
      const preselectedOptions = value
        .filter((val) => !options.some((option) => option.value === val))
        .map((val) => ({ label: val, value: val }));
      if (preselectedOptions.length > 0) {
        setOptions((prevOptions) => [...prevOptions, ...preselectedOptions]);
      }
    }
  }, []);

  const customStyles = {
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "12px",
    }),
    control: (provided) => ({
      ...provided,
      fontSize: "12px",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "12px",
    }),
    loadingMessage: (provided) => ({
      ...provided,
      fontSize: "12px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: "12px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "12px",
      color: "#4d4e4f",
    }),
  };

  const handleCreate = (inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    setOptions((prevOptions) => [...prevOptions, newOption]);
    handleChange([...value, inputValue]);
  };

  const handleSelectChange = (selectedOptions) => {
    handleChange(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
  };

  return (
    <Box sx={{ position: "relative" }}>
      <CreatableSelect
        menuPortalTarget={document.body}
        menuPosition="fixed"
        isMulti
        isDisabled={isDisabled}
        options={options}
        value={options.filter((option) => value?.includes(option.value))}
        onChange={handleSelectChange}
        onCreateOption={handleCreate}
        closeMenuOnSelect={false}
        styles={customStyles}
        isClearable
        required={isRequired}
      />
      {!isDisabled && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0, position: "absolute" }}
          value={(value || []).join(",")}
          required={isRequired}
          onChange={() => {}}
        />
      )}
    </Box>
  );
};

export default observer(MultiCreatableSelectField);
