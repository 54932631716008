import { Grid } from "@material-ui/core";
import {
  Box,
  MenuItem,
  Select,
  OutlinedInput,
  styled,
  Typography,
  FormControl,
} from "@mui/material";
import React from "react";

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const SourceScreen = ({
  details,
  userList,
  partnersList,
  handleChange,
  handleSetSourceType,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <Label>Source Type</Label>
          <Select
            id="source-type"
            name="source_type"
            value={details.source_type || ""}
            onChange={(e) => handleSetSourceType(e)}
            input={<OutlinedInput />}
            style={{
              width: "100%",
              height: "30px",
              fontSize: "12px",
              borderRadius: "4px",
            }}
          >
            <MenuItem value="internal">Internal</MenuItem>
            <MenuItem value="external">External</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {details.source_type === "internal" && (
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <Label>Internal User</Label>
            <Select
              name="internal_user"
              value={details.internal_user?.id || ""}
              displayEmpty
              onChange={(e) => {
                const selectedUser = userList.find(
                  (user) => user.id === e.target.value
                );
                handleChange({
                  target: { name: "internal_user", value: selectedUser },
                });
              }}
              style={{
                width: "100%",
                height: "30px",
                fontSize: "12px",
                borderRadius: "4px",
              }}
            >
              {userList.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {details.source_type === "external" && (
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <Label>External Partner</Label>
            <Select
              name="external_user"
              value={details.external_user?.id || ""}
              displayEmpty
              onChange={(e) => {
                const selectedUser = partnersList.find(
                  (user) => user.id === e.target.value
                );
                handleChange({
                  target: { name: "external_user", value: selectedUser },
                });
              }}
              style={{
                width: "100%",
                height: "30px",
                fontSize: "12px",
                borderRadius: "4px",
              }}
            >
              {partnersList.map((partner) => (
                <MenuItem key={partner.id} value={partner.id}>
                  {partner.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

export default SourceScreen;
