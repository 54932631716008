import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@material-ui/core";
import {
  AppBar,
  Box,
  CircularProgress,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {
  createLeadQuotationApi,
  editLeadQuotationApi,
  getProductsListApi,
  getQuotationNumberApi,
  getQuoteTemplateListApi,
  reviseLeadQuotationApi,
} from "../../Api";
import QuoteAndProuductInfo from "./screens/QuoteAndProuductInfo";
import AddProductModal from "./dialogs/AddProductModal";
import EditProductModal from "./dialogs/EditProductModal";
import ViewProductModal from "./dialogs/ViewProductModal";
import BillingInfo from "./screens/BillingInfo";
import AdditionalInfo from "./screens/AdditionalInfo";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import {
  consoleLogger,
  embedVariableValuesInMessageString,
  getLocalizedText,
} from "../../Functions";
import AddAreaBasedProductModal from "./dialogs/AddAreaBasedProductModal";
import EditAreaBasedItemModal from "./dialogs/EditAreaBasedItemModal";
import ViewAreaBasedProductModal from "./dialogs/ViewAreaBasedProductModal";
import { quotationTaxOptions } from "../../Db";
import AddProductLineItemModal from "./dialogs/AddProductLineItemModal";
import AddAreaBasedItemModal from "./dialogs/AddAreaBasedItemModal";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const WarningAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="warning"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 50vw;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  ${`height: calc(100vh - 128px);`};
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 24px;
  gap: 20px;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const AddQuoteSidePanel = ({
  setOpen,
  activeDealDetails,
  leadID,
  leadData,
  setRefresh,
  existingQuoteData,
  scope,
  setQuoteScope,
  selectedQuoteTemplate,
}) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [details, setDetails] = useState({
    title: "",
    quote_date: null,
    expiry_date: null,
    template_type: "template_basic",
  });
  const [openAddProductModal, setOpenAddProductModal] = useState(false);
  const [openEditProductModal, setOpenEditProductModal] = useState(false);
  const [openViewProductModal, setOpenViewProductModal] = useState(false);
  const [tableAdditionalColumns, setTableAdditionalColumns] = useState([]);
  const [selectedProductData, setSelectedProductData] = useState({});
  const [productAddedList, setProductAddedList] = useState([]);
  const [quoteList, setQuoteList] = useState([]);
  const [selectedQuoteID, setSelectedQuoteID] = useState("");
  const [quotationTemplateConfig, setQuotationTemplateConfig] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [quotationNumber, setQuotationNumber] = useState("");
  const [amountDetails, setAmountDetails] = useState({
    sub_total: "",
    additional_discount: "",
    additional_tax: "",
    adjustments: "",
    grand_total: "",
  });
  const [billingDetails, setBillingDetails] = useState({
    billing_name: "",
    billing_address: "",
    billing_city: "",
    billing_state: "",
    billing_country: "",
    billing_zipcode: "",
    billing_gst: "",
    shipping_name: "",
    shipping_address: "",
    shipping_city: "",
    shipping_state: "",
    shipping_country: "",
    shipping_zipcode: "",
  });
  const [additionalDetails, setAdditionalDetails] = useState({
    terms_and_conditions: "",
    additional_information: "",
  });
  const [placeOfSupply, setPlaceOfSupply] = useState("");
  const [showShippingAddress, setShowShippingAddress] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showAdditionalInformation, setShowAdditionalInformation] =
    useState(false);

  //tax reset handling on place_of_supply change
  const [showItemTaxWarning, setShowItemTaxWarning] = useState(false);
  const [showRequiredEmptyWarning, setShowRequiredEmptyWarning] =
    useState(false);
  const [itemTaxFlag, setItemTaxFlag] = useState(false);
  const [totalItemsReset, setTotalItemsReset] = useState(0);
  const [styleDetails, setStyleDetails] = useState({
    table_header: { background_color: "", text_color: "" },
    grand_total: { background_color: "", text_color: "" },
  });
  const [templateVersion, setTemplateVersion] = useState(null);
  const [productList, setProductList] = useState([]);
  const [defaultProductList, setDefaultProductList] = useState([]);
  const [openProductLineItemForm, setOpenProductLineItemForm] = useState(false);
  const [isEditProductLineItem, setIsEditProductLineItem] = useState(false);
  const [fullProductList, setFullProductList] = useState([]);

  const checkIfAllRequiredFieldsAreFilled = () => {
    return productAddedList.every((item) => item.rate && item.quantity);
  };

  const onSearchResetCallback = (searchResults) => {
    const newProductsList = defaultProductList.concat(searchResults);
    setProductList(newProductsList);
  };

  const getDefaultProductsList = () => {
    //setting default list to show show 10 items by default before search
    if (fullProductList.length > 0) {
      let productItems = fullProductList
        .filter((item) => !isProductAlreadyAdded(item.id))
        .slice(0, 10);

      setDefaultProductList(productItems);
      setProductList(productItems);
    } else {
      setDefaultProductList([]);
      setProductList([]);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setQuoteScope("");
  };
  const getValidIntegerValue = (value) => {
    if (value !== "" && value !== null) {
      return parseInt(value);
    }
    return "";
  };
  const getValidFloatValue = (value) => {
    if (value !== "" && value !== null) {
      const floatValue = parseFloat(value);
      return floatValue.toFixed(2);
    }
    return "";
  };

  const getCompanyDetails = () => {
    let index = quoteList.findIndex((quote) => quote["id"] === selectedQuoteID);
    return quoteList[index]["schema"]["company_details"];
  };

  const createQuote = async (payload) => {
    let response = await createLeadQuotationApi({
      payload: payload,
      leadID: leadID,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const editQuote = async (payload) => {
    let response = await editLeadQuotationApi({
      payload: payload,
      leadID: leadID,
      quoteID: existingQuoteData["id"],
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const handleSubmit = async () => {
    let formData = {
      quote_date: details.quote_date,
      expiry_date: details.expiry_date,
      quotation_template_id: selectedQuoteID,
    };
    if (activeDealDetails.hasOwnProperty("id")) {
      formData["deal_id"] = activeDealDetails["id"];
    }
    let tempQuotationSchema = {
      quote_title: details.title,
      styles: styleDetails,
      template_version: templateVersion,
    };
    tempQuotationSchema["additional_columns"] = tableAdditionalColumns;

    let itemList = [];

    productAddedList.forEach((productItem) => {
      if (details["template_type"] === "template_area_based") {
        let tempObj = {
          rate: getValidFloatValue(productItem["rate"]),
          amount: getValidFloatValue(productItem["amount"]),
          quantity: getValidIntegerValue(productItem["quantity"]),
          tax: getValidFloatValue(productItem["tax"]),
          width: getValidIntegerValue(productItem["width"]),
          height: getValidIntegerValue(productItem["height"]),
          unit: productItem["unit"],
          in_unit: getValidFloatValue(productItem["in_unit"]),
          total_unit: getValidFloatValue(productItem["total_unit"]),
          desc: productItem["desc"],
          additional_details: productItem["additional_details"],
        };
        tempObj["name"] = productItem["item"]["name"];

        if (productItem.hasOwnProperty("product_data")) {
          tempObj["product_data"] = productItem["product_data"];
        }
        itemList.push(tempObj);
      } else {
        let tempObj = {
          rate: getValidFloatValue(productItem["rate"]),
          amount: getValidFloatValue(productItem["amount"]),
          quantity: getValidIntegerValue(productItem["quantity"]),
          discount: getValidFloatValue(productItem["discount"]),
          tax: getValidFloatValue(productItem["tax"]),
          desc: productItem["desc"],
        };
        tempObj["name"] = productItem["item"]["name"];

        // while adding product line item - storing all product data under product_data key
        if (productItem.hasOwnProperty("product_data")) {
          tempObj["product_data"] = productItem["product_data"];
        }
        itemList.push(tempObj);
      }
    });
    tempQuotationSchema["items"] = itemList;
    let tempAmountDetails = {};
    Object.keys(amountDetails).forEach((key) => {
      tempAmountDetails[key] = getValidFloatValue(amountDetails[key]);
    });
    tempQuotationSchema["amount_details"] = tempAmountDetails;
    tempQuotationSchema["billing_details"] = billingDetails;

    tempQuotationSchema["additional_details"] = additionalDetails;
    tempQuotationSchema["place_of_supply"] = placeOfSupply;
    tempQuotationSchema["template_type"] = details["template_type"];

    if (scope === "edit") {
      //copy schema from existing quote data
      tempQuotationSchema["version"] = 2;
      tempQuotationSchema["company_details"] = {};
      tempQuotationSchema["company_details"] =
        existingQuoteData["quote_schema"]["company_details"];

      tempQuotationSchema["show_gst"] = existingQuoteData["quote_schema"][
        "show_gst"
      ]
        ? existingQuoteData["quote_schema"]["show_gst"]
        : false;
      tempQuotationSchema["title"] = existingQuoteData["quote_schema"][
        "quotation_title"
      ]
        ? existingQuoteData["quote_schema"]["quotation_title"]
        : existingQuoteData["quote_schema"]["title"];
      tempQuotationSchema["additional_details"]["show_additional_information"] =
        existingQuoteData["quote_schema"]["additional_details"][
          "show_additional_information"
        ];
      tempQuotationSchema["additional_details"]["show_terms_and_conditions"] =
        existingQuoteData["quote_schema"]["additional_details"][
          "show_terms_and_conditions"
        ];
      tempQuotationSchema["additional_details"]["show_quotation_number"] =
        existingQuoteData["quote_schema"]["additional_details"][
          "show_quotation_number"
        ];
      tempQuotationSchema["billing_details"]["show_shipping_address"] =
        existingQuoteData["quote_schema"]["billing_details"][
          "show_shipping_address"
        ];
    } else {
      //VERSION 2 SCHEMA copy required template schema to quote schema
      tempQuotationSchema["version"] = 2;
      tempQuotationSchema["company_details"] = {};
      tempQuotationSchema["company_details"] =
        quotationTemplateConfig["schema"]["company_details"];

      tempQuotationSchema["show_gst"] = quotationTemplateConfig["schema"][
        "show_gst"
      ]
        ? quotationTemplateConfig["schema"]["show_gst"]
        : false;
      tempQuotationSchema["title"] = quotationTemplateConfig["schema"][
        "quotation_title"
      ]
        ? quotationTemplateConfig["schema"]["quotation_title"]
        : quotationTemplateConfig["schema"]["title"];
      tempQuotationSchema["additional_details"]["show_additional_information"] =
        quotationTemplateConfig["schema"]["additional_details"][
          "show_additional_information"
        ];
      tempQuotationSchema["additional_details"]["show_terms_and_conditions"] =
        quotationTemplateConfig["schema"]["additional_details"][
          "show_terms_and_conditions"
        ];
      tempQuotationSchema["additional_details"]["show_quotation_number"] =
        quotationTemplateConfig["schema"]["additional_details"][
          "show_quotation_number"
        ];
      tempQuotationSchema["billing_details"]["show_shipping_address"] =
        quotationTemplateConfig["schema"]["billing_details"][
          "show_shipping_address"
        ];
    }

    formData["quote_schema"] = tempQuotationSchema;
    formData["amount"] = parseFloat(tempAmountDetails["grand_total"]);
    if (scope === "edit" || scope === "revise" || scope === "to_invoice") {
      await editQuote(formData);
    } else {
      await createQuote(formData);
    }
  };
  const getQuotationList = async () => {
    let response = await getQuoteTemplateListApi();
    //listing only quotation_template or quotation_template = null templates (null for existing templates)
    const filteredResponse = response.filter(
      (template) =>
        template.quotation_template === null || template.quotation_template
    );
    setQuoteList(filteredResponse);

    if (scope === "edit" || scope === "revise" || scope === "to_invoice") {
      let index = response.findIndex(
        (quote) => quote["id"] === existingQuoteData["quotation_template_id"]
      );
      if (index !== -1) {
        setQuotationTemplateConfig(response[index]);

        let quoteData = response[index]["schema"];
        setShowShippingAddress(
          quoteData["billing_details"]["show_shipping_address"]
        );
        setShowAdditionalInformation(
          quoteData["additional_details"]["show_additional_information"]
        );
        setShowTermsAndConditions(
          quoteData["additional_details"]["show_terms_and_conditions"]
        );
      }
    }
  };

  const getQuotationNumber = async () => {
    let response = await getQuotationNumberApi();
    if (response.hasOwnProperty("quotation_number")) {
      setQuotationNumber(response["quotation_number"]);
    }
  };
  const initBillingSectionPrefill = (selectedTemplateSchema) => {
    setBillingDetails({
      billing_name: "",
      billing_address: "",
      billing_city: "",
      billing_state: "",
      billing_country: "",
      billing_zipcode: "",
      billing_gst: "",
      shipping_name: "",
      shipping_address: "",
      shipping_city: "",
      shipping_state: "",
      shipping_country: "",
      shipping_zipcode: "",
    });
    let tempBillingDetails = {};
    for (var key in selectedTemplateSchema["address_variables"]) {
      if (selectedTemplateSchema["address_variables"][key] != "") {
        tempBillingDetails[key] = "";
        const value = embedVariableValuesInMessageString({
          messageString: selectedTemplateSchema["address_variables"][key],
          leadData: leadData,
        });
        tempBillingDetails[key] = value;
      }
    }
    setBillingDetails(tempBillingDetails);
  };

  const initCreateData = async () => {
    await getQuotationNumber();
    setQuotationTemplateConfig(selectedQuoteTemplate);
    setSelectedQuoteID(selectedQuoteTemplate["id"]);
    handleQuotationData(selectedQuoteTemplate);
  };

  const getProductsList = async () => {
    const queryPayload = {
      name: "",
      active: true,
    };

    let response = await getProductsListApi({
      page: 0,
      page_size: 50,
      payload: { query: queryPayload },
    });
    if (Object.keys(response).length > 0) {
      setFullProductList(response.items);
    } else {
      setFullProductList([]);
    }
  };

  const checkAndSetupActiveDealItems = (slug) => {
    let newList = [];
    if (
      activeDealDetails.hasOwnProperty("deal_items") &&
      activeDealDetails["deal_items"] !== null
    ) {
      activeDealDetails["deal_items"].forEach((product) => {
        let tempObj = { desc: "" };
        tempObj["discount"] = product["discount"];
        tempObj["rate"] = product["rate"];
        tempObj["quantity"] = product["quantity"];
        tempObj["amount"] = product["amount"];

        if (product.hasOwnProperty("product") && product["product"] !== null) {
          tempObj["type"] = "product";
          tempObj["product_data"] = {
            id: product["product"]["id"],
            name: product["product"]["name"],
            unit_price: product["product"]["unit_price"],
            unit_cost: product["product"]["unit_cost"],
            tax: product["product"]["tax"],
            max_discount: product["product"]["max_discount"],
            product_code: product["product"]["product_code"],
          };
          if (slug === "template_area_based") {
            //adding deal items for area based template
            const specifications = product["product"]["specifications"];
            const keys = Object.keys(specifications).reduce((acc, key) => {
              acc[key.toLowerCase()] = key;
              return acc;
            }, {});

            tempObj["height"] = keys["height"]
              ? specifications[keys["height"]]
              : "";
            tempObj["width"] = keys["width"]
              ? specifications[keys["width"]]
              : "";
            tempObj["unit"] = product["product"]["unit"];
            if (tempObj["height"] !== "" && tempObj["width"] !== "") {
              tempObj["in_unit"] = tempObj["width"] * tempObj["height"];
              tempObj["total_unit"] = tempObj["in_unit"] * tempObj["quantity"];
              tempObj["amount"] = tempObj["total_unit"] * tempObj["rate"];
            } else {
              tempObj["amount"] = product["quantity"] * product["rate"];
            }
          }
        }
        //tax calc from product data
        tempObj["tax"] =
          product.hasOwnProperty("product") &&
          product["product"] !== null &&
          product["product"].hasOwnProperty("tax") &&
          product["product"]["tax"] !== null &&
          product["product"]["tax"] !== ""
            ? product["product"]["tax"]
            : null;
        const taxedAmount =
          tempObj["tax"] !== null && tempObj["tax"] > 0
            ? (tempObj["amount"] * tempObj["tax"]) / 100
            : 0;
        tempObj["amount"] = (tempObj["amount"] + taxedAmount).toFixed(2);

        tempObj["item"] = {
          id: uuidv4(),
        };
        if (product["item"] !== null) {
          tempObj["item"]["name"] = product["item"];
        } else {
          tempObj["item"]["name"] = product["product"]["name"];
        }
        tempObj["discount"] = product.hasOwnProperty("discount")
          ? product["discount"]
          : "";
        newList.push(tempObj);
      });
    }
    return newList;
  };

  const initEditableData = async () => {
    await getQuotationList();
    setQuotationNumber(existingQuoteData["quote_number"]);
    setSelectedQuoteID(existingQuoteData["quotation_template_id"]);
    setBillingDetails(existingQuoteData["quote_schema"]["billing_details"]);

    if (
      existingQuoteData["quote_schema"].hasOwnProperty("additional_columns")
    ) {
      setTableAdditionalColumns(
        existingQuoteData["quote_schema"]["additional_columns"]
      );
    }
    let updatedAmountDetails = updateTotalAmount({
      type: "init",
      amountDetails: existingQuoteData["quote_schema"]["amount_details"],
    });
    setAmountDetails(updatedAmountDetails);
    setAdditionalDetails(
      existingQuoteData["quote_schema"]["additional_details"]
    );

    // if template version is 2 get the styles from schema data
    let templateVersion =
      existingQuoteData.hasOwnProperty("quote_schema") &&
      existingQuoteData["quote_schema"] !== null &&
      existingQuoteData["quote_schema"].hasOwnProperty("template_version") &&
      existingQuoteData["quote_schema"]["template_version"] !== null &&
      existingQuoteData["quote_schema"]["template_version"] !== ""
        ? existingQuoteData["quote_schema"]["template_version"]
        : 1;

    setTemplateVersion(templateVersion);

    if (templateVersion > 1) {
      setStyleDetails(existingQuoteData["quote_schema"]["styles"]);
    }

    let tempObj = {
      title: existingQuoteData["quote_schema"]["quote_title"],
      // setting type slug to basic if it is not there
      template_type:
        existingQuoteData.quote_schema.hasOwnProperty("template_type") &&
        existingQuoteData.quote_schema.template_type !== null &&
        existingQuoteData.quote_schema.template_type !== ""
          ? existingQuoteData["quote_schema"]["template_type"]
          : "template_basic",
      quote_date:
        existingQuoteData["quote_date"] !== null &&
        existingQuoteData["quote_date"] !== ""
          ? existingQuoteData["quote_date"]
          : null,
      expiry_date:
        existingQuoteData["expiry_date"] !== null &&
        existingQuoteData["expiry_date"] !== ""
          ? existingQuoteData["expiry_date"]
          : null,
    };
    setDetails(tempObj);
    setPlaceOfSupply(
      existingQuoteData["quote_schema"]["place_of_supply"] !== null &&
        existingQuoteData["quote_schema"]["place_of_supply"] !== ""
        ? existingQuoteData["quote_schema"]["place_of_supply"]
        : null
    );
    let tempList = [...existingQuoteData["quote_schema"]["items"]];
    tempList.forEach((item) => {
      item["item"] = { name: item["name"], id: uuidv4() };
      item["desc"] =
        item.hasOwnProperty("desc") && item["desc"] !== null
          ? item["desc"]
          : "";
      if (
        item.hasOwnProperty("product_data") &&
        item["product_data"] !== null
      ) {
        item["type"] = "product";
      }
    });

    setProductAddedList(tempList);
  };

  const checkAndIntegrateTemplateItems = ({
    templateData,
    productAddedList,
    slug,
  }) => {
    if (
      templateData.schema.hasOwnProperty("items") &&
      templateData.schema["items"] !== null &&
      templateData.schema["items"].length > 0
    ) {
      let tempList = [...productAddedList];
      let templateItemList = [];

      templateData.schema["items"].forEach((item) => {
        let tempObj = {};
        tempObj["discount"] = item["discount"];
        tempObj["rate"] = item["rate"];
        tempObj["amount"] = item["amount"];
        tempObj["quantity"] = item["quantity"];
        tempObj["item"] = {
          name: item["name"],
          id: uuidv4(),
        };
        tempObj["desc"] =
          item.hasOwnProperty("desc") && item["desc"] !== null
            ? item["desc"]
            : "";
        //adding necessary keys if it is area based
        if (slug === "template_area_based") {
          tempObj["width"] = item["width"];
          tempObj["height"] = item["height"];
          tempObj["unit"] = item["unit"];
          tempObj["in_unit"] = item["in_unit"];
          tempObj["total_unit"] = item["total_unit"];
          tempObj["additional_details"] = item["additional_details"];
        } else {
          tempObj["tax"] = item["tax"];
        }
        templateItemList.push(tempObj);
      });
      tempList = tempList.concat(templateItemList);
      setProductAddedList(tempList);
      updateCalculatedAmount(tempList);
    }
  };

  const getAmountForAreaBased = ({
    productData,
    rate,
    totalUnit,
    quantity,
  }) => {
    if (
      productData.width &&
      productData.height &&
      productData.width !== 0 &&
      productData.height !== 0 &&
      productData.width !== "" &&
      productData.height !== ""
    ) {
      return rate * totalUnit;
    }
    return rate * quantity;
  };

  const setup = async () => {
    if (scope === "edit" || scope === "revise" || scope === "to_invoice") {
      await initEditableData();
    } else {
      await initCreateData();
    }
    //initially getting full product list and storing to filter added product and use later
    await getProductsList();
    setLoading(false);
  };

  useEffect(() => {
    setup();
  }, []);

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleCloneItem = (item) => {
    const newItem = JSON.parse(JSON.stringify(item));
    newItem.item.id = uuidv4();

    const newProductsList = [...productAddedList, newItem];

    setProductAddedList(newProductsList);
    updateCalculatedAmount(newProductsList);
    checkAndSetItemTaxFlag(newProductsList);
  };

  const handleDeleteProduct = (indexToDelete) => {
    const newList = productAddedList.filter(
      (_, index) => index !== indexToDelete
    );
    setProductAddedList(newList);
    updateCalculatedAmount(newList);
    checkAndSetItemTaxFlag(newList);
  };

  const checkAndSetItemTaxFlag = (itemList) => {
    if (
      itemList.every(
        (x) => x.tax !== "" && x.tax !== null && x.tax !== undefined
      )
    ) {
      setItemTaxFlag(false);
    } else {
      setItemTaxFlag(true);
    }
  };

  const updateCalculatedAmount = (itemList) => {
    let tempObj = { ...amountDetails };
    let amount = 0;
    itemList.forEach((item) => {
      amount += parseFloat(item["amount"]);
    });
    tempObj["sub_total"] = amount.toFixed(2);

    let totalAmount = amount;
    let taxedAmount =
      amountDetails["additional_tax"] !== null &&
      amountDetails["additional_tax"] !== ""
        ? parseFloat(amountDetails["additional_tax"])
        : 0;
    let discountedAmount =
      amountDetails["additional_discount"] !== null &&
      amountDetails["additional_discount"] !== ""
        ? parseFloat(amountDetails["additional_discount"])
        : 0;

    totalAmount = totalAmount - discountedAmount;
    totalAmount = totalAmount + taxedAmount;

    //calc difference to auto adjust
    let roundedDifference = parseFloat(
      (Math.round(totalAmount) - totalAmount).toFixed(2)
    );
    tempObj["adjustments"] = roundedDifference;

    tempObj["grand_total"] = parseFloat(
      totalAmount + roundedDifference
    ).toFixed(2);

    setAmountDetails(tempObj);
  };

  const updateTotalAmount = ({ type, amountDetails }) => {
    let totalAmount = parseFloat(amountDetails["sub_total"]);
    let taxedAmount =
      amountDetails["additional_tax"] !== null &&
      amountDetails["additional_tax"] !== ""
        ? parseFloat(amountDetails["additional_tax"])
        : 0;
    let discountedAmount =
      amountDetails["additional_discount"] !== null &&
      amountDetails["additional_discount"] !== ""
        ? parseFloat(amountDetails["additional_discount"])
        : 0;
    let adjustmentAmount =
      amountDetails["adjustments"] !== null &&
      amountDetails["adjustments"] !== ""
        ? parseFloat(amountDetails["adjustments"])
        : 0;

    totalAmount = totalAmount - discountedAmount;
    totalAmount = totalAmount + taxedAmount;

    let roundedDifference = 0;
    if (type === "adjustments" || type === "init") {
      //using entered adjustment amount if it was init or from text field
      totalAmount = totalAmount + adjustmentAmount;
    } else {
      // done only if not changing adjustments directly or not init
      //calc difference to auto adjust
      roundedDifference = parseFloat(
        (Math.round(totalAmount) - totalAmount).toFixed(2)
      );
      amountDetails["adjustments"] = roundedDifference;
    }

    amountDetails["grand_total"] = parseFloat(
      totalAmount + roundedDifference
    ).toFixed(2);
    return amountDetails;
  };

  const canShowFormStepWithTermsAndAdditionalInfo = () => {
    return (
      showTermsAndConditions === true || showAdditionalInformation === true
    );
  };

  const RenderButton = () => {
    if (activeStep === 0) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "fit-content",
          }}
        >
          <Button
            type="button"
            onClick={handleClose}
            variant="outlined"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            {getLocalizedText("cancel")}
          </Button>
          <Button
            disabled={selectedQuoteID === ""}
            type="submit"
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            {getLocalizedText("next")}
          </Button>
        </Box>
      );
    } else if (activeStep === 1) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "fit-content",
          }}
        >
          <Button
            onClick={handlePrevious}
            type="button"
            variant="outlined"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            {getLocalizedText("prev")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            {canShowFormStepWithTermsAndAdditionalInfo()
              ? getLocalizedText("next")
              : getLocalizedText("submit")}
          </Button>
        </Box>
      );
    } else if (activeStep === 2) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "fit-content",
          }}
        >
          <Button
            onClick={handlePrevious}
            type="button"
            variant="outlined"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            {getLocalizedText("prev")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            {getLocalizedText("submit")}
          </Button>
        </Box>
      );
    }
  };

  const getHeaderText = () => {
    if (scope === "edit") {
      return getLocalizedText("edit_quote");
    } else if (scope === "revise") {
      return getLocalizedText("revise_quote");
    } else if (scope === "to_invoice") {
      return getLocalizedText("edit_invoice");
    } else {
      return getLocalizedText("create_quote");
    }
  };
  const getSuccessMessageText = () => {
    if (scope === "edit") {
      return getLocalizedText("quote_edited_successfully");
    } else if (scope === "revise") {
      return getLocalizedText("quote_revised_successfully");
    } else if (scope === "to_invoice") {
      return getLocalizedText("invoice_edited_successfully");
    } else {
      return getLocalizedText("quote_created_successfully");
    }
  };

  const removeTemplateItemsFromList = ({ templateData }) => {
    let newList = [];
    if (
      templateData.schema.hasOwnProperty("items") &&
      templateData.schema["items"] !== null &&
      templateData.schema["items"].length > 0
    ) {
      productAddedList.forEach((item) => {
        let index = templateData.schema["items"].findIndex(
          (obj) => obj["name"] === item["item"]["name"]
        );
        if (index === -1) {
          newList.push(item);
        }
      });
    }
    return newList;
  };

  const isProductAlreadyAdded = (id) => {
    let index = productAddedList.findIndex(
      (product) =>
        product.hasOwnProperty("product_data") &&
        product["product_data"]["id"] === id
    );
    if (index !== -1) {
      return true;
    }
    return false;
  };

  const handleQuotationData = (quoteTemplateData) => {
    let schemaData = quoteTemplateData["schema"];

    // if template version is 2 get the styles from schema data
    let templateVersion =
      schemaData.hasOwnProperty("template_version") &&
      schemaData["template_version"] !== null &&
      schemaData["template_version"] !== ""
        ? schemaData["template_version"]
        : 1;
    setTemplateVersion(templateVersion);

    if (templateVersion > 1) {
      setStyleDetails(schemaData["styles"]);
    } else {
      setStyleDetails({
        table_header: {
          background_color: "",
          text_color: "",
        },
        grand_total: {
          background_color: "",
          text_color: "",
        },
      });
    }

    const typeSlug = schemaData.hasOwnProperty("template_type")
      ? schemaData["template_type"]
      : "template_basic";

    let tempItemList = checkAndSetupActiveDealItems(typeSlug);
    setProductAddedList(tempItemList);
    updateCalculatedAmount(tempItemList);

    checkAndIntegrateTemplateItems({
      templateData: quoteTemplateData,
      productAddedList: tempItemList,
      slug: typeSlug,
    });

    //check if all items have tax values and set flag
    checkAndSetItemTaxFlag(tempItemList);
    //prefill subject
    const subject =
      schemaData["subject"] !== null && schemaData["subject"] !== undefined
        ? schemaData["subject"]
        : "";

    setDetails({
      ...details,
      title: subject,
      template_type: typeSlug,
      quote_date:
        details["quote_date"] !== null && details["quote_date"] !== ""
          ? details["quote_date"]
          : moment().startOf("day"),
    });

    //set prefill if available in selected template
    if (quoteTemplateData["schema"].hasOwnProperty("address_variables")) {
      initBillingSectionPrefill(schemaData);
      //else reset
    } else {
      setBillingDetails({
        billing_name: "",
        billing_address: "",
        billing_city: "",
        billing_state: "",
        billing_country: "",
        billing_zipcode: "",
        billing_gst: "",
        shipping_name: "",
        shipping_address: "",
        shipping_city: "",
        shipping_state: "",
        shipping_country: "",
        shipping_zipcode: "",
      });
    }
    setShowShippingAddress(
      schemaData["billing_details"]["show_shipping_address"]
    );
    setShowAdditionalInformation(
      schemaData["additional_details"]["show_additional_information"]
    );
    setShowTermsAndConditions(
      schemaData["additional_details"]["show_terms_and_conditions"]
    );

    let tempAdditionalDetails = {
      terms_and_conditions: "",
      additional_information: "",
    };
    if (schemaData["additional_details"]["show_terms_and_conditions"]) {
      tempAdditionalDetails["terms_and_conditions"] =
        schemaData["additional_details"]["terms_and_conditions"];
    }
    if (schemaData["additional_details"]["show_additional_information"]) {
      tempAdditionalDetails["additional_information"] =
        schemaData["additional_details"]["additional_information"];
    }
    setAdditionalDetails(tempAdditionalDetails);
  };

  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: "50vw",
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            <ModelHeader>{getHeaderText()}</ModelHeader>
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModelWrapper
            component={"form"}
            onSubmit={(e) => {
              e.preventDefault();
              const maxStepIndex = canShowFormStepWithTermsAndAdditionalInfo()
                ? 2
                : 1;

              if (activeStep < maxStepIndex) {
                if (itemTaxFlag) {
                  setShowItemTaxWarning(true);
                } else {
                  if (details["template_type"] === "template_area_based") {
                    //only for area based template, checking if required fields are filled before next
                    if (checkIfAllRequiredFieldsAreFilled()) {
                      handleNext();
                    } else {
                      setShowRequiredEmptyWarning(true);
                    }
                  } else {
                    handleNext();
                  }
                }
              } else {
                handleSubmit();
              }
            }}
          >
            <InputContainer>
              {(() => {
                if (activeStep === 0) {
                  return (
                    <QuoteAndProuductInfo
                      handleDelete={(productItem) => {
                        let tempProductAddedList = [...productAddedList];
                        tempProductAddedList = tempProductAddedList.filter(
                          (entry) =>
                            entry["item"]["id"] !== productItem["item"]["id"]
                        );
                        setProductAddedList(tempProductAddedList);
                        updateCalculatedAmount(tempProductAddedList);
                        checkAndSetItemTaxFlag(tempProductAddedList);
                      }}
                      handleEdit={(item) => {
                        setSelectedProductData(item);

                        if (
                          item.hasOwnProperty("type") &&
                          item["type"] === "product"
                        ) {
                          setIsEditProductLineItem(true);
                          setOpenProductLineItemForm(true);
                        } else {
                          setOpenEditProductModal(true);
                        }
                      }}
                      handleView={(item) => {
                        setSelectedProductData(item);
                        setOpenViewProductModal(true);
                      }}
                      handleAddCustomItem={() => {
                        setOpenAddProductModal(true);
                      }}
                      handleAddProduct={() => {
                        getDefaultProductsList();
                        setOpenProductLineItemForm(true);
                      }}
                      tableAdditionalColumns={tableAdditionalColumns}
                      setTableAdditionalColumns={setTableAdditionalColumns}
                      handleClone={(item) => {
                        handleCloneItem(item);
                      }}
                      details={details}
                      isFormDisabled={selectedQuoteID === ""}
                      productAddedList={productAddedList}
                      placeOfSupply={placeOfSupply}
                      setOpenAddProductModal={setOpenAddProductModal}
                      setOpenViewProductModal={setOpenViewProductModal}
                      setSelectedProductData={setSelectedProductData}
                      handleDeleteProduct={handleDeleteProduct}
                      handleAmountDetails={(event) => {
                        let tempObj = {
                          ...amountDetails,
                          [event.target.name]: event.target.value,
                        };
                        let updatedAmountDetails = updateTotalAmount({
                          type: event.target.name,
                          amountDetails: tempObj,
                        });

                        setAmountDetails(updatedAmountDetails);
                      }}
                      handleChange={(name, value) => {
                        setDetails({ ...details, [name]: value });
                      }}
                      handlePlaceOfSupply={(value) => {
                        setPlaceOfSupply(value);
                        //reset tax value for all items and set flag
                        if (productAddedList.length !== 0) {
                          let newProductList = [...productAddedList];
                          newProductList = newProductList.map((item) => {
                            let discount =
                              item.hasOwnProperty("discount") &&
                              item["discount"] !== null &&
                              item["discount"] !== undefined &&
                              item["discount"] !== ""
                                ? parseFloat(item["discount"])
                                : 0;
                            let rate =
                              item.hasOwnProperty("rate") &&
                              item["rate"] !== null &&
                              item["rate"] !== undefined &&
                              item["rate"] !== ""
                                ? parseFloat(item["rate"])
                                : 0;
                            let quantity =
                              item.hasOwnProperty("quantity") &&
                              item["quantity"] !== null &&
                              item["quantity"] !== undefined &&
                              item["quantity"] !== ""
                                ? parseInt(item["quantity"])
                                : 0;
                            let amount = 0;
                            if (
                              details["template_type"] === "template_area_based"
                            ) {
                              amount = getAmountForAreaBased({
                                productData: item,
                                rate: item["rate"],
                                totalUnit: item["total_unit"],
                                quantity: item["quantity"],
                              });
                            } else {
                              amount = rate * quantity;
                            }

                            let discountedAmount = (amount * discount) / 100;
                            amount = (amount - discountedAmount).toFixed(2);

                            if (
                              item["type"] === "product" &&
                              item.hasOwnProperty("product_data") &&
                              item["product_data"] !== null
                            ) {
                              item["tax"] =
                                item["product_data"]["tax"] !== null
                                  ? item["product_data"]["tax"]
                                  : "";

                              const taxedAmount =
                                item["tax"] !== null && item["tax"] > 0
                                  ? (amount * item["tax"]) / 100
                                  : 0;
                              item["amount"] = (
                                parseFloat(amount) + taxedAmount
                              ).toFixed(2);
                            } else {
                              item["tax"] = "";
                              item["amount"] = amount;
                            }
                            return item;
                          });
                          setProductAddedList(newProductList);
                          const hasEmptyTax = newProductList.some(
                            (item) => item["tax"] === ""
                          );
                          if (hasEmptyTax) {
                            setItemTaxFlag(true);
                          }
                        }
                      }}
                      amountDetails={amountDetails}
                      quotationNumber={quotationNumber}
                      slug={details["template_type"]}
                      handleCloneItem={handleCloneItem}
                    />
                  );
                } else if (activeStep === 1) {
                  return (
                    <BillingInfo
                      billingDetails={billingDetails}
                      handleChange={(name, value) => {
                        setBillingDetails({ ...billingDetails, [name]: value });
                      }}
                      showShippingAddress={showShippingAddress}
                    />
                  );
                } else if (activeStep === 2) {
                  return (
                    <AdditionalInfo
                      additionalInformation={additionalDetails}
                      handleChange={(name, value) => {
                        setAdditionalDetails({
                          ...additionalDetails,
                          [name]: value,
                        });
                      }}
                      showAdditionalInformation={showAdditionalInformation}
                      showTermsAndConditions={showTermsAndConditions}
                    />
                  );
                }
              })()}
            </InputContainer>

            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: "50vw", bgcolor: "#f9f9fc" }}
            >
              <Toolbar style={{ position: "relative" }}>
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                {(showItemTaxWarning || showRequiredEmptyWarning) && (
                  <WarningAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      if (showItemTaxWarning) {
                        setShowItemTaxWarning(false);
                      }
                      if (showRequiredEmptyWarning) {
                        setShowRequiredEmptyWarning(false);
                      }
                    }}
                    severity="warning"
                    sx={{ width: "100%" }}
                  >
                    {showRequiredEmptyWarning
                      ? getLocalizedText(
                          "please_fill_all_required_fields_for_items_before_proceeding"
                        )
                      : getLocalizedText(
                          "please_update_tax_for_all_items_before_proceeding"
                        )}
                  </WarningAlert>
                )}
                <ButtonWrapper>
                  <RenderButton />
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </ModelWrapper>
        ) : (
          <MessageWrapper>
            <Message>{getSuccessMessageText()}</Message>
            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              {getLocalizedText("close")}
            </Button>
          </MessageWrapper>
        )}
      </Box>
      {openAddProductModal &&
        (details["template_type"] === "template_area_based" ? (
          <AddAreaBasedItemModal
            open={openAddProductModal}
            setOpen={setOpenAddProductModal}
            productAddedList={productAddedList}
            setProductAddedList={setProductAddedList}
            updateCalculatedAmount={(productList) =>
              updateCalculatedAmount(productList)
            }
          />
        ) : (
          <AddProductModal
            open={openAddProductModal}
            setOpen={setOpenAddProductModal}
            productAddedList={productAddedList}
            setProductAddedList={setProductAddedList}
            updateCalculatedAmount={(productList) =>
              updateCalculatedAmount(productList)
            }
            taxOptions={quotationTaxOptions}
          />
        ))}
      {openEditProductModal &&
        (details["template_type"] === "template_area_based" ? (
          <EditAreaBasedItemModal
            open={openEditProductModal}
            setOpen={setOpenEditProductModal}
            productAddedList={productAddedList}
            setProductAddedList={setProductAddedList}
            editableProduct={selectedProductData}
            setEditableProduct={setSelectedProductData}
            updateCalculatedAmount={(productList) =>
              updateCalculatedAmount(productList)
            }
            setTotalItemsReset={setTotalItemsReset}
            totalItemsReset={totalItemsReset}
            itemTaxFlag={itemTaxFlag}
            setItemTaxFlag={setItemTaxFlag}
            setShowItemTaxWarning={setShowItemTaxWarning}
            slug={details["template_type"]}
          />
        ) : (
          <EditProductModal
            open={openEditProductModal}
            setOpen={setOpenEditProductModal}
            productAddedList={productAddedList}
            setProductAddedList={setProductAddedList}
            editableProduct={selectedProductData}
            setEditableProduct={setSelectedProductData}
            updateCalculatedAmount={(productList) =>
              updateCalculatedAmount(productList)
            }
            setTotalItemsReset={setTotalItemsReset}
            totalItemsReset={totalItemsReset}
            itemTaxFlag={itemTaxFlag}
            setItemTaxFlag={setItemTaxFlag}
            setShowItemTaxWarning={setShowItemTaxWarning}
            slug={details["template_type"]}
            taxOptions={quotationTaxOptions}
          />
        ))}
      {openViewProductModal &&
        (details["template_type"] === "template_area_based" ? (
          <ViewAreaBasedProductModal
            open={openViewProductModal}
            setOpen={setOpenViewProductModal}
            data={selectedProductData}
            setData={setSelectedProductData}
          />
        ) : (
          <ViewProductModal
            open={openViewProductModal}
            setOpen={setOpenViewProductModal}
            data={selectedProductData}
            setData={setSelectedProductData}
          />
        ))}
      {openProductLineItemForm &&
        (details["template_type"] === "template_area_based" ? (
          <AddAreaBasedProductModal
            open={openProductLineItemForm}
            setOpen={setOpenProductLineItemForm}
            isEdit={isEditProductLineItem}
            productAddedList={productAddedList}
            editableProduct={selectedProductData}
            setProductAddedList={setProductAddedList}
            updateCalculatedAmount={(productList) =>
              updateCalculatedAmount(productList)
            }
            handleClose={() => {
              setIsEditProductLineItem(false);
              setOpenProductLineItemForm(false);
            }}
            productList={productList}
            setProductList={setProductList}
            setItemTaxFlag={setItemTaxFlag}
            setShowItemTaxWarning={setShowItemTaxWarning}
            isProductAlreadyAdded={(id) => isProductAlreadyAdded(id)}
            defaultProducts={defaultProductList}
            onSearchResetCallback={onSearchResetCallback}
          />
        ) : (
          <AddProductLineItemModal
            editableProduct={selectedProductData}
            handleClose={() => {
              setIsEditProductLineItem(false);
              setOpenProductLineItemForm(false);
            }}
            isEdit={isEditProductLineItem}
            isProductAlreadyAdded={(id) => isProductAlreadyAdded(id)}
            open={openProductLineItemForm}
            postAddCallback={(details) => {
              let tempProductAddedList = [...productAddedList];
              tempProductAddedList.push(details);
              setProductAddedList(tempProductAddedList);
              updateCalculatedAmount(tempProductAddedList);
              setIsEditProductLineItem(false);
              setOpenProductLineItemForm(false);
            }}
            postEditCallback={(details) => {
              let tempProductAddedList = [...productAddedList];
              let index = productAddedList.findIndex(
                (productItem) =>
                  productItem["item"]["id"] === details["item"]["id"]
              );
              if (index !== -1) {
                tempProductAddedList[index] = details;
              }
              setProductAddedList(tempProductAddedList);
              updateCalculatedAmount(tempProductAddedList);

              const hasAllItemWithTax = tempProductAddedList.every(
                (entry) => entry.tax !== ""
              );
              if (hasAllItemWithTax) {
                setItemTaxFlag(false);
                setShowItemTaxWarning(false);
              }
              setIsEditProductLineItem(false);
              setOpenProductLineItemForm(false);
            }}
            productList={productList}
            defaultProducts={defaultProductList}
            onSearchResetCallback={onSearchResetCallback}
            taxOptions={quotationTaxOptions}
          />
        ))}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: "50vw",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AddQuoteSidePanel;
