import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getAdminUserCallsInDayApi } from "../../Api";
import { attendanceCallsColumnHeader } from "../../Db";
import { Box, CircularProgress, styled } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import ViewAttendanceCallInfoComponent from "./ViewAttendanceCallInfoComponent";
import { Drawer } from "@material-ui/core";
const Container = styled(Box)`
  width: 100%;
  ${`height: calc(100vh - 288px);`};
  overflow-y: auto;
  background-color: white;
  position: relative;
`;
const CallsTableWidget = ({ callsDate, id }) => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openCallInfoPanel, setOpenCallInfoPanel] = useState(false);
  const [selectedCallData, setSelectedCallData] = useState({});
  const getCalls = async () => {
    let response = await getAdminUserCallsInDayApi({
      payload: callsDate,
      userID: id,
    });
    setRow(response);
  };
  const init = async () => {
    await getCalls();
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, [callsDate]);

  const defaultColDef = useMemo(() => ({
    resizable: true,
    suppressMovable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };
  return (
    <>
      {!loading ? (
        <Container>
          <div className="ag-theme-alpine" style={{ width: "100%" }}>
            <AgGridReact
              domLayout="autoHeight"
              gridOptions={gridOptions}
              rowData={row}
              defaultColDef={defaultColDef}
              columnDefs={attendanceCallsColumnHeader}
              animateRows={true}
              suppressCellFocus
              pagination={false}
              onRowClicked={(row) => {
                if (row.event.defaultPrevented) {
                  return null;
                }
                setSelectedCallData(row.data);
                setOpenCallInfoPanel(true);
              }}
              suppressColumnVirtualisation={true}
            />
          </div>
        </Container>
      ) : (
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      )}
      {openCallInfoPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCallInfoPanel}
          onClose={() => {
            setOpenCallInfoPanel(false);
          }}
        >
          <ViewAttendanceCallInfoComponent details={selectedCallData} />
        </Drawer>
      )}
    </>
  );
};

export default CallsTableWidget;
