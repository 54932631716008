import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Button, CircularProgress, Grid, Switch } from "@material-ui/core";
import {
  IsSameUser,
  IsoToLocalTime,
  dataRenderer,
  IsoToLocalDate,
  getLocalizedText,
  consoleLogger,
} from "../../Functions";
import profile_placeholder from "../../assets/profile_placeholder.png";
import PreviewImageDialog from "../files_page_components/PreviewImageDialog";
import UserProfileImageComponent from "../file_upload_component/user_profile_image/UserProfileImageComponent";
import { Circle } from "@mui/icons-material";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const Header = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #a9a9a9;
  margin-bottom: 8px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ViewAdminPartnerSidepanel = ({
  data,
  handleEdit,
  handleUserActive,
  list,
  refresh,
}) => {
  const [viewProfileImage, setViewProfileImage] = useState(false);
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const getProfileImageUrl = () => {
    if (
      details.hasOwnProperty("profile_pic") &&
      details["profile_pic"] !== null
    ) {
      return details["profile_pic"];
    }

    return profile_placeholder;
  };
  
  const RenderBulletPoints = ({ value }) => {
    let list =
      value !== null && value !== "" && Array.isArray(value) ? value : [];
    if (list.length > 0) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {list.map((item) => {
            return (
              <Box sx={{ display: "flex", columnGap: "8px" }}>
                <Circle
                  style={{
                    width: "8px",
                    height: "8px",
                    marginTop: "6px",
                    color: "gray",
                  }}
                />
                <Detail>{item}</Detail>
              </Box>
            );
          })}
        </Box>
      );
    }
    return <Detail>-</Detail>;
  };

  const RenderListOfStrings = ({ value }) => {
    let listOfObjects;

    if (value !== null && value !== "") {
      if (typeof value === "string") {
        try {
          listOfObjects = JSON.parse(value); // Parse if it's a JSON string
        } catch (error) {
          consoleLogger("Error parsing JSON string:", error);
          listOfObjects = {}; // Default to an empty object on error
        }
      } else if (typeof value === "object") {
        listOfObjects = value; // Assign directly if it's an object
      } else {
        consoleLogger("Invalid value type:", typeof value);
        listOfObjects = {};
      }
    } else {
      listOfObjects = {}; // Default to an empty object if `value` is null or empty
    }

    if (Object.keys(listOfObjects).length > 0) {
      return (
        <TableContainer sx={{ width: "100%" }}>
          <Table>
            <TableBody>
              {Object.keys(listOfObjects).map((key, idx) => (
                <TableRow key={idx}>
                  <TableCell sx={{ width: "50%" }}>{key}</TableCell>
                  <TableCell>{listOfObjects[key]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }

    return <Detail>-</Detail>;
  };

  const init = () => {
    setLoading(true);
    let index = list.findIndex((item) => item["id"] === data["id"]);
    if (index !== -1) {
      setDetails(list[index]);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, [data, refresh]);
  return (
    <>
      {!loading ? (
        <Box sx={{ width: 500 }}>
          <AppBar
            component={"nav"}
            position="sticky"
            color="inherit"
            elevation={0}
            sx={{
              top: 0,
              bottom: "auto",
              width: 500,
              right: 0,
              bgcolor: "#f9f9fc",
            }}
          >
            <Toolbar>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <ModelHeader>Partner Details</ModelHeader>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    style={{
                      textTransform: "none",
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "fit-content",
                    }}
                    onClick={handleEdit}
                    color="primary"
                    variant="outlined"
                  >
                    Edit
                  </Button>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
          <ModelWrapper>
            <Grid container spacing={3}>
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <UserProfileImageComponent
                    url={getProfileImageUrl()}
                    handleImageClick={() => {
                      setViewProfileImage(true);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>ID</Label>
                  <Detail>{dataRenderer(details["flid"])}</Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>Name</Label>
                  <Detail>{dataRenderer(details["name"])}</Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>Phone</Label>
                  <Detail>{dataRenderer(details["phone"])}</Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>Alternate Phone</Label>
                  <Detail>{dataRenderer(details["alternate_phone"])}</Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>Email</Label>
                  <Detail>{dataRenderer(details["email"])}</Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>PAN Number</Label>
                  <Detail>{dataRenderer(details["pan_number"])}</Detail>
                </InputWrapper>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Header>Address Details</Header>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>{getLocalizedText("address")}</Label>
                        <Detail>{dataRenderer(details["address"])}</Detail>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>{getLocalizedText("city")}</Label>
                        <Detail>{dataRenderer(details["city"])}</Detail>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>{getLocalizedText("state")}</Label>
                        <Detail>{dataRenderer(details["state"])}</Detail>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>{getLocalizedText("country")}</Label>
                        <Detail>{dataRenderer(details["country"])}</Detail>
                      </InputWrapper>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Header>{getLocalizedText("bank_details")}</Header>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>{getLocalizedText("bank_name")}</Label>
                        <Detail>{dataRenderer(details["bank_name"])}</Detail>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>{getLocalizedText("bank_account_number")}</Label>
                        <Detail>
                          {dataRenderer(details["bank_account_number"])}
                        </Detail>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>
                          {getLocalizedText("bank_beneficiary_name")}
                        </Label>
                        <Detail>
                          {dataRenderer(details["bank_beneficiary_name"])}
                        </Detail>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>IFSC Code</Label>
                        <Detail>{dataRenderer(details["ifsc_code"])}</Detail>
                      </InputWrapper>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Header>{getLocalizedText("company_details")}</Header>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>{getLocalizedText("company_name")}</Label>
                        <Detail>{dataRenderer(details["company_name"])}</Detail>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>{getLocalizedText("company_tax_code")}</Label>
                        <Detail>
                          {dataRenderer(details["company_tax_code"])}
                        </Detail>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>{getLocalizedText("company_address")}</Label>
                        <Detail>
                          {dataRenderer(details["company_address"])}
                        </Detail>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>{getLocalizedText("company_city")}</Label>
                        <Detail>{dataRenderer(details["company_city"])}</Detail>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>{getLocalizedText("company_state")}</Label>
                        <Detail>
                          {dataRenderer(details["company_state"])}
                        </Detail>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>{getLocalizedText("company_country")}</Label>
                        <Detail>
                          {dataRenderer(details["company_country"])}
                        </Detail>
                      </InputWrapper>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Header>{getLocalizedText("specialities")}</Header>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <RenderBulletPoints
                          value={details["specialities"]}
                        />
                      </InputWrapper>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Header>{getLocalizedText("additional_details")}</Header>
                  <RenderListOfStrings
                    value={
                      details.hasOwnProperty("additional_details")
                        ? details["additional_details"]
                        : null
                    }
                  />
                </InputWrapper>
              </Grid>
            </Grid>
          </ModelWrapper>
        </Box>
      ) : (
        <Box
          sx={{
            width: 500,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {viewProfileImage && (
        <PreviewImageDialog
          open={viewProfileImage}
          setOpen={setViewProfileImage}
          file={getProfileImageUrl()}
          isCreate={false}
        />
      )}
    </>
  );
};

export default ViewAdminPartnerSidepanel;
