import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, CircularProgress, Drawer } from "@material-ui/core";
import rootStore from "../../stores/RootStore";
import { styled } from "@mui/material/styles";
import { closeTaskApi, editTaskApi, getLeadTasksListApi } from "../../Api";
import CreateTaskComponent from "./tasks_tab_components/CreateTaskComponent";
import TableComponent from "./tasks_tab_components/TableComponent";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import MessageWithLoadingStateCustomDialog from "../alert_dialogue/MessageWithLoadingStateCustomDialog";
import CloseTaskComponent from "./tasks_tab_components/CloseTaskComponent";
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 294px);`};
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;
const TasksTab = ({
  canEditLead,
  leadID,
  openCreateTaskPanel,
  setOpenCreateTaskPanel,
  setRefresh,
  refresh
}) => {
  const [loading, setLoading] = useState(true);
  const [allTasks, setAllTasks] = useState([]);
  const [todaysTasks, setTodaysTasks] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editableData, setEditableData] = useState({});
  const [fetchingApi, setFetchingApi] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [openCloseTaskPanel, setOpenCloseTaskPanel] = useState(false);

  const getTasksList = async ({ page }) => {
    let response = await getLeadTasksListApi({
      leadID: leadID,
    });
    let todaysTasks = [];
    const startOfDay = moment().startOf("day");
    const endOfDay = moment().endOf("day");

    response.forEach((obj) => {
      const { start_time, end_time } = obj;

      // Convert ISO 8601 strings to moment objects, if not null
      const startTime = start_time ? moment(start_time) : null;
      const endTime = end_time ? moment(end_time) : null;

      // Check if the task falls within today's range
      if (
        (startTime && startTime.isBetween(startOfDay, endOfDay, null, "[]")) ||
        (endTime && endTime.isBetween(startOfDay, endOfDay, null, "[]")) ||
        (startTime &&
          endTime &&
          startTime.isBefore(startOfDay) &&
          endTime.isAfter(endOfDay))
      ) {
        todaysTasks.push(obj);
      }
    });
    setTodaysTasks(todaysTasks);
    setAllTasks(response);
  };

  const init = async () => {
    setLoading(true);
    await getTasksList({ page: 0 });

    setLoading(false);
  };
  const refetch = async () => {
    await getTasksList({ page: 0 });
    setRefresh(false);
  };
  useEffect(() => {
    if (refresh === true) {
      refetch();
    }
  }, [refresh]);

  useEffect(() => {
    init();
    return () => {
      setOpenCreateTaskPanel(false);
    };
  }, []);

  const handleStatusUpdate = async (taskID, value) => {
    setFetchingApi(true);
    setOpenSubmitResponseDialog(true);
    let payload = {
      task_status: value,
    };
    let response = await closeTaskApi({
      taskID: taskID,
      payload: payload,
    });
    if (!response.hasError()) {
      setIsSubmitFail(false);
      setRefresh(true);
      setSuccessMessage("Task status updated successfully!");
      setFetchingApi(false);
    } else {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
      setFetchingApi(false);
    }
  };
  const handlePriorityUpdate = async (taskID, value) => {
    setFetchingApi(true);
    setOpenSubmitResponseDialog(true);
    let payload = {
      priority: value,
    };
    let response = await editTaskApi({
      taskID: taskID,
      payload: payload,
    });
    if (!response.hasError()) {
      setIsSubmitFail(false);
      setRefresh(true);
      setSuccessMessage("Task priority updated successfully!");
      setFetchingApi(false);
    } else {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
      setFetchingApi(false);
    }
  };

  return (
    <>
      {!loading ? (
        <TableWrapper>
          <Accordion defaultExpanded elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              sx={{
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              Today's Tasks{" "}
              {todaysTasks.length > 0 && `(${todaysTasks.length})`}
            </AccordionSummary>
            <AccordionDetails>
              <TableComponent
                tableData={todaysTasks}
                handleEditButton={(data) => {
                  setEditableData(data);
                  setIsEdit(true);
                  setOpenCreateTaskPanel(true);
                }}
                canEditLead={canEditLead}
                handleStatusUpdate={(taskID, value) =>
                  handleStatusUpdate(taskID, value)
                }
                handlePriorityUpdate={(taskID, value) =>
                  handlePriorityUpdate(taskID, value)
                }
                handleCloseTask={(data) => {
                  setEditableData(data);
                  setOpenCloseTaskPanel(true);
                }}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              sx={{
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              All Tasks {allTasks.length > 0 && `(${allTasks.length})`}
            </AccordionSummary>
            <AccordionDetails>
              <TableComponent
                tableData={allTasks}
                handleEditButton={(data) => {
                  setEditableData(data);
                  setIsEdit(true);
                  setOpenCreateTaskPanel(true);
                }}
                canEditLead={canEditLead}
                handleStatusUpdate={(taskID, value) =>
                  handleStatusUpdate(taskID, value)
                }
                handlePriorityUpdate={(taskID, value) =>
                  handlePriorityUpdate(taskID, value)
                }
                handleCloseTask={(taskData)=>{
                  setEditableData(taskData);
                  setOpenCloseTaskPanel(true)
                }}
              />
            </AccordionDetails>
          </Accordion>
        </TableWrapper>
      ) : (
        <TableWrapper
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </TableWrapper>
      )}
      {openCreateTaskPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCreateTaskPanel}
          onClose={() => {
            setIsEdit(false);
            setOpenCreateTaskPanel(false);
          }}
        >
          <CreateTaskComponent
            setOpen={setOpenCreateTaskPanel}
            setRefresh={setRefresh}
            leadID={leadID}
            editableData={editableData}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        </Drawer>
      )}

      {openCloseTaskPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCloseTaskPanel}
          onClose={() => {
            setOpenCloseTaskPanel(false);
          }}
        >
          <CloseTaskComponent
            setOpen={setOpenCloseTaskPanel}
            setRefresh={setRefresh}
            editableData={editableData}
          />
        </Drawer>
      )}

      {openSubmitResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={openSubmitResponseDialog}
          setOpen={setOpenSubmitResponseDialog}
          successMessage={successMessage}
          errorMessage={errorMessage}
          loading={fetchingApi}
          isError={isSubmitFail}
        />
      )}
    </>
  );
};

export default observer(TasksTab);
