import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Drawer, Tooltip } from "@material-ui/core";
import { ChevronRight, Edit } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { styled } from "@mui/material/styles";
import { Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { getProductsListApi } from "../../Api";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import AddProductSidePanel from "../../components/add_product_sidepanel/AddProductSidePanel";
import ViewProductSidepanel from "../../components/view_product_sidepanel/ViewProductSidepanel";
import { FormattedAgGridColumns } from "../../ColumnUtils";
import {
  consoleLogger,
  getAccountLocalizedText,
  getLocalizedText,
} from "../../Functions";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SelectProductColumnsDialog from "../../components/select_product_columns_dialog/SelectProductColumnsDialog";
import ImportProductDialog from "../../components/import_product_components/ImportProductDialog";
import AgGridColumnWithFilterIcon from "../../components/column_header/AgGridColumnWithFilterIcon";
import { useNavigate } from "react-router-dom";
import { MoreHoriz } from "@material-ui/icons";
import ViewListModal from "../../components/add_product_line_sidepanel/components/ViewListModal";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 16px 0px;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 236px);`};
  background-color: white;
  padding: 20px;
  padding-bottom: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;

const ProductsPage = () => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openAddProductPanel, setOpenAddProductPanel] = useState(false);
  const [openViewProductPanel, setOpenViewProductPanel] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedListData, setSelectedListData] = useState({});
  const [columns, setColumns] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [openSelectColumnDialog, setOpenSelectColumnDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterQuery, setFilterQuery] = useState({});
  const [viewList, setViewList] = useState(false);

  const Open = Boolean(anchorEl);
  let navigate = useNavigate();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const generateQueryPayload = (queryList) => {
    let tempQueryPayload = {};
    for (const key in queryList) {
      let queryData = queryList[key];
      if (queryData["value"] !== null && queryData["value"] !== "") {
        tempQueryPayload[key] = queryData["value"];
      }
    }
    return tempQueryPayload;
  };

  const getProductsList = async ({ query }) => {
    setLoadingData(true);
    const queryPayload = generateQueryPayload(query);
    let response = await getProductsListApi({
      page: rootStore.productStore.listingInfo.page_number,
      page_size: rootStore.productStore.listingInfo.page_size,
      payload: { query: queryPayload },
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      rootStore.productStore.updateCount(response.item_count);
    } else {
      setRow([]);
      rootStore.productStore.updateCount(0);
    }
    setLoadingData(false);
  };

  const getProductColumns = async () => {
    await rootStore.productStore.checkAndFetchProductColumns();
    if (rootStore.productStore.selectColumnsList.length === 0) {
      rootStore.productStore.updateSelectColumnsWithDefaultList();
    }
    let formattedColumns = FormattedAgGridColumns([
      ...rootStore.productStore.selectColumnsList,
    ]);
    setColumns(formattedColumns);
  };

  const init = async () => {
    setLoading(true);
    await getProductsList({ query: filterQuery });
    await getProductColumns();
    setLoading(false);
  };

  useEffect(() => {
    if (refresh === true) {
      getProductsList({ query: filterQuery });
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handlePageSize = async (e) => {
    rootStore.productStore.udpatePageNumber(0);
    rootStore.productStore.updatePageSize(e.target.value);
    await getProductsList({ query: filterQuery });
  };
  const handlePagination = async (e, page) => {
    rootStore.productStore.udpatePageNumber(page - 1);

    await getProductsList({ query: filterQuery });
  };

  const actionColumn = [
    {
      field: "actions",
      headerName: "ACTIONS",
      flex: 1,
      minWidth: 100,
      resizable: false,
      cellRenderer: (params) => {
        return (
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsEdit(true);
              setSelectedRowData(params.data);
              setOpenAddProductPanel(true);
            }}
          >
            <Tooltip title={"Edit"}>
              <Edit
                style={{ width: "18px", height: "18px", cursor: "pointer" }}
                color="primary"
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const updateColumnsPostSelectColumn = (columnList) => {
    rootStore.productStore.updateSelectColumnsList({ columnList: columnList });
    let formattedColumns = FormattedAgGridColumns([
      ...rootStore.productStore.selectColumnsList,
    ]);
    setColumns(formattedColumns);
  };

  const handleSearch = async (searchValue, fieldName, dataType) => {
    let tempFilterQuery = { ...filterQuery };
    tempFilterQuery[fieldName] = { value: searchValue, data_type: dataType };
    setFilterQuery(tempFilterQuery);
    rootStore.productStore.udpatePageNumber(0);
    await getProductsList({
      query: tempFilterQuery,
    });
  };

  const memoizedColumns = useMemo(() => {
    return columns.map((column) => {
      let index = rootStore.productStore.columnsList.findIndex(
        (entry) => entry["column_name"] === column["field"]
      );
      if (column["field"] === "category" || column["field"] === "name") {
        if (index !== -1) {
          let columnMeta = rootStore.productStore.columnsList[index];
          return {
            ...column,
            headerComponent: AgGridColumnWithFilterIcon,
            headerComponentParams: {
              headerText: column["headerName"],
              dataType: columnMeta["data_type"],
              handleSearch: (searchValue) => {
                handleSearch(
                  searchValue,
                  column["field"],
                  columnMeta["data_type"]
                );
              },
              queryData: filterQuery?.[column["field"]]?.value || null,
            },
          };
        }
      }
      if (index !== -1) {
        let columnMeta = rootStore.productStore.columnsList[index];
        if (columnMeta["data_type"] === "json_array_string") {
          return {
            ...column,
            cellRenderer: (params) => {
              if (params.data[column["field"]].length > 0) {
                return (
                  <MoreHoriz
                    style={{
                      marginRight: "20px",
                      width: "18px",
                      height: "18px",
                      cursor: "pointer",
                    }}
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setSelectedListData({
                        display_name: columnMeta["display_name"],
                        value:
                          typeof params.data[column["field"]] === "string" &&
                          params.data[column["field"]]
                            ? params.data[column["field"]].split(",")
                            : params.data[column["field"]],
                      });
                      setViewList(true);
                    }}
                  />
                );
              } else {
                return "-";
              }
            },
          };
        }
      }
      return column;
    });
  }, [columns, filterQuery]);

  return !loading ? (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Header>{getAccountLocalizedText("products")}</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
            </TopWrapper>
            <ButtonWrapper>
              <Button
                onClick={() => {
                  setOpenAddProductPanel(true);
                }}
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "fit-content",
                  whiteSpace: "nowrap",
                }}
              >
                {getAccountLocalizedText("add_product")}
              </Button>
              <IconButton
                style={{ padding: "0px" }}
                aria-label="more"
                id="long-button"
                aria-controls={Open ? "long-menu" : undefined}
                aria-expanded={Open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                style={{ display: "flex", flexDirection: "column" }}
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={Open}
                onClose={handleMenuClose}
                PaperProps={{
                  style: {
                    width: "fit-content",
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    setOpenSelectColumnDialog(true);
                    handleMenuClose();
                  }}
                >
                  {getLocalizedText("select_columns")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/products/builder");
                    handleMenuClose();
                  }}
                >
                  {getAccountLocalizedText("manufacturers")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/products/product-line");
                    handleMenuClose();
                  }}
                >
                  {getAccountLocalizedText("product_lines")}
                </MenuItem>
              </Menu>
            </ButtonWrapper>
          </Row>
          {!loadingData ? (
            <TableWrapper>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={memoizedColumns.concat(actionColumn)}
                  animateRows={true}
                  suppressCellFocus
                  pagination={false}
                  paginationPageSize={
                    rootStore.productStore.listingInfo.page_size
                  }
                  className="paginated-ag-grid"
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    setSelectedRowData(row.data);
                    setOpenViewProductPanel(true);
                  }}
                />
              </div>
            </TableWrapper>
          ) : (
            <TableWrapper
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingAnimationComponent size={"small"} />
            </TableWrapper>
          )}
          <PaginationWrapper>
            <PaginationComponent
              page_no={rootStore.productStore.listingInfo.page_number}
              row={row}
              page_size={rootStore.productStore.listingInfo.page_size}
              size={rootStore.productStore.listingInfo.page_size}
              count={rootStore.productStore.listingInfo.count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </PaginationWrapper>
        </Container>
      </ParentContainer>
      <Footer />
      {openAddProductPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openAddProductPanel}
          onClose={() => {
            setIsEdit(false);
            setSelectedRowData({});
            setOpenAddProductPanel(false);
          }}
        >
          <AddProductSidePanel
            setRefresh={setRefresh}
            handleClose={() => {
              setIsEdit(false);
              setOpenAddProductPanel(false);
            }}
            isEdit={isEdit}
            productID={selectedRowData["id"]}
          />
        </Drawer>
      )}

      {openViewProductPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openViewProductPanel}
          onClose={() => {
            setSelectedRowData({});
            setOpenViewProductPanel(false);
          }}
        >
          <ViewProductSidepanel
            productID={selectedRowData["id"]}
            setOpen={setOpenViewProductPanel}
            open={openViewProductPanel}
            scope="products_page"
          />
        </Drawer>
      )}
      {viewList && (
        <ViewListModal
          setOpen={setViewList}
          open={viewList}
          title={selectedListData["display_name"]}
          list={selectedListData["value"]}
        />
      )}
      {openSelectColumnDialog && (
        <SelectProductColumnsDialog
          handleClose={() => {
            setOpenSelectColumnDialog(false);
          }}
          open={openSelectColumnDialog}
          handlePostApply={(columnList) => {
            updateColumnsPostSelectColumn(columnList);
            setOpenSelectColumnDialog(false);
          }}
        />
      )}
    </>
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingAnimationComponent size={"small"} />
    </Box>
  );
};

export default observer(ProductsPage);
