import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Divider,
  Modal,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  consoleLogger,
  getLocalDateTime,
  getLocalizedText,
} from "../../Functions";
import CloseIcon from "@mui/icons-material/Close";
import rootStore from "../../stores/RootStore";
import moment from "moment";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "500px",
  textAlign: "left",
  margin: "auto",
  backgroundColor: "white",
  height: "fit-content",
}));

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  height: "fit-content",
  width: "fit-content",
  border: "none",
  outline: "none",
  backgroundColor: "white",
  boxShadow:
    "0px 3px 6px rgba(0, 0, 0, 0.05), 0px 3px 20px rgba(24, 93, 210, 0.12)",
  border: "1px solid #c5d7f1",
  borderRadius: "8px",
  overflow: "hidden",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "18px",
  fontFamily: "Open Sans",
  color: "#4d4e4f",
}));

const Title = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 14px Open Sans",
  color: "#5A606680",
}));
const Desc = styled(Typography)(({ theme }) => ({
  font: "normal normal 500 14px Open Sans",
  color: "#5A6066",
  wordWrap: "break-word",
}));
const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));
const EmptyWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
}));

const EmptyText = styled(Typography)(({ theme }) => ({
  margin: "0px",
  fontWeight: 500,
  fontSize: "14px",
  fontFamily: "'Open Sans', sans-serif",
  color: "#4d4e4f",
  textAlign: "center",
}));

const ViewSegmentFiltersModal = (props) => {
  const columnsList = rootStore.userStore.AllColumnsList;
  const [filtersData, setFiltersData] = useState({});

  useEffect(() => {
    let new_list = {};
    if (props.filterData !== null) {
      Object.keys(props.filterData).forEach((key) => {
        if (key !== "custom_params") {
          new_list[key] = props.filterData[key];
        } else {
          let custom_params_filters = props.filterData["custom_params"];
          Object.keys(custom_params_filters).forEach((custom_key) => {
            let new_key = "custom_params_" + custom_key;
            new_list[new_key] = custom_params_filters[custom_key];
          });
        }
      });
    }

    setFiltersData(new_list);
  }, []);

  const handleClose = () => {
    props.setOpen(false);
  };

  const getFilterFieldData = (filter_field) => {
    let index = columnsList.findIndex(
      (column) => column["field"] === filter_field
    );
    if (index !== -1) {
      return columnsList[index];
    }
    return null;
  };
  const getFilterValue = (key) => {
    const filter = filtersData[key];

    if (
      filter &&
      typeof filter === "object" &&
      filter.hasOwnProperty("value")
    ) {
      return filter.value;
    }
    return filter ?? "-";
  };
  const convertUtcToLocal = (utcDate) =>
    moment.utc(utcDate).local().format("YYYY-MM-DD");
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Container>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  position: "relative",
                }}
              >
                <HeaderWrapper>
                  <Header>{getLocalizedText("segment_filters")}</Header>
                </HeaderWrapper>

                <IconButton
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    color: "#555",
                    "&:hover": {
                      color: "#000",
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider
              style={{
                margin: "16px",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            />
            <Box
              style={{
                height: "300px",
                overflowY: "auto",
                overflowX: "hidden",
                padding: "0px 16px",
                marginBottom: "16px",
              }}
            >
              {Object.keys(filtersData).map((key, i) => {
                let field_data = getFilterFieldData(key);
                if (field_data !== null) {
                  if (field_data["filter_type"] === "list") {
                    return (
                      <Box key={i}>
                        <Box
                          style={{
                            marginTop: "8px",
                            marginBottom: "8px",
                          }}
                        >
                          <Grid container alignItems="center">
                            <Grid item xs={4}>
                              <Title>{field_data["headerName"]}</Title>
                            </Grid>
                            <Grid item xs={1}>
                              <Divider
                                orientation="vertical"
                                sx={{
                                  height: "20px",
                                  color: "#3535354D",
                                }}
                              />
                            </Grid>
                            <Grid item xs={7} paddingLeft={"8px"}>
                              {getFilterValue(key).map((item) => {
                                return <Desc>{item}</Desc>;
                              })}
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider orientation="horizontal" />
                      </Box>
                    );
                  } else if (
                    field_data["filter_type"] === "range" &&
                    (field_data["data_type"] === "date" ||
                      field_data["data_type"] === "datetime")
                  ) {
                    return (
                      <Box key={i}>
                        <Box
                          style={{
                            marginTop: "8px",
                            marginBottom: "8px",
                          }}
                        >
                          <Grid container alignItems="center">
                            <Grid item xs={4}>
                              <Title>{field_data["headerName"]}</Title>
                            </Grid>
                            <Grid item xs={1}>
                              <Divider
                                orientation="vertical"
                                sx={{
                                  height: "20px",
                                  color: "#3535354D",
                                }}
                              />
                            </Grid>
                            <Grid item xs={7} paddingLeft={"8px"}>
                              <Desc>
                                {`${convertUtcToLocal(
                                  getFilterValue(key)["start"]
                                )} - ${convertUtcToLocal(
                                  getFilterValue(key)["end"]
                                )}`}
                              </Desc>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider orientation="horizontal" />
                      </Box>
                    );
                  } else {
                    return (
                      <Box key={i}>
                        <Box
                          style={{
                            marginTop: "8px",
                            marginBottom: "8px",
                          }}
                        >
                          <Grid container alignItems="center">
                            <Grid item xs={4}>
                              <Title>{field_data["headerName"]}</Title>
                            </Grid>
                            <Grid item xs={1}>
                              <Divider
                                orientation="vertical"
                                sx={{
                                  height: "20px",
                                  color: "#3535354D",
                                }}
                              />
                            </Grid>
                            <Grid item xs={7} paddingLeft={"8px"}>
                              {field_data.data_type === "date" ? (
                                <Desc>
                                  {convertUtcToLocal(getFilterValue(key))}
                                </Desc>
                              ) : field_data.data_type === "datetime" ? (
                                <Desc>
                                  {getLocalDateTime(getFilterValue(key))}
                                </Desc>
                              ) : (
                                <Desc>{getFilterValue(key)}</Desc>
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider orientation="horizontal" />
                      </Box>
                    );
                  }
                }
              })}
              {Object.keys(filtersData).length === 0 && (
                <EmptyWrapper>
                  <EmptyText>{getLocalizedText("no_data_found")}</EmptyText>
                </EmptyWrapper>
              )}
            </Box>
          </Container>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(ViewSegmentFiltersModal);
