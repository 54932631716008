import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  TextField,
  Menu,
  MenuItem,
  CardActionArea,
  Backdrop,
  Tooltip,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { getLocalizedText, consoleLogger, dataRenderer } from "../../Functions";
import moment from "moment";
import { Close } from "@material-ui/icons";
import WhatsappChatMessageComponent from "./WhatsappChatMessageComponent";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DescriptionIcon from "@mui/icons-material/Description";
import SendIcon from "@mui/icons-material/Send";
import rootStore from "../../stores/RootStore";
import TemplateMessageSendPopupComponent from "./TemplateMessageSendPopupComponent";
import {
  getWhatsappChatMessagesApi,
  sendLeadWhatsAppBusinessChatApi,
  sendLeadWhatsAppBusinessChatDocumentApi,
  sendLeadWhatsAppBusinessChatImageApi,
  sendLeadWhatsAppBusinessChatTextApi,
  sendLeadWhatsAppBusinessChatVideoApi,
  uploadLeadWhatsAppBusinessMediaApi,
} from "../../Api";
import WhatsappChatImageSendScreen from "./WhatsappChatImageSendScreen";
import { Refresh } from "@mui/icons-material";

const ModelWrapper = styled(Box)(({ theme }) => ({
  width: "500px",
  margin: "auto",
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const ModelHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
}));

const ChatBoxTextField = styled(TextField)(({ theme }) => ({
  width: "95%",
  margin: "auto",
  backgroundColor: "white",
  borderRadius: "8px",
  "& .MuiInputBase-root": {
    maxHeight: "150px",
    overflowY: "auto",
    padding: "8px",
  },
  "& textarea": {
    maxHeight: "150px",
    overflowY: "auto",
    resize: "none",
  },
}));

const ChatBoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "fit-content",
  minHeight: "8%",
  padding: "8px",
  overflowY: "auto",
}));

const MessageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  flex: 1,
  padding: "15px",
  position: "relative",
  overflow: "auto",
  boxSizing: "border-box",
  scrollBehavior: "auto",
}));

const LeadDetailsWABAChatSidepanel = (props) => {
  const inputRef = useRef(null);
  const documentInputRef = useRef(null);
  const anchorRef = useRef(null);
  const messageContainerRef = useRef(null);
  const templateList = [...rootStore.authStore.metaWabaBusinessTemplateList];
  const [availableTemplates, setAvailableTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openTemplateSelect, setOpenTemplateSelect] = useState(false);
  const [openImageSendView, setOpenImageSendView] = useState(false);
  const [showTopLoader, setShowTopLoader] = useState(false);
  const [noMoreMessages, setNoMoreMessages] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [message, setMessage] = useState("");
  const [caption, setCaption] = useState(""); //image caption
  const [infoBubbleMessage, setInfoBubbleMessage] = useState("");
  const [chatTextDisabled, setChatTextDisabled] = useState(true);
  const [templateSendDisabled, setTemplateSendDisabled] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [chatDetails, setChatDetails] = useState([]);
  const [nextCursor, setNextCursor] = useState(0);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [providerData, setProviderData] = useState({});

  const [anchorEl, setAnchorEl] = React.useState(null);
  const Open = Boolean(anchorEl);

  const handleTemplateMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleStartInfoClick = () => {
    setAnchorEl(anchorRef.current);
  };

  const mergeChatDetails = (chatDetails, newList) => {
    const groupedChatDetails = chatDetails.reduce((groups, group) => {
      groups[group.headerName] = group.messages;
      return groups;
    }, {});

    // Iterate over newList and add each message to the corresponding date group in groupedChatDetails
    newList.forEach((message) => {
      const messageDate = moment(getMessageTime(message)).format("YYYY-MM-DD");

      if (groupedChatDetails[messageDate]) {
        // If the date exists, push the message into that day's array
        groupedChatDetails[messageDate].push(message);
      } else {
        // If the date does not exist, create a new array for that date and add the message
        groupedChatDetails[messageDate] = [message];
      }
    });

    // Sort the dates in ascending order (newest at the bottom)
    const sortedDates = Object.keys(groupedChatDetails).sort();

    // Convert groupedChatDetails back into an array of objects with the position key
    return sortedDates.map((date, index) => ({
      headerName: date,
      messages: sortMessages(groupedChatDetails[date]),
      position: index, // Add the position key
    }));
  };

  const handleScroll = async () => {
    if (messageContainerRef.current) {
      if (messageContainerRef.current.scrollTop === 0) {
        if (!noMoreMessages) {
          console.log("load more messages");

          setShowTopLoader(true); // User has reached the top
          const newList = await getNextCheckpointMessages();
          if (newList.length === 0) {
            //set flag to never check again if list is empty
            setNoMoreMessages(true);
            setShowTopLoader(false);
          } else {
            //if new messages are there after fetch, append to current list and sort
            let mergedChatDetails = mergeChatDetails(chatDetails, newList);
            //consoleLogger("merged:: ", mergeChatDetails);
            setChatDetails(mergedChatDetails);
            setShowTopLoader(false);
          }
        }
      }
    }
  };

  const handleWheel = async (event) => {
    if (messageContainerRef.current) {
      const isScrollable =
        messageContainerRef.current.scrollHeight >
        messageContainerRef.current.clientHeight;

      if (
        !isScrollable && // only when container is not scrollable
        event.deltaY < 0 &&
        !noMoreMessages &&
        !showTopLoader
      ) {
        console.log("load more messages on wheel");

        setShowTopLoader(true); // User has reached the top
        const newList = await getNextCheckpointMessages();
        if (newList.length === 0) {
          //set flag to never check again if list is empty
          setNoMoreMessages(true);
          setShowTopLoader(false);
        } else {
          //if new messages are there after fetch, append to current list and sort
          let mergedChatDetails = mergeChatDetails(chatDetails, newList);
          //const completeList = newList.concat(chatDetails);
          //consoleLogger("merged:: ", mergeChatDetails);
          setChatDetails(mergedChatDetails);
          setShowTopLoader(false);
        }
      }
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messageContainerRef.current) {
        messageContainerRef.current.scrollTop =
          messageContainerRef.current.scrollHeight;
      }
    }, 100);
  };

  const getDateHeaderText = (date) => {
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");

    if (moment(date).isSame(today, "day")) {
      return "TODAY";
    } else if (moment(date).isSame(yesterday, "day")) {
      return "YESTERDAY";
    } else if (moment(date)) {
      return moment(date).format("YYYY-MM-DD");
    }
  };

  const getMessageTime = (messageData) => {
    return messageData.created_at;
  };

  const handleAddMedia = (event) => {
    setSelectedMedia(event.target.files);
    setOpenImageSendView(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage({ isTemplate: false, variableDetails: {} });
    }
  };

  const handleSendMessage = async ({
    isTemplate = false,
    variableDetails = {},
    templateFile = null,
  }) => {
    if (isTemplate) {
      let incoming_components = [];
      //adding header media if present
      if (templateFile !== null) {
        const mediaID = await uploadWABAMedia(templateFile[0]);
        const fileType = templateFile[0].type.split("/")[0]; // image or video

        incoming_components.push({
          type: "header",
          parameters: [{ type: fileType, [fileType]: { id: mediaID } }],
        });
      }

      //sending payload with template data
      let bodyPayload = [];

      bodyPayload = variableDetails.map((detail) => ({
        type: "text",
        text: detail["value"],
      }));

      incoming_components.push({
        type: "body",
        parameters: bodyPayload,
      });

      let payload = {
        wt_id: selectedTemplate.id,
        component_parameters: incoming_components,
      };
      consoleLogger("payload:: ", payload);
      await sendTemplateMessage(payload, bodyPayload);
    } else {
      if (message !== "") {
        let payload = {
          lead_id: props.leadData.id,
          wp_id: providerData.id,
          text: message,
        };
        consoleLogger("payload:: ", payload);
        await sendTextMessage(payload);
      }
    }
  };

  const sendTemplateMessage = async (payload, bodyPayload) => {
    const response = await sendLeadWhatsAppBusinessChatApi({
      leadID: props.leadData.id,
      payload: payload,
    });

    //building the message structure to use if api fails
    let newMessage = {
      id: "",
      billable: null,
      delivered_at: null,
      direction: "outbound",
      message_parameters: [
        {
          type: "body",
          parameters: bodyPayload,
        },
      ],
      message_type: null,
      read_at: null,
      created_at: moment().toISOString(),
      status: null,
      whatsapp_template_id: selectedTemplate.id,
      user: { name: rootStore.userStore.UserData.name },
    };

    if (!response.hasError()) {
      newMessage = response.data; //will get message structure if response is there
    }

    setMessage("");
    setSelectedTemplate({});
    let mergedList = mergeChatDetails(chatDetails, [newMessage]);
    setChatDetails(mergedList);
  };

  const sendTextMessage = async (payload) => {
    const response = await sendLeadWhatsAppBusinessChatTextApi({
      leadID: props.leadData.id,
      payload: payload,
    });

    //building the message structure to use if api fails
    let newMessage = {
      id: "",
      delivered_at: null,
      direction: "outbound",
      message: {
        body: message,
      },
      message_parameters: null,
      message_type: "text",
      read_at: null,
      created_at: moment().toISOString(),
      status: null,
      whatsapp_template_id: null,
      user: { name: rootStore.userStore.UserData.name },
    };

    if (!response.hasError()) {
      newMessage = response.data; //will get message structure if response is there
    }

    setMessage("");
    setSelectedTemplate({});
    let mergedList = mergeChatDetails(chatDetails, [newMessage]);
    setChatDetails(mergedList);
  };

  const uploadWABAMedia = async (file) => {
    const formData = new FormData();
    formData.append("wp_id", providerData.id);
    formData.append("file", file);

    const response = await uploadLeadWhatsAppBusinessMediaApi({
      leadID: props.leadData.id,
      payload: formData,
    });

    if (!response.hasError()) {
      return response.data.media_id;
    } else {
      console.log(response.errorMessage);
      return "";
    }
  };

  const handleSendMedia = async (file) => {
    setOpenImageSendView(false);
    const fileType = file.type;

    const mediaID = await uploadWABAMedia(file);

    const messagePayload = {
      wp_id: providerData.id,
      media_id: mediaID,
    };

    if (fileType.startsWith("image/")) {
      // image send api
      await sendImageMessage(messagePayload);
    } else if (fileType.startsWith("video/")) {
      // video send api
      await sendVideoMessage(messagePayload);
    } else if (fileType.startsWith("application/")) {
      // document send api
      await sendDocumentMessage(messagePayload);
    }
  };

  const sendDocumentMessage = async (messagePayload) => {
    const messagesResponse = await sendLeadWhatsAppBusinessChatDocumentApi({
      leadID: props.leadData.id,
      payload: messagePayload,
    });

    let newMessage = {
      id: "",
      delivered_at: null,
      direction: "outbound",
      message: {
        document: {
          id: messagePayload.media_id,
        },
      },
      message_parameters: null,
      message_type: "document",
      read_at: null,
      created_at: moment().toISOString(),
      status: null,
      whatsapp_template_id: null,
      user: { name: rootStore.userStore.UserData.name },
    };

    if (!messagesResponse.hasError()) {
      newMessage = messagesResponse.data;
    }

    setMessage("");
    setSelectedTemplate({});
    let mergedList = mergeChatDetails(chatDetails, [newMessage]);
    setChatDetails(mergedList);
  };

  const sendImageMessage = async (messagePayload) => {
    const messagesResponse = await sendLeadWhatsAppBusinessChatImageApi({
      leadID: props.leadData.id,
      payload: messagePayload,
    });

    let newMessage = {
      id: "",
      delivered_at: null,
      direction: "outbound",
      message: {
        image: {
          id: messagePayload.media_id,
        },
      },
      message_parameters: null,
      message_type: "image",
      read_at: null,
      created_at: moment().toISOString(),
      status: null,
      whatsapp_template_id: null,
      user: { name: rootStore.userStore.UserData.name },
    };

    if (!messagesResponse.hasError()) {
      newMessage = messagesResponse.data;
    }

    setMessage("");
    setSelectedTemplate({});
    let mergedList = mergeChatDetails(chatDetails, [newMessage]);
    setChatDetails(mergedList);
  };

  const sendVideoMessage = async (messagePayload) => {
    const messagesResponse = await sendLeadWhatsAppBusinessChatVideoApi({
      leadID: props.leadData.id,
      payload: messagePayload,
    });

    let newMessage = {
      id: "",
      delivered_at: null,
      direction: "outbound",
      message: {
        video: {
          id: messagePayload.media_id,
        },
      },
      message_parameters: null,
      message_type: "video",
      read_at: null,
      created_at: moment().toISOString(),
      status: null,
      whatsapp_template_id: null,
      user: { name: rootStore.userStore.UserData.name },
    };

    if (!messagesResponse.hasError()) {
      newMessage = messagesResponse.data;
    }

    setMessage("");
    setSelectedTemplate({});
    let mergedList = mergeChatDetails(chatDetails, [newMessage]);
    setChatDetails(mergedList);
  };

  function hasInboundToday() {
    const today = moment().startOf("day"); // Get the start of today's day

    for (let i = 0; i < chatDetails.length; i++) {
      const messages = chatDetails[i].messages;

      if (Array.isArray(messages)) {
        for (let j = 0; j < messages.length; j++) {
          const message = messages[j];

          if (message.direction === "inbound") {
            const messageTime = moment(message.created_at);
            if (messageTime.isSame(today, "day")) {
              return true; // Found at least one inbound message for today
            }
          }
        }
      }
    }

    return false; // No inbound message found for today
  }

  function hasOutbound() {
    for (let i = 0; i < chatDetails.length; i++) {
      const messages = chatDetails[i].messages;

      if (Array.isArray(messages)) {
        for (let j = 0; j < messages.length; j++) {
          const message = messages[j];

          if (message.direction === "outbound") {
            return true;
          }
        }
      }
    }

    return false;
  }

  const handleTemplateSelect = (template) => {
    const templateJSON = { ...template };
    setSelectedTemplate(templateJSON);
    setOpenTemplateSelect(true);
  };

  const handleDrawerClose = () => {
    props.setOpen(false);
  };

  const getNextCheckpointMessages = async () => {
    let queryParams = {
      next_cursor: nextCursor,
    };
    const response = await getWhatsappChatMessagesApi({
      leadID: props.leadData.id,
      queryParams: queryParams,
    });
    setNextCursor(response.next_cursor);
    return response.messages;
  };

  const refetchChatMessages = async () => {
    const response = await getWhatsappChatMessagesApi({
      leadID: props.leadData.id,
    });
    let sortedMessagesResponse = sortMessages(response.messages);
    setNextCursor(response.next_cursor);
    let groupedMessages = groupMessagesByDate(sortedMessagesResponse);
    consoleLogger("groupedMessages:: ", groupedMessages);

    setChatDetails(groupedMessages);
    setRefresh(false);
    consoleLogger("res:: ", response);
  };

  const sortMessages = (messagesList) => {
    return messagesList.sort((a, b) => {
      return moment(getMessageTime(a)).diff(moment(getMessageTime(b)));
    });
  };

  const isTemplateSentWithinLast24Hours = () => {
    for (let i = 0; i < chatDetails.length; i++) {
      const messages = chatDetails[i].messages;

      if (Array.isArray(messages)) {
        for (let j = 0; j < messages.length; j++) {
          const message = messages[j];

          if (
            message.direction === "outbound" &&
            message.whatsapp_template_id !== null &&
            message.created_at
          ) {
            const messageTime = moment(message.created_at);
            const currentTime = moment();
            const timeDifferenceInHours = currentTime.diff(
              messageTime,
              "hours"
            );

            if (timeDifferenceInHours <= 24) {
              return true; // Found a template message within 24 hours
            }
          }
        }
      }
    }

    return false;
  };

  const groupMessagesByDate = (messages) => {
    const groupedMessages = messages.reduce((groups, message) => {
      const date = moment(getMessageTime(message)).format("YYYY-MM-DD");

      if (!groups[date]) {
        groups[date] = [];
      }

      groups[date].push(message);
      return groups;
    }, {});

    // sort the dates in ascending order (newest at the bottom)
    const sortedDates = Object.keys(groupedMessages).sort();

    return sortedDates.map((date, index) => ({
      headerName: date,
      messages: groupedMessages[date],
      position: index,
    }));
  };

  const init = async () => {
    //fetch provider data
    const currentProjectID = rootStore.authStore.currentProject.id;
    let index = props.providerResponse.findIndex(
      (provider) => provider["project"]["id"] === currentProjectID
    );
    if (index !== -1) {
      setProviderData(props.providerResponse[index]);
    }

    //set templates - showing only approved templates
    let templates = [];

    for (let i = 0; i < templateList.length; i++) {
      if (
        templateList[i].project !== null &&
        templateList[i].project !== "" &&
        templateList[i].project.id === currentProjectID &&
        templateList[i]["status"] === "APPROVED"
      ) {
        templates.push(templateList[i]);
      }
    }
    setAvailableTemplates(templates);

    //fetch messages
    const response = await getWhatsappChatMessagesApi({
      leadID: props.leadData.id,
    });

    let sortedMessagesResponse = sortMessages(response.messages);
    setNextCursor(response.next_cursor);
    let groupedMessages = groupMessagesByDate(sortedMessagesResponse);
    consoleLogger("groupedMessages:: ", groupedMessages);

    setChatDetails(groupedMessages);

    consoleLogger("res:: ", sortedMessagesResponse);

    setLoading(false);
    scrollToBottom();
  };
  useEffect(() => {
    //set
    setChatResponseDisabled();
  }, [chatDetails]);

  useEffect(() => {
    //update the corresponding message status in chatDetails
    let newChatDetails = [...chatDetails];
    let messageDataToUpdate = {
      ...rootStore.wabaChatNotificationState.leadChatStatusState,
    };
    // consoleLogger("useEffect in lead chat:: ", messageDataToUpdate);
    // consoleLogger("messages:: ", newChatDetails);

    newChatDetails.forEach((item) => {
      item.messages.forEach((message) => {
        if (message.id === messageDataToUpdate.waba_message_id) {
          message.status = messageDataToUpdate.status;
          consoleLogger(
            "match found and updated msg id:: ",
            message.waba_message_id
          );
          consoleLogger("msg:: ", message);
        }
      });
    });
    setChatDetails(newChatDetails);
  }, [rootStore.wabaChatNotificationState.leadChatStatusState]);

  useEffect(() => {
    //refetch if current lead gets a notification
    const notifications =
      rootStore.wabaChatNotificationState.getNotificationsForProject(
        rootStore.authStore.currentProject.id
      );
    const index = notifications.findIndex((notification) => {
      return notification.lead_id === props.leadData.id;
    });

    if (index !== -1) {
      consoleLogger("refetch on websocket notification");
      refetchChatMessages();
      rootStore.wabaChatNotificationState.setShowNotificationBadgeFlag(
        rootStore.authStore.currentProject.id
      );
    }
  }, [rootStore.wabaChatNotificationState.notificationsForProject]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (refresh) {
      refetchChatMessages();
    }
  }, [refresh]);

  function checkIfThreeMessagesInARowAreSent() {
    let outboundCount = 0;

    // Loop through chatDetails from last to first
    for (let i = chatDetails.length - 1; i >= 0; i--) {
      const messages = chatDetails[i].messages;

      if (Array.isArray(messages)) {
        // Loop through the messages array from last to first
        for (let j = messages.length - 1; j >= 0; j--) {
          const message = messages[j];

          if (message.direction === "outbound") {
            outboundCount++;
            if (outboundCount === 3) {
              return true; // If last three messages are "outbound", return true
            }
          } else {
            return false; // If a message is not "outbound", return false
          }
        }
      }
    }

    return false; // If less than three "outbound" messages are found
  }

  //set chat text field disable flag and info texts based on message inbound/outbound and other checks
  const setChatResponseDisabled = () => {
    setInfoBubbleMessage("");
    let sendTemplateDisableFlag = false;
    let chatTextDisableFlag = false;
    // if not messages are there, enabling only send template
    if (chatDetails.length === 0) {
      setInfoBubbleMessage(
        getLocalizedText(
          "send_pre_approved_template_message_to_start_the_conversation"
        )
      );
      sendTemplateDisableFlag = false;
      chatTextDisableFlag = true;
    } else {
      //if there is an inbound today, enabling all
      if (hasInboundToday()) {
        sendTemplateDisableFlag = false;
        chatTextDisableFlag = false;
        //else check if a template was send within last 24 hours
      } else {
        //disable all if it was sent
        if (isTemplateSentWithinLast24Hours()) {
          sendTemplateDisableFlag = true;
          chatTextDisableFlag = true;
        } else {
          //else only enabling send template
          sendTemplateDisableFlag = false;
          chatTextDisableFlag = true;
          setInfoBubbleMessage(
            getLocalizedText(
              "free_text_messages_are_enabled_once_the_lead_replies_to_your_template"
            )
          );
        }
      }

      //disabling last check
      //   //if there are more than 3 outbound messages in same day, disabling all send options
      //   //don't restrict if the last message was more than 24 hrs old
      //   if (
      //     checkIfThreeMessagesInARowAreSent() &&
      //     !isNewestMessageOlderThan24Hours()
      //   ) {
      //     setInfoBubbleMessage(
      //       getLocalizedText(
      //         "you_have_sent_multiple_messages_please_wait_for_the_customer_to_respond"
      //       )
      //     );
      //     sendTemplateDisableFlag = true;
      //     chatTextDisableFlag = true;
      //   }
      // }
    }

    //opt in status check
    if (
      props.leadData.hasOwnProperty("optin_status") &&
      props.leadData.optin_status === false
    ) {
      //disabling all if opt in status for contact is false
      sendTemplateDisableFlag = true;
      chatTextDisableFlag = true;
      setInfoBubbleMessage(
        getLocalizedText(
          "contact_has_opted_out_of_whatsapp_messages_to_reconnect_they_must_opt_in"
        )
      );
    }

    setTemplateSendDisabled(sendTemplateDisableFlag);
    setChatTextDisabled(chatTextDisableFlag);
  };

  const RenderStartInfoTextComponent = () => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          position: "sticky",
          zIndex: 10,
          top: 0,
          marginTop: "8px",
        }}
      >
        <CardActionArea
          disabled={chatDetails.length !== 0 || templateSendDisabled}
          sx={{ width: "fit-content", borderRadius: "6px" }}
          onClick={handleStartInfoClick}
        >
          <Box
            style={{
              width: "fit-content",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#ffeecd",
              boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
              padding: "10px 14px",
              borderTop: "1px solid #ffeecd",
              borderBottom: "1px solid #ffeecd",
              borderRadius: "6px",
            }}
          >
            <Typography
              sx={{
                color: "#54656f",
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              {infoBubbleMessage}
            </Typography>
          </Box>
        </CardActionArea>
      </Box>
    );
  };

  const RenderTopFetchLoaderComponent = () => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "12px",
        }}
      >
        <Box
          sx={{
            width: 44,
            height: 44,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#F0F2F5",
          }}
        >
          <CircularProgress style={{ color: "#25D366" }} size="22px" />
        </Box>
      </Box>
    );
  };

  return !loading ? (
    <>
      <Box role="presentation" sx={{ height: "100%", overflow: "hidden" }}>
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            <ModelHeader>
              {getLocalizedText("whatsapp") +
                ": " +
                dataRenderer(props.leadData.name)}
            </ModelHeader>
            <IconButton
              onClick={() => {
                setRefresh(true);
              }}
              variant="contained"
              style={{
                height: "18px",
                width: "18px",
                position: "absolute",
                top: "20px",
                right: "60px",
                cursor: "pointer",
              }}
            >
              <Refresh />
            </IconButton>
            <IconButton
              onClick={handleDrawerClose}
              style={{
                height: "18px",
                width: "18px",
                position: "absolute",
                top: "20px",
                right: "30px",
                cursor: "pointer",
              }}
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>

        <ModelWrapper
          sx={{
            height: "calc(100vh - 64px)",
            overflow: "hidden",
          }}
        >
          {!openImageSendView ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%", // or specific height
                  overflow: "hidden",
                  backgroundColor: "#e5ddd5",
                }}
              >
                <Backdrop
                  sx={(theme) => ({
                    color: "#fff",
                    zIndex: theme.zIndex.drawer + 1,
                  })}
                  style={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 100,
                  }}
                  open={refresh}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <MessageContainer
                  ref={messageContainerRef}
                  onScroll={handleScroll}
                  onWheel={handleWheel}
                >
                  {showTopLoader && <RenderTopFetchLoaderComponent />}
                  <Box sx={{ flex: 1 }}></Box>
                  {/* showing new chat info at the start of chat if chat is empty */}
                  {chatDetails
                    .sort((a, b) => a.position - b.position)
                    .map((group, i) => (
                      <Box key={i} sx={{ width: "100%" }}>
                        {/* Sticky Header */}
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            position: "sticky",
                            zIndex: 10,
                            top: 0,
                            marginBottom: "8px",
                          }}
                        >
                          <Box
                            style={{
                              width: "fit-content",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#f5f5f5",
                              boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                              padding: "8px 12px",
                              borderTop: "1px solid #e0e0e0",
                              borderBottom: "1px solid #e0e0e0",
                              borderRadius: "8px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#4d4e4f",
                                textAlign: "center",
                                fontSize: "12px",
                              }}
                            >
                              {getDateHeaderText(group.headerName)}
                            </Typography>
                          </Box>
                        </Box>
                        {group.messages.map((message, index) => (
                          <WhatsappChatMessageComponent
                            key={index}
                            data={message}
                            templateList={templateList}
                            id={props.leadData.id}
                          />
                        ))}
                      </Box>
                    ))}
                  {infoBubbleMessage !== "" && <RenderStartInfoTextComponent />}
                </MessageContainer>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#f0f2f5",
                  padding: "6px",
                }}
              >
                <IconButton
                  ref={anchorRef}
                  disabled={templateSendDisabled}
                  onClick={handleTemplateMenuClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Tooltip title="Attach Photo & Video">
                  <IconButton
                    disabled={chatTextDisabled}
                    onClick={() => {
                      inputRef.current.click();
                    }}
                  >
                    <AddPhotoAlternateIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Attach Document">
                  <IconButton
                    disabled={chatTextDisabled}
                    onClick={() => {
                      documentInputRef.current.click();
                    }}
                  >
                    <DescriptionIcon />
                  </IconButton>
                </Tooltip>
                <input
                  style={{ display: "none" }}
                  type="file"
                  ref={inputRef}
                  accept=".xbm,.tiff,.tif,.ico,.gif,.svg,.jpeg,.jpg,.svgz,.webp,.png,.bmp,.pjp,.apng,.mp4"
                  onClick={() => {
                    inputRef.current.value = "";
                  }}
                  onChange={(event) => handleAddMedia(event)}
                />
                <input
                  style={{ display: "none" }}
                  type="file"
                  ref={documentInputRef}
                  accept=".pdf"
                  onClick={() => {
                    documentInputRef.current.value = "";
                  }}
                  onChange={(event) => handleAddMedia(event)}
                />
                <ChatBoxContainer>
                  <Menu
                    style={{ display: "flex", flexDirection: "column" }}
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={Open}
                    onClose={handleClose}
                  >
                    {" "}
                    {availableTemplates.length > 0 ? (
                      availableTemplates.map((template) => (
                        <MenuItem
                          key={template.id}
                          onClick={() => {
                            handleTemplateSelect(template);
                            setAnchorEl(null);
                          }}
                        >
                          {template.template_title +
                            " (" +
                            dataRenderer(template.language) +
                            ")"}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>
                        {getLocalizedText("no_templates_found")}
                      </MenuItem>
                    )}
                  </Menu>
                  <ChatBoxTextField
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    inputProps={{
                      min: 0,
                      "aria-label": "weight",
                      style: {
                        fontSize: "14px",
                      },
                    }}
                    multiline
                    disabled={chatTextDisabled}
                    placeholder="Type a message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </ChatBoxContainer>
                <IconButton
                  disabled={chatTextDisabled}
                  onClick={handleSendMessage}
                >
                  <SendIcon />
                </IconButton>
              </Box>
            </>
          ) : (
            <WhatsappChatImageSendScreen
              caption={caption}
              setCaption={setCaption}
              setOpenImageSendView={setOpenImageSendView}
              fileURL={URL.createObjectURL(selectedMedia[0])}
              handleSendMedia={() => {
                handleSendMedia(selectedMedia[0]);
                scrollToBottom();
              }}
              fileType={selectedMedia[0].type}
              onCloseCallback={() => {
                scrollToBottom();
              }}
            />
          )}
        </ModelWrapper>
      </Box>
      {openTemplateSelect && (
        <TemplateMessageSendPopupComponent
          setOpen={setOpenTemplateSelect}
          open={openTemplateSelect}
          data={selectedTemplate}
          leadData={props.leadData}
          onSendCallback={(details, templateFile) => {
            handleSendMessage({
              isTemplate: true,
              variableDetails: details,
              templateFile: templateFile,
            });
          }}
          onCloseCallback={() => {
            setSelectedTemplate({});
          }}
        />
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(LeadDetailsWABAChatSidepanel);
