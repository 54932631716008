import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  CircularProgress,
  FormControl,
  Grid,
  Select,
  makeStyles,
} from "@material-ui/core";
import {
  AppBar,
  Box,
  MenuItem,
  Toolbar,
  Typography,
  Button,
  OutlinedInput,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import MuiAlert from "@mui/material/Alert";
import { myTheme } from "../../themeUtils";
import { createAdminPartnerApi, updateAdminPartnerApi } from "../../Api";
import { consoleLogger, getLocalizedText } from "../../Functions";
import PreviewImageDialog from "../files_page_components/PreviewImageDialog";
import UserProfileImageWithUploadComponent from "../file_upload_component/user_profile_image/UserProfileImageWithUploadComponent";
import { countryCodeList, partnerTypeOptionsList } from "../../Db";
import CustomFieldHeader from "../custom_tooltip_headers/CustomFieldHeader";
import { AddBoxOutlined, ExpandMore } from "@material-ui/icons";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 18px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;
const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled("textarea")`
  width: 100%;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;
  height: 80px;
  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const GroupLabel = styled("legend")`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 0px;
  width: auto;
`;
const CreateAdminPartnerComponent = ({
  setOpen,
  isEdit,
  setIsEdit,
  editableData,
  setRefresh,
}) => {
  const classes = useStyles();
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({
    name: "",
    email: "",
    phone: "",
    alternate_phone: "",
    address: "",
    city: "",
    state: "",
    country: "",
    company_name: "",
    company_tax_code: "",
    company_city: "",
    company_state: "",
    company_country: "",
    company_address: "",
    partner_type: "",
    pan_number: "",
    bank_beneficiary_name: "",
    bank_account_number: "",
    bank_name: "",
    pan_number: "",
    ifsc_code: "",
  });
  const [file, setFile] = useState(null);
  const [specialtiesList, setSpecialtiesList] = useState([]);
  const [additionalDetailsList, setAdditionalDetailsList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [primaryPhoneCountryCode, setPrimaryPhoneCountryCode] = useState("+91");
  const [alternatePhoneCountryCode, setAlternatePhoneCountryCode] =
    useState("+91");

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const checkIfSpecialtiesDisabled = () => {
    return !inputValue.trim();
  };

  const handleAddSpecialty = (e) => {
    const value = inputValue;
    if (!value.trim()) return;
    if (!specialtiesList.includes(value)) {
      setSpecialtiesList([...specialtiesList, value]);
    }
    setInputValue("");
  };
  const handleDelete = (input) => {
    let tempList = specialtiesList.filter((item) => item !== input);
    setSpecialtiesList(tempList);
  };

  const handleAddAdditionalDetail = () => {
    const initialFields = { field: "", value: "" };

    const newList = [
      ...additionalDetailsList,
      { id: uuidv4(), ...initialFields },
    ];

    setAdditionalDetailsList(newList);
  };
  const handleDeleteAdditionalDetail = (id) => {
    setAdditionalDetailsList((prev) =>
      prev.filter((detail) => detail.id !== id)
    );
  };
  const handleAdditionalDetailChange = (id, field, value) => {
    setAdditionalDetailsList((prev) =>
      prev.map((detail) =>
        detail.id === id ? { ...detail, [field]: value } : detail
      )
    );
  };

  const editUser = async () => {
    let payload = new FormData();

    Object.keys(details).forEach((key) => {
      if (details[key] !== "" && details[key] !== null) {
        if (key === "phone") {
          let value = `${primaryPhoneCountryCode}-${details[key]}`;
          payload.append(key, value);
        } else if (key === "alternate_phone") {
          let value = `${alternatePhoneCountryCode}-${details[key]}`;
          payload.append(key, value);
        } else if (key === "dob") {
          const date = details[key].toISOString();
          payload.append(key, date);
        } else {
          payload.append(key, details[key]);
        }
      }
    });

    if (file !== null) {
      payload.append("profile_pic", file);
    }

    payload.append("specialities", JSON.stringify(specialtiesList));

    const additionalDetails = {};
    additionalDetailsList.forEach((entry) => {
      additionalDetails[entry["field"]] = entry["value"];
    });
    payload.append("additional_details", JSON.stringify(additionalDetails));
    let response = await updateAdminPartnerApi({
      partnerID: editableData["id"],
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const getPhoneWithoutCountryCode = (phoneString) => {
    for (const code of countryCodeList) {
      if (phoneString.startsWith(`${code}-`)) {
        return phoneString.replace(`${code}-`, "");
      }
    }
    return phoneString;
  };

  const getCountryCodeWithoutPhone = (phoneString) => {
    for (const code of countryCodeList) {
      if (phoneString.startsWith(`${code}-`)) {
        return code;
      }
    }
    return "+91";
  };
  const addUser = async () => {
    let payload = new FormData();

    Object.keys(details).forEach((key) => {
      if (details[key] !== "" && details[key] !== null) {
        if (key === "phone") {
          let value = `${primaryPhoneCountryCode}-${details[key]}`;
          payload.append(key, value);
        } else if (key === "alternate_phone") {
          let value = `${alternatePhoneCountryCode}-${details[key]}`;
          payload.append(key, value);
        } else if (key === "dob") {
          const date = details[key].toISOString();
          payload.append(key, date);
        } else {
          payload.append(key, details[key]);
        }
      }
    });
    if (file !== null) {
      payload.append("profile_pic", file);
    }
    payload.append("specialities", JSON.stringify(specialtiesList));

    const additionalDetails = {};
    additionalDetailsList.forEach((entry) => {
      additionalDetails[entry["field"]] = entry["value"];
    });
    payload.append("additional_details", JSON.stringify(additionalDetails));
    let response = await createAdminPartnerApi({
      payload: payload,
    });

    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEdit === true) {
      editUser();
    } else {
      addUser();
    }
  };

  const init = async () => {
    if (isEdit === true) {
      let tempPrimaryPhone =
        editableData.hasOwnProperty("phone") && editableData.phone !== null
          ? editableData.phone
          : "";
      let tempPrimaryPhoneCountryCode =
        getCountryCodeWithoutPhone(tempPrimaryPhone);
      tempPrimaryPhone = getPhoneWithoutCountryCode(tempPrimaryPhone);
      setPrimaryPhoneCountryCode(tempPrimaryPhoneCountryCode);

      let tempAlternatePhone =
        editableData.hasOwnProperty("alternate_phone") &&
        editableData.alternate_phone !== null
          ? editableData.alternate_phone
          : "";
      let tempAlternatePhoneCountryCode =
        getCountryCodeWithoutPhone(tempAlternatePhone);
      tempAlternatePhone = getPhoneWithoutCountryCode(tempAlternatePhone);
      setAlternatePhoneCountryCode(tempAlternatePhoneCountryCode);

      let tempObj = {
        name:
          editableData.hasOwnProperty("name") && editableData.name !== null
            ? editableData.name
            : "",
        email:
          editableData.hasOwnProperty("email") && editableData.email !== null
            ? editableData.email
            : "",
        address:
          editableData.hasOwnProperty("address") &&
          editableData.address !== null
            ? editableData.address
            : "",
        company_name:
          editableData.hasOwnProperty("company_name") &&
          editableData.company_name !== null
            ? editableData.company_name
            : "",
        company_tax_code:
          editableData.hasOwnProperty("company_tax_code") &&
          editableData.company_tax_code !== null
            ? editableData.company_tax_code
            : "",
        phone: tempPrimaryPhone,
        alternate_phone: tempAlternatePhone,
        city:
          editableData.hasOwnProperty("city") && editableData.city !== null
            ? editableData.city
            : "",
        state:
          editableData.hasOwnProperty("state") && editableData.state !== null
            ? editableData.state
            : "",
        country:
          editableData.hasOwnProperty("country") &&
          editableData.country !== null
            ? editableData.country
            : "",
        company_city:
          editableData.hasOwnProperty("company_city") &&
          editableData.company_city !== null
            ? editableData.company_city
            : "",
        company_state:
          editableData.hasOwnProperty("company_state") &&
          editableData.company_state !== null
            ? editableData.company_state
            : "",
        company_country:
          editableData.hasOwnProperty("company_country") &&
          editableData.company_country !== null
            ? editableData.company_country
            : "",
        company_address:
          editableData.hasOwnProperty("company_address") &&
          editableData.company_address !== null
            ? editableData.company_address
            : "",
        partner_type:
          editableData.hasOwnProperty("partner_type") &&
          editableData.partner_type !== null
            ? editableData.partner_type
            : "",
        pan_number:
          editableData.hasOwnProperty("pan_number") &&
          editableData.pan_number !== null
            ? editableData.pan_number
            : "",
        bank_beneficiary_name:
          editableData.hasOwnProperty("bank_beneficiary_name") &&
          editableData.bank_beneficiary_name !== null
            ? editableData.bank_beneficiary_name
            : "",
        bank_account_number:
          editableData.hasOwnProperty("bank_account_number") &&
          editableData.bank_account_number !== null
            ? editableData.bank_account_number
            : "",
        bank_name:
          editableData.hasOwnProperty("bank_name") &&
          editableData.bank_name !== null
            ? editableData.bank_name
            : "",
        ifsc_code:
          editableData.hasOwnProperty("ifsc_code") &&
          editableData.ifsc_code !== null
            ? editableData.ifsc_code
            : "",
      };
      setDetails(tempObj);
      setSpecialtiesList(editableData["specialities"]);
      const additionalDetails = Object.keys(
        editableData["additional_details"]
      ).map((key) => {
        return { field: key, value: editableData["additional_details"][key] };
      });
      setAdditionalDetailsList(additionalDetails);
    }
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };

  const getProfileImageUrl = () => {
    if (file !== null) {
      return URL.createObjectURL(file);
    } else {
      if (isEdit === true) {
        if (
          editableData.hasOwnProperty("profile_pic") &&
          editableData["profile_pic"] !== null
        ) {
          return editableData["profile_pic"];
        }
      }
      return null;
    }
  };

  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            {isEdit ? (
              <ModelHeader>{getLocalizedText("update_details")}</ModelHeader>
            ) : (
              <ModelHeader>{getLocalizedText("create_partner")}</ModelHeader>
            )}
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModelWrapper
            component={"form"}
            onSubmit={handleSubmit}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <InputContainer>
              <Grid container spacing={2}>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <UserProfileImageWithUploadComponent
                      handleUpload={(event) => handleFile(event)}
                      url={getProfileImageUrl()}
                      handleImageClick={() => {
                        setOpenPreview(true);
                      }}
                      canShowUpload={true}
                      scope={"admin_users"}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("name")}*</Label>
                    <Input
                      value={details.name}
                      required
                      type="text"
                      placeholder="Enter Name"
                      name="name"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("email")}*</Label>
                    <Input
                      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                      value={details.email}
                      required
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("phone")}*</Label>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        "& .MuiFormControl-root": {
                          minWidth: "unset",
                        },
                      }}
                    >
                      <FormControl
                        sx={{
                          width: "100%",
                        }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          value={primaryPhoneCountryCode}
                          name="role"
                          displayEmpty
                          disableUnderline
                          variant="outlined"
                          onChange={(event) => {
                            setPrimaryPhoneCountryCode(event.target.value);
                          }}
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",
                            borderRadius: "0px",
                          }}
                        >
                          {countryCodeList.map((item, idx) => {
                            return (
                              <MenuItem
                                style={{
                                  fontSize: "12px",
                                }}
                                key={idx}
                                value={item}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <Input
                        style={{ borderLeft: "none" }}
                        pattern={
                          primaryPhoneCountryCode === "+91"
                            ? "^[0-9]{10}$"
                            : null
                        }
                        value={details.phone}
                        required
                        type="text"
                        placeholder="1231231231"
                        name="phone"
                        onChange={handleChange}
                      />
                    </Box>
                  </InputWrapper>
                </Grid>

                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("alternate_phone")}*</Label>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        "& .MuiFormControl-root": {
                          minWidth: "unset",
                        },
                      }}
                    >
                      <FormControl
                        sx={{
                          width: "100%",
                        }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          value={alternatePhoneCountryCode}
                          name="role"
                          displayEmpty
                          disableUnderline
                          variant="outlined"
                          onChange={(event) => {
                            setAlternatePhoneCountryCode(event.target.value);
                          }}
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",
                            borderRadius: "0px",
                          }}
                        >
                          {countryCodeList.map((item, idx) => {
                            return (
                              <MenuItem
                                style={{
                                  fontSize: "12px",
                                }}
                                key={idx}
                                value={item}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <Input
                        pattern={
                          alternatePhoneCountryCode === "+91"
                            ? "^[0-9]{10}$"
                            : null
                        }
                        style={{ borderLeft: "none" }}
                        value={details.alternate_phone}
                        required
                        type="text"
                        placeholder="1231231231"
                        name="alternate_phone"
                        onChange={handleChange}
                      />
                    </Box>
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("pan_number")}</Label>

                    <Input
                      value={details.pan_number}
                      type="text"
                      placeholder="Enter PAN Number"
                      name="pan_number"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("partner_type")}*</Label>
                    <FormControl
                      sx={{ width: "100%" }}
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        value={details.partner_type}
                        required
                        name="partner_type"
                        displayEmpty
                        disableUnderline
                        variant="outlined"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "0px",
                        }}
                      >
                        {partnerTypeOptionsList.map((e) => {
                          return (
                            <MenuItem id={e} value={e}>
                              {e}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="company-details-content"
                      id="company-details-header"
                    >
                      {getLocalizedText("address_details")}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <InputWrapper>
                            <Label>{getLocalizedText("address")}</Label>

                            <TextArea
                              value={details.address}
                              type="text"
                              placeholder="Enter Address"
                              name="address"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={6}>
                          <InputWrapper>
                            <Label>{getLocalizedText("city")}</Label>
                            <Input
                              value={details.city}
                              type="text"
                              placeholder="Enter City"
                              name="city"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={6}>
                          <InputWrapper>
                            <Label>{getLocalizedText("state")}</Label>
                            <Input
                              value={details.state}
                              type="text"
                              placeholder="Enter State"
                              name="state"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={12}>
                          <InputWrapper>
                            <Label>{getLocalizedText("country")}</Label>
                            <Input
                              value={details.country}
                              type="text"
                              placeholder="Enter Country"
                              name="country"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="company-details-content"
                      id="company-details-header"
                    >
                      {getLocalizedText("bank_details")}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <InputWrapper>
                            <Label>{getLocalizedText("bank_name")}</Label>

                            <TextArea
                              value={details.bank_name}
                              type="text"
                              placeholder="Enter Bank Name"
                              name="bank_name"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={6}>
                          <InputWrapper>
                            <Label>
                              {getLocalizedText("bank_beneficiary_name")}
                            </Label>

                            <Input
                              value={details.bank_beneficiary_name}
                              type="text"
                              placeholder="Enter Bank Beneficiary Name"
                              name="bank_beneficiary_name"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={6}>
                          <InputWrapper>
                            <Label>
                              {getLocalizedText("bank_account_number")}
                            </Label>

                            <Input
                              value={details.bank_account_number}
                              type="number"
                              placeholder="Enter Bank Account Number"
                              name="bank_account_number"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={6}>
                          <InputWrapper>
                            <Label>{getLocalizedText("ifsc_code")}</Label>
                            <Input
                              value={details.ifsc_code}
                              type="text"
                              placeholder="Enter IFSC Code"
                              name="ifsc_code"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="company-details-content"
                      id="company-details-header"
                    >
                      {getLocalizedText("company_details")}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <InputWrapper>
                            <Label>{getLocalizedText("company_name")}</Label>

                            <Input
                              value={details.company_name}
                              type="text"
                              placeholder="Enter Company Name"
                              name="company_name"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={6}>
                          <InputWrapper>
                            <Label>
                              {getLocalizedText("company_tax_code")}
                            </Label>

                            <Input
                              value={details.company_tax_code}
                              type="text"
                              placeholder="Enter Company GST"
                              name="company_tax_code"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={12}>
                          <InputWrapper>
                            <Label>{getLocalizedText("company_address")}</Label>

                            <TextArea
                              value={details.company_address}
                              type="text"
                              placeholder="Enter Company Address"
                              name="company_address"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={6}>
                          <InputWrapper>
                            <Label>{getLocalizedText("company_city")}</Label>
                            <Input
                              value={details.company_city}
                              type="text"
                              placeholder="Enter Company City"
                              name="company_city"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={6}>
                          <InputWrapper>
                            <Label>{getLocalizedText("company_state")}</Label>
                            <Input
                              value={details.company_state}
                              type="text"
                              placeholder="Enter Company State"
                              name="company_state"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={12}>
                          <InputWrapper>
                            <Label>{getLocalizedText("company_country")}</Label>
                            <Input
                              value={details.company_country}
                              type="text"
                              placeholder="Enter Company Country"
                              name="company_country"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="specialities-content"
                      id="specialities-header"
                    >
                      {getLocalizedText("specialities")}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <InputWrapper>
                            <CustomFieldHeader
                              header={getLocalizedText("specialities")}
                              tooltip={"Add specialities"}
                            />
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <OutlinedInput
                                style={{
                                  height: "30px",
                                  width: "100%",
                                  borderRadius: "0px",
                                }}
                                inputProps={{
                                  min: 0,
                                  "aria-label": "weight",
                                  style: {
                                    fontSize: "12px",
                                  },
                                }}
                                type="text"
                                placeholder="Type something"
                                name="specialities"
                                value={inputValue}
                                onChange={(e) => {
                                  setInputValue(e.target.value);
                                }}
                              />
                              <AddBoxOutlined
                                color={
                                  checkIfSpecialtiesDisabled()
                                    ? "disabled"
                                    : "primary"
                                }
                                onClick={
                                  checkIfSpecialtiesDisabled()
                                    ? null
                                    : handleAddSpecialty
                                }
                                style={{
                                  marginLeft: "5px",
                                  width: "30px",
                                  height: "30px",
                                  cursor: "pointer",
                                  transform: "scale(1.3)",
                                }}
                              />
                            </Box>
                            <Box
                              style={{
                                padding: "12px 0px",
                                display: "flex",
                                alignItems: "flex-start",
                                gap: "8px",
                                flexWrap: "wrap",
                              }}
                            >
                              {Array.isArray(specialtiesList) &&
                                specialtiesList.map((chip, index) => {
                                  return (
                                    <Box key={index}>
                                      <Chip
                                        index={index}
                                        label={chip}
                                        onDelete={() => handleDelete(chip)}
                                      />
                                    </Box>
                                  );
                                })}
                            </Box>
                          </InputWrapper>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="additional-details-content"
                      id="additional-details-header"
                    >
                      {getLocalizedText("additional_details")}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              overflowY: "auto",
                            }}
                          >
                            {additionalDetailsList.map((row) => (
                              <Box
                                key={row.id}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                  marginBottom: "6px",
                                }}
                              >
                                <OutlinedInput
                                  required
                                  style={{ height: "30px" }}
                                  inputProps={{
                                    maxLength: 2000,
                                    "aria-label": "weight",
                                    style: {
                                      padding: "0px 12px",
                                      fontSize: "12px",
                                    },
                                  }}
                                  placeholder="Name"
                                  value={row.field}
                                  onChange={(e) => {
                                    handleAdditionalDetailChange(
                                      row.id,
                                      "field",
                                      e.target.value
                                    );
                                  }}
                                  size="small"
                                  sx={{ flex: 1 }}
                                />
                                <OutlinedInput
                                  required
                                  style={{ height: "30px" }}
                                  inputProps={{
                                    maxLength: 25,
                                    "aria-label": "weight",
                                    style: {
                                      padding: "0px 12px",
                                      fontSize: "12px",
                                    },
                                  }}
                                  placeholder="Value"
                                  value={row.value}
                                  onChange={(e) => {
                                    handleAdditionalDetailChange(
                                      row.id,
                                      "value",
                                      e.target.value
                                    );
                                  }}
                                  size="small"
                                  sx={{ flex: 1 }}
                                />

                                <IconButton
                                  onClick={() =>
                                    handleDeleteAdditionalDetail(row.id)
                                  }
                                  color="error"
                                  size="small"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            ))}

                            <Button
                              style={{
                                textTransform: "none",
                                fontWeight: "bold",
                                width: "100%",
                              }}
                              color="primary"
                              variant="text"
                              onClick={() => {
                                handleAddAdditionalDetail();
                              }}
                            >
                              <AddIcon /> Add
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </InputContainer>
            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500 }}
            >
              <Toolbar style={{ width: 500, position: "relative" }}>
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    type="button"
                    variant="contained"
                    color="inherit"
                    style={{
                      backgroundColor: "#EFEFF4",
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {getLocalizedText("submit")}
                  </Button>
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </ModelWrapper>
        ) : (
          <MessageWrapper>
            {isEdit ? (
              <Message>Partner info updated successfully!</Message>
            ) : (
              <Message>Partner created successfully!</Message>
            )}

            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="inherit"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        )}
      </Box>
      {openPreview && (
        <PreviewImageDialog
          open={openPreview}
          setOpen={setOpenPreview}
          file={getProfileImageUrl()}
          isCreate={false}
        />
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(CreateAdminPartnerComponent);
