import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import rootStore from "../../stores/RootStore";
import Select from "react-select";
import { AddBoxOutlined } from "@material-ui/icons";

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import ColumnInfoCard from "./cards/ColumnInfoCard";

const InputContainer = styled(Box)`
  width: 100%;
`;
const OptionLabel = styled(Typography)`
  font: normal normal 600 12px Open Sans;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for options
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for the entire control container, including the search input
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "no options" message
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "loading" message
  }),
  container: (provided, state) => ({
    ...provided,
    width: "100%",
  }),
};
const AddColumnToSectionComponent = ({
  columnsList,
  sectionColumnMapping,
  selectedColumns,
  handleSelectedColumns,
}) => {
  const [selectedColumn, setSelectedColumn] = useState(null);

  const isColumnAlreadySelected = (columnID) => {
    for (const key in sectionColumnMapping) {
      let columnList = sectionColumnMapping[key];
      let index = columnList.findIndex((entry) => entry["value"] === columnID);
      if (index !== -1) {
        return true;
      }
    }
    return false;
  };

  const getSelectColumnOptions = () => {
    let tempList = [];
    columnsList.forEach((column) => {
      // not including - lead_owner, lead_creator columns, derived columns, parent/child columns, columns with is_visible false,column with enable_for_create false, already selected columns
      if (
        column["field"] !== "lead_owner" &&
        column["field"] !== "lead_creator" &&
        !column["derived"] &&
        column["parent"] === false &&
        column["child_of"] === null &&
        column["enable_for_create"] &&
        rootStore.userStore.isColumnVisible(column) &&
        isColumnAlreadySelected(column["id"]) === false
      ) {
        tempList.push(column);
      }
    });
    tempList.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );

    return tempList.map((column) => ({
      label: column["headerName"],
      value: column["id"],
    }));
  };

  const handleAddColumn = () => {
    let tempSelectedColumnList = [...selectedColumns];
    let index = tempSelectedColumnList.findIndex(
      (entry) => entry["value"] === selectedColumn["value"]
    );
    if (index === -1) {
      tempSelectedColumnList.push(selectedColumn);
    }
    setSelectedColumn(null);
    handleSelectedColumns(tempSelectedColumnList);
  };

  const handleRemoveColumn = (columnID) => {
    let tempSelectedColumnList = [...selectedColumns];
    tempSelectedColumnList = tempSelectedColumnList.filter(
      (entry) => entry["value"] !== columnID
    );
    handleSelectedColumns(tempSelectedColumnList);
  };

  const onChange = async (sourceId, sourceIndex, targetIndex, targetId) => {
    if (sourceIndex === targetIndex) {
      return;
    }
    const newState = swap(selectedColumns, sourceIndex, targetIndex);
    handleSelectedColumns(newState);
  };

  const getWidgetLayoutHeight = () => {
    const heightPerRow = 50;
    const numberOfRows = selectedColumns.length;
    return heightPerRow * numberOfRows;
  };

  // check if the column is a required field
  const isColumnRequiredField = (columnID) => {
    let index = columnsList.findIndex((column) => column["id"] === columnID);
    if (index !== -1) {
      return columnsList[index]["required"];
    }
    return false;
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps, isFocused, isSelected } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          padding: 10,

          backgroundColor: isSelected
            ? "#4d9aff"
            : isFocused
            ? "#deebff"
            : "white",
          color: isSelected ? "white" : "#4d4e4f",
        }}
      >
        <OptionLabel>
          {isColumnRequiredField(data["value"]) && (
            <span style={{ color: "red" }}>*</span>
          )}
          {data.label}
        </OptionLabel>
      </div>
    );
  };

  return (
    <InputContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            component={"fieldset"}
            sx={{
              border: "1px solid #cccccc",
              padding: "12px",
              paddingRight: "8px",
            }}
          >
            <legend
              style={{
                width: "auto",
                marginBottom: "0px",
                fontSize: "14px",
              }}
            >
              Columns
            </legend>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Select
                options={getSelectColumnOptions()}
                value={selectedColumn}
                onChange={(selectedOption) => {
                  setSelectedColumn(selectedOption);
                }}
                closeMenuOnSelect={true}
                styles={customStyles}
                isClearable
                components={{ Option: CustomOption }}
              />
              <IconButton
                style={{ padding: "0px" }}
                color="primary"
                disabled={selectedColumn === null}
                onClick={handleAddColumn}
              >
                <AddBoxOutlined style={{ width: "auto", height: "40px" }} />
              </IconButton>
            </Box>
          </Box>
        </Grid>

        {selectedColumns.length > 0 && (
          <Grid item xs={12}>
            <Box
              style={{
                maxHeight: "calc(100vh - 322px)",
                overflowY: "auto",
              }}
            >
              <GridContextProvider onChange={onChange}>
                <GridDropZone
                  id="items"
                  boxesPerRow={1}
                  rowHeight={50}
                  style={{
                    height: getWidgetLayoutHeight(),
                    overflow: "hidden",
                    paddingTop: "8px",
                  }}
                >
                  {selectedColumns.map((entry, index) => {
                    return (
                      <GridItem key={entry["value"]}>
                        <ColumnInfoCard
                          isRequiredField={isColumnRequiredField(
                            entry["value"]
                          )}
                          columnData={entry}
                          onRemove={() => {
                            handleRemoveColumn(entry["value"]);
                          }}
                        />
                      </GridItem>
                    );
                  })}
                </GridDropZone>
              </GridContextProvider>
            </Box>
          </Grid>
        )}
      </Grid>
    </InputContainer>
  );
};

export default observer(AddColumnToSectionComponent);
