import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, CircularProgress, Drawer } from "@material-ui/core";
import { Add, ChevronRight, Edit } from "@material-ui/icons";
import { adminPartnersColumnHeaders } from "../../Db";

import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled } from "@mui/material/styles";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { getAdminPartnersApi } from "../../Api";
import { dataRenderer, getLocalizedText } from "../../Functions";
import CreateAdminPartnerComponent from "../../components/admin_partners_page_components/CreateAdminPartnerComponent";
import ViewAdminPartnerSidepanel from "../../components/admin_partners_page_components/ViewAdminPartnerSidepanel";
import ViewListModal from "../../components/add_product_line_sidepanel/components/ViewListModal";
import { MoreHoriz } from "@material-ui/icons";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: flex-end;
  height: 100%;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 16px 0px;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 218px);`};
  background-color: white;
  padding: 20px;
  position: relative;
`;

const AdminPartnersPage = () => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openCreatePartnerPanel, setOpenCreatePartnerPanel] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [viewPartnerInfoPanel, setViewPartnerInfoPanel] = useState(false);
  const [viewList, setViewList] = useState(false);

  const getAdminPartners = async () => {
    setRow([]);
    let response = await getAdminPartnersApi({
      projectID: rootStore.authStore.projectId,
    });
    setRow(response);
  };

  const init = async () => {
    setLoading(true);
    await getAdminPartners();
    setLoading(false);
  };

  useEffect(() => {
    if (refresh === true) {
      init();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const actionColumn = [
    {
      field: "specialities",
      headerName: "SPECIALITIES",

      width: 200,
      cellRenderer: (params) => {
        if (params.data["specialities"].length > 0) {
          return (
            <MoreHoriz
              style={{
                marginRight: "20px",
                width: "18px",
                height: "18px",
                cursor: "pointer",
              }}
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setSelectedRowData(params.data);
                setViewList(true);
              }}
            />
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      resizable: false,
      minWidth: 130,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Tooltip title={"Edit"}>
            <IconButton
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedRowData(params.data);
                setIsEdit(true);
                setOpenCreatePartnerPanel(true);
              }}
            >
              <Edit
                style={{
                  width: "18px",
                  height: "18px",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <ParentContainer>
        {!loading ? (
          <Container>
            <Row>
              <TopWrapper>
                <Header>{getLocalizedText("admin")}</Header>
                <ChevronRight style={{ width: "16px", height: "16px" }} />
                <Header>{getLocalizedText("partners")}</Header>
              </TopWrapper>
              <Button
                onClick={() => {
                  setOpenCreatePartnerPanel(true);
                }}
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "160px",
                  marginLeft: "20px",
                  height: "40px",
                }}
              >
                <Add style={{ width: "18px", height: "18px" }} />{" "}
                {getLocalizedText("create_partner")}
              </Button>
            </Row>
            <TableWrapper>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={adminPartnersColumnHeaders.concat(actionColumn)}
                  animateRows={true}
                  suppressCellFocus
                  pagination={true}
                  paginationPageSize={pageSize}
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    setSelectedRowData(row.data);
                    setViewPartnerInfoPanel(true);
                  }}
                />
              </div>
            </TableWrapper>
          </Container>
        ) : (
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Container>
        )}
      </ParentContainer>
      <Footer />
      {openCreatePartnerPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCreatePartnerPanel}
          onClose={() => {
            setIsEdit(false);
            setOpenCreatePartnerPanel(false);
          }}
        >
          <CreateAdminPartnerComponent
            setRefresh={setRefresh}
            setOpen={setOpenCreatePartnerPanel}
            open={openCreatePartnerPanel}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            editableData={selectedRowData}
          />
        </Drawer>
      )}
      {viewPartnerInfoPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={viewPartnerInfoPanel}
          onClose={() => {
            setViewPartnerInfoPanel(false);
          }}
        >
          <ViewAdminPartnerSidepanel
            setOpen={setViewPartnerInfoPanel}
            open={viewPartnerInfoPanel}
            data={selectedRowData}
            handleEdit={() => {
              setIsEdit(true);
              setOpenCreatePartnerPanel(true);
              setViewPartnerInfoPanel(false);
            }}
            list={row}
            refresh={refresh}
          />
        </Drawer>
      )}
      {viewList && (
        <ViewListModal
          setOpen={setViewList}
          open={viewList}
          title={"Specialities"}
          list={
            selectedRowData["specialities"]
              ? selectedRowData["specialities"]
              : []
          }
        />
      )}
    </>
  );
};

export default observer(AdminPartnersPage);
