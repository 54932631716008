import { bonvoiceCall, getBonvoiceCallStatus } from "../Api";
import ApiResult from "./apiResult";
import CallService from "./callService";

class BonvoiceCallService extends CallService {
  makeCall = async (leadID) => {
    try {
      let response = await bonvoiceCall(leadID);
      return response.data;
    } catch (error) {
      return {
        message: "Something went wrong! Please contact your administrator.",
      };
    }
  };
  fetchCallStatus = async (formData) => {
    try {
      let response = await getBonvoiceCallStatus(formData);
      return response.data;
    } catch (error) {
      return {};
    }
  };
  setCallDisposition = async (leadID, formData) => {
    return new ApiResult("success", {}, 200);
  };
}

export default BonvoiceCallService;
