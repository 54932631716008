import { Box, Divider, Grid } from "@mui/material";
import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import JoditEditor from "jodit-react";

const Wrapper = styled(Box)`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border: 1px solid #eceef2;
`;
const TextEditorCard = ({
  editableData,
  handleEdit,
  defaultFontSize = "12px",
}) => {
  const editor = useRef(null);

  return (
    <Wrapper>
      <Divider style={{ margin: "0px 16px" }} />

      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                "& .jodit-container": {
                  border: "none",
                },
                "& .jodit-container p": {
                  marginBottom: "0px",
                  fontFamily: "unset",
                },
                "& .jodit-status-bar": {
                  display: "none",
                },
                "& .jodit-add-new-line": {
                  display: "none",
                },
              }}
            >
              <JoditEditor
                ref={editor}
                value={editableData["content"]}
                config={{
                  askBeforePasteFromWord: false,
                  askBeforePasteHTML: false,
                  defaultActionOnPaste: "insert_only_text",
                  resizable: false,
                  showTooltip: true,
                  style: { font: `${defaultFontSize} Poppins` },
                  readonly: false,
                  placeholder: "Start typing",
                  toolbarInline: true,
                  toolbarAdaptive: false,
                  controls: {
                    font: { list: ["Poppins"] },
                  },

                  buttons: [
                    "bold",
                    "strikethrough",
                    "underline",
                    "italic",
                    "|",
                    "ul",
                    "ol",
                    "|",
                    "fontsize",
                    "brush",
                    "align",
                    "|",
                  ],

                  events: {
                    // Handle drag-and-drop
                    drop: (event) => {
                      // prevent default copy content on drop
                      event.preventDefault();
                    },
                  },
                }}
                // tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => {
                  if (newContent.trim() === "<p><br></p>") {
                    handleEdit("");
                  } else {
                    handleEdit(newContent);
                  }
                }} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => {}}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
};

export default TextEditorCard;
