import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { Cancel } from "@material-ui/icons";

const CardWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 8px);
  height: fit-content;
  padding: 8px;
  background-color: #f9f9fc;
  align-items: center;
  border-radius: 8px;
  border: 1px dotted gray;
  position: relative;
`;
const Text = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  margin: 0px;
`;
const TextWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const ColumnInfoCard = ({
  columnData,
  onRemove,
  isRequiredField,
  canRemove = true,
}) => {
  return (
    <CardWrapper>
      <TextWrapper>
        <Tooltip title={columnData["label"]}>
          <Text>
            {isRequiredField && <span style={{ color: "red" }}>*</span>}
            {columnData["label"]}
          </Text>
        </Tooltip>
      </TextWrapper>

      {canRemove && (
        <IconButton
          style={{
            padding: "0px",
            position: "absolute",
            top: "-8px",
            right: "-8px",
          }}
          onClick={() => onRemove()}
        >
          <Cancel style={{ height: "20px", width: "20px" }} />
        </IconButton>
      )}
    </CardWrapper>
  );
};

export default ColumnInfoCard;
