import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@material-ui/core";
import {
  AppBar,
  Box,
  Tab,
  Tabs,
  tabsClasses,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import MuiAlert from "@mui/material/Alert";
import {
  createProductApi,
  editProductApi,
  getAccountMembersApi,
  getFilteredPartnersApi,
  getListApi,
  getProductDetailsApi,
  getProductLineListApi,
  getProductManufacturerListApi,
} from "../../Api";
import {
  getAccountLocalizedText,
  getLocalizedText,
  getSlug,
} from "../../Functions";
import rootStore from "../../stores/RootStore";
import PreviewImageDialog from "../files_page_components/PreviewImageDialog";
import PreviewPdfDialog from "../files_page_components/PreviewPdfDialog";
import { LoadingButton } from "@mui/lab";
import SpecificationsScreen from "./screens/SpecificationsScreen";
import BasicDetailsScreen from "./screens/BasicDetailsScreen";
import PreviewVideoDialog from "../files_page_components/PreviewVideoDialog";
import moment from "moment";
import BuilderProductLineScreen from "./screens/BuilderProductLineScreen";
import SourceScreen from "./screens/SourceScreen";
const drawerWidth = "50vw";
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const Wrapper = styled(Box)`
  width: 100%;
  ${`height: calc(100vh - 132px);`};
  padding: 16px;
  overflow-y: auto;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 18px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;

const AddProductSidePanel = ({
  setRefresh,
  productID,
  handleClose,
  isEdit,
}) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [sortedColumnsList, setSortedColumnsList] = useState([]);
  const [mandatoryFields, setMandatoryFields] = useState([]);

  //source screen related states
  const [userList, setUserList] = useState([]);
  const [partnersList, setPartnersList] = useState([]);
  //----------------------------
  const columnsList = [...rootStore.productStore.columnsList];
  const [selectedImages, setSelectedImages] = useState({
    image_1: null,
    image_2: null,
    image_3: null,
    image_4: null,
  });
  const [selectedDocuments, setSelectedDocuments] = useState({
    file_1: null,
    file_2: null,
    file_3: null,
    file_4: null,
  });
  const [viewImageDialog, setViewImageDialog] = useState(false);
  const [viewPdfDialog, setViewPdfDialog] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [tabIdx, setTabIdx] = useState(0);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [listFieldInputMeta, setListFieldInputMeta] = useState({});
  const [fieldValues, setFieldValues] = useState({});
  const [payloadData, setPayloadData] = useState({});
  const [specificationList, setSpecificationList] = useState([]);
  const [additionalDetailsList, setAdditionalDetailsList] = useState([]);
  const [productLineList, setProductLineList] = useState([]);
  const [productManufacturerList, setProductManufacturerList] = useState([]);
  const [builderProductLineDetails, setBuilderProductLineDetails] = useState({
    product_manufacturer: null, //builder/manufacturer
    product_line: null,
  });
  const [inventoryDetails, setInventoryDetails] = useState({
    manage_inventory: false,
  });
  const [sourceDetails, setSourceDetails] = useState({
    source_type: "internal",
    internal_user: {},
    external_user: {},
  });
  const [mediaDetails, setMediaDetails] = useState({
    product_image: null,
    product_video: null,
    product_brochure: null,
  });
  const [openVideoPreviewDialog, setOpenVideoPreviewDialog] = useState(false);
  const objectUrlCache = new Map();

  const updatePayloadValue = (key, value) => {
    const columnIndex = sortedColumnsList.findIndex(
      (entry) => entry["column_name"] === key
    );
    if (columnIndex !== -1) {
      let fieldValue = value;
      const columnData = sortedColumnsList[columnIndex];
      // if filter type is list: field will be a dropdown where it stores value as {label:"",value:""}
      if (
        columnData["filter_type"] === "list" &&
        columnData["data_type"] !== "json_array_string"
      ) {
        fieldValue = value !== null && value !== "" ? value["value"] : "";
      }

      if (columnData["data_type"] === "integer") {
        fieldValue =
          fieldValue !== null && fieldValue !== "" ? parseInt(fieldValue) : "";
      } else if (columnData["data_type"] === "decimal") {
        fieldValue =
          fieldValue !== null && fieldValue !== ""
            ? parseFloat(fieldValue)
            : "";
      } else if (columnData["data_type"] === "json_array_string") {
        if (columnData["filter_type"] === "list") {
          fieldValue =
            value !== null && value !== "" ? JSON.stringify(value) : "";
        }
      }

      let tempPayloadData = { ...payloadData };

      if (
        columnData.hasOwnProperty("is_custom_param") &&
        columnData["is_custom_param"] === true
      ) {
        key = key.replace("custom_params_", "");
        if (!tempPayloadData.hasOwnProperty("custom_params")) {
          tempPayloadData["custom_params"] = {};
        }
        tempPayloadData["custom_params"][key] = fieldValue;
      } else {
        tempPayloadData[key] = fieldValue;
      }

      setPayloadData(tempPayloadData);
    }
  };

  const getOptionListForSelectFieldToRender = (listFieldMeta) => {
    let tempOptionList = [];
    if (listFieldMeta["type"] === "list") {
      listFieldMeta["list"].forEach((entry) => {
        tempOptionList.push({ label: entry, value: entry });
      });
    } else {
      try {
        listFieldMeta["list"].forEach((entry) => {
          tempOptionList.push({ label: entry["name"], value: entry["value"] });
        });
      } catch (error) {
        console.log(error);
        return [];
      }
    }
    return tempOptionList;
  };

  const handleChange = ({ key, value }) => {
    setIsSubmitFail(false);

    setFieldValues({ ...fieldValues, [key]: value });
    updatePayloadValue(key, value);
  };

  const createProduct = async (formData) => {
    let response = await createProductApi({
      payload: formData,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };
  const updateProduct = async (formData) => {
    let response = await editProductApi({
      productID: productID,
      payload: formData,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const validateMandatoryFields = () => {
    let filled_fields = { ...fieldValues };
    for (let i = 0; i < mandatoryFields.length; i++) {
      let item = mandatoryFields[i];

      if (filled_fields.hasOwnProperty(item["field"])) {
        if (
          filled_fields[item["field"]] === "" ||
          filled_fields[item["field"]] === null
        ) {
          setErrorMessage(`Please fill "${item["display_name"]}" field`);
          return false;
        }
      } else {
        setErrorMessage(`Please fill "${item["display_name"]}" field`);
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async () => {
    setSubmittingForm(true);
    if (validateMandatoryFields()) {
      let formData = new FormData();
      Object.keys(payloadData).forEach((key) => {
        if (payloadData[key] !== "") {
          formData.append(key, payloadData[key]);
        }
      });
      const specifications = {};
      specificationList.forEach((entry) => {
        specifications[entry["label"]] = entry["value"];
      });
      formData.append("specifications", JSON.stringify(specifications));

      const additionalDetails = {};
      additionalDetailsList.forEach((entry) => {
        additionalDetails[entry["label"]] = entry["value"];
      });
      formData.append("additional_details", JSON.stringify(additionalDetails));
      Object.keys(selectedImages).forEach((key) => {
        let value = selectedImages[key];
        if (value !== null) {
          if (value instanceof File) {
            formData.append(key, selectedImages[key]);
          }
        } else {
          formData.append(key, null);
        }
      });

      //builder screen
      formData.append(
        "product_manufacturer_id",
        builderProductLineDetails["product_manufacturer"] !== null
          ? builderProductLineDetails["product_manufacturer"]["value"]["id"]
          : null
      );
      formData.append(
        "product_line_id",
        builderProductLineDetails["product_line"] !== null
          ? builderProductLineDetails["product_line"]["value"]["id"]
          : null
      );

      //inventory screen
      Object.keys(inventoryDetails).forEach((key) => {
        formData.append(key, inventoryDetails[key]);
      });

      //source screen
      if (sourceDetails["source_type"] === "internal") {
        if (
          sourceDetails["internal_user"] !== null &&
          sourceDetails["internal_user"].hasOwnProperty("name") &&
          sourceDetails["internal_user"].hasOwnProperty("id") &&
          sourceDetails["internal_user"]["name"] !== null &&
          sourceDetails["internal_user"]["name"] !== "" &&
          sourceDetails["internal_user"]["id"] !== null &&
          sourceDetails["internal_user"]["id"] !== ""
        ) {
          formData.append("source_type", sourceDetails["source_type"]);
          formData.append(
            "source_name",
            sourceDetails["internal_user"]["name"]
          );
          formData.append("source_id", sourceDetails["internal_user"]["id"]);
        }
      } else {
        if (
          sourceDetails["external_user"] !== null &&
          sourceDetails["external_user"].hasOwnProperty("name") &&
          sourceDetails["external_user"].hasOwnProperty("id") &&
          sourceDetails["external_user"]["name"] !== null &&
          sourceDetails["external_user"]["name"] !== "" &&
          sourceDetails["external_user"]["id"] !== null &&
          sourceDetails["external_user"]["id"] !== ""
        ) {
          formData.append("source_type", sourceDetails["source_type"]);
          formData.append(
            "source_name",
            sourceDetails["external_user"]["name"]
          );
          formData.append("source_id", sourceDetails["external_user"]["id"]);
        }
      }

      Object.keys(selectedDocuments).forEach((key) => {
        let value = selectedDocuments[key];
        if (value !== null) {
          if (value instanceof File) {
            formData.append(key, selectedDocuments[key]);
          }
        } else {
          formData.append(key, null);
        }
      });

      for (const key in mediaDetails) {
        formData.append(key, mediaDetails[key]);
      }

      if (isEdit === true) {
        await updateProduct(formData);
      } else {
        await createProduct(formData);
      }
    } else {
      setIsSubmitFail(true);
    }

    setSubmittingForm(false);
  };

  function sortColumnFields() {
    let tempColumns = columnsList.filter(
      (columnData) =>
        (isEdit
          ? columnData["show_in_edit_form"]
          : columnData["show_in_create_form"]) && columnData["is_visible"]
    );
    let requiredFields = tempColumns
      .filter((item) => item["required"])
      .map((item) => item)
      .sort((a, b) =>
        a["display_name"]
          .toLowerCase()
          .localeCompare(b["display_name"].toLowerCase())
      );
    let allFields = [...requiredFields];

    let remainingFields = [];
    tempColumns.forEach((item) => {
      let index = allFields.findIndex(
        (element) => element["id"] === item["id"]
      );
      if (index === -1) {
        remainingFields.push(item);
      }
    });
    remainingFields.sort((a, b) =>
      a["display_name"]
        .toLowerCase()
        .localeCompare(b["display_name"].toLowerCase())
    );
    remainingFields.forEach((data) => {
      let index = allFields.findIndex(
        (element) => element["id"] === data["id"]
      );
      if (index === -1) {
        allFields.push(data);
      }
    });
    allFields.sort((a, b) => {
      if (a.is_multiline && !b.is_multiline) {
        return 1;
      } else if (!a.is_multiline && b.is_multiline) {
        return -1;
      } else {
        return 0;
      }
    });

    for (const item of allFields) {
      if (item.is_multiline || item.data_type === "json_array_string") {
        item.width = 2;
        item.modifyWidth = false;
      } else {
        item.width = 1;
        item.modifyWidth = true;
      }
    }
    for (let i = 0; i < allFields.length; i++) {
      let currentItem = allFields[i];

      if (currentItem.modifyWidth) {
        if (i === allFields.length - 1) {
          currentItem.width = 2;
          currentItem.modifyWidth = false;
        } else {
          let nextItem = allFields[i + 1];

          if (nextItem.width === 2) {
            currentItem.width = 2;
            currentItem.modifyWidth = false;
          } else if (nextItem.width === 1) {
            currentItem.width = 1;
            currentItem.modifyWidth = false;
            nextItem.modifyWidth = false;
          }
        }
      }
    }
    return allFields;
  }

  const getListDetailsToRender = async (columnMeta) => {
    const input = columnMeta["input"];
    let tempType = "list";
    let tempList = [];
    if (input.charAt(0) === "/" || input.startsWith("ep:")) {
      let slug = getSlug(input);
      let response = await getListApi({ slug: slug });
      if (response !== null) {
        if (Array.isArray(response)) {
          tempType = "list";
          tempList = response;
        } else if (typeof response === "object") {
          if (Object.keys(response).length > 0) {
            tempType = response["type"];
            tempList = response["values"];
          }
        }
      }
    } else if (input.charAt(0) !== "/" && input !== "" && input !== null) {
      try {
        let parseJSON = JSON.parse(input);
        if (Array.isArray(parseJSON)) {
          tempType = "list";
          tempList = parseJSON;
        } else if (typeof parseJSON === "object") {
          if (Object.keys(parseJSON).length > 0) {
            tempType = parseJSON["type"];
            tempList = parseJSON["values"];
          }
        }
      } catch (error) {
        if (input.length > 0) {
          let list = input.split(",");
          tempType = "list";
          tempList = list;
        }
      }
    }
    return { list: tempList, type: tempType };
  };

  const getListFieldInputMeta = async ({ columnsList }) => {
    let tempList = {};
    for (let i = 0; i < columnsList.length; i++) {
      let columnMeta = columnsList[i];
      if (columnMeta["filter_type"] === "list") {
        let metaData = await getListDetailsToRender(columnMeta);
        tempList[columnMeta["column_name"]] = metaData;
      }
    }
    return tempList;
  };

  const getProductLineList = async () => {
    const queryPayload = {
      name: "",
    };

    let response = await getProductLineListApi({
      page: 0,
      page_size: 10,
      payload: { query: queryPayload },
    });
    if (Object.keys(response).length > 0) {
      setProductLineList(response.items);
    } else {
      setProductLineList([]);
    }
  };

  const getProductManufacturerList = async () => {
    const queryPayload = {
      name: "",
    };

    let response = await getProductManufacturerListApi({
      page: 0,
      page_size: 10,
      payload: { query: queryPayload },
    });
    if (Object.keys(response).length > 0) {
      setProductManufacturerList(response.items);
    } else {
      setProductManufacturerList([]);
    }
  };

  const getUsers = async () => {
    let response = await getAccountMembersApi();
    setUserList(response);
  };

  const getPartners = async () => {
    const payload = {};
    let response = await getFilteredPartnersApi({
      payload: { query: payload },
    });
    setPartnersList(response);
  };

  const setupMandatoryFields = (columnList) => {
    let mandatory_field_list = [];
    columnList.forEach((item) => {
      if (item["required"] === true) {
        let tempObj = { display_name: item["display_name"] };
        tempObj["field"] = item["column_name"];

        mandatory_field_list.push(tempObj);
      }
    });
    setMandatoryFields(mandatory_field_list);
  };

  const init = async () => {
    let sortedFields = sortColumnFields();
    setSortedColumnsList(sortedFields);
    setupMandatoryFields(sortedFields);
    let tempListFieldInputMeta = await getListFieldInputMeta({
      columnsList: sortedFields,
    });
    setListFieldInputMeta(tempListFieldInputMeta);
    if (isEdit === true) {
      await initEditableData({ columnList: sortedFields });
    }
    await getUsers();
    await getPartners();
    await getProductLineList();
    await getProductManufacturerList();
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const imageKeys = Object.keys(selectedImages);

    files.forEach((file) => {
      const availableKey = imageKeys.find(
        (key) => selectedImages[key] === null
      );
      if (availableKey) {
        setSelectedImages((prevImages) => ({
          ...prevImages,
          [availableKey]: file,
        }));
      }
    });
  };

  const handleDocumentUpload = (event) => {
    const files = Array.from(event.target.files);
    const docKeys = Object.keys(selectedDocuments);

    files.forEach((file) => {
      const availableKey = docKeys.find(
        (key) => selectedDocuments[key] === null
      );
      if (availableKey) {
        setSelectedDocuments((prevDocs) => ({
          ...prevDocs,
          [availableKey]: file,
        }));
      }
    });
  };

  const handleImagePreview = (image) => {
    if (image instanceof File) {
      const objectURL = URL.createObjectURL(image);
      setSelectedFileUrl(objectURL);
    } else {
      setSelectedFileUrl(image["url"]);
    }
    setViewImageDialog(true);
  };
  const handleDocumentPreview = (doc) => {
    if (doc instanceof File) {
      const objectURL = URL.createObjectURL(doc);
      setSelectedFileUrl(objectURL);
    } else {
      setSelectedFileUrl(doc["url"]);
    }
    setViewPdfDialog(true);
  };

  const handleVideoPreview = (video) => {
    if (video instanceof File) {
      const objectURL = URL.createObjectURL(video);
      setSelectedFileUrl(objectURL);
    } else {
      setSelectedFileUrl(video["url"]);
    }
    setOpenVideoPreviewDialog(true);
  };

  const handleRemoveImage = (key) => {
    setSelectedImages((prevImages) => ({
      ...prevImages,
      [key]: null,
    }));
  };

  const handleRemoveDoc = (key) => {
    setSelectedDocuments((prevDocs) => ({
      ...prevDocs,
      [key]: null,
    }));
  };

  const getFileUrl = (file) => {
    if (file instanceof File) {
      // Check if the URL is already cached
      if (!objectUrlCache.has(file)) {
        const objectURL = URL.createObjectURL(file);
        objectUrlCache.set(file, objectURL);
      }
      return objectUrlCache.get(file);
    } else {
      // For non-File objects, return the existing URL
      return file["url"];
    }
  };

  const handleSetSourceType = (e) => {
    //resetting selected source values while setting source type
    setSourceDetails({
      source_type: e.target.value,
      internal_user: {},
      external_user: {},
    });
  };

  const handleInventoryCheckbox = (event) => {
    setInventoryDetails({ manage_inventory: event.target.checked });
  };

  const handleProductLineChange = (productLineValue) => {
    let productManufacturer = null;
    let selectedManufacturer = {
      ...builderProductLineDetails["product_manufacturer"],
    };
    if (productLineValue !== null) {
      //get manufacturer if product line has a match and overwrite builder field
      productManufacturer =
        productLineValue["value"].hasOwnProperty("product_manufacturer") &&
        productLineValue["value"]["product_manufacturer"] !== ""
          ? productLineValue["value"]["product_manufacturer"]
          : null;
      if (
        productManufacturer !== null &&
        productManufacturer.hasOwnProperty("name")
      ) {
        //build select dropdown data
        selectedManufacturer = {
          value: productManufacturer,
          label: productManufacturer.name,
        };
      } else {
        selectedManufacturer = null;
      }
    }

    setBuilderProductLineDetails({
      product_manufacturer: selectedManufacturer,
      product_line: productLineValue,
    });
  };

  const RenderCancelOrPreviousButton = () => {
    if (tabIdx === 0) {
      return (
        <Button
          onClick={handleClose}
          variant="outlined"
          color="primary"
          style={{ width: "100px", textTransform: "none" }}
        >
          {getLocalizedText("cancel")}
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            setIsSubmitFail(false);
            setErrorMessage("");
            setTabIdx(tabIdx - 1);
          }}
          variant="outlined"
          color="primary"
          style={{ width: "100px", textTransform: "none" }}
        >
          {getLocalizedText("prev")}
        </Button>
      );
    }
  };

  const RenderNextOrSubmitButton = () => {
    if (tabIdx < 3) {
      return (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{
            width: "100px",
            textTransform: "none",
          }}
        >
          {getLocalizedText("next")}
        </Button>
      );
    } else {
      if (submittingForm) {
        return (
          <LoadingButton
            loading
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              width: "100px",
            }}
          >
            <span> {getLocalizedText("submit")}</span>
          </LoadingButton>
        );
      } else {
        return (
          <Button
            color="primary"
            type="submit"
            variant="contained"
            style={{
              width: "100px",
              textTransform: "none",
            }}
          >
            {getLocalizedText("submit")}
          </Button>
        );
      }
    }
  };

  const handleProductMediaUpload = (event, fileType) => {
    const file = event.target.files[0];
    if (file) {
      setMediaDetails({ ...mediaDetails, [fileType]: file });
    }
  };

  const getFieldValue = ({ columnMeta, productDetails }) => {
    let productData = productDetails;
    let key = columnMeta["column_name"];
    if (columnMeta["is_custom_param"] === true) {
      productData = productDetails.hasOwnProperty("custom_params")
        ? JSON.parse(productDetails["custom_params"])
        : {};
      key = columnMeta["column_name"].replace("custom_params_", "");
    }

    if (productData.hasOwnProperty(key)) {
      let value = productData[key];
      if (value !== null && value !== "") {
        if (
          columnMeta["data_type"] === "date" ||
          columnMeta["data_type"] === "datetime"
        ) {
          value = moment(productData[key]);
        } else if (columnMeta["data_type"] === "integer") {
          value = parseInt(productData[key]);
        } else if (columnMeta["data_type"] === "decimal") {
          value = parseFloat(productData[key]);
        } else {
          if (
            columnMeta["filter_type"] === "list" &&
            columnMeta["data_type"] !== "json_array_string"
          ) {
            value = { label: productData[key], value: productData[key] };
          }
          if (columnMeta["data_type"] === "json_array_string") {
            if (typeof productData[key] === "string") {
              value = productData[key].split(",");
            }
          }
        }
        return value;
      }
      return "";
    }
  };

  const getPrefilledInputData = ({ columnList, productDetails }) => {
    let tempInputData = {};

    for (let i = 0; i < columnList.length; i++) {
      let item = columnList[i];

      let value = getFieldValue({
        columnMeta: item,
        productDetails: productDetails,
      });
      tempInputData[item["column_name"]] = value;
    }

    return tempInputData;
  };

  const initEditableData = async ({ columnList }) => {
    let productDetails = await getProductDetails();
    let preFilledData = getPrefilledInputData({
      columnList: columnList,
      productDetails: productDetails,
    });
    setFieldValues(preFilledData);
    const tempImageFiles = Object.keys(productDetails)
      .filter((key) => key.startsWith("image_"))
      .reduce((acc, key) => {
        acc[key] = productDetails[key];
        return acc;
      }, {});
    const tempDocFiles = Object.keys(productDetails)
      .filter((key) => key.startsWith("file_"))
      .reduce((acc, key) => {
        acc[key] = productDetails[key];
        return acc;
      }, {});
    setSelectedImages(tempImageFiles);
    setSelectedDocuments(tempDocFiles);

    let productImage =
      productDetails.hasOwnProperty("product_image") &&
      productDetails["product_image"] !== null &&
      productDetails["product_image"] !== ""
        ? productDetails["product_image"]
        : null;
    let productVideo =
      productDetails.hasOwnProperty("product_video") &&
      productDetails["product_video"] !== null &&
      productDetails["product_video"] !== ""
        ? productDetails["product_video"]
        : null;
    let productBrochure =
      productDetails.hasOwnProperty("product_brochure") &&
      productDetails["product_brochure"] !== null &&
      productDetails["product_brochure"] !== ""
        ? productDetails["product_brochure"]
        : null;
    setMediaDetails({
      product_image: productImage,
      product_brochure: productBrochure,
      product_video: productVideo,
    });
    let existingAdditionalDetails =
      productDetails.hasOwnProperty("additional_details") &&
      productDetails["additional_details"] !== null &&
      productDetails["additional_details"] !== ""
        ? productDetails["additional_details"]
        : {};
    if (typeof existingAdditionalDetails === "string") {
      existingAdditionalDetails = JSON.parse(existingAdditionalDetails);
    }

    let exstingSpecifications =
      productDetails.hasOwnProperty("specifications") &&
      productDetails["specifications"] !== null &&
      productDetails["specifications"] !== ""
        ? productDetails["specifications"]
        : {};
    if (typeof exstingSpecifications === "string") {
      exstingSpecifications = JSON.parse(exstingSpecifications);
    }
    let tempSpecificationList = [];
    for (const key in exstingSpecifications) {
      tempSpecificationList.push({
        label: key,
        value: exstingSpecifications[key],
      });
    }
    let tempAdditionalDetailsList = [];
    for (const key in existingAdditionalDetails) {
      tempAdditionalDetailsList.push({
        label: key,
        value: existingAdditionalDetails[key],
      });
    }

    setAdditionalDetailsList(tempAdditionalDetailsList);

    setSpecificationList(tempSpecificationList);

    setInventoryDetails({
      manage_inventory:
        productDetails.hasOwnProperty("manage_inventory") &&
        productDetails.manage_inventory !== null &&
        productDetails.manage_inventory !== ""
          ? productDetails["manage_inventory"]
          : false,
    });
    const newBuilderProductLineDetails = {
      product_manufacturer:
        productDetails.hasOwnProperty("product_manufacturer") &&
        productDetails.product_manufacturer !== null &&
        productDetails.product_manufacturer !== ""
          ? {
              label: productDetails.product_manufacturer.name,
              value: {
                id: productDetails.product_manufacturer.id,
                name: productDetails.product_manufacturer.name,
              },
            }
          : null,
      product_line:
        productDetails.hasOwnProperty("product_line") &&
        productDetails.product_line !== null &&
        productDetails.product_line !== ""
          ? {
              label: productDetails.product_line.name,
              value: {
                id: productDetails.product_line.id,
                name: productDetails.product_line.name,
              },
            }
          : null,
    };
    setBuilderProductLineDetails(newBuilderProductLineDetails);

    const newSourceDetails = {
      source_type:
        productDetails.hasOwnProperty("source_type") &&
        productDetails.source_type !== null &&
        productDetails.source_type !== ""
          ? productDetails.source_type
          : "internal",
      internal_user: {},
      external_user: {},
    };

    if (newSourceDetails["source_type"] === "internal") {
      newSourceDetails["internal_user"] =
        productDetails.hasOwnProperty("source_name") &&
        productDetails.source_name !== null &&
        productDetails.source_name !== "" &&
        productDetails.hasOwnProperty("source_id") &&
        productDetails.source_id !== null &&
        productDetails.source_id !== ""
          ? { id: productDetails.source_id, name: productDetails.source_name }
          : {};
    } else {
      newSourceDetails["external_user"] =
        productDetails.hasOwnProperty("source_name") &&
        productDetails.source_name !== null &&
        productDetails.source_name !== "" &&
        productDetails.hasOwnProperty("source_id") &&
        productDetails.source_id !== null &&
        productDetails.source_id !== ""
          ? { id: productDetails.source_id, name: productDetails.source_name }
          : {};
    }
    setSourceDetails(newSourceDetails);
  };

  const getProductDetails = async () => {
    let response = await getProductDetailsApi({ productID: productID });
    return response;
  };

  return !loading ? (
    <>
      <Box role="presentation" sx={{ width: drawerWidth }}>
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: drawerWidth,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar style={{ padding: "16px" }}>
            <ModelHeader>
              {isEdit === true
                ? getAccountLocalizedText("edit_product")
                : getAccountLocalizedText("add_product")}
            </ModelHeader>
          </Toolbar>
        </AppBar>

        {!isSubmitSuccess ? (
          <Box
            component="main"
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Tabs
              orientation="vertical"
              sx={{
                backgroundColor: "#f9f9fc",
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
                position: "sticky",
                width: "250px",
                top: "64px",
                left: "0px",
                height: "calc(100vh - 64px)",
              }}
              variant="standard"
              value={tabIdx}
              textColor="primary"
              indicatorColor="primary"
              scrollButtons={"auto"}
              onChange={(event, newValue) => {
                setTabIdx(newValue);
              }}
            >
              <Tab
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#E7EEFA",
                    color: "#4079DA",
                    font: "normal normal 600 14px Open Sans",
                    textTransform: "capitalize",
                  },

                  backgroundColor: "#f9f9fc",
                  color: "#4D4E4F",
                  font: "normal normal 600 14px Open Sans",
                  textTransform: "capitalize",
                }}
                label={"Basic Details"}
              />
              <Tab
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#E7EEFA",
                    color: "#4079DA",
                    font: "normal normal 600 14px Open Sans",
                    textTransform: "capitalize",
                  },

                  backgroundColor: "#f9f9fc",
                  color: "#4D4E4F",
                  font: "normal normal 600 14px Open Sans",
                  textTransform: "capitalize",
                }}
                label={"Specifications & Additional Details"}
              />
              <Tab
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#E7EEFA",
                    color: "#4079DA",
                    font: "normal normal 600 14px Open Sans",
                    textTransform: "capitalize",
                  },

                  backgroundColor: "#f9f9fc",
                  color: "#4D4E4F",
                  font: "normal normal 600 14px Open Sans",
                  textTransform: "capitalize",
                }}
                label={getAccountLocalizedText(
                  "manufacturers_and_product_lines"
                )}
              />
              <Tab
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#E7EEFA",
                    color: "#4079DA",
                    font: "normal normal 600 14px Open Sans",
                    textTransform: "capitalize",
                  },

                  backgroundColor: "#f9f9fc",
                  color: "#4D4E4F",
                  font: "normal normal 600 14px Open Sans",
                  textTransform: "capitalize",
                }}
                label={"Source Details"}
              />
            </Tabs>
            <Wrapper
              component={"form"}
              onSubmit={(e) => {
                e.preventDefault();
                if (tabIdx < 3) {
                  setTabIdx(tabIdx + 1);
                } else {
                  handleSubmit();
                }
              }}
            >
              {(() => {
                if (tabIdx === 0) {
                  const fieldsToRender = sortedColumnsList.filter(
                    (entry) => entry["is_custom_param"] === false
                  );

                  return (
                    <BasicDetailsScreen
                      getFileUrl={(file) => getFileUrl(file)}
                      fieldValues={fieldValues}
                      handleChange={({ key, value }) => {
                        handleChange({
                          key: key,
                          value: value,
                        });
                      }}
                      fieldsToRender={fieldsToRender}
                      getOptionListForSelectFieldToRender={(listFieldMeta) =>
                        getOptionListForSelectFieldToRender(listFieldMeta)
                      }
                      listFieldInputMeta={listFieldInputMeta}
                      mediaDetails={mediaDetails}
                      handleRemoveProductMedia={(type) => {
                        let tempMediaDetails = { ...mediaDetails };
                        tempMediaDetails[type] = null;
                        setMediaDetails(tempMediaDetails);
                      }}
                      handleViewProductMedia={(type) => {
                        if (type === "product_image") {
                          handleImagePreview(mediaDetails["product_image"]);
                        } else if (type === "product_brochure") {
                          handleDocumentPreview(
                            mediaDetails["product_brochure"]
                          );
                        } else if (type === "product_video") {
                          handleVideoPreview(mediaDetails["product_video"]);
                        }
                      }}
                      handleProductMediaUpload={(file, type) =>
                        handleProductMediaUpload(file, type)
                      }
                      handleRemoveDoc={(idx) => handleRemoveDoc(idx)}
                      handleDocumentPreview={(doc) =>
                        handleDocumentPreview(doc)
                      }
                      handleImageUpload={(imageFile) =>
                        handleImageUpload(imageFile)
                      }
                      handleRemoveImage={(imageIndex) =>
                        handleRemoveImage(imageIndex)
                      }
                      handleImagePreview={(docFile) =>
                        handleImagePreview(docFile)
                      }
                      selectedDocuments={selectedDocuments}
                      selectedImages={selectedImages}
                      handleDocumentUpload={(docFile) =>
                        handleDocumentUpload(docFile)
                      }
                      manageInventory={inventoryDetails["manage_inventory"]}
                      handleInventoryCheckbox={handleInventoryCheckbox}
                    />
                  );
                } else if (tabIdx === 1) {
                  return (
                    <SpecificationsScreen
                      handleSpecificationDetails={(details) => {
                        let tempSpecificationList = [...specificationList];
                        let index = tempSpecificationList.findIndex(
                          (entry) =>
                            entry["label"].toLowerCase() ===
                            details["label"].toLowerCase()
                        );
                        if (index === -1) {
                          tempSpecificationList.push(details);
                        }
                        setSpecificationList(tempSpecificationList);
                      }}
                      handleSpecificationsDelete={(label) => {
                        let tempSpecificationList = [...specificationList];
                        tempSpecificationList = tempSpecificationList.filter(
                          (entry) =>
                            entry["label"].toLowerCase() !== label.toLowerCase()
                        );
                        setSpecificationList(tempSpecificationList);
                      }}
                      specificationList={specificationList}
                      additionalDetailsList={additionalDetailsList}
                      handleAdditionalDetails={(details) => {
                        let tempAdditionalDetailsList = [
                          ...additionalDetailsList,
                        ];
                        let index = tempAdditionalDetailsList.findIndex(
                          (entry) =>
                            entry["label"].toLowerCase() ===
                            details["label"].toLowerCase()
                        );
                        if (index === -1) {
                          tempAdditionalDetailsList.push(details);
                        }
                        setAdditionalDetailsList(tempAdditionalDetailsList);
                      }}
                      handleAdditionalDetailsDelete={(label) => {
                        let tempAdditionalDetailsList = [
                          ...additionalDetailsList,
                        ];
                        tempAdditionalDetailsList =
                          tempAdditionalDetailsList.filter(
                            (entry) =>
                              entry["label"].toLowerCase() !==
                              label.toLowerCase()
                          );
                        setAdditionalDetailsList(tempAdditionalDetailsList);
                      }}
                    />
                  );
                } else if (tabIdx === 2) {
                  return (
                    <BuilderProductLineScreen
                      details={builderProductLineDetails}
                      handleChange={(name, value) => {
                        setBuilderProductLineDetails({
                          ...builderProductLineDetails,
                          [name]: value,
                        });
                      }}
                      handleProductLineChange={handleProductLineChange}
                      productManufacturerList={productManufacturerList}
                      productLineList={productLineList}
                    />
                  );
                } else {
                  return (
                    <SourceScreen
                      details={sourceDetails}
                      userList={userList}
                      partnersList={partnersList}
                      handleSetSourceType={handleSetSourceType}
                      handleChange={(e) => {
                        const { name, value } = e.target;
                        setSourceDetails({
                          ...sourceDetails,
                          [name]: value,
                        });
                      }}
                    />
                  );
                }
              })()}

              <AppBar
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                  top: "auto",
                  bottom: 0,
                  width: drawerWidth,
                  bgcolor: "#f9f9fc",
                }}
              >
                <Toolbar
                  style={{
                    width: drawerWidth,
                    position: "relative",
                    padding: "16px",
                  }}
                >
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <ButtonWrapper>
                    <RenderCancelOrPreviousButton />
                    <RenderNextOrSubmitButton />
                  </ButtonWrapper>
                </Toolbar>
              </AppBar>
            </Wrapper>
          </Box>
        ) : (
          <MessageWrapper>
            {isEdit ? (
              <Message>
                {getAccountLocalizedText("product_updated_successfully")}
              </Message>
            ) : (
              <Message>
                {getAccountLocalizedText("product_added_successfully")}
              </Message>
            )}

            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="inherit"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        )}
      </Box>

      {viewPdfDialog && (
        <PreviewPdfDialog
          open={viewPdfDialog}
          setOpen={setViewPdfDialog}
          file={selectedFileUrl}
        />
      )}
      {viewImageDialog && (
        <PreviewImageDialog
          open={viewImageDialog}
          setOpen={setViewImageDialog}
          file={selectedFileUrl}
          isCreate={false}
        />
      )}
      {openVideoPreviewDialog && (
        <PreviewVideoDialog
          open={openVideoPreviewDialog}
          setOpen={setOpenVideoPreviewDialog}
          fileUrl={
            mediaDetails["product_video"] !== null &&
            mediaDetails["product_video"] !== ""
              ? URL.createObjectURL(mediaDetails["product_video"])
              : ""
          }
        />
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: drawerWidth,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(AddProductSidePanel);
