import {
  getPhoneApi,
  getProjectMemberDialerConfigApi,
  getTeckinfoCallStatus,
  teckinfoClickToCall,
  teckinfoCloseCallApi,
  teckinfoGetTokenApi,
  teckinfoLeadpixieCall,
} from "../Api";
import { isTeckinfoDialer } from "../Functions";
import rootStore from "../stores/RootStore";
import CallService from "./callService";

class TeckinfoCallService extends CallService {
  ssoEp = "https://192.168.0.225:9003/panel";
  tsplToken = null;
  callNumber = null;
  dialerConfig = {
    terminal_id: "",
    host_id: "",
    dni: "",
  };
  makeCall = async (leadID) => {
    try {
      if (this.tsplToken === null) {
        let response = await this.fetchToken();
        if (response.hasOwnProperty("ResultString")) {
          this.tsplToken = response["ResultString"];
        } else {
          return {
            message:
              "Not able to get token! Please contact your administrator.",
          };
        }
      }
      let queryParams = {};
      let phoneNumber = await this.fetchLeadPhone(leadID);
      phoneNumber = this.extract10DigitNumber(phoneNumber);
      queryParams["dni"] = this.dialerConfig["dni"];
      queryParams["cli"] = phoneNumber;
      queryParams["addon"] = "";
      queryParams["hostId"] = this.dialerConfig["host_id"];
      queryParams["tspltoken"] = this.tsplToken;
      let clickToCallResponse = await teckinfoClickToCall({
        queryParams: queryParams,
      });
      if (Object.keys(clickToCallResponse).length > 0) {
        this.callNumber = clickToCallResponse["data"]["callNumber"];
        let payload = {
          call_number: clickToCallResponse["data"]["callNumber"],
          dial_response: clickToCallResponse,
        };
        let leadpixie_response = await teckinfoLeadpixieCall({
          leadID: leadID,
          payload: payload,
        });
        if (!leadpixie_response.hasError()) {
          return leadpixie_response.data;
        } else {
          return {
            message: leadpixie_response.errorMessage,
          };
        }
      } else {
        return {
          message:
            "Error initiating the call! Please contact your administrator.",
        };
      }
    } catch (error) {
      return {
        message: "Something went wrong! Please contact your administrator.",
      };
    }
  };

  fetchLeadPhone = async (leadID) => {
    try {
      let response = await getPhoneApi(leadID);
      return response.data;
    } catch (error) {
      return "";
    }
  };

  fetchToken = async () => {
    let queryParams = {
      hostId: this.dialerConfig["host_id"],
    };

    let response = await teckinfoGetTokenApi({
      queryParams: queryParams,
    });
    return response;
  };

  fetchCallStatus = async (formData) => {
    let response = await getTeckinfoCallStatus(formData);
    return response;
  };
  setCallDisposition = async (leadID, formData) => {
    let queryParams = {
      disposition: formData["lead_status"],
      nextDialTime: "",
      remarks: "",
      callNumber: this.callNumber,
      hostId: this.dialerConfig["host_id"],
      extraParameters: "",
      tspltoken: this.tsplToken,
    };
    let response = await teckinfoCloseCallApi({ queryParams: queryParams });
    return response;
  };

  setupDialerConfig = async () => {
    this.dialerConfig = {
      terminal_id: "",
      host_id: "",
      dni: "",
    };
    if (isTeckinfoDialer()) {
      let payload = {
        dialer: "teckinfo",
        user_id: rootStore.userStore.UserData["id"],
      };
      let response = await getProjectMemberDialerConfigApi({
        payload: payload,
      });
      if (
        response !== null &&
        response.hasOwnProperty("xdata") &&
        Object.keys(response["xdata"]).length > 0
      ) {
        this.dialerConfig = response["xdata"];
      }
    }
  };

  getSSOUrl = () => {
    let url = `${this.ssoEp}?agent_login_id=${this.dialerConfig["host_id"]}&agent_terminal_id=${this.dialerConfig["terminal_id"]}`;
    return url;
  };

  extract10DigitNumber = (text) => {
    // Remove all non-digit characters
    const cleanedText = text.replace(/\D/g, "");
    // Find the 10-digit number
    const match = cleanedText.match(/\d{10}$/);
    if (match) {
      return match[0];
    } else {
      return text;
    }
  };

  reset = () => {
    this.tsplToken = null;
    this.callNumber = null;
    this.dialerConfig = {
      terminal_id: "",
      host_id: "",
      dni: "",
    };
  };
}

export default TeckinfoCallService;
