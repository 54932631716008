import React, { useEffect, useMemo, useState } from "react";
import { AddBoxOutlined, PlayArrow } from "@material-ui/icons";
import rootStore from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { callsColumns } from "../../../Db";
import CallLogModal from "../../call_log_modal/CallLogModal";
import { callLogsLimit } from "../../../Config";
import ViewCallLogDetails from "../../call_log_modal/ViewCallLogDetails";
import CallInfoDialog from "../../call/CallInfoDialog";
import { callInfoSheetScope } from "../../../utils/enum";
import analytics from "../../../Analytics";
import PaginationWithoutPageSize from "../../pagination/PaginationWithoutPageSize";
import { Box, CircularProgress, IconButton, styled } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import AudioPlayerModal from "../../audio_player_modal/AudioPlayerModal";

const Container = styled(Box)`
  width: 100%;
  height: 100%;
`;
const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 0px;
  top: 9px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
`;
const TableWrapper = styled(Box)`
  position: relative;
  width: 100%;
  ${`min-height: calc(100vh - 345px);`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const CallLogsTab = ({
  uid,
  lid,
  setRefreshLeadDetails,
  isUpdateStatusDisabled,
  leadData,
}) => {
  const [open, setOpen] = useState(false);

  const [count, setCount] = useState(0);
  const [row, setRow] = useState([]);
  const [selectedCallLog, setSelectedCallLog] = useState(null);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);

  const [viewLog, setViewLog] = useState(false);
  const { authStore, phoneCallState } = rootStore;
  const [openCallInfo, setOpenCallInfo] = useState(false);
  const [callInfoScope, setCallInfoScope] = useState(null);
  const [refreshCallLogAfterAdd, setRefreshCallLogAfterAdd] = useState(false);
  const [openAudioPlayer, setOpenAudioPlayer] = useState(false);

  const getAudioUrl = (callLogData) => {
    if (
      callLogData.hasOwnProperty("audio") &&
      callLogData["audio"] !== null &&
      callLogData["audio"] !== ""
    ) {
      return callLogData["audio"];
    }

    let dialerLog = callLogData["dialer_log"] ?? {};
    let externalUrl = "";

    if (Object.keys(dialerLog).length > 0) {
      let summary = dialerLog["summary_response"] ?? {};
      if (dialerLog["dialer"] === "leadpixie_dialer") {
        externalUrl = summary["audio"] ?? "";
      } else {
        externalUrl = phoneCallState.getExternalDialerAudioUrl(summary);
      }
    }
    return externalUrl;
  };
  const actionColumn = [
    {
      field: "audio",
      headerName: "AUDIO",
      minWidth: 100,
      flex: 1,
      cellRenderer: (params) => {
        let audioUrl = getAudioUrl(params.data);
        if (audioUrl !== null && audioUrl !== "") {
          return (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setSelectedCallLog(params.data);
                setOpenAudioPlayer(true);
              }}
            >
              <PlayArrow color="primary" />
            </IconButton>
          );
        } else {
          return "-";
        }
      },
    },
  ];

  const canOpenCallInfoDialog = () => {
    let leadInfo = {};
    if (phoneCallState.callStatus.hasOwnProperty("lead")) {
      leadInfo = phoneCallState.callStatus["lead"];
    }
    if (
      leadInfo.hasOwnProperty("id") &&
      leadInfo["id"] !== null &&
      leadInfo["id"] !== ""
    ) {
      if (authStore.leadDetailStatus["lead_id"] !== leadInfo["id"]) {
        return true;
      }
    }
    return false;
  };
  const handleClick = () => {
    if (phoneCallState.hasPendingCallLog()) {
      if (canOpenCallInfoDialog()) {
        setOpenCallInfo(true);
        setCallInfoScope(callInfoSheetScope.lead_details_call_log);
      } else {
        setOpen(true);
      }
    } else {
      setOpen(true);
    }
  };
  const getCallLogData = async ({ page_no }) => {
    setLoading(true);
    setRow([]);
    let data = await rootStore.authStore.getLeadCallDetails({
      leadID: lid,
      offset: page_no,
    });
    if (data.items !== null && data.item_count !== null) {
      setRow(data.items);
      setCount(data.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
    setLoading(false);
  };

  const fetchFirstPageCallLogs = async () => {
    setLoading(true);
    await rootStore.authStore.fetchLeadCallLogs({
      leadID: lid,
      offset: 0,
    });
    let items = rootStore.authStore.currentLeadInfo.call_logs.items;
    let item_count = rootStore.authStore.currentLeadInfo.call_logs.item_count;
    if (items !== null && item_count !== null) {
      setRow(items);
      setCount(item_count);
    } else {
      setRow([]);
      setCount(0);
    }
    setLoading(false);
  };
  const init = async () => {
    let offset =
      rootStore.authStore.currentLeadInfo.call_logs.start_index !== null
        ? rootStore.authStore.currentLeadInfo.call_logs.start_index
        : 0;
    setPage(offset);
    if (offset === 0) {
      await fetchFirstPageCallLogs();
    } else {
      await getCallLogData({ page_no: offset });
    }
  };

  const refetch = async () => {
    await getCallLogData({ page_no: page });
    setRefreshCallLogAfterAdd(false);
  };

  const handlePagination = async (e, page) => {
    setPage(page - 1);
    await getCallLogData({ page_no: page - 1 });
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000051,
      "call_logs_tab_load",
      "lead_details_page",
      "",
      {}
    );
    init();
  }, []);

  useEffect(() => {
    if (refreshCallLogAfterAdd) {
      refetch();
    }
  }, [refreshCallLogAfterAdd]);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  return (
    <>
      <Container>
        {!loading ? (
          <TableWrapper>
            <AddBtnWrapper>
              <AddBoxOutlined
                color={isUpdateStatusDisabled() ? "disabled" : "primary"}
                onClick={() => {
                  authStore.updateLastActiveTime();
                  if (isUpdateStatusDisabled()) {
                    return null;
                  } else {
                    analytics.triggerEvent(
                      4625000063,
                      "add_call_log_button_tap",
                      "call_logs_tab",
                      "add_call_log_button",
                      {}
                    );
                    handleClick();
                  }
                }}
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </AddBtnWrapper>

            <div className="ag-theme-alpine" style={{ width: "100%" }}>
              <AgGridReact
                domLayout="autoHeight"
                gridOptions={gridOptions}
                rowData={row}
                defaultColDef={defaultColDef}
                columnDefs={callsColumns.concat(actionColumn)}
                animateRows={true}
                suppressCellFocus
                pagination={false}
                paginationPageSize={callLogsLimit}
                onRowClicked={(row) => {
                  if (row.event.defaultPrevented) {
                    return null;
                  }
                  analytics.triggerEvent(
                    4625000067,
                    "call_log_row_tap",
                    "call_logs_tab",
                    "",
                    {}
                  );
                  setSelectedCallLog(row.data);
                  setViewLog(true);
                }}
                suppressColumnVirtualisation={true}
              />
            </div>
            <PaginationWithoutPageSize
              page_no={page}
              row={row}
              count={count}
              handlePage={handlePagination}
              counterText={rootStore.authStore.getLeadCallLogsPaginationCounterText()}
              page_size={callLogsLimit}
            />
          </TableWrapper>
        ) : (
          <TableWrapper
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </TableWrapper>
        )}
      </Container>

      {open ? (
        <CallLogModal
          scope={"call_log_tab"}
          open={open}
          setOpen={setOpen}
          lid={lid}
          uid={uid}
          setRefreshCallLogAfterAdd={setRefreshCallLogAfterAdd}
          setRefreshLeadDetails={setRefreshLeadDetails}
          leadData={leadData}
        />
      ) : null}

      {viewLog && (
        <ViewCallLogDetails
          setOpen={setViewLog}
          open={viewLog}
          data={selectedCallLog}
          setData={setSelectedCallLog}
        />
      )}
      {openCallInfo && (
        <CallInfoDialog
          open={openCallInfo}
          setOpen={setOpenCallInfo}
          scope={callInfoScope}
          openCallLogCallback={async () => {
            setOpen(true);
            setOpenCallInfo(false);
          }}
        />
      )}
      {openAudioPlayer && (
        <AudioPlayerModal
          audioUrl={getAudioUrl(selectedCallLog)}
          open={openAudioPlayer}
          setOpen={setOpenAudioPlayer}
        />
      )}
    </>
  );
};

export default observer(CallLogsTab);
