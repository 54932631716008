import React from "react";
import AsyncSelect from "react-select/async";
import {
  getProductLineListApi,
  getProductManufacturerListApi,
} from "../../Api";
import { getLocalizedText } from "../../Functions";

const SearchableSelectFormField = (props) => {
  const filter = async (inputValue) => {
    const queryPayload = {
      name: inputValue,
    };
    let response = {};
    if (props.type === "product_manufacturer") {
      response = await getProductManufacturerListApi({
        page: 0,
        page_size: 10,
        payload: { query: queryPayload },
      });
    } else {
      response = await getProductLineListApi({
        page: 0,
        page_size: 10,
        payload: { query: queryPayload },
      });
    }

    //return list for select dropdown
    return response.items.map((item) => ({
      value: item,
      label: item.name,
    }));
  };

  const loadOptions = (inputValue) => {
    if (inputValue.length > 3) {
      return filter(inputValue);
    } else {
      return Promise.resolve([]);
    }
  };

  const getDefaultOptions = () => {
    return props.defaultOptions.map((item) => ({
      value: item,
      label: item.name,
    }));
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for options
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for the entire control container, including the search input
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for "no options" message
    }),
    loadingMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for "loading" message
    }),
  };

  return (
    <>
      <AsyncSelect
        styles={{
          ...customStyles,
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        value={props.selectedItem || null}
        defaultOptions={getDefaultOptions()}
        cacheOptions={true}
        menuPortalTarget={document.body}
        isClearable={true}
        onChange={(value) => props.onChangeCallback(value || null)}
        loadOptions={loadOptions}
        placeholder={getLocalizedText("search")}
        menuPlacement={props.menuPlacement ? props.menuPlacement : "bottom"}
      />
    </>
  );
};

export default SearchableSelectFormField;
