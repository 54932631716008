import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import TextEditorCard from "./TextEditorCard";

const Wrapper = styled(Box)`
  width: 100%;
  height: fit-content;
  background-color: white;
  border: 1px solid #eceef2;
  padding: 16px;
  border-radius: 4px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const BasicDetailsCard = ({
  basicDetails,
  setBasicDetails,
  widgetList,
  setWidgetList,
  scope,
}) => {
  const renderWidgets = () => {
    return widgetList.map((widgetData, idx) => {
      if (widgetData["type"] === "text") {
        return (
          <Grid item xs={12} key={idx}>
            <InputWrapper>
              <Label>Quotation Title</Label>
              <TextEditorCard
                editableData={widgetData}
                handleEdit={(htmlContent) => {
                  let tempWidgetList = [...widgetList];
                  const index = tempWidgetList.findIndex(
                    (entry) => entry["id"] === widgetData["id"]
                  );
                  if (index !== -1) {
                    let widgetData = tempWidgetList[index];
                    widgetData["content"] = htmlContent;
                    tempWidgetList[index] = widgetData;
                    setWidgetList(tempWidgetList);
                  }
                }}
              />
            </InputWrapper>
          </Grid>
        );
      }
    });
  };
  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputWrapper>
            <Label>Template Name*</Label>
            <OutlinedInput
              style={{ height: "30px" }}
              inputProps={{
                min: 0,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              disabled={scope === "edit"}
              name="name"
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  ["name"]: e.target.value,
                });
              }}
              value={basicDetails["name"]}
              type={"text"}
              placeholder={"Enter Value"}
              required
            />
          </InputWrapper>
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            <RadioGroup
              row
              value={
                basicDetails["quotation_template"]
                  ? "quotation_template"
                  : "invoice_template"
              }
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  quotation_template: e.target.value === "quotation_template",
                  invoice_template: e.target.value === "invoice_template",
                });
              }}
            >
              <FormControlLabel
                disabled={scope === "edit"}
                value="quotation_template"
                control={<Radio size="small" />}
                label={
                  <Label
                    style={{
                      marginBottom: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                  >
                    Quotation Template
                  </Label>
                }
              />
              <FormControlLabel
                disabled={scope === "edit"}
                value="invoice_template"
                control={<Radio size="small" />}
                label={
                  <Label
                    style={{
                      marginBottom: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                  >
                    Invoice Template
                  </Label>
                }
              />
            </RadioGroup>
          </FormGroup>
        </Grid>
        {renderWidgets()}
      </Grid>
    </Wrapper>
  );
};

export default BasicDetailsCard;
