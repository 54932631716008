import React from "react";
import styled from "styled-components";
import { Box, Typography, Card, CardActionArea } from "@mui/material";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import analytics from "../../Analytics";
import rootStore from "../../stores/RootStore";
import { AddTask } from "@mui/icons-material";
import businessIcon from "../../assets/images/whatsapp-business.svg";
import {
  checkIfProjectHasWabaProvider,
  validateDisabledFeature,
} from "../../Functions";
import add_deal_icon from "../../assets/campaign_icons/add_deal.png";
import create_quote_icon from "../../assets/campaign_icons/create_quote.png";
import create_invoice_icon from "../../assets/campaign_icons/create_invoice.png";

const Container = styled(Box)(({ theme }) => ({}));

const Icon = styled.img`
  height: auto;
  width: 20px;
  cursor: pointer;
`;

const StickyIconCard = ({ icon, label, onClick, disabled }) => {
  return (
    <Card
      sx={{
        display: "flex",
        width: "145px",
        height: "45px",
        minHeight: "40px",
        transform: "translate(70px,0px)",
        alignItems: "center",
        margin: "2px",
        marginTop: "3px",
        marginBottom: "3px",
        textDecoration: "none",
        transition: "transform 0.6s",
        "&:hover": {
          transform: "translate(-40px,0px)",
        },
      }}
    >
      <CardActionArea
        disabled={disabled}
        sx={{ width: "100%", height: "100%" }}
        onClick={onClick}
      >
        <Box
          sx={{
            opacity: !disabled ? 1 : 0.4, //dulling when disabled
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: "7px",
            }}
          >
            {icon}
          </Box>
          <Typography
            variant="body1"
            sx={{
              width: "100%",
              color: "#000",
              textAlign: "center",
              fontSize: "14px",
              lineHeight: 1.2,
            }}
          >
            {label}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
};

const StickyIconsSidebar = ({
  canEditLead,
  isUpdateStatusDisabled,
  setWhatsAppContextAnchor,
  handleWhatsapp,
  handleAddCallLog,
  messageTemplates,
  handleCreateTask,
  handleWhatsappBusiness,
  handleCreateQuote,
  handleCreateInvoice,
  isAddCustomDealDisabled,
  setOpenAddDealPanel,
}) => {
  const { authStore } = rootStore;

  return (
    <>
      <Container
        sx={{
          position: "fixed",
          top: "50%",
          right: 0,
          height: "auto",
          width: "60px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "1rem",
          zIndex: 10,
          transform: "translateY(-50%)",
        }}
      >
        <StickyIconCard
          icon={<AddIcCallIcon color="primary" fontSize="small" />}
          label="Add Call Log"
          onClick={handleAddCallLog}
          disabled={isUpdateStatusDisabled()}
        />

        {rootStore.subscriptionState.isFeatureAvailableWithAddOns("Deals") && (
          <StickyIconCard
            icon={<Icon src={add_deal_icon} alt="loading.." />}
            label="Add Deal"
            onClick={() => setOpenAddDealPanel(true)}
            disabled={isAddCustomDealDisabled()}
          />
        )}

        {rootStore.subscriptionState.isFeatureAvailableWithAddOns(
          "Quotations"
        ) && (
          <>
            <StickyIconCard
              icon={<Icon src={create_quote_icon} alt="loading.." />}
              label="Create Quote"
              onClick={handleCreateQuote}
              disabled={!canEditLead()}
            />
            <StickyIconCard
              icon={<Icon src={create_invoice_icon} alt="loading.." />}
              label="Create Invoice"
              onClick={handleCreateInvoice}
              disabled={!canEditLead()}
            />
          </>
        )}

        {validateDisabledFeature("whatsapp_personal") && (
          <StickyIconCard
            icon={<WhatsAppIcon sx={{ color: "#25d267" }} fontSize="small" />}
            label="WhatsApp"
            onClick={(e) => {
              analytics.triggerEvent(
                4625000039,
                "whatsapp_button_tap",
                "lead_details_page",
                "whatsapp_button",
                {}
              );
              authStore.updateLastActiveTime();
              if (messageTemplates.length > 0) {
                setWhatsAppContextAnchor(e.currentTarget);
              } else {
                handleWhatsapp();
              }
            }}
            disabled={!canEditLead()}
          />
        )}

        {validateDisabledFeature("waba") && checkIfProjectHasWabaProvider() && (
          <StickyIconCard
            icon={
              <img src={businessIcon} alt="Whatsapp Business" width="20px" />
            }
            label="WhatsApp Business"
            onClick={(e) => {
              analytics.triggerEvent(
                4625000039,
                "whatsapp_button_tap",
                "lead_details_page",
                "whatsapp_button",
                {}
              );
              authStore.updateLastActiveTime();
              handleWhatsappBusiness();
            }}
            disabled={!canEditLead()}
          />
        )}

        {rootStore.subscriptionState.isFeatureAvailableWithAddOns("Tasks") && (
          <StickyIconCard
            icon={<AddTask color="primary" fontSize="small" />}
            label="Create Task"
            onClick={handleCreateTask}
            disabled={!canEditLead()}
          />
        )}
      </Container>
    </>
  );
};

export default StickyIconsSidebar;
