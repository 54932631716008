import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Box } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import DealDeatilsWithHistoryWidget from "./details_widgets/DealDetailsWithHistoryWidget";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  ${`min-height: calc(100vh - 258px);`};
  margin-bottom: 20px;
`;
const Wrapper = styled(Box)`
  display: flex;
`;

const DetailsWrapper = styled(Box)`
  ${`min-height: calc(100vh - 282px);`};
  width: 100%;
  background: white;
  border-radius: 10px;
  display: flex;
`;

const DealsTab = ({
  setOpenAddDealPanel,
  setOpenAddPredefinedDealPanel,
  setOpenDeactivateDealDialog,
  details,
  dealsHistoryList,
  refetchActiveDealAndDealList,
}) => {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const init = async () => {
    setLoading(false);
  };
  const refetch = async () => {
    setLoading(true);
    await refetchActiveDealAndDealList();
    setLoading(false);
    setRefresh(false);
  };
  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  const resetStateVariables = () => {
    setOpenAddDealPanel(false);
    setOpenAddPredefinedDealPanel(false);
    setOpenDeactivateDealDialog(false);
  };

  useEffect(() => {
    init();
    return () => {
      resetStateVariables();
    };
  }, []);

  return (
    <>
      <Container>
        <Wrapper>
          {!loading ? (
            <DetailsWrapper>
              <DealDeatilsWithHistoryWidget
                details={details}
                dealsHistoryList={dealsHistoryList}
              />
            </DetailsWrapper>
          ) : (
            <DetailsWrapper
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <CircularProgress />
            </DetailsWrapper>
          )}
        </Wrapper>
      </Container>
    </>
  );
};

export default observer(DealsTab);
