import React from "react";
import { styled } from "@mui/material/styles";
import { Button, AppBar, Box, Toolbar, Typography } from "@mui/material";
import { Grid } from "@material-ui/core";
import { IsoToLocalDate, dataRenderer, getLocalizedText } from "../../../Functions";
import TableComponent from "./table_component/TableComponent";
import { Edit } from "@material-ui/icons";
import TableComponentAreaBased from "./table_component/TableComponentAreaBased";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Header = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #a9a9a9;
  margin-bottom: 8px;
`;

const DetailWrapper = styled(Box)`
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;
const DetailLabel = styled(Typography)`
  color: #a9a9a9;
  font: normal normal normal 12px Open Sans;
  width: 150px;
`;
const SwitchAndTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ViewQuoteSidepanel = ({
  scope,
  details,
  checkIfDisabled,
  onClickCallback,
}) => {
  const multilineTextGetter = (raw_string) => {
    return raw_string.replace(/\r\n|\n/g, "\n");
  };
  return (
    <Box sx={{ width: 500 }}>
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <ModelHeader>Quote Details</ModelHeader>
            <Box>
              {scope === "lead" && (
                <Button
                  disabled={checkIfDisabled("edit", details.status)}
                  onClick={() => {
                    onClickCallback(details);
                  }}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "fit-content",
                  }}
                >
                  <Edit
                    style={{
                      fontSize: "16px",
                      marginRight: "4px",
                    }}
                  />
                  Edit
                </Button>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <ModelWrapper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputWrapper>
              <Label>Title</Label>
              <Detail>
                {dataRenderer(details["quote_schema"].quote_title)}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Quote</Label>
              <Detail>{dataRenderer(details.quote_number)}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Quote Date</Label>
              <Detail>
                {details.quote_date !== null
                  ? IsoToLocalDate(details.quote_date)
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Expiry Date</Label>
              <Detail>
                {details.expiry_date !== null
                  ? IsoToLocalDate(details.expiry_date)
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Place of Supply</Label>
              <Detail>
                {details.quote_schema.place_of_supply !== null
                  ? dataRenderer(details.quote_schema.place_of_supply)
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Header>{getLocalizedText("product_or_services")}</Header>
              {details.quote_schema.template_type === "template_area_based" ? (
                <TableComponentAreaBased
                  isReadOnly={false}
                  tableData={
                    details["quote_schema"]["items"] !== null &&
                    details["quote_schema"]["items"].length > 0
                      ? details["quote_schema"]["items"]
                      : []
                  }
                />
              ) : (
                <TableComponent
                  isReadOnly={false}
                  tableData={
                    details["quote_schema"]["items"] !== null &&
                    details["quote_schema"]["items"].length > 0
                      ? details["quote_schema"]["items"]
                      : []
                  }
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: "8px",
              }}
            >
              <DetailWrapper>
                <DetailLabel>Sub Total (INR) :</DetailLabel>
                <Detail>
                  {dataRenderer(
                    details["quote_schema"].amount_details.sub_total
                  )}
                </Detail>
              </DetailWrapper>

              <DetailWrapper>
                <SwitchAndTextWrapper>
                  <DetailLabel>Additonal Discount (INR) :</DetailLabel>
                </SwitchAndTextWrapper>
                <Detail>
                  {dataRenderer(
                    details["quote_schema"].amount_details.additional_discount
                  )}
                </Detail>
              </DetailWrapper>

              <DetailWrapper>
                <DetailLabel>Additonal Tax (INR) :</DetailLabel>
                <Detail>
                  {dataRenderer(
                    details["quote_schema"].amount_details.additional_tax
                  )}
                </Detail>
              </DetailWrapper>

              <DetailWrapper>
                <DetailLabel>Adjustments (INR) :</DetailLabel>
                <Detail>
                  {dataRenderer(
                    details["quote_schema"].amount_details.adjustments
                  )}
                </Detail>
              </DetailWrapper>

              <DetailWrapper>
                <DetailLabel>Grand Total (INR) :</DetailLabel>
                <Detail>
                  {dataRenderer(
                    details["quote_schema"].amount_details.grand_total
                  )}
                </Detail>
              </DetailWrapper>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Header>Billing Details</Header>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Billing Name</Label>
                    <Detail>
                      {dataRenderer(
                        details["quote_schema"].billing_details.billing_name
                      )}
                    </Detail>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Billing Address</Label>
                    <Detail
                      component="div"
                      style={{
                        whiteSpace: "pre-line",
                      }}
                    >
                      {multilineTextGetter(
                        dataRenderer(
                          details["quote_schema"].billing_details
                            .billing_address
                        )
                      )}
                    </Detail>
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>Billing City</Label>
                    <Detail>
                      {dataRenderer(
                        details["quote_schema"].billing_details.billing_city
                      )}
                    </Detail>
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>Billing State</Label>
                    <Detail>
                      {dataRenderer(
                        details["quote_schema"].billing_details.billing_state
                      )}
                    </Detail>
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>Billing Country</Label>
                    <Detail>
                      {dataRenderer(
                        details["quote_schema"].billing_details.billing_country
                      )}
                    </Detail>
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>Billing Zipcode</Label>
                    <Detail>
                      {dataRenderer(
                        details["quote_schema"].billing_details.billing_zipcode
                      )}
                    </Detail>
                  </InputWrapper>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Header>Shipping Details</Header>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Shipping Name</Label>
                    <Detail>
                      {dataRenderer(
                        details["quote_schema"].billing_details.shipping_name
                      )}
                    </Detail>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Shipping Address</Label>
                    <Detail
                      component="div"
                      style={{
                        whiteSpace: "pre-line",
                      }}
                    >
                      {multilineTextGetter(
                        dataRenderer(
                          details["quote_schema"].billing_details
                            .shipping_address
                        )
                      )}
                    </Detail>
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>Shipping City</Label>
                    <Detail>
                      {dataRenderer(
                        details["quote_schema"].billing_details.shipping_city
                      )}
                    </Detail>
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>Shipping State</Label>
                    <Detail>
                      {dataRenderer(
                        details["quote_schema"].billing_details.shipping_state
                      )}
                    </Detail>
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>Shipping Country</Label>
                    <Detail>
                      {dataRenderer(
                        details["quote_schema"].billing_details.shipping_country
                      )}
                    </Detail>
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>Shipping Zipcode</Label>
                    <Detail>
                      {dataRenderer(
                        details["quote_schema"].billing_details.shipping_zipcode
                      )}
                    </Detail>
                  </InputWrapper>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Header>Additional Details</Header>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Terms and Conditions</Label>
                    <Detail
                      component="div"
                      style={{
                        whiteSpace: "pre-line",
                      }}
                    >
                      {multilineTextGetter(
                        dataRenderer(
                          details["quote_schema"].additional_details
                            .terms_and_conditions
                        )
                      )}
                    </Detail>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Additional Information</Label>
                    <Detail
                      component="div"
                      style={{
                        whiteSpace: "pre-line",
                      }}
                    >
                      {multilineTextGetter(
                        dataRenderer(
                          details["quote_schema"].additional_details
                            .additional_information
                        )
                      )}
                    </Detail>
                  </InputWrapper>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </ModelWrapper>
    </Box>
  );
};

export default ViewQuoteSidepanel;
