import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { AgGridReact } from "ag-grid-react";
import { dataRenderer, IsoToLocalTime } from "../../../Functions";

const dialogWidth = 600;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const ViewProductHistoryDialog = ({
  open,
  setOpen,
  leadID,
  productHistory,
}) => {
  const [loading, setLoading] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const columnHeaders = [
    {
      field: "sr_no",
      headerName: "S.NO",
      width: 70,
      valueGetter: "node.rowIndex + 1",
    },
    {
      field: "action_at",
      headerName: "ACTION AT",

      width: 200,
      cellRenderer: (params) => {
        let value = IsoToLocalTime(params.data.action_at);
        return (
          <>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      minWidth: 150,
      cellRenderer: (params) => {
        let value = dataRenderer(params.data.product_status);
        return (
          <>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
      minWidth: 150,
      cellRenderer: (params) => {
        let value = dataRenderer(params.data.action);
        return (
          <>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "action_by",
      headerName: "ACTION BY",
      flex: 1,
      minWidth: 150,
      cellRenderer: (params) => {
        let value = dataRenderer(params.data.action_by);
        return (
          <>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const init = async () => {
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModelContainer>
        <Box>
          <AppBar
            component={"nav"}
            elevation={0}
            position="sticky"
            color="inherit"
            sx={{
              top: 0,
              bottom: "auto",
              width: "100%",
              right: 0,
            }}
          >
            <Toolbar
              style={{
                padding: "16px",
              }}
            >
              <HeaderWrapper>
                <Header>Product History</Header>
              </HeaderWrapper>
            </Toolbar>
          </AppBar>
          <Divider style={{ margin: "0px 16px" }} />
          <Container sx={{ width: "1100px" }}>
            {!loading ? (
              <Box
                sx={{
                  width: "100%",
                  height: "400px",
                  overflowY: "auto",
                  overflowX: "auto",
                }}
              >
                <div
                  className="ag-theme-alpine"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    rowData={productHistory}
                    defaultColDef={defaultColDef}
                    columnDefs={columnHeaders}
                    animateRows={true}
                    suppressCellFocus
                  />
                </div>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Container>
          <AppBar
            position="static"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: "100%" }}
          >
            <Toolbar
              style={{
                padding: "16px",
                width: dialogWidth,
                position: "relative",
              }}
            >
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="inherit"
                    style={{
                      fontSize: "12px",
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Box>
      </ModelContainer>
    </Modal>
  );
};

export default ViewProductHistoryDialog;
