import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Divider,
  Modal,
  OutlinedInput,
  Typography,
  AppBar,
  Toolbar,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button, makeStyles } from "@material-ui/core";
import rootStore from "../../stores/RootStore";
import { getLocalizedText } from "../../Functions";
import { delhiveryIntegerationOrderRequestFields } from "../../Db";
import {
  createDelhiveryOrderApi,
  editContactApi,
  editLeadApi,
  getPhoneApi,
} from "../../Api";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  margin: "auto",
  backgroundColor: "white",
  height: "fit-content",
  maxHeight: "95vh",
  width: "500px",
}));

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  height: "fit-content",
  width: "500px",
  border: "none",
  outline: "none",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px #0000000d, 0px 3px 20px #185dd21f",
  border: "1px solid #c5d7f1",
  borderRadius: "8px",
  overflow: "hidden",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "18px",
  fontFamily: "Open Sans",
  color: "#4d4e4f",
}));

const Message = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "12px",
  fontFamily: "Open Sans",
  color: "red",
  marginRight: "10px",
}));

const SuccessMessage = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Open Sans",
  color: "#4d4e4f",
  margin: 0,
  textAlign: "center",
}));

const MessageWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
  alignItems: "center",
}));

const InputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  columnGap: "20px",
  rowGap: "20px",
  maxHeight: "95vh",
  alignContent: "flex-start",
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "fit-content",
  width: "235px",
  flexBasis: "calc(50% - 10px)",
}));

const SubHeader = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Open Sans",
  color: "#6f6f6f",
}));

const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "10px",
  fontFamily: "Open Sans",
  color: "#4d4e4f",
  marginBottom: "6px",
}));

const InputField = styled(OutlinedInput)(({ theme }) => ({
  fontSize: "12px",
  height: "30px",
  "&:focus": {
    outline: "none",
    border: "none",
  },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "flex-end",
}));

const DelhiveryCreateOrder = (props) => {
  const { userStore, authStore } = rootStore;
  const pid = authStore.projectId;
  const columnsList = userStore.AllColumnsList;
  const [editableFields, setEditableFields] = useState({});
  const [isUpdatingLead, setIsUpdatingLead] = useState(false);
  const [updateLeadHasError, setUpdateLeadHasError] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(true);

  const getLeadPhoneNumber = async () => {
    let response = await getPhoneApi(props.data.id);
    return response.data;
  };

  const setup = async () => {
    let newFields = {};
    let customData = props.data.hasOwnProperty("custom_params")
      ? JSON.parse(props.data["custom_params"])
      : {};

    for (const field of delhiveryIntegerationOrderRequestFields) {
      if (props.requestMapping.hasOwnProperty(field.key)) {
        let index = columnsList.findIndex(
          (column) => column["id"] === props.requestMapping[field.key]
        );

        if (index !== -1) {
          if (columnsList[index]["is_custom_param"]) {
            let key = columnsList[index]["field"].replace("custom_params_", "");
            if (customData[key] && customData[key] !== "") {
              newFields[field.key] = customData[key];
            }
          } else {
            if (
              props.data.hasOwnProperty(columnsList[index]["field"]) &&
              props.data[columnsList[index]["field"]] !== null &&
              props.data[columnsList[index]["field"]] !== ""
            ) {
              if (field.key === "phone") {
                let phoneNumber = await getLeadPhoneNumber();
                newFields[field.key] = phoneNumber;
              } else {
                newFields[field.key] = props.data[columnsList[index]["field"]];
              }
            }
          }
        }
      }
    }
    setEditableFields(newFields);
    setLoading(false);
  };

  useEffect(() => {
    setup();
  }, []);

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      shipments: [
        {
          add: editableFields["address"],
          phone: editableFields["phone"],
          payment_mode: editableFields["payment_mode"],
          cod_amount: editableFields["cod_amount"],
          name: editableFields["name"],
          pin: editableFields["pin"],
          order: editableFields["order"],
          ewbn: editableFields["ewbn"],
          cosignee_gst_tin: editableFields["cosignee_gst_tin"],
          hsn_code: editableFields["hsn_code"],
          gst_cess_amount:  editableFields["gst_cess_amount"],
          cosignee_gst_amount: editableFields["cosignee_gst_amount"],
          integrated_gst_amount: editableFields["integrated_gst_amount"],
        },
      ],
      pickup_location: {
        name: editableFields["pickup_location"],
      },
    };
    let payload = {
      project_id: pid,
      data: data,
    };
    let response = await createDelhiveryOrderApi({
      leadID: props.data.id,
      payload: payload,
    });

    if (!response.hasError()) {
      let normalFieldsPayload = {};
      let customFieldsPayload = {};
      let contactFieldsPayload = {};

      function checkAndAssign(key, value) {
        if (typeof value !== "object") {
          if (props.responseMapping.hasOwnProperty(key)) {
            let index = columnsList.findIndex(
              (column) => column["id"] === props.responseMapping[key]
            );
        
            if (index !== -1) {
              if (
                columnsList[index]["contact_column"] !== null &&
                columnsList[index]["contact_column"] !== ""
              ) {
                contactFieldsPayload[columnsList[index]["field"]] = value;
              }
              if (columnsList[index]["is_custom_param"]) {
                let new_key_name = columnsList[index]["field"].replace(
                  "custom_params_",
                  ""
                );
                customFieldsPayload[new_key_name] = value;
              } else {
                normalFieldsPayload[columnsList[index]["field"]] = value;
              }
            }
          }
        }
      }
      //storing the whole response in delivery_order_response column
      customFieldsPayload["delhivery_order_response"] =
        JSON.stringify(response.data);

      //update lead fields with responseMapping from integrations
      if (Object.keys(props.responseMapping).length !== 0) {
        // looping through top level keys
        Object.keys(response.data).forEach((key) => {
          if (typeof response.data[key] !== "object" || response.data[key] === null) {
            checkAndAssign(key, response.data[key]);
          }
        });

        // looping through packages array and objects nested inside
        if (Array.isArray(response.data.packages)) {
          response.data.packages.forEach((pkg) => {
            Object.keys(pkg).forEach((pkgKey) => {
              checkAndAssign(pkgKey, pkg[pkgKey]);
            });
          });
        }
      }

      //update contact if needed
      if (Object.keys(contactFieldsPayload).length > 0) {
        if (
          props.data["contact_id"] !== null &&
          props.data["contact_id"] !== ""
        ) {
          let response = await editContactApi({
            contactID: props.data["contact_id"],
            payload: contactFieldsPayload,
          });
          if (response.hasError()) {
            setUpdateLeadHasError(true);
          }
        }
      }

      const updateLeadDetails = async () => {
        setIsUpdatingLead(true);
        let payload = {
          ...normalFieldsPayload,
          custom_params: { ...customFieldsPayload },
        };
        try {
          let response = await editLeadApi({
            id: props.data.id,
            data: payload,
          });
          props.setRefreshLeadDetails(true);
          setIsSubmitSuccess(true);
        } catch (error) {
          setUpdateLeadHasError(true);
        }
      };

      updateLeadDetails();
    } else {
      setIsSubmitSuccess(false);
      setIsError(true);
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    setEditableFields({
      ...editableFields,
      [e.target.name]: value,
    });
  };

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          {!loading ? (
            !isSubmitSuccess ? (
              <Container component={"form"} onSubmit={handleSubmit}>
                <Box>
                  {!isUpdatingLead ? (
                    <>
                      <AppBar
                        component={"nav"}
                        elevation={0}
                        position="sticky"
                        color="inherit"
                        sx={{
                          top: 0,
                          bottom: "auto",
                          width: "100%",
                          right: 0,
                          borderRadius: "8px 8px 0px 0px",
                        }}
                      >
                        <Toolbar
                          style={{
                            padding: "16px",
                          }}
                        >
                          <HeaderWrapper>
                            <Header>{getLocalizedText("create_order")}</Header>

                            <SubHeader>
                              {getLocalizedText(
                                "below_are_the_fields_required_for_creating_the_order"
                              )}
                            </SubHeader>
                          </HeaderWrapper>
                        </Toolbar>
                      </AppBar>
                      <Divider style={{ margin: "16px", marginTop: "0px" }} />
                      <Box
                        style={{
                          maxHeight: "calc(100vh - 200px)",
                          height: "490px",
                          overflowY: "auto",
                          overflowX: "hidden",
                          padding: "0px 16px",
                        }}
                      >
                        <InputContainer>
                          <InputWrapper>
                            <Label>
                              Name <span style={{ color: "red" }}>*</span>
                            </Label>
                            <InputField
                              error={editableFields.name === ""}
                              type="text"
                              required
                              value={editableFields.name || ""}
                              name="name"
                              onChange={handleChange}
                              placeholder="Enter value"
                            />
                          </InputWrapper>

                          <InputWrapper>
                            <Label>
                              Address <span style={{ color: "red" }}>*</span>
                            </Label>
                            <InputField
                              error={editableFields.address === ""}
                              type="text"
                              required
                              value={editableFields.address || ""}
                              name="address"
                              onChange={handleChange}
                              placeholder="Enter value"
                            />
                          </InputWrapper>

                          <InputWrapper>
                            <Label>
                              Pin <span style={{ color: "red" }}>*</span>
                            </Label>
                            <InputField
                              error={editableFields.pin === ""}
                              type="number"
                              required
                              value={editableFields.pin || ""}
                              name="pin"
                              onChange={handleChange}
                              placeholder="Enter value"
                            />
                          </InputWrapper>

                          <InputWrapper>
                            <Label>
                              Phone <span style={{ color: "red" }}>*</span>
                            </Label>
                            <InputField
                              error={editableFields.phone === ""}
                              required
                              value={editableFields.phone || ""}
                              name="phone"
                              onChange={handleChange}
                              placeholder="Enter value"
                              sx={{
                                "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                                  {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                  },
                                "input[type=number]": {
                                  MozAppearance: "textfield",
                                },
                              }}
                            />
                          </InputWrapper>

                          <InputWrapper>
                            <Label>
                              Payment Mode{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Label>
                            <InputField
                              error={editableFields.payment_mode === ""}
                              type="text"
                              required
                              value={editableFields.payment_mode || ""}
                              name="payment_mode"
                              onChange={handleChange}
                              placeholder="Enter value"
                            />
                          </InputWrapper>

                          <InputWrapper>
                            <Label>
                              COD Amount <span style={{ color: "red" }}>*</span>
                            </Label>
                            <InputField
                              error={editableFields.cod_amount === ""}
                              type="number"
                              required
                              value={editableFields.cod_amount || ""}
                              name="cod_amount"
                              onChange={handleChange}
                              placeholder="Enter value"
                            />
                          </InputWrapper>

                          <InputWrapper>
                            <Label>
                              Order <span style={{ color: "red" }}>*</span>
                            </Label>
                            <InputField
                              error={editableFields.order === ""}
                              type="text"
                              required
                              value={editableFields.order || ""}
                              name="order"
                              onChange={handleChange}
                              placeholder="Enter value"
                            />
                          </InputWrapper>

                          <InputWrapper>
                            <Label>
                              EWBN <span style={{ color: "red" }}>*</span>
                            </Label>
                            <InputField
                              error={editableFields.ewbn === ""}
                              type="text"
                              required
                              value={editableFields.ewbn || ""}
                              name="ewbn"
                              onChange={handleChange}
                              placeholder="Enter value"
                            />
                          </InputWrapper>

                          <InputWrapper>
                            <Label>
                              Cosignee GST Tin{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Label>
                            <InputField
                              error={editableFields.cosignee_gst_tin === ""}
                              type="text"
                              required
                              value={editableFields.cosignee_gst_tin || ""}
                              name="cosignee_gst_tin"
                              onChange={handleChange}
                              placeholder="Enter value"
                            />
                          </InputWrapper>

                          <InputWrapper>
                            <Label>
                              HSN Code <span style={{ color: "red" }}>*</span>
                            </Label>
                            <InputField
                              error={editableFields.hsn_code === ""}
                              type="text"
                              required
                              value={editableFields.hsn_code || ""}
                              name="hsn_code"
                              onChange={handleChange}
                              placeholder="Enter value"
                            />
                          </InputWrapper>

                          <InputWrapper>
                            <Label>
                              GST Cess Amount{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Label>
                            <InputField
                              error={editableFields.gst_cess_amount === ""}
                              type="number"
                              required
                              value={editableFields.gst_cess_amount || ""}
                              name="gst_cess_amount"
                              onChange={handleChange}
                              placeholder="Enter value"
                            />
                          </InputWrapper>

                          <InputWrapper>
                            <Label>
                              Cosignee GST Amount{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Label>
                            <InputField
                              error={editableFields.cosignee_gst_amount === ""}
                              type="number"
                              required
                              value={editableFields.cosignee_gst_amount || ""}
                              name="cosignee_gst_amount"
                              onChange={handleChange}
                              placeholder="Enter value"
                            />
                          </InputWrapper>

                          <InputWrapper>
                            <Label>
                              Integrated GST Amount{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Label>
                            <InputField
                              error={
                                editableFields.integrated_gst_amount === ""
                              }
                              type="number"
                              required
                              value={editableFields.integrated_gst_amount || ""}
                              name="integrated_gst_amount"
                              onChange={handleChange}
                              placeholder="Enter value"
                            />
                          </InputWrapper>

                          <InputWrapper>
                            <Label>
                              Pickup Location{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Label>
                            <InputField
                              error={editableFields.pickup_location === ""}
                              type="text"
                              required
                              value={editableFields.pickup_location || ""}
                              name="pickup_location"
                              onChange={handleChange}
                              placeholder="Enter value"
                            />
                          </InputWrapper>
                        </InputContainer>
                      </Box>
                      <AppBar
                        position="static"
                        color="inherit"
                        elevation={0}
                        sx={{
                          top: "auto",
                          bottom: 0,
                          width: "100%",
                          borderRadius: "0px 0px 8px 8px",
                        }}
                      >
                        <Toolbar
                          style={{
                            padding: "16px",
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <ButtonWrapper>
                            <>
                              <Button
                                onClick={handleClose}
                                type="button"
                                variant="outlined"
                                color="default"
                                style={{
                                  textTransform: "none",
                                  marginRight: "20px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  width: "100px",
                                }}
                              >
                                {getLocalizedText("cancel")}
                              </Button>
                              <Button
                                type="submit"
                                variant="contained"
                                style={{
                                  backgroundColor: "#185DD2",
                                  color: "white",
                                  textTransform: "none",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  width: "fit-content",
                                }}
                              >
                                {getLocalizedText("submit")}
                              </Button>
                            </>
                          </ButtonWrapper>
                        </Toolbar>
                      </AppBar>
                    </>
                  ) : !updateLeadHasError ? (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px",
                      }}
                    >
                      <CircularProgress
                        style={{ width: "22px", height: "22px" }}
                      />
                      <Message style={{ color: "#4D4E4F", fontSize: "16px" }}>
                        Creating order...
                      </Message>
                    </Box>
                  ) : (
                    <Box
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "30px",
                      }}
                    >
                      <Message style={{ fontSize: "16px" }}>
                        {getLocalizedText("something_went_wrong")}
                      </Message>
                      <Button
                        onClick={handleClose}
                        type="button"
                        variant="outlined"
                        color="default"
                        style={{
                          textTransform: "none",
                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "100px",
                        }}
                      >
                        {getLocalizedText("close")}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Container>
            ) : (
              <Box sx={{ padding: "20px" }}>
                <MessageWrapper style={{ marginBottom: "0px" }}>
                  <SuccessMessage>
                    {isError
                      ? "Some error occured!"
                      : "Order created successfully!"}
                  </SuccessMessage>
                  <Button
                    onClick={handleClose}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100px",
                    }}
                  >
                    {getLocalizedText("close")}
                  </Button>
                </MessageWrapper>
              </Box>
            )
          ) : (
            <Box
              sx={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(DelhiveryCreateOrder);
