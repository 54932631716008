import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getLocalizedText } from "../../../Functions";
import { DragIndicator } from "@material-ui/icons";

const CardWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  padding: 12px 16px;
  background-color: white;
  align-items: center;
  position: relative;
  cursor: pointer;
`;
const Text = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
`;
const TextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
`;
const MenuText = styled(Typography)`
  font: normal normal 500 12px Open Sans;
`;
const SectionCard = ({
  sectionData,
  handleDeleteSection,
  isSelected,
  handleSectionClick,
  handleEditSection,
}) => {
  const [anchorE1, setAnchorE1] = React.useState(null);
  const Open = Boolean(anchorE1);
  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE1(null);
  };
  return (
    <CardWrapper
      style={{
        backgroundColor: isSelected ? "#e6edfa" : "#f9f9fc",
      }}
      onClick={handleSectionClick}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
          width: "calc(100% - 16px)",
        }}
      >
        <IconButton style={{ padding: "0px" }}>
          <DragIndicator style={{ width: "18px", height: "18px" }} />
        </IconButton>
        <TextWrapper>
          <Tooltip title={sectionData["name"]}>
            <Text
              sx={{
                color: isSelected ? " #1975d1" : "#4d4e4f",
              }}
            >
              {sectionData["name"]}
            </Text>
          </Tooltip>
        </TextWrapper>
      </Box>

      <IconButton
        style={{ padding: "0px" }}
        aria-label="more"
        id="long-button"
        aria-controls={Open ? "long-menu" : undefined}
        aria-expanded={Open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon style={{ width: "18px", height: "18px" }} />
      </IconButton>

      <Menu
        style={{ display: "flex", flexDirection: "column" }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorE1}
        open={Open}
        onClose={() => {
          handleClose();
        }}
        PaperProps={{
          style: {
            width: "fit-content",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleEditSection();
            handleClose();
          }}
        >
          <MenuText>{getLocalizedText("edit")}</MenuText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDeleteSection();
            handleClose();
          }}
        >
          <MenuText>{getLocalizedText("delete")}</MenuText>
        </MenuItem>
      </Menu>
    </CardWrapper>
  );
};

export default SectionCard;
