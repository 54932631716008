import {
  Button,
  Card,
  CardActionArea,
  Checkbox,
  CircularProgress,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import productPlaceholder from "../../assets/campaign_icons/product_placeholder.png";

import React, { useState } from "react";
const Label = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  text-align: center;
`;
const ImageBox = styled("img")`
  height: 40px;
  width: auto;
  object-fit: contain;
`;
const ProductCard = ({
  details,
  handleClick,
  isProductSelected,
  isProductDisabled,
  handleView,
}) => {
  const [loading, setLoading] = useState(true);
  return (
    <Card
      sx={{
        border: "1px solid #f9f9fc",
        bgcolor: "#f9f9fc",
      }}
      onClick={() => {
        if (!isProductDisabled()) {
          handleClick();
        }
      }}
    >
      <CardActionArea
        disableRipple
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "8px",
          rowGap: "4px",
          position: "relative",
          height: "100px",
        }}
      >
        {isProductDisabled() === false && (
          <Checkbox
            checked={isProductSelected()}
            size="small"
            color={"primary"}
            style={{ position: "absolute", top: "-10px", left: "-10px" }}
          />
        )}
        <ImageBox
          src={
            details["product_image"] !== null &&
            details["product_image"].hasOwnProperty("url")
              ? details["product_image"]["url"]
              : productPlaceholder
          }
          onLoad={() => {
            setLoading(false);
          }}
          alt="loading..."
        />
        {loading && (
          <CircularProgress
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: "auto",
              width: "20px",
              height: "20px",
            }}
          />
        )}

        <Label style={{ color: isProductDisabled() ? "lightgray" : "#4d4e4f" }}>
          {details["name"]}
        </Label>
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleView();
          }}
          variant="text"
          color="primary"
          style={{
            fontWeight: "bold",
            textTransform: "none",
            padding: "0px",
            fontSize: "12px",
            textDecoration: "underline",
          }}
        >
          View
        </Button>
      </CardActionArea>
    </Card>
  );
};

export default ProductCard;
