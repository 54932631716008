import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  Grid,
  makeStyles,
  Modal,
  Select,
} from "@material-ui/core";
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Typography,
  MenuItem,
  Divider,
  AppBar,
  Toolbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { getLocalizedText } from "../../../Functions";
import { CurrencyRupeeRounded, Percent } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import SearchableSelectProduct from "../../searchable_select/SearchableSelectProduct";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 420px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin: 0px;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin: 0px;
  margin-top: 2px;
`;
const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const AddProductLineItemModal = ({
  open,
  isEdit,
  productList,
  editableProduct,
  postAddCallback,
  postEditCallback,
  handleClose,
  isProductAlreadyAdded,
  taxOptions,
  defaultProducts,
  onSearchResetCallback,
}) => {
  const classes = useStyles();
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedProductData, setSelectedProductData] = useState({
    rate: "",
    quantity: "",
    discount: "",
    tax: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [maxDiscount, setMaxDiscount] = useState(100);
  const handleChange = (e) => {
    setErrorMessage("");
    setShowAlert(false);
    setSelectedProductData({
      ...selectedProductData,
      [e.target.name]: e.target.value,
    });
  };

  const getSelectedPruductDataByID = (id) => {
    let index = productList.findIndex((product) => product["id"] === id);
    if (index !== -1) {
      return productList[index];
    }
    return {};
  };

  const handleEditProduct = () => {
    let newObj = { ...editableProduct };
    let rate =
      selectedProductData["rate"] !== ""
        ? parseFloat(selectedProductData["rate"])
        : 0;
    let quantity = parseInt(selectedProductData["quantity"]);
    let discount =
      selectedProductData["discount"] !== ""
        ? parseFloat(selectedProductData["discount"])
        : 0;
    let tax =
      selectedProductData["tax"] !== ""
        ? parseFloat(selectedProductData["tax"])
        : 0;
    let amount = rate * quantity;

    newObj["rate"] = rate;
    newObj["quantity"] = quantity;
    newObj["discount"] = discount;
    newObj["tax"] = tax;
    newObj["amount"] = parseFloat(amount);

    let discountedAmount = (newObj["amount"] * discount) / 100;
    newObj["amount"] = newObj["amount"] - discountedAmount;
    let taxedAmount = tax > 0 ? (newObj["amount"] * tax) / 100 : 0;
    newObj["amount"] = (newObj["amount"] + taxedAmount).toFixed(2);
    postEditCallback(newObj);
  };

  const handleAddProduct = () => {
    let newObj = {};
    let rate =
      selectedProductData["rate"] !== ""
        ? parseFloat(selectedProductData["rate"])
        : 0;
    let quantity = parseInt(selectedProductData["quantity"]);
    let discount =
      selectedProductData["discount"] !== ""
        ? parseFloat(selectedProductData["discount"])
        : 0;
    let tax =
      selectedProductData["tax"] !== ""
        ? parseFloat(selectedProductData["tax"])
        : 0;

    let amount = rate * quantity;

    newObj["rate"] = rate;
    newObj["quantity"] = quantity;
    newObj["discount"] = discount;
    newObj["tax"] = tax;
    newObj["type"] = "product";
    
    newObj["amount"] = parseFloat(amount);

    let discountedAmount = (newObj["amount"] * discount) / 100;
    newObj["amount"] = newObj["amount"] - discountedAmount;
    let taxedAmount = tax > 0 ? (newObj["amount"] * tax) / 100 : 0;
    newObj["amount"] = (newObj["amount"] + taxedAmount).toFixed(2);

    let productData = getSelectedPruductDataByID(selectedProduct);
    newObj["item"] = {
      id: uuidv4(),
      name: productData["name"],
    };
    newObj["product_data"] = {
      id: productData["id"],
      name: productData["name"],
      unit_price: productData["unit_price"],
      unit_cost: productData["unit_cost"],
      tax: productData["tax"],
      max_discount: productData["max_discount"],
      product_code: productData["product_code"],
    };
    postAddCallback(newObj);
  };
  const prefillProductData = ({ productData }) => {
    let rate =
      productData.hasOwnProperty("unit_price") &&
      productData["unit_price"] !== null
        ? productData["unit_price"]
        : "";
    let tax =
      productData.hasOwnProperty("tax") && productData["tax"] !== null
        ? productData["tax"]
        : "";
    let maxDiscount =
      productData.hasOwnProperty("max_discount") &&
      productData["max_discount"] !== null &&
      productData["max_discount"] !== ""
        ? parseFloat(productData["max_discount"])
        : "";

    setMaxDiscount(maxDiscount);
    setSelectedProductData({
      rate: rate,
      quantity: "",
      discount: "",
      tax: tax,
    });
  };
  const resetForm = () => {
    setSelectedProduct("");
    setMaxDiscount("");
    setSelectedProductData({
      rate: "",
      quantity: "",
      discount: "",
      tax: "",
    });
    setErrorMessage("");
    setShowAlert(false);
  };
  const setupEditableData = () => {
    let productID =
      editableProduct.hasOwnProperty("product_data") &&
      editableProduct["product_data"] !== null
        ? editableProduct["product_data"]["id"]
        : "";
    setSelectedProduct(productID);

    let tempSelectedProductData = {
      rate: editableProduct.hasOwnProperty("rate")
        ? editableProduct["rate"]
        : "",
      quantity: editableProduct.hasOwnProperty("quantity")
        ? editableProduct["quantity"]
        : "",
      discount:
        editableProduct.hasOwnProperty("discount") &&
        editableProduct["discount"] !== null &&
        editableProduct["discount"] !== ""
          ? parseFloat(editableProduct["discount"])
          : "",
      tax:
        editableProduct.hasOwnProperty("tax") &&
        editableProduct["tax"] !== null &&
        editableProduct["tax"] !== ""
          ? parseFloat(editableProduct["tax"])
          : "",
    };
    setSelectedProductData(tempSelectedProductData);
  };
  useEffect(() => {
    if (isEdit) {
      setupEditableData();
    }
  }, []);

  const getMaxDiscount = () => {
    if (isEdit) {
      let productData =
        editableProduct.hasOwnProperty("product_data") &&
        editableProduct["product_data"] !== null
          ? editableProduct["product_data"]
          : {};
      if (
        productData.hasOwnProperty("max_discount") &&
        productData["max_discount"] !== null &&
        productData["max_discount"] !== ""
      ) {
        return parseFloat(productData["max_discount"]);
      }
      return 100;
    }
    return maxDiscount;
  };

  const canShowTaxOptions = () => {
    let productData = {};
    if (isEdit) {
      productData =
        editableProduct.hasOwnProperty("product_data") &&
        editableProduct["product_data"] !== null
          ? editableProduct["product_data"]
          : {};
    } else {
      productData = getSelectedPruductDataByID(selectedProduct);
    }
    if (
      productData.hasOwnProperty("tax") &&
      productData["tax"] !== null &&
      productData["tax"] !== ""
    ) {
      return false;
    }
    return true;
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Container
            component={"form"}
            onSubmit={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (selectedProduct === "" || selectedProduct === null) {
                setErrorMessage(getLocalizedText("please_select_a_product"));
                setShowAlert(true);
                return;
              }
              if (isEdit) {
                handleEditProduct();
              } else {
                handleAddProduct();
              }
            }}
          >
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  {isEdit ? (
                    <Header>
                      {getLocalizedText("edit_product", "project")}
                    </Header>
                  ) : (
                    <Header>
                      {getLocalizedText("add_product", "project")}
                    </Header>
                  )}
                  <SubHeader>
                    {getLocalizedText("fill_in_the_below_details")}
                  </SubHeader>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>

            <Divider style={{ margin: "16px", marginTop: "0px" }} />
            <Box
              style={{
                height: "100%",
                height: "100%",
                padding: "0px 16px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{`${getLocalizedText("select_product")}*`}</Label>
                    <SearchableSelectProduct
                      scope={"quote"}
                      isEdit={isEdit}
                      product={editableProduct.product_data}
                      isProductAlreadyAdded={isProductAlreadyAdded}
                      defaultProducts={defaultProducts}
                      onSearchResetCallback={onSearchResetCallback}
                      onChangeCallback={(value) => {
                        if (value === "") {
                          resetForm();
                          return;
                        }
                        let index = productList.findIndex(
                          (entry) => entry["id"] === value
                        );

                        if (index !== -1) {
                          setSelectedProduct(value);
                          prefillProductData({
                            productData: productList[index],
                          });
                          setErrorMessage("");
                          setShowAlert(false);
                        } else {
                          setSelectedProduct("");
                        }
                      }}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{`${getLocalizedText("quantity")}*`}</Label>

                    <OutlinedInput
                      required
                      type="number"
                      placeholder="Quantity"
                      onChange={handleChange}
                      name="quantity"
                      value={selectedProductData["quantity"]}
                      style={{ height: "30px", overflow: "hidden" }}
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        min: 1,
                        "aria-label": "weight",
                        style: {
                          padding: "0px 12px",
                          fontSize: "12px",
                        },
                      }}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{`${getLocalizedText("rate")}*`}</Label>
                    <OutlinedInput
                      type="number"
                      placeholder="Rate"
                      name="rate"
                      onChange={handleChange}
                      value={selectedProductData["rate"]}
                      style={{ height: "30px", overflow: "hidden" }}
                      id="outlined-adornment-weight"
                      startAdornment={
                        <InputAdornment position="start">
                          <CurrencyRupeeRounded
                            style={{ width: "18px", height: "18px" }}
                          />
                        </InputAdornment>
                      }
                      required
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        min: 0,
                        step: "0.01",
                        "aria-label": "weight",
                        style: {
                          padding: "0px",
                          fontSize: "12px",
                        },
                      }}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("tax")}*</Label>
                    {canShowTaxOptions() ? (
                      <FormControl
                        sx={{ width: "100%" }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          displayEmpty
                          required
                          placeholder="Tax"
                          name="tax"
                          onChange={handleChange}
                          value={selectedProductData["tax"]}
                          disableUnderline
                          variant="outlined"
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",

                            borderRadius: "0px",
                          }}
                        >
                          {taxOptions.map((item, i) => {
                            return (
                              <MenuItem
                                value={item.value}
                                key={i}
                                style={{ fontSize: "12px" }}
                              >
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    ) : (
                      <OutlinedInput
                        type="number"
                        placeholder="Tax"
                        name="tax"
                        value={selectedProductData["tax"]}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        startAdornment={
                          <InputAdornment position="start">
                            <Percent
                              style={{
                                color: "#a9a9a9",
                                width: "18px",
                                height: "18px",
                              }}
                            />
                          </InputAdornment>
                        }
                        readOnly
                        disabled
                        inputProps={{
                          step: "0.01",
                          "aria-label": "weight",
                          style: {
                            padding: "0px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    )}
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("discount")}</Label>
                    <OutlinedInput
                      type="number"
                      placeholder="Discount"
                      name="discount"
                      onChange={handleChange}
                      value={selectedProductData["discount"]}
                      style={{ height: "30px" }}
                      id="outlined-adornment-weight"
                      startAdornment={
                        <InputAdornment position="start">
                          <Percent style={{ width: "18px", height: "18px" }} />
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        min: 0,
                        max: getMaxDiscount(),
                        step: "0.01",
                        "aria-label": "weight",
                        style: {
                          padding: "0px",
                          fontSize: "12px",
                        },
                      }}
                    />
                  </InputWrapper>
                </Grid>
              </Grid>
            </Box>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{
                top: "auto",
                bottom: 0,
                width: "100%",
                borderRadius: "0px 0px 8px 8px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: "100%",
                  position: "relative",
                }}
              >
                {showAlert && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setShowAlert(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "flex-end",
                        gap: "16px",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        onClick={handleClose}
                        type="button"
                        variant="contained"
                        color="inherit"
                        style={{
                          textTransform: "none",

                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "100%",
                        }}
                      >
                        {getLocalizedText("cancel")}
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{
                          textTransform: "none",

                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "100%",
                        }}
                      >
                        {isEdit
                          ? getLocalizedText("edit")
                          : getLocalizedText("add")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Container>
          {/* </Box> */}
        </ModelContainer>
      </Modal>
    </>
  );
};

export default AddProductLineItemModal;
