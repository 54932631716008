import React, { useEffect, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { styled } from "@mui/material/styles";
import { Tab, Tabs, tabsClasses, Typography } from "@mui/material";

import { getUsersAttendanceListApi } from "../../Api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AttendanceSummaryCardWidget from "../../components/attendance_details_page_components/AttendanceSummaryCardWidget";
import AttendanceSummaryIconCardWidget from "../../components/attendance_details_page_components/AttendanceSummaryIconCardWidget";
import moment from "moment";
import {
  dataRenderer,
  getLocalTimeStringFromIsoDateString,
} from "../../Functions";
import DatePickerDialog from "../../components/attendance_history_page_components/DatePickerDialog";
import CheckinTableWidget from "../../components/attendance_details_page_components/CheckinTableWidget";
import DateNavigationComponent from "../../components/attendance_details_page_components/DateNavigationComponent";
import NoAccessPermissionPage from "../no_access_page/NoAccessPermissionPage";
import PreviewFixedImageDialog from "../../components/files_page_components/PreviewFixedImageDialog";
import CallsTableWidget from "../../components/attendance_details_page_components/CallsTableWidget";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const TextLabel = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: gray;
  width: 50px;
`;
const TextDetail = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SectionHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  variant: "span",
  color: "#4d4e4f",
  margin: "16px 0px",
}));
const TableWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 20px;
  position: relative;
`;
const AttendanceDetailsPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const receivedProps = location.state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [attendanceSummaryData, setAttendanceSummaryData] = useState({});
  const [date, setDate] = useState(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [viewImageDialog, setViewImageDialog] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [tabIdx, setTabIdx] = useState(0);

  const getAttendanceDate = () => {
    if (receivedProps !== null && receivedProps.hasOwnProperty("date")) {
      return moment(receivedProps["date"]);
    }
    return null;
  };

  const getAttendanceDataFromRoute = () => {
    if (receivedProps !== null && receivedProps.hasOwnProperty("data")) {
      if (receivedProps["data"] !== null) {
        return receivedProps["data"];
      }
    }
    return {};
  };

  const init = async () => {
    if (
      rootStore.subscriptionState.isFeatureAvailableWithAddOns("Attendance")
    ) {
      let tempDate = getAttendanceDate();
      setDate(tempDate);
      let tempData = getAttendanceDataFromRoute();
      let attendanceData =
        tempData.hasOwnProperty("attendance_data") &&
        tempData["attendance_data"] !== null
          ? tempData["attendance_data"]
          : {};
      setAttendanceSummaryData(attendanceData);
    }
    setLoading(false);
  };

  const evaluateAttendanceSummary = ({ list }) => {
    if (list.length === 0) {
      return {
        attendance_in_time: null,
        attendance_out_time: null,
        total_in_time: 0,
        face_image_in: null,
        face_image_out: null,
        attendance_in_latlong: null,
        attendance_out_latlong: null,
      };
    }

    let earliestInTime = null;
    let latestOutTime = null;
    let totalInTime = 0;
    let faceImageIn = null;
    let faceImageOut = null;
    let inLatLong = null;
    let outLatLong = null;

    list.forEach((entry) => {
      if (entry.attendance_in_time) {
        const inTime = moment(entry.attendance_in_time);
        if (!earliestInTime || inTime.isBefore(earliestInTime)) {
          earliestInTime = inTime;
          faceImageIn = entry.face_image_in;
          inLatLong = entry.attendance_in_latlong;
        }
      }

      if (entry.attendance_out_time) {
        const outTime = moment(entry.attendance_out_time);
        if (!latestOutTime || outTime.isAfter(latestOutTime)) {
          latestOutTime = outTime;
          faceImageOut = entry.face_image_out;
          outLatLong = entry.attendance_out_latlong;
        }
      }

      totalInTime += entry.total_in_time || 0;
    });

    return {
      attendance_in_time: earliestInTime ? earliestInTime.toISOString() : null,
      attendance_out_time: latestOutTime ? latestOutTime.toISOString() : null,
      total_in_time: totalInTime,
      face_image_in: faceImageIn,
      face_image_out: faceImageOut,
      attendance_in_latlong: inLatLong,
      attendance_out_latlong: outLatLong,
    };
  };

  const getUserAttendanceData = async ({ date }) => {
    let payload = { attendance_date: date };
    let response = await getUsersAttendanceListApi({ payload: payload });
    let filteredData = response.filter((obj) => obj["user"]["id"] === id);
    if (filteredData.length > 0) {
      let tempEvaluatedData = evaluateAttendanceSummary({
        list: filteredData,
      });
      let tempObj = { ...filteredData[0], ...tempEvaluatedData };
      setAttendanceSummaryData(tempObj);
    } else {
      setAttendanceSummaryData({});
    }
  };

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);
  const handleNext = async () => {
    setLoading(true);
    let tempDate = date.clone().add(1, "day");
    setDate(tempDate);
    await getUserAttendanceData({ date: tempDate });
    setLoading(false);
  };

  const handlePrev = async () => {
    setLoading(true);
    let tempDate = date.clone().subtract(1, "day");
    setDate(tempDate);
    await getUserAttendanceData({ date: tempDate });
    setLoading(false);
  };

  const handleSet = async (date) => {
    setLoading(true);
    setDate(date);
    await getUserAttendanceData({ date: date });
    setLoading(false);
  };

  const handleLocation = (value) => {
    if (value !== null) {
      let a = value;
      let array = a.split(",");
      let b = array[0];
      let c = array[1];
      window.open(`https://www.google.com/maps/?q=${b},${c}`, "_blank");
    }
  };

  const postClickCallback = (inOutType, iconType) => {
    if (iconType === "photo") {
      setSelectedFileUrl(
        inOutType === "in"
          ? attendanceSummaryData.face_image_in
          : attendanceSummaryData.face_image_out
      );
      setViewImageDialog(true);
    } else if (iconType === "map") {
      let locationValue =
        inOutType === "in"
          ? attendanceSummaryData.attendance_in_latlong
          : attendanceSummaryData.attendance_out_latlong;
      handleLocation(locationValue);
    }
  };

  const RenderUserInfo = () => {
    let data = getAttendanceDataFromRoute();
    return (
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <TextLabel>Name</TextLabel>:
          <TextDetail>{dataRenderer(data["name"])}</TextDetail>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <TextLabel>Phone</TextLabel>:
          <TextDetail>{dataRenderer(data["phone"])}</TextDetail>
        </Box>
      </Box>
    );
  };

  const getTotalInTimeInHrs = () => {
    const totalInTimeInMinutes = attendanceSummaryData?.total_in_time ?? null;

    if (!totalInTimeInMinutes) {
      return null;
    }

    const hours = Math.floor(totalInTimeInMinutes / 60);
    const minutes = totalInTimeInMinutes % 60;

    return minutes === 0
      ? `${hours} hours`
      : `${hours} hours and ${minutes} minutes`;
  };

  return rootStore.subscriptionState.isFeatureAvailableWithAddOns(
    "Attendance"
  ) ? (
    <>
      <ParentContainer>
        {!loading ? (
          <Container>
            <Row>
              <TopWrapper>
                <Header>Admin</Header>
                <ChevronRight style={{ width: "16px", height: "16px" }} />
                <Header
                  style={{ color: "#185DD2", cursor: "pointer" }}
                  onClick={() => {
                    let tempDate = getAttendanceDate();
                    navigate(`/admin/attendance-history/`, {
                      state: {
                        date: tempDate !== null ? tempDate.toISOString() : "",
                        scope: "attendance_details_page",
                      },
                    });
                  }}
                >
                  Attendance
                </Header>
                <ChevronRight style={{ width: "16px", height: "16px" }} />
                <Header>{id}</Header>
              </TopWrapper>
            </Row>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                bgcolor: "white",
                padding: "16px",
                marginBottom: "16px",
              }}
            >
              <RenderUserInfo />
              <DateNavigationComponent
                date={date}
                handleNextButton={handleNext}
                handlePrevButton={handlePrev}
                handleDatePicker={() => {
                  setOpenDatePicker(true);
                }}
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <AttendanceSummaryIconCardWidget
                  data={{
                    title: "Attendance In Time",
                    value:
                      attendanceSummaryData.hasOwnProperty(
                        "attendance_in_time"
                      ) && attendanceSummaryData["attendance_in_time"] !== null
                        ? getLocalTimeStringFromIsoDateString(
                            attendanceSummaryData["attendance_in_time"]
                          )
                        : null,
                  }}
                  imageDisabled={
                    !attendanceSummaryData.hasOwnProperty("face_image_in") ||
                    attendanceSummaryData.face_image_in === null
                  }
                  locationDisabled={
                    !attendanceSummaryData.hasOwnProperty(
                      "attendance_in_latlong"
                    ) || attendanceSummaryData.attendance_in_latlong === null
                  }
                  inOutType={"in"}
                  postClickCallback={postClickCallback}
                />
              </Grid>
              <Grid item xs={4}>
                <AttendanceSummaryIconCardWidget
                  data={{
                    title: "Attendance Out Time",
                    value:
                      attendanceSummaryData.hasOwnProperty(
                        "attendance_out_time"
                      ) && attendanceSummaryData["attendance_out_time"] !== null
                        ? getLocalTimeStringFromIsoDateString(
                            attendanceSummaryData["attendance_out_time"]
                          )
                        : null,
                  }}
                  imageDisabled={
                    !attendanceSummaryData.hasOwnProperty("face_image_out") ||
                    attendanceSummaryData.face_image_out === null
                  }
                  locationDisabled={
                    !attendanceSummaryData.hasOwnProperty(
                      "attendance_out_latlong"
                    ) || attendanceSummaryData.attendance_out_latlong === null
                  }
                  inOutType={"out"}
                  postClickCallback={postClickCallback}
                />
              </Grid>
              <Grid item xs={4}>
                <AttendanceSummaryCardWidget
                  data={{
                    title: "Total in time(hrs)",
                    value: getTotalInTimeInHrs(),
                  }}
                />
              </Grid>
            </Grid>
            {/* <SectionHeader>CheckIns for the day</SectionHeader> */}

            <Tabs
              sx={{
                marginTop: "16px",
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
                width: "100%",
              }}
              variant="scrollable"
              value={tabIdx}
              textColor="primary"
              indicatorColor="primary"
              scrollButtons={"auto"}
              onChange={(event, newValue) => {
                setTabIdx(newValue);
              }}
            >
              <Tab
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#E7EEFA",
                    color: "#4079DA",
                    font: "normal normal 600 14px Open Sans",
                    textTransform: "none",
                  },

                  backgroundColor: "white",
                  color: "#4D4E4F",
                  font: "normal normal 600 14px Open Sans",
                  textTransform: "none",
                }}
                label={"Checkins for the day"}
              />
              <Tab
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#E7EEFA",
                    color: "#4079DA",
                    font: "normal normal 600 14px Open Sans",
                    textTransform: "none",
                  },

                  backgroundColor: "white",
                  color: "#4D4E4F",
                  font: "normal normal 600 14px Open Sans",
                  textTransform: "none",
                }}
                label={"Calls for the day"}
              />
            </Tabs>

            {(() => {
              if (tabIdx === 0) {
                return (
                  <TableWrapper>
                    <CheckinTableWidget id={id} checkinDate={date} />
                  </TableWrapper>
                );
              } else if (tabIdx === 1) {
                return (
                  <TableWrapper>
                    <CallsTableWidget id={id} callsDate={date} />
                  </TableWrapper>
                );
              }
            })()}
          </Container>
        ) : (
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Container>
        )}
      </ParentContainer>
      <Footer />
      {openDatePicker && (
        <DatePickerDialog
          editableDate={date}
          handleSet={(dateValue) => {
            handleSet(dateValue);
            setOpenDatePicker(false);
          }}
          open={openDatePicker}
          setOpen={setOpenDatePicker}
        />
      )}
      {viewImageDialog && (
        <PreviewFixedImageDialog
          open={viewImageDialog}
          setOpen={setViewImageDialog}
          file={selectedFileUrl !== null ? selectedFileUrl : ""}
          isCreate={false}
        />
      )}
    </>
  ) : (
    <NoAccessPermissionPage />
  );
};

export default observer(AttendanceDetailsPage);
