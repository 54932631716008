import { Grid } from "@material-ui/core";
import {
  Box,
  styled,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React, { useCallback } from "react";
import MutliValueAddField from "../../custom_field_components/MutliValueAddField";
import AddFileInputComponent from "../../add_product_sidepanel/components/AddFileInputComponent";
import InputFileViewComponent from "../../add_product_sidepanel/components/InputFileViewComponent";
import MultiMediaUploadComponent from "../../add_product_sidepanel/components/MultiMediaUploadComponent";
import { getLocalizedText } from "../../../Functions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #979797;
  margin-bottom: 16px;
`;
const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border-radius: 4px;
  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled("textarea")`
  width: 100%;
  padding: 4px 12px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;
  height: 80px;
  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const BasicDetailsScreen = ({
  handleChange,
  fieldValues,
  mediaDetails,
  handleProductMediaUpload,
  handleRemoveProductMedia,
  handleViewProductMedia,
  selectedDocuments,
  selectedImages,
  handleDocumentUpload,
  handleImageUpload,
  handleDocumentPreview,
  handleImagePreview,
  handleRemoveDoc,
  handleRemoveImage,
  getFileUrl,
}) => {
  const RenderLabel = ({ item }) => {
    const isEditable = item["editable"];
    return (
      <Label style={{ color: isEditable ? "#4d4e4f" : "lightgray" }}>
        {item.display_name}
        {item.required && (
          <span style={{ color: isEditable ? "red" : "lightgray" }}>*</span>
        )}
      </Label>
    );
  };

  const RenderSelectedDocuments = useCallback(() => {
    return Object.entries(selectedDocuments).map(([key, doc]) =>
      doc ? (
        <InputFileViewComponent
          key={key}
          fileURL={getFileUrl(doc)}
          handleRemove={() => handleRemoveDoc(key)}
          handleView={() => handleDocumentPreview(doc)}
          scope={"pdf"}
        />
      ) : null
    );
  }, [selectedDocuments]);
  const RenderSelectedImages = useCallback(() => {
    return Object.entries(selectedImages).map(([key, image]) =>
      image ? (
        <InputFileViewComponent
          key={key}
          fileURL={getFileUrl(image)}
          handleRemove={() => handleRemoveImage(key)}
          handleView={() => handleImagePreview(image)}
          scope={"image"}
        />
      ) : null
    );
  }, [selectedImages]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MultiMediaUploadComponent
          handleMediaUpload={(file, type) =>
            handleProductMediaUpload(file, type)
          }
          mediaDetails={mediaDetails}
          handleRemove={(type) => handleRemoveProductMedia(type)}
          handleView={(type) => handleViewProductMedia(type)}
          getFileUrl={(file) => getFileUrl(file)}
        />
      </Grid>
      <Grid item xs={12}>
        <InputWrapper>
          <Label>{getLocalizedText("name")}*</Label>
          <Input
            value={fieldValues.name}
            required
            type="text"
            placeholder="Enter Name"
            name="name"
            onChange={(event) => {
              handleChange({
                key: "name",
                value: event.target.value,
              });
            }}
          />
        </InputWrapper>
      </Grid>
      <Grid item xs={12}>
        <InputWrapper>
          <Label>{getLocalizedText("description")}</Label>
          <TextArea
            value={fieldValues.desc}
            type="text"
            placeholder="Enter Description"
            name="desc"
            onChange={(event) => {
              handleChange({
                key: "desc",
                value: event.target.value,
              });
            }}
          />
        </InputWrapper>
      </Grid>
      <Grid item xs={12}>
        <InputWrapper>
          <Label>{getLocalizedText("tags")}</Label>
          <MutliValueAddField
            handleChange={(list) => {
              handleChange({
                key: "tags",
                value: list,
              });
            }}
            value={
              fieldValues.hasOwnProperty("tags") &&
              fieldValues["tags"] !== null &&
              fieldValues["tags"] !== ""
                ? fieldValues["tags"]
                : []
            }
          />
        </InputWrapper>
      </Grid>
      <Grid item xs={12}>
        <InputWrapper>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <legend
                style={{
                  width: "auto",
                  marginBottom: "0px",
                  fontSize: "12px",
                }}
              >
                <SectionHeader sx={{ marginBottom: "0px" }}>
                  Images
                </SectionHeader>
              </legend>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                  flexWrap: "wrap",
                }}
              >
                <RenderSelectedImages />
                {Object.values(selectedImages)
                  .filter((value) => value === null)
                  .map((_, idx) => (
                    <AddFileInputComponent
                      key={idx}
                      acceptedFiles={"image/jpeg,image/png"}
                      handleChange={(event) => handleImageUpload(event)}
                      scope={"image"}
                    />
                  ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </InputWrapper>
      </Grid>
      <Grid item xs={12}>
        <InputWrapper>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <legend
                style={{
                  width: "auto",
                  marginBottom: "0px",
                  fontSize: "12px",
                }}
              >
                <SectionHeader sx={{ marginBottom: "0px" }}>
                  Documents
                </SectionHeader>
              </legend>
            </AccordionSummary>{" "}
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                  flexWrap: "wrap",
                }}
              >
                <RenderSelectedDocuments />
                {Object.values(selectedDocuments)
                  .filter((value) => value === null)
                  .map((_, idx) => (
                    <AddFileInputComponent
                      acceptedFiles={"application/pdf,text/plain"}
                      handleChange={(event) => handleDocumentUpload(event)}
                      scope={"pdf"}
                    />
                  ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </InputWrapper>
      </Grid>
    </Grid>
  );
};

export default BasicDetailsScreen;
