import { Grid } from "@material-ui/core";
import { Box, styled, Typography } from "@mui/material";
import React from "react";
import SearchableSelectFormField from "../../searchable_select/SearchableSelectFormField";
import { getAccountLocalizedText, getLocalizedText } from "../../../Functions";
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;
const BuilderScreen = ({ details, handleChange, productManufacturerList }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputWrapper>
          <Label>{getAccountLocalizedText("select_manufacturer")}</Label>
          <SearchableSelectFormField
            type={"product_manufacturer"}
            selectedItem={details}
            defaultOptions={productManufacturerList}
            onChangeCallback={(value) => {
              handleChange(value);
            }}
            menuPlacement={"top"}
          />
        </InputWrapper>
      </Grid>
    </Grid>
  );
};

export default BuilderScreen;
