import React from "react";
import { styled } from "@mui/system";
import get_in_touch from "../../../../assets/get_in_touch.jpg";
import { Box } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
    justifyContent: "center",
  },
  width: "50%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: theme.spacing(2),
}));

const SvgImage = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  height: "auto",
}));
const LeftSection = () => {
  return (
    <Wrapper>
      <SvgImage src={get_in_touch} alt="loading..." />
    </Wrapper>
  );
};

export default LeftSection;
