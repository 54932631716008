import React from "react";
import AsyncSelect from "react-select/async";
import { getProductsListApi } from "../../Api";
import { getAccountLocalizedText, getLocalizedText } from "../../Functions";

const SearchableSelectProduct = (props) => {
  const filter = async (inputValue) => {
    const queryPayload = {
      name: inputValue,
      active: true,
    };

    let response = await getProductsListApi({
      page: 0,
      page_size: 10,
      payload: { query: queryPayload },
    });
    props.onSearchResetCallback(response.items);

    //filter out already used products
    const filteredList = response.items.filter((item) => {
      return !props.isProductAlreadyAdded(item.id);
    });

    //return list for select dropdown
    return filteredList.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  };

  const loadOptions = (inputValue) => {
    if (inputValue.length > 3) {
      return filter(inputValue);
    } else {
      return Promise.resolve([]);
    }
  };

  const getDefaultOptions = () => {
    return props.defaultProducts.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  };

  const handleChange = (value) => {
    props.onChangeCallback(value !== null ? value.value : "");
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for options
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for the entire control container, including the search input
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for "no options" message
    }),
    loadingMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for "loading" message
    }),
  };

  const selectedProduct =
    props.isEdit && props.product !== null
      ? {
          value: props.product.id,
          label: props.product.name,
        }
      : null;
  return (
    <>
      <AsyncSelect
        isDisabled={props.isEdit}
        value={props.isEdit ? selectedProduct : undefined}
        styles={{
          ...customStyles,
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        defaultOptions={getDefaultOptions()}
        cacheOptions={true}
        required={true}
        menuPortalTarget={document.body}
        isClearable={!props.isEdit}
        onChange={handleChange}
        loadOptions={loadOptions}
        placeholder={props.scope === "deals" ? getAccountLocalizedText("search_product") : getLocalizedText("search_product")}
      />
    </>
  );
};

export default SearchableSelectProduct;
