import { Grid } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import {
  Box,
  Button,
  Chip,
  OutlinedInput,
  styled,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getLocalizedText } from "../../../Functions";

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #979797;
`;
const Detail = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const SpecificationsScreen = ({
  specificationList,
  handleSpecificationDetails,
  handleSpecificationsDelete,
  additionalDetailsList,
  handleAdditionalDetails,
  handleAdditionalDetailsDelete,
}) => {
  const [specificationsDetails, setSpecificationsDetails] = useState({ label: "", value: "" });
  const handleSpecificationsChange = (e) => {
    setSpecificationsDetails({ ...specificationsDetails, [e.target.name]: e.target.value });
  };

  const [additionalDetails, setAdditionalDetails] = useState({ label: "", value: "" });
  const handleAdditionalDetailsChange = (e) => {
    setAdditionalDetails({ ...additionalDetails, [e.target.name]: e.target.value });
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <legend
              style={{
                width: "auto",
                marginBottom: "0px",
                fontSize: "12px",
              }}
            >
              <SectionHeader>Specifications</SectionHeader>
            </legend>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item xs={5}>
                    <InputWrapper>
                      <Label>Specification Name</Label>
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        value={specificationsDetails.label}
                        type="text"
                        placeholder=""
                        name="label"
                        onChange={handleSpecificationsChange}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={5}>
                    <InputWrapper>
                      <Label>Specification Value</Label>

                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        value={specificationsDetails.value}
                        type="text"
                        placeholder=""
                        name="value"
                        onChange={handleSpecificationsChange}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      style={{
                        textTransform: "none",
                        fontWeight: "bold",
                        width: "100%",
                        fontSize: "12px",
                      }}
                      disabled={
                        specificationsDetails["label"] === "" || specificationsDetails["value"] === ""
                      }
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        handleSpecificationDetails(specificationsDetails);
                        setSpecificationsDetails({ label: "", value: "" });
                      }}
                    >
                      {" "}
                      ADD
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ width: "100%" }}>
                  {specificationList.length > 0 &&
                    specificationList.map((entry, idx) => {
                      return (
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={5}>
                            <Detail>{entry["label"]}</Detail>
                          </Grid>
                          <Grid item xs={5}>
                            <Detail>{entry["value"]}</Detail>
                          </Grid>
                          <Grid xs={2} item>
                            <Button
                              style={{
                                textTransform: "none",
                                fontWeight: "bold",
                                width: "100%",
                                fontSize: "12px",
                              }}
                              color="error"
                              variant="outlined"
                              onClick={() => {
                                handleSpecificationsDelete(entry["label"]);
                              }}
                            >
                              DELETE
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Box>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <legend
              style={{
                width: "auto",
                marginBottom: "0px",
                fontSize: "12px",
              }}
            >
              <SectionHeader>Additional Details</SectionHeader>
            </legend>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* <Box
          sx={{
            border: "1px solid #cccccc",
            padding: "20px",
          }}
        > */}
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item xs={5}>
                    <InputWrapper>
                      <Label>Name</Label>
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        value={additionalDetails.label}
                        type="text"
                        placeholder=""
                        name="label"
                        onChange={handleAdditionalDetailsChange}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={5}>
                    <InputWrapper>
                      <Label>Value</Label>
                      {/* <Box
                  sx={{ display: "flex", alignItems: "flex-end", gap: "16px" }}
                > */}
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        value={additionalDetails.value}
                        type="text"
                        placeholder=""
                        name="value"
                        onChange={handleAdditionalDetailsChange}
                      />
                      {/* <Button
                    style={{
                      textTransform: "none",
                      fontWeight: "bold",
                      width: "100%",
                      fontSize: "12px",
                    }}
                    disabled={
                      specificationsDetails["label"] === "" || specificationsDetails["value"] === ""
                    }
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      handleAdditionalDetails(specificationsDetails);
                      setSpecificationsDetails({ label: "", value: "" });
                    }}
                  >
                    {" "}
                    ADD
                  </Button>
                </Box> */}
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={2}>
                    {/* <Button
                style={{
                  textTransform: "none",
                  fontWeight: "bold",
                  width: "100%",
                }}
                disabled={specificationsDetails["label"] === "" || specificationsDetails["value"] === ""}
                color="primary"
                variant="text"
                onClick={() => {
                  handleAdditionalDetails(specificationsDetails);
                  setSpecificationsDetails({ label: "", value: "" });
                }}
              >
                <Add /> Add Specification
              </Button> */}
                    <Button
                      style={{
                        textTransform: "none",
                        fontWeight: "bold",
                        width: "100%",
                        fontSize: "12px",
                      }}
                      disabled={
                        additionalDetails["label"] === "" || additionalDetails["value"] === ""
                      }
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        handleAdditionalDetails(additionalDetails);
                        setAdditionalDetails({ label: "", value: "" });
                      }}
                    >
                      {" "}
                      ADD
                    </Button>
                  </Grid>
                </Grid>
                {/* </Box> */}
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ width: "100%" }}>
                  {additionalDetailsList.length > 0 &&
                    additionalDetailsList.map((entry, idx) => {
                      return (
                        <Grid
                          container
                          spacing={2}
                          // key={idx}
                          style={
                            {
                              // backgroundColor: idx % 2 !== 0 ? "#F2F2F2" : "white",
                              // padding: "4px 0px",
                              // width: "100%",
                            }
                          }
                          alignItems="center"
                        >
                          <Grid item xs={5}>
                            <Detail>{entry["label"]}</Detail>
                          </Grid>
                          <Grid item xs={5}>
                            <Detail>{entry["value"]}</Detail>
                          </Grid>
                          <Grid xs={2} item>
                            <Button
                              style={{
                                textTransform: "none",
                                fontWeight: "bold",
                                width: "100%",
                                fontSize: "12px",
                              }}
                              color="error"
                              variant="outlined"
                              onClick={() => {
                                handleAdditionalDetailsDelete(entry["label"]);
                              }}
                            >
                              DELETE
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Box>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default SpecificationsScreen;
