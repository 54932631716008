import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Drawer, Tooltip } from "@material-ui/core";
import { ChevronRight, Add } from "@material-ui/icons";
import { adminWhatsappPageColumnHeaders } from "../../../Db";
import {
  createAdminAccountMetaWabaTemplateApi,
  syncAdminAccountWhatsAppBusinessApi,
  deleteAdminAccountMetaWabaTemplateApi,
  getAdminAccountWhatsAppBusinessTemplateListApi,
} from "../../../Api";
import rootStore from "../../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../../Config";
import { styled } from "@mui/material/styles";
import {
  Backdrop,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Preview, Refresh } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import LoadingAnimationComponent from "../../../components/loading_component/LoadingAnimationComponent";
import WhatsappPreviewPopup from "../../../components/whatsapp_templates_page_components/message_preview/WhatsappPreviewPopup";
import SendTemplateDialogComponent from "../../../components/whatsapp_templates_page_components/SendTemplateDialogComponent";
import AddWhatsappBusinessTemplateSidepanel from "../../../components/whatsapp_templates_page_components/AddWhatsappBusinessTemplateSidepanel";
import CustomConfirmationDialog from "../../../components/alert_dialogue/CustomConfirmationDialog";
import SuccessMessageDialog from "../../../components/alert_dialogue/SuccessMessageDialog";
import ErrorMessageDialog from "../../../components/alert_dialogue/ErrorMessageDialog";
import { MultiSelect } from "react-multi-select-component";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const ProgressWrapper = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 16px 0px;
`;
const TableWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${`min-height: calc(100vh - 262px);`};
  background-color: white;
  position: relative;
`;
const ChipsContainer = styled(Box)`
  width: 20%;
  display: flex;
  margin-bottom: 10px;
  column-gap: 8px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const AdminBusinessWhatsappTemplates = () => {
  const [row, setRow] = useState([]);
  const [filteredRow, setFilteredRow] = useState([]);
  const providersList = [...rootStore.authStore.metaWabaProvidersList];
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [metaWabaRefreshing, setMetaWabaRefreshing] = useState(false);
  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [availableBusinessAccDetails, setAvailableBusinessAccDetails] =
    useState([]);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [selectedWABAAccDetails, setSelectedWABAAccDetails] = useState([]);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState([]);

  //preview popup states
  const [previewOpen, setPreviewOpen] = useState(false);
  const [whatsappPreviewMessage, setWhatsappPreviewMessage] = useState("");
  const [previewComponents, setPreviewComponents] = useState([]);

  //add sidebar states
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState("");

  const init = async () => {
    setLoading(true);
    
    let templatesList = await getAdminAccountWhatsAppBusinessTemplateListApi();

    //set available projects and whatsapp business account ids from template list
    let availableProjectsList = [];
    let businessAccountIds = [];
    let projectIdsSet = new Set();
    let businessIdsSet = new Set();

    for (let i = 0; i < templatesList.length; i++) {
      if (templatesList[i]?.project) {
        const project = templatesList[i].project;
        if (!projectIdsSet.has(project.id)) {
          projectIdsSet.add(project.id);
          availableProjectsList.push({
            label: project.stage,
            value: project.id,
          });
        }
      }

      if (templatesList[i]?.whatsapp_provider) {
        const providerId = templatesList[i].whatsapp_provider.id;
        const index = providersList.findIndex(
          (provider) => provider.id === providerId
        );
        if (index !== -1) {
          const businessId = providersList[index]["waba_account_id"];
          if (businessId && !businessIdsSet.has(businessId)) {
            businessIdsSet.add(businessId);
            businessAccountIds.push({
              label: businessId,
              value: businessId,
              provider_id: providerId,
            });
          }
        }
      }
    }
    setAvailableProjects(availableProjectsList);
    setSelectedProjectDetails(availableProjectsList);
    setAvailableBusinessAccDetails(businessAccountIds);
    setSelectedWABAAccDetails(businessAccountIds);
    handleListingFilter(
      businessAccountIds,
      availableProjectsList,
      templatesList
    );
    setRow(templatesList);

    setLoading(false);
  };

  const handleMetaWABARefresh = async () => {
    setMetaWabaRefreshing(true);
    await syncAdminAccountWhatsAppBusinessApi();
    setMetaWabaRefreshing(false);
    setRefresh(true);
  };

  const handleListingFilter = (
    availableBusinessAccountsList,
    availableProjectsList,
    templatesList
  ) => {
    //business account filtering (business account id mapped to provider)
    const selectedProviderIds = availableBusinessAccountsList.map(
      (item) => item.provider_id
    );
    let filteredRows = templatesList.filter((item) =>
      selectedProviderIds.includes(item.whatsapp_provider.id)
    );
    //project filtering
    const selectedProjectIds = availableProjectsList.map((item) => item.value);
    filteredRows = filteredRows.filter(
      (item) =>
        item.project !== null &&
        item.project !== "" &&
        selectedProjectIds.includes(item.project.id)
    );

    setFilteredRow(filteredRows);
  };

  useEffect(() => {
    init();
  }, []);

  const refetch = async () => {
    await init();
    setRefresh(false);
  };

  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  const getUserPhoneNumber = () => {
    if (rootStore.userStore.UserData !== null) {
      return rootStore.userStore.UserData.hasOwnProperty("phone")
        ? rootStore.userStore.UserData["phone"]
        : "";
    }
    return "";
  };

  const getComponentByType = (selectedTemplateData, type) => {
    const index = selectedTemplateData["components"].findIndex(
      (x) => x.type.toLowerCase() === type
    );
    if (index !== -1) {
      return selectedTemplateData["components"][index];
    } else {
      return null;
    }
  };

  const shouldDisabledSendIcon = (templateData) => {
    const headerComponent = getComponentByType(templateData, "header");
    if (headerComponent === null) {
      return false;
    }
    return headerComponent["format"]?.toLowerCase() !== "text";
  };

  const handleTemplateDelete = async (templateID) => {
    setDeleteConfirmationOpen(false);
    setShowBackDrop(true);
    const response = await deleteAdminAccountMetaWabaTemplateApi({
      templateID: templateID,
    });

    if (response.hasError()) {
      setShowBackDrop(false);
      setOpenErrorDialog(true);
    } else {
      setShowBackDrop(false);
      setSuccessMessage("Template deleted successfully!");
      setOpenSuccessDialog(true);
      setRefresh(true);
    }
  };

  const handleSubmit = async (payload) => {
    setSubmitLoading(true);
    if (isEditable) {
      //update api - edit disabled for now
      setIsSubmitSuccess(true);
      setSubmitLoading(false);
    } else {
      //create api
      const response = await createAdminAccountMetaWabaTemplateApi({
        payload: payload,
      });

      if (response.hasError()) {
        setIsSubmitSuccess(false);
        setErrorMessage(response.errorMessage);
        setIsSubmitFail(true);
      } else {
        setRefresh(true);
        setIsSubmitSuccess(true);
      }
      setSubmitLoading(false);
    }
    setIsEditable(false);
  };

  const additionalColumns = [
    {
      field: "action",
      headerName: "ACTIONS",
      minWidth: 150,
      flex: 1,
      resizable: false,
      cellRenderer: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              columnGap: "20px",
            }}
          >
            {/* Edit disabled */}
            {/* <Tooltip title={"Edit"}>
              <IconButton
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSelectedRowData(params.data);
                  setIsEditable(true);
                  setIsSubmitSuccess(false);
                  setIsSubmitFail(false);
                  setErrorMessage("");
                  setSubmitLoading(false);
                  setOpen(true);
                }}
              >
                <Edit
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip> */}
            <Tooltip title={"Delete"}>
              <IconButton
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSelectedRowData(params.data);
                  setDeleteConfirmationOpen(true);
                }}
              >
                <DeleteIcon
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Preview"}>
              <IconButton
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  setPreviewComponents(params.data.components);
                  setWhatsappPreviewMessage(params.data.message);
                  setPreviewOpen(true);
                }}
              >
                <Preview
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Send"}>
              <IconButton
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  setSelectedRowData(params.data);
                  setSendDialogOpen(true);
                }}
                disabled={shouldDisabledSendIcon(params.data)}
              >
                <SendIcon
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  return (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Header>Admin</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Header>WhatsApp Templates</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Header>Business</Header>
            </TopWrapper>
            <Box sx={{ display: "flex", columnGap: "8px" }}>
              <Button
                onClick={() => {
                  handleMetaWABARefresh();
                }}
                variant="contained"
                style={{
                  backgroundColor: "#185DD2",
                  width: "fit-content",
                }}
              >
                {metaWabaRefreshing ? (
                  <CircularProgress size="20px" color="inherit" />
                ) : (
                  <Refresh />
                )}
              </Button>
              <Button
                onClick={() => {
                  setIsSubmitSuccess(false);
                  setIsSubmitFail(false);
                  setErrorMessage("");
                  setSubmitLoading(false);
                  setIsEditable(false);
                  setOpen(true);
                }}
                variant="contained"
                style={{
                  backgroundColor: "#185DD2",
                  color: "white",
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                <Add />
                Create Template
              </Button>
            </Box>
          </Row>
          {availableProjects.length > 1 &&
            availableBusinessAccDetails.length > 1 && (
              <ChipsContainer>
                <InputWrapper>
                  <MultiSelect
                    options={availableBusinessAccDetails}
                    value={selectedWABAAccDetails}
                    onChange={(e) => {
                      setSelectedWABAAccDetails(e);
                      handleListingFilter(e, selectedProjectDetails, row);
                    }}
                    className={"chart_multi_select"}
                    labelledBy="WhatsApp Business Account"
                    overrideStrings={{
                      selectSomeItems: "WhatsApp Business Account",
                      search: "Search WhatsApp Business Accounts...",
                      allItemsAreSelected:
                        "All WhatsApp Business Accounts selected",
                    }}
                  />
                </InputWrapper>
                <InputWrapper>
                  <MultiSelect
                    options={availableProjects}
                    value={selectedProjectDetails}
                    onChange={(e) => {
                      setSelectedProjectDetails(e);
                      handleListingFilter(selectedWABAAccDetails, e, row);
                    }}
                    className={"chart_multi_select"}
                    labelledBy="Project"
                    overrideStrings={{
                      selectSomeItems: "Project",
                      search: "Search Projects...",
                      allItemsAreSelected: "All Projects selected",
                    }}
                  />
                </InputWrapper>
              </ChipsContainer>
            )}
          <TableWrapper>
            {!loading ? (
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={filteredRow}
                  defaultColDef={defaultColDef}
                  columnDefs={adminWhatsappPageColumnHeaders.concat(
                    additionalColumns
                  )}
                  animateRows={true}
                  suppressCellFocus
                  pagination={true}
                  paginationPageSize={pageSize}
                  className="paginated-ag-grid"
                />
              </div>
            ) : (
              <ProgressWrapper>
                <LoadingAnimationComponent size={"small"} />
              </ProgressWrapper>
            )}
          </TableWrapper>
        </Container>
      </ParentContainer>
      {open && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={open}
          onClose={() => {
            // setOpen(false);
          }}
        >
          <AddWhatsappBusinessTemplateSidepanel
            setOpen={setOpen}
            isEdit={isEditable}
            editableData={selectedRowData}
            setPreviewOpen={setPreviewOpen}
            postSubmitCallback={handleSubmit}
            isSubmitSuccess={isSubmitSuccess}
            isSubmitFail={isSubmitFail}
            setIsSubmitFail={setIsSubmitFail}
            errorMessage={errorMessage}
            submitLoading={submitLoading}
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            selectedFileUrl={selectedFileUrl}
            setSelectedFileUrl={setSelectedFileUrl}
            setPreviewComponents={setPreviewComponents}
            providersList={providersList}
          />
        </Drawer>
      )}
      <Footer />
      {previewOpen && (
        <WhatsappPreviewPopup
          open={previewOpen}
          setOpen={setPreviewOpen}
          content={whatsappPreviewMessage}
          images={selectedImages}
          contentType={"metaWaba"}
          components={previewComponents}
        />
      )}
      {sendDialogOpen && (
        <SendTemplateDialogComponent
          open={sendDialogOpen}
          setOpen={setSendDialogOpen}
          templateData={selectedRowData}
          phoneNumber={getUserPhoneNumber()}
        />
      )}
      {deleteConfirmationOpen && (
        <CustomConfirmationDialog
          message={"Are you sure you want to delete this template?"}
          open={deleteConfirmationOpen}
          setOpen={setDeleteConfirmationOpen}
          handleConfirm={() => {
            handleTemplateDelete(selectedRowData["id"]);
          }}
        />
      )}
      {openSuccessDialog && (
        <SuccessMessageDialog
          open={openSuccessDialog}
          setOpen={setOpenSuccessDialog}
          message={successMessage}
        />
      )}
      {openErrorDialog && (
        <ErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
        />
      )}
      <Backdrop
        open={showBackDrop}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
    </>
  );
};

export default observer(AdminBusinessWhatsappTemplates);
