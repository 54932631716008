import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, CircularProgress, Drawer } from "@material-ui/core";
import { Add, ChevronRight, Edit } from "@material-ui/icons";
import { productsBuilderColumnHeaders } from "../../Db";

import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled } from "@mui/material/styles";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { getProductManufacturerListApi } from "../../Api";
import { getAccountLocalizedText, getLocalizedText } from "../../Functions";
import CreateBuilderComponent from "../../components/builder_page_components/CreateBuilderComponent";
import ViewBuilderInfoSidepanel from "../../components/builder_page_components/ViewBuilderInfoSidepanel";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../../components/pagination/PaginationComponent";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 16px 0px;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 218px);`};
  background-color: white;
  padding: 20px;
  position: relative;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;

const BuildersPage = () => {
  const [row, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openCreateBuilderPanel, setOpenCreateBuilderPanel] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [viewBuilderInfoPanel, setViewBuilderInfoPanel] = useState(false);
  let navigate = useNavigate();

  const getManufacturerList = async ({ page, page_size }) => {
    const queryPayload = {
      name: "",
    };
    let response = await getProductManufacturerListApi({
      page: page,
      page_size: page_size,
      payload: { query: queryPayload },
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
  };

  const init = async () => {
    await getManufacturerList({ page: 0, page_size: pageSize });
    setLoading(false);
  };

  const refetch = async () => {
    setLoading(true);
    await getManufacturerList({ page: 0, page_size: pageSize });
    setRefresh(false);
    setLoading(false);
  };

  useEffect(() => {
    if (refresh === true) {
      refetch();
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const handlePageSize = async (e) => {
    setLoading(true);
    setSize(e.target.value);
    setPage(0);
    await getManufacturerList({
      page: 0,
      page_size: e.target.value,
    });
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setLoading(true);
    setPage(page - 1);
    await getManufacturerList({ page: page - 1, page_size: size });

    setLoading(false);
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const actionColumn = [
    {
      field: "actions",
      headerName: "ACTIONS",
      resizable: false,
      minWidth: 130,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Tooltip title={"Edit"}>
            <IconButton
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedRowData(params.data);
                setIsEdit(true);
                setOpenCreateBuilderPanel(true);
              }}
            >
              <Edit
                style={{
                  width: "18px",
                  height: "18px",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <ParentContainer>
        {!loading ? (
          <Container>
            <Row>
              <TopWrapper>
                <Header
                  style={{
                    color: "#185DD2",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/products");
                  }}
                >
                  {getAccountLocalizedText("products")}
                </Header>
                <ChevronRight style={{ width: "16px", height: "16px" }} />
                <Header>{getAccountLocalizedText("manufacturers")}</Header>
              </TopWrapper>
              <Button
                onClick={() => {
                  setOpenCreateBuilderPanel(true);
                }}
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "160px",
                  marginLeft: "20px",
                  height: "40px",
                }}
              >
                <Add sx={{ height: "18px" }} />
                {getAccountLocalizedText("create_manufacturer")}
              </Button>
            </Row>
            <TableWrapper>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={productsBuilderColumnHeaders.concat(actionColumn)}
                  animateRows={true}
                  suppressCellFocus
                  pagination={false}
                  paginationPageSize={pageSize}
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    setSelectedRowData(row.data);
                    setViewBuilderInfoPanel(true);
                  }}
                />
              </div>
              <PaginationComponent
                page_no={page}
                row={row}
                page_size={size}
                size={size}
                count={count}
                handlePageSize={handlePageSize}
                handlePage={handlePagination}
              />
            </TableWrapper>
          </Container>
        ) : (
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Container>
        )}
      </ParentContainer>
      <Footer />
      {openCreateBuilderPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCreateBuilderPanel}
          onClose={() => {
            setIsEdit(false);
            setOpenCreateBuilderPanel(false);
          }}
        >
          <CreateBuilderComponent
            setRefresh={setRefresh}
            setOpen={setOpenCreateBuilderPanel}
            open={openCreateBuilderPanel}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            editableData={selectedRowData}
          />
        </Drawer>
      )}
      {viewBuilderInfoPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={viewBuilderInfoPanel}
          onClose={() => {
            setViewBuilderInfoPanel(false);
          }}
        >
          <ViewBuilderInfoSidepanel
            setOpen={setViewBuilderInfoPanel}
            open={viewBuilderInfoPanel}
            data={selectedRowData}
            handleEdit={() => {
              setIsEdit(true);
              setOpenCreateBuilderPanel(true);
              setViewBuilderInfoPanel(false);
            }}
            list={row}
            refresh={refresh}
          />
        </Drawer>
      )}
    </>
  );
};

export default observer(BuildersPage);
