import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  makeStyles,
  Modal,
  Select,
  Typography,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { Alert, AppBar, Toolbar } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {
  createCampaignApi,
  getCampaignProviderListApi,
  getCampaignTypeListApi,
  getMetaWabaTemplatesForProviderApi,
  getSegmentsApi,
} from "../../Api";
import { useEffect } from "react";
import { consoleLogger, dataRenderer, getLocalizedText } from "../../Functions";
import AutoDialerCampaign from "../campaign_forms/AutoDialerCampaign";
import EmailCampaign from "../campaign_forms/EmailCampaign";
import SmsCampaign from "../campaign_forms/SmsCampaign";
import WhatsappCampaign from "../campaign_forms/WhatsappCampaign";
import analytics from "../../Analytics";
import { MoreHoriz } from "@material-ui/icons";
import moment from "moment-timezone";
import rootStore from "../../stores/RootStore";
import CampaignFilterModal from "./campaign_filter_modal/CampaignFilterModal";
import OfflineCampaign from "../campaign_forms/OfflineCampaign";
import EmailScheduleComponent from "../campaign_forms/EmailScheduleComponent";
import { campaignSubTypes } from "../../Db";
import MuiAlert from "@mui/material/Alert";
import WhatsappScheduleComponent from "../campaign_forms/WhatsappScheduleComponent";
import { Preview } from "@mui/icons-material";
import ViewSegmentFiltersModal from "./ViewSegmentFiltersModal";
import Papa from "papaparse";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  gap: 20px;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;
const ModelWrapper = styled(Box)`
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: calc(100vh - 128px);
  padding-top: 0px;
`;

const Header = styled.span`
  font: normal normal 600 18px Open Sans;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 340px;
  width: 100%;
`;
const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const SegmentBoxLabel = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;
const Input = styled.input`
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 600 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled.textarea`
  height: 60px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;
  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 180px;
  @media (max-width: 700px) {
    width: 140px;
  }
`;
const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  column-gap: 10px;
`;
const GroupLabel = styled("legend")`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  width: auto;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;

function ampm(time) {
  if (time.value !== "") {
    var hours = time.split(":")[0];
    var minutes = time.split(":")[1];
    var suffix = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var displayTime = hours + ":" + minutes + " " + suffix;
    return displayTime;
  }
  return;
}
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const autoDialerCampaignTypeList = [
  { label: "Agentwise - Equally", value: "agent_wise" },
];
const CreateCampaignModal = (props) => {
  const classes = useStyles();
  const [data, setData] = useState({
    title: "",
    desc: "",
  });
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedCampaignTypeID, setSelectedCampaignTypeID] = useState("");
  const [selectedProviderItemID, setSelectedProviderItemID] = useState("");
  const [selectedProviderData, setSelectedProviderData] = useState({});
  const [templatesForProvider, setTemplatesForProvider] = useState([]);
  const [selectedSubType, setSelectedSubType] = useState("");

  //whatsapp campaign
  const [whatsappScheduleOption, setWhatsappScheduleOption] = useState("now");
  const [selectedWhatsappTemplate, setSelectedWhatsappTemplate] = useState("");
  const [whatsappVariablePayload, setWhatsappVariablePayload] = useState({});
  const [whatsappScheduleDateTime, setWhatsappScheduleDateTime] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const [timeSlot, setTimeSlot] = useState("");
  const [timezone, setTimezone] = useState(moment.tz.guess());

  const [whatsappVariablesFilled, setWhatsappVariablesFilled] = useState(false);
  const [variableDetails, setVariableDetails] = useState([]);
  const [headerVariableField, setHeaderVariableField] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);

  const [segment, setSegment] = useState({});
  const [segmentError, setSegmentError] = useState(false);
  const [viewFiltersOpen, setViewFiltersOpen] = useState(false);
  const [segmentList, setSegmentList] = useState([]);
  const [campaignTypeList, setCampaignTypeList] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [autodialerCampaignType, setAutodialerCampaignType] = useState("");

  //email state vars
  const [emailDateData, setEmailDateData] = useState({
    date: null,
    time: null,
    recurring_type: "Daily",
    every: 0,
    end_type: "Never End",
    end_date: null,
  });

  //below are the state variable for select filter popup
  const [selectedSegmentFilterData, setSelectedSegmentFiterData] = useState({});
  const [openFilterSelectionModal, setOpenFilterSelectionModal] =
    useState(false);
  const [dateRangeFilterList, setDateRangeFilterList] = useState(null);
  const [numRangeFilterList, setNumRangeFilterList] = useState({});
  const [selectFieldDataList, setSelectFieldDataList] = useState({});
  const [filterList, setFilterList] = useState({});
  const [segmentFilterFieldList, setSegementFilterFieldList] = useState([]); //this state is maintained to track to make the segemnt filter fields disable/readonly
  const [emailTemplateVariableList, setEmailTemplateVariableList] = useState(
    {}
  ); //this state is maintained for email template variables

  //*********************END******************************* */
  const columnsList = [...rootStore.userStore.AllColumnsList];

  //state variables for email campaign creation
  const [fromAddress, setFromAddress] = useState("");
  const [emailTemplateID, setEmailTemplateID] = useState("");

  //******************END***************** */

  const [campaignDateRange, setCampaignDateRange] = useState({
    start_date: "",
    end_date: "",
  }); //this state is created for camapign type offline

  const [stepCount, setStepCount] = useState(0);
  const [segmentRadio, setSegmentRadio] = useState(0);
  const [smsText, setSmsText] = useState(
    "Hello {{name}}, Nice to meet you,we have received your {{email}}"
  );
  const [variables, setVariables] = useState({});
  const [showFileErrorMessage, setShowFileErrorMessage] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  function getIsoString(date) {
    const momentObj = moment(date);
    const isoString = momentObj.toISOString();
    return isoString;
  }
  function convertTimeToISO(timeInput) {
    //HH:mm input
    const today = moment().utc().startOf("day");
    const timeInUTC = today.add(moment.duration(timeInput));

    return timeInUTC.toISOString();
  }

  const createCampaign = async () => {
    if (segmentRadio === 0 && Object.keys(segment).length === 0) {
      setSegmentError(true);
      return;
    }

    rootStore.userStore.setReportFilters({
      filters: filterList,
      dateRange: dateRangeFilterList === null ? {} : dateRangeFilterList,
      numRange: numRangeFilterList,
      selectedVal: selectFieldDataList,
    });
    let campaign_type_name = getCampaignTypeName();
    let provider_name = getProviderName();
    let formData = new FormData();

    formData.append("title", data.title);
    formData.append("desc", data.desc);
    formData.append("campaign_type", campaign_type_name);
    if (campaign_type_name.toLowerCase() === "whatsapp") {
      formData.append(
        "input",
        JSON.stringify({
          component_parameters:
            whatsappVariablePayload?.component_parameters || [],
          whatsapp_template_id: selectedWhatsappTemplate.id,
        })
      );
      formData.append("schedule_type", whatsappScheduleOption);
      formData.append("schedule_date", whatsappScheduleDateTime);
      formData.append("provider_id", selectedProviderItemID);
    }

    if (campaign_type_name.toLowerCase() !== "offline") {
      if (segmentRadio === 0) {
        formData.append("segment_id", segment.id);
      } else {
        if (file) {
          formData.append("input_file", file);
        }
      }
    }

    if (campaign_type_name.toLowerCase() === "offline") {
      let start_date = campaignDateRange["start_date"]
        ? getIsoString(campaignDateRange["start_date"])
        : null;
      let end_date = campaignDateRange["end_date"]
        ? getIsoString(campaignDateRange["end_date"])
        : null;
      if (start_date) {
        formData.append("start_date", start_date);
      }
      if (end_date) {
        formData.append("end_date", end_date);
      }
    }

    if (
      campaign_type_name.toLowerCase() === "auto dialer" &&
      provider_name.toLowerCase() === "mcube"
    ) {
      formData.append(
        "input",
        JSON.stringify({
          dialer_campaign_type: autodialerCampaignType,
          distribution: "equal",
          agent_list: agentList,
        })
      );
    } else if (
      campaign_type_name.toLowerCase() === "email" &&
      provider_name.toLowerCase() === "sendgrid"
    ) {
      //sending selected fromAdress object as payload
      const fromAddressIndex = selectedProviderData["provider"][
        "from_addresses"
      ].findIndex((x) => x.email === fromAddress);
      let fromAddressObj =
        fromAddressIndex !== -1
          ? selectedProviderData["provider"]["from_addresses"][fromAddressIndex]
          : {};

      formData.append(
        "input",
        JSON.stringify({
          from_address: fromAddressObj,
          email_template_id: emailTemplateID,
          variables: emailTemplateVariableList,
        })
      );

      let scheduleData = {};

      if (selectedSubType.toLowerCase() === "recurring") {
        for (let key in emailDateData) {
          if (key === "date") {
            scheduleData["start_date"] = emailDateData[key].toISOString();
          } else if (
            key === "end_date" &&
            emailDateData["end_type"].toLowerCase() === "till"
          ) {
            scheduleData[key] = emailDateData[key].toISOString();
          } else if (key === "time") {
            scheduleData[key] = convertTimeToISO(emailDateData[key]);
          } else {
            scheduleData[key] = emailDateData[key];
          }
        }
      } else {
        scheduleData["date"] = emailDateData["date"].toISOString();
        scheduleData["time"] = convertTimeToISO(emailDateData["time"]);
      }

      formData.append("sub_type", selectedSubType);
      formData.append("schedule", JSON.stringify(scheduleData));
    }

    let response = await createCampaignApi(formData);

    if (response.hasError()) {
      console.log(response.errorMessage);
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(true);
      setIsSubmitSuccess(true);
      //props.setOpen(false);
    }
  };
  const handleSubmit = () => {
    if (getCampaignTypeName(selectedCampaignTypeID).toLowerCase() === "email") {
      if (stepCount < 2) {
        setStepCount(stepCount + 1);
      } else {
        createCampaign();
      }
    } else if (
      stepCount === 1 &&
      getCampaignTypeName(selectedCampaignTypeID).toLowerCase() === "whatsapp"
    ) {
      //template variable validation for whatsapp
      if (hasVariablesInTemplate()) {
        if (whatsappVariablesFilled) {
          createCampaign();
        } else {
          setIsSubmitFail(true);
          setErrorMessage(
            getLocalizedText("please_fill_the_variables_in_preview")
          );
        }
      } else {
        createCampaign();
      }
    } else {
      if (stepCount === 0) {
        setStepCount(1);
      } else {
        createCampaign();
      }
    }
  };

  const getSegments = async (selectedProvider) => {
    let response = await getSegmentsApi({
      page_size: 100,
      page: 0,
    });
    if (Object.keys(response).length > 0) {
      if (getCampaignTypeName().toLowerCase() === "whatsapp") {
        //filter based on provider for whatsapp
        const filteredSegments = response.items.filter(
          (item) => item?.project?.id === selectedProvider?.project?.id
        );
        setSegmentList(filteredSegments);
      } else {
        setSegmentList(response.items);
      }
    } else {
      setSegmentList([]);
    }
  };
  const getCampaignsTypeList = async () => {
    let response = await getCampaignTypeListApi();
    setCampaignTypeList(response);
  };
  const setup = async () => {
    await getCampaignsTypeList();
  };
  useEffect(() => {
    analytics.triggerEvent(
      4625000245,
      "create_campaign_form_load",
      "campaigns_page",
      "",
      {}
    );
    setup();
  }, []);

  const handlePrevious = () => {
    setStepCount(stepCount - 1);
  };

  const validEmailHeaders = ["Email", "email", "email_id"];
  const validWhatsappHeaders = ["phone", "Phone"];

  const hasValidHeader = (headers) => {
    const validHeaders =
      getCampaignTypeName().toLowerCase() === "whatsapp"
        ? validWhatsappHeaders
        : validEmailHeaders;

    return headers.some((header) => validHeaders.includes(header));
  };

  const handleFileUpload = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];

    if (file) {
      setShowFileErrorMessage(false);
      setFileErrorMessage("");
      let startTime = performance.now();

      Papa.parse(file, {
        header: false,
        skipEmptyLines: true,
        worker: true,
        complete: function (results) {
          let elapsedTime = (performance.now() - startTime) / 1000;
          consoleLogger("Performance time for parsing csv:", elapsedTime, "s");

          let rowCount = Array.isArray(results.data) ? results.data.length : 0;
          consoleLogger("ROW Count:", rowCount);

          if (rowCount === 0) {
            setFileErrorMessage("Error: No data is present in uploaded file.");
            setShowFileErrorMessage(true);
            fileInput.value = "";
            return;
          }

          if (rowCount - 1 > 1000) {
            setFileErrorMessage(
              "Error: The number of rows has exceeded the allowed limit."
            );
            setShowFileErrorMessage(true);
            fileInput.value = "";
            return;
          }

          let headers = results.data[0];
          if (!hasValidHeader(headers)) {
            setFileErrorMessage(
              `Import failed: Please ensure your import file ${
                getCampaignTypeName().toLowerCase() === "whatsapp"
                  ? "Phone"
                  : "Email"
              } column.`
            );
            setShowFileErrorMessage(true);
            fileInput.value = "";
            return;
          }

          consoleLogger("CSV Rows:", headers);

          setFile(file);
        },
      });
    }
  };

  const getCurrentProviderData = (id) => {
    let index = providerList.findIndex((provider) => provider["id"] === id);
    return index !== -1 ? providerList[index] : {};
  };

  const getProviderList = async (campaign_type_id) => {
    let payload = { campaign_type_id: campaign_type_id };
    const response = await getCampaignProviderListApi(payload);
    setProviderList(response);
  };
  const handleCampaign = (e) => {
    setSelectedCampaignTypeID(e.target.value);
    getProviderList(e.target.value);
  };

  const getCampaignTypeName = () => {
    let index = campaignTypeList.findIndex(
      (campaign) => campaign["id"] === selectedCampaignTypeID
    );
    if (index !== -1) {
      return campaignTypeList[index]["name"];
    }
    return "";
  };

  const getProviderName = () => {
    if (!Array.isArray(providerList) || providerList.length === 0) {
      return ""; // Return an empty string if providerList is undefined, null, or empty
    }

    let index = providerList.findIndex(
      (provider) => provider["id"] === selectedProviderItemID
    );
    if (index !== -1) {
      return providerList[index]["provider_name"];
    }
    return "";
  };

  const renderFieldSteps = () => {
    let campaign_type_name = getCampaignTypeName();
    let provider_name = getProviderName();
    if (
      campaign_type_name.toLowerCase() === "auto dialer" &&
      provider_name.toLowerCase() === "mcube"
    ) {
      return (
        <AutoDialerCampaign
          autoDialerCampaignTypeList={autoDialerCampaignTypeList}
          setAutodialerCampaignType={setAutodialerCampaignType}
          provider={provider_name}
          setAgentList={setAgentList}
        />
      );
    } else if (
      campaign_type_name.toLowerCase() === "email" &&
      provider_name.toLowerCase() === "sendgrid"
    ) {
      return (
        <EmailCampaign
          campaignTypeName={campaign_type_name}
          setVariables={setVariables}
          variables={variables}
          providerName={provider_name}
          providerList={providerList}
          selectedProviderItemID={selectedProviderItemID}
          selectedProviderData={selectedProviderData}
          setFromAddress={setFromAddress}
          setEmailTemplateID={setEmailTemplateID}
          emailTemplateVariableList={emailTemplateVariableList}
          setEmailTemplateVariableList={setEmailTemplateVariableList}
          emailTemplateID={emailTemplateID}
          fromAddress={fromAddress}
        />
      );
    } else if (campaign_type_name.toLowerCase() === "offline") {
      return (
        <OfflineCampaign
          campaignTypeName={campaign_type_name}
          campaignDateRange={campaignDateRange}
          setCampaignDateRange={setCampaignDateRange}
        />
      );
    } else if (
      campaign_type_name.toLowerCase() === "sms" &&
      provider_name.toLowerCase() === "sendgrid"
    ) {
      return (
        <SmsCampaign
          smsText={smsText}
          campaignType={campaign_type_name}
          setVariables={setVariables}
          variables={variables}
          provider={provider_name}
        />
      );
    } else if (
      campaign_type_name.toLowerCase() === "whatsapp" //&&
      //provider_name.toLowerCase() === "sendgrid"
    ) {
      return (
        <WhatsappCampaign
          smsText={smsText}
          campaignType={campaign_type_name}
          setVariables={setVariables}
          variables={variables}
          provider={provider_name}
          providerData={selectedProviderData}
          setSelectedWhatsappTemplate={setSelectedWhatsappTemplate}
          selectedWhatsappTemplate={selectedWhatsappTemplate}
          setWhatsappVariablePayload={setWhatsappVariablePayload}
          setWhatsappVariablesFilled={setWhatsappVariablesFilled}
          setVariableDetails={setVariableDetails}
          variableDetails={variableDetails}
          setHeaderVariableField={setHeaderVariableField}
          headerVariableField={headerVariableField}
          setSelectedFile={setSelectedFile}
          selectedFile={selectedFile}
          isCsvSegment={segmentRadio === 1}
          metaTemplates={templatesForProvider}
        />
      );
    }
  };

  //***********below are helper functions used for filter selection modal ************************** */

  const getFieldMeta = (fieldName) => {
    let data = {};
    let index = columnsList.findIndex(
      (column) => column["field"] === fieldName
    );
    if (index !== -1) {
      data["filter_type"] = columnsList[index]["filter_type"];
      data["data_type"] = columnsList[index]["data_type"];
    }
    return data;
  };

  const getComponentByType = (type) => {
    const component = selectedWhatsappTemplate["components"].find(
      (x) => x.type.toLowerCase() === type
    );
    return component || null;
  };

  function extractPlaceholders(inputString) {
    const regex = /\{\{\d+\}\}/g;
    const matches = inputString.match(regex);
    return matches || [];
  }

  //whatsapp template
  const hasVariablesInTemplate = () => {
    // Check header component for variables
    const headerData = getComponentByType("header");
    let headerHasVariables = false;

    if (headerData && headerData.hasOwnProperty("format")) {
      if (
        headerData.format.toLowerCase() === "image" ||
        headerData.format.toLowerCase() === "video"
      ) {
        headerHasVariables = true;
      }
    }

    // Check body component for variables
    const bodyData = getComponentByType("body");
    let bodyHasVariables = false;

    if (bodyData && bodyData.hasOwnProperty("text")) {
      bodyHasVariables = extractPlaceholders(bodyData.text).length > 0;
    }

    return headerHasVariables || bodyHasVariables;
  };

  const initSegmentFilterData = () => {
    if (Object.keys(selectedSegmentFilterData).length > 0) {
      let filterData = selectedSegmentFilterData;
      let normalFilterValues = { ...filterList };
      let dataRangeFilterValues = { ...dateRangeFilterList };
      let numRangeFilterValues = { ...numRangeFilterList };
      let listFilterValues = { ...selectFieldDataList };
      let fieldList = [];
      let customData = selectedSegmentFilterData.hasOwnProperty("custom_params")
        ? selectedSegmentFilterData["custom_params"]
        : {};
      Object.keys(filterData).forEach((e) => {
        let field_meta = getFieldMeta(e);
        let data_type = field_meta.hasOwnProperty("data_type")
          ? field_meta["data_type"]
          : null;
        let filter_type = field_meta.hasOwnProperty("filter_type")
          ? field_meta["filter_type"]
          : null;
        let index = columnsList.findIndex((column) => column["field"] === e);
        if (index !== -1) {
          if (columnsList[index]["is_custom_param"]) {
            Object.keys(customData).forEach((item) => {
              let new_key = "custom_params_" + item;
              let field_meta = getFieldMeta(new_key);
              let data_type = field_meta.hasOwnProperty("data_type")
                ? field_meta["data_type"]
                : null;
              let filter_type = field_meta.hasOwnProperty("filter_type")
                ? field_meta["filter_type"]
                : null;
              if (filter_type === "range") {
                if (data_type === "date" || data_type === "datetime") {
                  let obj = {};
                  let d1 = new Date(customData[item]["start"]);
                  let d2 = new Date(customData[item]["end"]);
                  d1 = moment(d1.toString()).format("YYYY-MM-DD");
                  d2 = moment(d2.toString()).format("YYYY-MM-DD");
                  obj["start"] = d1;
                  obj["end"] = d2;
                  dataRangeFilterValues[new_key] = obj;
                  fieldList.push(new_key);
                } else if (data_type === "integer") {
                  let obj = {};
                  let num1 = customData[item]["start"];
                  let num2 = customData[item]["end"];
                  obj["start"] = num1;
                  obj["end"] = num2;
                  numRangeFilterValues[new_key] = obj;
                  fieldList.push(new_key);
                }
              } else if (filter_type === "list") {
                listFilterValues[new_key] = customData[item];
                fieldList.push(new_key);
              } else {
                if (data_type === "date" || data_type === "datetime") {
                  let d = new Date(customData[item]);
                  d = moment(d.toString()).format("YYYY-MM-DD");
                  normalFilterValues[new_key] = {
                    value: d,
                    type: data_type,
                  };
                  fieldList.push(new_key);
                } else {
                  normalFilterValues[new_key] = {
                    value: customData[item],
                    type: data_type,
                  };
                  fieldList.push(new_key);
                }
              }
            });
          } else {
            let filter_value = filterData[e];
            if (filter_type === "range") {
              if (data_type === "date" || data_type === "datetime") {
                let obj = {};
                let d1 = new Date(filter_value["start"]);
                let d2 = new Date(filter_value["end"]);
                d1 = moment(d1.toString()).format("YYYY-MM-DD");
                d2 = moment(d2.toString()).format("YYYY-MM-DD");
                obj["start"] = d1;
                obj["end"] = d2;
                dataRangeFilterValues[e] = obj;
                fieldList.push(e);
              } else if (data_type === "integer") {
                let obj = {};
                let num1 = filter_value["start"];
                let num2 = filter_value["end"];
                obj["start"] = num1;
                obj["end"] = num2;
                numRangeFilterValues[e] = obj;
                fieldList.push(e);
              }
            } else if (filter_type === "list") {
              listFilterValues[e] = filter_value;
              fieldList.push(e);
            } else {
              if (data_type === "date" || data_type === "datetime") {
                let d = new Date(filter_value);
                d = moment(d.toString()).format("YYYY-MM-DD");
                normalFilterValues[e] = {
                  value: d,
                  type: data_type,
                };
                fieldList.push(e);
              } else {
                normalFilterValues[e] = {
                  value: filter_value,
                  type: data_type,
                };
                fieldList.push(e);
              }
            }
          }
        }
      });

      setSelectFieldDataList(listFilterValues);
      setDateRangeFilterList(dataRangeFilterValues);
      setNumRangeFilterList(numRangeFilterValues);
      setFilterList(normalFilterValues);
      setSegementFilterFieldList(fieldList);
      setOpenFilterSelectionModal(true);
    }
  };

  const resetFilterData = () => {
    setSelectFieldDataList({});
    setDateRangeFilterList({});
    setNumRangeFilterList({});
    setFilterList({});
  };
  //**********************END****************************** */
  return (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Header>{getLocalizedText("create_a_new_campaign")}</Header>
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModelWrapper
            component={"form"}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Container
              style={{
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              {(() => {
                if (stepCount === 0) {
                  return (
                    <>
                      <InputContainer>
                        <InputWrapper>
                          <Label>{getLocalizedText("title")}*</Label>
                          <Input
                            required
                            type="text"
                            placeholder="Enter title"
                            name="title"
                            value={data.title}
                            onChange={handleChange}
                            style={{ marginBottom: "20px" }}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <Label>{getLocalizedText("description")}</Label>
                          <TextArea
                            value={data.desc}
                            type="text"
                            placeholder="Enter description"
                            name="desc"
                            onChange={handleChange}
                          />
                        </InputWrapper>

                        <SelectColumn>
                          <Label>{getLocalizedText("campaign_type")}*</Label>
                          <FormControl
                            sx={{ m: 1, minWidth: 428 }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <Select
                              classes={{
                                icon: classes.icon,
                              }}
                              value={selectedCampaignTypeID}
                              name="campaign_type"
                              displayEmpty
                              disableUnderline
                              variant="outlined"
                              required
                              onChange={(e) => {
                                handleCampaign(e);
                                setSelectedProviderItemID("");
                                setSelectedProviderData({});
                                setTemplatesForProvider([]);
                                setSegment({});
                              }}
                              style={{
                                marginBottom: "20px",
                                height: "30px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "0px",
                              }}
                            >
                              {campaignTypeList.map((e) => {
                                return (
                                  <MenuItem id={e} value={e.id}>
                                    {e.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </SelectColumn>
                        {getCampaignTypeName(
                          selectedCampaignTypeID
                        ).toLowerCase() === "email" && (
                          <InputWrapper>
                            <Label>{getLocalizedText("sub_type")}*</Label>
                            <FormControl
                              sx={{ m: 1, width: "100%" }}
                              classes={{
                                root: classes.quantityRoot,
                              }}
                            >
                              <Select
                                classes={{
                                  icon: classes.icon,
                                }}
                                required
                                value={selectedSubType}
                                name="sub_type"
                                displayEmpty
                                disableUnderline
                                variant="outlined"
                                onChange={(e) => {
                                  setSelectedSubType(e.target.value);
                                }}
                                style={{
                                  marginBottom: "20px",
                                  width: "100%",
                                  height: "30px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  borderRadius: "0px",
                                }}
                              >
                                {campaignSubTypes.map((e, i) => {
                                  return (
                                    <MenuItem
                                      id={i}
                                      value={e.option}
                                      disabled={e.disabled}
                                    >
                                      {e.option}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </InputWrapper>
                        )}
                      </InputContainer>
                    </>
                  );
                } else if (stepCount === 1) {
                  return (
                    <InputContainer>
                      <SelectColumn>
                        <Label>
                          {" "}
                          {getCampaignTypeName().toLowerCase() === "offline" ||
                          getCampaignTypeName().toLowerCase() === ""
                            ? getLocalizedText("select_provider")
                            : `${getLocalizedText("select_provider")}*`}
                        </Label>
                        <FormControl
                          style={{ width: "100%" }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            value={selectedProviderItemID}
                            name="provider"
                            displayEmpty
                            disableUnderline
                            variant="outlined"
                            required={
                              getCampaignTypeName().toLowerCase() !==
                                "offline" &&
                              getCampaignTypeName().toLowerCase() !== ""
                            }
                            disabled={
                              getCampaignTypeName().toLowerCase() ===
                                "offline" ||
                              getCampaignTypeName().toLowerCase() === ""
                            }
                            onChange={async (e) => {
                              setSelectedProviderItemID(e.target.value);
                              const providerData = getCurrentProviderData(
                                e.target.value
                              );
                              const metaTemplates =
                                await getMetaWabaTemplatesForProviderApi(
                                  providerData.id
                                );
                              setTemplatesForProvider(metaTemplates);
                              setSelectedProviderData(providerData);
                              if (
                                getCampaignTypeName().toLowerCase() ===
                                "whatsapp"
                              ) {
                                //reset selected segment
                                setSegment({});

                                //resetting selected whatsapp template
                                setSelectedWhatsappTemplate(null);
                                //reset selected variable data and payload data
                                setVariableDetails([]);
                                setHeaderVariableField({});
                                setSelectedFile(null);
                                setWhatsappVariablesFilled(false);
                                setWhatsappVariablePayload({});
                              }
                              await getSegments(providerData);
                            }}
                            style={{
                              marginBottom: "20px",
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {providerList?.map((e) => {
                              return (
                                <MenuItem id={e} value={e.id}>
                                  {dataRenderer(e.name)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </SelectColumn>
                      <SelectColumn style={{ width: "100%" }}>
                        <Box
                          component={"fieldset"}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "10px",
                            border: "1px solid #cccccc",
                            padding: "12px",
                            width: "100%",
                            marginBottom: "20px",
                            height: "100%",
                          }}
                        >
                          <GroupLabel
                            style={{
                              display: "flex",
                              alignItems: "center",
                              lineHeight: "1.5",
                              marginBottom: "0px",
                            }}
                          >
                            Audience
                          </GroupLabel>
                          <Box>
                            {" "}
                            <Box
                              sx={{
                                height: "fit-content",
                                display: "flex",
                                marginBottom: "12px",
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={segmentRadio === 0}
                                    onChange={() => {
                                      setSegmentRadio(0);
                                      //resetting selected whatsapp template
                                      setSelectedWhatsappTemplate(null);
                                      //reset selected variable data and payload data
                                      setVariableDetails([]);
                                      setHeaderVariableField({});
                                      setSelectedFile(null);
                                      setWhatsappVariablesFilled(false);
                                      setWhatsappVariablePayload({});
                                    }}
                                    size="small"
                                  />
                                }
                                label={
                                  <Label
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {getCampaignTypeName().toLowerCase() ===
                                      "offline" ||
                                    getCampaignTypeName().toLowerCase() === ""
                                      ? getLocalizedText("select_segment")
                                      : `${getLocalizedText("select_segment")}`}
                                  </Label>
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={segmentRadio === 1}
                                    onChange={() => {
                                      setSegmentRadio(1);
                                      //resetting selected whatsapp template
                                      setSelectedWhatsappTemplate(null);
                                      //reset selected variable data and payload data
                                      setVariableDetails([]);
                                      setHeaderVariableField({});
                                      setSelectedFile(null);
                                      setWhatsappVariablesFilled(false);
                                      setWhatsappVariablePayload({});
                                    }}
                                    size="small"
                                  />
                                }
                                label={
                                  <Label
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Pick a File
                                  </Label>
                                }
                              />
                            </Box>
                            {segmentRadio === 0 ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: "10px",
                                  width: "100%",
                                  rowGap: "10px",
                                }}
                              >
                                <SelectColumn style={{ width: "100%" }}>
                                  <FormControl
                                    error={segmentError}
                                    style={{ width: "100%" }}
                                    required={
                                      segmentRadio === 0 &&
                                      getCampaignTypeName().toLowerCase() !==
                                        "offline" &&
                                      getCampaignTypeName().toLowerCase() !== ""
                                    }
                                  >
                                    <Select
                                      value={segment}
                                      disabled={
                                        segmentRadio !== 0 ||
                                        getCampaignTypeName().toLowerCase() ===
                                          "offline" ||
                                        getCampaignTypeName().toLowerCase() ===
                                          ""
                                      }
                                      name="segment"
                                      disableUnderline
                                      variant="outlined"
                                      required={
                                        segmentRadio === 0 &&
                                        getCampaignTypeName().toLowerCase() !==
                                          "offline" &&
                                        getCampaignTypeName().toLowerCase() !==
                                          ""
                                      }
                                      onChange={(e) => {
                                        setSegment(e.target.value);
                                        let index = segmentList.findIndex(
                                          (segment) =>
                                            segment["id"] === e.target.value
                                        );
                                        if (index !== -1) {
                                          setSelectedSegmentFiterData(
                                            segmentList[index]["filters"]
                                          );
                                          setSegementFilterFieldList([]);
                                        }
                                        resetFilterData();
                                        setSegmentError(false);
                                      }}
                                      style={{
                                        width: "100%",
                                        height: "30px",
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        borderRadius: "0px",
                                      }}
                                    >
                                      {segmentList.length === 0 && (
                                        <MenuItem disabled>
                                          {getLocalizedText(
                                            "no_segments_found"
                                          )}
                                        </MenuItem>
                                      )}
                                      {segmentList.map((seg) => (
                                        <MenuItem key={seg.id} value={seg}>
                                          {seg.title} (
                                          {dataRenderer(seg.project?.name)})
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {segmentError && (
                                      <Alert
                                        severity="warning"
                                        style={{
                                          padding: "0px 10px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Please select a segment
                                      </Alert>
                                    )}
                                  </FormControl>
                                  {/* {Object.keys(segment).length === 0 &&
                            (
                              <MoreHoriz
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  initSegmentFilterData();
                                }}
                                color="primary"
                              />
                            )} */}
                                </SelectColumn>

                                {Object.keys(segment).length !== 0 && (
                                  <>
                                    <SegmentBoxLabel>
                                      Description:
                                    </SegmentBoxLabel>
                                    <Label
                                      style={{
                                        fontSize: "12px",
                                        marginRight: "12px",
                                      }}
                                    >
                                      {dataRenderer(segment.desc)}
                                    </Label>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SegmentBoxLabel
                                        style={{
                                          marginRight: "4px",
                                        }}
                                      >
                                        Filters:
                                      </SegmentBoxLabel>{" "}
                                      <Preview
                                        color={"primary"}
                                        style={{
                                          cursor:
                                            Object.keys(segment).length === 0 ||
                                            segmentRadio !== 0
                                              ? "not-allowed"
                                              : "pointer",
                                          pointerEvents:
                                            Object.keys(segment).length === 0 ||
                                            segmentRadio !== 0
                                              ? "none"
                                              : "auto",
                                          opacity:
                                            Object.keys(segment).length === 0 ||
                                            segmentRadio !== 0
                                              ? 0.5
                                              : 1,
                                        }}
                                        fontSize="small"
                                        onClick={() => {
                                          if (
                                            Object.keys(segment).length === 0 ||
                                            segmentRadio !== 0
                                          ) {
                                            return;
                                          }
                                          setViewFiltersOpen(true);
                                        }}
                                      />
                                    </Box>
                                  </>
                                )}
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: "10px",
                                  width: "100%",
                                  rowGap: "10px",
                                }}
                              >
                                <Input
                                  required={
                                    segmentRadio === 1 &&
                                    getCampaignTypeName().toLowerCase() !==
                                      "offline" &&
                                    getCampaignTypeName().toLowerCase() !== ""
                                  }
                                  type="file"
                                  onClick={(e) => (e.target.value = null)}
                                  accept={".csv"}
                                  onChange={(event) => handleFileUpload(event)}
                                  disabled={segmentRadio !== 1}
                                />
                                {showFileErrorMessage && (
                                  <Label style={{ color: "red" }}>
                                    {fileErrorMessage}
                                  </Label>
                                )}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </SelectColumn>

                      {renderFieldSteps()}
                    </InputContainer>
                  );
                } else if (
                  stepCount === 2 &&
                  getCampaignTypeName(selectedCampaignTypeID).toLowerCase() ===
                    "email"
                ) {
                  return (
                    <InputContainer>
                      <EmailScheduleComponent
                        selectedSubType={selectedSubType}
                        setEmailDateData={(name, value) => {
                          setEmailDateData({ ...emailDateData, [name]: value });
                        }}
                        setRecurringType={(recurringType) => {
                          setEmailDateData((prevState) => ({
                            ...prevState,
                            recurring_type: recurringType,
                            every: "",
                          }));
                        }}
                        emailDateData={emailDateData}
                      />
                    </InputContainer>
                  );
                } else if (
                  stepCount === 2 &&
                  getCampaignTypeName(selectedCampaignTypeID).toLowerCase() ===
                    "whatsapp"
                ) {
                  return (
                    <InputContainer>
                      <WhatsappScheduleComponent
                        setScheduleOption={setWhatsappScheduleOption}
                        scheduleOption={whatsappScheduleOption}
                        setScheduleDateTime={setWhatsappScheduleDateTime}
                        setSelectedDate={setSelectedDate}
                        selectedDate={selectedDate}
                        setSelectedTime={setSelectedTime}
                        selectedTime={selectedTime}
                        setTimeSlot={setTimeSlot}
                        timeSlot={timeSlot}
                        setTimezone={setTimezone}
                        timezone={timezone}
                      />
                    </InputContainer>
                  );
                }
              })()}
            </Container>
            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500, bgcolor: "#f9f9fc" }}
            >
              <Toolbar style={{ width: 500, position: "relative" }}>
                <ButtonWrapper>
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <Button
                    type="button"
                    onClick={() => {
                      if (stepCount === 0) {
                        analytics.triggerEvent(
                          4625000246,
                          "cancel_button_tap",
                          "create_campaign_form",
                          "cancel_button",
                          {}
                        );
                        handleClose();
                      } else {
                        analytics.triggerEvent(
                          4625000248,
                          "previous_button_tap",
                          "create_campaign_form",
                          "previous_button",
                          {}
                        );
                        handlePrevious();
                      }
                    }}
                    variant="outlined"
                    color="primary"
                    style={{
                      textTransform: "none",
                      width: "120px",
                      fontWeight: "bold",
                    }}
                  >
                    {stepCount === 0
                      ? getLocalizedText("cancel")
                      : getLocalizedText("prev")}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      width: "120px",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      if (stepCount < 2) {
                        analytics.triggerEvent(
                          4625000247,
                          "next_button_tap",
                          "create_campaign_form",
                          "next_button",
                          {}
                        );
                      } else {
                        analytics.triggerEvent(
                          4625000249,
                          "create_button_tap",
                          "create_campaign_form",
                          "create_button",
                          {}
                        );
                      }
                    }}
                  >
                    {getCampaignTypeName(
                      selectedCampaignTypeID
                    ).toLowerCase() === "email" // ||     //hiding 3rd step for whatsapp for now (schedule step)
                      ? // getCampaignTypeName(
                        //   selectedCampaignTypeID
                        // ).toLowerCase() === "whatsapp"
                        stepCount < 2
                        ? getLocalizedText("next")
                        : getLocalizedText("create")
                      : stepCount === 0
                      ? getLocalizedText("next")
                      : getLocalizedText("create")}
                  </Button>
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </ModelWrapper>
        ) : (
          <MessageWrapper>
            <Message>Campaign created successfully!</Message>
            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        )}
      </Box>
      {viewFiltersOpen && (
        <ViewSegmentFiltersModal
          open={viewFiltersOpen}
          setOpen={setViewFiltersOpen}
          filterData={segment.filters ? segment.filters : {}}
        />
      )}
      {openFilterSelectionModal && (
        <CampaignFilterModal
          open={openFilterSelectionModal}
          setOpen={setOpenFilterSelectionModal}
          dateRangeFilterList={dateRangeFilterList}
          setDateRangeFilterList={setDateRangeFilterList}
          numRangeFilterList={numRangeFilterList}
          setNumRangeFilterList={setNumRangeFilterList}
          selectFieldDataList={selectFieldDataList}
          setSelectFieldDataList={setSelectFieldDataList}
          filterList={filterList}
          setFilterList={setFilterList}
          fieldList={segmentFilterFieldList}
        />
      )}
    </>
  );
};

export default observer(CreateCampaignModal);
