import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, Modal, Typography, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button, makeStyles } from "@material-ui/core";
import rootStore from "../../stores/RootStore";
import { getLocalizedText } from "../../Functions";
import {
  editContactApi,
  editLeadApi,
  getDelhiveryTrackOrderApi,
} from "../../Api";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  margin: "auto",
  backgroundColor: "white",
  height: "fit-content",
  maxHeight: "95vh",
  width: "500px",
}));

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  height: "fit-content",
  width: "500px",
  border: "none",
  outline: "none",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px #0000000d, 0px 3px 20px #185dd21f",
  border: "1px solid #c5d7f1",
  borderRadius: "8px",
  overflow: "hidden",
}));

const Message = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "12px",
  fontFamily: "Open Sans",
  color: "red",
  marginRight: "10px",
}));

const SuccessMessage = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Open Sans",
  color: "#4d4e4f",
  margin: 0,
  textAlign: "center",
}));

const MessageWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
  alignItems: "center",
}));

const DelhiveryTrackOrderDialog = (props) => {
  const { userStore, authStore } = rootStore;
  const columnsList = [...userStore.AllColumnsList];
  const [updateLeadHasError, setUpdateLeadHasError] = useState(false);
  const [waybillError, setWaybillError] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const getWaybillNumber = () => {
    if (props.responseMapping.hasOwnProperty("waybill")) {
      let waybillColumnID = props.responseMapping.waybill;
      const index = columnsList.findIndex(
        (column) => column["id"] === waybillColumnID
      );

      if (index !== -1) {
        if (columnsList[index]["is_custom_param"]) {
          let custom_data = props.data.hasOwnProperty("custom_params")
            ? JSON.parse(props.data["custom_params"])
            : {};

          let newKey = columnsList[index]["field"].replace(
            "custom_params_",
            ""
          );
          return custom_data.hasOwnProperty(newKey) ? custom_data[newKey] : "";
        }
        return props.data.hasOwnProperty(columnsList[index]["field"])
          ? props.data.columnsList[index]["field"]
          : "";
      }
      return "";
    }
    return "";
  };

  const init = async () => {
    const waybillNumber = getWaybillNumber();
    if (waybillNumber !== "") {
      const queryParams = {
        waybill: waybillNumber,
      };

      const response = await getDelhiveryTrackOrderApi({
        leadID: props.data.id,
        queryParams: queryParams,
      });

      let normalFieldsPayload = {};
      let customFieldsPayload = {};
      let contactFieldsPayload = {};

      function checkAndAssign(key, value) {
        if (typeof value !== "object") {
          if (props.statusMapping.hasOwnProperty(key)) {
            let index = columnsList.findIndex(
              (column) => column["id"] === props.statusMapping[key]
            );
            if (index !== -1) {
              if (
                columnsList[index]["contact_column"] !== null &&
                columnsList[index]["contact_column"] !== ""
              ) {
                contactFieldsPayload[columnsList[index]["field"]] = value;
              }
              if (columnsList[index]["is_custom_param"]) {
                let new_key_name = columnsList[index]["field"].replace(
                  "custom_params_",
                  ""
                );
                customFieldsPayload[new_key_name] = value;
              } else {
                normalFieldsPayload[columnsList[index]["field"]] = value;
              }
            }
          }
        }
      }

      //storing the whole response in delhivery_status_response column
      customFieldsPayload["delhivery_status_response"] =
        JSON.stringify(response);

      //update lead fields with mapping from integrations
      if (
        response.hasOwnProperty("ShipmentData") &&
        Array.isArray(response.ShipmentData) &&
        response.ShipmentData.length > 0
      ) {
        if (Object.keys(props.statusMapping).length !== 0) {
          const shipment = response.ShipmentData[0]?.Shipment;

          // looping through top-level keys
          if (shipment && typeof shipment === "object") {
            Object.keys(shipment).forEach((shipmentKey) => {
              checkAndAssign(shipmentKey, shipment[shipmentKey]);
            });
          }

          // looping through packages array and objects nested inside
          const status = shipment?.Status;
          if (status && typeof status === "object") {
            Object.keys(status).forEach((statusKey) => {
              checkAndAssign(statusKey, status[statusKey]);
            });
          }
        }
      }
      //update contact if needed
      if (Object.keys(contactFieldsPayload).length > 0) {
        if (
          props.data["contact_id"] !== null &&
          props.data["contact_id"] !== ""
        ) {
          let response = await editContactApi({
            contactID: props.data["contact_id"],
            payload: contactFieldsPayload,
          });
          if (response.hasError()) {
            setUpdateLeadHasError(true);
          }
        }
      }

      const updateLeadDetails = async () => {
        let payload = {
          ...normalFieldsPayload,
          custom_params: { ...customFieldsPayload },
        };
        try {
          let response = await editLeadApi({
            id: props.data.id,
            data: payload,
          });
          props.setRefreshLeadDetails(true);
          setIsSubmitSuccess(true);
        } catch (error) {
          setUpdateLeadHasError(true);
        }
      };

      updateLeadDetails();
    } else {
      setIsSubmitSuccess(true);
      setWaybillError(true);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Container>
            <Box>
              {!isSubmitSuccess ? (
                !updateLeadHasError ? (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px",
                    }}
                  >
                    <CircularProgress
                      style={{ width: "22px", height: "22px" }}
                    />
                    <Message style={{ color: "#4D4E4F", fontSize: "16px" }}>
                      Getting order status...
                    </Message>
                  </Box>
                ) : (
                  <Box
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "30px",
                    }}
                  >
                    <Message style={{ fontSize: "16px" }}>
                      {getLocalizedText("something_went_wrong")}
                    </Message>
                    <Button
                      onClick={handleClose}
                      type="button"
                      variant="outlined"
                      color="default"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "100px",
                      }}
                    >
                      {getLocalizedText("close")}
                    </Button>
                  </Box>
                )
              ) : waybillError ? (
                <Box sx={{ padding: "20px" }}>
                  <MessageWrapper style={{ marginBottom: "0px" }}>
                    <Message style={{ fontSize: "16px" }}>
                      {getLocalizedText("waybill_number_not_found")}
                    </Message>
                    <Button
                      onClick={handleClose}
                      type="button"
                      variant="contained"
                      color="default"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "100px",
                      }}
                    >
                      {getLocalizedText("close")}
                    </Button>{" "}
                  </MessageWrapper>
                </Box>
              ) : (
                <Box sx={{ padding: "20px" }}>
                  <MessageWrapper style={{ marginBottom: "0px" }}>
                    <SuccessMessage>
                      Order status updated successfully!
                    </SuccessMessage>
                    <Button
                      onClick={handleClose}
                      type="button"
                      variant="contained"
                      color="default"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "100px",
                      }}
                    >
                      {getLocalizedText("close")}
                    </Button>
                  </MessageWrapper>
                </Box>
              )}
            </Box>
          </Container>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(DelhiveryTrackOrderDialog);
