import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Modal,
  Select,
  MenuItem,
} from "@material-ui/core";
import { OutlinedInput } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../../Functions";
import { Typography } from "@mui/material";
import { myTheme } from "../../../themeUtils";
import MuiAlert from "@mui/material/Alert";
import analytics from "../../../Analytics";
import { addProductInventoryApi } from "../../../Api";
import { inventorySourceList } from "../../../Db";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  width: 420px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const ButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const CustomizedDatePicker = styled(DatePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
  }
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const AddInventoryModal = (props) => {
  const classes = useStyles();
  const [details, setDetails] = useState({
    units: "",
    source: "",
    remarks: "",
    total_price: "",
  });
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (parseInt(details.units) < 0) {
      //if negative value, checking if current total units will go below 0
      let subtractedTotalUnits =
        parseInt(props.productDetails.total_units) + parseInt(details.units);

      if (subtractedTotalUnits < 0) {
        setErrorMessage(
          "The Units value exceeds the total available units. Please enter a valid value!"
        );
        setIsSubmitFail(true);
        return;
      }
    }

    let payload = {
      quantity: details["units"],
      source: details["source"],
      remarks: details["remarks"],
    };

    if (details["total_price"] !== "") {
      payload["total_price"] = details["total_price"];
    }

    let response = await addProductInventoryApi({
      payload: payload,
      productID: props.productDetails.id,
    });

    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setIsSubmitSuccess(true);
      props.setRefresh(true);
    }
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitSuccess ? (
          <ModelContainer>
            <Box>
              <Container component={"form"} onSubmit={handleSubmit}>
                <AppBar
                  component={"nav"}
                  elevation={0}
                  position="sticky"
                  color="inherit"
                  sx={{
                    top: 0,
                    bottom: "auto",
                    width: "100%",
                    right: 0,
                    borderRadius: "8px 8px 0px 0px",
                  }}
                >
                  <Toolbar
                    style={{
                      padding: "16px",
                    }}
                  >
                    <HeaderWrapper>
                      <Header>{getLocalizedText("add_inventory")}</Header>
                      <SubHeader>
                        {getLocalizedText("fill_in_the_below_details")}
                      </SubHeader>
                    </HeaderWrapper>
                  </Toolbar>
                </AppBar>

                <Divider style={{ margin: "16px", marginTop: "0px" }} />

                <Box sx={{ padding: "0px 16px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>{getLocalizedText("units")}*</Label>
                        <OutlinedInput
                          required
                          type="number"
                          style={{ height: "30px" }}
                          inputProps={{
                            "aria-label": "weight",
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          value={details.units}
                          name={"units"}
                          placeholder={"Enter Value"}
                          onChange={handleChange}
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>{getLocalizedText("source")}*</Label>
                        <Select
                          required
                          classes={{
                            icon: classes.icon,
                          }}
                          displayEmpty
                          onChange={handleChange}
                          name="source"
                          value={details["source"]}
                          disableUnderline
                          variant="outlined"
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",
                            borderRadius: "4px",
                          }}
                        >
                          {inventorySourceList.map((item) => {
                            return (
                              <MenuItem
                                value={item.value}
                                style={{ fontSize: "12px" }}
                              >
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Comments*</Label>
                        <OutlinedInput
                          required
                          multiline={true}
                          rows={3}
                          inputProps={{
                            min: 0,
                            "aria-label": "weight",
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          value={details.remarks}
                          name={"remarks"}
                          type={"text"}
                          placeholder={"Enter Value"}
                          onChange={handleChange}
                        />
                      </InputWrapper>
                    </Grid>
                    {/* hiding total price */}
                    {/* <Grid item xs={12}>
                      <InputWrapper>
                        <Label
                          style={{
                            color: details.units < 0 ? "lightgray" : "#4d4e4f",
                          }}
                        >
                          {getLocalizedText("total_price")}
                        </Label>
                        <OutlinedInput
                          disabled={details.units < 0}
                          type="number"
                          style={{ height: "30px" }}
                          inputProps={{
                            min: 0,
                            step: 0.1,
                            "aria-label": "weight",
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          value={details.total_price}
                          name={"total_price"}
                          placeholder={"Enter Value"}
                          onChange={handleChange}
                        />
                      </InputWrapper>
                    </Grid> */}
                  </Grid>
                </Box>

                <AppBar
                  position="static"
                  color="inherit"
                  elevation={0}
                  sx={{
                    top: "auto",
                    bottom: 0,
                    width: "100%",
                    borderRadius: "0px 0px 8px 8px",
                  }}
                >
                  <Toolbar
                    style={{
                      padding: "16px",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    {isSubmitFail && (
                      <ErrorAlert
                        onClose={(e) => {
                          e.stopPropagation();
                          setIsSubmitFail(false);
                          setErrorMessage("");
                        }}
                        severity="error"
                        sx={{ width: "100%" }}
                      >
                        {errorMessage}
                      </ErrorAlert>
                    )}
                    <Grid container>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "flex-end",
                            gap: "16px",
                          }}
                        >
                          <Button
                            onClick={() => {
                              props.setOpen(false);
                            }}
                            variant="contained"
                            color="default"
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              width: "fit-content",
                              textTransform: "none",
                            }}
                          >
                            {getLocalizedText("cancel")}
                          </Button>
                          <Button
                            onClick={() => {}}
                            type="submit"
                            variant="contained"
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              width: "fit-content",
                              textTransform: "none",
                              ...myTheme.Button.btnBlue,
                            }}
                          >
                            {getLocalizedText("submit")}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Toolbar>
                </AppBar>
              </Container>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "16px",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px" }}>
              <Message>Inventory added successfully!</Message>

              <Button
                onClick={() => {
                  analytics.triggerEvent(
                    4625000259,
                    "close_button_tap",
                    "update_stage_form",
                    "close_button",
                    { order: props.scope }
                  );
                  handleClose();
                }}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default AddInventoryModal;
