import gifImg1 from "./assets/images/online-learning.gif";
import gifImg2 from "./assets/images/gifs/gifImg2.gif";
import gifImg3 from "./assets/images/gifs/gifImg3.gif";
import gifImg4 from "./assets/images/gifs/gifImg4.gif";
import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import rootStore from "./stores/RootStore";
import moment from "moment";
import { toJS } from "mobx";
import enUsData from "./text_utilities/en-US.json";
import Cookies from "js-cookie";
import {
  accountIDsForNewPaginationAccess,
  defaultTableWidgetSize,
  whatsappBusinessAccessAccountIDs,
  domainName,
  userIDsForWABAAccess,
} from "./Config";
import { leadPresetColorsWithLabels } from "./Db";
import strings from "./localization/localization";

const { authStore, userStore } = rootStore;
//created separate file to store all the fuctions used in our different pages and sections
const Gif = styled.img`
  height: 100%;
  width: 100%;
  display: unset;
`;

const Days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const CustomDotLogin = ({ index, onClick, active }) => {
  return (
    <button
      className={active ? "activelogin" : "inactivelogin"}
      onClick={() => {
        onClick();
        ChangeIndex(index);
      }}
    ></button>
  );
};

let currIndex = -1;

function ChangeIndex(i) {
  currIndex = i;
  let element = <Gif src={gifImg1} />;
  if (currIndex == 2) {
    element = <Gif src={gifImg1} />;
  } else if (currIndex == 3) {
    element = <Gif src={gifImg2} />;
  } else if (currIndex == 4) {
    element = <Gif src={gifImg3} />;
  } else {
    element = <Gif src={gifImg4} />;
  }
  document.getElementById("default_gif").style.display = "none";
  ReactDOM.render(element, document.getElementById("gif_image"));
}

const validateUnmaskColumns = () => {
  let flag = false;
  let role = authStore.getCurrentProjectRoleDetails();
  if (role.hasOwnProperty("unmask_columns")) {
    if (role.unmask_columns === false) {
      if (userStore.UserData !== null) {
        if (userStore.UserData.unmask_columns === true) {
          flag = true;
        }
      }
    } else {
      flag = true;
    }
  }
  return flag;
};

const validateUnmaskColumnsAtProjectLevel = (role) => {
  if (role !== null) {
    return role.unmask_columns;
  } else {
    return userStore.UserData.unmask_columns;
  }
};

// below function will return date time string if it contains time also otherwise it will return only date (this is specifically used for view reports page only)
function getLocalDateTime(dateString) {
  const momentObj = moment(dateString);

  if (momentObj.isValid()) {
    if (
      momentObj.get("hour") === 0 &&
      momentObj.get("minute") === 0 &&
      momentObj.get("second") === 0
    ) {
      return momentObj.format("YYYY-MM-DD"); // Date only
    } else {
      return momentObj.format("YYYY-MM-DD HH:mm A"); // Date and time
    }
  }

  return "Invalid date";
}

const dateTimeHandler = (value) => {
  let date = new Date(0);
  date.setUTCSeconds(value);
  let newDate = date.toString();
  let dateString = moment(newDate).format("YYYY-MM-DD HH:mm A");
  if (dateString.includes("05:30 AM")) {
    dateString = moment(newDate).format("YYYY-MM-DD");
  }
  return dateString;
};

function IsoToLocalTime(isoString) {
  if (isoString !== null) {
    const localDateTime = moment.utc(isoString).local();
    return localDateTime.format("YYYY-MM-DD hh:mm A");
  } else {
    return "-";
  }
}

function IsoToLocalDate(isoString) {
  if (isoString !== null) {
    const localDateTime = moment.utc(isoString).local();
    return localDateTime.format("YYYY-MM-DD");
  } else {
    return "-";
  }
}
// Function to check if a date is more than X days ago
function isMoreThanXDaysAgo(days, dateString) {
  const xDaysAgo = moment().subtract(days, "days").startOf("day");
  // const xDaysAgo = moment().subtract(days, "minutes");
  const updatedAt = moment.utc(dateString).local();
  let flag = updatedAt.isBefore(xDaysAgo);
  return flag;
}

const dateHandler = (value) => {
  let d = new Date(0);
  d.setUTCSeconds(value);
  let newDate = d.toString();
  newDate = moment(newDate).format("YYYY-MM-DD HH:mm A");
  return newDate;
};
const tabsDateHandler = (value) => {
  let d = new Date(0);
  d.setUTCSeconds(value);
  let newDate = d.toString();
  newDate = moment(newDate).format("YYYY-MM-DD");
  return newDate;
};
const convertToUTC = (value) => {
  let newDate = new Date(value);
  newDate = newDate.toUTCString();
  return newDate;
};
function ampm(time) {
  if (time.value !== "") {
    var hours = time.split(":")[0];
    var minutes = time.split(":")[1];
    var suffix = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var displayTime = hours + ":" + minutes + " " + suffix;
    return displayTime;
  }
  return;
}

const validateDisabledFeature = (str) => {
  let flag = true;
  if (
    authStore.disabled_features !== null &&
    authStore.disabled_features !== undefined
  ) {
    const list = toJS(authStore.disabled_features);
    // console.log(list.includes(str), str, list);
    if (list.includes(str)) {
      flag = false;
    }
  }
  return flag;
};
const validateDisabledSubFeature = (str) => {
  let flag = true;
  if (authStore.disabled_sub_features !== null) {
    if (authStore.disabled_sub_features.includes(str)) {
      flag = false;
    }
  }
  return flag;
};

const IsSameUser = (id) => {
  const uid = localStorage.getItem("uid");
  return uid === id;
};

const currentDayNameGetter = () => {
  let d = new Date();
  return Days[d.getDay()];
};
const getLocalTimeFromIso = (isoString) => {
  if (isoString !== null && isoString !== "") {
    let localTime = moment.utc(isoString).local();
    localTime = localTime.format("HH:mm:ss");
    localTime = moment(localTime, "HH:mm:ss");
    return localTime;
  } else {
    return null;
  }
};
const isUnderWorkSchedule = () => {
  // let flag = false;
  // if (userStore.UserData !== null) {
  //   if (
  //     userStore.UserData.role === "admin" ||
  //     userStore.UserData.role === "owner"
  //   ) {
  //     flag = true;
  //   } else {
  //     if (authStore.currentProject !== null) {
  //       let list = authStore.currentProject;
  //       let today_day_name = "workday_" + currentDayNameGetter();
  //       if (list.hasOwnProperty(today_day_name)) {
  //         if (list[today_day_name]) {
  //           let start_time = getLocalTimeFromIso(list["work_start_time"]);
  //           let end_time = getLocalTimeFromIso(list["work_end_time"]);
  //           if (start_time !== null && end_time !== null) {
  //             let currentTime = moment().local();
  //             if (currentTime.isBetween(start_time, end_time)) {
  //               flag = true;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // return flag;
  return true;
};
const getLocalizedText = (key, scope = "global") => {
  if (strings.hasOwnProperty(key) === false) {
    return key;
  }
  return strings.formatString(
    strings[key],
    rootStore.userStore.projectLocalizationTextConfig
  );
};

const getLocalizedErrorText = (key, scope = "global") => {
  if (strings.hasOwnProperty(key) === false) {
    return null;
  }

  return strings.formatString(
    strings[key],
    rootStore.userStore.projectLocalizationTextConfig
  );
};

const getAccountLocalizedText = (key, scope = "global") => {
  if (strings.hasOwnProperty(key) === false) {
    return key;
  }
  return strings.formatString(
    strings[key],
    rootStore.userStore.accountLocalizationTextConfig
  );
};

const updateViewList = () => {
  let role = authStore.getCurrentProjectRole();
  if (userStore.UserData !== null) {
    if (
      userStore.UserData.role === "admin" ||
      userStore.UserData.role === "owner" ||
      role === "manager"
    ) {
      let list = [getLocalizedText("all_leads", "project"), "Current View"];
      userStore.updateViewList({ view: list[0], viewList: list });
    } else {
      let list = [getLocalizedText("my_leads", "project"), "Current View"];
      userStore.updateViewList({ view: list[0], viewList: list });
    }
  } else {
    let list = [getLocalizedText("my_leads", "project"), "Current View"];
    userStore.updateViewList({ view: list[0], viewList: list });
  }
};

//feature access validation related function

function checkFeaturePermission(featureName) {
  if (userStore.UserData !== null) {
    let accountRole = userStore.UserData.role;
    let projectRole = authStore.getCurrentProjectRole();
    consoleLogger("project role::::", projectRole);
    switch (featureName) {
      case "dashboard":
      case "leads":
        if (accountRole === "owner") {
          return true;
        } else {
          if (projectRole === "") {
            return false;
          } else {
            return true;
          }
        }
      case "assign_leads":
      case "fresh_lead":
      case "update_stage":
      case "reset_followup":
        if (accountRole === "admin" || accountRole === "owner") {
          return true;
        } else {
          if (projectRole !== "member") {
            return true;
          }
          return false;
        }
      case "raw_leads":
      case "banners":
      case "files":
      case "contacts":
      case "entities":
      case "move_to_project":
      case "imports_customer":
      case "imports_product":
        if (accountRole === "admin" || accountRole === "owner") {
          return true;
        }
        return false;
      case "reports":
      case "imports":
        if (accountRole === "admin" || accountRole === "owner") {
          return true;
        } else {
          if (projectRole === "manager" || projectRole === "lead") {
            return true;
          }
          return false;
        }
      case "export_leads_listing":
      case "terms_and_conditions":
        if (accountRole === "owner") {
          return true;
        } else {
          return false;
        }
      case "billing":
      case "administrator_users":
      case "target":
      case "marketing":
      case "assign_customer":
        if (accountRole === "admin" || accountRole === "owner") {
          return true;
        } else {
          return false;
        }
      case "lead_listing_delete":
        if (accountRole === "owner") {
          return true;
        } else {
          if (projectRole !== "member") {
            return true;
          }
          return false;
        }
      case "lead_details_delete":
      case "lead_details_update_status":
        if (projectRole !== "member") {
          return true;
        }
        return false;

      case "lead_details_update_source":
      case "notification_assign_lead":
        if (accountRole === "admin" || accountRole === "owner") {
          return true;
        } else {
          if (projectRole === "manager") {
            return true;
          }
          return false;
        }
      case "dashboard_add_widget_filter":
        if (accountRole === "admin" || accountRole === "owner") {
          return true;
        } else {
          if (projectRole === "manager" || projectRole === "lead") {
            return true;
          }
          return false;
        }
      case "assign_leads_member_filter":
        if (
          accountRole === "owner" ||
          accountRole === "admin" ||
          projectRole === "manager"
        ) {
          return true;
        } else {
          return false;
        }
      case "transfer_lead":
        if (projectRole === "member") {
          return true;
        } else {
          return false;
        }
      case "member_entities":
        if (accountRole === "member") {
          return true;
        } else {
          return false;
        }
      default:
      // code block
    }
  }
}

const dataRenderer = (val) => {
  if (val !== null && val !== "" && val !== undefined) {
    return val;
  } else {
    return "-";
  }
};
//**********End***************************** */

const getLocalTimeFromIsoString = (isoDateString) => {
  if (isoDateString !== null && isoDateString !== undefined) {
    return moment.utc(isoDateString).local().format("YYYY-MM-DD HH:mm:ss");
  } else {
    return "-";
  }
};

const consoleLogger = (indication_text = "", value) => {
  if (process.env.NODE_ENV !== "production") {
    console.log(indication_text, value);
  }
};

//below function is specifically used for leads listing pages
const isLastContactedToday = (isoStringTimestamp) => {
  if (isoStringTimestamp !== null) {
    const today = moment().startOf("day"); // Get today's date, time set to midnight
    const epochDate = moment(isoStringTimestamp).startOf("day"); // Convert ISO string to moment and set time to midnight

    return epochDate.isSame(today, "day");
  }
  return false;
};

function sortAndAssignWidthColumnSetup(columnMeta) {
  let tempColumns = [...columnMeta];

  tempColumns = tempColumns
    .filter(
      (item) => item["filterable"] && rootStore.userStore.isColumnVisible(item)
    )
    .slice();
  let allFields = tempColumns
    .filter((item) => item["child_of"] === null)
    .slice();
  allFields.sort((a, b) =>
    a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
  );

  let parentFields = tempColumns.filter((item) => item["parent"]).slice();
  let parentChildMap = {};
  for (let item of parentFields) {
    let parentId = item["id"];
    let children = tempColumns
      .filter(
        (child) =>
          child["child_of"] !== null && child["child_of"]["id"] === parentId
      )
      .slice()
      .sort((a, b) =>
        a["headerName"]
          .toLowerCase()
          .localeCompare(b["headerName"].toLowerCase())
      );
    parentChildMap[parentId] = children;
  }

  for (let parentId in parentChildMap) {
    let parentIndex = allFields.findIndex(
      (element) => element["id"] === parentId
    );
    if (parentIndex !== -1) {
      let children = parentChildMap[parentId];
      allFields.splice(parentIndex + 1, 0, ...children);
    }
  }
  for (const item of allFields) {
    if (item.filter_type === "normal" || item.filter_type === "") {
      item.width = 1;
      item.modifyWidth = true;
    } else if (item.filter_type === "range" || item.filter_type === "list") {
      item.width = 2;
      item.modifyWidth = false;
    }
  }

  for (let i = 0; i < allFields.length; i++) {
    let currentItem = allFields[i];

    if (currentItem.modifyWidth) {
      if (i === allFields.length - 1) {
        currentItem.width = 2;
        currentItem.modifyWidth = false;
      } else {
        let nextItem = allFields[i + 1];

        if (nextItem.width === 2) {
          currentItem.width = 2;
          currentItem.modifyWidth = false;
        } else if (nextItem.width === 1) {
          currentItem.width = 1;
          currentItem.modifyWidth = false;
          nextItem.modifyWidth = false;
        }
      }
    }
  }
  return allFields;
}
function getCurrentEpochTime() {
  // Get the current time in seconds since epoch using Moment.js
  const epochSeconds = moment().unix();
  return epochSeconds;
}

function capitalizeWord(input) {
  const words = input.split("_");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return capitalizedWords.join(" ");
}
function isValidDateTime(dateTimeString) {
  const date = new Date(dateTimeString);

  // Check if the date object is valid and not equal to the default invalid date
  return !isNaN(date) && date.toString() !== "Invalid Date";
}
function isNumber(value) {
  return typeof value === "number" && !Number.isNaN(value);
}

function getIsoString(date) {
  const momentObj = moment(date);
  const isoString = momentObj.toISOString();
  return isoString;
}

// function to get ep to fetch data for dropdowns
function replacePlaceholdersWithProjectID(inputString) {
  let result = `projects/${rootStore.authStore.projectId}` + inputString;

  return result;
}

function createLeadReplaceFilterEpWithProject(inputString, pid) {
  let result = `projects/${pid}` + inputString;

  return result;
}

function replacePlaceholder(inputString, key, replacement) {
  const regex = new RegExp(`{${key}}`, "g");
  return inputString.replace(regex, replacement);
}

function replacePlaceholders(inputString) {
  const replacements = {
    project_id: rootStore.authStore.projectId,
    account_id: rootStore.userStore.UserData.account.id,
    team_id:
      rootStore.authStore.teamId !== null
        ? rootStore.authStore.teamId !== "All"
          ? rootStore.authStore.teamId
          : ""
        : null,
    user_id: rootStore.userStore.UserData.id,
  };
  let result = inputString;
  for (const key in replacements) {
    result = replacePlaceholder(result, key, replacements[key]);
  }
  return result;
}

function createLeadReplaceFilterPlaceHolderEp(inputString, pid, teamID) {
  const replacements = {
    project_id: pid,
    account_id: rootStore.userStore.UserData.account.id,
    team_id: teamID,
    user_id: rootStore.userStore.UserData.id,
  };
  let result = inputString;
  for (const key in replacements) {
    result = replacePlaceholder(result, key, replacements[key]);
  }
  return result;
}

const getSlug = (filterInput) => {
  if (filterInput.charAt(0) === "/") {
    return replacePlaceholdersWithProjectID(filterInput);
  } else if (filterInput.startsWith("ep:")) {
    let inputString = filterInput.replace("ep:", "");
    if (inputString.charAt(0) === "/") {
      inputString = inputString.slice(1);
    }
    return replacePlaceholders(inputString);
  }
  return filterInput;
};

//***********END*********************** */

const getJobTypeDisplayName = (key) => {
  switch (key) {
    case "reassign_leads":
      return "Reassign Leads";
    case "export_leads":
      return "Export Leads";
    case "export_leads_dialer_mcube":
      return "Export Leads For Mcube";
    case "export_leads_dialer_ozonetel":
      return "Export Leads For OzoneTel";
    case "delete_leads":
      return "Delete Leads";
    default:
      return "-";
  }
};

//*********************functions to get stage or status color code for widgets*************** */
const getUniqueStatusColorCodeList = () => {
  const uniqueColors = new Set(
    rootStore.authStore.statusList
      .map((status) => status.color)
      .filter((color) => color !== null)
  );
  return Array.from(uniqueColors);
};
const getUniqueStagesColorCodeList = () => {
  const uniqueColors = new Set(
    rootStore.authStore.stageList
      .map((stage) => stage.color)
      .filter((color) => color !== null)
  );
  return Array.from(uniqueColors);
};

const getRandomColor = (uniqueColors) => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  if (!uniqueColors.includes(color)) {
    return color;
  } else {
    getRandomColor(uniqueColors);
  }
};
const getStatusColorCode = (key) => {
  let uniqueColors = getUniqueStatusColorCodeList();
  if (uniqueColors.length === 0) {
    return getRandomColor(uniqueColors);
  } else {
    let index = rootStore.authStore.statusList.findIndex(
      (status) => status["status"] === key
    );
    if (index !== -1) {
      let color = rootStore.authStore.statusList[index]["color"];
      if (color !== null && color !== "") {
        return color;
      } else {
        return getRandomColor(uniqueColors);
      }
    } else {
      return getRandomColor(uniqueColors);
    }
  }
};

const getStageColorCode = (key) => {
  let uniqueColors = getUniqueStagesColorCodeList();
  if (uniqueColors.length === 0) {
    return getRandomColor(uniqueColors);
  } else {
    let index = rootStore.authStore.stageList.findIndex(
      (stage) => stage["stage"] === key
    );
    if (index !== -1) {
      let color = rootStore.authStore.stageList[index]["color"];
      if (color !== null && color !== "") {
        return color;
      } else {
        return getRandomColor(uniqueColors);
      }
    } else {
      return getRandomColor(uniqueColors);
    }
  }
};
//*********************************END*************************************************** */

//**********************Function to set cookie*************** */
const setCookie = (cookieName, cookieValue, options) => {
  Cookies.set(cookieName, cookieValue, {
    domain: domainName,
    path: "/",
    expires: 7,
    ...options,
  });
};
//**********************END******************************** */

const isProjectAndAccountIdMatched = ({ accountID, projectID }) => {
  return (
    accountID === rootStore.userStore.UserData.account.id &&
    projectID === rootStore.authStore.projectId
  );
};

const isAccountIdMatched = ({ accountID }) => {
  return accountID === rootStore.userStore.UserData.account.id;
};

//below functions are related to dashboard widgets

const isMoreThanFiveMinutes = (datetime) => {
  const now = moment();
  const then = moment(datetime);
  const diffMinutes = now.diff(then, "minutes");

  return diffMinutes > 5;
};

const getCombinedWidgetAndAccountID = (widgetID) => {
  return rootStore.userStore.UserData.account.id + widgetID;
};

//End

const isMoreThanFifteenMinutes = (datetime) => {
  const now = moment();
  const then = moment(datetime);
  const diffMinutes = now.diff(then, "minutes");
  return diffMinutes > 15;
};

const isToday = (dateTimeString) => {
  const dateTime = moment(dateTimeString);
  return dateTime.isSame(moment(), "day");
};

const isAfterCurrentTime = (dateTimeString) => {
  const dateTime = moment(dateTimeString);
  return dateTime.isAfter(moment());
};

const isBeforeCurrentTime = (dateTimeString) => {
  const dateTime = moment(dateTimeString);
  return dateTime.isBefore(moment());
};

const getLocalTimeStringFromIsoDateString = (isoDateString) => {
  if (isoDateString !== null && isoDateString !== undefined) {
    return moment.utc(isoDateString).local().format("h:mm A");
  } else {
    return "-";
  }
};

const getClosestDateTime = (isoDateTime1, isoDateTime2) => {
  const dateTime1 = moment(isoDateTime1);
  const dateTime2 = moment(isoDateTime2);
  const currentMoment = moment();
  const diff1 = Math.abs(dateTime1.diff(currentMoment));
  const diff2 = Math.abs(dateTime2.diff(currentMoment));
  if (diff1 < diff2) {
    return isoDateTime1;
  } else {
    return isoDateTime2;
  }
};

const getFilePattern = (fileType) => {
  if (fileType.includes("image")) {
    return ".jpg,.png,.jpeg,.svg";
  } else if (fileType.includes("pdf")) {
    return ".pdf";
  }
  return "";
};

//functions related to rotten lead
const isLeadRotten = (leadData) => {
  if (leadData["lead_stage"] !== null) {
    let index = rootStore.authStore.stageList.findIndex(
      (stage) =>
        stage["stage"].toLowerCase() === leadData["lead_stage"].toLowerCase()
    );
    if (index !== -1) {
      let stageData = rootStore.authStore.stageList[index];
      if (!isStageClosed(stageData)) {
        if (
          leadData["in_current_stage_from"] !== null &&
          stageData["rotting_in"] !== null &&
          stageData["rotting_in"] !== 0
        ) {
          let numberOfDays = getNumberOfDaysInCurrentStage(leadData);
          return numberOfDays > stageData["rotting_in"];
        }
      }
    }
  }
  return false;
};

const isStageClosed = (stageData) => {
  return (
    stageData["mark_as_closed"] ||
    stageData["mark_as_won"] ||
    stageData["mark_as_lost"]
  );
};

const getNumberOfDaysInCurrentStage = (leadData) => {
  const today = moment().startOf("day");
  let differenceInDays = today.diff(
    moment(leadData["in_current_stage_from"]).startOf("day"),
    "days"
  );
  return differenceInDays;
};
//*********end************** */

//*********************Function related to relative datetime and date string********** */

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const getDateTimeDescription = (isoDateString) => {
  try {
    const inputTime = moment(isoDateString).local().seconds(0);
    const currentTime = moment().local().seconds(0);
    if (!inputTime.isValid()) {
      return isoDateString;
    }
    const dayDiff = inputTime.diff(currentTime, "days");
    const absDayDiff = Math.abs(dayDiff);

    const inputDate = moment(isoDateString).local().startOf("day");
    const currentDate = moment().local().startOf("day");
    const dayDiffWithoutTime = inputDate.diff(currentDate, "days");

    if (dayDiffWithoutTime === 1) {
      return "Tomorrow";
    }

    if (dayDiffWithoutTime === -1) {
      return "Yesterday";
    }

    if (absDayDiff > 1) {
      return capitalizeFirstLetter(inputTime.from(currentTime));
    }

    const hourDiff = inputTime.diff(currentTime, "hours");
    const absHourDiff = Math.abs(hourDiff);

    if (absHourDiff > 0) {
      return capitalizeFirstLetter(inputTime.from(currentTime));
    }

    const minDiff = inputTime.diff(currentTime, "minutes", true);

    if (Math.abs(minDiff) <= 2) {
      return "Now";
    } else {
      return capitalizeFirstLetter(inputTime.from(currentTime));
    }
  } catch (error) {
    console.log(error);
    return isoDateString;
  }
};

const getDateDescription = (isoDateString) => {
  try {
    const inputDate = moment(isoDateString).local().startOf("day");
    const currentDate = moment().local().startOf("day");
    if (!inputDate.isValid()) {
      return isoDateString;
    }
    const dayDiff = inputDate.diff(currentDate, "days");

    if (dayDiff === 0) {
      return "Today";
    }

    if (dayDiff === 1) {
      return "Tomorrow";
    }

    if (dayDiff === -1) {
      return "Yesterday";
    }

    return capitalizeFirstLetter(inputDate.from(currentDate));
  } catch (error) {
    console.log(error);
    return isoDateString;
  }
};

//*******************************END************************************************* */

//****************function to check whethere date or datetime is in range*************** */
const isDateInRange = (min, max, input) => {
  const inputDate = moment(input.toString()).local().startOf("day");
  const minDate = moment(min).local().startOf("day");
  const maxDate = moment(max).local().startOf("day");
  return inputDate.isBetween(minDate, maxDate, null, "[]");
};
const isDateTimeInRange = (min, max, input) => {
  const minDatetime = moment(min).local().seconds(0);
  const maxDatetime = moment(max).local().seconds(0);
  const inputDatetime = moment(input.toString()).local().seconds(0);
  return inputDatetime.isBetween(minDatetime, maxDatetime, null, "[]");
};
//**********************************END*********************************************** */

const getTooltipOfLeadColor = (colorValue) => {
  if (colorValue !== undefined && colorValue !== null && colorValue !== "") {
    let index = leadPresetColorsWithLabels.findIndex(
      (color) => color["color"] === colorValue
    );
    if (index !== -1) {
      return leadPresetColorsWithLabels[index]["label"];
    }
  }
  return "-";
};

//***********************lead listing context menu functions***************** */

const getLeadAccessLevel = ({ leadData }) => {
  let accountRole =
    rootStore.userStore.UserData !== null
      ? rootStore.userStore.UserData["role"]
      : "";
  let userID =
    rootStore.userStore.UserData !== null
      ? rootStore.userStore.UserData["id"]
      : "";
  let projectRole = authStore.getCurrentProjectRole();
  let leadOwnerID = leadData["lead_owner"]["id"];
  if (accountRole === "owner") {
    return "owner";
  }
  if (leadOwnerID === userID) {
    return "owner";
  }
  if (projectRole === "manager") {
    return "manager";
  } else if (projectRole === "lead") {
    return "lead";
  } else if (projectRole === "member") {
    return "team";
  }
  return "";
};
const canEditLead = ({ leadData }) => {
  let accessLevel = getLeadAccessLevel({ leadData: leadData });
  if (leadData["lead_closed"]) {
    return false;
  } else {
    let account_role =
      rootStore.userStore.UserData !== null
        ? rootStore.userStore.UserData["role"]
        : "";
    if (account_role === "owner") {
      return true;
    }
    return leadData["access_edit"].includes(accessLevel);
  }
};

const isStatusLocked = ({ leadData }) => {
  let statusList = rootStore.authStore.statusList;
  let index = statusList.findIndex(
    (element) => element["status"] === leadData.lead_status
  );
  if (index !== -1) {
    return statusList[index]["lock"];
  } else {
    return false;
  }
};

const isSetLeadColorContextMenuDisabled = ({ leadData }) => {
  if (canEditLead({ leadData: leadData })) {
    return false;
  } else {
    return true;
  }
};

const isOpenCallLogContextMenuDisabled = ({ leadData }) => {
  if (canEditLead({ leadData: leadData })) {
    return isStatusLocked({ leadData: leadData });
  }
  return true;
};
//***************************************END******************************** */

const canShowSkypeCallIcon = ({ isPhone, value }) => {
  let dialer = rootStore.userStore.getOutboundDialer();
  if (dialer === "skype") {
    if (isPhone) {
      return value !== null && value !== "";
    }
  }
  return false;
};

const isTeckinfoDialer = () => {
  let outboundDialer = rootStore.userStore.getOutboundDialer();
  let inboundDialer = rootStore.userStore.getInboundDialer();

  return outboundDialer === "teckinfo" || inboundDialer === "teckinfo";
};

//********************icons column related helper functions */
const getLeadListingAvatarIconColor = ({ data }) => {
  if (data["is_customer"] === true) {
    return "#4caf50";
  }
  if (data["was_customer"] === true) {
    return "#ff9800";
  }
  return "#AAB0B7";
};

const getLeadListingAvatarTooltipText = ({ data }) => {
  if (data["is_customer"] === true) {
    return "Is Customer";
  }
  if (data["was_customer"] === true) {
    return "Was Customer";
  }

  return "Contact";
};
//********************************end************************ */

const getCustomerListingAvatarTooltipText = ({ data }) => {
  if (data["is_customer"] === true) {
    return "Is Customer";
  }
  if (data["was_customer"] === true) {
    return "Was Customer";
  }

  return "Customer";
};

const getCustomerListingAvatarIconColor = ({ data }) => {
  if (data["is_customer"] === true) {
    return "#4caf50";
  }
  if (data["was_customer"] === true) {
    return "#ff9800";
  }
  return "#AAB0B7";
};

//**********************update status in lead listing related functions********** */
const getUpdateFieldsCountInStageStatus = ({ statusName, leadData }) => {
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const stageStatusList = [...rootStore.authStore.stageStatusList];
  let count = 0;
  let currentLeadStage =
    leadData.hasOwnProperty("lead_stage") && leadData["lead_stage"] !== null
      ? leadData["lead_stage"]
      : "";
  let index = stageStatusList.findIndex(
    (item) =>
      item["lead_status"]["status"] === statusName &&
      item["lead_stage"]["stage"] === currentLeadStage
  );
  if (index !== -1) {
    if (
      stageStatusList[index].hasOwnProperty("update_fields") &&
      stageStatusList[index]["update_fields"] !== null
    ) {
      let updateFieldsData = stageStatusList[index]["update_fields"];
      updateFieldsData.forEach((data) => {
        let index = columnsList.findIndex(
          (column) => column["id"] === data["column_meta"]["id"]
        );
        if (index !== -1) {
          if (data["required"]) {
            count += 1;
          }
        }
      });
    }
  }
  return count;
};

const getUpdateFieldsCountInStatus = ({ statusName }) => {
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const statusList = [...rootStore.authStore.statusList];
  let count = 0;
  let index = statusList.findIndex((status) => status["status"] === statusName);
  if (index !== -1) {
    if (
      statusList[index].hasOwnProperty("update_fields") &&
      statusList[index]["update_fields"] !== null
    ) {
      let updateFieldsData = statusList[index]["update_fields"];
      updateFieldsData.forEach((data) => {
        let index = columnsList.findIndex(
          (column) => column["id"] === data["column_meta"]["id"]
        );
        if (index !== -1) {
          if (data["required"]) {
            count += 1;
          }
        }
      });
    }
  }
  return count;
};
const hasUpdateFields = ({ statusName, leadData }) => {
  let updateFieldsCountInStageSTatus = getUpdateFieldsCountInStageStatus({
    statusName,
    leadData,
  });
  if (updateFieldsCountInStageSTatus > 0) {
    return true;
  } else {
    let updateFieldsCountInStatus = getUpdateFieldsCountInStatus({
      statusName,
    });
    return updateFieldsCountInStatus > 0;
  }
};

const isAdditionalStatusDetailsRequired = ({ status, leadData }) => {
  return hasUpdateFields({ statusName: status, leadData: leadData });
};
//*******************************end****************************************** */

const hasReportAccess = () => {
  if (validateDisabledSubFeature("feature_reports") === false) {
    return false;
  }
  let projectRole = rootStore.authStore.getCurrentProjectRole();

  // Check if the feature is disabled for managers and below
  if (validateDisabledSubFeature("feature_reports_managers") === false) {
    if (["manager", "lead", "member"].includes(projectRole)) {
      return false;
    }
  }

  // Check if the feature is disabled for leads and below
  if (validateDisabledSubFeature("feature_reports_leads") === false) {
    if (["lead", "member"].includes(projectRole)) {
      return false;
    }
  }

  // Check if the feature is disabled for members
  if (validateDisabledSubFeature("feature_reports_members") === false) {
    if (projectRole === "member") {
      return false;
    }
  }

  // If none of the above conditions are met, the user has access
  return true;
};

//******************identify lead deleted protected functions ***********************/
const isStageDeleteProtected = ({ leadData }) => {
  let index = rootStore.authStore.stageList.findIndex(
    (stage) => stage["stage"] === leadData["lead_stage"]
  );
  if (index !== -1) {
    return rootStore.authStore.stageList[index]["delete_protected"];
  }
  return false;
};
const isStatusDeleteProtected = ({ leadData }) => {
  let index = rootStore.authStore.statusList.findIndex(
    (status) => status["status"] === leadData["lead_status"]
  );
  if (index !== -1) {
    return rootStore.authStore.statusList[index]["delete_protected"];
  }
  return false;
};

const isLeadDeleteProtected = ({ leadData }) => {
  return (
    isStageDeleteProtected({ leadData: leadData }) ||
    isStatusDeleteProtected({ leadData: leadData })
  );
};
//****************************END******************************************* */

const hasAttendanceMarkInOutAccess = () => {
  const accountRole =
    rootStore.userStore.UserData !== null
      ? rootStore.userStore.UserData["role"]
      : "";
  if (rootStore.subscriptionState.isFeatureAvailableWithAddOns("Attendance")) {
    //for account role member or admin, checking for account module "attendance"
    if (accountRole === "member" || accountRole === "admin") {
      //if attendance config is there
      if (rootStore.userStore.hasAttendanceUserSetting()) {
        const attendanceConfig = {
          ...rootStore.userStore.userAttendanceSetting,
        };
        return (
          attendanceConfig["attendance_from_device"] === "Web or Mobile" ||
          attendanceConfig["attendance_from_device"] === "Web"
        );
      }
      return false;
    }
    return false;
  }

  return false;
};

const generateHourListFromHourlyLogs = (hourlyLogs) => {
  try {
    if (!hourlyLogs || hourlyLogs.length === 0) {
      // If no hourly logs, use current time and expand to cover 10 hours
      const currentHour = moment().hour();
      let startHour = Math.max(currentHour - 5, 0);
      let endHour = Math.min(currentHour + 4, 23);

      if (endHour - startHour + 1 < 10) {
        const extraHoursNeeded = 10 - (endHour - startHour + 1);
        if (startHour === 0) {
          endHour = Math.min(endHour + extraHoursNeeded, 23);
        } else if (endHour === 23) {
          startHour = Math.max(startHour - extraHoursNeeded, 0);
        } else {
          startHour = Math.max(startHour - Math.ceil(extraHoursNeeded / 2), 0);
          endHour = Math.min(endHour + Math.floor(extraHoursNeeded / 2), 23);
        }
      }

      return Array.from(
        { length: endHour - startHour + 1 },
        (_, i) => startHour + i
      );
    }

    // Extract hours from the hourly logs
    const hours = hourlyLogs.map((log) => log.Hour);
    const minHour = Math.min(...hours);
    const maxHour = Math.max(...hours);

    let startHour = minHour;
    let endHour = maxHour;

    // Ensure at least 10 hours
    if (endHour - startHour + 1 < 10) {
      const extraHoursNeeded = 10 - (endHour - startHour + 1);
      startHour = Math.max(startHour - Math.ceil(extraHoursNeeded / 2), 0);
      endHour = Math.min(endHour + Math.floor(extraHoursNeeded / 2), 23);
    }

    // Generate the list of hours
    const hourList = [];
    for (let hour = startHour; hour <= endHour; hour++) {
      hourList.push(hour);
    }

    return hourList;
  } catch (error) {
    console.error(error);
    // Return a default list of 24 hours (0-23)
    return Array.from({ length: 24 }, (_, i) => i);
  }
};

//**********************mark as priority related helper functions**************************** */
const getISODateTimeByTimeSlot = (timeSlot) => {
  const todayDateTimeISO = moment(timeSlot, "h:mm A").toISOString();
  return todayDateTimeISO;
};

const getTimeSlotsForPriorityLead = () => {
  let currentTime = moment();
  let nextHour = moment().add(1, "hour").startOf("hour");
  if (nextHour.diff(currentTime, "minutes") < 30) {
    currentTime = nextHour.add(1, "hour").startOf("hour");
  } else {
    currentTime = nextHour;
  }
  const timeSlots = [];
  let currentTimeSlot = currentTime.clone();
  while (currentTimeSlot.isSameOrBefore(currentTime.clone().endOf("day"))) {
    timeSlots.push(currentTimeSlot.format("h:mm A"));
    currentTimeSlot.add(30, "minutes");
  }
  return timeSlots;
};
//***********************************end************************************************ */

//*******************************functions related widget data refetch ********************* */

const checkFrequency = (datetime, frequency) => {
  const now = moment().seconds(0).milliseconds(0);
  const then = moment(datetime).seconds(0).milliseconds(0);

  switch (frequency) {
    case "Every 5 Mins":
      return now.isSameOrAfter(then.add(5, "minutes"));
    case "Every 10 Mins":
      return now.isSameOrAfter(then.add(10, "minutes"));
    case "Every 15 Mins":
      return now.isSameOrAfter(then.add(15, "minutes"));
    case "Every 30 Mins":
      return now.isSameOrAfter(then.add(30, "minutes"));
    case "Every 1 Hour":
      return now.isSameOrAfter(then.add(1, "hour"));
    case "Every 3 Hours":
      return now.isSameOrAfter(then.add(3, "hours"));
    case "Every 6 Hours":
      return now.isSameOrAfter(then.add(6, "hours"));
    case "Every 12 Hours":
      return now.isSameOrAfter(then.add(12, "hours"));
    case "Every Day":
      return now.isSameOrAfter(then.add(1, "day"));
    case "Every Week":
      return now.isSameOrAfter(then.add(1, "week"));
    case "Every 15 Days":
      return now.isSameOrAfter(then.add(15, "days"));
    case "Every Month":
      return now.isSameOrAfter(then.add(1, "month"));
    default:
      return now.isSameOrAfter(then.add(5, "minutes")); // default case
  }
};

const canFetchWidgetData = ({ widgetID, frequencyString }) => {
  if (
    rootStore.userStore.widgetData.hasOwnProperty(
      getCombinedWidgetAndAccountID(widgetID)
    )
  ) {
    if (
      checkFrequency(
        rootStore.userStore.widgetData[getCombinedWidgetAndAccountID(widgetID)][
          "last_updated"
        ],
        frequencyString
      )
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};
//****************************************************END**************************************** */

const getFormattedMultilineText = (raw_string) => {
  return raw_string.replace(/\r\n|\n/g, "\n");
};

const getWorkTimingBreakChipColorTheme = ({ breakData }) => {
  if (
    breakData.hasOwnProperty("secure_break") &&
    breakData["secure_break"] !== null
  ) {
    if (breakData["secure_break"] === true) {
      return "warning";
    }
  }
  return "default";
};

//**********functions related to dashboard table widget height and width measurement**************** */

const getTableSize = ({ containerHeight }) => {
  let tempHeight = containerHeight;
  let rowHeight = 42;
  let tableHeaderFooterHeight = 96;
  if (tempHeight > tableHeaderFooterHeight) {
    tempHeight -= tableHeaderFooterHeight;
  }
  let tempTableSize = Math.floor(tempHeight / rowHeight);
  if (tempTableSize > defaultTableWidgetSize) {
    return tempTableSize;
  }
  return defaultTableWidgetSize;
};

const getColumnHeaderWidthByStringLength = (str) => {
  const charSize = 10;
  let str_len = str.length + 3;
  if (str_len < 10) {
    return 10 * charSize;
  } else {
    return str_len * charSize;
  }
};
//**************************END******************************** */

//below function is to get relative time string in days or hours or minutes

const formatMinutes = (minutes) => {
  try {
    const numMinutes = parseInt(minutes);

    if (isNaN(numMinutes)) {
      throw new Error("Invalid minutes input");
    }

    const days = Math.floor(numMinutes / 1440); // 1440 minutes in a day
    const hours = Math.floor((numMinutes % 1440) / 60);
    const remainingMinutes = numMinutes % 60;

    if (days > 0) {
      return `${days} day(s)${days > 1 ? "s" : ""}${
        hours ? ` ${hours} hr(s)` : ""
      }${remainingMinutes ? ` ${remainingMinutes} min(s)` : ""}`;
    } else if (hours > 0) {
      return `${hours} hr(s)${
        remainingMinutes ? ` ${remainingMinutes} min(s)` : ""
      }`;
    } else {
      return `${remainingMinutes} min(s)`;
    }
  } catch (error) {
    console.log(error);
    return "-";
  }
};

const checkFeaturePermissionInModule = (featureName) => {
  let modulesString =
    rootStore.userStore.UserData !== null &&
    rootStore.userStore.UserData.account.modules !== null
      ? rootStore.userStore.UserData.account.modules
      : "";
  let moduleList = modulesString.split(";");

  return moduleList.includes(featureName);
};

//**********below functions are used in lead listing and lead details pages for quick status update, update status, submit call log, add status to stage modal */

const getAvailableStatusListByStage = ({
  currentStage,
  stageList,
  statusList,
}) => {
  let index = stageList.findIndex((stage) => stage["stage"] === currentStage);
  if (index !== -1) {
    let stageData = stageList[index];
    if (
      stageData.hasOwnProperty("available_statuses") &&
      stageData["available_statuses"] !== null
    ) {
      if (stageData["available_statuses"].length > 0) {
        let tempList = [];
        stageData["available_statuses"].forEach((id) => {
          let index = statusList.findIndex((status) => status["id"] === id);
          if (index !== -1) {
            tempList.push(statusList[index]);
          }
        });
        return tempList;
      }
    }
  }
  return statusList;
};

//**************************end********************* */

//********permission check functions for job features************************** */

const canShowJobs = () => {
  if (validateDisabledFeature("jobs")) {
    const projectRole = authStore.getCurrentProjectRole().toLowerCase();
    if (projectRole === "member") {
      return false;
    } else if (projectRole === "manager") {
      return validateDisabledFeature("jobs_managers");
    } else if (projectRole === "lead") {
      return validateDisabledFeature("jobs_leads");
    } else {
      return true;
    }
  }
  return false;
};

//***********************END***************************** */

//********permission check functions for lead listing features************************** */

const canShowUpdateSourceInLeadListing = () => {
  if (validateDisabledSubFeature("update_source")) {
    const projectRole = authStore.getCurrentProjectRole().toLowerCase();
    if (projectRole === "member") {
      return false;
    } else if (projectRole === "manager") {
      return validateDisabledSubFeature("update_source_managers");
    } else if (projectRole === "lead") {
      return validateDisabledSubFeature("update_source_leads");
    } else {
      return true;
    }
  }
  return false;
};

const canShowUpdateFieldInLeadListing = () => {
  if (validateDisabledSubFeature("bulk_update_field")) {
    const projectRole = authStore.getCurrentProjectRole().toLowerCase();
    if (projectRole === "member") {
      return false;
    } else if (projectRole === "manager") {
      return validateDisabledSubFeature("bulk_update_field_managers");
    } else if (projectRole === "lead") {
      return validateDisabledSubFeature("bulk_update_field_leads");
    } else {
      return true;
    }
  }
  return false;
};

const canShowBulkSendWhatsappTemplate = () => {
  if (validateDisabledSubFeature("bulk_send_whatsapp_template")) {
    const projectRole = authStore.getCurrentProjectRole().toLowerCase();
    if (projectRole === "member") {
      return false;
    } else if (projectRole === "manager") {
      return validateDisabledSubFeature("bulk_send_whatsapp_template_managers");
    } else if (projectRole === "lead") {
      return validateDisabledSubFeature("bulk_send_whatsapp_template_leads");
    } else {
      return true;
    }
  }
  return false;
};

const canShowUpdateStatusInLeadListing = () => {
  if (validateDisabledSubFeature("update_status")) {
    const projectRole = authStore.getCurrentProjectRole().toLowerCase();
    if (projectRole === "member") {
      return false;
    } else if (projectRole === "manager") {
      return validateDisabledSubFeature("update_status_managers");
    } else if (projectRole === "lead") {
      return validateDisabledSubFeature("update_status_leads");
    } else {
      return true;
    }
  }
  return false;
};

const canShowUpdateStageInLeadListing = () => {
  if (validateDisabledSubFeature("update_stage")) {
    const projectRole = authStore.getCurrentProjectRole().toLowerCase();
    if (projectRole === "member") {
      return false;
    } else if (projectRole === "manager") {
      return validateDisabledSubFeature("update_stage_managers");
    } else if (projectRole === "lead") {
      return validateDisabledSubFeature("update_stage_leads");
    } else {
      return true;
    }
  }
  return false;
};

const canShowIndividualUpdateStage = () => {
  if (validateDisabledSubFeature("update_stage") === false) {
    return false;
  } else {
    const projectRole = authStore.getCurrentProjectRole().toLowerCase();
    if (projectRole === "member") {
      if (validateDisabledSubFeature("update_stage_members") === false) {
        return false;
      }
    } else if (projectRole === "lead") {
      if (validateDisabledSubFeature("update_stage_leads") === false) {
        return false;
      }
    } else if (projectRole === "manager") {
      if (validateDisabledSubFeature("update_stage_managers") === false) {
        return false;
      }
    }
    return true;
  }
};

const checkFeatureAndSubFeaturePermission = (featureName) => {
  switch (featureName) {
    case "update_source":
      return canShowUpdateSourceInLeadListing();
    case "update_status":
      return canShowUpdateStatusInLeadListing();
    case "update_stage":
      return canShowUpdateStageInLeadListing();
    case "update_field":
      return canShowUpdateFieldInLeadListing();
    case "send_whatsapp_template":
      return canShowBulkSendWhatsappTemplate();
    default:
      break;
  }
};

//***********************END***************************** */

//*************Functions to replace variables from a give string******************** */

// Function to replace variables in a template string
const replaceVariablesInString = ({
  messageString,
  variableValues,
  leadPattern,
  systemPattern,
}) => {
  // Replace lead variables {{VariableName}}
  let resultString = messageString.replace(leadPattern, (match, p1) => {
    return variableValues[p1] !== undefined &&
      variableValues[p1] !== null &&
      variableValues[p1] !== ""
      ? variableValues[p1]
      : "-";
  });

  // Replace system variables [[VariableName]]
  resultString = resultString.replace(systemPattern, (match, p1) => {
    return variableValues[p1] !== undefined &&
      variableValues[p1] !== null &&
      variableValues[p1] !== ""
      ? variableValues[p1]
      : "-";
  });

  return resultString;
};

// Function to extract variables and their types from a template string
const extractVariablesWithType = ({
  messageString,
  leadPattern,
  systemPattern,
}) => {
  // Array to store extracted variables with their types
  let variables = [];

  // Function to match and store variables with a specific type
  const extractMatches = (pattern, variable_type) => {
    let match;
    while ((match = pattern.exec(messageString)) !== null) {
      variables.push({ variable_name: match[1], variable_type });
    }
  };

  // Extract lead variables ({{Variable}})
  extractMatches(leadPattern, "lead_variable");

  // Extract system variables ([[Variable]])
  extractMatches(systemPattern, "system_variable");

  return variables;
};

const getSystemVariableValue = ({ variable }) => {
  switch (variable) {
    case "Date":
      return moment().format("YYYY-MM-DD");
    case "Time":
      return moment().format("hh:mm A");

    default:
      return null;
  }
};

const getLeadVariableValue = ({ columnMeta, leadData }) => {
  let data = leadData;
  let key = columnMeta["field"];

  if (columnMeta["is_custom_param"]) {
    key = columnMeta["field"].replace("custom_params_", "");
    let customData = leadData.hasOwnProperty("custom_params")
      ? JSON.parse(leadData["custom_params"])
      : {};
    data = customData;
  }

  if (data.hasOwnProperty(key)) {
    let value = data[key];
    if (columnMeta["data_type"] === "date") {
      value = IsoToLocalDate(value);
    } else if (columnMeta["data_type"] === "datetime") {
      value = IsoToLocalTime(value);
    }
    if (key === "lead_owner" || key === "team" || key === "lead_creator") {
      return value !== null ? value["name"] : "";
    }

    return value !== null ? value : "";
  } else {
    return "";
  }
};

const embedVariableValuesInMessageString = ({ messageString, leadData }) => {
  const columnsList = [...rootStore.userStore.AllColumnsList];
  // Patterns for lead and system variables
  const leadPattern = /\{\{([^{}]+)\}\}/g;
  const systemPattern = /\[\[([^[\]]+)\]\]/g;
  const variablesWithType = extractVariablesWithType({
    messageString: messageString,
    leadPattern: leadPattern,
    systemPattern: systemPattern,
  });
  console.log("variables with types:::", JSON.stringify(variablesWithType));

  let tempVariableValueMapping = {};
  variablesWithType.forEach((variableData) => {
    if (variableData["variable_type"] === "lead_variable") {
      let index = columnsList.findIndex(
        (column) => column["headerName"] === variableData["variable_name"]
      );
      if (index !== -1) {
        let variableValue = getLeadVariableValue({
          columnMeta: columnsList[index],
          leadData: leadData,
        });
        tempVariableValueMapping[variableData["variable_name"]] = variableValue;
      } else {
        tempVariableValueMapping[variableData["variable_name"]] = null;
      }
    } else {
      let variableValue = getSystemVariableValue({
        variable: variableData["variable_name"],
      });
      tempVariableValueMapping[variableData["variable_name"]] = variableValue;
    }
  });

  console.log(
    "temp variable value mapping::::",
    JSON.stringify(tempVariableValueMapping)
  );

  let resultantString = replaceVariablesInString({
    leadPattern: leadPattern,
    systemPattern: systemPattern,
    messageString: messageString,
    variableValues: tempVariableValueMapping,
  });

  console.log("resultant string::::", resultantString);
  return resultantString;
};

//***************************END****************************************** */

// Function to match closest string for projectColumns
const alternateStrings = {
  phone: "mobile",
  name: "person",
};
const findClosestMatchColumn = (field, projectColumns) => {
  const searchStr = field;
  const titleStr = capitalizeWord(field);
  const words = field.split("_");
  const wordsPascalSplit = field
    .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
    .toLowerCase()
    .split(" ");

  //match name/phone first

  // 1. Exact match for field
  let index = projectColumns.findIndex((obj) =>
    alternateStrings.hasOwnProperty(obj.field)
      ? obj.field === searchStr || alternateStrings[obj.field] === searchStr
      : obj.field === searchStr
  );
  if (index !== -1) return index;

  // 2. Exact match for headerName
  index = projectColumns.findIndex((obj) =>
    alternateStrings.hasOwnProperty(obj.headerName)
      ? obj.headerName === titleStr ||
        alternateStrings[obj.headerName] === titleStr
      : obj.headerName === titleStr
  );
  if (index !== -1) return index;

  // 3. Case-insensitive match for field
  index = projectColumns.findIndex((obj) =>
    alternateStrings.hasOwnProperty(obj.field)
      ? obj.field.toLowerCase() === searchStr.toLowerCase() ||
        alternateStrings[obj.field].toLowerCase() === searchStr.toLowerCase()
      : obj.field.toLowerCase() === searchStr.toLowerCase()
  );
  if (index !== -1) return index;

  // 4. Case-insensitive match for headerName
  index = projectColumns.findIndex((obj) =>
    alternateStrings.hasOwnProperty(obj.headerName)
      ? obj.headerName.toLowerCase() === titleStr.toLowerCase() ||
        alternateStrings[obj.headerName].toLowerCase() ===
          titleStr.toLowerCase()
      : obj.headerName.toLowerCase() === titleStr.toLowerCase()
  );
  if (index !== -1) return index;

  //5. Match for any word from the snake_case string in field or headerName

  //check name and phone first
  if (
    words.includes("name") ||
    words.includes("NAME") ||
    wordsPascalSplit.includes("person") ||
    wordsPascalSplit.includes("name")
  ) {
    const index = projectColumns.findIndex(
      (obj) =>
        obj.field.toLowerCase() === "name" ||
        obj.headerName.toLowerCase() === "name"
    );
    if (index !== -1) {
      const fieldWords = projectColumns[index]["field"]
        .toLowerCase()
        .split("_");
      const headerNameWords = projectColumns[index]["headerName"]
        .toLowerCase()
        .split(" ");

      let match = false;

      match = words.some(
        (word) =>
          fieldWords.includes(word.toLowerCase()) ||
          headerNameWords.includes(word.toLowerCase())
      );
      if (!match) {
        const alternateIndex = fieldWords.findIndex((key) =>
          Object.hasOwn(alternateStrings, key)
        );
        if (alternateIndex !== -1) {
          match = wordsPascalSplit.some(
            (word) =>
              fieldWords.includes(word.toLowerCase()) ||
              headerNameWords.includes(word.toLowerCase()) ||
              alternateStrings[fieldWords[alternateIndex]].includes(
                word.toLowerCase()
              )
          );
        }
      }

      if (match) {
        return index;
      }
    }
  }

  index = projectColumns.findIndex((obj) => {
    const fieldWords = obj.field.toLowerCase().split("_");
    const headerNameWords = obj.headerName.toLowerCase().split(" ");

    if (fieldWords.some((key) => key in alternateStrings)) {
      //comparing alternate string with search string if it is present
      const index = fieldWords.findIndex((key) =>
        Object.hasOwn(alternateStrings, key)
      );

      return (
        words.some(
          (word) =>
            fieldWords.includes(word.toLowerCase()) ||
            headerNameWords.includes(word.toLowerCase()) ||
            alternateStrings[fieldWords[index]].includes(word.toLowerCase())
        ) ||
        wordsPascalSplit.some(
          (word) =>
            fieldWords.includes(word.toLowerCase()) ||
            headerNameWords.includes(word.toLowerCase()) ||
            alternateStrings[fieldWords[index]].includes(word.toLowerCase())
        )
      );
    } else {
      return (
        words.some(
          (word) =>
            fieldWords.includes(word.toLowerCase()) ||
            headerNameWords.includes(word.toLowerCase())
        ) ||
        wordsPascalSplit.some(
          (word) =>
            fieldWords.includes(word.toLowerCase()) ||
            headerNameWords.includes(word.toLowerCase())
        )
      );
    }
  });
  if (index !== -1) return index;

  return -1;
};

//***************************END****************************************** */

const hasAccessToLeadsPaginationV2 = () => {
  const featureStartingDate = "2024-09-17T18:30:00.000Z";

  if (process.env.NODE_ENV !== "production") {
    return true;
  }

  const userData = rootStore.userStore.UserData;
  const currentAccountID = userData?.account?.id || "";

  if (accountIDsForNewPaginationAccess.includes(currentAccountID)) {
    return true;
  }

  const currentAccountCreatedAt = userData?.account?.created_at || null;

  return currentAccountCreatedAt
    ? moment(currentAccountCreatedAt).isSameOrAfter(featureStartingDate)
    : false;
};

const checkIfProjectHasWabaProvider = () => {
  const providersList = rootStore.authStore.getMetaWabaProvidersList();
  const index = providersList.findIndex((provider) => {
    if (provider.hasOwnProperty("project") && provider.project !== null) {
      return provider.project.id === rootStore.authStore.projectId;
    }
  });

  if (index !== -1) {
    consoleLogger("provider available for project");
    return true;
  }
  return false;
};

const getProjectMetaWabaProvider = () => {
  const providersList = rootStore.authStore.getMetaWabaProvidersList();
  const index = providersList.findIndex((provider) => {
    if (provider.hasOwnProperty("project") && provider.project !== null) {
      return provider.project.id === rootStore.authStore.projectId;
    }
  });

  if (index !== -1) {
    return providersList[index];
  }
  return {};
};

const getDaysDifference = (date) => {
  if (!date) return -1;

  const now = moment();
  const lastShownMoment = moment(date);

  if (!lastShownMoment.isValid()) {
    consoleLogger("Invalid date.");
    return -1;
  }

  const daysDifference = now.diff(lastShownMoment, "days");

  return daysDifference;
};

const getTimeDifferenceInHours = (time1, time2) => {
  const moment1 = moment(time1);
  const moment2 = moment(time2);

  const diffInHours = moment2.diff(moment1, "hours", true);

  return diffInHours;
};

const formatPhoneNumberForWhatsApp = (phoneNumber) => {
  // indian number regex
  const indianNumberRegex = /^[6-9]\d{9}$/;

  // Regular expression to check for country code
  const countryCodeRegex = /^\+\d{1,3}/;

  if (countryCodeRegex.test(phoneNumber)) {
    // Remove special characters and white spaces if country code is present
    return phoneNumber.replace(/[^0-9]/g, "");
  } else {
    phoneNumber = phoneNumber.replace(/[^0-9]/g, ""); // remove special characters and white spaces before comparing
    //if country code not found, handle india phone cases by default
    if (phoneNumber.length === 10 && indianNumberRegex.test(phoneNumber)) {
      phoneNumber = "91" + phoneNumber;
    } else if (phoneNumber.length > 10) {
      if (phoneNumber.startsWith("0")) {
        let temp = phoneNumber.slice(1);
        phoneNumber = "91" + temp;
      }
    }
    return phoneNumber;
  }
};

const isCurrentLatLongValid = async () => {
  const locationLatLong = rootStore.authStore.getLoginLocationCoordinates();
  let targetCoordinates = {};

  //if latlong in store is null or empty, not doing the check
  if (locationLatLong !== null) {
    if (locationLatLong === "") {
      return true;
    }

    let array = locationLatLong.split(",");
    targetCoordinates = {
      latitude: parseFloat(array[0]),
      longitude: parseFloat(array[1]),
    };
  } else {
    return true;
  }

  // continue with the check only if target coords are present and check is enabled in admin settings
  if (
    targetCoordinates !== null &&
    targetCoordinates !== undefined &&
    Object.keys(targetCoordinates).length !== 0
  ) {
    // if target coord is not there, allowing login
    if (
      targetCoordinates["latitude"] === null ||
      targetCoordinates["longitude"] === null ||
      targetCoordinates["latitude"] === undefined ||
      targetCoordinates["longitude"] === undefined
    ) {
      return true;
    }

    //getting browser location
    const location = window.navigator && window.navigator.geolocation;

    //getCurrentPosition will return error callback if permission is denied or location not available
    try {
      const position = await new Promise((resolve, reject) => {
        location.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
        });
      });

      const { latitude, longitude } = position.coords;
      //if current browser coords is within 100 meter radius of target coordinates, allowing access
      const isValid = isCoordWithinRange(
        { latitude: latitude, longitude: longitude },
        targetCoordinates,
        100
      ); // 100 meters radius buffer
      consoleLogger(
        "latitude, longitude, isValid: ",
        latitude + ", " + longitude + ", " + isValid
      );

      return isValid;
    } catch (error) {
      // if unable to get browser location and when user blocks browser permission prompt, not allowing login
      console.log("Error getting location:", error);
      return false;
    }
  } else {
    return true;
  }
};

const isCoordWithinRange = (checkPoint, centerPoint, meters) => {
  var ky = 40000 / 360;
  var kx = Math.cos((Math.PI * centerPoint.latitude) / 180.0) * ky;
  var dx = Math.abs(centerPoint.longitude - checkPoint.longitude) * kx;
  var dy = Math.abs(centerPoint.latitude - checkPoint.latitude) * ky;
  return Math.sqrt(dx * dx + dy * dy) <= meters / 1000;
};

const convertBytesToMB = (bytes) => {
  return (bytes / (1024 * 1024)).toFixed(2);
};

const canShowAreaBasedQuoteType = () => {
  let bool = false;
  const projectData = rootStore.authStore.currentProject;

  if (
    projectData.hasOwnProperty("vertical_config") &&
    projectData.vertical_config !== null &&
    projectData.vertical_config !== ""
  ) {
    if (
      projectData.vertical_config.hasOwnProperty("Quotations") &&
      projectData.vertical_config["Quotations"] !== null &&
      projectData.vertical_config["Quotations"] !== ""
    ) {
      bool = projectData.vertical_config["Quotations"].includes(
        "area_based_quotation_template"
      );
    }
  }
  return bool;
};

function getStartEndDatesForReport(dateRange) {
  let startDate = "";
  let endDate = "";

  switch (dateRange) {
    case "Today":
      startDate = moment().startOf("day");
      endDate = moment().startOf("day");
      break;
    case "Yesterday":
      startDate = moment().subtract(1, "days");
      endDate = moment().subtract(1, "days");
      break;
    case "Last 7 Days":
      startDate = moment().subtract(8, "d");
      endDate = moment().subtract(1, "days");
      break;
    case "Current Month":
      startDate = moment().startOf("month");
      endDate = moment().endOf("month");
      break;
    case "Last Month":
      startDate = moment().subtract(1, "month").startOf("month");
      endDate = moment().subtract(1, "month").endOf("month");
      break;
    case "Last 3 Months":
      startDate = moment().subtract(3, "months").startOf("month");
      endDate = moment().endOf("month");
      break;
    case "Year":
      startDate = moment().startOf("year");
      endDate = moment().endOf("year");
      break;
    default:
      startDate = moment().startOf("day");
      endDate = moment().endOf("day");
      break;
  }
  const dates = {
    start_date: getIsoString(startDate.format("YYYY-MM-DD")),
    end_date: getIsoString(endDate.format("YYYY-MM-DD")),
  };
  return dates;
}

const getImportTypeDisplayName = (importType) => {
  if (
    importType.toLowerCase() === "create" ||
    importType.toLowerCase() === "create_leads_v1" ||
    importType.toLowerCase() === "create_customers" ||
    importType.toLowerCase() === "create_products"
  ) {
    return "Create";
  } else if (importType.toLowerCase() === "update" || "update_leads_v1") {
    return "Update";
  }
  return "-";
};

const convertPxToPts = (html) => {
  // Regex to find pixel values (e.g., `20px`)
  return html.replace(/(\d+)px/g, (match, pxValue) => {
    const ptsValue = (parseFloat(pxValue) / 1.333).toFixed(2); // Convert px to pts
    return `${ptsValue}pt`; // Replace with equivalent pt value
  });
};

const isCustomQuotationTemplate = (schemaData) => {
  return (
    schemaData.hasOwnProperty("template_type") &&
    schemaData["template_type"] === "custom_quotation_template_1"
  );
};

const canCloneCustomQuotationTemplate = (schemaData) => {
  let templateType = schemaData.hasOwnProperty("template_type")
    ? schemaData["template_type"]
    : "";

  let bool = false;
  const projectData = rootStore.authStore.currentProject;

  if (
    projectData.hasOwnProperty("vertical_config") &&
    projectData.vertical_config !== null
  ) {
    if (
      projectData.vertical_config.hasOwnProperty("Quotations") &&
      projectData.vertical_config["Quotations"] !== null
    ) {
      bool = projectData.vertical_config["Quotations"].includes(templateType);
    }
  }
  return bool;
};

 const canViewDelhiveryMenu = () => {
  const delhiveryConfigData = rootStore.userStore.getDelhiveryIntegrationsConfig();
    //showing if delhivery config data is present
    if (
      Object.keys(delhiveryConfigData).length > 0 &&
      delhiveryConfigData.hasOwnProperty("project_id") &&
      delhiveryConfigData.project_id !== null &&
      delhiveryConfigData.project_id !== ""
    ) {
      return (
        delhiveryConfigData["project_id"] === rootStore.authStore.projectId
      );
    }
    return false;
  };


export {
  responsive,
  CustomDotLogin,
  ChangeIndex,
  dateTimeHandler,
  dateHandler,
  tabsDateHandler,
  convertToUTC,
  ampm,
  validateUnmaskColumns,
  validateDisabledFeature,
  validateDisabledSubFeature,
  IsoToLocalTime,
  IsoToLocalDate,
  isMoreThanXDaysAgo,
  IsSameUser,
  isUnderWorkSchedule,
  getLocalizedText,
  getLocalizedErrorText,
  getAccountLocalizedText,
  updateViewList,
  validateUnmaskColumnsAtProjectLevel,
  checkFeaturePermission,
  dataRenderer,
  getLocalDateTime,
  getLocalTimeFromIsoString,
  consoleLogger,
  isLastContactedToday,
  sortAndAssignWidthColumnSetup,
  getCurrentEpochTime,
  capitalizeWord,
  isValidDateTime,
  isNumber,
  getIsoString,
  replacePlaceholdersWithProjectID,
  createLeadReplaceFilterEpWithProject,
  createLeadReplaceFilterPlaceHolderEp,
  replacePlaceholders,
  getSlug,
  getJobTypeDisplayName,
  getStatusColorCode,
  getStageColorCode,
  setCookie,
  isProjectAndAccountIdMatched,
  isAccountIdMatched,
  isMoreThanFiveMinutes,
  getCombinedWidgetAndAccountID,
  isMoreThanFifteenMinutes,
  isToday,
  isAfterCurrentTime,
  isBeforeCurrentTime,
  getLocalTimeStringFromIsoDateString,
  getClosestDateTime,
  getFilePattern,
  isLeadRotten,
  getNumberOfDaysInCurrentStage,
  isStageClosed,
  getDateTimeDescription,
  getDateDescription,
  isDateInRange,
  isDateTimeInRange,
  getTooltipOfLeadColor,
  isSetLeadColorContextMenuDisabled,
  isOpenCallLogContextMenuDisabled,
  canShowSkypeCallIcon,
  isTeckinfoDialer,
  currentDayNameGetter,
  getLeadListingAvatarIconColor,
  getLeadListingAvatarTooltipText,
  isAdditionalStatusDetailsRequired,
  hasReportAccess,
  getCustomerListingAvatarTooltipText,
  getCustomerListingAvatarIconColor,
  isLeadDeleteProtected,
  hasAttendanceMarkInOutAccess,
  getTimeSlotsForPriorityLead,
  getISODateTimeByTimeSlot,
  canFetchWidgetData,
  getFormattedMultilineText,
  getWorkTimingBreakChipColorTheme,
  generateHourListFromHourlyLogs,
  getTableSize,
  getColumnHeaderWidthByStringLength,
  formatMinutes,
  checkFeaturePermissionInModule,
  getAvailableStatusListByStage,
  canShowJobs,
  checkFeatureAndSubFeaturePermission,
  embedVariableValuesInMessageString,
  findClosestMatchColumn,
  hasAccessToLeadsPaginationV2,
  checkIfProjectHasWabaProvider,
  getProjectMetaWabaProvider,
  getDaysDifference,
  getTimeDifferenceInHours,
  formatPhoneNumberForWhatsApp,
  isCurrentLatLongValid,
  convertBytesToMB,
  canShowAreaBasedQuoteType,
  isCoordWithinRange,
  getStartEndDatesForReport,
  getSystemVariableValue,
  getLeadVariableValue,
  getImportTypeDisplayName,
  canShowIndividualUpdateStage,
  convertPxToPts,
  isCustomQuotationTemplate,
  canCloneCustomQuotationTemplate,
  canViewDelhiveryMenu,
};
